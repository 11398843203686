import {
  GetCustomerInfo,
  ReadCustomerLinks,
  GetMobileCustomerBalance,
  getPointOverview
} from "../api/PayServerApi";

async function fetchCustInfo(context, config) {
  const { userid, psapi, apikey } = context;
  const { customer, phone, success, failure } = config;
  let result = GetCustomerInfo(psapi, userid, customer, phone, apikey)
    .then(success)
    .catch(failure);
  return result;
}

async function fetchCustLinks(context, config) {
  const { userid, psapi, apikey } = context;
  const { customer, phone, success, failure } = config;
  let result = ReadCustomerLinks(psapi, userid, customer, phone, apikey)
    .then(success)
    .catch(failure);
  return result;
}

async function fetchBalance(context, config) {
  const { userid, psapi, apikey } = context;
  const { customer, success, failure } = config;
  let result = GetMobileCustomerBalance(psapi, userid, customer, apikey)
    .then(success)
    .catch(failure);
  return result;
}

async function fetchOverview(context, config) {
  const { psapi, apikey } = context;
  const { phone, success, failure } = config;
  const phonenumber = phone;
  let result = getPointOverview(psapi, phonenumber, apikey)
    .then(success)
    .catch(failure);
  return result;
}

export { fetchCustInfo, fetchCustLinks, fetchBalance, fetchOverview };
