import React, { useState, useEffect } from "react";
import { Card, Col, CardHeader, CardTitle, CardBody } from "reactstrap";
import { chartDefaults } from "../../options/Charts";
import { Line } from "react-chartjs-2";
import { formatDate } from "../../../utils";
import PropTypes from "prop-types";

const ErrorCorrectChart = ({ data }) => {
  const [dates, setDates] = useState([]);
  const [errorCorrects, setErrorCorrects] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    getDates();
    getTotal();
  }, [data]);

  const getDates = () => {
    const dateArray = [];
    const voidArray = [];
    data.map((record) => {
      dateArray.push(formatDate(record.post_Date));
      voidArray.push(record.error_Correct);
    });

    setDates(dateArray);
    setErrorCorrects(voidArray);
  };

  const getTotal = () => {
    const totalizerTotal = data.reduce((acc, cur) => {
      return parseInt(cur.error_Correct) + acc;
    }, 0);

    setTotal(totalizerTotal);
  };
  let chartExample2 = {
    data: (canvas) => {
      let ctx = canvas.getContext("2d");

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(72,72,176,0.1)");
      gradientStroke.addColorStop(0.4, "rgba(72,72,176,0.0)");
      gradientStroke.addColorStop(0, "rgba(119,52,169,0)"); //purple colors
      //"#1f8ef1"

      // gradientStroke.addColorStop(1, "rgba(253,93,147,0.8)");
      // gradientStroke.addColorStop(0, "rgba(253,93,147,0)"); //blue colors

      return {
        labels: dates,
        datasets: [
          {
            label: "Error Correct",
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: "#ff5991",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#ff5991",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#ff5991",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: errorCorrects
          }
        ]
      };
    },
    options: chartDefaults
  };
  return (
    <Col lg="6">
      <Card className="card-chart">
        <CardHeader>
          <h1
            style={{
              fontSize: "1.2rem",
              color: "#6ad66d",
              marginBottom: "0px"
            }}
          >
            {total} records
          </h1>
          <CardTitle tag="h3">
            <i className="fad fa-store-slash text-info" />
            Total Error Corrects
          </CardTitle>
        </CardHeader>
        <CardBody>
          <div className="chart-area">
            <Line data={chartExample2.data} options={chartExample2.options} />
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

ErrorCorrectChart.propTypes = {
  data: PropTypes.array
};

export default ErrorCorrectChart;
