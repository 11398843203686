import React, { useState, useEffect, useContext } from "react";
import HeaderRb from "../common/HeaderRb";
import Header from "../Header/Header";
import { ContextConfig } from "../App";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "../common/Spinner";
import * as actions from "../actions/actionTypes";
import { formatDate } from "../utils";
import { debounce } from "../fp";
import { toast } from "react-toastify";
import { charges } from "../api/widgetsApi";
import PagingToolbar from "../common/PagingToolbar";
import ErrorBoundary from "../common/ErrorBoundary";

const Charges = () => {
  const [reRender, setReRender] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [recordsPerPage, setRecordsPerPage] = useState(50);
  const [pagingOptions, setPagingOptions] = useState({
    start: 0,
    end: 50
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const state = useSelector((app) => app.chargesReducer);
  const dispatch = useDispatch();

  const context = useContext(ContextConfig);

  useEffect(() => {
    if (context.lastSearchType != "3") {
      dispatch({ type: actions.REF_ENABLE_CASHIER, enableCashier: false });
    }

    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }

      return () => {
        dispatch({ type: actions.REF_ENABLE_CASHIER, enableCashier: false });
      };
    }, 500);

    findDivHeight();

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, []);

  useEffect(() => {
    if (state.filteredCharges.length > 0) {
      filterTotalizers();
      filterCustomers();
    }
  }, [state.filteredCharges]);

  useEffect(() => {
    filterChargesByState();
  }, [state.customer]);

  useEffect(() => {
    filterChargesByState();
  }, [state.totalizer]);

  useEffect(() => {
    getCharges();
  }, [pagingOptions, reRender, recordsPerPage]);

  const findDivHeight = () => {
    const navbar = document
      .getElementById("master-navbar")
      .getBoundingClientRect().height;
    const controls = document.getElementById("controls").getBoundingClientRect()
      .height;
    const usedSpace = navbar + controls;
    const remaining = window.innerHeight - usedSpace;

    const body = document.getElementById("body");
    body.style.height = remaining + "px";
    body.style.overflow = "auto";
  };

  const filterTotalizers = () => {
    const newArray = [];
    const newDropdownArray = [];
    state.filteredCharges.map((charge) => {
      if (!newArray.includes(charge.f1034)) {
        newArray.push(charge.f1034);
        newDropdownArray.push({ f1034: charge.f1034, f1039: charge.f1039 });
      }
    });

    dispatch({
      type: actions.CHARGES_SET_TOTALIZERS,
      totalizers: newDropdownArray
    });
  };

  const filterCustomers = () => {
    const newArray = [];
    const dropdownArray = [];
    state.charges.map((charge) => {
      if (!newArray.includes(charge.f1148)) {
        newArray.push(charge.f1148);
        dropdownArray.push({ f1148: charge.f1148, name: charge.f1155 });
      }
    });
    dispatch({ type: actions.CHARGES_SET_CUSTOMERS, customers: dropdownArray });
  };

  const filterChargesByState = () => {
    if (state.customer == "0" && state.totalizer == "0") {
      dispatch({
        type: actions.CHARGES_SET_FILTERED_CHARGES,
        filteredCharges: state.charges
      });
    } else if (state.customer == "0" && parseInt(state.totalizer) > 0) {
      const filteredTotalizers = state.charges.filter(
        (c) => c.f1034 == state.totalizer
      );
      dispatch({
        type: actions.CHARGES_SET_FILTERED_CHARGES,
        filteredCharges: filteredTotalizers
      });
    } else if (parseFloat(state.customer) > 0 && state.totalizer == "0") {
      const filteredCustomers = state.charges.filter(
        (c) => c.f1148 == state.customer
      );
      dispatch({
        type: actions.CHARGES_SET_FILTERED_CHARGES,
        filteredCharges: filteredCustomers
      });
    } else if (
      parseFloat(state.customer) > 0 &&
      parseInt(state.totalizer) > 0
    ) {
      const filteredCharges = state.charges.filter(
        (c) => c.f1148 == state.customer && c.f1034 == state.totalizer
      );
      dispatch({ type: actions.CHARGES_SET_FILTERED_CHARGES, filteredCharges });
    }
  };

  const getCharges = () => {
    if (pagingOptions.start === 0 && pagingOptions.end === 0) return;

    const start = currentPage * recordsPerPage;
    const end = (currentPage + 1) * recordsPerPage;

    dispatch({ type: actions.CHARGES_IS_LOADING, isLoading: true });
    let search = context.lastSearchType === 2 ? "Group" : "Stores";
    if (context.lastSearchType == 3) {
      search = "Store";
    }

    let searchValue = context.lastStoreid;
    if (context.lastSearchType == 2) {
      searchValue = context.lastGroup;
    }
    charges(
      context.url,
      context.token,
      searchValue,
      formatDate(state.startDate),
      formatDate(state.endDate),
      search,
      state.customer,
      state.totalizer,
      start,
      end
    )
      .then((response) => {
        dispatch({ type: actions.CHARGES_IS_LOADING, isLoading: false });
        const j = response.data;
        if (j.error === 0) {
          setTotalRecords(j.chargeCount);
          dispatch({ type: actions.CHARGES_SET_CHARGES, charges: j.charges });
          dispatch({
            type: actions.CHARGES_SET_FILTERED_CHARGES,
            filteredCharges: j.charges
          });
        } else {
          toast.warning(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
          dispatch({ type: actions.CHARGES_SET_CHARGES, charges: [] });
          dispatch({
            type: actions.CHARGES_SET_FILTERED_CHARGES,
            filteredCharges: []
          });
        }
      })
      .catch((err) => {
        dispatch({ type: actions.CHARGES_IS_LOADING, isLoading: false });
        console.log(err);
        dispatch({ type: actions.CHARGES_SET_CHARGES, charges: [] });
        dispatch({
          type: actions.CHARGES_SET_FILTERED_CHARGES,
          filteredCharges: []
        });
        toast.error("An Internal Error has occured", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const gatherTotals = () => {
    const totals = state.filteredCharges.reduce(
      (acc, cur) => {
        const newF64 = parseFloat(acc.f64) + parseFloat(cur.f64);
        const newF65 = parseFloat(acc.f65) + parseFloat(cur.f65);
        const newF67 = parseFloat(acc.f67) + parseFloat(cur.f67);
        return { f64: newF64, f65: newF65, f67: newF67 };
      },
      {
        f64: 0,
        f65: 0,
        f67: 0
      }
    );

    return (
      <tr>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td style={{ textAlign: "right" }}>
          {parseFloat(totals.f64).toFixed(2)}
        </td>
        <td style={{ textAlign: "right" }}>
          {parseFloat(totals.f65).toFixed(2)}
        </td>
        <td style={{ textAlign: "right" }}>
          {parseFloat(totals.f67).toFixed(2)}
        </td>
      </tr>
    );
  };

  const handlePageChange = (e) => {
    setCurrentPage(e);

    const start = e * recordsPerPage;
    const end = (e + 1) * recordsPerPage;

    setPagingOptions({
      start: start,
      end: end
    });
  };

  const handleRecordsPerPageChange = (e) => {
    const start = currentPage * parseInt(e.target.value);
    const end = (currentPage + 1) * parseInt(e.target.value);

    setPagingOptions({
      start: start,
      end: end
    });
    setRecordsPerPage(e.target.value);
  };

  return (
    <div>
      {state.isLoading && <Spinner />}
      <HeaderRb />
      <div id="controls">
        <div className="container-fluid">
          <h3>Charges</h3>
          <Header
            reRender={reRender}
            setReRender={setReRender}
            startDate={context.startDate}
            endDate={context.endDate}
            setStartDate={(e) =>
              dispatch({ type: actions.CHARGES_SET_START_DATE, startDate: e })
            }
            setEndDate={(e) =>
              dispatch({ type: actions.CHARGES_SET_END_DATE, endDate: e })
            }
            setLoading={(e) => e}
            showSingleStore={true}
          />
        </div>
        <ErrorBoundary>
          <div className="container mt-3">
            <div className="row justify-content-center">
              <div className="col-4">
                <select
                  className="form-control"
                  value={state.totalizer}
                  onChange={(e) =>
                    dispatch({
                      type: actions.CHARGES_SET_TOTALIZER,
                      totalizer: e.target.value
                    })
                  }
                >
                  <option value="0">Select a Totalizer</option>
                  {state.totalizers.map((totalizer, i) => (
                    <option key={`ct-${i}`} value={totalizer.f1034}>
                      {totalizer.f1034 + " : " + totalizer.f1039}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-4">
                <select
                  className="form-control"
                  value={state.customer}
                  onChange={(e) =>
                    dispatch({
                      type: actions.CHARGES_SET_CUSTOMER,
                      customer: e.target.value
                    })
                  }
                >
                  <option value="0">Select a Customer</option>
                  {state.customers.map((customer, i) => (
                    <option key={`cn-${i}`} value={customer.f1148}>
                      {customer.f1148 + " : " + customer.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-2" style={{ marginTop: "20px" }}>
                {state.filteredCharges.length} records
              </div>
            </div>
          </div>
        </ErrorBoundary>
      </div>
      <div id="body">
        <ErrorBoundary>
          <div className="container" style={{ marginTop: "20px" }}>
            {state.filteredCharges.length == 0 ? (
              <div className="empty-charges">
                There are no records to Display
              </div>
            ) : (
              <div>
                <PagingToolbar
                  data={state.filteredCharges}
                  currentPage={currentPage}
                  totalRecords={totalRecords}
                  handlePageChange={handlePageChange}
                  recordsPerPage={recordsPerPage}
                  handleRecordsPerPageChange={handleRecordsPerPageChange}
                  start={pagingOptions.start}
                  end={pagingOptions.end}
                />
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Customer</th>
                      <th>Name</th>
                      <th>Totalizer</th>
                      <th>Qty</th>
                      <th style={{ textAlign: "right" }}>Amount</th>
                      <th style={{ textAlign: "right" }}>Weight</th>
                    </tr>
                  </thead>
                  <tbody>
                    {state.filteredCharges.map((record, index) => (
                      <tr key={`cc-${index}`}>
                        <td>{record.f253}</td>
                        <td>{record.f1148}</td>
                        <td>{record.f1155}</td>
                        <td>{record.f1039}</td>
                        <td style={{ textAlign: "right" }}>
                          {parseFloat(record.f64).toFixed(2)}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {parseFloat(record.f65).toFixed(2)}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {parseFloat(record.f67).toFixed(2)}
                        </td>
                      </tr>
                    ))}
                    {gatherTotals()}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </ErrorBoundary>
      </div>
    </div>
  );
};

export default Charges;
