import React from "react";
import PropTypes from "prop-types";
import { setDecimal } from "../../utils";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardFooter,
  CardBody,
  CardTitle
} from "reactstrap";

const Tot = (props) => {
  const { f1034, median, f65 } = props;
  return (
    <Card style={{ cursor: "pointer" }}>
      <CardHeader tag="h3">
        <span style={{ color: props.setColor(f1034) }}>{props.tot.f1039}</span>
      </CardHeader>
      <CardBody>
        <Row>
          <Col xs="6" className="tot-median">
            <p>Cashier Median</p>
            <CardTitle tag="h3">
              {parseFloat(median) < parseFloat(f65) ? (
                <span style={{ color: "limegreen" }}>{median}</span>
              ) : (
                <span style={{ color: "ed3226" }}>{median}</span>
              )}
            </CardTitle>
          </Col>
          <Col xs="6">
            <p>Store Median</p>
            <CardTitle tag="h3">
              <span>{f65}</span>
            </CardTitle>
          </Col>
        </Row>

        <Col xs="12" className="tot-score">
          <CardTitle tag="h3">Score: {setDecimal(props.tot.score)}</CardTitle>
        </Col>
      </CardBody>
      <CardFooter>Tlz: {props.tot.f1034}</CardFooter>
    </Card>
  );
};

Tot.propTypes = {
  tot: PropTypes.object,
  setColor: PropTypes.func,
  f1034: PropTypes.any,
  median: PropTypes.any,
  f65: PropTypes.any
};

export default Tot;
