import React, { useState, useEffect, useContext } from "react";
import QueryBuilder from "./QueryBuilder";
import { v4 as uuidv4 } from "uuid";
import HeaderRB from "../../common/HeaderRb";
import { debounce } from "../../fp";
import qs from "qs";
import { getReportList } from "../../api/basketApi";
import { ContextConfig } from "../../App";
import { toast } from "react-toastify";
import PropTypes from "prop-types";

export const data = [
  {
    id: uuidv4(),
    label: "Date",
    accessor: "f254",
    aggregate: "0",
    alias: "",
    css: "0",
    customm: "",
    type: "string"
  },
  {
    id: uuidv4(),
    label: "Store Number",
    accessor: "f1056",
    aggregate: "",
    alias: "",
    css: "0",
    customm: "",
    type: "number"
  },
  {
    id: uuidv4(),
    label: "Terminal",
    accessor: "f1057",
    aggregate: "",
    alias: "",
    css: "0",
    customm: "",
    type: "string"
  },
  {
    id: uuidv4(),
    label: "Transaction Number",
    accessor: "f1032",
    aggregate: "",
    alias: "",
    css: "0",
    customm: "",
    type: "number"
  },
  {
    id: uuidv4(),
    label: "UPC",
    accessor: "f01",
    aggregate: "0",
    alias: "",
    css: "0",
    customm: "",
    type: "string"
  },
  {
    id: uuidv4(),
    label: "Descriptor",
    accessor: "f02",
    aggregate: "",
    alias: "",
    css: "0",
    customm: "",
    type: "string"
  },
  {
    id: uuidv4(),
    label: "Department",
    accessor: "f03",
    aggregate: "",
    alias: "",
    css: "0",
    customm: "",
    type: "number"
  },
  {
    id: uuidv4(),
    label: "Sub Department",
    accessor: "f04",
    aggregate: "",
    alias: "",
    css: "0",
    customm: "",
    type: "number"
  },
  {
    id: uuidv4(),
    label: "Sales",
    accessor: "f65",
    aggregate: "",
    alias: "",
    css: "0",
    customm: "",
    type: "money"
  },
  {
    id: uuidv4(),
    label: "Qty",
    accessor: "f64",
    aggregate: "",
    alias: "",
    css: "0",
    customm: "",
    type: "number"
  },
  {
    id: uuidv4(),
    label: "Weight",
    accessor: "f67",
    aggregate: "",
    alias: "",
    css: "0",
    customm: "",
    type: "weight"
  },
  {
    id: uuidv4(),
    label: "Active Price",
    accessor: "f1007",
    aggregate: "",
    alias: "",
    css: "0",
    customm: "",
    type: "money"
  },
  {
    id: uuidv4(),
    label: "Active Qty",
    accessor: "f1006",
    aggregate: "",
    alias: "",
    css: "0",
    customm: "",
    type: "number"
  },
  {
    id: uuidv4(),
    label: "Price Type",
    accessor: "f113",
    aggregate: "",
    alias: "",
    css: "0",
    customm: "",
    type: "string"
  },
  {
    id: uuidv4(),
    label: "Customer Number",
    accessor: "f1148",
    aggregate: "",
    alias: "",
    css: "0",
    customm: "",
    type: "string"
  },
  {
    id: uuidv4(),
    label: "Package Price Discount Descriptor",
    accessor: "f1512",
    aggregate: "",
    alias: "",
    css: "0",
    customm: "",
    type: "string"
  },
  {
    id: uuidv4(),
    label: "Pack Price Discount Amount",
    accessor: "f1515",
    aggregate: "",
    alias: "",
    css: "0",
    customm: "",
    type: "money"
  },
  {
    id: uuidv4(),
    label: "Cashier Number",
    accessor: "f1126",
    aggregate: "",
    alias: "",
    css: "0",
    customm: "",
    type: "number"
  },
  {
    id: uuidv4(),
    label: "Cashier Name",
    accessor: "f1127",
    aggregate: "",
    alias: "",
    css: "0",
    customm: "",
    type: "string"
  },
  {
    id: uuidv4(),
    label: "Phone Number",
    accessor: "f1577",
    aggregate: "",
    alias: "",
    css: "0",
    customm: "",
    type: "string"
  },
  {
    id: uuidv4(),
    label: "First Name",
    accessor: "f1149",
    aggregate: "",
    alias: "",
    css: "0",
    customm: "",
    type: "string"
  },
  {
    id: uuidv4(),
    label: "Last Name",
    accessor: "f1150",
    aggregate: "",
    alias: "",
    css: "0",
    customm: "",
    type: "string"
  },
  {
    id: uuidv4(),
    label: "Category",
    accessor: "catNumber",
    aggregate: "",
    alias: "",
    css: "0",
    customm: "",
    type: "number"
  },
  {
    id: uuidv4(),
    label: "Category Description",
    accessor: "catDescriptor",
    aggregate: "",
    alias: "",
    css: "0",
    customm: "",
    type: "string"
  },
  {
    id: uuidv4(),
    label: "Sub Department Description",
    accessor: "subDescription",
    aggregate: "",
    alias: "",
    css: "0",
    customm: "",
    type: "string"
  },
  {
    id: uuidv4(),
    label: "Shopper Level",
    accessor: "f1152",
    aggregate: "",
    alias: "",
    css: "0",
    customm: "",
    type: "number"
  },
  {
    id: uuidv4(),
    label: "Storeid",
    accessor: "storeid",
    aggregate: "",
    alias: "",
    css: "0",
    customm: "",
    type: "number"
  },
  {
    id: uuidv4(),
    label: "Group",
    accessor: "group",
    aggregate: "",
    alias: "",
    css: "",
    customm: "",
    type: "string"
  },
  {
    id: uuidv4(),
    label: "Limited Price Discount",
    accessor: "limitedPriceDiscountAmount",
    aggregate: "",
    alias: "",
    css: "",
    custom: "",
    type: "money"
  },
  {
    id: uuidv4(),
    label: "Points 1",
    accessor: "points1Amount",
    aggregate: "",
    alias: "",
    css: "",
    custom: "",
    type: "number"
  },
  {
    id: uuidv4(),
    label: "Points 2",
    accessor: "points2Amount",
    aggregate: "",
    alias: "",
    css: "",
    custom: "",
    type: "number"
  },
  {
    id: uuidv4(),
    label: "Points 3",
    accessor: "points3Amount",
    aggregate: "",
    alias: "",
    css: "",
    custom: "",
    type: "number"
  },
  {
    id: uuidv4(),
    label: "Points 4",
    accessor: "points4Amount",
    aggregate: "",
    alias: "",
    css: "",
    custom: "",
    type: "number"
  },
  {
    id: uuidv4(),
    label: "Point Redemptions",
    accessor: "redemptionAmount",
    aggregate: "",
    alias: "",
    css: "",
    custom: "",
    type: "number"
  },
  {
    id: uuidv4(),
    label: "Digital Coupons",
    accessor: "digitalVendorCouponAmount",
    aggregate: "",
    alias: "",
    css: "",
    custom: "",
    type: "money"
  },
  {
    id: uuidv4(),
    label: "Passive Discounts",
    accessor: "passiveItemDiscountAmount",
    aggregate: "",
    alias: "",
    css: "",
    custom: "",
    type: "money"
  },
  {
    id: uuidv4(),
    label: "Transaction Finder",
    accessor: "transfinder",
    aggregate: "",
    alias: "",
    css: "",
    custom: "",
    type: "bulk"
  },
  {
    id: uuidv4(),
    label: "Raw SQL",
    accessor: "raw",
    aggregate: "",
    alias: "",
    css: "",
    custom: "",
    type: "raw"
  }
];

const StartQuery = (props) => {
  const [editQuery, setEditQuery] = useState({});
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const context = useContext(ContextConfig);

  useEffect(() => {
    const queryId = qs.parse(props.location.search);
    if (queryId) {
      if (Object.keys(queryId).length > 0) {
        if (queryId["?id"].length > 0) {
          const id = queryId["?id"];
          getReport(id);
        }
      }
    }

    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);

    findDivHeight();

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, []);

  const findDivHeight = () => {
    const navbar = document
      .getElementById("master-navbar")
      .getBoundingClientRect().height;
    const usedSpace = navbar;
    const remaining = window.innerHeight - usedSpace;

    const body = document.getElementById("body");
    body.style.height = remaining + "px";
    body.style.overflow = "auto";
  };

  const getReport = (id) => {
    getReportList(context.url, context.basketApi, id)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setEditQuery(j.reports[0]);
        } else {
          setEditQuery({});
          toast.warn(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setEditQuery({});
        toast.error("An Internal error occured", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  return (
    <div>
      <HeaderRB />
      <div id="body">
        <QueryBuilder data={data} editQuery={editQuery} />
      </div>
    </div>
  );
};

StartQuery.propTypes = {
  location: PropTypes.object
};

export default StartQuery;
