import React, { useState, useEffect, useContext } from "react";
import ClockDesktopMenu from "./ClockDesktopMenu";
import { ContextConfig } from "../App";
import OpenPunches from "./widgets/OpenPunches";
import Header from "./widgets/Header";
import HoursTable from "./widgets/HoursTable";
import FloatingSidebar from "../Dashboard/FloatingSidebar";
import ApproachingFourty from "./widgets/ApproachingFourty";
import Requests from "./widgets/Requests";
import ResetDialog from "./Login/ResetDialog";
import { encrypt } from "../utils";
import Spinner from "../common/Spinner";
import { toast } from "react-toastify";
import Axios from "axios";
import qs from "qs";
import UserDash from "./DesktopTimeclock/UserDash";
import MissingBreaks from "./widgets/MissingBreaks";
import MissingLunches from "./widgets/MissingLunches";
import { debounce } from "../fp";
import SecurityDialog from "./Login/SecurityDialog";
import { withRouter } from "react-router";
import "./ClockDesktop.css";

const ClockDesktop = () => {
  const [reRender, setReRender] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showReset, setShowReset] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [showSecurity, setShowSecurity] = useState(false);
  const [answer, setAnswer] = useState("");
  const [question, setQuestion] = useState(0);
  const [securityAlreadyShown, setSecurityAlreadyShown] = useState(false);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const context = useContext(ContextConfig);

  useEffect(() => {
    if (context.resetPassword.toString() == "1") {
      setShowReset(true);
    }

    getSecurityQuestions();

    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);

    findDivHeight();

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, [reRender, showReset, showSecurity]);

  useEffect(() => {
    if (
      questions.length > 0 &&
      context.securityQuestion == "0" &&
      !securityAlreadyShown
    ) {
      setShowSecurity(true);
      setSecurityAlreadyShown(true);
    }
  }, [questions]);

  const findDivHeight = () => {
    if (context.userLevel < 5) return;
    const navbar = document
      .getElementById("master-navbar")
      .getBoundingClientRect().height;

    let controlsHeight = 0;

    const controls = document.getElementById("controls");

    if (typeof controls !== "undefined" && controls !== null) {
      controlsHeight = controls.getBoundingClientRect().height;
    }

    const usedSpace = navbar + controlsHeight + 100;
    const remaining = window.innerHeight - usedSpace;

    const widget = document.getElementById("dash");
    widget.style.height = remaining + "px";
    widget.style.overflowY = "auto";
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const hide = () => {
    context.resetPassword = "0";
    setShowReset(false);
  };

  const getSecurityQuestions = () => {
    Axios({
      method: "GET",
      cors: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + context.clockToken
      },
      url: context.clockUrl + "users/question"
    })
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setQuestions(j.questions);
        } else {
          toast.warning(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An error occured getting the security quesitons", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const resetPassword = (password) => {
    setIsLoading(true);
    const encryptedPassword = encrypt(password, context.key).toString();
    Axios({
      method: "POST",
      cors: true,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + context.clockToken
      },
      url: context.clockUrl + "users/resetpasswordsafe",
      data: qs.stringify({
        userid: context.userid,
        newpassword: encryptedPassword
      })
    })
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          context.resetPassword = "0";
          setShowReset(false);
          toast.success("Your password has been reset", {
            position: toast.POSITION.TOP_LEFT
          });
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const setSecurityAnswer = () => {
    Axios({
      method: "POST",
      cors: true,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + context.clockToken
      },
      url: context.clockUrl + "users/questionanswer",
      data: qs.stringify({
        userid: context.userid,
        questionId: question,
        answer
      })
    })
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setShowSecurity(false);
        } else {
          toast.warning(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An error occured validating your Security Answer", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const hideSecurityQuestion = () => {
    setShowSecurity(false);
  };

  const handleAnswerQuestion = () => {
    setSecurityAnswer();
  };

  const handleQuestionChange = (e) => {
    setQuestion(e.target.value);
  };

  return (
    <div className="clock-wrapper">
      {isLoading && <Spinner />}
      {showSecurity && (
        <SecurityDialog
          isShowing={showSecurity}
          hide={hideSecurityQuestion}
          questions={questions}
          answer={answer}
          setAnswer={setAnswer}
          question={question}
          setQuestion={handleQuestionChange}
          handleAnswerQuestion={handleAnswerQuestion}
        />
      )}
      {context.resetPassword.toString() == "1" && (
        <ResetDialog
          isShowing={showReset}
          hide={hide}
          newPassword={newPassword}
          handleNewPasswordChange={handleNewPasswordChange}
          confirmPassword={confirmPassword}
          handleConfirmNewPasswordChange={handleConfirmPasswordChange}
          handleResetClick={resetPassword}
          context={context}
        />
      )}
      <ClockDesktopMenu />

      {context.userLevel < 5 ? (
        <React.Fragment>
          <UserDash />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <FloatingSidebar />
          <div className="row" id="controls">
            <div className="col">
              <div className="d-clock-desktop-title">TimeClock Dashboard</div>
            </div>
          </div>
          <Header reRender={reRender} setReRender={setReRender} />

          <div className="container mt-4 mb-4" id="dash">
            <ApproachingFourty />
            <OpenPunches />
            <MissingBreaks />
            <MissingLunches />
            <Requests />
            <HoursTable />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default withRouter(ClockDesktop);
