import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import "./Header.css";
import { BrowserView, MobileView, isBrowser } from "react-device-detect";
import MobileHeader from "./MobileHeader";
import DesktopHeader from "./DesktopHeader";
import { ContextConfig } from "../App";
import { loadGroupsForPicker, loadStores } from "./Header-I";
import { savePrefs } from "../api/GlobalApi";
import { getCurrentState } from "../utils";

const pickerData = [
  { id: 1, name: "Stores" },
  { id: 2, name: "Group" }
];

const Header = ({
  setShowGroups,
  setShowDates,
  reRender,
  setReRender,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  setLoading,
  showSingleStore
}) => {
  const [groups, setGroups] = useState([]);
  const [group, setGroup] = useState([]);
  const [assignedStores, setAssignedStores] = useState([]);
  const [showSelection, setShowSelection] = useState(false);
  const [showDate, setShowDate] = useState(false);
  const context = useContext(ContextConfig);

  useEffect(() => {
    loadGroupsForPicker(setLoading, context, setGroups, setShowGroups);
    loadStores(setLoading, context, setAssignedStores);
    // dynamically control picker data
    if (isBrowser && pickerData.length === 2 && showSingleStore) {
      pickerData.push({ id: 3, name: "Single Store" });
    } else if (isBrowser && pickerData.length == 3 && !showSingleStore) {
      pickerData.pop();
    }
    getCurrentState(
      context,
      setShowGroups,
      setShowSelection,
      setStartDate,
      setEndDate
    );
    savePrefs(
      context.url,
      context.token,
      context.lastStoreid,
      context.lastGroup,
      context.lastSearchType,
      context.lastGroupName,
      prefsSuccess,
      prefsFailure
    );
    if (!context.hasOwnProperty("endDate")) {
      setShowDate(true);
      return;
    }
  }, [reRender]);

  const prefsSuccess = () => {};

  const prefsFailure = (err) => {
    console.log(err);
  };

  const handleDateChange = () => {
    setShowDates(true);
  };

  return (
    <React.Fragment>
      <BrowserView>
        <DesktopHeader
          startDate={startDate}
          endDate={endDate}
          handleDateChange={handleDateChange}
          pickerData={pickerData}
          groupData={groups}
          reRender={reRender}
          setReRender={setReRender}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          setGroup={setGroup}
          group={group}
          assignedStores={assignedStores}
          lastSearchType={parseInt(context.lastSearchType)}
        />
      </BrowserView>
      <MobileView>
        <MobileHeader
          endDate={endDate}
          pickerData={pickerData}
          groups={groups}
          reRender={reRender}
          setReRender={setReRender}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          showSelection={showSelection}
          groupData={groups}
          promptDate={showDate}
        />
      </MobileView>
    </React.Fragment>
  );
};

Header.propTypes = {
  setShowGroups: PropTypes.func,
  handleShowSelection: PropTypes.func,
  setShowSelection: PropTypes.func,
  setShowDates: PropTypes.func,
  groups: PropTypes.array,
  reRender: PropTypes.bool,
  setReRender: PropTypes.func,
  startDate: PropTypes.any,
  endDate: PropTypes.any,
  setStartDate: PropTypes.func,
  setEndDate: PropTypes.func,
  setGroup: PropTypes.func,
  setLoading: PropTypes.func,
  showSingleStore: PropTypes.bool
};

export default Header;
