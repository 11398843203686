import React, { useContext, useEffect } from "react";
import { ContextConfig } from "../App";
import { useSelector, useDispatch } from "react-redux";

import { toast } from "react-toastify";
import * as actions from "../actions/actionTypes";
import FreshopCard from "./FreshopCard";

import { Unclosed } from "./Freshop-I";

const UnclosedOrders = () => {
  const freshopState = useSelector((app) => app.freshopReducer);
  const dispatch = useDispatch();

  const context = useContext(ContextConfig);

  useEffect(() => {
    getOrders();
    if (freshopState.openOrderError != null) {
      toast.warning(freshopState.openOrderError, {
        position: toast.POSITION.TOP_LEFT
      });

      dispatch({ type: actions.FRESHOP_SET_OPEN_ORDER_ERROR, error: null });
    }
  }, [
    freshopState.selectedStore.id,
    freshopState.startDate,
    freshopState.endDate,
    freshopState.openOrderError
  ]);

  const getOrders = () => {
    Unclosed(
      context.freshopApi,
      freshopState.selectedStore.id,
      freshopState.startDate,
      freshopState.endDate,
      context.freshopAppKey,
      dispatch
    );
  };

  return (
    <FreshopCard
      headerText={"Unclosed Orders"}
      count={freshopState.unclosedOrders.length}
      state={freshopState}
      handler={"showOpenOrders"}
      actionType={"SHOW_OPEN_ORDERS"}
    />
  );
};

export default UnclosedOrders;
