import axios from "axios";
import { formatDate } from "../utils";
import qs from "qs";

async function getBatches(url, token, storeid) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "eod/batches",
    params: {
      storeid
    }
  });
  return json;
}

async function closeBatch(url, token, storeid, closeDate, forced) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "eod/close",
    params: {
      storeid,
      closeDate,
      forced: forced ? 1 : 0
    }
  });
  return json;
}

async function checkEodForOpenPunches(url, token, storeid, startdate, enddate) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "eod/check",
    params: {
      storeid,
      startdate: formatDate(startdate),
      enddate: formatDate(enddate)
    }
  });
  return json;
}

async function getTc08(url, token, storeid, startdate, enddate) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "eod/tc08",
    params: {
      storeid,
      startdate: formatDate(startdate),
      enddate: formatDate(enddate)
    }
  });
  return json;
}

async function getConsents(url, token, storeid) {
  let json = await axios({
    method: "GET",
    cors: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "eod/consent",
    params: {
      storeid
    }
  });

  return json;
}

async function createConsent(url, token, id, userid, password) {
  let json = await axios({
    method: "POST",
    cors: true,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: "Bearer " + token
    },
    url: url + "eod/completeconsent",
    data: qs.stringify({
      id,
      userid,
      password
    })
  });

  return json;
}

export {
  getBatches,
  closeBatch,
  checkEodForOpenPunches,
  getTc08,
  getConsents,
  createConsent
};
