import React, { useState } from "react";
import { Button } from "reactstrap";
import Switch from "react-bootstrap-switch";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

const FixedPlugin = (props) => {
  const [darkmode, setDarkmode] = useState(true);
  const [classes, setClasses] = useState("dropdown");

  const handleClick = () => {
    if (classes === "dropdown") {
      setClasses("dropdown show");
      console.log("class set to dropdown show");
    } else {
      setClasses("dropdown");
      console.log("class set to dropdown");
    }
  };

  const handleActiveMode = () => {
    setDarkmode(!darkmode);
    document.body.classList.toggle("white-content");
  };

  const handleForgetMe = () => {
    localStorage.removeItem("rUsername");
    localStorage.removeItem("rPassword");
    props.history.push("/");
  };

  return (
    <div className="fixed-plugin" style={{ padding: "10px" }}>
      <div className={classes}>
        <a
          href="#mikto"
          onClick={(e) => {
            e.preventDefault();
            handleClick();
          }}
        >
          <i className="fa fa-cog fa-2x" />
        </a>
        <ul className="dropdown-menu show">
          <li className="header-title">SIDEBAR BACKGROUND</li>
          <li className="adjustments-line">
            <div className="badge-colors text-center">
              <span
                className={
                  props.activeColor === "primary"
                    ? "badge filter badge-primary active"
                    : "badge filter badge-primary"
                }
                data-color="primary"
                onClick={() => {
                  props.handleActiveClick("primary");
                }}
              />
              <span
                className={
                  props.activeColor === "blue"
                    ? "badge filter badge-info active"
                    : "badge filter badge-info"
                }
                data-color="info"
                onClick={() => {
                  props.handleActiveClick("blue");
                }}
              />
              <span
                className={
                  props.activeColor === "green"
                    ? "badge filter badge-success active"
                    : "badge filter badge-success"
                }
                data-color="success"
                onClick={() => {
                  props.handleActiveClick("green");
                }}
              />
              <span
                className={
                  props.activeColor === "orange"
                    ? "badge filter badge-warning active"
                    : "badge filter badge-warning"
                }
                data-color="warning"
                onClick={() => {
                  props.handleActiveClick("orange");
                }}
              />
              <span
                className={
                  props.activeColor === "red"
                    ? "badge filter badge-danger"
                    : "badge filter badge-danger"
                }
                data-color="danger"
                onClick={() => {
                  props.handleActiveClick("red");
                }}
              />
            </div>
          </li>
          <li className="adjustments-line">
            <div className="togglebutton switch-change-color mt-3">
              <span className="label-switch">LIGHT MODE</span>
              <Switch
                onChange={handleActiveMode}
                value={darkmode}
                onText=""
                offText=""
              />
              <span className="label-switch">DARK MODE</span>
            </div>
          </li>
          <li
            className="button-container"
            style={{ marginTop: "5px", marginBottom: "5px" }}
          >
            <Button
              color="default"
              block
              className="btn-round"
              outline
              onClick={handleForgetMe}
              style={{ width: "100%" }}
            >
              Forget Me
            </Button>
          </li>
          <li className="button-container" style={{ marginBottom: "10px" }}>
            <Button
              color="default"
              block
              className="btn-round"
              outline
              href="http://www.miktosolutions.com"
              target="_blank"
            >
              <i className="nc-icon nc-paper" /> MikTo Solutions
            </Button>
          </li>
        </ul>
      </div>
    </div>
  );
};

FixedPlugin.propTypes = {
  handleActiveClick: PropTypes.func,
  activeColor: PropTypes.string,
  history: PropTypes.object
};

export default withRouter(FixedPlugin);
