import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table
} from "reactstrap";
import PropTypes from "prop-types";
import { readGroups, deleteGroup } from "../api/GroupApi";
import { ContextConfig } from "../App";
import { toast } from "react-toastify";

const DeleteGroup = ({ state, deleteCallback }) => {
  const [modal, setModal] = useState(false);
  const [groups, setGroups] = useState([]);
  const [nestedModal, setNestedModal] = useState(false);
  const [closeAll, setCloseAll] = useState(false);
  const [id, setId] = useState(0);
  const context = useContext(ContextConfig);

  useEffect(() => {
    setModal(state);

    readGroups(
      context.url,
      context.token,
      response => {
        var j = response.data;
        if (j.error === 0) {
          setGroups(j.groups);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      },
      err => {
        console.log(err);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      }
    );
  }, [context.url, context.token, state]);

  const handleDelete = id => {
    setId(id);
    setNestedModal(true);
  };

  const toggleNested = () => {
    // here we are confirming that the user really wants to delete this group
    const config = {
      url: context.url,
      token: context.token,
      id: id
    };

    deleteGroup(
      config,
      response => {
        const j = response.data;
        if (j.error === 0) {
          toast.success("Your Group has been deleted", {
            position: toast.POSITION.TOP_LEFT
          });
          setNestedModal(false);
          setModal(false);
          deleteCallback();
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      },
      err => {
        console.log(err);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      }
    );
    setNestedModal(!nestedModal);
    setCloseAll(false);
  };

  const toggleAll = () => {
    setNestedModal(!nestedModal);
  };

  const toggle = () => setModal(!modal);
  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Delete a Group</ModalHeader>
        <ModalBody>
          <Table dark>
            <thead>
              <tr>
                <th>Id</th>
                <th>Group</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {groups.length === 0 ? (
                <div>There are no records to display</div>
              ) : (
                groups.map(group => (
                  <tr key={group.id}>
                    <td>{group.id}</td>
                    <td>{group.group_name}</td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-outline-danger"
                        onClick={() => handleDelete(group.id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
          <Modal
            isOpen={nestedModal}
            toggle={toggleNested}
            onClosed={closeAll ? toggle : undefined}
          >
            <ModalHeader>Confirm Delete</ModalHeader>
            <ModalBody>Are you sure you want to delete group {id}</ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={toggleNested}>
                Confirm Delete
              </Button>
              <Button color="secondary" onClick={toggleAll}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

DeleteGroup.propTypes = {
  state: PropTypes.bool,
  deleteCallback: PropTypes.func
};

export default DeleteGroup;
