import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import { ContextConfig } from "../../App";
import { isValid, usePrevious } from "../../utils";
import { isEqual } from "../../fp";
import { getUserClockStores, setLastStoreid } from "../../api/StoreApi";
import { useSelector, useDispatch } from "react-redux";
import { variables } from "../../common/variables";
import { toast } from "react-toastify";
import * as actions from "../../actions/actionTypes";

const SingleHeader = () => {
  const state = useSelector((app) => app.appReducer);
  const dispatch = useDispatch();

  const context = useContext(ContextConfig);
  const previousStores = usePrevious(state.stores);

  useEffect(() => {
    if (!isEqual(previousStores, state.stores)) {
      loadStores();
    }
    if (isValid(context.lastClockStore)) {
      dispatch({ type: actions.APP_SET_STORE, store: context.lastClockStore });
    }
    context.singleDay = state.endDate;
  }, [state.stores]);

  const loadStores = () => {
    getUserClockStores(context.clockUrl, context.clockToken)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          dispatch({ type: actions.APP_SET_STORES, stores: j.stores });
        } else {
          console.log(j.msg);
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
          dispatch({ type: actions.APP_SET_STORES, stores: [] });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: actions.APP_SET_STORES, stores: [] });
        toast.error("An Internal error occured", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const handleEndDateChange = (e) => {
    context.singleDay = e;
    dispatch({ type: actions.APP_SET_END_DATE, endDate: e });
    dispatch({ type: actions.APP_SET_RERENDER });
  };

  const handleStoreChange = (e) => {
    const storeid = e.target.value;
    context.lastClockStore = storeid;
    context.weekStartLoaded = false;
    dispatch({ type: actions.APP_SET_STORE, store: storeid });
    dispatch({ type: actions.APP_SET_RERENDER });
    setLastStoreid(context.clockUrl, context.clockToken, storeid)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          console.log(storeid + " saved as last storeid");
        } else {
          console.log(j.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //{/* <div className="d=clock-header px-5 mt-2"> */}

  return (
    <div style={variables.desktopHeader}>
      <div className="row">
        <div className="col align-items-center justify-content-center d-flex mt-1">
          End:&nbsp;
          <DatePicker selected={state.endDate} onChange={handleEndDateChange} />
        </div>
        <div className="col align-items-center justify-content-center d-flex mt-1">
          <label className="px-1" htmlFor="storeselector">
            Store:
          </label>
          <select
            className="form-control"
            value={state.store}
            onChange={handleStoreChange}
            style={{ width: "200px" }}
          >
            {state.stores.length === 0 ? (
              <option key={`s-s-0`} value="0">
                No Stores to Select
              </option>
            ) : (
              <React.Fragment>
                <option key={`s-1-0`} value="0">
                  All Stores
                </option>
                {state.stores.map((record, index) => (
                  <option key={`ss-${index}`} value={record.id}>
                    {record.storeName}
                  </option>
                ))}
              </React.Fragment>
            )}
          </select>
        </div>
      </div>
    </div>
  );
};

SingleHeader.propTypes = {
  setReRender: PropTypes.func,
  reRender: PropTypes.bool,
  tempStartDate: PropTypes.string
};

export default SingleHeader;
