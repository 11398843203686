import React from "react";
import PropTypes from "prop-types";
import { FaArrowCircleUp } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import "./ScrollDivToTop.css";

const ScrollDivToTop = ({ id }) => {
  //const [showScroll, setShowScroll] = useState(true);

  //   const checkScrollTop = () => {
  //     if (!showScroll && window.pageYOffset > 400) {
  //       setShowScroll(true);
  //     } else if (showScroll && window.pageYOffset <= 400) {
  //       setShowScroll(false);
  //     }
  //   };

  const scrollTop = () => {
    const div = document.getElementById(id);
    div.scrollTo({ top: 0, behavior: "smooth" });
  };

  //window.addEventListener("scroll", checkScrollTop);

  return (
    <React.Fragment>
      <ReactTooltip />
      <FaArrowCircleUp
        id={id}
        className="scrollDivTop"
        onClick={scrollTop}
        style={{ height: 40, display: "flex" }}
        data-tip="Scroll to Top"
      />
    </React.Fragment>
  );
};

ScrollDivToTop.propTypes = {
  id: PropTypes.any
};

export default ScrollDivToTop;
