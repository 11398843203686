import React, { useState, useEffect, useContext } from "react";
import HeaderRB from "../../common/HeaderRb";
import { toast } from "react-toastify";
import { isEqual } from "lodash";
import {
  usePrevious,
  formatDate,
  isValidDate,
  handleCsv,
  addDays
} from "../../utils";
import { ContextConfig } from "../../App";
import { readOperatorMultiDesktop } from "../../api/ReportsApi";
import OperatorReportData from "./OperatorReportData";
import Spinner from "../../common/Spinner";
import { debounce } from "../../fp";

const dOperatorMulti = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const context = useContext(ContextConfig);

  const previousData = usePrevious(data);

  let uniqueIds = [];

  useEffect(() => {
    if (!isEqual(previousData, data)) {
      loadData();
    }

    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);

    findDivHeight();

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, [data]);

  const findDivHeight = () => {
    const navbar = document
      .getElementById("master-navbar")
      .getBoundingClientRect().height;
    const controls = document
      .getElementById("r-controls")
      .getBoundingClientRect().height;
    const usedSpace = navbar + controls + 30;
    const remaining = window.innerHeight - usedSpace;

    const body = document.getElementById("r-body");
    body.style.height = remaining + "px";
    body.style.overflow = "auto";
  };

  const loadData = () => {
    setIsLoading(true);
    if (!isValidDate(context.startDate)) {
      context.startDate = addDays(new Date(), -7);
    }
    readOperatorMultiDesktop(
      context.url,
      context.token,
      context.startDate,
      context.endDate,
      context.lastStoreid
    )
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          setData(j.items);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const handlePrint = () => {
    print(document.getElementById("multi").innerHTML);
  };

  return (
    <React.Fragment>
      {isLoading && <Spinner />}
      <HeaderRB />
      <div className="container-fluid" id="multi">
        <div id="r-controls">
          <div className="rpt-name">Operator Multi</div>
          <div className="export-report">
            <span className="print-report" onClick={handlePrint}>
              <i className="far fa-print"></i>
            </span>
            <span
              className="csv-report"
              onClick={() => handleCsv(data, "Smulti.csv")}
            >
              <i className="far fa-save"></i>
            </span>
          </div>

          <div className="d-report-info">
            <div className="d-report-storename">
              <div className="d-report=date">{context.reportStore.name}</div>
              <span>Start: {formatDate(context.startDate)}</span>
              <span>&nbsp;</span>
              <span>End: {formatDate(context.endDate)}</span>
            </div>
          </div>
        </div>
        <div id="r-body">
          <React.Fragment>
            {data.length === 0 ? (
              <div>No records were returned</div>
            ) : (
              data
                .filter((i) => {
                  if (!uniqueIds.includes(i.num)) {
                    uniqueIds.push(i.num);
                    return { id: i.num, name: i.name };
                  }
                })
                .map((operator, index) => (
                  <OperatorReportData
                    key={index}
                    data={data}
                    operator={operator}
                    reportDate={context.endDate}
                  />
                ))
            )}
          </React.Fragment>
        </div>
      </div>
    </React.Fragment>
  );
};

export default dOperatorMulti;
