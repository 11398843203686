import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import TimeBars from "./Upper/TimeBars";
import "../Time.css";
import { debounce } from "../../fp";
import Spinner from "../../common/Spinner";
import StorePunchList from "./Lower/StorePunchList";
import EmployeePunches from "./Lower/EmployeePunches";

const DesktopTime = ({ punchList, timeDays }) => {
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });
  const [isLoading, setIsLoading] = useState(false);
  const [cashier, setCashier] = useState({});
  const [reload, setReload] = useState(false);

  useEffect(() => {
    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);
    findDivHeight();
    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, [reload]);

  const findDivHeight = () => {
    const div = document.getElementById("d-sales-lower");
    div.style.height = window.innerHeight - 470 + "px";
    div.style.display = "grid";
  };

  const handleCashierClick = (record) => {
    setCashier(record);
    setReload(!reload);
  };

  return (
    <React.Fragment>
      {!isLoading ? null : <Spinner />}
      <div className="container-fluid d-sales">
        <div className="d-sales-upper">
          <div>
            <TimeBars timeDays={timeDays} />
          </div>
        </div>
        <div
          className="time-lower"
          id="d-sales-lower"
          style={{ marginTop: "50px" }}
        >
          <div className="time-lower-left">
            {punchList.length === 0 ? (
              <div>No records were returned</div>
            ) : (
              <StorePunchList
                punchList={punchList}
                handleCashierClick={handleCashierClick}
                selectedCashierRow={cashier.rownumber}
              />
            )}
          </div>
          <div className="time-lower-right">
            <EmployeePunches cashier={cashier} setIsLoading={setIsLoading} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

DesktopTime.propTypes = {
  punchList: PropTypes.array,
  timeDays: PropTypes.array
};

export default DesktopTime;
