import React from "react";
import "./Timebox.css";
import PropTypes from "prop-types";
import { formatDate, getDayOfWeek } from "../../../utils";

const TimeBox = ({ record }) => {
  return (
    <div className="col time-box">
      <div className="time-box-inner1">
        <div className="time-box-inner2">
          <div className="time-box-inner3">
            <div className="time-box-title">{getDayOfWeek(record.f254)}</div>
            <div className="time-box-hours">
              {parseFloat(record.totalHours).toFixed(1)}
            </div>
            <div className="time-box-footer">
              {record.f254 == "total" ? (
                <span>Total Week Hours</span>
              ) : (
                <span>{formatDate(record.f254)}</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

TimeBox.propTypes = {
  record: PropTypes.any
};

export default TimeBox;
