import axios from "axios";
import { formatDate } from "../utils";
import qs from "qs";

async function getMetrics(url, userid, startdate, enddate, appkey) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
    url: url + "web",
    params: {
      userid,
      startdate: formatDate(startdate),
      enddate: formatDate(enddate),
      appkey
    }
  });
  return json;
}

async function getLinkedStores(url, userid, company, appkey) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
    url: url + "web/linked",
    params: {
      userid,
      company,
      appkey
    }
  });
  return json;
}

async function link(url, userid, storeid, remove, appkey) {
  let json = await axios({
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    url: `${url}web/link?appkey=${appkey}`,
    data: qs.stringify({
      userid,
      storeid,
      remove
    })
  });
  return json;
}

async function copylinks(url, sourceUserid, destUserid, appkey) {
  let json = await axios({
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    url: `${url}web/copylinks?appkey=${appkey}`,
    data: qs.stringify({
      sourceUserid,
      destUserid
    })
  });
  return json;
}

async function getUnclosedOrders(url, storeid, startdate, enddate, appkey) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
    url: url + "widgets/unclosed",
    params: {
      storeid,
      startdate: formatDate(startdate),
      enddate: formatDate(enddate),
      appkey
    }
  });
  return json;
}

async function getPriceMods(url, storeid, startdate, enddate, appkey) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
    url: url + "widgets/mods",
    params: {
      storeid,
      startdate: formatDate(startdate),
      enddate: formatDate(enddate),
      appkey
    }
  });
  return json;
}

async function getTrueUpErrors(url, storeid, startdate, enddate, appkey) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
    url: url + "widgets/trueup",
    params: {
      storeid,
      startdate: formatDate(startdate),
      enddate: formatDate(enddate),
      appkey
    }
  });
  return json;
}

async function getItemErrors(url, storeid, startdate, enddate, appkey) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
    url: url + "widgets/items",
    params: {
      storeid,
      startdate: formatDate(startdate),
      enddate: formatDate(enddate),
      appkey
    }
  });
  return json;
}

async function savePreferences(url, storeid, userid, appkey) {
  let json = await axios({
    method: "POST",
    cors: true,
    headers: {
      "Content-Type": "application/json"
    },
    url: url + "web/saveprefs",
    params: {
      userid,
      storeid,
      appkey
    }
  });

  return json;
}

async function getPreferences(url, userid, appkey) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
    url: url + "web/getprefs",
    params: {
      userid,
      appkey
    }
  });

  return json;
}

async function getOrdersForWidget(url, storeid, startdate, enddate, appkey) {
  let json = await axios({
    metho: "GET",
    cors: true,
    headers: {
      "Content-Type": "application/json"
    },
    url: url + "widgets/orders",
    params: {
      storeid,
      startdate: formatDate(startdate),
      enddate: formatDate(enddate),
      appkey
    }
  });

  return json;
}

async function getPayload(url, orderid, appkey) {
  let json = await axios({
    method: "GET",
    cors: true,
    headers: {
      "Content-Type": "application/json"
    },
    url: url + "widgets/payload",
    params: {
      orderid,
      appkey
    }
  });

  return json;
}

async function setMetricsOrderby(url, userid, orderby, appkey) {
  let json = await axios({
    method: "POST",
    cors: true,
    headers: {
      "Content-Type": "application/json"
    },
    url: url + "web/metricsorder",
    params: {
      userid,
      orderby,
      appkey
    }
  });
  return json;
}

export {
  getMetrics,
  getLinkedStores,
  link,
  copylinks,
  getUnclosedOrders,
  getPriceMods,
  getItemErrors,
  savePreferences,
  getPreferences,
  getTrueUpErrors,
  getOrdersForWidget,
  getPayload,
  setMetricsOrderby
};
