import axios from "axios";
import qs from "qs";
import { formatDate } from "../utils";

async function openPunches(url, token, startDate, endDate) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "widgets/openpunchesbycompany",
    params: {
      startDate,
      endDate
    }
  });
  return json;
}

async function openPunchesByStore(url, token, startDate, endDate, storeid) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "widgets/openpunchesbystore",
    params: {
      storeid,
      startDate,
      endDate
    }
  });
  return json;
}

async function updatePunch(url, token, punch) {
  let json = await axios({
    method: "PUT",
    cors: true,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: "Bearer " + token
    },
    url: url + "punches/updatepunch",
    data: qs.stringify({
      punchType: punch.punchType,
      punchIn: punch.punchIn,
      punchOut: punch.punchOut,
      punchId: punch.punchId,
      comment: punch.comment,
      userid: punch.userid,
      total: punch.total,
      mode: punch.mode,
      timeZoneOffset: punch.timeZoneOffset,
      isDst: punch.isDst
    })
  });
  return json;
}

async function getHoursByCompany(url, token, startDate, endDate) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "widgets/hoursbycompany",
    params: {
      startDate,
      endDate
    }
  });
  return json;
}

async function getHoursByStore(url, token, storeid, startDate, endDate) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "widgets/hoursbystore",
    params: {
      storeid,
      startdate: formatDate(startDate),
      enddate: formatDate(endDate)
    }
  });
  return json;
}

async function getMissingBreaks(url, token, storeid, startdate, enddate) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "widgets/missingbreaks",
    params: {
      storeid,
      startdate: formatDate(startdate),
      enddate: formatDate(enddate)
    }
  });
  return json;
}

async function getMissingLunches(url, token, storeid, startdate, enddate) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "widgets/missinglunches",
    params: {
      storeid,
      startdate: formatDate(startdate),
      enddate: formatDate(enddate)
    }
  });
  return json;
}

async function readCashierRequestRealTime(
  url,
  token,
  storeid,
  daysback,
  showWaiting
) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "widgets/cashierhistory1",
    params: {
      storeid,
      daysback,
      onlyShowWaiting: showWaiting ? 1 : 0
    }
  });
  return json;
}

async function exceptions(
  url,
  token,
  storeid,
  startDate,
  endDate,
  searchType,
  threshold,
  cashier
) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "widgets/exeptionTotalizers",
    params: {
      storeid,
      startDate,
      endDate,
      threshold,
      searchType,
      cashier
    }
  });
  return json;
}

async function refundQty(
  url,
  token,
  storeid,
  searchType,
  startDate,
  endDate,
  threshold,
  cashier,
  totalizer
) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "widgets/refund",
    params: {
      storeid,
      startDate,
      endDate,
      threshold,
      searchType,
      cashier,
      totalizer
    }
  });
  return json;
}

async function storeClerks(url, token, storeid, startDate, endDate) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "widgets/clerks1",
    params: {
      storeid,
      startDate,
      endDate
    }
  });
  return json;
}

async function getKpi(url, token, storeid, startDate, endDate) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "widgets/kpi",
    params: {
      storeid,
      startdate: formatDate(startDate),
      enddate: formatDate(endDate)
    }
  });
  return json;
}

async function charges(
  url,
  token,
  storeid,
  startDate,
  endDate,
  searchType,
  customer,
  totalizer,
  start,
  end
) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "widgets/charges",
    params: {
      storeid,
      startDate,
      endDate,
      searchType,
      customer,
      totalizer,
      start,
      end
    }
  });
  return json;
}

async function getTransactions(
  url,
  token,
  storeid,
  startDate,
  endDate,
  cashier,
  totalizer,
  inclusionList,
  inclusionType
) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "widgets/transactions",
    params: {
      storeid,
      startDate,
      endDate,
      cashier,
      totalizer,
      inclusionList,
      inclusionType
    }
  });
  return json;
}

async function filterTransactions(
  url,
  token,
  trans,
  inclusionList,
  inclusionType
) {
  let json = await axios({
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: "Bearer " + token
    },
    url:
      url +
      "widgets/filter?inclusionList=" +
      inclusionList +
      "&inclusionType=" +
      inclusionType,
    data: qs.stringify({
      trans
    })
  });
  return json;
}

async function boxPlot(url, token, storeid, startdate, enddate, totalizer) {
  let json = await axios({
    method: "GET",
    cors: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "analysis/boxplot",
    params: {
      storeid,
      startdate,
      enddate,
      totalizer
    }
  });

  return json;
}

export {
  openPunches,
  updatePunch,
  openPunchesByStore,
  getHoursByCompany,
  getHoursByStore,
  getMissingBreaks,
  getMissingLunches,
  readCashierRequestRealTime,
  refundQty,
  storeClerks,
  charges,
  exceptions,
  getTransactions,
  filterTransactions,
  getKpi,
  boxPlot
};
