import React, { useState, useEffect, useContext } from "react";
import HeaderRB from "../../common/HeaderRb";
import { ContextConfig } from "../../App";
import { toast } from "react-toastify";
import { getTransactions } from "../../api/CashierApi";
import { BrowserView, MobileView, isBrowser } from "react-device-detect";
import { isEqual } from "lodash";
import { usePrevious } from "../../utils";
import MobileTransactions from "./MobileTransactions";
import DesktopTransactions from "./DesktopTransactions";
import Spinner from "../../common/Spinner";

const Transactions = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);

  const context = useContext(ContextConfig);
  const previousTransactions = usePrevious(transactions);

  useEffect(() => {
    if (!isEqual(previousTransactions, transactions)) {
      loadTransactions();
    }
  }, [transactions]);

  const loadTransactions = () => {
    setIsLoading(true);
    let startDate = context.endDate;
    let endDate = context.endDate;
    if (isBrowser) {
      startDate = context.totalizer.post_Date;
      endDate = context.totalizer.post_Date;
    }
    getTransactions(
      context.url,
      context.token,
      context.selectedRecord.storeid,
      startDate,
      endDate,
      context.cashier.f1185,
      context.totalizer.f1034
    )
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          setTransactions(j.trans);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  return (
    <React.Fragment>
      {isLoading && <Spinner />}
      <HeaderRB />
      <div className="div">
        <React.Fragment>
          <BrowserView>
            <DesktopTransactions data={transactions} context={context} />
          </BrowserView>
          <MobileView>
            <MobileTransactions data={transactions} context={context} />
          </MobileView>
        </React.Fragment>
      </div>
    </React.Fragment>
  );
};

Transactions.propTypes = {};

export default Transactions;
