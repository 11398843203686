import React, { useState, useEffect, useContext, useRef } from "react";
import HeaderRB from "../common/HeaderRb";
import { fetchCustInfo, fetchCustLinks, fetchOverview } from "./PayServer-I";
import { toast } from "react-toastify";
import { ContextConfig } from "../App";
import { BrowserView, MobileView } from "react-device-detect";
import CustomerDesktop from "./CustomerDesktop";
import CustomerMobile from "./CustomerMobile";
import LinksDesktop from "./LinksDesktop";
import LinksMobile from "./LinksMobile";
import { usePrevious } from "../utils";
import PointOverviewMobile from "./PointOverviewMobile";
import PointOverviewDesktop from "./PointOverviewDesktop";
import Spinner from "../common/Spinner";
import { debounce } from "../fp";

const PayServer = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [customer, setCustomer] = useState("");
  const [links, setLinks] = useState([]);
  const [isPhone, setIsPhone] = useState(false);
  const [userInfo, setUserInfo] = useState([]);
  const [overview, setOverview] = useState([]);
  const [rerender, setRerender] = useState([]);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const context = useContext(ContextConfig);

  let previousCustomer = usePrevious(customer);

  const ref = useRef(null);

  useEffect(() => {
    ref.current.focus();
    // if our context already has a customer in it, then use this for our customer
    if (context.pslookup) {
      if (previousCustomer !== customer) {
        setCustomer(context.pslookup.customer || context.pslookup.phone);
        setRerender(true);
      }
    }
    if (rerender === true) {
      setRerender(false);
      submit();
    }

    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);

    findDivHeight();
    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, [userInfo, links, rerender]);

  const findDivHeight = () => {
    const navbar = document.getElementById("master-navbar");
    const form = document.getElementById("ps-form");
    const usedSpace =
      navbar.getBoundingClientRect().height +
      form.getBoundingClientRect().height;
    const remainingSpace = window.innerHeight - (usedSpace + 50);
    const psbody = document.getElementById("ps-body");
    psbody.style.height = remainingSpace + "px";
    psbody.style.overflow = "auto";
  };

  const handleCustomerSuccess = (response) => {
    setIsLoading(false);
    const j = response.data;
    if (j.error === 0) {
      // now we can display our data to the customer
      toast.success("Customer Found", {
        position: toast.POSITION.TOP_LEFT
      });
      setUserInfo(j.info);
    } else {
      toast.error(j.msg, {
        position: toast.POSITION.TOP_LEFT
      });
      setUserInfo([]);
    }
  };

  const handleCustomerFailure = () => {
    setIsLoading(false);
    setUserInfo([]);
    toast.error(
      "An error occured processing your request for customer lookup",
      {
        position: toast.POSITION.TOP_LEFT
      }
    );
  };

  const handleCustomerChange = (e) => {
    if (e.target.value.length === 10) {
      setIsPhone(true);
    }
    if (e.target.value.length > 10) {
      setIsPhone(false);
    }
    setCustomer(e.target.value);
  };

  const handleLinksSuccess = (response) => {
    setIsLoading(false);
    const j = response.data;
    if (j.error === 0) {
      setLinks(j.links);
      fetchOverview(context, { phone: j.links[0].f1577 })
        .then(handleOverViewSuccess)
        .catch(handleOverviewFailure);
    } else {
      toast.error(j.msg, {
        position: toast.POSITION.TOP_LEFT
      });
    }
  };

  const handleLinksFailure = () => {
    setIsLoading(false);
    toast.error("An error occured processing your request for customer links", {
      position: toast.POSITION.TOP_LEFT
    });
  };

  const handleOverViewSuccess = (response) => {
    const j = response.data;
    if (j.error === 0) {
      setOverview(j.overview);
    } else {
      toast.error(j.msg, {
        position: toast.POSITION.TOP_LEFT
      });
    }
  };

  const handleOverviewFailure = () => {
    toast.error("An error occured getting the points overview", {
      position: toast.POSITION.TOP_LEFT
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    submit();
  };

  const submit = () => {
    if (customer.length < 10) {
      toast.error("You must have at least 10 numbers", {
        position: toast.POSITION.TOP_LEFT
      });
      ref.current.focus();
      return;
    }
    setIsLoading(true);

    const config = {
      customer: customer.length == 10 ? null : customer,
      phone: customer.length == 10 ? customer : null
    };
    context.pslookup = config;

    fetchCustInfo(context, config)
      .then(handleCustomerSuccess)
      .catch(handleCustomerFailure);
    fetchCustLinks(context, config)
      .then(handleLinksSuccess)
      .catch(handleLinksFailure);
  };
  return (
    <React.Fragment>
      {isLoading && <Spinner />}
      <HeaderRB />
      <div className="container-fluid">
        <form onSubmit={handleSubmit} id="ps-form">
          <h3>Pay Server Lookup</h3>
          <div className="form-group">
            <label htmlFor="txtCustomer">Customer</label>
            <input
              type="number"
              className="form-control"
              id="txtCustomer"
              value={customer}
              onChange={handleCustomerChange}
              aria-describedby="customer"
              placeholder="Enter Customer #"
              ref={ref}
            />
          </div>
          <button type="submit" className="btn btn-outline-dark mt-2">
            Submit
          </button>
        </form>
        <div className="ps-body" id="ps-body">
          <div>
            <h3>Customer Info</h3>
            {userInfo.length === 0 ? (
              <div className="customer-not-found">Customer not found</div>
            ) : (
              <React.Fragment>
                <BrowserView>
                  <CustomerDesktop
                    userInfo={userInfo}
                    isPhone={isPhone}
                    links={links}
                  />
                </BrowserView>
                <MobileView>
                  <CustomerMobile
                    userInfo={userInfo}
                    isPhone={isPhone}
                    links={links}
                  />
                </MobileView>
              </React.Fragment>
            )}
          </div>
          <div>
            <h3>Customer Links</h3>
            {links.length === 0 ? (
              <div className="links-not-found">Customer Links not found</div>
            ) : (
              <React.Fragment>
                <BrowserView>
                  <LinksDesktop links={links} isPhone={isPhone} />
                </BrowserView>
                <MobileView>
                  <LinksMobile links={links} isPhone={isPhone} />
                </MobileView>
              </React.Fragment>
            )}
          </div>
          <div>
            <h3>Overview</h3>
            <BrowserView>
              <PointOverviewDesktop
                data={overview}
                customer={customer}
                links={links}
              />
            </BrowserView>
            <MobileView>
              <PointOverviewMobile
                data={overview}
                customer={customer}
                links={links}
              />
            </MobileView>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PayServer;
