import axios from "axios";

async function readEmpPunches(url, token, storeid, cashier, queryDate) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "time/emppunches",
    params: {
      storeid,
      cashier,
      queryDate
    }
  });
  return json;
}

async function readTotalEmpHours(
  url,
  token,
  startDate,
  endDate,
  type,
  groupid,
  storelist
) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "time/totalemphours",
    params: {
      startDate,
      endDate,
      type,
      groupid,
      storelist
    }
  });
  return json;
}

async function readEmpHours(
  url,
  token,
  type,
  groupid,
  storelist,
  startDate,
  endDate
) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "time/emphours",
    params: {
      type,
      groupid,
      storelist,
      startDate,
      endDate
    }
  });
  return json;
}

export { readEmpPunches, readTotalEmpHours, readEmpHours };
