import React from "react";
import ReactDOM from "react-dom";
import { Row, Col } from "reactstrap";

import * as actions from "../actions/actionTypes";

import "./PayloadModal.css";

const PriceModModal = ({ state, payload, dispatch, dimensions }) =>
  state.showOrderModal
    ? ReactDOM.createPortal(
        <React.Fragment>
          <div className="modal-login-overlay freshop-fade-in" />
          <div
            className="modal-login-wrapper freshop-fade-in"
            aria-modal
            aria-hidden
            tabIndex={-1}
            role="dialog"
          >
            <div
              className="modal-pricemod"
              style={{
                position: "absolute",
                top: `${dimensions.height / 2 - dimensions.height / 4}`,
                left: `${dimensions.width / 2 - 500}`,
                height: `${dimensions.height - dimensions.height / 4 - 100}`,
                width: `${dimensions.width - dimensions.width / 4 - 100}`
              }}
            >
              <div className="modal-pricemodheader">
                <span className="modal-pricemod-confirm-title">
                  order Payload
                </span>
                <span className="modal-pricemod-close-box">
                  <button
                    type="button"
                    className="modal-pricemod-close-button"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() =>
                      dispatch({
                        type: actions.FRESHOP_SHOW_ORDER_MODAL,
                        isShowing: false
                      })
                    }
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </span>
              </div>
              <div
                className="modal-pricemod-body"
                style={{ overflowY: "auto" }}
              >
                <Row className="payload-header-info">
                  <Col xs="4">Orderid: {payload.Orderid}</Col>
                  <Col xs="4">SMS Transaction #: {payload.TransNumber}</Col>
                  <Col xs="4" className="payload-customer">
                    {payload.CustomerNumber} - {payload.Customer}
                  </Col>
                </Row>
                <Row className="payload-header-sub">
                  <Col xs="4">Shopper Level: {payload.ShopperLevel}</Col>
                  <Col xs="4">Risk Level: {payload.RiskLevel}</Col>
                  <Col xs="4">Terminal: {payload.Terminal}</Col>
                </Row>

                <div
                  className="jusify-content-center"
                  style={{ marginTop: "10px" }}
                >
                  {payload.Items ? (
                    <React.Fragment>
                      {payload.Items.map((item, i) => (
                        <React.Fragment key={`foi=${i}`}>
                          <Row className="justify-content-center mt-2">
                            <Col
                              xs="2"
                              style={{ color: "#fff", fontSize: "1rem" }}
                            >
                              {item.Upc}
                            </Col>
                            <Col
                              xs="5"
                              style={{ color: "#fff", fontSize: "1rem" }}
                            >
                              {item.Description}
                            </Col>
                            <Col xs="3">
                              <span
                                style={{
                                  color: "#fff",
                                  fontSize: "1.1rem",
                                  marginRight: "10px",
                                  textAlign: "right"
                                }}
                              >
                                {item.Description.toUpperCase() !=
                                "FRESHOP OFFLINE" ? (
                                  <span>Sold for: </span>
                                ) : null}
                                {parseFloat(item.ItemTotal).toFixed(2)}
                              </span>
                              {item.Description.toUpperCase() !=
                              "FRESHOP OFFLINE" ? (
                                <div>
                                  Active:
                                  {item.RetailSplit}@
                                  {parseFloat(item.Retail).toFixed(2)}
                                </div>
                              ) : null}
                            </Col>
                          </Row>
                          <Row lassName="justify-content-center">
                            <Col xs="12">
                              <div
                                style={{ width: "100%", textAlign: "center" }}
                              >
                                T1: {item.Tax1} T2: {item.Tax2} T3: {item.Tax3}{" "}
                                T4: {item.Tax4} T5: {item.Tax5} T6: {item.Tax6}{" "}
                                FS: {item.Fs} Scalable: {item.Scalable} Wic:{" "}
                                {item.Wic} Markdown: {item.Markdown} Points
                                Redeemed: {item.PointsRedeemed} Discount from
                                Points:{" "}
                                {parseFloat(item.DiscountFromPoints).toFixed(2)}{" "}
                                Tax: {payload.Tax} Sub: {item.SubDepartment}{" "}
                                Weight: {parseFloat(item.Weight).toFixed(3)}
                              </div>
                            </Col>
                          </Row>
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ) : null}
                </div>

                {payload.Totals ? (
                  <div style={{ marginTop: "20px" }}>
                    <div
                      style={{
                        fontSize: "1.2rem",
                        color: "#fff",
                        width: "100%",
                        textAlign: "center"
                      }}
                    >
                      Totals
                    </div>
                    {payload.Totals.map((total, i) => (
                      <Row key={`fot-${i}`} className="justify-content-center">
                        <Col xs="3">{total.Description}</Col>
                        <Col xs="3">{parseInt(total.Quantity)}</Col>
                        <Col xs="3">{parseFloat(total.Amount).toFixed(2)}</Col>
                        <Col xs="3">{parseFloat(total.Weight).toFixed(2)}</Col>
                      </Row>
                    ))}
                  </div>
                ) : null}

                {payload.Points ? (
                  <div style={{ marginTop: "20px" }}>
                    <div
                      style={{
                        fontSize: "1.2rem",
                        color: "#fff",
                        width: "100%",
                        textAlign: "center"
                      }}
                    >
                      Points
                    </div>
                    {payload.Points.map((point, i) => (
                      <Row key={`foip-${i}`} className="justify-content-center">
                        <Col xs="3">
                          ({point.Totalizer}) {point.Description}
                        </Col>
                        <Col xs="3">{parseInt(point.Qty)}</Col>
                        <Col xs="3">{parseFloat(point.Amount).toFixed(2)}</Col>
                        <Col xs="3">{parseFloat(point.Weight).toFixed(2)}</Col>
                      </Row>
                    ))}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </React.Fragment>,
        document.body
      )
    : null;

export default PriceModModal;
