import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { ContextConfig } from "../../App";
import { toast } from "react-toastify";
import { deleteDepartment, updateDepartment } from "../../api/departmentApi";
import ReactTooltip from "react-tooltip";
import ConfirmDelete from "../../common/ConfirmDelete";
import { isBrowser } from "react-device-detect";

const DepartmentList = (props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [recordToEdit, setRecordToEdit] = useState({});
  const [showDelete, setShowDelete] = useState(false);
  const [confirmText, setConfirmText] = useState("");
  const [error, setError] = useState("");
  const [recordToDelete, setRecordToDelete] = useState({});

  const context = useContext(ContextConfig);

  useEffect(() => {}, [isEditing]);

  const handleEdit = (record) => {
    setIsEditing(true);
    setRecordToEdit(record);
  };

  const handleDelete = (record) => {
    setRecordToDelete(record);
    setShowDelete(true);
  };

  const handleDepartmentChange = (e) => {
    setRecordToEdit({
      ...recordToEdit,
      description: e.target.value
    });
  };

  const handleGroupChange = (e) => {
    setRecordToEdit({
      ...recordToEdit,
      group: e.target.value
    });
  };

  const handlePosLinkChange = (e) => {
    setRecordToEdit({
      ...recordToEdit,
      posLink: e.target.value
    });
  };

  const handleSubmit = () => {
    updateDepartment(
      context.clockUrl,
      context.clockToken,
      context.lastStoreid,
      recordToEdit
    )
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setIsEditing(false);
          props.reload();
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An internal error occured", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const cancelEdit = () => {
    setIsEditing(false);
  };

  const hideDelete = () => {
    setShowDelete(false);
  };

  const compareDeleteText = () => {
    if (confirmText === "delete me") {
      deleteRecord();
      setShowDelete(false);
      setConfirmText("");
    } else {
      setError(`"${confirmText}" does not match "delete me"`);
    }
  };

  const deleteRecord = () => {
    deleteDepartment(context.clockUrl, context.clockToken, recordToDelete.id)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          props.reload();
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An Internal Error occured", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const handleConfirmTextChanges = (e) => {
    setConfirmText(e.target.value);
  };

  return (
    <div className="container">
      <ReactTooltip />
      {showDelete && (
        <ConfirmDelete
          isShowing={showDelete}
          hide={hideDelete}
          confirmText={confirmText}
          setConfirmText={handleConfirmTextChanges}
          error={error}
          compareDeleteText={compareDeleteText}
        />
      )}
      <table className="table table-striped">
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th scope="col">Id</th>
            <th scope="col">Department</th>
            <th scope="col">Group</th>
            {isBrowser ? <th scope="col">POS Link</th> : null}
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {props.departments.length > 0
            ? props.departments.map((department, index) => (
                <tr key={`dept-${index}`}>
                  <td
                    onClick={() => handleEdit(department)}
                    style={{ cursor: "pointer" }}
                    data-tip="Edit Department"
                  >
                    <i className="far fa-edit"></i>
                  </td>
                  <td>{department.id}</td>
                  <td>
                    {isEditing && recordToEdit.id === department.id ? (
                      <input
                        className="form-control"
                        type="text"
                        value={recordToEdit.description}
                        onChange={handleDepartmentChange}
                      />
                    ) : (
                      <span>{department.description}</span>
                    )}
                  </td>
                  <td>
                    {isEditing && recordToEdit.id === department.id ? (
                      <input
                        className="form-control"
                        type="text"
                        value={recordToEdit.group}
                        onChange={handleGroupChange}
                      />
                    ) : (
                      <span>{department.group}</span>
                    )}
                  </td>
                  {isBrowser ? (
                    <td>
                      {isEditing && recordToEdit.id === department.id ? (
                        <input
                          className="form-control"
                          type="text"
                          value={recordToEdit.posLink}
                          onChange={handlePosLinkChange}
                        />
                      ) : (
                        <span>{department.posLink}</span>
                      )}
                    </td>
                  ) : null}

                  <td
                    onClick={() => handleDelete(department)}
                    style={{ cursor: "pointer" }}
                    data-tip="Delete Department"
                  >
                    <i className="fal fa-minus-octagon"></i>
                  </td>
                  {isEditing && recordToEdit.id === department.id ? (
                    <React.Fragment>
                      <ReactTooltip id="edit" />
                      <td
                        onClick={() => cancelEdit()}
                        style={{ cursor: "pointer" }}
                        data-tip="Cancel Edit"
                        data-for="edit"
                      >
                        <i className="fas fa-comment-slash"></i>
                      </td>
                    </React.Fragment>
                  ) : null}
                  {isEditing && recordToEdit.id === department.id ? (
                    <td>
                      <button
                        className="btn btn-outline-light"
                        onClick={handleSubmit}
                        data-tip="Submit Changes"
                      >
                        Submit
                      </button>
                    </td>
                  ) : null}
                </tr>
              ))
            : null}
          {props.departments.length === 0 ? (
            <tr>
              <td colSpan="4" className="empty-departments">
                {" "}
                There are no Departments to display
              </td>
            </tr>
          ) : null}
        </tbody>
      </table>
    </div>
  );
};

DepartmentList.propTypes = {
  departments: PropTypes.array,
  reload: PropTypes.func
};

export default DepartmentList;
