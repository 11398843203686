import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { variables } from "../../common/variables";
import { ContextConfig } from "../../App";
import { isEqual } from "../../fp";
import { getUserClockStores } from "../../api/StoreApi";
import { usePrevious, formatDate } from "../../utils";
import ReactTooltip from "react-tooltip";

const CalendarHeader = (props) => {
  const {
    startDate,
    endDate,
    handlePreviousWeek,
    handleNextWeek,
    store,
    setStore,
    departments,
    selectedDepartment,
    setSelectedDepartment,
    showDepartments,
    setShowDepartments
  } = props;

  const [stores, setStores] = useState([]);

  const context = useContext(ContextConfig);
  const previousStores = usePrevious(stores);

  useEffect(() => {
    if (!isEqual(previousStores, stores)) {
      loadStores();
    }
  }, [stores]);

  const handleChange = () => {};

  const loadStores = () => {
    getUserClockStores(context.clockUrl, context.clockToken)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setStores(j.stores);
        } else {
          console.log(j.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleStoreChange = (e) => {
    const storeid = e.target.value;
    setStore(storeid);
  };

  const handleshowDepartmentChange = (e) => {
    if (e.target.checked) {
      setShowDepartments(true);
    } else {
      setShowDepartments(false);
    }
  };

  return (
    <div style={variables.desktopHeader} id="schedulHeaderControls">
      <ReactTooltip />
      <div className="row jusfify-content-center">
        <div
          className="col-1 align-items-center justify-content-center d-flex mt-1"
          style={{ color: "#fff" }}
          onClick={handlePreviousWeek}
        >
          <i
            className="far fa-backward"
            data-tip="Previous Week"
            style={{
              fontSize: "1.5rem",
              fontWeight: "bold",
              color: "#fff",
              cursor: "pointer"
            }}
          ></i>
        </div>
        <div className="col-2 align-items-center justify-content-center d-flex mt-1">
          <span className="schedule-start-text">Start: &nbsp;</span>
          <input
            className="form-control"
            onChange={handleChange}
            type="text"
            value={formatDate(startDate)}
          />
        </div>
        <div className="col-2 align-items-center justify-content-center d-flex mt-1">
          <span className="schedule-start-text">End: &nbsp;</span>
          <input
            className="form-control"
            onChange={handleChange}
            type="text"
            value={formatDate(endDate)}
          />
        </div>
        <div
          className="col-1 align-items-center justify-content-center d-flex mt-1"
          style={{ color: "#fff" }}
          onClick={handleNextWeek}
        >
          <i
            className="far fa-forward"
            data-tip="Next Week"
            style={{
              fontSize: "1.5rem",
              fontWeight: "bold",
              color: "#fff",
              cursor: "pointer"
            }}
          ></i>
        </div>
        <div className="col-3 align-items-center justify-content-center d-flex mt-1">
          {!showDepartments ? (
            <label>Filter by Departments: </label>
          ) : (
            <label>Disable</label>
          )}
          <input
            type="checkbox"
            style={{
              opacity: "1",
              visibility: "visible",
              marginLeft: "5px",
              marginBottom: "5px",
              marginRight: "10px"
            }}
            value={showDepartments}
            onChange={handleshowDepartmentChange}
            id="check1"
          />
          {showDepartments ? (
            <select
              className="form-control"
              value={selectedDepartment}
              onChange={(e) => setSelectedDepartment(e.target.value)}
            >
              <option value="0">Select Department</option>
              {departments.map((dept, i) => (
                <option key={`fd-${i}`} value={dept.id}>
                  {dept.description}
                </option>
              ))}
            </select>
          ) : null}
        </div>

        <div className="col-3 align-items-center justify-content-center d-flex mt-1">
          <label className="px-1" htmlFor="storeselector">
            Store:
          </label>
          <select
            className="form-control"
            value={store}
            onChange={handleStoreChange}
            style={{ width: "220px" }}
          >
            {stores.length === 0 ? (
              <option key={`s-s-0`} value="0">
                No Stores to Select
              </option>
            ) : (
              <React.Fragment>
                <option key={`s-1-0`} value="0">
                  All Stores
                </option>
                {stores.map((record, index) => (
                  <option key={`ss-${index}`} value={record.id}>
                    {record.storeName}
                  </option>
                ))}
              </React.Fragment>
            )}
          </select>
        </div>
      </div>
    </div>
  );
};

CalendarHeader.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  handlePreviousWeek: PropTypes.func,
  handleNextWeek: PropTypes.func,
  store: PropTypes.any,
  setStore: PropTypes.func,
  setReloadSchedule: PropTypes.func,
  reloadSchedule: PropTypes.bool,
  departments: PropTypes.array,
  selectedDepartment: PropTypes.number,
  setSelectedDepartment: PropTypes.func,
  showDepartments: PropTypes.bool,
  setShowDepartments: PropTypes.func
};

export default CalendarHeader;
