import React from "react";
import PropTypes from "prop-types";
import Td from "./components/Td";
import { formatDate, addDays } from "../../utils";
import { compose, curry } from "../../fp";

const Footer = (props) => {
  let accumulatedTotal = 0;

  const getSchedules = () => {
    const newArray = [];

    props.storeSchedules.map((record) => {
      if (record.schedule.length > 0) {
        record.schedule.map((s) => {
          newArray.push(s);
        });
      }
    });

    return newArray;
  };

  const getDaysArray = () => {
    for (
      var arr = [], dt = new Date(props.startDate);
      dt <= props.endDate;
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(new Date(dt));
    }
    return arr;
  };

  const filterByDay = (day, schedules) => {
    const dates = getDaysArray();
    //DayRollOver
    const rolloverdate = addDays(dates[day], -1);
    const checkStartDateString =
      formatDate(rolloverdate) +
      " " +
      props.settings.dayRollOver.substring(0, 2) +
      ":" +
      props.settings.dayRollOver.substr(2);
    const finalStartDate = new Date(checkStartDateString);
    const checkEndDateString =
      formatDate(dates[day]) +
      " " +
      props.settings.dayRollOver.substring(0, 2) +
      ":" +
      props.settings.dayRollOver.substr(2);
    const finalEndDate = new Date(checkEndDateString);
    const days = schedules.filter(
      (d) =>
        new Date(d.startTime) >= finalStartDate &&
        new Date(d.startTime) <= finalEndDate
    );
    //const days = schedules.filter(filterFn(day schedules))
    return days;
  };

  const addMinutes = (filteredData) => {
    return filteredData.reduce((acc, cur) => {
      return acc + cur.totalMinutes;
    }, 0);
  };

  const curriedFilterByDay = curry(filterByDay);

  const totalHours = (totalMinutes) => {
    const total = totalMinutes * 0.016667;

    accumulatedTotal = accumulatedTotal + total;
    return parseFloat(total).toFixed(props.settings.timePrecision);
  };

  const getDayTotal = (day) => {
    if (props.storeSchedules.length === 0) return 0;

    const filterFn = curriedFilterByDay(day);

    const resultFn = compose(totalHours, addMinutes, filterFn, getSchedules);
    return resultFn();
  };

  return (
    <div className="scheduler-footer" id="scheduleFoot">
      <div className={`schedule-employee`}>Totals</div>
      {props.weekStart
        ? props.days.map((day, index) => {
            return (
              <Td key={`footer-c-${index}`} className="day-f-c-t">
                {getDayTotal(day)}
              </Td>
            );
          })
        : null}
      <div className="footer-total">
        {props.settings.timePrecision
          ? accumulatedTotal.toFixed(props.settings.timePrecision)
          : accumulatedTotal.toFixed(2)}
      </div>
    </div>
  );
};

Footer.propTypes = {
  weekStart: PropTypes.any,
  days: PropTypes.array,
  storeSchedules: PropTypes.array,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  settings: PropTypes.object
};

export default Footer;
