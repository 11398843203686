import React, { useState, useEffect, useContext } from "react";
import ClockSidebar from "../ClockSidebar";
//import colors from "../../common/colors";
import Tabs from "../../common/Tabs";
import "../Clock.css";
import CreateUser from "./CreateUser";
import UserList from "./UserList";
import { isValid } from "../../utils";
import { BrowserView, MobileView, isBrowser } from "react-device-detect";
import ClockDesktopMenu from "../ClockDesktopMenu";
import FloatingSidebar from "../../Dashboard/FloatingSidebar";
import { debounce } from "../../fp";
import { getUserClockStores } from "../../api/StoreApi";
import { ContextConfig } from "../../App";
import { toast } from "react-toastify";
import { loadDepartments } from "../../api/departmentApi";

import "./Users.css";

const Users = () => {
  const [activeUser, setActiveUser] = useState({});
  const [hideTab, setHideTab] = useState(true);
  const [stores, setStores] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [reloadDepartments, setReloadDepartments] = useState(false);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const context = useContext(ContextConfig);

  const handleUserEdit = (record) => {
    setActiveUser(record);
  };

  useEffect(() => {
    if (isValid(activeUser)) {
      setHideTab(false);
      var tab = document.getElementById("edit-user-tab");
      if (isValid(tab)) {
        tab.click();
      }
    }

    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);

    findDivHeight();

    window.addEventListener("resize", debounceHandleResize);

    getClockStores();

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, [activeUser, hideTab]);

  useEffect(() => {
    getDepartments();
  }, [context.lastClockStore, reloadDepartments]);

  const findDivHeight = () => {
    if (!isBrowser) return;
    const navbar = document
      .getElementById("master-navbar")
      .getBoundingClientRect().height;
    const usedSpace = navbar + 180; // for some reason the user controls div shows a height of zero???
    const remaining = window.innerHeight - usedSpace;

    const widget = document.getElementById("users-list");
    if (isValid(widget)) {
      widget.style.height = remaining + "px";
      widget.style.overflow = "auto";
    }
  };

  const getClockStores = () => {
    getUserClockStores(context.clockUrl, context.clockToken)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setStores(j.stores);
        } else {
          setStores([]);
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setStores([]);
        console.log(err);
        toast.error("An Internal error occured");
      });
  };

  const getDepartments = () => {
    loadDepartments(
      context.clockUrl,
      context.clockToken,
      context.lastClockStore
    )
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setDepartments(j.departments);
        } else {
          setDepartments([]);
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setDepartments([]);
        toast.error("An error occured getting the departments", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  return (
    <React.Fragment>
      <MobileView>
        <div className="clock-mobile">
          <div className="mobile-clock-header">
            <ClockSidebar />
            <div className="container cc-main-body">
              <Tabs>
                <div label="List">
                  <UserList
                    sendActiveUser={handleUserEdit}
                    stores={stores}
                    departments={departments}
                    reloadDepartments={reloadDepartments}
                    setReloadDepartments={setReloadDepartments}
                  />
                </div>
                <div label="Create">
                  <CreateUser />
                </div>
                <div id={"edit-user-tab"} label="Edit" disabled={hideTab}>
                  <CreateUser
                    activeUser={activeUser}
                    editMode={true}
                    stores={stores}
                  />
                </div>
              </Tabs>
            </div>
          </div>
        </div>
      </MobileView>
      <BrowserView>
        <div>
          <ClockDesktopMenu />
          <FloatingSidebar />
          <div className="container">
            <div
              style={{
                width: "100%",
                marginTop: "20px",
                textAlign: "center",
                fontSize: "1.3rem",
                fontWeight: "bold"
              }}
            >
              Users
            </div>
            <div className="container">
              <Tabs>
                <div label="List">
                  <UserList
                    sendActiveUser={handleUserEdit}
                    stores={stores}
                    departments={departments}
                    reloadDepartments={reloadDepartments}
                    setReloadDepartments={setReloadDepartments}
                  />
                </div>
                <div label="Create">
                  <CreateUser />
                </div>
                <div id={"edit-user-tab"} label="Edit" disabled={hideTab}>
                  <CreateUser
                    activeUser={activeUser}
                    editMode={true}
                    stores={stores}
                  />
                </div>
              </Tabs>
            </div>
          </div>
        </div>
      </BrowserView>
    </React.Fragment>
  );
};

export default Users;
