import jsPDF from "jspdf";
import "jspdf-autotable";
import { formatDate } from "../../utils";

function generatePdf1(report, columns, rows, css, params, dataTypes) {
  console.log("css=" + css);
  var doc = new jsPDF({ compress: true });
  var totalPagesExp = "{total_pages_count_string}";

  var pageContent = function(data) {
    // header
    doc.setFontSize(16);
    // first number is left. second is top
    doc.text(10, 10, report.reportName);
    doc.setFontSize(10);
    //doc.setFontType("italic");

    if (typeof params !== "undefined" && params !== null) {
      if (
        typeof params.startDate !== "undefined" &&
        params.startDate !== null
      ) {
        doc.setFontSize(12);
        doc.text(10, 17, "Dates: " + formatDate(params.startDate));
        if (typeof params.endDate !== "undefined" && params.endDate !== null) {
          doc.text(50, 17, " - " + formatDate(params.endDate));
        }
      }
      if (params.group) {
        doc.text(10, 25, "Group: " + params.group);
      }
      if (params.store) {
        doc.text(10, 25, "Store: " + params.store);
      }
    }

    // footer
    var str = "Page " + data.pageCount;
    doc.setFontSize(10);
    var pageHeight =
      doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
    doc.text(str, data.settings.margin.left, pageHeight - 10);
    doc.text(50, pageHeight - 10, "Printed: " + new Date());
  };

  doc.autoTable(generateColumns(columns), generateRows(columns, rows), {
    didDrawPage: pageContent,
    margin: { top: 30 },
    didParseCell: function(data) {
      const cssAbbreviation = css[data.column.index];
      let alignment = "left";
      if (cssAbbreviation == "1") {
        alignment = "center";
      } else if (cssAbbreviation == "2") {
        alignment = "right";
      }

      data.cell.styles.halign = alignment;
      if (data.section == "body") {
        if (dataTypes[data.column.index] == "money") {
          data.cell.text[0] = parseFloat(data.cell.text[0]).toFixed(2);
        }
      }
    }
  });

  // Total page number plugin only available in jspdf v1.0+
  if (typeof doc.putTotalPages === "function") {
    doc.putTotalPages(totalPagesExp);
  }

  var twoMegs = 2097152;
  var dataUriStringLength = doc.output("datauristring").length;
  if (dataUriStringLength > twoMegs) {
    doc.output("save");
  } else {
    document.getElementById("output").src = "";
    document.getElementById("output").src = doc.output("datauristring");
  }
}

const generateRows = (columns, rows) => {
  const data = [];
  for (var r = 0; r < rows.length; r++) {
    const args = rows[r].split(",");
    const row = {};
    for (var c = 0; c < args.length; c++) {
      row[columns[c]] = args[c];
    }
    data.push(row);
  }
  console.log(data.length + " rows returned");
  return data;
};

const generateColumns = (columns) => {
  const data = [];
  for (var c = 0; c < columns.length; c++) {
    data.push({ title: columns[c], dataKey: columns[c] });
  }
  return data;
};

export { generatePdf1 };
