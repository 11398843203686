// App Actions
export const APP_SET_RERENDER = "APP_SET_RERENDER";
export const APP_SET_END_DATE = "APP_SET_END_DATE";
export const APP_SET_START_DATE = "APP_SET_START_DATE";
export const APP_SET_STORE = "APP_SET_STORE";
export const APP_SET_STORES = "APP_SET_STORES";

// Login Actions
export const LOGIN_SET_USERNAME = "LOGIN_SET_USERNAME";
export const LOGIN_SET_PASSWORD = "LOGIN_SET_PASSWORD";
export const LOGIN_SET_REMEMBER_ME = "LOGI_SET_REMEMBER_ME";
export const LOGIN_AUTO_LOGIN = "LOGIN_AUTO_LOGIN";
export const LOGIN_SET_IS_LOADING = "LOGIN_SET_IS_LOADING";
export const LOGIN_SET_TO_HOME = "LOGIN_SET_TO_HOME";
export const LOGIN_SHOW_FORGOT = "LOGIN_SHOW_FORGOT";

// Punchcard Actions
export const PC_SET_STOREID = "PC_SET_STOREID";
export const PC_SET_DEPARTMENTS = "PC_SET_DEPARTMENTS";
export const PC_SET_ISLOADING_DEPARTMENTS = "PC_SET_ISLOADING_DEPARTMENTS";
export const PC_SET_GENERAL_SETTINGS = "PC_SET_GENERAL_SETTINGS";
export const PC_SET_PUNCHES = "PC_SET_PUNCHES";
export const PC_UPDATE_PUNCH = "PC_UPDATE_PUNCH";
export const PC_ADD_PUNCH = "PC_ADD_PUNCH";
export const PC_SET_USERINFO = "PC_SET_USERINFO";
export const PC_RESET_PUNCH = "PC_RESET_PUNCH";
export const PC_CANCEL_EDIT = "PC_CANCEL_EDIT";

// HoursTable Actions
export const HT_LOAD_DATA = "HT_LOAD_DATA";
export const HT_IS_LOADING = "HT_IS_LOADING";
export const HT_SET_FILTERED_PUNCHES = "HT_SET_FILTERED_PUNCHES";
export const HT_SET_FILTER_FIELD = "HT_SET_FILTER_FIELD";
export const HT_SET_SORT_FIELD = "HT_SET_SORT_FIELD";
export const HT_CHANGE_FILTER_VALUE = "HT_CHANGE_FILTER_VALUE";

// Link Actions
export const LK_IS_LOADING_USERS = "LK_IS_LOADING_USERS";
export const LK_IS_LOADING_COMPANIES = "LK_IS_LOADING_COMPANIES";
export const LK_IS_LOADING_STORES = "LK_IS_LOADING_STORES";
export const LK_SET_USERS = "LK_SET_USERS";
export const LK_SET_USER = "LK_SET_USER";
export const LK_SET_COMPANIES = "LK_SET_COMPANIES";
export const LK_SET_COMPANY = "LK_SET_COMPANY";
export const LK_SET_STORES = "LK_SET_STORES";
export const LK_SET_SEARCH = "LK_SET_SEARCH";
export const LK_SET_EMPLOYEEID = "LK_SET_EMPLOYEEID";

// Edit Actions
export const EDIT_SET_SETTINGS = "EDIT_SET_SETTINGS";
export const EDIT_SET_RERENDER = "EDIT-SET_RERENDER";
export const EDIT_SET_EXISTING_PUNCH = "EDIT_SET_EXISTING_PUNCH";
export const EDIT_SET_USERS = "EDIT_SET_USERS";
export const EDIT_SET_PUNCH_USER = "EDIT_SET_PUNCH_USER";
export const EDIT_SET_IS_EDITABLE = "EDIT_SET_IS_EDITABLE";
export const EDIT_SET_IS_LOCKED = "EDIT_SET_IS_LOCKED";
export const EDIT_SET_SINGLE_DAY = "EDIT_SET_SINGLE_DAY";
export const EDIT_RESET = "EDIT_RESET";

// Edit Punch Actons
export const EDIT_SET_PUNCHES = "EDIT_SET_PUNCHES";
export const EDIT_SET_FILTERED_PUNCHES = "EDIT_SET_FILTERED_PUNCHES";
export const EDIT_SET_SHOW_DELETE = "EDIT_SET_SHOW_DELETE";
export const EDIT_SET_DEL_PUNCH = "EDIT_SET_DEL_PUNCH";
export const EDIT_SET_ERROR = "EDIT_SET_ERROR";
export const EDIT_SET_CONFIRM_TEXT = "EDIT_SET_CONFIRM_TEXT";
export const EDIT_SET_IS_LOADING_DEPARTMENTS =
  "EDIT_SET_IS_LOADING_DEPARTMENTS";
export const EDIT_SET_PUNCH = "EDIT_SET_PUNCH";
export const EDIT_SET_DEPARTMENTS = "EDIT_SET_DEPARTMENTS";
export const EDIT_SET_ERRORS = "EDIT_SET_ERRORS";

// Refund Actions
export const REF_IS_LOADING = "REF_IS_LOADING";
export const REF_SET_START_DATE = "REF_SET_START_DATE";
export const REF_SET_END_DATE = "REF_SET_END_DATE";
export const REF_SET_THRESHOLD = "REF_SET_THRESHOLD";
export const REF_SET_CASHIER = "REF_SET_CASHIER";
export const REF_SET_CASHIERS = "REF_SET_CASHIERS";
export const REF_SET_REFUNDS = "REF_SET_REFUNDS";
export const REF_ENABLE_CASHIER = "REF_ENABLE_CASHIER";
export const REF_LOAD_TRANSACTIONS = "REF_LOAD_TRANSACTIONS";
export const REF_SHOW_MODAL = "REF_SHOW_MODAL";
export const REF_SET_EXCEPTIONS = "REF_SET_EXCEPTIONS";
export const REF_SET_TOTALIZER = "REF_SET_TOTALIZER";
export const REF_IS_LOADING_EXCEPTIONS = "REF_IS_LOADING_EXCEPTIONS";
export const REF_SET_FILTERED_REFUNDS = "REF_SET_FILTERED_REFUNDS";
export const REF_SET_STORE_NUMBER_ASC = "REF_SET_STORE_NUMBER_ASC";
export const REF_SET_FILTERED_PUNCHES = "REF_SET_FILTERED_PUNCHES";
export const REF_SET_FILTER_FIELD = "REF_SET_FILTER_FIELD";
export const REF_SET_FILTER_VALUE = "REF_SET_FILTER_VALUE";

export const REF_SET_HDR = "REF_SET_HDR";
export const REF_SET_REG = "REF_SET_REG";
export const REF_SET_TTL = "REF_SET_TTL";
export const REF_SHOW_KEYS = "REF_SHOW_KEYS";
export const REF_SET_TRANSACTION = "REF_SET_TRANSACTION";
export const REF_SHOW_TRANSACTION = "REF_SHOW_TRANSACTION";
export const REF_SET_FILTERED_TRANSACTIONS = "REF_SET_FILTERED_TRANSACTIONS";
export const REF_SET_CURRENT_TRANSACTION = "REF_SET_CURRENT_TRANSACTION";
export const REF_RETREIVE_BACKPACK = "REF_RETREIVE_BACKPACK";
export const REF_SHOW_BACKPACK = "REF_SHOW_BACKPACK";

// Charges Actions
export const CHARGES_IS_LOADING = "CHARGES_IS_LOADING";
export const CHARGES_SET_START_DATE = "CHARGES_SET_START_DATE";
export const CHARGES_SET_END_DATE = "CHARGES_SET_END_DATE";
export const CHARGES_SET_CHARGES = "CHARGES_SET_CHARGES";
export const CHARGES_SET_FILTERED_CHARGES = "CHARGES_SET_FILTERED_CHARGES";
export const CHARGES_SET_CUSTOMER = "CHARGES_SET_CUSTOMER";
export const CHARGES_SET_TOTALIZER = "CHARGES_SET_TOTALIZER";
export const CHARGES_SET_TOTALIZERS = "CHARGES_SET_TOTALIZERS";
export const CHARGES_SET_CUSTOMERS = "CHARGES_SET_CUSTOMERS";

// Freshop Actions
export const FRESHOP_SET_START_DATE = "FRESHOP_SET_START_DATE";
export const FRESHOP_SET_END_DATE = "FRESHOP_SET_END_DATE";
export const FRESHOP_SET_STORELIST = "FRESHOP_SET_STORELIST";
export const FRESHOP_SET_SELECTED_STORE = "FRESHOP_SET_SELECTED_STORE";
export const FRESHOP_SET_UNCLOSED_ORDERS = "FRESHOP_SET_UNCLOSED_ORDERS";
export const FRESHOP_SET_PRICE_MODS = "FRESHOP_SET_PRICE_MODS";
export const FRESHOP_SET_ITEM_ERRORS = "FRESHOP_SET_ITEM_ERRORS";
export const FRESHOP_SET_ACTIVE_PRICE_MOD = "FRESHOP_SET_ACTIVE-PRICE_MOD";
export const FRESHOP_CHANGE_STATE = "FRESHOP_CHANGE_STATE";
export const FRESHOP_SET_OPEN_ORDER_ERROR = "FRESHOP_SET_OPEN_ORDER_ERROR";
export const FRESHOP_SET_PRICE_MOD_ERROR = "FRESHOP_SET_PRICE_MOD_ERROR";
export const FRESHOP_SET_METRICS = "FRESHOP_SET_METRICS";
export const FRESHOP_SET_METRICS_ERROR = "FRESHOP_SET_METRICS_ERROR";
export const FRESHOP_SET_TRUEUP_ERRORS = "FRESHOP_SET_TRUEUP_ERRORS";
export const FRESHOP_SET_TRUEUP_ERROR_MSG = "FRESHOP_SET_TRUEUP_ERROR_MSG";
export const FRESHOP_SET_ORDERS = "FRESHOP_SET_ORDERS";
export const FRESHOP_SET_ORDER_ERROR = "FRESHOP_SET_ORDER_ERROR";
export const FRESHOP_SET_FILTERED_METRICS = "FRESHOP_SET_FILTERED_ORDERS";
export const FRESHOP_IS_LOADING = "FRESHOP_IS_LOADING";
export const FRESHOP_SHOW_ORDER_MODAL = "FRESHOP_SHOW_ORDER_MODAL";
export const FRESHOP_SET_PAYLOAD_ORDER_ID = "FRESHOP_SET_PAYLOAD_ORDER_ID";
export const FRESHOP_SET_PAYLOAD = "FRESHOP_SET_PAYLOAD";
export const FRESHOP_SET_METRICS_ORDERBY = "FRESHOP_SET_METRICS_ORDERBY";

export const KPI_SHOW_MODAL = "KPI_SHOW_MODAL";
export const KPI_SET_STORE_DATA_1 = "KPI_SET_STORE_DATA_1";
export const KPI_SET_STORE_DATA_2 = "KPI_SET_STORE_DATA_2";
export const KPI_SET_START_DATE = "KPI_SET_START_DATE";
export const KPI_SET_END_DATE = "KPI_SET_END_DATE";
export const KPI_SET_IS_LOADING = "KPI_SET_IS_LOADING";
export const KPI_SET_LEFT = "KPI_SET_LEFT";
export const KPI_SET_RIGHT = "KPI_SET_RIGHT";
export const KPI_DELETE_LEFT = "KPI_DELETE_LEFT";
export const KPI_DELETE_RIGHT = "KPI_DELETE_RIGHT";

export const FORM4_LOADING = "FORM4_LOADING";
export const FORM4_SET_STORES = "FORM4_SET_STORES";
export const FORM4_SET_STORE = "FORM4_SET_STORE";
export const FORM4_SET_HISTORY = "FORM4_SET_HISTORY";

export const SETTINGS_SET_ASSIGNED_SEARCH = "SETTINGS_SET_ASSIGNED_SEARCH";
export const SETTINGS_SET_UNASSIGNED_SEARCH = "SETTINGS_SET_UNASSIGNED_SEARCH";

export const IS_IS_LOADING = "IS_IS_LOADING";
export const IS_IS_LOADING_GROUPS = "IS_IS_LOADING_GROUPS";
export const IS_IS_LOADING_STORES = "IS_IS_LOADING_STORES";
export const IS_ASSIGNED_STORES = "IS_ASSIGNED_STORES";
export const IS_SET_CURRENT_STORE = "IS_SET_CURRENT_STORES";
export const IS_SET_ITEMSETS = "IS_SET_ITEMSETS";
export const IS_SET_PAGED_ITEMSETS = "IS_SET_PAGED_ITEMSETS";
export const IS_SET_CURRENT_PAGE = "IS_SET_CURRENT_PAGE";
export const IS_SET_RECORDS_PER_PAGE = "IS_SET_RECORDS_PER_PAGE";
export const IS_SET_PAGING_OPTIONS = "IS_SET_PAGING_OPTIONS";
export const IS_SET_SEARCH = "IS_SET_SEARCH";
export const IS_SET_START_DATE = "IS_SET_START_DATE";
export const IS_SET_END_DATE = "IS_SET_END_DATE";
export const IS_SET_IS_SEARCHING = "IS_SET_IS_SEARCHING";
export const IS_SET_SEARCH_RESULTS = "IS_SET_SEARCH_RESULTS";
