import React from "react";
import PropTypes from "prop-types";

const Info = (props) => {
  return (
    <div className="d-clock-info" style={{ padding: "2px" }}>
      <span className="clock-error-messages">{props.message}</span>
    </div>
  );
};

Info.propTypes = {
  message: PropTypes.string
};

export default Info;
