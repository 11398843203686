import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ConfirmDelete from "../../common/ConfirmDelete";

const StoreList = (props) => {
  const [currentStore, setCurrentStore] = useState({});
  const [showingDelete, setShowingDelete] = useState(false);
  const [confirmText, setConfirmText] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [filteredStores, setFilteredStores] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    setFilteredStores(props.stores);
  }, [props.stores]);

  useEffect(() => {}, [filteredStores]);

  const handleEdit = (record) => {
    setCurrentStore(record);
    props.editStore(record);
  };

  const handleDelete = (record) => {
    setCurrentStore(record);
    setShowingDelete(true);
  };

  const handleConfirmTextChange = (e) => {
    setConfirmText(e.target.value);
  };

  const compareDeleteText = () => {
    if (confirmText == "delete me") {
      setShowingDelete(false);
      props.deleteStore(currentStore);
    } else {
      setError(confirmText + " does not match delete me");
    }
  };

  const handleChange = (e) => {
    setSearchValue(e.target.value);
    const newArray = props.stores.filter((store) => {
      if (
        store.storeName.toLowerCase().includes(e.target.value.toLowerCase()) ||
        store.storeNumber
          .toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      ) {
        return store;
      }
    });
    setFilteredStores(newArray);
  };
  return (
    <div>
      <ConfirmDelete
        isShowing={showingDelete}
        hide={() => setShowingDelete(false)}
        confirmText={confirmText}
        setConfirmText={handleConfirmTextChange}
        compareDeleteText={compareDeleteText}
        error={error}
      />
      {props.stores.length === 0 ? (
        <div>no records returned</div>
      ) : (
        <div>
          <h3>Stores</h3>
          <div>
            <input
              className="form-control"
              value={searchValue}
              onChange={handleChange}
            />
          </div>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Edit</th>
                <th>#</th>
                <th>Name</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {filteredStores.length === 0
                ? null
                : filteredStores.map((record, index) => (
                    <tr key={index}>
                      <td
                        onClick={() => handleEdit(record)}
                        style={{ cursor: "pointer" }}
                      >
                        <i className="far fa-edit"></i>
                      </td>
                      <td>{record.storeNumber}</td>
                      <td>{record.storeName}</td>
                      <td
                        onClick={() => handleDelete(record)}
                        style={{ cursor: "pointer" }}
                      >
                        <i className="fal fa-minus-octagon"></i>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

StoreList.propTypes = {
  stores: PropTypes.array,
  deleteStore: PropTypes.func,
  editStore: PropTypes.func,
  filteredStores: PropTypes.array
};

export default StoreList;
