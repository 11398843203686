import React from "react";
import { PropTypes } from "prop-types";
import { formatMoney } from "../../utils";
import colors from "./colors";
import ErrorBoundary from "../../common/ErrorBoundary";
import "./Box.css";

const Box = ({ color, s1, s2, s3, months, month, totalizer }) => {
  let description = "";
  let record1 = {};
  let record2 = {};
  let record3 = {};
  let currentMonth = "";
  let previousMonth = "";
  let twoMonthsBack = "";

  const predicate = (key) => Number(key.f1034) == Number(totalizer);
  const record = s1.filter(predicate)[0];
  if (!record) {
    return <div>No records returned</div>;
  }

  switch (record.f1034) {
    case "3407":
      description = "Adjustments";
      record1 = s1.find((r) => r.f1034 == "3407");
      record2 = s2.find((r) => r.f1034 == "3407");
      record3 = s3.find((r) => r.f1034 == "3407");
      break;
    case "7801":
      description = "Redemptions";
      record1 = s1.find((r) => r.f1034 == "7801");
      record2 = s2.find((r) => r.f1034 == "7801");
      record3 = s3.find((r) => r.f1034 == "7801");
      break;
    case "7701":
      description = "Accrued";
      record1 = s1.find((r) => r.f1034 == "7701");
      record2 = s2.find((r) => r.f1034 == "7701");
      record3 = s3.find((r) => r.f1034 == "7701");
      break;
    default:
      description = "Accrued";
      break;
  }

  const getColor = (category, value) => {
    const r1 = record1[category];
    const r2 = record2[category];
    const r3 = record3[category];
    const max = Math.max(r1, r2, r3);
    const min = Math.min(r1, r2, r3);
    if (value == max) {
      return colors.green;
    } else if (value == min) {
      return colors.red;
    } else {
      return colors.orange;
    }
  };

  currentMonth = months.find((m) => Number(m.month) === Number(month));
  if (month == "1") {
    month = 13;
    previousMonth = months.find((m) => Number(m.month) === Number(month) - 1);
    twoMonthsBack = months.find((m) => Number(m.month) === Number(month) - 2);
  } else if (month == "2") {
    month = 12;
    previousMonth = months.find((m) => Number(m.month) === 1);
    twoMonthsBack = months.find((m) => Number(m.month) === 12);
  } else {
    previousMonth = months.find((m) => Number(m.month) === Number(month) - 1);
    twoMonthsBack = months.find((m) => Number(m.month) === Number(month) - 2);
  }

  return (
    <ErrorBoundary>
      <div className="psas-box-wrapper">
        <div className="psas-box-top-container p-2">
          <div className="row justify-content-center">
            <div className="col-3 psas-box-month">&nbsp;</div>
            <div className="col-3 psas-box-month">{twoMonthsBack.name}</div>
            <div className="col-3 psas-box-month">{previousMonth.name}</div>
            <div className="col-3 psas-box-month">{currentMonth.name}</div>
          </div>
          <div className="row justify-content-center">
            <div className="col-3">Customers</div>
            <div
              className="col-3 text-center"
              style={{
                color: getColor("custCnt", record3.custCnt)
              }}
            >
              {formatMoney(record3.custCnt, 0)}
            </div>
            <div
              className="col-3 text-center"
              style={{
                color: getColor("custCnt", record2.custCnt)
              }}
            >
              {formatMoney(record2.custCnt, 0)}
            </div>
            <div
              className="col-3 text-center"
              style={{
                color: getColor("custCnt", record1.custCnt)
              }}
            >
              {formatMoney(record1.custCnt, 0)}
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-3">Transactions</div>
            <div
              className="col-3 text-center"
              style={{
                color: getColor("transCnt", record3.transCnt)
              }}
            >
              {formatMoney(record3.transCnt, 0)}
            </div>
            <div
              className="col-3 text-center"
              style={{
                color: getColor("transCnt", record2.transCnt)
              }}
            >
              {formatMoney(record2.transCnt, 0)}
            </div>
            <div
              className="col-3 text-center"
              style={{
                color: getColor("transCnt", record1.transCnt)
              }}
            >
              {formatMoney(record1.transCnt, 0)}
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-3">Points</div>
            <div
              className="col-3 text-center"
              style={{
                color: getColor("pointTotal", record3.pointTotal)
              }}
            >
              {formatMoney(record3.pointTotal, 0)}
            </div>
            <div
              className="col-3 text-center"
              style={{
                color: getColor("pointTotal", record2.pointTotal)
              }}
            >
              {formatMoney(record2.pointTotal, 0)}
            </div>
            <div
              className="col-3 text-center"
              style={{
                color: getColor("pointTotal", record1.pointTotal)
              }}
            >
              {formatMoney(record1.pointTotal, 0)}
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-3">Trans per</div>
            <div
              className="col-3 text-center"
              style={{
                color: getColor("transPerCustomer", record3.transPerCustomer)
              }}
            >
              {record3.transPerCustomer}
            </div>
            <div
              className="col-3 text-center"
              style={{
                color: getColor("transPerCustomer", record2.transPerCustomer)
              }}
            >
              {record2.transPerCustomer}
            </div>
            <div
              className="col-3 text-center"
              style={{
                color: getColor("transPerCustomer", record1.transPerCustomer)
              }}
            >
              {record1.transPerCustomer}
            </div>
          </div>
        </div>
        <div
          style={{
            backgroundColor: color,
            borderBottomRightRadius: "10px",
            borderBottomLeftRadius: "10px"
          }}
        >
          <h2 className="psas-box-descriptor">{description}</h2>
        </div>
      </div>
    </ErrorBoundary>
  );
};

Box.propTypes = {
  month: PropTypes.any,
  color: PropTypes.string,
  s1: PropTypes.array,
  s2: PropTypes.array,
  s3: PropTypes.array,
  months: PropTypes.array,
  totalizer: PropTypes.string
};

export default Box;
