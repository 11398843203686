import React from "react";
import PropTypes from "prop-types";
import { Card, Col, CardHeader, CardTitle, CardBody } from "reactstrap";
import { Bar } from "react-chartjs-2";

const Depts = (props) => {
  let chartExample3 = {
    data: (canvas) => {
      let ctx = canvas.getContext("2d");

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(72,72,176,0.1)");
      gradientStroke.addColorStop(0.4, "rgba(72,72,176,0.0)");
      gradientStroke.addColorStop(0, "rgba(119,52,169,0)"); //purple colors

      return {
        labels: props.deptLabels,
        datasets: [
          {
            label: "Departments",
            fill: true,
            backgroundColor: gradientStroke,
            hoverBackgroundColor: gradientStroke,
            borderColor: "#d048b6",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            data: props.deptSales
          }
        ]
      };
    },
    options: {
      maintainAspectRatio: false,
      legend: {
        display: false
      },
      tooltips: {
        backgroundColor: "#f5f5f5",
        titleFontColor: "#333",
        bodyFontColor: "#666",
        bodySpacing: 4,
        xPadding: 12,
        mode: "nearest",
        intersect: 0,
        position: "nearest"
      },
      responsive: true,
      scales: {
        yAxes: [
          {
            gridLines: {
              drawBorder: false,
              color: "rgba(225,78,202,0.1)",
              zeroLineColor: "transparent"
            },
            ticks: {
              suggestedMin: 60,
              suggestedMax: 120,
              padding: 20,
              fontColor: "#9e9e9e"
            }
          }
        ],
        xAxes: [
          {
            gridLines: {
              drawBorder: false,
              color: "rgba(225,78,202,0.1)",
              zeroLineColor: "transparent"
            },
            ticks: {
              padding: 20,
              fontColor: "#9e9e9e"
            }
          }
        ]
      }
    }
  };

  return (
    <Col lg="4">
      <Card className="card-chart">
        <CardHeader>
          <h5 className="card-category">By Groups</h5>
          <CardTitle tag="h3">
            <i className="far fa-cash-register text-primary"></i>
            Top 6 Department Sales
          </CardTitle>
        </CardHeader>
        <CardBody>
          <div className="chart-area">
            <Bar data={chartExample3.data} options={chartExample3.options} />
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

Depts.propTypes = {
  deptLabels: PropTypes.array,
  deptSales: PropTypes.array
};

export default Depts;
