import React, { useState, useEffect } from "react";
import { encrypt, decrypt } from "../utils";
import CryptoJS from "crypto-js";

const CryptoTest = () => {
  const [clearText, setClearText] = useState("");
  const [encrypted, setEncrypted] = useState("");
  const [text2, setText2] = useState("");
  const [decryptedText, setDecryptedText] = useState("");
  let password = "8080808080808080";

  useEffect(() => {}, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    // const encryptedText = encrypt1(clearText, password).toString();
    // setEncrypted(encryptedText);
    const encryptedText = encrypt(clearText, password);
    setEncrypted(encryptedText);
  };

  const handleChange = (e) => {
    setClearText(e.target.value);
  };

  const handleChange2 = (e) => {
    setText2(e.target.value);
  };

  const handleSubmit2 = (e) => {
    e.preventDefault();
    const decrypted = decrypt(text2, password).toString(CryptoJS.enc.Utf8);
    setDecryptedText(decrypted);
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit} style={{ padding: "10px" }}>
        <input
          type="text"
          style={{ width: "400px" }}
          value={clearText}
          onChange={handleChange}
          placeholder="Enter Text"
        />
        <br />
        <input
          type="text"
          value={encrypted}
          readOnly
          style={{ width: "800px" }}
        />
        <br />
        <button type="submit">Encrypt</button>
      </form>
      <form onSubmit={handleSubmit2} style={{ padding: "10px" }}>
        <input
          type="text"
          value={text2}
          style={{ width: "800px" }}
          onChange={handleChange2}
          placeholder="Enter Text"
        />
        <br />
        <input
          type="text"
          value={decryptedText}
          readOnly
          style={{ width: "800px" }}
        />
        <br />
        <button type="submit">Decrypt</button>
      </form>
    </React.Fragment>
  );
};

export default CryptoTest;
