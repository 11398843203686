import React from "react";
import PropTypes from "prop-types";

import "./BouncyLoader.css";

const BouncyLoader = ({ label = "" }) => {
  return (
    <div className="bouncy-window">
      <div className="bouncy-wrapper">
        <div className="bouncy-circle"></div>
        <div className="bouncy-circle"></div>
        <div className="bouncy-circle"></div>
        <div className="bouncy-shadow"></div>
        <div className="bouncy-shadow"></div>
        <div className="bouncy-shadow"></div>
        <span className="bouncy-span">{label}</span>
      </div>
    </div>
  );
};

BouncyLoader.propTypes = {
  label: PropTypes.string
};

export default BouncyLoader;
