import React from "react";
import PropTypes from "prop-types";

const ClockButtonsMobile = ({
  status,
  generalSettings,
  clockIn,
  startBreak,
  startLunch,
  clockOut,
  endBreak,
  endLunch
}) => {
  return (
    <div className="col">
      {generalSettings.hasOwnProperty("id") ? (
        <div className="row">
          {status == 0 ? (
            <div className="col">
              <div className="c-button clock-in-button" onClick={clockIn}>
                Clock In
              </div>
            </div>
          ) : (
            <React.Fragment>
              {status === 9 ? null : null}
              {status === 1 ? (
                <React.Fragment>
                  {generalSettings.enableBreak == "1" ? (
                    <div className="col-sm-12 c-button" onClick={startBreak}>
                      Start Break
                    </div>
                  ) : null}
                  {generalSettings.enableLunch == "1" ? (
                    <div className="col-sm-12 c-button" onClick={startLunch}>
                      Go to Lunch
                    </div>
                  ) : null}
                  <div className="col-sm-12 c-button" onClick={clockOut}>
                    Clock Out
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {status === 2 ? (
                    <div
                      className="col c-button clock-end-break-button"
                      onClick={endBreak}
                    >
                      End Break
                    </div>
                  ) : (
                    <React.Fragment>
                      {status === 3 ? (
                        <div
                          className="col c-button clock-end-lunch-button"
                          onClick={endLunch}
                        >
                          End Lunch
                        </div>
                      ) : (
                        <React.Fragment>
                          {status === 3 ? (
                            <div
                              className="col c-button clock-end-lunch-button"
                              onClick={endLunch}
                            >
                              End Lunch
                            </div>
                          ) : null}
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </div>
      ) : null}
    </div>
  );
};

ClockButtonsMobile.propTypes = {
  status: PropTypes.any,
  generalSettings: PropTypes.object,
  clockIn: PropTypes.func,
  startBreak: PropTypes.func,
  startLunch: PropTypes.func,
  clockOut: PropTypes.func,
  endBreak: PropTypes.func,
  endLunch: PropTypes.func
};

export default ClockButtonsMobile;
