import React, { useEffect, useState, useContext } from "react";
import { getDistrictStore } from "../api/BannerApi";
import { usePrevious } from "../utils";
import { isEqual } from "lodash";
import { ContextConfig } from "../App";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import HeaderRb from "../common/HeaderRb";

const BannerStoreSales = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const context = useContext(ContextConfig);

  const previousSales = usePrevious(data);

  useEffect(() => {
    if (!isEqual(previousSales, data)) {
      loadData();
    }
  }, [data]);

  const loadData = () => {
    setIsLoading(true);
    getDistrictStore(
      context.url,
      context.token,
      context.bannerGroup,
      context.endDate
    )
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          setData(j.items);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  return (
    <LoadingOverlay active={isLoading} spinner text="Loading Data...">
      <HeaderRb />
      <div className="container-fluid">
        <h3>Banner Sales</h3>
        {data.length === 0 ? (
          <div>No records were returned</div>
        ) : (
          <table style={{ width: "90%", margin: "auto" }}>
            <thead>
              <tr>
                <th style={{ width: "50%" }}>Store</th>
                <th style={{ width: "50%", textAlign: "right" }}>Sales</th>
              </tr>
            </thead>
            <tbody>
              {data.map((record, index) => (
                <tr key={index}>
                  <td>{record.store_Name}</td>
                  <td style={{ textAlign: "right" }}>{record.f65}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </LoadingOverlay>
  );
};

export default BannerStoreSales;
