import { addDays } from "../utils";
import * as actions from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  startDate: addDays(new Date(), -7),
  endDate: new Date(),
  selectedStore: {},
  stores: [],
  unclosedOrders: [],
  priceMods: [],
  itemErrors: [],
  currentPriceMod: {},
  showOpenOrders: false,
  showPriceMods: false,
  showItemErrors: false,
  openOrderError: null,
  priceModError: null,
  orderIdForPayload: 0,
  payload: {},
  showOrderModal: null,
  metrics: [],
  metricsOrderBy: "",
  filteredMetrics: [],
  metricsError: null,
  trueupErrors: [],
  trueupErrorMsg: null,
  showTrueupErrors: false,
  orders: [],
  showOrders: false,
  orderErrors: null,
  sortField: null,
  filterField: null,
  filteredValue: "",
  dbField: null,
  sortDataType: "string",
  sortOrder: "desc",
  sortOpenOrderAsc: true,
  sortDateAsc: true,
  sortOrdersAsc: true,
  sortPickupAsc: true,
  sortDeliveryAsc: true,
  sortPriceModsAsc: true,
  sortItemErrorsAsc: true,
  sortPaperAsc: true,
  sortPlasticAsc: true,
  sortOrderErrorsAsc: true
};

const freshopReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FRESHOP_SET_START_DATE:
      return {
        ...state,
        startDate: action.date
      };
    case actions.FRESHOP_SET_END_DATE:
      return {
        ...state,
        endDate: action.date
      };
    case actions.FRESHOP_SET_STORELIST:
      return {
        ...state,
        stores: action.stores
      };
    case actions.FRESHOP_SET_SELECTED_STORE:
      return {
        ...state,
        selectedStore: action.store
      };
    case actions.FRESHOP_SET_UNCLOSED_ORDERS:
      return {
        ...state,
        unclosedOrders: action.orders
      };
    case actions.FRESHOP_SET_PRICE_MODS:
      return {
        ...state,
        priceMods: action.mods
      };
    case actions.FRESHOP_SET_ITEM_ERRORS:
      return {
        ...state,
        itemErrors: action.items
      };
    case actions.FRESHOP_SET_ACTIVE_PRICE_MOD:
      return {
        ...state,
        currentPriceMod: action.currentPriceMod
      };
    case actions.FRESHOP_CHANGE_STATE:
      switch (action.payloadType) {
        case "SHOW_OPEN_ORDERS":
          return {
            ...state,
            showOpenOrders: action.value
          };
        case "SHOW_PRICE_MODS":
          return {
            ...state,
            showPriceMods: action.value
          };
        case "SHOW_ITEM_ERRORS":
          return {
            ...state,
            showItemErrors: action.value
          };
        case "SHOW_TRUEUP_ERRORS":
          return {
            ...state,
            showTrueupErrors: action.value
          };
        case "SHOW_ORDERS":
          return {
            ...state,
            showOrders: action.value
          };
        default:
          return {
            ...state
          };
      }
    case actions.FRESHOP_SET_OPEN_ORDER_ERROR:
      return {
        ...state,
        openOrderError: action.error
      };
    case actions.FRESHOP_SET_PRICE_MOD_ERROR:
      return {
        ...state,
        priceModError: action.error
      };
    case actions.FRESHOP_SET_METRICS:
      return {
        ...state,
        metrics: action.metrics,
        filteredMetrics: action.metrics
      };
    case actions.FRESHOP_SET_METRICS_ERROR:
      return {
        ...state,
        metricsError: action.error
      };
    case actions.FRESHOP_SET_TRUEUP_ERRORS:
      return {
        ...state,
        trueupErrors: action.errors
      };
    case actions.FRESHOP_SET_TRUEUP_ERROR_MSG:
      return {
        ...state,
        trueupErrorMsg: action.error
      };
    case actions.FRESHOP_SET_ORDERS:
      return {
        ...state,
        orders: action.orders
      };
    case actions.FRESHOP_SET_ORDER_ERROR:
      return {
        ...state,
        orderErrors: action.error
      };
    case "FRESHOP_SET_SORT_FIELD":
      return {
        ...state,
        sortField: action.field,
        [action.sortState]: !state[action.sortState],
        sortDataType: action.sortDataType,
        sortState: !state[action.sortState]
      };
    case "FRESHOP_SET_FILTER_FIELD":
      return {
        ...state,
        //[action.filterState]: !state[action.filterState],
        filterField:
          action.filterField === state.filterField ? null : action.filterState,
        sortDataType: action.dataType
      };
    case "FRESHOP_CHANGE_FILTER_VALUE":
      return {
        ...state,
        filteredValue: action.value
      };
    case actions.FRESHOP_SET_FILTERED_METRICS:
      return {
        ...state,
        filteredMetrics: action.orders
      };
    case actions.FRESHOP_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading
      };
    case actions.FRESHOP_SHOW_ORDER_MODAL:
      if (!action.isShowing) {
        return {
          ...state,
          orderIdForPayload: 0,
          showOrderModal: false
        };
      } else {
        return {
          ...state,
          showOrderModal: action.isShowing
        };
      }
    case actions.FRESHOP_SET_PAYLOAD_ORDER_ID:
      return {
        ...state,
        orderIdForPayload: action.orderid
      };
    case actions.FRESHOP_SET_PAYLOAD:
      return {
        ...state,
        payload: action.payload
      };
    case actions.FRESHOP_SET_METRICS_ORDERBY:
      return {
        ...state,
        metricsOrderBy: action.orderby,
        sortOrder: action.sortOrder
      };

    default:
      return state;
  }
};

export default freshopReducer;
