/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import ClockDesktopMenu from "../ClockDesktopMenu";
import FloatingSidebar from "../../Dashboard/FloatingSidebar";
import styled from "styled-components";
import BoxPlot from "../../common/BoxPlot";
import PropTypes from "prop-types";
import { ContextConfig } from "../../App";
import { toast } from "react-toastify";

export const Container = styled.div`
  margin: auto;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Open Sans", sans-serif;
`;

const StyledBoxplot = styled(BoxPlot)`
  margin: 10px;
  border: solid 20px #ccc;
  border-radius: 3px;
  background-color: white;
`;

const users = [
  {
    id: 1,
    name: "Mike Bedingfield",
    job: "Programmer"
  },
  {
    id: 2,
    name: "Tommy Adair",
    job: "Marketing"
  },
  {
    id: 3,
    name: "Tj Smith",
    job: "Retail Head"
  },
  {
    id: 4,
    name: "Josh Parker",
    job: "Support"
  }
];

const Playground = () => {
  const context = useContext(ContextConfig);

  useEffect(() => {}, []);

  return (
    <div>
      <ClockDesktopMenu />
      <FloatingSidebar />
      <Container>
        <div className="row justify-content-center">
          <h3>Cashiers</h3>
        </div>
      </Container>
    </div>
  );
};

Playground.propTypes = {
  user: PropTypes.any
};

export default Playground;
