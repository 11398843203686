import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import DateFnsUtils from "@date-io/date-fns";
import Grid from "@material-ui/core/Grid";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from "@material-ui/pickers";
import "./ModalShift.css";

const ModalShift = ({
  isShowing,
  style,
  hide,
  modalClass,
  employee,
  day,
  handleSubmitShift,
  departments,
  newShift,
  setDept,
  handleStartDateChange,
  handleEndDateChange,
  handleStartTimeChange,
  handleEndTimeChange,
  errors
}) =>
  isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <div className="modal-shift-overlay" />
          <div
            className="modal-shift-wrapper"
            aria-modal
            aria-hidden
            tabIndex={-1}
            role="dialog"
          >
            <div
              className={`modal-shift ${modalClass}`}
              style={style}
              id="modal-shift"
            >
              <div className="modal-shift-header">
                <span className="modal-shift-confirm-title">Create Shift</span>
                <span className="modal-shift-close-box">
                  <button
                    type="button"
                    className="modal-shift-close-button"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={hide}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </span>
              </div>
              <div className="modal-shift-body p-4">
                <div>
                  <div>
                    {employee.firstName} {employee.lastName}
                  </div>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="space-around">
                      <div className="row w=100">
                        <div className="col">
                          <KeyboardDatePicker
                            format="MM/dd/yyyy"
                            value={newShift.startDate}
                            onChange={handleStartDateChange}
                            margin="normal"
                            label="Date In"
                            KeyboardButtonProps={{
                              "aria-label": "change date"
                            }}
                          />
                          {errors.startDate && (
                            <div className="alert alert-danger">
                              {errors.startDate}
                            </div>
                          )}
                        </div>
                        <div className="col">
                          <KeyboardDatePicker
                            format="MM/dd/yyyy"
                            value={newShift.endDate}
                            onChange={handleEndDateChange}
                            margin="normal"
                            label="Date Out"
                            KeyboardButtonProps={{
                              "aria-label": "change date"
                            }}
                          />
                          {errors.endDate && (
                            <div className="alert alert-danger">
                              {errors.endDate}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="row w-100">
                        <div className="col">
                          <KeyboardTimePicker
                            value={newShift.startTime}
                            onChange={handleStartTimeChange}
                            margin="normal"
                            label="Time in"
                            KeyboardButtonProps={{
                              "aria-label": "change time"
                            }}
                          />
                          {errors.startTime && (
                            <div className="alert alert-danger">
                              {errors.startTime}
                            </div>
                          )}
                        </div>
                        <div className="col">
                          <KeyboardTimePicker
                            margin="normal"
                            value={newShift.endTime}
                            onChange={handleEndTimeChange}
                            label="Time out"
                            KeyboardButtonProps={{
                              "aria-label": "change time"
                            }}
                          />
                          {errors.endTime && (
                            <div className="alert alert-danger">
                              {errors.endTime}
                            </div>
                          )}
                        </div>
                      </div>
                    </Grid>
                  </MuiPickersUtilsProvider>
                  <div className="row w=100 mt-3 mb-2">
                    <select
                      className="form-control"
                      value={newShift.department || ""}
                      onChange={setDept}
                    >
                      <option value="-1">Select a Department</option>
                      {departments.length > 0
                        ? departments.map((department, index) => (
                            <option key={`d-${index}`} value={department.id}>
                              {department.description}
                            </option>
                          ))
                        : null}
                    </select>
                    {errors.department && (
                      <div className="alert alert-danger">
                        {errors.department}
                      </div>
                    )}
                  </div>
                  <div style={{ textAlign: "left" }}>
                    <button className="btn btn-outline-dark" onClick={hide}>
                      Cancel
                    </button>
                    <span style={{ textAlign: "right" }}>
                      <button
                        className="btn btn-outline-dark"
                        onClick={() =>
                          handleSubmitShift(employee, day, newShift)
                        }
                        style={{ float: "right" }}
                      >
                        Submit
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>,
        document.body
      )
    : null;

ModalShift.propTypes = {
  modalClass: PropTypes.string,
  style: PropTypes.object,
  isShowing: PropTypes.bool,
  employee: PropTypes.object,
  day: PropTypes.number,
  handleSubmitShift: PropTypes.func,
  departments: PropTypes.array,
  newShift: PropTypes.object,
  errors: PropTypes.object
};

export default ModalShift;
