import React, { useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { ContextConfig } from "../App";
import HeaderRB from "../common/HeaderRb";
import Main from "./Pages/Main";
import { Route, withRouter } from "react-router-dom";
import Banners from "./Pages/Banner/Banners";
import { isMobile } from "react-device-detect";
import SyncFusion from "./Pages/Pivot/SyncFusion";
import { useDispatch } from "react-redux";
import * as actions from "../actions/actionTypes";
import preval from "preval.macro";

const Homepage = () => {
  const dispatch = useDispatch();
  const context = useContext(ContextConfig);

  useEffect(() => {
    dispatch({ type: actions.LOGIN_SET_TO_HOME, setHome: false });
  }, []);

  return (
    <div>
      <HeaderRB />
      <div className={`container-fluid ${!isMobile ? "homepage-base" : ""}`}>
        <div className="welcom">Welcome {context.username}!</div>
        <span>
          Last compile Date:{" "}
          {preval`module.exports = new Date().toLocaleString();`}
        </span>

        <Route path="/home" exact component={Main} />
        <Route path="/home/banners" component={Banners} />
        <Route path="/home/pivot" component={SyncFusion} />
      </div>
    </div>
  );
};

Homepage.propTypes = {
  history: PropTypes.object
};

export default withRouter(Homepage);
