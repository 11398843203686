import React from "react";
import PropTypes from "prop-types";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasErrors: false
    };
  }

  static getDerivedStateFromError(error) {
    console.log(error);
    return {
      hasError: true
    };
  }
  render() {
    if (this.state.hasError) {
      return <h1>Oops, something went wrong</h1>;
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.any
};

export default ErrorBoundary;
