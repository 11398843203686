/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import { variables } from "../../common/variables";
import {
  getStoreRequests,
  denyRequest,
  approveRequest
} from "../../api/requestapi";
import { ContextConfig } from "../../App";
import { toast } from "react-toastify";
import ReviewThumbnail from "../Requests/ReviewThumbnail";
import ReviewRequest1 from "../Requests/ReviewRequest1";
import { addDays, formatDate } from "../../utils";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import "./OpenPunches.css";

import "./Requests.css";

const Requests = () => {
  // eslint-disable-next-line
  const [requests, setRequests] = useState([]);
  const [showRequest, setShowRequest] = useState(false);
  const [recordToEdit, setRecordToEdit] = useState({});
  const [requestType, setRequestType] = useState(0);
  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(addDays(new Date(), 30));
  // const [denyReason, setDenyReason] = useState("");
  // const [showDenyReason, setShowDenyReason] = useState(false);

  const context = useContext(ContextConfig);

  useEffect(() => {
    fetchRequests();
  }, [requestType, start, end]);

  const fetchRequests = () => {
    getStoreRequests(
      context.clockUrl,
      context.clockToken,
      context.lastClockStore,
      requestType,
      formatDate(start),
      formatDate(end)
    )
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          if (typeof j.requests !== "undefined" && j.requests) {
            if (j.requests.length > 0) {
              setRequests(j.requests);
            } else {
              setRequests([]);
            }
          } else {
            setRequests([]);
          }
        } else {
          setRequests([]);
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setRequests([]);
        toast.error("An error occured getting PTO requests", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const colors = ["#2A2D40", "#3565F2", "#2980F2", "#2291F2", "#04D9C4"];

  const getBackgroundColor = () => {
    return colors[Math.floor(Math.random() * colors.length)];
  };

  const handleReview = (record) => {
    setRecordToEdit(record);
    setShowRequest(true);
  };

  const hide = () => {
    setShowRequest(false);
  };

  const handleConfirm = () => {
    approveRequest(context.clockUrl, context.clockToken, recordToEdit.id)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          fetchRequests();
          setShowRequest(false);
          if (j.msg && j.msg.length > 0) {
            toast.warn(j.msg, {
              position: toast.POSITION.TOP_LEFT
            });
          }
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const handleDeny = (denyReason) => {
    denyRequest(
      context.clockUrl,
      context.clockToken,
      recordToEdit.id,
      denyReason
    )
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          fetchRequests();
          setShowRequest(false);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const handleRequestChangeState = (e) => {
    setRequestType(e.target.value);
  };

  return (
    <div style={variables.widgetStyle}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid>
          <div className="row">
            <div className="col-2">
              <h3>PTO ({requests.length})</h3>
            </div>
            <div className="col-4">
              <select
                className="form-control"
                value={requestType}
                onChange={handleRequestChangeState}
              >
                <option value="0">Show Requests</option>
                <option value="1">Show Approved</option>
                <option value="2">Show Denied</option>
              </select>
            </div>
            {requestType > 0 ? (
              <React.Fragment>
                <div className="col-3">
                  <KeyboardDatePicker
                    format="MM/dd/yyyy"
                    margin="normal"
                    value={start}
                    onChange={setStart}
                    KeyboardButtonProps={{
                      "aria-label": "start date"
                    }}
                  />
                </div>
                <div className="col-3">
                  <KeyboardDatePicker
                    format="MM/dd/yyyy"
                    margin="normal"
                    value={end}
                    onChange={setEnd}
                    KeyboardButtonProps={{
                      "aria-label": "End date"
                    }}
                  />
                </div>
              </React.Fragment>
            ) : null}
          </div>
        </Grid>
      </MuiPickersUtilsProvider>

      <ReviewRequest1
        isShowing={showRequest}
        hide={hide}
        pto={recordToEdit}
        handleConfirm={handleConfirm}
        handleDeny={handleDeny}
      />
      {requests.length === 0 ? (
        <div>There are currently no PTO requests</div>
      ) : (
        <React.Fragment>
          <div className="row justify-content-center">
            {requests.map((record, index) => (
              <ReviewThumbnail
                key={`pto-${index}`}
                record={record}
                backgroundColor={getBackgroundColor()}
                handleReview={handleReview}
              />
            ))}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default Requests;
