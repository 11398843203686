import React from "react";
import PropTypes from "prop-types";
import EntryButton from "./EntryButton";

const EntryButtons = (props) => {
  return (
    <div className="row mt-4">
      <div className="col-8 m-auto">
        <div className="row mt-3 justify-content-between">
          <EntryButton value="C" handleButtonClick={props.handleButtonClick} />
          <EntryButton value="B" handleButtonClick={props.handleButtonClick} />
          <EntryButton value="E" handleButtonClick={props.handleButtonClick} />
        </div>
        <div className="row mt-3 justify-content-between">
          <EntryButton value="7" handleButtonClick={props.handleButtonClick} />
          <EntryButton value="8" handleButtonClick={props.handleButtonClick} />
          <EntryButton value="9" handleButtonClick={props.handleButtonClick} />
        </div>
        <div className="row mt-3 justify-content-between">
          <EntryButton value="4" handleButtonClick={props.handleButtonClick} />
          <EntryButton value="5" handleButtonClick={props.handleButtonClick} />
          <EntryButton value="6" handleButtonClick={props.handleButtonClick} />
        </div>
        <div className="row mt-3 justify-content-between">
          <EntryButton value="1" handleButtonClick={props.handleButtonClick} />
          <EntryButton value="2" handleButtonClick={props.handleButtonClick} />
          <EntryButton value="3" handleButtonClick={props.handleButtonClick} />
        </div>
        <div className="row mt-3 justify-content-between">
          <EntryButton value="" handleButtonClick={props.handleButtonClick} />
          <EntryButton value="0" handleButtonClick={props.handleButtonClick} />
          <EntryButton value="" handleButtonClick={props.handleButtonClick} />
        </div>
      </div>
    </div>
  );
};

EntryButtons.propTypes = {
  handleButtonClick: PropTypes.func
};

export default EntryButtons;
