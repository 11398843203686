import React, { useState, useEffect, useContext } from "react";
import HeaderRB from "../common/HeaderRb";
import Header from "../Header/Header";
import { ContextConfig } from "../App";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "../common/Spinner";
import * as actions from "../actions/actionTypes";
import { debounce } from "../fp";
import {
  refundQty,
  storeClerks,
  exceptions,
  getTransactions,
  filterTransactions
} from "../api/widgetsApi";
import { toast } from "react-toastify";
import { formatDate, handleCsv, isValid } from "../utils";
import ModalRefunds from "./ModalRefunds";
import SortableFilterableColumn from "../Clock/widgets/SortableFilterableColumn";
import ModalTransaction from "./ModalTransaction";
import ErrorBoundary from "../common/ErrorBoundary";
import { getTransaction } from "../api/CashierApi";
import { email } from "../Cashiers/Transaction/EmailTrans";

const Refunds = () => {
  const [reRender, setReRender] = useState(false);
  const [subs, setSubs] = useState([]);
  const [inclusionList, setInclusionList] = useState("");
  const [inclusionType, setInclusionType] = useState(0);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const state = useSelector((app) => app.refundReducer);
  const refundTableState = useSelector((app) => app.refundTableReducer);
  const dispatch = useDispatch();

  const context = useContext(ContextConfig);

  useEffect(() => {
    debugger;
    if (context.lastSearchType != "3") {
      dispatch({ type: actions.REF_ENABLE_CASHIER, enableCashier: false });
    }
    getExceptions();
    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }

      return () => {
        dispatch({ type: actions.REF_ENABLE_CASHIER, enableCashier: false });
      };
    }, 500);

    findDivHeight();

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, [reRender]);

  useEffect(() => {
    if (state.enableCashier) {
      readStoreClerks();
    }
  }, [
    state.enableCashier,
    context.lastStoreid,
    state.startDate,
    state.endDate,
    state.totalizer,
    state.threshold
  ]);

  useEffect(() => {
    filterSubs();
  }, [state.transactions]);

  useEffect(() => {
    if (state.hdr.length > 0 && state.reg.length > 0 && state.ttl.length > 0) {
      dispatch({ type: actions.REF_SHOW_TRANSACTION, showTransaction: true });
    }
  }, [state.hdr, state.reg, state.ttl]);

  useEffect(() => {
    getRefundQty();
  }, [
    context.lastStoreid,
    context.lastGroup,
    state.startDate,
    state.endDate,
    state.totalizer,
    state.cashier,
    state.threshold
  ]);

  useEffect(() => {
    if (refundTableState.sortField !== null) {
      sortRecords();
    }
  }, [refundTableState.sortField, refundTableState.sortState]);

  useEffect(() => {
    filterRecords();
  }, [refundTableState.filteredValue]);

  useEffect(() => {
    dispatch({ type: actions.REF_SET_CASHIER, cashier: 0 });
  }, [context.lastStoreid]);

  const findDivHeight = () => {
    const navbar = document
      .getElementById("master-navbar")
      .getBoundingClientRect().height;
    const controls = document.getElementById("controls").getBoundingClientRect()
      .height;
    const usedSpace = navbar + controls + 20;
    const remaining = window.innerHeight - usedSpace;

    const body = document.getElementById("body");
    body.style.height = remaining + "px";
    body.style.overflow = "auto";
  };

  const getExceptions = () => {
    let search = context.lastSearchType === 2 ? "Group" : "Stores";
    if (context.lastSearchType == 3) {
      search = "Store";
    }

    let searchValue = context.lastStoreid;
    if (context.lastSearchType == 2) {
      searchValue = context.lastGroup;
    }
    exceptions(
      context.url,
      context.token,
      searchValue,
      formatDate(state.startDate),
      formatDate(state.endDate),
      search
    )
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          dispatch({
            type: actions.REF_SET_EXCEPTIONS,
            exceptions: j.totalizers
          });
        } else {
          dispatch({ type: actions.REF_SET_EXCEPTIONS, exceptions: [] });
          toast.warning(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: actions.REF_SET_EXCEPTIONS, exceptions: [] });
        toast.error("An Internal error occured", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const readStoreClerks = () => {
    storeClerks(
      context.url,
      context.token,
      context.lastStoreid,
      state.startDate,
      state.endDate
    )
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          dispatch({ type: actions.REF_SET_CASHIERS, cashiers: j.items });
        } else {
          dispatch({ type: actions.REF_SET_CASHIERS, cashiers: [] });
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        dispatch({ type: actions.REF_SET_CASHIERS, cashiers: [] });
        console.log(err);
        toast.error("An Internal error has occured", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const getRefundQty = () => {
    dispatch({ type: actions.REF_IS_LOADING_EXCEPTIONS, isLoading: true });
    let search = context.lastSearchType === 2 ? "Group" : "Stores";
    if (context.lastSearchType == 3) {
      search = "Store";
    }

    let searchValue = context.lastStoreid;
    if (context.lastSearchType == 2) {
      searchValue = context.lastGroup;
    }
    refundQty(
      context.url,
      context.token,
      searchValue,
      search,
      formatDate(state.startDate),
      formatDate(state.endDate),
      state.threshold,
      state.cashier,
      state.totalizer
    )
      .then((response) => {
        dispatch({ type: actions.REF_IS_LOADING_EXCEPTIONS, isLoading: false });
        const j = response.data;
        if (j.error === 0) {
          dispatch({ type: actions.REF_SET_REFUNDS, refunds: j.items });
        } else {
          dispatch({ type: actions.REF_SET_REFUNDS, refunds: [] });
        }
      })
      .catch((err) => {
        dispatch({ type: actions.REF_IS_LOADING_EXCEPTIONS, isLoading: false });
        dispatch({ type: actions.REF_SET_REFUNDS, refunds: [] });
        console.log(err);
        toast.error("An Internal Error occured", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const handleRowClick = (record) => {
    dispatch({ type: actions.REF_IS_LOADING_EXCEPTIONS, isLoading: true });
    dispatch({
      type: actions.REF_SET_CURRENT_TRANSACTION,
      transaction: record
    });
    getTransactions(
      context.url,
      context.token,
      record.storeid,
      state.startDate,
      state.endDate,
      record.f1185,
      state.totalizer
    )
      .then((response) => {
        dispatch({ type: actions.REF_IS_LOADING_EXCEPTIONS, isLoading: false });
        const j = response.data;
        if (j.error === 0) {
          dispatch({
            type: actions.REF_LOAD_TRANSACTIONS,
            transactions: j.trans
          });
          dispatch({
            type: actions.REF_SET_FILTERED_TRANSACTIONS,
            transactions: j.trans
          });
          dispatch({ type: actions.REF_SHOW_MODAL, showModal: true });
        } else {
          dispatch({ type: actions.REF_LOAD_TRANSACTIONS, transactions: [] });
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        dispatch({ type: actions.REF_IS_LOADING_EXCEPTIONS, isLoading: false });
        console.log(err);
        dispatch({ type: actions.REF_LOAD_TRANSACTIONS, transactions: [] });
        toast.error("An Internal error occured", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const hide = () => {
    dispatch({ type: actions.REF_SHOW_MODAL, showModal: false });
  };

  const Csv = () => {
    handleCsv(state.filteredTransactions, "transactions.csv");
  };

  const getSortField = () => {
    switch (refundTableState.sortField) {
      case "store_Number":
        return refundTableState.sortStoreNameAsc;
      case "cnt":
        return refundTableState.sortCntAsc;
      case "median":
        return refundTableState.sortMedianAsc;
      case "avgf64":
        return refundTableState.sortAvgF64Asc;
      case "f65":
        return refundTableState.sortF65Asc;
      case "f64":
        return refundTableState.sortF64Asc;
      case "f1126":
        return refundTableState.sortF1126Asc;
      case "username":
        return refundTableState.sortUsernameAsc;
      case "totalCount":
        return refundTableState.sortTotalCountAsc;
      case "f1142":
        return refundTableState.sortF1142Asc;
      case "post_Date":
        return refundTableState.sortPostDateAsc;
      default:
        return null;
    }
  };

  const compare = (a, b) => {
    let fieldA = a[refundTableState.sortField];
    let fieldB = b[refundTableState.sortField];

    if (refundTableState.sortDataType !== "number") {
      fieldA = fieldA.toUpperCase();
      fieldB = fieldB.toUpperCase();
    } else {
      fieldA = parseFloat(a[refundTableState.sortField]);
      fieldB = parseFloat(b[refundTableState.sortField]);
    }

    let comparison = 0;
    if (fieldA > fieldB) {
      comparison = 1;
    } else if (fieldA < fieldB) {
      comparison = -1;
    }

    if (getSortField()) {
      return comparison * -1;
    } else {
      return comparison;
    }
  };

  const sortRecords = () => {
    dispatch({ type: actions.REF_IS_LOADING, isLoading: true });
    dispatch({
      type: actions.REF_SET_FILTERED_REFUNDS,
      filteredData: [...state.filteredRefunds].sort(compare)
    });
    dispatch({ type: actions.REF_IS_LOADING, isLoading: false });
  };

  const filterRecords = () => {
    if (refundTableState.filteredValue.length === 0) {
      dispatch({
        type: actions.REF_SET_FILTERED_REFUNDS,
        filteredData: state.refunds
      });
      return;
    }

    let newArray = [];
    if (refundTableState.sortDataType == "number") {
      newArray = state.refunds.filter((r) =>
        parseFloat(r[refundTableState.filterField])
          .toFixed(2)
          .toString()
          .includes(refundTableState.filteredValue)
      );
    } else {
      newArray = state.refunds.filter((r) =>
        r[refundTableState.filterField]
          .toUpperCase()
          .includes(refundTableState.filteredValue.toUpperCase())
      );
    }

    dispatch({
      type: actions.REF_SET_FILTERED_REFUNDS,
      filteredData: newArray
    });
  };

  Array.prototype.contains = function(key, value) {
    let status = false;
    this.forEach((item) => {
      if (item[key] === value) {
        status = true;
      }
    });

    return status;
  };

  const filterSubs = () => {
    const newArray = [];
    state.transactions.map((record) => {
      const recordSubs = record.subs;
      if (isValid(recordSubs)) {
        const splits = recordSubs.split(",");
        splits.map((split) => {
          const args = split.split("|");
          if (!newArray.contains("id", args[1])) {
            newArray.push({ id: args[1], description: args[0] });
          }
        });
      }
    });

    setSubs(newArray);
  };

  const handleSubChange = (e) => {
    setInclusionList(e.target.value);
  };

  const handleInclusionTypeChange = (e) => {
    setInclusionType(e.target.value);
  };

  const handleUpdateResults = () => {
    if (
      inclusionList !== null &&
      inclusionList.length > 0 &&
      inclusionList != "0" &&
      inclusionType == "0"
    ) {
      toast.warning("Please select an inclusion type (bottom combobox)");
      return;
    }
    dispatch({ type: actions.REF_IS_LOADING_EXCEPTIONS, isLoading: true });
    filterTransactions(
      context.url,
      context.token,
      state.transactions,
      inclusionList,
      inclusionType
    )
      .then((response) => {
        dispatch({ type: actions.REF_IS_LOADING_EXCEPTIONS, isLoading: false });
        const j = response.data;
        if (j.error === 0) {
          dispatch({
            type: actions.REF_SET_FILTERED_TRANSACTIONS,
            transactions: j.trans
          });
          dispatch({ type: actions.REF_SHOW_MODAL, showModal: true });
          toast.success("We are done filtering your records", {
            position: toast.POSITION.TOP_LEFT
          });
          dispatch({ type: actions.REF_SHOW_BACKPACK, showBackpack: true });
        } else {
          toast.warning(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: actions.REF_IS_LOADING_EXCEPTIONS, isLoading: false });
        toast.error("An Internal error occured", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const handleTransactionClick = (e) => {
    //dispatch({ type: actions.REF_SHOW_MODAL, showModal: true });
    dispatch({ type: actions.REF_SET_TRANSACTION, transaction: e });

    const { f254, f1032, storeid, term, f1185 } = e;
    const transList = `${f254},${f1032},${storeid},${term},${f1185}`;
    getTransaction(context.url, context.token, transList)
      .then((response) => {
        //dispatch({ type: actions.REF_SHOW_MODAL, showModal: false });
        const j = response.data;
        if (j.error === 0) {
          dispatch({ type: actions.REF_SET_HDR, hdr: j.hdr });
          dispatch({ type: actions.REF_SET_REG, reg: j.reg });
          dispatch({ type: actions.REF_SET_TTL, ttl: j.ttl });
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        //dispatch({ type: actions.REF_SHOW_MODAL, showModal: false });
        toast.error("An error has occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const handleEmail = (f1032, storeid, cashier, f254, f1056) => {
    dispatch({ type: actions.REF_IS_LOADING_EXCEPTIONS, isLoading: true });
    email(f1032, storeid, cashier, f254, f1056, context)
      .then((response) => {
        dispatch({ type: actions.REF_IS_LOADING_EXCEPTIONS, isLoading: false });
        const j = response.data;
        if (j.error === 0) {
          toast.success("Your transaction has been emailed", {
            position: toast.POSITION.TOP_LEFT
          });
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        dispatch({ type: actions.REF_IS_LOADING_EXCEPTIONS, isLoading: false });
        console.log(err);
        toast.error("An error occured sending your email", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  return (
    <div>
      {state.isLoading && <Spinner />}
      {state.isLoadingExceptions && <Spinner />}
      {state.showTransaction && (
        <ModalTransaction
          handleEmail={handleEmail}
          state={state}
          dispatch={dispatch}
        />
      )}
      {state.showModal ? (
        <ModalRefunds
          isShowing={state.showModal}
          hide={hide}
          transactions={state.filteredTransactions}
          Csv={Csv}
          subs={subs}
          handleSubChange={handleSubChange}
          inclusionList={inclusionList}
          inclusionType={inclusionType}
          handleInclusionTypeChange={handleInclusionTypeChange}
          handleUpdateResults={handleUpdateResults}
          handleTransactionClick={handleTransactionClick}
          dispatch={dispatch}
          state={state}
        />
      ) : null}
      <HeaderRB />
      <div id="controls">
        <div className="container-fluid">
          <h3>Refunds</h3>
          <Header
            reRender={reRender}
            setReRender={setReRender}
            startDate={state.startDate}
            endDate={state.endDate}
            setStartDate={(e) =>
              dispatch({ type: actions.REF_SET_START_DATE, startDate: e })
            }
            setEndDate={(e) =>
              dispatch({ type: actions.REF_SET_END_DATE, endDate: e })
            }
            setLoading={(e) =>
              dispatch({ type: actions.REF_IS_LOADING, isLoading: e })
            }
            showSingleStore={true}
          />
        </div>
        <ErrorBoundary>
          <div className="container">
            <div className="row justify-content-center mt-2">
              {state.exceptions.length > 0 ? (
                <div className="col-3">
                  <span style={{ display: "flex", flexDirection: "row" }}>
                    <label style={{ marginTop: "10px", marginRight: "10px" }}>
                      Totalizer:
                    </label>
                    <select
                      className="form-control"
                      value={state.totalizer}
                      onChange={(e) =>
                        dispatch({
                          type: actions.REF_SET_TOTALIZER,
                          totalizer: e.target.value
                        })
                      }
                    >
                      <option value="0">Select a Totalizer</option>
                      {state.exceptions.map((totalizer, i) => (
                        <option key={`st-${i}`} value={totalizer.f1034}>
                          {totalizer.f1039}
                        </option>
                      ))}
                    </select>
                  </span>
                </div>
              ) : null}
              <div className="col-3">
                <span style={{ display: "flex", flexDirection: "row" }}>
                  <label style={{ marginTop: "10px", marginRight: "10px" }}>
                    Threshold:
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    value={state.threshold}
                    onChange={(e) =>
                      dispatch({
                        type: actions.REF_SET_THRESHOLD,
                        threshold: e.target.value
                      })
                    }
                  />
                </span>
              </div>
              {context.lastSearchType == "3" ? (
                <div className="col-2" style={{ paddingTop: "18px" }}>
                  <label style={{ marginRight: "10px" }}>Enable Cashier</label>
                  <input
                    type="checkbox"
                    style={{ opacity: "1", visibility: "visible" }}
                    value={state.enableCashier}
                    checked={state.enableCashier}
                    onChange={(e) =>
                      dispatch({
                        type: actions.REF_ENABLE_CASHIER,
                        enableCashier: e.target.checked
                      })
                    }
                  />
                </div>
              ) : null}
              {state.enableCashier ? (
                <div className="col-3">
                  <select
                    className="form-control"
                    value={state.cashier.id}
                    onChange={(e) =>
                      dispatch({
                        type: actions.REF_SET_CASHIER,
                        cashier: e.target.value
                      })
                    }
                  >
                    <option value="0">All Cashiers</option>
                    {state.cashiers.map((cashier, i) => (
                      <option key={`rc-${i}`} value={cashier.id}>
                        {cashier.cashier}
                      </option>
                    ))}
                  </select>
                </div>
              ) : null}
              {state.refunds.length > 0 ? (
                <React.Fragment>
                  <div className="col-2" style={{ marginTop: "20px" }}>
                    {state.filteredRefunds.length} records
                  </div>
                  <div
                    style={{
                      color: "red",
                      fontSize: "1.2rem",
                      textAlign: "center"
                    }}
                  >
                    Please note, that if you select a record that has a large
                    quantity, the server response may take a while. Just think,
                    we are having to look at every item in every transaction to
                    see what Sub Departments are included so you can use{" "}
                    <i>exclude/include</i> feature
                  </div>
                </React.Fragment>
              ) : null}
            </div>
          </div>
        </ErrorBoundary>
      </div>
      <div id="body">
        <ErrorBoundary>
          <div className="container" style={{ marginTop: "20px" }}>
            {state.refunds.length === 0 ? (
              <div
                className="empty-refunds"
                style={{
                  width: "100%",
                  fontSize: "1.2rem",
                  textAlign: "center"
                }}
              >
                There are currently no records to show
              </div>
            ) : (
              <table className="table table-striped">
                <thead>
                  <tr>
                    <SortableFilterableColumn
                      state={refundTableState}
                      dispatch={dispatch}
                      title={"Store"}
                      sortable={true}
                      sortField={"sortStoreNameAsc"}
                      dbField={"store_Number"}
                      sortFieldAction={"REF_SET_SORT_FIELD"}
                      sortDataType={"string"}
                      filterable={true}
                      filterField={"store_Name"}
                      filterFieldAction={"REF_SET_FILTER_FIELD"}
                      filterValueChangeAction={"REF_CHANGE_FILTER_VALUE"}
                    />
                    <SortableFilterableColumn
                      state={refundTableState}
                      dispatch={dispatch}
                      title={"Qty"}
                      sortable={true}
                      sortField={"sortCntAsc"}
                      dbField={"cnt"}
                      sortFieldAction={"REF_SET_SORT_FIELD"}
                      sortDataType={"number"}
                      filterable={true}
                      filterField={"cnt"}
                      filterFieldAction={"REF_SET_FILTER_FIELD"}
                      filterValueChangeAction={"REF_CHANGE_FILTER_VALUE"}
                      tooltip={"This is the total for this day"}
                    />
                    <SortableFilterableColumn
                      state={refundTableState}
                      dispatch={dispatch}
                      title={"Total"}
                      sortable={true}
                      sortField={"sortTotalCountAsc"}
                      dbField={"totalCount"}
                      sortFieldAction={"REF_SET_SORT_FIELD"}
                      sortDataType={"number"}
                      filterable={true}
                      filterField={"totalCount"}
                      filterFieldAction={"REF_SET_FILTER_FIELD"}
                      filterValueChangeAction={"REF_CHANGE_FILTER_VALUE"}
                      tooltip={
                        "This is the total for the requested date period"
                      }
                    />

                    <SortableFilterableColumn
                      state={refundTableState}
                      dispatch={dispatch}
                      title={"Median"}
                      sortable={true}
                      sortField={"sortF65Asc"}
                      dbField={"f65"}
                      sortFieldAction={"REF_SET_SORT_FIELD"}
                      sortDataType={"number"}
                      filterable={true}
                      filterField={"f65"}
                      filterFieldAction={"REF_SET_FILTER_FIELD"}
                      filterValueChangeAction={"REF_CHANGE_FILTER_VALUE"}
                      tooltip={"This is the Stores Median"}
                    />

                    <SortableFilterableColumn
                      state={refundTableState}
                      dispatch={dispatch}
                      title={"Av Qty"}
                      sortable={true}
                      sortField={"sortF64Asc"}
                      dbField={"f64"}
                      sortFieldAction={"REF_SET_SORT_FIELD"}
                      sortDataType={"number"}
                      filterable={true}
                      filterField={"f64"}
                      filterFieldAction={"REF_SET_FILTER_FIELD"}
                      filterValueChangeAction={"REF_CHANGE_FILTER_VALUE"}
                    />

                    <SortableFilterableColumn
                      state={refundTableState}
                      dispatch={dispatch}
                      title={"C Median"}
                      sortable={true}
                      sortField={"sortMedianAsc"}
                      dbField={"median"}
                      sortFieldAction={"REF_SET_SORT_FIELD"}
                      sortDataType={"number"}
                      filterable={true}
                      filterField={"median"}
                      filterFieldAction={"REF_SET_FILTER_FIELD"}
                      filterValueChangeAction={"REF_CHANGE_FILTER_VALUE"}
                      tooltip={"This is the Cashier Median"}
                    />

                    <SortableFilterableColumn
                      state={refundTableState}
                      dispatch={dispatch}
                      title={"#"}
                      sortable={true}
                      sortField={"sortF1126Asc"}
                      dbField={"f1126"}
                      sortFieldAction={"REF_SET_SORT_FIELD"}
                      sortDataType={"number"}
                      filterable={true}
                      filterField={"f1126"}
                      filterFieldAction={"REF_SET_FILTER_FIELD"}
                      filterValueChangeAction={"REF_CHANGE_FILTER_VALUE"}
                    />
                    <SortableFilterableColumn
                      state={refundTableState}
                      dispatch={dispatch}
                      title={"Cashier"}
                      sortable={true}
                      sortField={"sortUsernameAsc"}
                      dbField={"username"}
                      sortFieldAction={"REF_SET_SORT_FIELD"}
                      sortDataType={"string"}
                      filterable={true}
                      filterField={"username"}
                      filterFieldAction={"REF_SET_FILTER_FIELD"}
                      filterValueChangeAction={"REF_CHANGE_FILTER_VALUE"}
                    />
                    <SortableFilterableColumn
                      state={refundTableState}
                      dispatch={dispatch}
                      title={"Level"}
                      sortable={true}
                      sortField={"sortF1142Asc"}
                      dbField={"f1142"}
                      sortFieldAction={"REF_SET_SORT_FIELD"}
                      sortDataType={"number"}
                      filterable={true}
                      filterField={"f1142"}
                      filterFieldAction={"REF_SET_FILTER_FIELD"}
                      filterValueChangeAction={"REF_CHANGE_FILTER_VALUE"}
                    />
                    <SortableFilterableColumn
                      state={refundTableState}
                      dispatch={dispatch}
                      title={"Date"}
                      sortable={true}
                      sortField={"sortPostDateAsc"}
                      dbField={"post_Date"}
                      sortFieldAction={"REF_SET_SORT_FIELD"}
                      sortDataType={"string"}
                      filterable={true}
                      filterField={"post_Date"}
                      filterFieldAction={"REF_SET_FILTER_FIELD"}
                      filterValueChangeAction={"REF_CHANGE_FILTER_VALUE"}
                    />
                  </tr>
                </thead>
                <tbody>
                  {state.filteredRefunds.map((record, index) => (
                    <tr
                      key={`rr-${index}`}
                      onClick={() => handleRowClick(record)}
                      style={{ cursor: "pointer" }}
                    >
                      <td>{record.store_Name}</td>
                      <td>{parseInt(record.cnt).toFixed(0)}</td>
                      <td>{parseInt(record.totalCount).toFixed(0)}</td>
                      <td>{parseFloat(record.f65).toFixed(2)}</td>
                      <td>{parseFloat(record.avf64).toFixed(2)}</td>
                      <td>{parseFloat(record.median).toFixed(2)}</td>
                      <td>{record.f1126}</td>
                      <td>{record.username}</td>
                      <td>{record.f1142}</td>
                      <td>{record.post_Date}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </ErrorBoundary>
      </div>
    </div>
  );
};

export default Refunds;
