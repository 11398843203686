import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import "./TypeAheadDropDown.css";

const TypeAheadDropDown = (props) => {
  const [suggestions, setSuggestions] = useState([]);
  const [text, setText] = useState("");
  const {
    placeholder,
    value,
    data,
    keyValue,
    displayValue,
    setSelection,
    label,
    reload
  } = props;

  const listRef = useRef();

  useEffect(() => {
    if (data.length > 0) {
      setSuggestions(data);
    }

    if (typeof value !== "undefined" && value !== null) {
      const records = data.filter((r) => r[keyValue] === value);
      if (records.length > 0) {
        setText(records[0][displayValue]);
      }
    }
  }, [data]);

  useEffect(() => {
    if (typeof value !== "undefined" && value !== null) {
      const records = data.filter((r) => r[keyValue] === value);
      if (records.length > 0) {
        setText(records[0][displayValue]);
      }
    }
  }, [reload]);

  const onChange = (e) => {
    setText(e.target.value);
    const records = data.filter((r) =>
      r[displayValue].toUpperCase().includes(e.target.value.toUpperCase())
    );
    if (records.length === 0) {
      setSuggestions(data);
    } else {
      setSuggestions(records);
    }
  };

  const handleSuggestionSelected = (e, selection) => {
    console.log(selection);
    setSelection(selection);
  };

  const onFocus = () => {
    listRef.current.style.visibility = "visible";
    listRef.current.style.opacity = "1";
  };

  const onBlur = () => {
    listRef.current.style.opacity = "0";
    setTimeout(() => {
      listRef.current.style.visibility = "hidden";
    }, 500);
  };

  const handleClick = (e) => {
    e.target.select();
  };

  const renderSuggestions = () => {
    return (
      <ul ref={listRef}>
        {suggestions.map((record, i) => (
          <li key={`${record[keyValue]}-${i}`}>
            <span
              className="tadd-text"
              onClick={(e) => handleSuggestionSelected(e, record)}
            >
              {record[displayValue]}
            </span>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className="TypeAheadDropDown ">
      <div style={{ display: "flex", flexDirection: "row" }}>
        <label
          style={{
            color: props.labelColor ? props.labelColor : "rgb(100, 109, 142)",
            marginTop: "10px",
            textAlign: "right"
            // width: "200px"
          }}
          htmlFor="typeahead"
        >
          {label}:
        </label>
        <input
          className="form-control"
          style={{
            backgroundColor: props.backgroundColor
              ? props.backgroundColor
              : "#1f1e2e"
          }}
          name="typeahead"
          autoComplete="off"
          placeholder={placeholder}
          value={text}
          type="text"
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          onClick={handleClick}
        />
      </div>
      {renderSuggestions()}
    </div>
  );
};

TypeAheadDropDown.propTypes = {
  placeholder: PropTypes.string,
  data: PropTypes.array,
  value: PropTypes.any,
  keyValue: PropTypes.string,
  displayValue: PropTypes.string,
  setSelection: PropTypes.func,
  label: PropTypes.string,
  labelColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  reload: PropTypes.bool
};

export default TypeAheadDropDown;
