import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { formatDate, setDecimal } from "../../utils";
import "./MobileTot.css";
import { Redirect, withRouter } from "react-router-dom";
import CashierNavigation from "../CashierNavigation";
import { debounce } from "../../fp";

const MobileTot = ({ data, context, ...props }) => {
  const [goBack, setGoBack] = useState(false);
  const [step, setStep] = useState(1);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  useEffect(
    () => {
      const debounceHandleResize = debounce(function handleResize() {
        setDimensions({
          height: window.innerHeight,
          width: window.innerWidth
        });
        if (dimensions !== null) {
          findDivHeight();
        }
      }, 500);

      findDivHeight();

      window.addEventListener("resize", debounceHandleResize);

      return () => {
        window.removeEventListener("resize", debounceHandleResize);
      };
    },
    { data }
  );

  const findDivHeight = () => {
    const navbar = document
      .getElementById("master-navbar")
      .getBoundingClientRect().height;
    const controls = document
      .getElementById("desktoptot-controls")
      .getBoundingClientRect().height;
    const usedSpace = navbar + controls + 20;
    const remaining = window.innerHeight - usedSpace;
    const widgets = document.getElementById("desktoptot-body");
    widgets.style.height = remaining + "px";
    widgets.style.overflow = "auto";
  };

  const handleGoback = (currentStep) => {
    setStep(currentStep);
    setGoBack(true);
  };

  const handleClick = (totalizer) => {
    context.totalizer = totalizer;
    props.history.push("/transactions");
  };

  return (
    <React.Fragment>
      {goBack && step == 1 && <Redirect to="/flyout" />}
      {goBack && step == 0 && <Redirect to="/cashiers" />}

      <div className="container-fluid mobiletot">
        <div className="container desktoptot-controls" id="desktoptot-controls">
          <div className="mobiletot-store">
            {context.selectedRecord.store_Name}
          </div>
          <div className="mobiletot-cashier">{context.cashier.cashier}</div>
          <div className="mobiletot-date">{formatDate(context.endDate)}</div>
          <CashierNavigation step="1" goBack={handleGoback} />
        </div>

        <div className="mobiltot-body" id="desktoptot-body">
          {data.map((tot, index) => (
            <div
              key={index}
              className="mobiltot-totalizer-item"
              onClick={() => handleClick(tot)}
            >
              <div className="mobilttot-left">
                <div className="mobiletot-totalizer">{tot.f1039}</div>
                <div className="mobiletot-score">
                  Score: {setDecimal(tot.score)}
                </div>
              </div>
              <div className="mobiltot-right">
                <div className="mobiletot-cashier-median">
                  Cashier Median: {tot.median}
                </div>
                <div className="mobiletot-store-median">
                  Store Median: {tot.f65}
                </div>
                <div className="mobiletot-tlz">Tlz: {tot.f1034}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

MobileTot.propTypes = {
  data: PropTypes.array,
  context: PropTypes.object,
  history: PropTypes.object
};

export default withRouter(MobileTot);
