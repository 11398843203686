const tableReducer = (state, action) => {
  console.log("action", action);
  switch (action.type) {
    case "sortF1056":
      return {
        ...state,
        sortF1056Asc: !state.sortF1056Asc,
        sortField: "f1056",
        sortDataType: "string"
      };
    case "filterF1056":
      return {
        ...state,
        filterF1056: !state.filterF1056,
        filterField: "f1056"
      };
    case "sortF64":
      return {
        ...state,
        sortF64Asc: !state.sortF64Asc,
        sortField: "f64",
        sortDataType: "number"
      };
    case "filterF64":
      return {
        ...state,
        filterF64: !state.filterF64,
        filterField: "f64"
      };
    case "sortF1057":
      return {
        ...state,
        sortF1057Asc: !state.sortF1057Asc,
        sortField: "f1057",
        sortDataType: "string"
      };
    case "filterF1057":
      return {
        ...state,
        filterF1057: !state.filterF1057,
        filterField: "f1057"
      };
    case "sortF1148":
      return {
        ...state,
        sortF1148Asc: !state.sortF1148Asc,
        sortField: "f1148",
        sortDataType: "string"
      };
    case "filterF1148":
      return {
        ...state,
        filterF1148: !state.filterF1148,
        filterField: "f1148"
      };
    case "sortF1034":
      return {
        ...state,
        sortF1034Asc: !state.sortF1034Asc,
        sortField: "f1034",
        sortDataType: "string"
      };
    case "filterF1034":
      return {
        ...state,
        filterF1034: !state.filterF1034,
        filterField: "f1034"
      };
    case "sortF1032":
      return {
        ...state,
        sortF1032Asc: !state.sortF1032Asc,
        sortField: "f1032",
        sortDataType: "number"
      };
    case "filterF1032":
      return {
        ...state,
        filterF1032: !state.filterF1032,
        filterField: "1032"
      };
    case "sortF254":
      return {
        ...state,
        sortF254Asc: !state.sortF254Asc,
        sortDataType: "string",
        sortField: "f254"
      };
    case "filterF254":
      return {
        ...state,
        filterF254: !state.filterF254,
        filterField: "254"
      };
    case "sortF253":
      return {
        ...state,
        sortF253Asc: !state.sortF253Asc,
        sortDataType: "string",
        sortField: "f253"
      };
    case "filterF253":
      return {
        ...state,
        filterF253: !state.filterF253,
        filterField: "f253"
      };
    case "filterchange":
      return {
        ...state,
        filterValue: action.value
      };
    case "startdate":
      return {
        ...state,
        startDate: action.date
      };
    case "enddate":
      return {
        ...state,
        endDate: action.date
      };
    case "storeid":
      action.context.lastStoreid = action.storeid;
      return {
        ...state,
        storeid: action.storeid
      };
    case "storenumber":
      return {
        ...state,
        storenumber: action.storenumber
      };
    case "success":
      return {
        ...state,
        [action.store]: action.data.items
      };
    case "customer":
      return {
        ...state,
        customer: action.customer
      };
    case "error":
      return {
        ...state,
        error: action.msg,
        [action.store]: []
      };
    case "fail":
      console.log(action.error);
      return {
        ...state,
        [action.store]: [],
        error: action.msg
      };

    default:
      return state;
  }
};

const formatTotalizer = (totalizer) => {
  switch (totalizer) {
    case "7701":
      return "Accrued";
    case "7801":
      return "Redeemed";
    case "3407":
      return "Adjusted";
    default:
      return totalizer;
  }
};

export { formatTotalizer, tableReducer };
