import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Employee from "./Employee";

import "./Employees.css";

const Employees = (props) => {
  useEffect(() => {}, [props.hours]);

  const findShifts = (employeeId) => {
    if (props.hours.length === 0) return [];
    const shifts = props.hours.filter((s) => s.employeeId === employeeId);
    return shifts;
  };

  return (
    <div className="employees-days">
      {props.users.length > 0
        ? props.users.map((user, i) => (
            <Employee
              key={`ur-${i}`}
              user={user}
              days={props.days}
              shifts={findShifts(user.employeeId)}
              weekStart={props.weekStart}
              context={props.context}
              reloadSchedule={props.reloadSchedule}
              setReloadSchedule={props.setReloadSchedule}
              handleDateClick={props.handleDateClick}
              editShift={props.editShift}
              startDate={props.startDate}
            />
          ))
        : null}
    </div>
  );
};

Employees.propTypes = {
  users: PropTypes.array,
  days: PropTypes.array,
  hours: PropTypes.array,
  weekStart: PropTypes.instanceOf(Date),
  context: PropTypes.object,
  reloadSchedule: PropTypes.bool,
  setReloadSchedule: PropTypes.func,
  handleDateClick: PropTypes.func,
  editShift: PropTypes.func,
  setStoreSchedules: PropTypes.func,
  startDate: PropTypes.instanceOf(Date),
  store: PropTypes.any,
  endDate: PropTypes.instanceOf(Date)
};

export default Employees;
