import React from "react";
import ReactDOM from "react-dom";
import "./ModalHours.css";
import { formatDate, formatTimeAmPm } from "../../utils";

const ModalHours = ({
  isShowing,
  hide,
  punches,
  username,
  employeeid,
  total,
  context
}) =>
  isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <div className="modal-hours-overlay" />

          <div
            className="p-4 modal-hours-wrapper"
            aria-modal
            aria-hidden
            tabIndex={-1}
            role="dialog"
          >
            <div className="modal-hours">
              <div className="modal-hours-header">
                <span className="modal-hours-confirm-title">Weekly Hours</span>
                <span className="modal-hours-close-box">
                  <button
                    type="button"
                    className="modal-hours-close-button"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={hide}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </span>
              </div>
              <div className="modal-hours-body">
                <div className="modal-hours-user">{username}</div>
                <div className="modal-hours-empid">{employeeid}</div>
                <div className="modal-hours-list">
                  <div className="row">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th className="modal-hours-data" scope="col">
                            Date
                          </th>
                          <th className="modal-hours-data" scope="col">
                            Type
                          </th>
                          <th className="modal-hours-data" scope="col">
                            In
                          </th>
                          <th className="modal-hours-data" scope="col">
                            Out
                          </th>

                          <th
                            className="modal-hours-total"
                            scope="row"
                            style={{ textAlign: "right" }}
                          >
                            Total
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {punches.length > 0
                          ? punches.map((punch, index) => (
                              <tr key={`punches-${index}`}>
                                <td className="modal-hours-data">
                                  {formatDate(punch.punchDate)}
                                </td>
                                <td className="modal-hours-data">
                                  {punch.punchType}
                                </td>
                                <td className="modal-hours-data">
                                  {context.settings.showRawPunches
                                    ? formatTimeAmPm(punch.rawPunchIn)
                                    : formatTimeAmPm(punch.punchIn)}
                                </td>
                                <td className="modal-hours-data">
                                  {context.settings.showRawPunches
                                    ? formatTimeAmPm(punch.rawPunchOut)
                                    : formatTimeAmPm(punch.punchOut)}
                                </td>
                                <td
                                  className="modal-hours-total"
                                  style={{ textAlign: "right" }}
                                >
                                  {punch.total}
                                </td>
                              </tr>
                            ))
                          : null}
                        {punches.length > 0 ? (
                          <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td style={{ textAlign: "right" }}>Total</td>
                            <td style={{ textAlign: "right" }}>
                              {parseFloat(total).toFixed(
                                context.settings.timePrecision
                              )}
                            </td>
                          </tr>
                        ) : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>,
        document.body
      )
    : null;

export default ModalHours;
