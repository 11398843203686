import React from "react";

function showFlags(fs, wic, t1, t2, t3, t4) {
  let output = "";
  if (fs == "1") {
    output += "FS ";
  }
  if (wic == "1") {
    output += "WIC ";
  }
  if (t1 == "1") {
    output += "T1";
  }
  if (t2 == "1") {
    output += "T2";
  }
  if (t3 == "1") {
    output += "T3";
  }
  if (t4 == "1") {
    output += "T4";
  }
  return output;
}

function showKeysFunction(rec) {
  if (rec.f1081.toUpperCase().indexOf("PDC") >= 0) {
    let bracketPos = rec.f1081.indexOf("]");
    if (bracketPos >= 0) {
      rec.f1081 = "KEY=" + rec.f1081.substring(bracketPos);
    } else {
      rec.f1081 = "";
    }
  }

  let arrayResults = [];

  if (rec.f1081.toUpperCase().indexOf("KEY") >= 0) {
    let rec1 = rec.f1081.replace("KEY=[", "");
    let args = rec1.split("[");

    args.map((arg) => {
      if (arg.indexOf("]") >= 0) {
        let data = arg.split("]");
        if (data.length > 2) {
          data.map((s, index) => {
            if (index >= 2) {
              return (
                <tr key={rec.f1101}>
                  <td>Key</td>
                  <td className="m-trans-keys-1">
                    (fct :{getFunctionDescription(s)})
                  </td>
                </tr>
              );
            } else {
              if (getFunctionDescription(s).length > s.length) {
                arrayResults.push({
                  name: "KEY",
                  value: `(fct: ${getFunctionDescription(s)})`
                });
              } else {
                arrayResults.push({
                  name: "KEY",
                  value: `(entry: ${getFunctionDescription(s)})`
                });
              }
            }
          });
        } else {
          if (getFunctionDescription(data[0]).length > data[0].length) {
            arrayResults.push({
              name: "KEY",
              value: `(fct: ${getFunctionDescription(data[1])})`
            });
            arrayResults.push({
              name: "KEY",
              value: `(fct: ${getFunctionDescription(data[0])})`
            });
          } else {
            arrayResults.push({
              name: "KEY",
              value: `(fct: ${getFunctionDescription(
                data[1]
              )} ${getFunctionDescription(data[0])})`
            });
          }
        }
      }
    });
  }

  return arrayResults;
}

function getFunctionDescription(functionNumber) {
  var description = "";
  switch (functionNumber) {
    case "610":
      description = "Error Correct";
      break;
    case "620":
      description = "Void";
      break;
    case "30":
      description = "Clear";
      break;
    case "720":
      description = "Sub Total";
      break;
    case "630":
      description = "Cancel Order";
      break;
    case "680":
      description = "No Sale";
      break;
    case "715":
      description = "Sub";
      break;
    case "640":
      description = "Correction";
      break;
    case "650":
      description = "Refund Key";
      break;
    case "10011":
      description = "Login Access";
      break;
    case "690":
      description = "View Line Number";
      break;
    case "104":
      description = "Credit Card";
      break;
    case "710":
      description = "Scan Item";
      break;
    default:
      description = "";
      break;
  }
  if (description.length > 0) {
    return functionNumber + " : " + description;
  } else {
    return functionNumber;
  }
}

export { showFlags, showKeysFunction };
