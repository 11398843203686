import axios from "axios";

async function readMenu(url, token, isMobile) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "menu",
    params: {
      menuType: isMobile ? 1 : 0
    }
  });
  return json;
}

export { readMenu };
