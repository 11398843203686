import React, { useState, useEffect, useContext } from "react";
import { getMissingBreaks } from "../../api/widgetsApi";
import { ContextConfig } from "../../App";
import { variables } from "../../common/variables";
import ModalCreatePunch from "./ModalCreatePunch";
import { useSelector, useDispatch } from "react-redux";

import "./MissingBreaks.css";

const colors = ["#2A2D40", "#3565F2", "#2980F2", "#2291F2", "#04D9C4"];

const MissingBreaks = () => {
  const [breaks, setBreaks] = useState([]);
  const [showModal, setShowModal] = useState();
  const [currentPunch, setCurrentPunch] = useState({});

  const context = useContext(ContextConfig);

  const state = useSelector((app) => app.editReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchBreaks();
  }, [
    context.startDate,
    context.endDate,
    context.lastClockStore,
    state.reRender
  ]);

  const getBackgroundColor = () => {
    return colors[Math.floor(Math.random() * colors.length)];
  };

  const fetchBreaks = () => {
    getMissingBreaks(
      context.clockUrl,
      context.clockToken,
      context.lastClockStore,
      context.startDate,
      context.endDate
    ).then((response) => {
      const j = response.data;
      if (j.error === 0) {
        if (j.breaks) {
          setBreaks(j.breaks);
        } else {
          setBreaks([]);
        }
      } else {
        setBreaks([]);
      }
    });
  };

  const handleCreate = (punch) => {
    setShowModal(true);
    setCurrentPunch(punch);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div style={variables.widgetStyle}>
      {showModal && (
        <ModalCreatePunch
          isShowing={showModal}
          hide={closeModal}
          punch={currentPunch}
          state={state}
          context={context}
          dispatch={dispatch}
          punchType={2}
        />
      )}
      <h3>Missing Breaks</h3>
      <div className="row justify-content-center">
        {breaks.map((b, index) => (
          <div className="col-2 d-flex" key={`mb-${index}`}>
            <div
              className="break"
              style={{ backgroundColor: getBackgroundColor() }}
            >
              <div style={{ width: "100%", display: "flex" }}>
                <span
                  className="opt-edit"
                  style={{
                    cursor: "pointer",
                    width: "100%",
                    textAlign: "right"
                  }}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Edit"
                  onClick={() => handleCreate(b)}
                >
                  <i className="far fa-save"></i>
                </span>
              </div>
              <div>{b.id}</div>
              <div>{b.username}</div>
              <div>{b.punchDate}</div>
              <div>{b.employeeId}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MissingBreaks;
