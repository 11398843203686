import React, { useEffect } from "react";
import Selector from "./Selector";
import PropTypes from "prop-types";
// import { DragDropContext, Droppable } from "react-beautiful-dnd";

const Where = (props) => {
  useEffect(() => {}, [props.wheres]);

  useEffect(() => {}, [props.reloadSelectors]);

  return (
    <div>
      <div>
        {props.wheres.map((where, index) => {
          return (
            <Selector
              key={index}
              id={where.id}
              index={index}
              data={props.data}
              update={props.updateWhere}
              deleteWhere={props.deleteWhere}
              reloadSelectors={props.reloadSelectors}
              groups={props.userGroups}
              where={where}
              stores={props.stores}
              subs={props.subs}
              categories={props.categories}
            />
          );
        })}
      </div>
    </div>
  );
};

Where.propTypes = {
  wheres: PropTypes.array,
  reloadSelectors: PropTypes.bool,
  data: PropTypes.array,
  updateWhere: PropTypes.func,
  deleteWhere: PropTypes.func,
  userGroups: PropTypes.array,
  stores: PropTypes.array,
  where: PropTypes.object,
  subs: PropTypes.array,
  categories: PropTypes.categories
};

export default Where;
