import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import "./CircularProgressBar.css";

const CircularProgressBar = (props) => {
  const [offset, setOffset] = useState(0);
  const circleRef = useRef(null);

  const {
    size,
    progress,
    progressText,
    strokeWidth,
    circleOneStroke,
    circleTwoStroke,
    showPercent
  } = props;

  useEffect(() => {
    const progressOffset = ((100 - progress) / 100) * circumference;
    setOffset(progressOffset);

    circleRef.current.style =
      "transition: stroke-dashoffset 850ms ease-in-out, stroke 500ms linear";
  }, [setOffset, progress, circumference, offset]);

  const center = size / 2;
  const radius = size / 2 - strokeWidth / 2;
  const circumference = 2 * Math.PI * radius;

  return (
    <div>
      <svg className="circular-progress-bar" width={size} height={size}>
        <circle
          className="circular-progress-bar-bg"
          stroke={circleOneStroke}
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={strokeWidth}
          fill={"none"}
        />
        <circle
          ref={circleRef}
          className="circular-progress-bar-circle"
          stroke={circleTwoStroke}
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          fill={"none"}
        />
        <text
          x={center}
          y={center}
          className="circular-progress-bar-percentage"
        >
          {parseInt(progressText.toString() || 30)}
          {showPercent ? "%" : null}
        </text>
      </svg>
    </div>
  );
};

CircularProgressBar.propTypes = {
  size: PropTypes.number,
  progress: PropTypes.number,
  progressText: PropTypes.any,
  strokeWidth: PropTypes.number,
  circleOneStroke: PropTypes.string,
  circleTwoStroke: PropTypes.string,
  showPercent: PropTypes.bool
};

export default CircularProgressBar;
