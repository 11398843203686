import React, { useContext } from "react";
import PropTypes from "prop-types";
import { formatDate, isValid } from "../utils";
import { ContextConfig } from "../App";
import { withRouter } from "react-router-dom";
import "./PointOverview.css";

const PointOverviewDesktop = ({ data, customer, links, ...props }) => {
  const context = useContext(ContextConfig);

  const handleCurrentPointActivity = (record) => {
    let userNumber = customer;
    if (customer.length > 10) {
      // we dont want to use this because what we really need is the phone number to send up
      userNumber = links[0].f1577;
    }
    context.loyalty = {
      prevous: false,
      phonenumber: userNumber,
      activityDate: data[0].today,
      record: record,
      currentMonth: data[0].currentMonth,
      lastMonth: data[0].lastMonth
    };

    props.history.push("/history");
  };

  const handlePreviousPointActivity = (record) => {
    let userNumber = customer;
    if (customer.length > 10) {
      // we dont want to use this because what we really need is the phone number to send up
      userNumber = links[0].f1577;
    }
    context.loyalty = {
      previous: true,
      phonenumber: userNumber,
      activityDate: data[0].previousMonth,
      record: record,
      currentMonth: data[0].currentMonth,
      lastMonth: data[0].lastMonth
    };
    props.history.push("/history");
  };

  return (
    <React.Fragment>
      {isValid(data) && data.length === 0 ? (
        <div className="po-empty-records">There are no results to display</div>
      ) : (
        <div className="po-body">
          <div className="po-header-container">
            <div className="po-header-d"></div>
            {/* end of banner */}
          </div>
          {/* end of header */}
          <div className="po-instructions">
            Participating locations only. For more info CLICK HERE or visit FAQ
            page.
          </div>
          {isValid(data) &&
            data.map((record, index) => (
              <div className="po-data-body" key={index}>
                <div className="po-current-points">
                  <div className="po-current-points-text">Current Points</div>
                  <div className="po-current-points-points-d">
                    {record.currentPointBalance}
                  </div>
                </div>
                <div className="po-fuel-savings">
                  {`You've earned $${record.fuelDiscount} off a gallon!`}
                </div>
                <div className="po-fuel-disclaimer">
                  *Fuel discounts valid up to 15 gallons
                </div>
                <div className="po-point-history-d">
                  <div className="po-point-history-current">
                    <div
                      className="po-point-history-current-circle"
                      onClick={() => handleCurrentPointActivity(record)}
                    >
                      <div className="po-point-history-current-circle-month-d">
                        {record.currentMonth}
                      </div>
                      <div className="po-point-history-current-circle-points-d">
                        {record.currentMonthEndingBalance}
                      </div>
                      <div
                        className="po-point-history-current-circle-view-points-d"
                        onClick={() => handleCurrentPointActivity(record)}
                      >
                        View Points
                      </div>
                    </div>{" "}
                    {/* end of circle */}
                    <div className="po-point-history-current-expiration-d">
                      <div className="po-point-history-current-exp-month">
                        {record.currentMonthLong} points
                      </div>
                      <div className="po-point-history-current-exp-date">
                        expire {formatDate(record.currentMonthExpDate)}
                      </div>
                    </div>
                  </div>

                  <div className="po-point-history-previous">
                    <div
                      className="po-point-history-previous-circle"
                      onClick={() => handlePreviousPointActivity(record)}
                    >
                      <div className="po-point-history-previous-circle-month-d">
                        {record.lastMonth}
                      </div>
                      <div className="po-point-history-previous-circle-points-d">
                        {record.lastMonthEndingBalance}
                      </div>
                      <div
                        className="po-point-history-previous-circle-view-points-d"
                        onClick={() => handlePreviousPointActivity(record)}
                      >
                        View Points
                      </div>
                    </div>{" "}
                    {/* end of circle */}
                    <div className="po-point-history-previous-expiration-d">
                      <div className="po-point-history-previous-exp-month">
                        {record.lastMonthLong} points
                      </div>
                      <div className="po-point-history-previous-exp-date">
                        expire {formatDate(record.lastMonthExpDate)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}
    </React.Fragment>
  );
};

PointOverviewDesktop.propTypes = {
  data: PropTypes.array,
  customer: PropTypes.string,
  history: PropTypes.object,
  links: PropTypes.any
};

export default withRouter(PointOverviewDesktop);
