import React, { useState, useEffect, useContext } from "react";
import { ContextConfig } from "../../../App";
import {
  getPivotItems,
  getPivotItemsAttributes
} from "../../../api/DashboardApi";
import { toast } from "react-toastify";
import { addDays, formatDate } from "../../../utils";
import { Row, Col } from "reactstrap";
import DatePicker from "react-datepicker";
import Spinner from "../../../common/Spinner";
import {
  PivotViewComponent,
  FieldList,
  Inject,
  GroupingBar,
  CalculatedField
} from "@syncfusion/ej2-react-pivotview";
import "./Pivot.css";
import { debounce } from "../../../fp";

const Syncfusion = () => {
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(new addDays(Date(), -2));
  const [endDate, setEndDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  // const [categories, setCategories] = useState([]);
  // const [subs, setSubs] = useState([]);
  // const [totalizers, setTotalizers] = useState([]);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const context = useContext(ContextConfig);

  useEffect(() => {
    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);

    findDivHeight();

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, [startDate, endDate]);

  const findDivHeight = () => {
    const navbar = document
      .getElementById("master-navbar")
      .getBoundingClientRect().height;
    const controls = document
      .getElementById("pivot-controls")
      .getBoundingClientRect().height;
    const usedSpace = navbar + controls + 100;
    const remaining = window.innerHeight - usedSpace;
    const body = document.getElementById("pivot-body");
    body.style.height = remaining + "px";
    body.style.overflow = "auto";
  };

  const loadItemTemplate = () => {
    loadItemAttributes()
      .then(() => {
        console.log("finished loading attributes");
        loadItemPivot({});
      })
      .catch((err) => {
        console.log("rejected", err);
      });
  };

  const loadItemPivot = (config) => {
    setIsLoading(true);

    const { rowSchema, columnSchema, valueSchema } = config;
    return new Promise((resolve, reject) => {
      getPivotItems(
        context.url,
        context.token,
        formatDate(startDate),
        formatDate(endDate),
        rowSchema,
        columnSchema,
        valueSchema
      )
        .then((response) => {
          setIsLoading(false);
          const j = response.data;
          if (j.error === 0) {
            setData(j.items);
            resolve();
          } else {
            setData([]);
            toast.error(j.msg, {
              position: toast.POSITION.TOP_LEFT
            });
            reject();
          }
        })
        .catch((err) => {
          setIsLoading(false);
          setData([]);
          console.log(err);
          toast.error("An error occured fetching your data", {
            position: toast.POSITION.TOP_LEFT
          });
          reject();
        });
    });
  };

  const loadItemAttributes = () => {
    return new Promise((resolve, reject) => {
      getPivotItemsAttributes(
        context.url,
        context.token,
        formatDate(startDate),
        formatDate(endDate)
      )
        .then((response) => {
          const j = response.data;
          if (j.error === 0) {
            // setSubs(j.subs);
            // setTotalizers(j.totalizers);
            // setCategories(j.categories);
            resolve();
          } else {
            // setSubs([]);
            // setTotalizers([]);
            // setCategories([]);
            toast.error(j.msg, {
              position: toast.POSITION.TOP_LEFT
            });
            reject();
          }
        })
        .catch((err) => {
          console.log(err);
          // setSubs([]);
          // setTotalizers([]);
          // setCategories([]);
          toast.error("An error occured fetching item attributes", {
            position: toast.POSITION.TOP_LEFT
          });
          reject();
        });
    });
  };

  const handlePreviousWeek = () => {
    setStartDate(addDays(startDate - 7));
    setEndDate(addDays(endDate, -7));
  };

  const handleStartDateChange = (e) => {
    setStartDate(e);
    setEndDate(addDays(e, -6));
  };

  const handleEndDateChange = (e) => {
    setEndDate(e);
    setStartDate(addDays(e, 6));
  };

  const handleNextWeek = () => {
    setStartDate(addDays(startDate, 7));
    setEndDate(addDays(endDate, 7));
  };

  const dataSourceSettings = {
    columns: [{ name: "f254", caption: "Date" }],
    dataSource: data,
    expandAll: false,
    filters: [],
    formatSettings: [
      { name: "f254", format: "MM/DD/YYYY" },
      { name: "f65", format: "C2" }
    ],
    drilledMembers: [{ name: "f17", items: ["f02"] }],
    rows: [{ name: "f17" }, { name: "f02" }],
    values: [
      { name: "f65", caption: "Units Sold" },
      { name: "Total", caption: "Total Units", type: "CalculatedField" }
    ],
    calculatedFieldSettings: [{ name: "Total", formula: '"Sum(f65)"' }]
  };

  return (
    <div>
      {isLoading && <Spinner />}
      <div className="container">
        <div id="pivot-controls">
          <div className="row pivot-controls">
            <div
              className="col align-items-center justify-content-center d-flex mt-1"
              onClick={handlePreviousWeek}
            >
              <i
                className="far fa-backward"
                data-tip="Previous Week"
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  color: "#fff",
                  cursor: "pointer"
                }}
              ></i>
            </div>
            <div className="col align-items-center justify-content-center d-flex mt-1">
              Start:&nbsp;
              <DatePicker
                selected={startDate}
                onChange={handleStartDateChange}
              />
            </div>
            <div className="col align-items-center justify-content-center d-flex mt-1">
              End:&nbsp;
              <DatePicker selected={endDate} onChange={handleEndDateChange} />
            </div>
            <div
              className="col align-items-center justify-content-center d-flex mt-1"
              onClick={handleNextWeek}
            >
              <i
                className="far fa-forward"
                data-tip="Next Week"
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  color: "#fff",
                  cursor: "pointer"
                }}
              ></i>
            </div>
          </div>
          <div className="pivot-templates mt-3">
            <Row className="justify-content-center">
              <Col xs="2">
                <button className="btn btn-primary" onClick={loadItemTemplate}>
                  Items
                </button>
              </Col>
              <Col xs="2">
                <div className="salesStyle">Sales</div>
              </Col>
              <Col xs="2">
                <div className="testStyle">Departments</div>
              </Col>
              <Col xs="2">
                <div className="SalesStyle">Subs</div>
              </Col>
            </Row>
          </div>
        </div>
        <div id="pivot-body">
          <PivotViewComponent
            dataSourceSettings={dataSourceSettings}
            showFieldList={true}
            showGroupingBar={true}
            allowCalculatedField={true}
            height={"100%"}
          >
            <Inject services={[FieldList, GroupingBar, CalculatedField]} />
          </PivotViewComponent>
        </div>
      </div>
    </div>
  );
};

export default Syncfusion;
