import React, { useState, useEffect, useContext } from "react";
import { ContextConfig } from "../../App";
import { getApproachingForty } from "../../api/punchApi";
import { toast } from "react-toastify";
import Approaching40Spinner from "./Approaching40Spinner";
import "./ApproachingFourty.css";
import { variables } from "../../common/variables";
import { Line } from "react-chartjs-2";
import { calculateHours } from "../clockUtils";

const colors = [
  [246, 72, 97],
  [237, 50, 38],
  [203, 45, 214],
  [79, 38, 237],
  [227, 122, 36],
  [182, 88, 174],
  [62, 184, 173],
  [71, 133, 194]
];

const ApproachingForty = () => {
  const [hours, setHours] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dateArray, setDateArray] = useState([]);
  const [employees, setEmployees] = useState([]);

  const context = useContext(ContextConfig);

  const parseShortDate = (date) => {
    var newDate = new Date(date);
    var month = newDate.getMonth() + 1,
      day = newDate.getDate(),
      year = newDate.getFullYear();

    return month + "/" + day + "/" + year;
  };

  useEffect(() => {
    console.log("loading approaching 49");
    loadHours();
  }, [context.startDate]);

  const loadHours = () => {
    setIsLoading(true);
    getApproachingForty(
      context.clockUrl,
      context.clockToken,
      context.lastClockStore,
      context.startDate,
      context.endDate
    )
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          setHours(JSON.parse(j.hours));
          parseDates(JSON.parse(j.hours));
          parseEmployees(JSON.parse(j.hours));
        } else {
          setHours([]);
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setHours([]);
        console.log(err);
        toast.error(
          "An error occured getting the punches for the approaching 40 chart",
          {
            position: toast.POSITION.TOP_LEFT
          }
        );
      });
  };

  const parseDates = (data) => {
    const newArray = [];

    data.map((record) => {
      const date = parseShortDate(record.punchdate);
      if (!newArray.includes(date)) {
        newArray.push(date);
      }
    });

    setDateArray(newArray);
  };

  const parseEmployees = (data) => {
    const newArray = [];
    const record = data[0];
    for (var property in record) {
      if (property != "punchdate") {
        newArray.push(property);
      }
    }
    setEmployees(newArray);
  };

  const mapEmployees = (gradientStroke) => {
    if (employees.length === 0) return [];
    const newArray = employees.map((e) => gatherHours(e, gradientStroke));

    return newArray;
  };

  const getArrayMax = (arr) => {
    if (arr.length === 0) return 0;

    return arr[arr.length - 1];
  };

  const gatherHours = (employee, ctx) => {
    if (typeof employee === "undefined" || employee === null) return [];

    const newArray = [];
    hours.map((record) => {
      for (var property in record) {
        if (property == employee) {
          const empHours =
            parseFloat(getArrayMax(newArray)) +
            parseFloat(calculateHours(record[property], context.settings));
          newArray.push(parseFloat(empHours).toFixed(1));
        }
      }
    });

    var gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
    const color = colors[Math.floor(Math.random() * colors.length)];
    gradientStroke.addColorStop(
      1,
      `rgba(${color[0]}, ${color[1]}, ${color[2]}, 0.2)`
    );
    gradientStroke.addColorStop(
      0.8,
      `rgba(${color[0]}, ${color[1]}, ${color[2]}, 0.1)`
    );
    gradientStroke.addColorStop(
      0,
      `rgba(${color[0]}, ${color[1]}, ${color[2]}, 0.0)`
    );

    const dataset = {
      label: employee.replace(" ", "_"),
      fill: true,
      backgroundColor: gradientStroke,
      borderColor: `rgb(${color[0]}, ${color[1]}, ${color[2]})`,
      borderWidth: 2,
      borderDash: [],
      borderDashOffset: 0.0,
      pointBackgroundColor: `rgb(${color[0]}, ${color[1]}, ${color[2]})`,
      pointBorderColor: "rgba(255,255,255,0)",
      pointHoverBackgroundColor: `rgb(${color[0]}, ${color[1]}, ${color[2]})`,
      pointBorderWidth: 20,
      pointHoverRadius: 4,
      pointHoverBorderWidth: 15,
      pointRadius: 4,
      data: newArray
    };

    return dataset;
  };

  const chart = {
    data: (canvas) => {
      let ctx = canvas.getContext("2d");
      return {
        labels: dateArray,
        datasets: mapEmployees(ctx)
      };
    },
    options: {
      maintainAspectRatio: true,
      legend: {
        display: true
      },
      tooltips: {
        backgroundColor: "#f5f5f5",
        titleFontColor: "#333",
        bodyFontColor: "#666",
        bodySpacing: 4,
        xPadding: 12,
        mode: "nearest",
        intersect: 0,
        position: "nearest"
      },
      responsive: true,
      scales: {
        yAxes: [
          {
            barPercentage: 1.6,
            gridLines: {
              drawBorder: false,
              color: "rgba(29,140,248,0.0)",
              zeroLineColor: "transparent"
            },
            ticks: {
              suggestedMin: 0,
              suggestedMax: 40,
              padding: 20,
              fontColor: "#9e9e9e"
            }
          }
        ],
        xAxes: [
          {
            barPercentage: 1.6,
            gridLines: {
              drawBorder: false,
              color: "rgba(29,140,248,0.1)",
              zeroLineColor: "transparent"
            },
            ticks: {
              padding: 20,
              fontColor: "#9e9e9e"
            }
          }
        ]
      }
    }
  };

  return (
    <div style={variables.widgetStyle} className="a40">
      {isLoading && <Approaching40Spinner />}
      <h3>Approaching 40</h3>
      <Line
        data={chart["data"]}
        options={chart.options}
        style={{ maxHeight: "200px", height: "100px" }}
      />
    </div>
  );
};

export default ApproachingForty;
