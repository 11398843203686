import React, { useState } from "react";
import PropTypes from "prop-types";
import { formatDate, addDays } from "../../utils";
import ConfirmDelete from "../../common/ConfirmDelete";
import { deleteShift } from "../../api/scheduleApi";
import { toast } from "react-toastify";

import Shift from "./Shift";

const Employee = (props) => {
  const {
    user,
    days,
    shifts,
    startDate,
    handleDragStart,
    context,
    reloadSchedule,
    setReloadSchedule,
    handleDateClick,
    editShift,
    handleDrop,
    dragNode,
    pto
  } = props;
  const [shiftToDelete, setShiftToDelete] = useState(0);
  const [confirmText, setConfirmText] = useState("");
  const [isShowing, setIsShowing] = useState(false);
  const [error, setError] = useState("");

  const hide = () => {
    setIsShowing(false);
  };

  const getEmployeeTotal = () => {
    if (typeof shifts === "undefined" || shifts === null) return;
    if (typeof startDate === "undefined" || startDate === null) return;

    const totalMinutes = shifts.reduce((acc, cur) => {
      return parseInt(acc) + parseInt(cur.totalMinutes);
    }, 0);

    const total = totalMinutes * 0.016667;
    return parseFloat(total).toFixed(context.settings.timePrecision);
  };

  const findShift = (day) => {
    if (typeof shifts === "undefined" || shifts === null) return;
    if (typeof startDate === "undefined" || startDate === null) return;

    const shift = shifts.filter(
      (s) => formatDate(s.punchDate) === formatDate(addDays(startDate, day))
    );

    if (shift.length === 0) return null;

    if (shift.length > 1) {
      const ptoRecord = pto.filter(
        (p) =>
          formatDate(new Date(p.requestStart)) ==
          formatDate(new Date(shift[0].punchDate))
      );
      return shift.map((s, i) => (
        <Shift
          key={`shift-${i}`}
          shift={s}
          user={user}
          handleDragStart={handleDragStart}
          day={day}
          deletePunchSetup={deletePunchSetup}
          editShift={editShift}
          ptoRecord={ptoRecord[0]}
          context={context}
        />
      ));
    } else {
      const ptoRecord = pto.filter(
        (p) =>
          formatDate(new Date(p.requestStart)) ==
          formatDate(new Date(shift[0].punchDate))
      );
      return (
        <Shift
          user={user}
          shift={shift[0]}
          handleDragStart={handleDragStart}
          day={day}
          deletePunchSetup={deletePunchSetup}
          editShift={editShift}
          ptoRecord={ptoRecord[0]}
          context={context}
        />
      );
    }
  };

  const deletePunchSetup = (id) => {
    setShiftToDelete(id);
    setIsShowing(true);
  };

  const setText = (e) => {
    setConfirmText(e.target.value);
  };

  const delShift = () => {
    deleteShift(context.clockUrl, context.clockToken, shiftToDelete)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          // now we just need to reload our punches
          setReloadSchedule(!reloadSchedule);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION_TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An Internal error occured", {
          postition: toast.POSITION_TOP_LEFT
        });
      });
  };

  const compareDeleteText = () => {
    if (confirmText === "delete me") {
      setIsShowing(false);
      setConfirmText("");
      delShift();
      setError("");
    } else {
      setError(`Sorry, "${confirmText}" does not match "delete me"`);
    }
  };

  const handleDragOver = (e) => {
    if (e.target != dragNode.current) {
      //e.target.classList.add("drag-over");
      e.target.style.backgroundColor = "#4e4b73";
    }
    e.stopPropagation();
    e.preventDefault();
  };

  const handleBadDragOver = (e) => {
    e.target.style.backgroundColor = "red";
    e.stopPropagation();
    e.preventDefault();
  };

  const handleDragLeave = (e) => {
    e.target.style.backgroundColor = "#1f1e2e";
  };

  const handleBadDragLeave = (e) => {
    e.target.style.backgroundColor = "#1f1e2e";
  };

  const isDisabled = (e) => {
    let result = {
      type: "",
      hours: 0,
      indicator: 0
    };
    const dateToCheck = addDays(startDate, parseInt(e));
    pto.map((ptoRecord) => {
      const ptoStart = new Date(ptoRecord.requestStart);
      const ptoEnd = new Date(ptoRecord.requestEnd);
      if (dateToCheck >= ptoStart && dateToCheck <= ptoEnd) {
        if (ptoRecord.hours < 7 && ptoRecord.type == "2") {
          result = {
            type: 3,
            hours: parseInt(ptoRecord.hours),
            indicator: parseInt(ptoRecord.type)
          };
        } else if (ptoRecord.hours < 7 && ptoRecord.type == "1") {
          result = {
            type: 4,
            hours: parseInt(ptoRecord.hours),
            indicator: parseInt(ptoRecord.type)
          };
        } else {
          result = {
            type: parseInt(ptoRecord.type),
            hours: parseInt(ptoRecord.hours),
            indicator: parseInt(ptoRecord.type)
          };
        }
      }
    });

    return result;
  };

  return (
    <div className="employee-row">
      <ConfirmDelete
        isShowing={isShowing}
        hide={hide}
        confirmText={confirmText}
        setConfirmText={setText}
        compareDeleteText={compareDeleteText}
        error={error}
        title={"Delete Shift"}
        instructionsText={"delete me"}
      />
      <div className="employee-name">
        <div>
          {user.firstName} {user.lastName}
        </div>
        <div>#: {user.employeeId}</div>
        <div>Userid: {user.userid}</div>
      </div>
      {days.map((day, i) => {
        const { type, indicator } = isDisabled(day);
        switch (type) {
          case 2:
            return (
              <div
                key={`day=${i}`}
                className="cell day-cell disabled-cell"
                onDragOver={(e) => handleBadDragOver(e)}
                onDragLeave={(e) => handleBadDragLeave(e)}
                onDrop={(e) => props.handleBadDrop(e)}
              >
                <div className="disabled-cell-content">
                  {indicator == 1 ? "PTO" : null}
                  {indicator == 2 ? "RTO" : null}
                  <div style={{ display: "none" }}>{findShift(day)}</div>
                </div>
              </div>
            );
          case 3:
            return (
              <div
                key={`day=${i}`}
                className="cell day-cell"
                onDoubleClick={() => handleDateClick(day, user)}
                onDragOver={(e) => handleDragOver(e)}
                onDragLeave={(e) => handleDragLeave(e)}
                onDrop={(e) => handleDrop(e, { day, user })}
              >
                <div className="cell pto-cell">
                  {indicator == 1 ? "P PTO" : null}
                  {indicator == 2 ? "P RTO" : null}
                </div>
                {findShift(day)}
              </div>
            );
          default:
            return (
              <div
                key={`day=${i}`}
                className="cell day-cell"
                onDoubleClick={() => handleDateClick(day, user)}
                onDragOver={(e) => handleDragOver(e)}
                onDragLeave={(e) => handleDragLeave(e)}
                onDrop={(e) => handleDrop(e, { day, user })}
              >
                {findShift(day)}
              </div>
            );
        }
      })}

      <div className="cell employee-total">{getEmployeeTotal()}</div>
    </div>
  );
};

Employee.propTypes = {
  user: PropTypes.object,
  days: PropTypes.array,
  startDate: PropTypes.instanceOf(Date),
  shifts: PropTypes.array,
  handleDragStart: PropTypes.func,
  dragging: PropTypes.bool,
  context: PropTypes.object,
  reloadSchedule: PropTypes.bool,
  setReloadSchedule: PropTypes.func,
  handleDateClick: PropTypes.func,
  editShift: PropTypes.func,
  handleDrop: PropTypes.func,
  dragNode: PropTypes.any,
  pto: PropTypes.array,
  handleBadDrop: PropTypes.func
};

export default Employee;
