/* eslint-disable react/prop-types */
import React from "react";

const Controls = (props) => {
  return (
    <div className="mt-1 mb-2 d-flex justify-content-end">
      <button className="btn btn-primary" onClick={props.addWhere}>
        Add Where
      </button>
    </div>
  );
};

export default Controls;
