import React, { useState, useEffect, useContext } from "react";
import HeaderRB from "../../common/HeaderRb";
import { ContextConfig } from "../../App";
import { usePrevious, truncateString, convertAmount } from "../../utils";
import { readGroupSingle } from "../../api/ReportsApi";
import { toast } from "react-toastify";
import MobileReportController from "../MobileReportController";
import Spinner from "../../common/Spinner";
import { debounce, isEqual } from "../../fp";

const GroupSingle = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [rerender, setRerender] = useState(false);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const context = useContext(ContextConfig);

  const previousData = usePrevious(data);
  const previousRerender = usePrevious(rerender);

  useEffect(() => {
    if (!isEqual(previousData, data)) {
      loadData();
    }
    if (!isEqual(previousRerender, rerender)) {
      loadData();
    }

    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);

    findDivHeight();

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, [data, rerender]);

  const findDivHeight = () => {
    const navbar = document
      .getElementById("master-navbar")
      .getBoundingClientRect().height;
    const controls = document
      .getElementById("r-controls")
      .getBoundingClientRect().height;
    const usedSpace = navbar + controls + 30;
    const remaining = window.innerHeight - usedSpace;

    const body = document.getElementById("r-body");
    body.style.height = remaining + "px";
    body.style.overflow = "auto";
  };

  const loadData = () => {
    setIsLoading(true);
    readGroupSingle(
      context.url,
      context.token,
      context.endDate,
      context.lastStoreid
    )
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          setData(j.items);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  return (
    <React.Fragment>
      {isLoading && <Spinner />}
      <HeaderRB />
      <div id="r-controls">
        <div className="header-spacer">&nbsp;</div>
        <MobileReportController rerender={rerender} setRerender={setRerender} />
        <div className="container-fluid">
          <div className="rpt-name">Group Single</div>
        </div>
      </div>
      <div id="r-body">
        {data.length === 0 ? (
          <div>No records were returned</div>
        ) : (
          <table>
            <thead>
              <tr>
                <th style={{ width: "50%" }}>Group</th>
                <th style={{ width: "10%", textAlign: "right" }}>Qty</th>
                <th style={{ width: "20%", textAlign: "right" }}>Sales</th>
                <th style={{ width: "10%", textAlign: "right" }}>%</th>
              </tr>
            </thead>
            <tbody>
              {data.map((record, index) => (
                <tr key={index}>
                  <td>{truncateString(record.f1132, 24)}</td>
                  <td style={{ textAlign: "right" }}>{record.f64}</td>
                  <td style={{ textAlign: "right" }}>{record.f65}</td>
                  <td style={{ textAlign: "right" }}>{record.percent}</td>
                </tr>
              ))}
              <tr style={{ borderTop: "1px solid black" }}>
                <td style={{ fontWeight: "bold" }}>Total</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td style={{ fontWeight: "bold", textAlign: "right" }}>
                  {convertAmount(
                    data.reduce((acc, cur) => {
                      return acc + parseFloat(cur.f65);
                    }, 0)
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
    </React.Fragment>
  );
};

export default GroupSingle;
