import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import HeaderRB from "../../common/HeaderRb";
import { ContextConfig } from "../../App";
import { flyoutDetails } from "../../api/CashierApi";
import { toast } from "react-toastify";
import { usePrevious } from "../../utils";
import { isEqual } from "lodash";
import { BrowserView, MobileView, isBrowser } from "react-device-detect";
import { gridEnums } from "../../api/enums";
import MobileTot from "./MobileTot";
import DesktopTot from "./DesktopTot";
import Spinner from "../../common/Spinner";

const Totalizers = ({ cashier }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [totalizers, setTotalizers] = useState([]);
  const [dates, setDates] = useState([]);

  const context = useContext(ContextConfig);

  const previousTotalizers = usePrevious(totalizers);

  useEffect(() => {
    if (!isEqual(previousTotalizers, totalizers)) {
      getTotalizers();
    }

    if (totalizers.length > 0) {
      parseDates();
    }
  }, [cashier, totalizers]);

  const getTotalizers = () => {
    setIsLoading(true);
    let startDate = context.endDate;
    if (isBrowser) {
      startDate = context.startDate;
    }
    flyoutDetails(
      context.url,
      context.token,
      startDate,
      context.endDate,
      context.selectedRecord.storeid,
      context.cashier.f1185
    )
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          context.totalizers = j.details;
          setTotalizers(j.details);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  function includesFieldValue(field, value) {
    for (var i = 0; i < this.length; i++) {
      var record = this[i];
      if (record[field] == value) {
        return true;
      }
    }
    return false;
  }

  Array.prototype.includesFieldValue = includesFieldValue;

  function distinct(x) {
    const newArray = [];

    for (var i = 0; i < this.length; i++) {
      var record = this[i];
      if (!newArray.includesFieldValue(x, record[x])) {
        newArray.push({ [x]: record[x] });
      }
    }

    return newArray;
  }

  Array.prototype.distinct = distinct;

  const parseDates = () => {
    const newArray = totalizers.distinct("post_Date");
    setDates(newArray);
  };

  return (
    <React.Fragment>
      <HeaderRB />
      {isLoading && <Spinner />}
      {totalizers.length === 0 ? (
        <div key="0">{gridEnums.emptyGrid}</div>
      ) : (
        <React.Fragment>
          <BrowserView>
            <DesktopTot data={totalizers} context={context} dates={dates} />
          </BrowserView>
          <MobileView>
            <MobileTot data={totalizers} context={context} />
          </MobileView>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

Totalizers.propTypes = {
  cashier: PropTypes.object
};

export default Totalizers;
