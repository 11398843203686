import React from "react";
import { useTable } from "react-table";

// const testGroups = [
//   { name: "Ace", value: 1 },
//   { name: "Priceless", value: 2 },
//   { name: "IGA", value: 3 },
//   { name: "Crossroads", value: 4 },
//   { name: "Hanks", value: 5 }
// ];

const GridFile = () => {
  //   const data = React.useMemo(
  //     () => [
  //       {
  //         storeid: 1,
  //         storeName: "Mikes Test Store",
  //         termCount: 3,
  //         group: 2,
  //         active: 1
  //       },
  //       {
  //         storeid: 2,
  //         storeName: "Tommys Test Store",
  //         termCount: 4,
  //         group: 3,
  //         active: 1
  //       },
  //       {
  //         storeid: 3,
  //         storeName: "Tjs Test Store",
  //         termCount: 5,
  //         group: 4,
  //         active: 0
  //       }
  //     ],
  //     []
  //   );
  const data = React.useMemo(
    () => [
      {
        storeid: 1,
        storeName: "Mikes Test Store",
        termCount: 4,
        group: 2,
        active: 1
      },
      {
        storeid: 2,
        storeName: "Little Bobs Grocery",
        termCount: 3,
        group: 1,
        active: 1
      },
      {
        storeid: 3,
        storeName: "Tommys Test Store",
        termCount: 5,
        group: 5,
        active: 1
      }
    ],
    []
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "storeid"
      },
      {
        Header: "Name",
        accessor: "storeName"
      },
      {
        Header: "Terms",
        accessor: "termCount"
      },
      {
        Header: "Group",
        accessor: "group"
      },
      {
        Header: "Active",
        accessor: "active"
      }
    ],
    []
  );

  //   const columns = React.useMemo(
  //     () => [
  //       {
  //         Header: "ID",
  //         accessor: "storeid" // accessor is the "key" in the data
  //       },
  //       {
  //         Header: "Name",
  //         accessor: "storeName"
  //       },
  //       {
  //         Header: "Terms",
  //         accessor: "termCount"
  //       },
  //       {
  //         Header: "Group",
  //         accessor: "group"
  //       },
  //       {
  //         Header: "Active",
  //         accessor: "active"
  //       }
  //     ],
  //     []
  //   );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({ columns, data });

  return (
    <div className="container">
      <table {...getTableProps()} style={{ border: "solid 1px blue" }}>
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr key={`header-${index}`} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => (
                <th
                  key={`col-${index}`}
                  {...column.getHeaderProps()}
                  style={{
                    borderBottom: "solid 3px red",
                    background: "aliceblue",
                    color: "black",
                    fontWeight: "bold"
                  }}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row);
            return (
              <tr key={`row-${index}`} {...row.getRowProps()}>
                {row.cells.map((cell, index) => {
                  return (
                    <td
                      key={`cel-${index}`}
                      {...cell.getCellProps()}
                      style={{
                        padding: "10px",
                        border: "solid 1px gray",
                        background: "papayawhip"
                      }}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default GridFile;
