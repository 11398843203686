import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { ContextConfig } from "../../App";
import "./DesktopCashierGrid.css";
import DesktopChromeCart from "./DesktopChromeCart";
import { useSpring, animated } from "react-spring";

const DesktopCashierGrid = ({ stores }) => {
  const [redirect, setRedirect] = useState(false);
  const fade = useSpring({ from: { opacity: 0 }, opacity: 1 });

  const context = useContext(ContextConfig);

  const handleClick = (selectedRecord) => {
    context.selectedRecord = selectedRecord;
    setRedirect(true);
  };

  useEffect(() => {}, []);

  const AnimatedChromeChart = animated(DesktopChromeCart);

  return (
    <div className="container-fluid" id="desktop-cashier-grid">
      {redirect && <Redirect to="flyout" />}
      {stores.length == 0 ? (
        <div className="empty-cashier-stores">
          There are no records to display
        </div>
      ) : (
        <div className="row justify-content-center p-5">
          {stores.map((store, index) => (
            <AnimatedChromeChart
              key={index}
              store={store}
              handleClick={handleClick}
              style={fade}
            />
          ))}
        </div>
      )}
    </div>
  );
};

DesktopCashierGrid.propTypes = {
  stores: PropTypes.array
};

export default DesktopCashierGrid;
