import axios from "axios";
import qs from "qs";

async function readCompanies(url, token) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "companies"
  });
  return json;
}

async function createCompany(url, token, company) {
  let json = await axios({
    method: "POST",
    cors: true,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: "Bearer " + token
    },
    url: url + "companies",
    data: qs.stringify({
      company_id: company.company_Id,
      company_name: company.company_Name,
      company_address: company.company_Address,
      company_city: company.company_City,
      company_state: company.company_State,
      company_zip: company.company_Zip,
      company_phone: company.company_Phone
    })
  });
  return json;
}

async function updateCompany(url, token, company) {
  let json = await axios({
    method: "PUT",
    cors: true,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: "Bearer " + token
    },
    url: url + "companies",
    data: qs.stringify({
      company_Id: company.company_Id,
      company_name: company.company_Name,
      company_address: company.company_Address,
      company_city: company.company_City,
      company_state: company.company_State,
      company_zip: company.company_Zip,
      company_phone: company.company_Phone
    })
  });
  return json;
}

async function deleteCompany(url, token, id) {
  let json = await axios({
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "companies",
    params: {
      id
    }
  });
  return json;
}

export { createCompany, readCompanies, updateCompany, deleteCompany };
