import * as actions from "../actions/actionTypes";

const initialState = {
  reRender: false,
  endDate: new Date(),
  stores: [],
  store: ""
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.APP_SET_END_DATE:
      return {
        ...state,
        endDate: action.endDate
      };
    case actions.APP_SET_START_DATE:
      return {
        ...state,
        startDate: action.startDate
      };
    case actions.APP_SET_STORES:
      return {
        ...state,
        stores: action.stores
      };
    case actions.APP_SET_STORE:
      return {
        ...state,
        store: action.store
      };
    case actions.APP_SET_RERENDER:
      return {
        ...state,
        reRender: !state.reRender
      };
    default:
      return state;
  }
};

export default appReducer;
