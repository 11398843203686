import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  TimePicker,
  DatePicker
} from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
import "./ModalPunch.css";
import "../widgets/animations.css";

const ModalPunch = ({
  isShowing,
  hide,
  punch,
  handleButtonClick,
  handleChange,
  handleDateInChange,
  handleInChange,
  handleDateOutChange,
  handleOutChange,
  modalClass,
  style
}) =>
  isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className="modal-punch-overlay" />
            <div
              className="modal-punch-wrapper"
              aria-modal
              aria-hidden
              tabIndex={-1}
              role="dialog"
            >
              <div
                className={`modal-punch ${modalClass}`}
                style={style}
                id="modal-punch"
              >
                <div className="modal-punch-header">
                  <span className="modal-punch-confirm-title">Edit Punch</span>
                  <span className="modal-punch-close-box">
                    <button
                      type="button"
                      className="modal-punch-close-button"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={hide}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </span>
                </div>
                <div className="modal-punch-body">
                  <form className="form">
                    <div className="form-row">
                      <div className="col-3">
                        <label htmlFor="punchid">Id</label>
                        <input
                          className="form-control"
                          type="text"
                          readOnly
                          value={punch.id}
                          style={{ textAlign: "center", color: "#fff" }}
                        />
                      </div>
                      <div className="col-3">
                        <label htmlFor="employeeNumber">Emp ID</label>

                        <input
                          type="text"
                          className="form-control"
                          name="employeeNumber"
                          readOnly
                          style={{ color: "#fff" }}
                          value={punch.employeeNumber}
                        />
                      </div>
                      <div className="col-6">
                        <label htmlFor="username">User</label>
                        <input
                          type="text"
                          className="form-control"
                          name="username"
                          readOnly
                          style={{ color: "#fff" }}
                          value={punch.username}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-2">
                        <label htmlFor="punchtype">Type</label>
                        <input
                          type="text"
                          readOnly
                          style={{ color: "#fff" }}
                          className="form-control"
                          value={punch.punchType}
                        />
                      </div>
                      <div className="col-10">
                        <label htmlFor="comment">Comment</label>
                        <input
                          type="text"
                          className="form-control"
                          name="comment"
                          value={punch.comment}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <Grid container justify="space-around">
                        <div className="col">
                          <DatePicker
                            margin="normal"
                            label="In"
                            value={punch.punchInDate}
                            onChange={handleDateInChange}
                          />
                          <TimePicker
                            margin="normal"
                            value={punch.rawPunchIn}
                            onChange={handleInChange}
                          />
                        </div>

                        <div className="col">
                          <DatePicker
                            margin="normal"
                            label="Out"
                            value={punch.punchOutDate || new Date()}
                            onChange={handleDateOutChange}
                          />
                          <TimePicker
                            margin="normal"
                            value={punch.rawPunchOut}
                            onChange={handleOutChange}
                          />
                        </div>

                        <div className="col" style={{ width: "75px" }}>
                          <div>
                            <label htmlFor="total">Total</label>
                            <input
                              type="text"
                              className="form-control"
                              name="total"
                              readOnly
                              style={{ color: "#fff" }}
                              value={punch.total}
                            />
                          </div>
                          <div>
                            <button
                              className="btn btn-outline-dark"
                              style={{
                                marginTop: "15px",
                                textAlign: "center",
                                width: "100%"
                              }}
                              onClick={handleButtonClick}
                            >
                              <i className="far fa-save"></i> Save
                            </button>
                          </div>
                        </div>
                      </Grid>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </MuiPickersUtilsProvider>
        </React.Fragment>,
        document.body
      )
    : null;

ModalPunch.propTypes = {
  isShowing: PropTypes.bool,
  hide: PropTypes.func,
  punch: PropTypes.object,
  handleButtonClick: PropTypes.func,
  handleChange: PropTypes.func,
  handleDateInChange: PropTypes.func,
  handleInChange: PropTypes.func,
  handleDateOutChange: PropTypes.func,
  handleOutChange: PropTypes.func,
  modalClass: PropTypes.string,
  style: PropTypes.object
};

export default ModalPunch;
