import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import "../../../Header/react-datepicker.css";
import { isValid } from "../../../utils";
import { ContextConfig } from "../../../App";
import { readGroupAsync } from "../../../api/GroupApi";
import { getAssignedStoresAsync } from "../../../api/StoreApi";
import { toast } from "react-toastify";

const DesktopHeader = ({
  pickerData,
  reRender,
  setReRender,
  endDate,
  setEndDate,
  topCount,
  setTopCount
}) => {
  const [showGroups, setShowGroups] = useState(false);
  const [searchType, setSearchType] = useState(1);
  const [groups, setGroups] = useState([]);
  // eslint-disable-next-line
  const [group, setGroup] = useState(0);
  const [assignedStores, setAssignedStores] = useState([]);

  const context = useContext(ContextConfig);

  useEffect(() => {
    loadGroupsForPicker();
    loadStores();
  }, [searchType, showGroups]);

  const handleSelectionChange = (e) => {
    if (parseInt(e.target.value) === 2) {
      context.lastSearchType = 2;
      setSearchType(2);
      setShowGroups(true);
    } else if (parseInt(e.target.value) === 3) {
      context.lastSearchType = 3;
      setSearchType(3);
    } else {
      context.lastSearchType = 1;
      setShowGroups(false);
    }
    setReRender(!reRender);
  };

  const handleGroupChange = (e) => {
    setGroup(e.target.value);
    context.lastSearchType = 2;
    context.lastGroup = e.target.value;
    setReRender(!reRender);
  };

  const handleStoreChange = (e) => {
    context.lastStoreid = e.target.value;
    context.lastSearchType = 3;
    setReRender(!reRender);
  };

  const handleEndDateChange = (e) => {
    context.endDate = e;
    setEndDate(context.endDate);
    setReRender(!reRender);
  };

  const loadGroupsForPicker = () => {
    if (!isValid(context)) return;
    if (!isValid(context.token)) return;
    readGroupAsync(context.url, context.token)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setGroups(j.groups);
        } else {
          setGroups([]);
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setGroups([]);
        console.log(err);
        toast.error("An error occured getting your groups", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const loadStores = () => {
    if (!isValid(context)) return;
    if (!isValid(context.token)) return;
    getAssignedStoresAsync(context.url, context.token)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setAssignedStores(j.items);
        } else {
          setAssignedStores([]);
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setAssignedStores([]);
        console.log(err);
        toast.error("An error occured getting your assigned Stores", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  return (
    <form className="form-inline" style={{ margin: "auto" }}>
      <div className="form-group row w-75 justify-context-center m-auto">
        <div className="col-lg-3 col-md-6 col-sm=12 align-items-center d-flex mt-1">
          <span style={{ marginRight: "10px" }}>Selection Type:</span>
          <select
            className="form-control"
            id="comboSelectionType"
            value={context.lastSearchType}
            onChange={handleSelectionChange}
          >
            {pickerData.map((record) => (
              <option key={record.id} value={record.id}>
                {record.name}
              </option>
            ))}
          </select>
        </div>

        {context.lastSearchType == 1 ? (
          <div className="col-lg-3 col-md-6 col-sm=12 align-items-center justify-content-center d-flex mt-1">
            &nbsp;
          </div>
        ) : null}

        {context.lastSearchType == 2 ? (
          <div className="col-lg-3 col-md-6 col-sm=12 align-items-center justify-content-center d-flex mt-1">
            <div>
              <span style={{ marginRight: "10px" }}>Group:</span>
              <select
                id="comboGroup"
                className="form-control"
                onChange={handleGroupChange}
                value={context.lastGroup}
              >
                <option value="0">Select a gropus</option>
                {groups.length === 0
                  ? null
                  : groups.map((group) => (
                      <option key={group.id} value={group.id}>
                        {group.group_name}
                      </option>
                    ))}
              </select>
            </div>
          </div>
        ) : null}

        {context.lastSearchType == 3 ? (
          <div className="col-lg-3 col-md-6 col-sm=12 align-items-center justify-content-center d-flex mt-1">
            <div>
              <span style={{ marginRight: "10px" }}>Store:</span>
              <select
                id="comboStores"
                className="form-control"
                onChange={handleStoreChange}
                value={context.lastStoreid}
              >
                {assignedStores.length === 0
                  ? null
                  : assignedStores.map((store) => (
                      <option key={store.storeid} value={store.storeid}>
                        {store.store_Name}
                      </option>
                    ))}
              </select>
            </div>
          </div>
        ) : null}

        <div className="col-lg-3 col-md-6 col-sm=12 align-items-center justify-content-center d-flex mt-1">
          <span style={{ marginRight: "10px" }}>End:</span>
          <DatePicker selected={endDate} onChange={handleEndDateChange} />
        </div>
        <div className="col align-items-center justify-content-center d-flex mt-1">
          <label>Top Item Count:</label>
          <input
            type="number"
            className="form-control"
            value={topCount}
            onChange={setTopCount}
            style={{ width: "100px", marginLeft: "10px" }}
          />
        </div>
      </div>
    </form>
  );
};

DesktopHeader.propTypes = {
  pickerData: PropTypes.array,
  reRender: PropTypes.bool,
  setReRender: PropTypes.func,
  endDate: PropTypes.any,
  setEndDate: PropTypes.func,
  topCount: PropTypes.any,
  setTopCount: PropTypes.func
};

export default DesktopHeader;
