import React from "react";
import PropTypes from "prop-types";
import Tooltip from "../Tooltip/Tooltip";
//import * as actions from "../../actions/actionTypes";

const SortableFilterableColumn = ({
  state,
  dispatch,
  title,
  sortable,
  sortField,
  filterable,
  filterField,
  sortDataType,
  dbField,
  sortFieldAction = "HT_SET_SORT_FIELD",
  filterFieldAction = "HT_SET_FILTER_FIELD",
  filterValueChangeAction = "HT_CHANGE_FILTER_VALUE",
  tooltip,
  maxInputWidth = "150px",
  ...restProps
}) => {
  return (
    <th style={restProps}>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: `${
            sortable && filterable
              ? "1fr 20px 20px"
              : sortable
              ? "1fr 20px"
              : filterable
              ? "1fr 20px"
              : null
          }`
        }}
      >
        {tooltip ? (
          <Tooltip message={tooltip} position={"left"}>
            <span>{title}</span>
          </Tooltip>
        ) : (
          <span>{title}</span>
        )}
        {sortable ? (
          <span
            onClick={() =>
              dispatch({
                type: sortFieldAction,
                field: dbField,
                sortState: sortField,
                sortDataType: sortDataType
              })
            }
          >
            {state[sortField] ? (
              <span style={{ cursor: "pointer" }}>
                <i className="fas fa-sort-down"></i>
              </span>
            ) : (
              <span style={{ cursor: "pointer" }}>
                <i className="fas fa-sort-up"></i>
              </span>
            )}
          </span>
        ) : null}

        {filterable ? (
          <React.Fragment>
            <span
              style={{ cursor: "pointer" }}
              onClick={() =>
                dispatch({
                  type: filterFieldAction,
                  filterState: filterField,
                  filterField: dbField,
                  dataType: sortDataType
                })
              }
            >
              <i className="far fa-filter"></i>
            </span>
            {state.filterField === dbField ? (
              <React.Fragment>
                {maxInputWidth ? (
                  <input
                    type="text"
                    className="form-control"
                    style={{ maxWidth: maxInputWidth }}
                    value={state.filteredValue}
                    onChange={(e) =>
                      dispatch({
                        type: filterValueChangeAction,
                        value: e.target.value
                      })
                    }
                  />
                ) : (
                  <input
                    type="text"
                    className="form-control"
                    value={state.filteredValue}
                    onChange={(e) =>
                      dispatch({
                        type: filterValueChangeAction,
                        value: e.target.value
                      })
                    }
                  />
                )}
              </React.Fragment>
            ) : null}
          </React.Fragment>
        ) : null}
      </div>
    </th>
  );
};

SortableFilterableColumn.propTypes = {
  state: PropTypes.object,
  dispatch: PropTypes.func,
  title: PropTypes.string,
  dispatchType: PropTypes.string,
  sortField: PropTypes.any,
  dbField: PropTypes.string,
  sortable: PropTypes.bool,
  filterable: PropTypes.bool,
  filterField: PropTypes.string,
  sortDataType: PropTypes.string,
  sortFieldAction: PropTypes.any,
  filterFieldAction: PropTypes.string,
  filterValueChangeAction: PropTypes.string,
  tooltip: PropTypes.any,
  maxInputWidth: PropTypes.string
};

export default SortableFilterableColumn;
