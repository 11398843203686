import React, { useState, useEffect, useContext } from "react";
import ClockDesktopMenu from "../ClockDesktopMenu";
import { readClockStores } from "../../api/StoreApi";
import { ContextConfig } from "../../App";
import { toast } from "react-toastify";
import { getConsents, createConsent } from "../../api/eodApi";
import Pin from "./Pin";
import { formatDate } from "../../utils";

const Consent = () => {
  const [stores, setStores] = useState([]);
  const [store, setStore] = useState(0);
  const [consents, setConsents] = useState([]);
  const [password, setPassword] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [consentRecord, setConsentRecord] = useState({});
  const [disclaimer, setDisclaimer] = useState("");

  const context = useContext(ContextConfig);

  useEffect(() => {
    getStoreList();
  }, []);

  useEffect(() => {
    fetchConsents();
  }, [store]);

  const getStoreList = () => {
    readClockStores(context.clockUrl, context.clockToken)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setStores(j.stores);
          if (context.lastClockStore) {
            setStore(context.lastClockStore);
          }
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An internal error has occured", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const fetchConsents = () => {
    getConsents(context.clockUrl, context.clockToken, store)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setConsents(j.consents);
          setDisclaimer(j.disclaimer);
        } else {
          setConsents([]);
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setConsents([]);
        console.log(err);
        toast.error("An internal error has occurred", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const changeStore = (e) => {
    setStore(e.target.value);
  };

  const verifyUser = (consent) => {
    setConsentRecord(consent);
    setShowModal(true);
  };

  const makeConsent = () => {
    createConsent(
      context.clockUrl,
      context.clockToken,
      consentRecord.id,
      consentRecord.employeeId,
      password
    )
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          toast.success("Your information has been submitted", {
            position: toast.POSITION.TOP_LEFT
          });
          hide();
          fetchConsents();
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .error((err) => {
        console.log(err);
        toast.error("An internal error has occurred", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const hide = () => {
    setShowModal(false);
  };

  return (
    <div>
      <ClockDesktopMenu />
      {showModal && (
        <Pin
          isShowing={showModal}
          hide={hide}
          password={password}
          setPassword={setPassword}
          makeConsent={makeConsent}
          disclaimer={disclaimer}
        />
      )}
      <div className="container" style={{ marginTop: "20px" }}>
        <div className="row justify-content-center align-items-center">
          <div className="col">
            <select
              className="form-control"
              onChange={changeStore}
              value={store}
            >
              <option value="0">Select a Store</option>
              {stores.length > 0 ? (
                <React.Fragment>
                  {stores.map((store, i) => (
                    <option key={`store-${i}`} value={store.id}>
                      {store.storeName}
                    </option>
                  ))}
                </React.Fragment>
              ) : null}
            </select>
          </div>
        </div>
        {consents.length === 0 ? (
          <div>There are no Records to show</div>
        ) : (
          <div className="justify-content-center align-items-center">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Period</th>
                  <th>Employee</th>
                  <th>Total Hours</th>
                  <th>Consent</th>
                </tr>
              </thead>
              <tbody>
                {consents.map((consent, i) => (
                  <tr key={`cs-${i}`}>
                    <td>{formatDate(consent.period_Date)}</td>
                    <td>{consent.employee}</td>
                    <th>{consent.hours_Worked}</th>
                    <td>
                      <button
                        onClick={() => verifyUser(consent)}
                        className="btn btn-primary"
                      >
                        Sign Off
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default Consent;
