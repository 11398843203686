import axios from "axios";
import qs from "qs";
import { formatDate } from "../utils";

async function createPunch(url, token, punch) {
  let json = await axios({
    method: "POST",
    cors: true,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: "Bearer " + token
    },
    url: url + "punches",
    data: qs.stringify({
      punch
    })
  });
  return json;
}

async function getUserPunches(url, token, userid, punchdate, storeid) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "punches",
    params: {
      userid,
      punchdate,
      storeid
    }
  });
  return json;
}

async function getUserPunchesByDates(
  url,
  token,
  userid,
  startdate,
  enddate,
  storeid,
  singleDay
) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "punches/userpunchesbydate",
    params: {
      userid,
      startDate: formatDate(startdate),
      endDate: formatDate(enddate),
      storeid,
      singleDay: singleDay ? 1 : 0
    }
  });
  return json;
}

async function deletePunch(url, token, id) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "punches/delpunch",
    params: {
      id
    }
  });
  return json;
}

async function enterManualPunch(url, token, punch) {
  let json = await axios({
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: "Bearer " + token
    },
    url: url + "punches/manualentry",
    data: qs.stringify({
      punchType: punch.punchType,
      punchIn: punch.punchIn,
      punchOut: punch.punchOut,
      timeIn: punch.timeIn,
      timeOut: punch.timeOut,
      comment: punch.comment,
      userid: punch.userid,
      total: punch.total,
      storeid: punch.storeid,
      punchId: punch.punchid,
      id: punch.id,
      mode: punch.mode,
      timeZoneOffset: punch.timeZoneOffset,
      isDst: punch.isDst,
      departmentId: punch.department,
      edited: punch.edited ? 1 : 0,
      editType: punch.editType
    })
  });
  return json;
}

async function getApproachingForty(url, token, storeid, startDate, endDate) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "widgets/approaching",
    params: {
      storeid,
      startDate,
      endDate
    }
  });
  return json;
}

async function getManagers(url, token, storeid) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "punches/managers",
    params: {
      storeid
    }
  });
  return json;
}

export {
  createPunch,
  getUserPunches,
  deletePunch,
  enterManualPunch,
  getUserPunchesByDates,
  getApproachingForty,
  getManagers
};
