import React, { useState, useEffect, useContext, useRef } from "react";
import { Picker, ModalDatePicker } from "c5-react-library";
import { readHourlySales } from "../api/SalesApi";
import { ContextConfig } from "../App";
import { toast } from "react-toastify";
import { isEqual, debounce } from "lodash";
import { usePrevious, formatDate, convertAmount, addDays } from "../utils";
import { getAssignedStoresAsync } from "../api/StoreApi";
import Spinner from "../common/Spinner";

const MobileHourly = () => {
  const [time, setTime] = useState(new Date());
  const [isShowing, setIsShowing] = useState(false);
  const [showDate, setShowDate] = useState(false);
  const [stores, setStores] = useState([]);
  const [store, setStore] = useState({});
  const [hourly, setHourly] = useState([]);
  const [hasReturned, setHasReturned] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const context = useContext(ContextConfig);

  const previousStore = usePrevious(store);
  const previousHourly = usePrevious(hourly);
  const previousTime = usePrevious(time);

  useEffect(() => {
    if (!isEqual(previousStore, store)) {
      loadStores();
    }

    setTimeout(() => {
      if (!store || !store.hasOwnProperty("storeid")) {
        setIsShowing(true);
      } else {
        setIsShowing(false);
      }
    }, 500);

    if (!context.hasOwnProperty("endDate")) {
      setShowDate(true);
      return;
    } else {
      // if the date is todays date, then we need to back it up one day
      const testDate = new Date();
      if (formatDate(context.endDate) == formatDate(testDate)) {
        const newDate = addDays(context.endDate, -1);
        context.endDate = newDate;
        setTime(newDate);
      } else {
        setTime(context.endDate);
      }
    }

    // const newDate = addDays(new Date(), -1);
    // setTime(newDate);

    if (!isEqual(previousTime, time)) {
      loadHourly(context.lastStoreid);
    }

    if (!isEqual(previousHourly, hourly)) {
      loadHourly(context.lastStoreid);
    }

    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });

      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);
    findDivHeight();

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, [hourly, store, time]);

  const findDivHeight = () => {
    const navbar = document
      .getElementById("master-navbar")
      .getBoundingClientRect().height;
    const controls = document
      .getElementById("hourly-controls")
      .getBoundingClientRect().height;
    const usedSpace = navbar + controls + 20;
    const remaining = window.innerHeight - usedSpace;

    const widget = document.getElementById("hourly-body");
    widget.style.height = remaining + "px";
    widget.style.overflow = "auto";
  };

  const loadHourly = (storeid) => {
    setIsLoading(true);
    if (!context.hasOwnProperty("endDate")) {
      // we need to force a date selection
      setShowDate(true);
      return;
    }

    readHourlySales(context.url, context.token, storeid, context.endDate)
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          setHourly(j.items);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("error", err);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const loadStores = () => {
    setIsLoading(true);
    getAssignedStoresAsync(context.url, context.token)
      .then((response) => {
        setIsLoading(false);
        setHasReturned(true);
        const j = response.data;
        if (j.error === 0) {
          const newArray = j.items.map((s) => {
            if (s.storeid == context.lastStoreid) {
              setStore(s);
            }
            return { ...s, store_Name: s.store_Name.substring(0, 20) };
          });
          setStores(newArray);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setHasReturned(true);
        console.log("stores error", err);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const handleToggleStores = () => {
    setIsShowing(!isShowing);
  };

  const handleStoreSelect = (e) => {
    console.log("store selected", e);
    context.lastStoreid = e.storeid;
    setIsShowing(false);
    setStore(e);
    loadHourly(e.storeid);
  };

  const handleShowDate = () => {
    setShowDate(true);
  };

  const handleDateSelect = (e) => {
    context.endDate = e;
    console.log("date selected", e);
    setShowDate(false);
    setTime(e);
  };

  const handleDateCancel = () => {
    setShowDate(false);
  };

  return (
    <React.Fragment>
      {isLoading && <Spinner />}
      <div className="row p-2" id="hourly-controls">
        <div className="col header-selection-btn">
          <button
            type="button"
            className="btn btn-outline-dark"
            onClick={handleToggleStores}
          >
            <i className="far fa-users"></i>
          </button>
        </div>
        <div>
          <div className="hourly-m-header">
            <div className="hourly-m-header-date">
              {time && formatDate(time)}
            </div>
            <div className="hourly-m-header-store">
              {store && store.store_Name}
            </div>
          </div>
        </div>
        <div className="col ml-auto header-date-btn">
          <button
            className="btn btn-outline-dark"
            type="button"
            onClick={handleShowDate}
          >
            <i className="far fa-calendar-alt"></i>
          </button>
        </div>
      </div>
      <div id="hourly-body">
        {hasReturned && hourly.length === 0 ? (
          <div>There are no records to view</div>
        ) : (
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Hour</th>
                <th scope="col">Sales</th>
              </tr>
            </thead>
            <tbody>
              {hourly.map((hour, index) => (
                <tr key={index}>
                  <td scope="row">{hour.f01}</td>
                  <td scope="row">{convertAmount(hour.f65)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

      <ModalDatePicker
        value={time}
        isOpen={showDate}
        onSelect={handleDateSelect}
        onCancel={handleDateCancel}
      />
      <Picker
        isShowing={isShowing}
        hide={handleToggleStores}
        header="stores"
        store={stores}
        displayField="store_Name"
        valueField="storeid"
        handleSelect={handleStoreSelect}
        useEffect={useEffect}
        useState={useState}
        useRef={useRef}
        debug={false}
        debugListeners={false}
      />
    </React.Fragment>
  );
};

MobileHourly.propTypes = {};

export default MobileHourly;
