import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import { formatDate, formatTimeAmPm } from "../utils";
import Tooltip from "../Tooltip/Tooltip";
import * as actions from "../actions/actionTypes";

const OrdersWidget = ({ freshopState, dispatch }) => {
  return (
    <div>
      <h5 className="error-item-title">Orders</h5>
      <Row className="justify-content-center align-items-center">
        {freshopState.orders.map((record, i) => (
          <Col lg="6" md="12" key={`foo-${i}`}>
            <Card className="freshop-price-mod-widget freshop-fade-in">
              <CardHeader>
                <div
                  className="freshop-orders-title"
                  style={{ display: "grid", gridTemplateColumns: "1fr 100px" }}
                >
                  <div className="error-item-header">{record.orderid}</div>
                  <div
                    className="freshop-orders-payload"
                    onClick={() =>
                      dispatch({
                        type: actions.FRESHOP_SET_PAYLOAD_ORDER_ID,
                        orderid: record.orderid
                      })
                    }
                    style={{
                      textAlign: "right",
                      marginRight: "10px",
                      fontSize: "1.4rem",
                      cursor: "pointer"
                    }}
                  >
                    <Tooltip
                      message={"Click me to see the Transactions Payload"}
                      position={"top"}
                    >
                      <i className="far fa-space-shuttle"></i>
                    </Tooltip>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs="6">
                    <div>Customer: {record.customer}</div>
                  </Col>
                  <Col xs="6">
                    <div>Phone: {record.phone}</div>
                  </Col>
                </Row>
                <Row>
                  <Col xs="6">
                    <div>Freshop Items: {record.freshopItemCount}</div>
                  </Col>
                  <Col xs="6">
                    <div>Items Processed: {record.processedItems}</div>
                  </Col>
                </Row>
                <Row>
                  <Col xs="4">
                    <div>Estimated Total: {record.estimatedTotal}</div>
                  </Col>
                  <Col xs="4">
                    <div>Computed Total: {record.smsTotalComputed}</div>
                  </Col>
                  <Col xs="4">
                    <div>Total Sales: {record.totalSales}</div>
                  </Col>
                </Row>
                <Row>
                  <Col xs="6">
                    <div>Status: {record.status}</div>
                  </Col>
                  <Col xs="6">
                    <div>Taxes: {record.totalTaxes}</div>
                  </Col>
                </Row>
                <Row>
                  <Col xs="4">
                    <div>{formatDate(record.orderDate)}</div>
                  </Col>
                  <Col xs="4">
                    <div>{formatTimeAmPm(record.received)}</div>
                  </Col>
                  <Col xs="4">
                    <div>
                      Bagging Type:
                      {record.baggingTypeId == "660588" ? "Plastic" : "Paper"}
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

OrdersWidget.propTypes = {
  freshopState: PropTypes.object,
  dispatch: PropTypes.func
};

export default OrdersWidget;
