import React from "react";
import ReactDOM from "react-dom";
import "./Rounding.css";

const Rounding = ({
  isShowing,
  hide,
  currentScheme,
  schemeName,
  updateScheme,
  saveCurrentScheme,
  breakPoint,
  setBreakPoint
}) =>
  isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <div className="rounding-overlay" />

          <div
            className="p-4 rounding-wrapper"
            aria-modal
            aria-hidden
            tabIndex={-1}
            role="dialog"
          >
            <div className="rounding">
              <div className="rounding-header">
                <span className="rounding-confirm-title">Rounding Scheme</span>
                <span className="rounding-close-box">
                  <button
                    type="button"
                    className="rounding-close-button"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={hide}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </span>
              </div>
              <div className="rounding-body">
                <div className="rounding-title">
                  <h1>{schemeName}</h1>
                </div>
                <div>
                  <div className="row justify-content-center mv-2 p-3">
                    <div className="col">
                      <p className="rounding-instructions">
                        A Break Point is the minute at which you would like the
                        hour to roll over to the next hour. For example, if the
                        Break Point is set to 55, and the punch is 1:55, then
                        the rounded punch will be 2:00. If you dont want or need
                        a break point, you can skip this setting.
                      </p>
                    </div>
                  </div>
                  <div className="row justify-content-center mv-2 p-3">
                    <div className="col">
                      <select
                        className="form-control"
                        value={breakPoint}
                        onChange={(e) => setBreakPoint(e.target.value)}
                      >
                        <option value="-1">Select a Break Point</option>
                        {currentScheme.map((record, index) => (
                          <option
                            key={`bp-${index}`}
                            value={record.actualPunch}
                          >
                            {record.actualPunch}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Minute</th>
                        <th>Rounds To</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentScheme.map((record, index) => (
                        <tr key={`rk-${index}`}>
                          <td>{record.actualPunch}</td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              value={record.roundsTo}
                              onChange={(e) =>
                                updateScheme(record, e.target.value)
                              }
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="row justify-content-center">
                    <button
                      className="btn btn-outline-light"
                      onClick={saveCurrentScheme}
                    >
                      Save Scheme
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>,
        document.body
      )
    : null;

export default Rounding;
