import React, { useState, useEffect, useContext } from "react";
import HeaderRb from "../../common/HeaderRb";
import { toast } from "react-toastify";
import { ContextConfig } from "../../App";
import {
  isValidDate,
  addDays,
  formatDate,
  print,
  handleCsv
} from "../../utils";
import { getRedemptions } from "../../api/PayServerApi";
import { getStoreNumberById } from "../../api/ReportsApi";
import Spinner from "../../common/Spinner";
import { debounce } from "../../fp";
import ReportConverter from "./ReportConverter";
import ScrollDivToTop from "../../common/ScrollDivToTop";

const dRedemptions = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [html, setHtml] = useState("");
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const context = useContext(ContextConfig);

  useEffect(() => {
    console.log(context);
    getStoreNumber(context.lastStoreid);

    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);

    findDivHeight();

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, []);

  const findDivHeight = () => {
    const navbar = document
      .getElementById("master-navbar")
      .getBoundingClientRect().height;
    const controls = document
      .getElementById("r-controls")
      .getBoundingClientRect().height;
    const usedSpace = navbar + controls + 30;
    const remaining = window.innerHeight - usedSpace;

    const body = document.getElementById("r-body");
    body.style.height = remaining + "px";
    body.style.overflow = "auto";
  };

  const loadData = (storenumber) => {
    setIsLoading(true);
    if (!isValidDate(context.startDate)) {
      context.startDate = addDays(new Date(), -7);
    }

    getRedemptions(
      context.psapi,
      context.apikey,
      context.startDate,
      context.endDate,
      storenumber
    )
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          setHtml(
            j.html.replace(
              "display: none",
              "display: table; width: 90%; margin: auto;"
            )
          );
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const getStoreNumber = (storeid) => {
    setIsLoading(true);
    getStoreNumberById(context.url, context.token, storeid)
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          loadData(j.storenumber);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const handlePrint = () => {
    print(document.getElementById("redemption").innerHTML);
  };

  return (
    <React.Fragment>
      {isLoading && <Spinner />}
      <HeaderRb />
      <div className="container-fluid" id="redemption">
        <div id="r-controls">
          <div className="rpt-name">Redemptions</div>
          <div className="export-report">
            <span className="print-report" onClick={handlePrint}>
              <i className="far fa-print"></i>
            </span>
            <span
              className="csv-report"
              onClick={() =>
                handleCsv(
                  document.getElementById("basic-table"),
                  "redemption.csv"
                )
              }
            >
              <i className="far fa-save"></i>
            </span>
          </div>

          <div className="d-report-info">
            <div className="d-report-storename">
              <div className="d-report=date">{context.reportStore.name}</div>
              <span>Start: {formatDate(context.startDate)}</span>
              <span>&nbsp;</span>
              <span>End: {formatDate(context.endDate)}</span>
            </div>
          </div>
        </div>
        <div id="r-body">
          <React.Fragment>
            {html.length === 0 ? (
              <div>No records were returned</div>
            ) : (
              <ReportConverter html={html} />
            )}
            <ScrollDivToTop id={"r-body"} />
          </React.Fragment>
        </div>
      </div>
    </React.Fragment>
  );
};

export default dRedemptions;
