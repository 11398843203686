import React from "react";
import PropTypes from "prop-types";

const RoleControls = ({
  handleSubmit,
  handleUserChange,
  user,
  users,
  company,
  handleCompanyChange,
  companies,
  currentCompany,
  search,
  handleSearchChange,
  handleSearchClick,
  stores,
  handleStoreChange,
  context
}) => {
  return (
    <React.Fragment>
      {company === 1 && companies ? (
        <form>
          <label>Select Company</label>
          <div className="cc-form-item">
            <select
              className="form-control"
              name="company"
              placeholder="Select Company"
              onChange={handleCompanyChange}
              value={currentCompany || ""}
            >
              <option value="-1">Select a Company</option>
              {companies.length === 0 ? (
                <option value="0">No Companies returned</option>
              ) : (
                companies.map((c, index) => (
                  <option key={`c-${index}`} value={c.company_Id}>
                    {c.company_Name}
                  </option>
                ))
              )}
            </select>
          </div>
        </form>
      ) : null}
      <div className="row">
        <div className="col-6">
          <form className="p3" onSubmit={handleSubmit}>
            <label>Select User</label>
            <div className="cc-form-item">
              <select
                className="form-control"
                name="userid"
                placeholder="Select User"
                onChange={handleUserChange}
                value={user || ""}
              >
                <option value="-1">Select a User</option>
                {users.length === 0 ? (
                  <option value="0">No Users returned</option>
                ) : (
                  users.map((u, index) => (
                    <option key={`u-${index}`} value={u.userid}>
                      {u.username}
                    </option>
                  ))
                )}
              </select>
            </div>
          </form>
        </div>
        <div className="col-6" style={{ marginTop: "10px" }}>
          <label>Select a Store</label>
          <select
            className="form-control"
            value={context.lastClockStore || ""}
            onChange={handleStoreChange}
          >
            {/* <option value="0">All Stores</option> */}
            {stores &&
              stores.map((store, i) => (
                <option key={`rci=${i}`} value={store.id}>
                  {store.storeName}
                </option>
              ))}
          </select>
        </div>
      </div>

      <div className="user-search">
        <input
          type="text"
          className="form-control user-search-box"
          value={search}
          onChange={handleSearchChange}
          placeholder="Search"
        />
        <button
          className="btn btn-secondary user-search-button"
          type="submit"
          onClick={handleSearchClick}
        >
          <i className="fa fa-search"></i>
        </button>
      </div>
      <div className="user-spacer">&nbsp;</div>
    </React.Fragment>
  );
};

RoleControls.propTypes = {
  handleSubmit: PropTypes.func,
  handleUserChange: PropTypes.func,
  user: PropTypes.any,
  users: PropTypes.array,
  company: PropTypes.any,
  handleCompanyChange: PropTypes.func,
  companies: PropTypes.array,
  currentCompany: PropTypes.any,
  search: PropTypes.string,
  handleSearchChange: PropTypes.func,
  handleSearchClick: PropTypes.func,
  stores: PropTypes.array,
  handleStoreChange: PropTypes.func,
  context: PropTypes.any
};

export default RoleControls;
