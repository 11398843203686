import axios from "axios";

async function createRequest(
  url,
  token,
  requestStart,
  requestEnd,
  comments,
  requestType,
  requestDuration
) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "daysoff/requestdayoff",
    params: {
      requestStart,
      requestEnd,
      comments,
      hours: requestDuration,
      type: requestType
    }
  });
  return json;
}

async function getStoreRequests(url, token, storeid, type, start, end) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "daysoff/check",
    params: {
      storeid,
      type,
      start,
      end
    }
  });
  return json;
}

async function getRequests(url, token) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "daysoff/emprequests"
  });
  return json;
}

async function denyRequest(url, token, id, denyReason) {
  let json = await axios({
    method: "delete",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "daysoff",
    params: {
      id,
      denyReason
    }
  });
  return json;
}

async function approveRequest(url, token, id) {
  let json = await axios({
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "daysoff",
    params: {
      id
    }
  });
  return json;
}

async function getPtoStats(url, token) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "daysoff/getpto"
  });
  return json;
}

export {
  createRequest,
  getRequests,
  getStoreRequests,
  denyRequest,
  approveRequest,
  getPtoStats
};
