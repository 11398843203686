/* eslint-disable no-unused-vars */
import * as actions from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  data: [],
  filteredData: [],
  sortUsernameAsc: false,
  sortEmployeeIdAsc: false,
  sortDateAsc: false,
  sortDeptAsc: false,
  sortTotalAsc: false,
  sortField: null,
  sortDataType: "string",
  filterField: null,
  filterUsername: false,
  filterEmployeeId: false,
  filterDepartment: false,
  filterDate: false,
  filterTotal: false,
  filteredValue: ""
};

const hoursTableReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.HT_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading
      };
    case actions.HT_LOAD_DATA:
      return {
        ...state,
        data: action.data,
        filteredData: action.data
      };
    case actions.HT_SET_FILTERED_PUNCHES:
      return {
        ...state,
        filteredData: action.filteredData
      };
    case actions.HT_SET_SORT_FIELD:
      return {
        ...state,
        sortField: action.field,
        [action.sortState]: !state[action.sortState],
        sortDataType: action.sortDataType
      };
    case actions.HT_SET_FILTER_FIELD:
      return {
        ...state,
        [action.filterState]: !state[action.filterState],
        filterField: action.filterField
      };
    case actions.HT_CHANGE_FILTER_VALUE:
      return {
        ...state,
        filteredValue: action.value
      };
    default:
      return state;
  }
};

export default hoursTableReducer;
