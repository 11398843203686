import React, { useState, useEffect, useContext } from "react";
import ClockSidebar from "../ClockSidebar";
//import colors from "../../common/colors";
import Tabs from "../../common/Tabs";
import CreateStore from "./CreateStore";
import StoreList from "./Storelist";
import { usePrevious, isValid } from "../../utils";
import { isEqual, debounce } from "../../fp";
import { readClockStores, deleteClockStore } from "../../api/StoreApi";
import { ContextConfig } from "../../App";
import { toast } from "react-toastify";
import { BrowserView, MobileView, isBrowser } from "react-device-detect";
import ClockDesktopMenu from "../ClockDesktopMenu";
import FloatingSidebar from "../../Dashboard/FloatingSidebar";

const Stores = () => {
  const [hideTab, setHideTab] = useState(true);
  const [activeStore, setActiveStore] = useState({});
  const [stores, setStores] = useState([]);

  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const context = useContext(ContextConfig);

  const previousStores = usePrevious(stores);

  useEffect(() => {
    if (!isEqual(previousStores, stores)) {
      loadStores();
    }
    if (isValid(activeStore)) {
      setHideTab(false);
      var tab = document.getElementById("edit-store-tab");
      if (isValid(tab)) {
        tab.click();
      }
    }

    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);

    findDivHeight();

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, [stores, hideTab, activeStore]);

  const findDivHeight = () => {
    if (!isBrowser) return;
    const navbar = document
      .getElementById("master-navbar")
      .getBoundingClientRect().height;

    const usedSpace = navbar + 180;
    const remaining = window.innerHeight - usedSpace;

    const widget = document.getElementById("store-list");
    if (typeof widget !== "undefined" && widget !== null) {
      widget.style.height = remaining + "px";
      widget.style.overflow = "auto";
    }
  };

  const loadStores = () => {
    readClockStores(context.clockUrl, context.clockToken)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setStores(j.stores);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const editStore = (record) => {
    setHideTab(false);
    setActiveStore(record);
  };

  const deleteStore = (record) => {
    if (record.id == context.lastClockStore) {
      toast.error(
        "You cannot delete this store. This is your currently active store",
        {
          position: toast.POSITION.TOP_LEFT
        }
      );
      return;
    }
    deleteClockStore(context.clockUrl, context.clockToken, record.id)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          loadStores();
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const updateStoreList = () => {
    loadStores();
  };

  return (
    <React.Fragment>
      <MobileView>
        <div className="clock-mobile">
          <div className="mobile-clock-header">
            <ClockSidebar />
            <div className="container cc-main-body">
              <Tabs>
                <div label="List">
                  <StoreList
                    stores={stores}
                    editStore={editStore}
                    deleteStore={deleteStore}
                  />
                </div>
                <div label="Create">
                  <CreateStore updateStoreList={updateStoreList} />
                </div>
                <div id={"edit-store-tab"} label="Edit" disabled={hideTab}>
                  <CreateStore
                    activeStore={activeStore}
                    editMode={true}
                    updateStoreList={updateStoreList}
                  />
                </div>
              </Tabs>
            </div>
          </div>
        </div>
      </MobileView>
      <BrowserView>
        <div>
          <ClockDesktopMenu />
          <FloatingSidebar />
          <div className="container">
            <div
              style={{
                width: "100%",
                marginTop: "20px",
                textAlign: "center",
                fontSize: "1.3rem",
                fontWeight: "bold"
              }}
            >
              Stores
            </div>
            <Tabs>
              <div label="List">
                <div id="store-list">
                  <StoreList
                    stores={stores}
                    editStore={editStore}
                    deleteStore={deleteStore}
                  />
                </div>
              </div>
              <div label="Create">
                <CreateStore updateStoreList={updateStoreList} />
              </div>
              <div id={"edit-store-tab"} label="Edit" disabled={hideTab}>
                <CreateStore
                  activeStore={activeStore}
                  editMode={true}
                  updateStoreList={updateStoreList}
                />
              </div>
            </Tabs>
          </div>
        </div>
      </BrowserView>
    </React.Fragment>
  );
};

export default Stores;
