/* eslint-disable no-unused-vars */
import jsPDF from "jspdf";
import "jspdf-autotable";
import { formatDate } from "../../utils";

function reportOptions(header, footer, css, index) {
  var topMargin = 85;
  var options = {
    beforePageContent: header,
    afterPageContent: footer || null,
    margin: { top: topMargin, bottom: 70 }
  };

  if (typeof css !== "undefined" && css !== null) {
    if (css.length > 0) {
      //style up our headers
      options.createdHeaderCell = function(cell, data) {
        switch (css[data.column.dataKey]) {
          case "l":
            cell.styles.halign = "left";
            break;
          case "r":
            cell.styles.halign = "right";
            break;
          case "c":
            cell.styles.halign = "center";
            break;
          default:
            cell.styles.halign = "left";
            break;
        }
        var pos = cell.raw.indexOf("{h}");
        if (pos >= 0) {
          cell.styles.columnWidth = "0";
          cell.styles.cellPadding = "0";
        }
      };
      //style up our cells

      options.createdCell = function(cell) {
        // switch (css[data.column.dataKey]) {
        switch (css[cell.column.index]) {
          case "l":
            cell.cell.styles.halign = "left";
            break;
          case "r":
            cell.cell.styles.halign = "right";
            break;
          case "c":
            cell.cell.styles.halign = "center";
            break;
          default:
            cell.cell.styles.halign = "left";
            break;
        }
        var pos = cell.cell.text[0].indexOf("{d}");
        if (pos >= 0) {
          cell.cell.styles.textColor = "#e2164d";
          cell.cell.text = cell.text[0].replace("{d}", "");
        }
        pos = cell.cell.text[0].indexOf("{u}");
        if (pos >= 0) {
          cell.cell.styles.textColor = "#4cb539";
          cell.cell.text[0] = cell.cell.text[0].replace("{u}", "");
        }
        pos = cell.cell.text[0].indexOf("{b}");

        if (pos >= 0) {
          cell.cell.styles.fontStyle = "bold";
          cell.cell.text[0] = cell.cell.text[0].replace("{b}", "");
        }
        pos = cell.cell.text[0].indexOf("{r}");
        if (pos >= 0) {
          cell.cell.styles.halign = "right";
          cell.cell.text[0] = cell.cell.text[0].replace("{r}", "");
        }
        pos = cell.cell.text[0].indexOf("{h}");
        if (pos >= 0) {
          cell.cell.styles.columnWidth = "0";
          cell.cell.styles.cellPadding = "0";
        }
      };
    }
  }
  return options;
}

function generatePdf(report, params, tables, html) {
  var doc = new jsPDF("l", "px");
  doc.page = 1;
  if (html) {
    return;
  }

  let currentUsername = "";
  let currentEmployeeId = "";

  var header = function(data) {
    doc.setFontSize(16);
    // first number is left. second is top
    doc.text(50, 40, report.reportName);
    doc.setFontSize(10);
    //doc.setFontType("italic");
    //doc.setTextColor(95, 162, 161);

    if (typeof params !== "undefined" && params !== null) {
      if (
        typeof params.startDate !== "undefined" &&
        params.startDate !== null
      ) {
        doc.setFontSize(12);
        doc.text(50, 51, "Dates: " + formatDate(params.startDate));
        if (typeof params.endDate !== "undefined" && params.endDate !== null) {
          doc.text(125, 51, " - " + formatDate(params.endDate));
        }
      }
      if (params.usersName) {
        doc.text(50, 61, params.usersName);
        if (params.levelDescription) {
          doc.text(50, 71, params.levelDescription);
        }
        doc.text(50, 81, "EmployeeId: " + params.employeeId);
      }
      if (params.storename) {
        var pageWidth =
          doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
        doc.setFontSize(16);
        doc.text(pageWidth - 100, 40, params.storename);
        doc.setFontSize(10);
      }
    }
    if (typeof report.storename !== "undefined" && report.storename !== null) {
      doc.setFontSize(12);
      doc.text(50, 65, "Store: " + report.storename);
      doc.setFontSize(10);
    }

    if (
      typeof report.companyname !== "undefined" &&
      report.companyname !== null
    ) {
      doc.setFontSize(12);
      doc.text(50, 65, "Company: " + report.companyname);
      doc.setFontSize(10);
    }

    if (typeof report.username !== "undefined" && report.username !== null) {
      doc.setFontSize(12);
      doc.text(450, 65, "Employee: " + report.username);
    }
    if (
      typeof report.employeeid !== "undefined" &&
      report.employeeid !== null
    ) {
      doc.setFontSize(10);
      doc.text(450, 75, "Employee ID: " + report.employeeid);
    }

    if (tables.length > 0) {
      try {
        var pageNumber = doc.internal.getCurrentPageInfo().pageNumber;
        doc.text(200, 50, currentUsername);
        doc.text(300, 50, "EmployeeId: " + currentEmployeeId);
      } catch (e) {
        console.log("error on page " + pageNumber);
      }
    }
  };

  var footer = function(data) {
    var height = doc.internal.pageSize.height,
      width = doc.internal.pageSize.width,
      margin = 15,
      top = height - margin;
    doc.setFontSize(9);

    doc.text(
      width - 50,
      top,
      "Page: " + doc.internal.getCurrentPageInfo().pageNumber
    );
    doc.text(50, top, "Printed: " + new Date());
  };

  var options = reportOptions(header, footer, report.css);

  if (tables.length > 0) {
    tables.map((table, index) => {
      currentUsername = table.employeeName;
      currentEmployeeId = table.employeeNumber;
      if (index > 0) {
        doc.addPage();
      }
      var res = doc.autoTableHtmlToJson(
        document.getElementById(table.tableName)
      );

      const newMargin = {
        top: 60,
        bottom: 20
      };
      options.margin = newMargin;
      doc.autoTable(res.columns, res.data, options);
    });
  } else {
    var res = doc.autoTableHtmlToJson(document.getElementById("basic-table"));
    if (res !== null) {
      doc.autoTable(res.columns, res.data, options);
    }
  }

  document.getElementById("output").src = "";
  document.getElementById("output").src = doc.output("datauristring");
}

export { generatePdf };
