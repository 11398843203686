import axios from "axios";
import qs from "qs";
import { encrypt } from "../../utils";

async function signin(username, password, context, useImpersonation) {
  const encryptedUsername = encrypt(username, context.key).toString();
  const encryptedPassword = encrypt(password, context.key).toString();
  let json = await axios({
    method: "POST",
    url: context.clockUrl + "auth/reacttoken",
    cors: true,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    data: qs.stringify({
      username: encryptedUsername,
      password: encryptedPassword,
      Ov: useImpersonation ? 1 : 0
    })
  });

  return json;
}

async function checkEmail(email, context) {
  let json = await axios({
    method: "GET",
    cors: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + context.clockToken
    },
    url: context.clockUrl + "users/emaillookup",
    params: {
      email
    }
  });

  return json;
}

async function checkAnswer(context, userid, answer) {
  let json = await axios({
    method: "GET",
    cors: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + context.clockToken
    },
    url: context.clockUrl + "users/checkanswer",
    params: {
      userid,
      answer
    }
  });

  return json;
}

async function reset(context, userid, encryptedPassword) {
  let json = axios({
    method: "POST",
    cors: true,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: "Bearer " + context.clockToken
    },
    url: context.clockUrl + "users/resetpasswordsafe",
    data: qs.stringify({
      userid: userid,
      newpassword: encryptedPassword
    })
  });

  return json;
}

export { signin, checkEmail, checkAnswer, reset };
