/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
// import { Draggable } from "react-beautiful-dnd";
import PropTypes from "prop-types";

const OrderBy = ({
  index,
  data,
  changeOrderBy,
  id,
  removeOrderBy,
  orderby
}) => {
  const [currentOrderBy, setCurrentOrderBy] = useState("");
  const [direction, setDirection] = useState(1);

  useEffect(() => {}, []);
  useEffect(() => {
    changeOrderBy(id, currentOrderBy, direction);
  }, [currentOrderBy, direction]);

  return (
    <div className="row justify-content-center mt-2" key={`ob-${index}`}>
      <div
        className="col-1"
        style={{ color: "#fff", marginTop: "10px", fontSize: "1.2rem" }}
      >
        <i className="fas fa-bars"></i>
      </div>
      <div className="col-4">
        <select
          className="form-control"
          value={orderby.orderby}
          onChange={(e) => setCurrentOrderBy(e.target.value)}
        >
          <option value="0">Select Order By</option>
          {data.map((record, i) => (
            <option key={`${id}-orderby-field-${i}`} value={record.accessor}>
              {record.label}
            </option>
          ))}
        </select>
      </div>
      <div className="col-4">
        <select
          className="form-control"
          value={orderby.direction}
          onChange={(e) => setDirection(e.target.value)}
        >
          <option value="0">Select Direction</option>
          <option value="1">Ascending</option>
          <option value="2">Descending</option>
        </select>
      </div>
      <div className="col-2">
        <button className="btn btn-danger" onClick={() => removeOrderBy(id)}>
          X
        </button>
      </div>
    </div>
  );
};

OrderBy.propTypes = {
  id: PropTypes.any,
  index: PropTypes.any,
  data: PropTypes.array,
  changeOrderBy: PropTypes.func,
  removeOrderBy: PropTypes.func,
  orderby: PropTypes.object
};

export default OrderBy;
