/* eslint-disable react/display-name */
import React, { useEffect, useContext } from "react";
import { usePrevious, isValid } from "../../utils";
import { ContextConfig } from "../../App";
import { getHoursByCompany, getHoursByStore } from "../../api/widgetsApi";
import { toast } from "react-toastify";
import { variables } from "../../common/variables";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "../../common/Spinner";
import * as actions from "../../actions/actionTypes";
import SortableFilterableColumn from "./SortableFilterableColumn";

import "./Hours.css";
import "./HoursTable.css";

const Hours = () => {
  const state = useSelector((app) => app.hoursTableReducer);
  const dispatch = useDispatch();

  const context = useContext(ContextConfig);

  const previousStoreid = usePrevious(context.lastClockStore);
  const previousStartDate = usePrevious(context.startDate);
  const previousEndDate = usePrevious(context.endDate);

  useEffect(() => {
    if (previousStoreid != context.lastClockStore) {
      loadHours();
    } else if (previousStartDate != context.startDate) {
      loadHours();
    } else if (previousEndDate != context.endDate) {
      loadHours();
    }
    dispatch({
      type: actions.HT_CHANGE_FILTER_VALUE,
      value: ""
    });
  }, [context.lastClockStore, context.startDate, context.endDate]);

  useEffect(() => {
    if (state.filteredData.length > 0) {
      if (isValid(state.sortField)) {
        sortRecords();
      }
    }
  }, [
    state.sortField,
    state.filterField,
    state.sortUsernameAsc,
    state.sortEmployeeIdAsc,
    state.sortDateAsc,
    state.sortTotalAsc,
    state.sortDeptAsc
  ]);

  useEffect(() => {
    filterRecords();
  }, [state.filteredValue]);

  const loadHours = () => {
    if (isValid(context.lastClockStore) && context.lastClockStore != "0") {
      getHoursByStore(
        context.clockUrl,
        context.clockToken,
        context.lastClockStore,
        context.startDate,
        context.endDate
      )
        .then((response) => {
          const j = response.data;
          if (j.error === 0) {
            processHours(j.hours);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error("An error occured getting Store hours from server", {
            position: toast.POSITION.TOP_LEFT
          });
        });
    } else {
      getHoursByCompany(
        context.clockUrl,
        context.clockToken,
        context.startDate,
        context.endDate
      )
        .then((response) => {
          const j = response.data;
          if (j.error === 0) {
            processHours(j.hours);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error("An error occured getting Company hours", {
            position: toast.POSITION.TOP_LEFT
          });
        });
    }
  };

  const processHours = (data) => {
    if (isValid(data)) {
      if (data.length > 0) {
        dispatch({ type: actions.HT_LOAD_DATA, data: data });
      } else {
        dispatch({ type: actions.HT_LOAD_DATA, data: [] });
      }
    } else {
      dispatch({ type: actions.HT_LOAD_DATA, data: [] });
    }
  };

  const calculateTotal = () => {
    return state.filteredData.reduce((acc, cur) => {
      return acc + parseFloat(cur.totalHours);
    }, 0);
  };

  const getSortField = () => {
    switch (state.sortField) {
      case "username":
        return state.sortUsernameAsc;
      case "employeeId":
        return state.sortEmployeeIdAsc;
      case "punchDate":
        return state.sortDateAsc;
      case "department":
        return state.sortDeptAsc;
      case "totalHours":
        return state.sortTotalAsc;
      default:
        return null;
    }
  };

  const compare = (a, b) => {
    let fieldA = a[state.sortField];
    let fieldB = b[state.sortField];

    if (state.sortDataType !== "number") {
      fieldA = fieldA.toUpperCase();
      fieldB = fieldB.toUpperCase();
    } else {
      fieldA = parseFloat(a[state.sortField]);
      fieldB = parseFloat(b[state.sortField]);
    }

    let comparison = 0;
    if (fieldA > fieldB) {
      comparison = 1;
    } else if (fieldA < fieldB) {
      comparison = -1;
    }

    if (getSortField()) {
      return comparison * -1;
    } else {
      return comparison;
    }
  };

  const sortRecords = () => {
    dispatch({ type: actions.HT_IS_LOADING, isLoading: true });
    dispatch({
      type: actions.HT_SET_FILTERED_PUNCHES,
      filteredData: [...state.filteredData].sort(compare)
    });
    dispatch({ type: actions.HT_IS_LOADING, isLoading: false });
  };

  const filterRecords = () => {
    if (state.filteredValue.length === 0) {
      dispatch({
        type: actions.HT_SET_FILTERED_PUNCHES,
        filteredData: state.data
      });
      return;
    }

    const newArray = state.data.filter((r) =>
      r[state.filterField]
        .toUpperCase()
        .includes(state.filteredValue.toUpperCase())
    );
    dispatch({ type: actions.HT_SET_FILTERED_PUNCHES, filteredData: newArray });
  };

  return (
    <div style={variables.widgetStyle}>
      {state.isLoading && <Spinner />}
      <h3>Hours Widget</h3>
      {/* <HoursTableData Styles={Styles} columns={columns} data={data} /> */}
      {state.filteredData.length > 0 ? (
        <table className="table table-striped hours-table">
          <thead>
            <tr>
              <SortableFilterableColumn
                state={state}
                dispatch={dispatch}
                title={"Name"}
                sortField={"sortUsernameAsc"}
                sortable={true}
                filterable={true}
                filterField={"filterUsername"}
                dbField={"username"}
                sortDataType={"string"}
              />
              <SortableFilterableColumn
                state={state}
                dispatch={dispatch}
                title={"Employee ID"}
                sortField={"sortEmployeeIdAsc"}
                sortable={true}
                sortDataType={"string"}
                dbField={"employeeId"}
                filterable={true}
                filterField={"filterEmployeeId"}
              />
              <SortableFilterableColumn
                state={state}
                dispatch={dispatch}
                title={"Date"}
                sortField={"sortDateAsc"}
                sortable={true}
                sortDataType={"string"}
                dbField={"punchDate"}
                filterable={true}
                filterField={"filterDate"}
              />
              <SortableFilterableColumn
                state={state}
                dispatch={dispatch}
                title={"Department"}
                sortField={"sortDeptAsc"}
                sortable={true}
                dbField={"department"}
                sortDataType={"string"}
                filterField={"filterDepartment"}
                filterable={true}
                style={{ textAlign: "center" }}
              />
              <SortableFilterableColumn
                state={state}
                dispatch={dispatch}
                title={"Total"}
                sortField={"sortTotalAsc"}
                sortable={true}
                dbField={"totalHours"}
                sortDataType={"number"}
                filterField={"filterTotal"}
                filterable={true}
                style={{ textAlign: "right" }}
              />
            </tr>
          </thead>
          <tbody>
            {state.filteredData.map((record, index) => (
              <tr key={`hwr-${index}`}>
                <td>{record.username}</td>
                <td>{record.employeeId}</td>
                <td>{record.punchDate}</td>
                <td>{record.department}</td>
                <td style={{ textAlign: "right" }}>{record.totalHours}</td>
              </tr>
            ))}
            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td className="hours-table-total">
                Total:
                <span style={{ textAlign: "right" }}>{calculateTotal()}</span>
              </td>
            </tr>
          </tbody>
        </table>
      ) : null}
    </div>
  );
};

export default Hours;
