// import * as actions from "../actions/actionTypes";

const initialState = {
  sortAvgF64Asc: false,
  sortCntAsc: false,
  sortF65Asc: false,
  sortF64Asc: false,
  sortMedianAsc: false,
  sortStoreNameAsc: false,
  sortF1126Asc: false,
  sortUsernameAsc: false,
  sortTotalCountAsc: false,
  sortF1142Asc: false,
  sortPostDateAsc: false,
  sortField: null,
  sortDataType: "string",
  dbField: null,
  sortState: null,
  filterField: null,
  filteredValue: ""
};

const refundTableReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REF_SET_SORT_FIELD":
      debugger;
      return {
        ...state,
        sortField: action.field,
        [action.sortState]: !state[action.sortState],
        sortDataType: action.sortDataType,
        sortState: !state[action.sortState]
      };
    case "REF_SET_FILTER_FIELD":
      return {
        ...state,
        [action.filterState]: !state[action.filterState],
        filterField: action.filterState,
        sortDataType: action.dataType
      };
    case "REF_CHANGE_FILTER_VALUE":
      return {
        ...state,
        filteredValue: action.value
      };
    default:
      return state;
  }
};

export default refundTableReducer;
