import React, { useState, useEffect, useContext } from "react";
import HeaderRB from "../../../common/HeaderRb";
import { ContextConfig } from "../../../App";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { debounce } from "../../../fp";
import * as actions from "../../../actions/actionTypes";
import Header from "../../../Header/Header";
import ErrorBoundary from "../../../common/ErrorBoundary";
import { getSecurityExceptions } from "../../../api/securityApi";
import { exceptions } from "../../../api/widgetsApi";
import { formatDate } from "../../../utils";
import VoidChart from "./VoidChart";
import CorrectionsChart from "./CorrectionsChart";
import RefundChart from "./RefundChart";
import ErrorCorrectChart from "./ErrorCorrectChart";
import { Row } from "reactstrap";
import RefundTicker from "./RefundTicker";
import VoidTicker from "./VoidTicker";
import ErrorCorrectTicker from "./ErrorCorrectTicker";

const SecurityMain = () => {
  const [reRender, setReRender] = useState(false);
  const [data, setData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [useDashboard, setUseDashboard] = useState(true);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const state = useSelector((app) => app.refundReducer);
  const dispatch = useDispatch();

  const context = useContext(ContextConfig);

  useEffect(() => {
    if (context.lastSearchType != "3") {
      dispatch({ type: actions.REF_ENABLE_CASHIER, enableCashier: false });
    }
    getExceptions();
    securityExeceptions();
    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }

      return () => {
        dispatch({ type: actions.REF_ENABLE_CASHIER, enableCashier: false });
      };
    }, 500);

    findDivHeight();

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, [reRender]);

  const findDivHeight = () => {
    const navbar = document
      .getElementById("master-navbar")
      .getBoundingClientRect().height;
    const controls = document.getElementById("controls").getBoundingClientRect()
      .height;
    const usedSpace = navbar + controls;
    const remaining = window.innerHeight - usedSpace;

    const body = document.getElementById("body");
    body.style.height = remaining + "px";
    body.style.overflow = "auto";
  };

  const securityExeceptions = () => {
    getSecurityExceptions(context.url, context.token, "", "", "", "")
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setData(j.exception);
        } else {
          toast.warning(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An internal error occured", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const getExceptions = () => {
    let search = context.lastSearchType === 2 ? "Group" : "Stores";
    if (context.lastSearchType == 3) {
      search = "Store";
    }

    let searchValue = context.lastStoreid;
    if (context.lastSearchType == 2) {
      searchValue = context.lastGroup;
    }
    exceptions(
      context.url,
      context.token,
      searchValue,
      formatDate(state.startDate),
      formatDate(state.endDate),
      search
    )
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          dispatch({
            type: actions.REF_SET_EXCEPTIONS,
            exceptions: j.totalizers
          });
        } else {
          dispatch({ type: actions.REF_SET_EXCEPTIONS, exceptions: [] });
          toast.warning(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: actions.REF_SET_EXCEPTIONS, exceptions: [] });
        toast.error("An Internal error occured", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  return (
    <div>
      <HeaderRB
        reRender={reRender}
        setReRender={setReRender}
        startDate={state.startDate}
        endDate={state.endDate}
        setStartDate={(e) =>
          dispatch({ type: actions.REF_SET_START_DATE, startDate: e })
        }
        setEndDate={(e) =>
          dispatch({ type: actions.REF_SET_END_DATE, endDate: e })
        }
        setLoading={(e) =>
          dispatch({ type: actions.REF_IS_LOADING, isLoading: e })
        }
        showSingleStore={true}
      />
      <div id="controls">
        <div className="container-fluid">
          <div
            style={{
              fontSize: "1.3rem",
              fontWeight: "bold",
              textAlign: "center",
              width: "100%"
            }}
          >
            Security Dashboard
          </div>
          {!useDashboard && (
            <Header
              reRender={reRender}
              setReRender={setReRender}
              startDate={state.startDate}
              endDate={state.endDate}
              setStartDate={(e) =>
                dispatch({ type: actions.REF_SET_START_DATE, startDate: e })
              }
              setEndDate={(e) =>
                dispatch({ type: actions.REF_SET_END_DATE, endDate: e })
              }
              setLoading={(e) =>
                dispatch({ type: actions.REF_IS_LOADING, isLoading: e })
              }
              showSingleStore={true}
            />
          )}
        </div>
        <ErrorBoundary>
          <div className="container">
            {!useDashboard && (
              <div className="row jusify-content-center">
                {state.exceptions.length > 0 ? (
                  <div className="col-3">
                    <span style={{ display: "flex", flexDirection: "row" }}>
                      <label style={{ marginTop: "10px", marginRight: "10px" }}>
                        Totalizer:
                      </label>
                      <select
                        className="form-control"
                        value={state.totalizer}
                        onChange={(e) =>
                          dispatch({
                            type: actions.REF_SET_TOTALIZER,
                            totalizer: e.target.value
                          })
                        }
                      >
                        <option value="0">Select a Totalizer</option>
                        {state.exceptions.map((totalizer, i) => (
                          <option key={`st-${i}`} value={totalizer.f1034}>
                            {totalizer.f1039}
                          </option>
                        ))}
                      </select>
                    </span>
                  </div>
                ) : null}
              </div>
            )}
          </div>
        </ErrorBoundary>
      </div>
      <div id="body" style={{ padding: "20px" }}>
        <ErrorBoundary>
          <Row>
            <VoidChart data={data} />
            <CorrectionsChart data={data} />
          </Row>
          <Row>
            <RefundChart data={data} />
            <ErrorCorrectChart data={data} />
          </Row>
          <div
            className="refund-ticker-header"
            style={{
              textAlign: "left",
              fontSize: "1.3rem",
              fontWeight: "bold"
            }}
          >
            Refund Ticker
          </div>
          <div
            style={{
              backgroundColor: "#000",
              padding: "10px",
              border: "2px solid red",
              color: "limegreen"
            }}
          >
            <RefundTicker />
          </div>
          <div
            className="refund-ticker-header"
            style={{
              textAlign: "left",
              fontSize: "1.3rem",
              fontWeight: "bold",
              marginTop: "20px"
            }}
          >
            Void Ticker
          </div>
          <div
            style={{
              backgroundColor: "#000",
              padding: "10px",
              border: "2px solid orange",
              color: "limegreen"
            }}
          >
            <VoidTicker />
          </div>

          <div
            className="refund-ticker-header"
            style={{
              textAlign: "left",
              fontSize: "1.3rem",
              fontWeight: "bold",
              marginTop: "20px"
            }}
          >
            Error Correct Ticker
          </div>
          <div
            style={{
              backgroundColor: "#000",
              padding: "10px",
              border: "2px solid red",
              color: "limegreen"
            }}
          >
            <ErrorCorrectTicker />
          </div>
        </ErrorBoundary>
      </div>
    </div>
  );
};

export default SecurityMain;
