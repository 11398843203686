import {
  getUnclosedOrders,
  getPriceMods,
  getMetrics,
  getTrueUpErrors,
  getOrdersForWidget
} from "../api/freshopApi";
import * as actions from "../actions/actionTypes";

const Unclosed = (
  url,
  storeid,
  startDate,
  endDate,
  freshopAppKey,
  dispatch
) => {
  getUnclosedOrders(url, storeid, startDate, endDate, freshopAppKey)
    .then((response) => {
      const j = response.data;
      if (j.error === 0) {
        dispatch({
          type: actions.FRESHOP_SET_UNCLOSED_ORDERS,
          orders: j.orders
        });
      } else {
        dispatch({ type: actions.FRESHOP_SET_UNCLOSED_ORDERS, orders: [] });
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: actions.FRESHOP_SET_UNCLOSED_ORDERS, orders: [] });
      dispatch({
        type: actions.FRESHOP_SET_OPEN_ORDER_ERROR,
        error: "An Internal Error occured"
      });
    });
};

const PriceMods = (
  url,
  storeid,
  startDate,
  endDate,
  freshopAppKey,
  dispatch
) => {
  getPriceMods(url, storeid, startDate, endDate, freshopAppKey)
    .then((response) => {
      const j = response.data;
      if (j.error === 0) {
        dispatch({ type: actions.FRESHOP_SET_PRICE_MODS, mods: j.mods });
      } else {
        dispatch({ type: actions.FRESHOP_SET_PRICE_MODS, mods: [] });
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: actions.FRESHOP_SET_PRICE_MODS, mods: [] });
      dispatch({
        type: actions.FRESHOP_SET_PRICE_MOD_ERROR,
        error: "An Internal error occured getting price modifictions"
      });
    });
};

const fetchMetrics = (url, userid, startDate, endDate, appKey, dispatch) => {
  getMetrics(url, userid, startDate, endDate, appKey)
    .then((response) => {
      const j = response.data;
      if (j.error === 0) {
        dispatch({ type: actions.FRESHOP_SET_METRICS, metrics: j.metrics });
      } else {
        dispatch({ type: actions.FRESHOP_SET_METRICS, metrics: [] });
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: actions.FRESHOP_SET_METRICS, metrics: [] });
      dispatch({
        type: actions.FRESHOP_SET_METRICS_ERROR,
        error: "An Internal Error occured"
      });
    });
};

const TrueUpErrors = (url, storeid, startdate, enddate, appkey, dispatch) => {
  getTrueUpErrors(url, storeid, startdate, enddate, appkey)
    .then((response) => {
      const j = response.data;
      if (j.error === 0) {
        dispatch({ type: actions.FRESHOP_SET_TRUEUP_ERRORS, errors: j.errors });
      } else {
        dispatch({ type: actions.FRESHOP_SET_TRUEUP_ERRORS, errors: [] });
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: actions.FRESHOP_SET_TRUEUP_ERRORS, errors: [] });
      dispatch({
        type: actions.FRESHOP_SET_TRUEUP_ERROR_MSG,
        error: "An Internal error occured"
      });
    });
};

const OrdersForWidget = (
  url,
  storeid,
  startdate,
  enddate,
  appkey,
  dispatch
) => {
  getOrdersForWidget(url, storeid, startdate, enddate, appkey)
    .then((response) => {
      const j = response.data;
      if (j.error === 0) {
        dispatch({ type: actions.FRESHOP_SET_ORDERS, orders: j.orders });
      } else {
        dispatch({ type: actions.FRESHOP_SET_ORDERS, orders: [] });
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: actions.FRESHOP_SET_ORDERS, orders: [] });
      dispatch({
        type: actions.FRESHOP_SET_ORDER_ERROR,
        error: "An Internal error occured"
      });
    });
};

export { Unclosed, PriceMods, fetchMetrics, TrueUpErrors, OrdersForWidget };
