import "date-fns";
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from "@material-ui/pickers";
import {
  isValidDate,
  formatTimeAmPm,
  formatDate,
  usePrevious,
  isValid
} from "../../utils";
import { roundPunch } from "../clockUtils";
import { enterManualPunch } from "../../api/punchApi";
import { toast } from "react-toastify";
import { isEqual } from "../../fp";
import { loadDepartments } from "../../api/departmentApi";
import Spinner from "../../common/Spinner";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../actions/actionTypes";

const initialState = {
  userid: 0,
  punchType: 0,
  punchIn: null,
  punchOut: null,
  timeIn: null,
  timeOut: null,
  punchid: 0,
  storeid: 0,
  comment: "",
  total: 0,
  timeZoneOffset: null,
  isDst: null,
  punchDate: null,
  department: "",
  edited: false,
  editType: "",
  editDate: ""
};
const ManualPunch = ({ context, isEditable, isLocked }) => {
  const state = useSelector((app) => app.editReducer);
  const dispatch = useDispatch();

  const previousPunch = usePrevious(state.punch);

  useEffect(() => {
    // first lets determin if we are allowed to make edit or not
    if (!isEqual(previousPunch, state.punch)) {
      if (isValidDate(state.punch.timeOut)) {
        if (!state.punch.rawPunchIn) {
          state.punch.rawPunchIn =
            formatDate(state.punch.punchIn) +
            " " +
            formatTimeAmPm(state.punch.punchIn);
        }
        if (!state.punch.rawPunchOut) {
          state.punch.rawPunchOut =
            formatDate(state.punch.punchOut) +
            " " +
            formatTimeAmPm(state.punch.punchOut);
        }

        const { totalTime } = roundPunch(state.punch, state.settings);

        if (totalTime < 0) {
          toast.error(
            "You cannot have an out date that is previous to the in Date",
            {
              position: toast.POSITION.TOP_LEFT
            }
          );
          dispatch({ type: actions.EDIT_SET_PUNCH, punch: initialState });
          return;
        }
        dispatch({
          type: actions.EDIT_SET_PUNCH,
          punch: { ...state.punch, total: totalTime }
        });
      }
    }

    // now check to see if we are being sent a punch or not
    if (isValid(state.existingPunch)) {
      let punchtype = state.existingPunch.punchType;
      switch (state.existingPunch.punchType.toUpperCase()) {
        case "BREAK":
          punchtype = 2;
          break;
        case "LUNCH":
          punchtype = 3;
          break;
        case "PUNCH":
          punchtype = 1;
          break;
        default:
          punchtype = 1;
          break;
      }

      const tempPunch = {
        punchid: state.existingPunch.id,
        id: state.existingPunch.id,
        total: state.existingPunch.total,
        punchIn: state.existingPunch.rawPunchIn,
        punchOut: state.existingPunch.rawPunchOut,
        timeIn: state.existingPunch.rawPunchIn,
        timeOut: state.existingPunch.rawPunchOut,
        punchType: punchtype,
        storeid: state.existingPunch.storeid,
        comment: state.existingPunch.comment,
        userid: state.existingPunch.userid,
        edited: state.existingPunch.edited,
        editType: state.existingPunch.editType,
        department: state.existingPunch.departmentId,
        editDate: state.existingPunch.editDate
      };
      if (!isEqual(tempPunch, previousPunch)) {
        dispatch({ type: actions.EDIT_SET_PUNCH, punch: tempPunch });
        dispatch({ type: actions.EDIT_SET_EXISTING_PUNCH, punch: null });
      }
    }
  }, [state.punch, state.existingPunch]);

  useEffect(() => {
    dispatch({ type: actions.EDIT_SET_PUNCH_USER, punchUser: "" });
  }, []);

  useEffect(() => {
    getDepartments();
  }, [context.lastClockStore]);

  // useEffect(() => {
  //   formIsValid();
  // }, [state.punch]);

  useEffect(() => {}, [state.errors]);

  function formIsValid() {
    const _errors = {};
    if (!state.punch.punchType) _errors.punchType = "Punch Type Required";
    if (!state.punch.punchIn) _errors.punchIn = "Punch In Required";
    if (!state.punch.timeIn) _errors.timeIn = "Time in Required";

    dispatch({ type: actions.EDIT_SET_ERROR, errors: _errors });
    // form is valid is errors object has no properties
    return Object.keys(_errors).length === 0;
  }

  const handleChange = (e) => {
    let changeText = state.punch.editType;
    if (state.punch.editType) {
      if (!state.punch.editType.toUpperCase().includes("PUNCHTYPE")) {
        changeText = changeText + "punchType,";
      }
    } else {
      changeText = "punchType,";
    }

    dispatch({
      type: actions.EDIT_SET_PUNCH,
      punch: {
        ...state.punch,
        storeid: context.lastClockStore,
        userid: state.punchUser,
        comment: "",
        punchType: e.target.value,
        edited: true,
        editType: changeText
      }
    });
  };

  const handleDateInChange = (date) => {
    if (date < context.startDate) {
      toast.error("Your Punch in date cannot be prior to week start", {
        position: toast.POSITION.TOP_LEFT
      });
      return;
    }
    let changeText = state.punch.editType;
    if (state.punch.editType) {
      if (!state.punch.editType.toUpperCase().includes("DATEIN")) {
        changeText = changeText + "dateIn,";
      }
    } else {
      changeText = "dateIn,";
    }

    if (!isValidDate(state.punch.punchOut)) {
      dispatch({
        type: actions.EDIT_SET_PUNCH,
        punch: {
          ...state.punch,
          punchIn: date,
          punchOut: date,
          edited: true,
          editType: changeText
        }
      });
    } else {
      dispatch({
        type: actions.EDIT_SET_PUNCH,
        punch: {
          ...state.punch,
          punchIn: date,
          edited: true,
          editType: changeText
        }
      });
    }
  };

  const handleInChange = (punchin) => {
    let changeText = state.punch.editType;
    if (state.punch.editType) {
      if (state.punch.editType) {
        if (!state.punch.editType.toUpperCase().includes("TIMEIN")) {
          changeText = changeText + "timeIn,";
        }
      } else {
        changeText = "timeIn,";
      }
    } else {
      changeText = "timeIn,";
    }

    dispatch({
      type: actions.EDIT_SET_PUNCH,
      punch: {
        ...state.punch,
        timeIn: state.punch.punchIn
          ? formatDate(state.punch.punchIn) + " " + formatTimeAmPm(punchin)
          : formatDate(new Date()) + " " + formatTimeAmPm(punchin),
        rawPunchIn: state.punch.punchIn
          ? formatDate(state.punch.punchIn) + " " + formatTimeAmPm(punchin)
          : formatDate(new Date()) + " " + formatTimeAmPm(punchin),
        punchIn: state.punch.punchIn
          ? formatDate(state.punch.punchIn)
          : formatDate(new Date()),
        edited: true,
        editType: changeText
      }
    });
  };

  const handleDateOutChange = (date) => {
    let changeText = state.punch.editType;
    if (state.punch.editType) {
      if (!state.punch.editType.toUpperCase().includes("DATEOUT")) {
        changeText = changeText + "dateOut,";
      }
    } else {
      changeText = "dateOut,";
    }

    dispatch({
      type: actions.EDIT_SET_PUNCH,
      punch: {
        ...state.punch,
        punchOut: date,
        edited: true,
        editType: changeText
      }
    });
  };

  const handleOutChange = (punchout) => {
    let changeText = state.punch.editType;
    if (state.punch.editType) {
      if (!state.punch.editType.toUpperCase().includes("TIMEOUT")) {
        changeText = changeText + "timeout,";
      }
    } else {
      changeText = "timeout,";
    }

    dispatch({
      type: actions.EDIT_SET_PUNCH,
      punch: {
        ...state.punch,
        timeOut: state.punch.punchOut
          ? formatDate(state.punch.punchOut) + " " + formatTimeAmPm(punchout)
          : formatDate(new Date()) + " " + formatTimeAmPm(punchout),
        rawPunchOut: state.punch.punchOut
          ? formatDate(state.punch.punchOut) + " " + formatTimeAmPm(punchout)
          : formatDate(new Date()) + " " + formatTimeAmPm(punchout),
        edited: true,
        editType: changeText
      }
    });
  };

  const handleCommentChange = (e) => {
    let changeText = state.punch.editType;
    if (state.punch.editType) {
      if (!state.punch.editType.toUpperCase().includes("COMMENT")) {
        changeText = changeText + "comment,";
      }
    } else {
      changeText = "comment,";
    }

    dispatch({
      type: actions.EDIT_SET_PUNCH,
      punch: {
        ...state.punch,
        comment: e.target.value,
        edited: true,
        editType: changeText
      }
    });
  };

  const handleDepartmentChange = (e) => {
    let changeText = state.punch.editType;
    if (state.punch.editType) {
      if (!state.punch.editType.toUpperCase().includes("DEPT")) {
        changeText = changeText + "dept,";
      }
    } else {
      changeText = "dept,";
    }

    dispatch({
      type: actions.EDIT_SET_PUNCH,
      punch: {
        ...state.punch,
        department: e.target.value,
        edited: true,
        editType: changeText
      }
    });
  };

  const handleClick = () => {
    debugger;
    if (!formIsValid()) return;

    if (!state.punch.storeid) {
      state.punch.storeid = context.lastClockStore;
    }
    if (state.punch.storeid === 0) {
      state.punch.storeid = context.lastClockStore;
    }
    if (isValid(context.punch)) {
      if (isValid(context.punch.selectedUser)) {
        state.punch.userid = context.punch.selectedUser;
      } else {
        state.punch.userid = context.userid;
      }
    } else {
      if (state.punch.userid === 0) {
        state.punch.userid = context.userid;
      }
    }

    process.env.NODE_ENV === "development"
      ? (state.punch.mode = "debug")
      : (state.punch.mode = "live");

    if (isValidDate(state.punch.punchIn)) {
      const dte = new Date(state.punch.punchIn);
      state.punch.timeZoneOffset = dte.getTimezoneOffset();
      state.punch.isDst = dte.isDstObserved();
    }

    // make the punch in and punch out simple date strings to avoid the timezone offsets
    state.punch.punchIn =
      formatDate(state.punch.punchIn) +
      " " +
      formatTimeAmPm(state.punch.timeIn);

    if (
      typeof state.punch.timeOut !== "undefined" &&
      state.punch.timeOut !== null
    ) {
      state.punch.punchOut =
        formatDate(state.punch.punchOut) +
        " " +
        formatTimeAmPm(state.punch.timeOut);
    } else {
      state.punch.punchOut = null;
    }

    enterManualPunch(context.clockUrl, context.clockToken, state.punch)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          dispatch({ type: actions.EDIT_SET_PUNCH, punch: initialState });
          dispatch({ type: actions.APP_SET_RERENDER });
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An error occured processing your manual punch", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const getDepartments = () => {
    dispatch({
      type: actions.EDIT_SET_IS_LOADING_DEPARTMENTS,
      isLoading: true
    });
    loadDepartments(
      context.clockUrl,
      context.clockToken,
      context.lastClockStore
    )
      .then((response) => {
        dispatch({
          type: actions.EDIT_SET_IS_LOADING_DEPARTMENTS,
          isLoading: false
        });
        const j = response.data;
        if (j.error === 0) {
          dispatch({
            type: actions.EDIT_SET_DEPARTMENTS,
            departments: j.departments
          });
        } else {
          dispatch({
            type: actions.EDIT_SET_IS_LOADING_DEPARTMENTS,
            isLoading: false
          });
          dispatch({ type: actions.EDIT_SET_DEPARTMENTS, departments: [] });
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: actions.EDIT_SET_IS_LOADING_DEPARTMENTS,
          isLoading: false
        });
        console.log(err);
        dispatch({ type: actions.EDIT_SET_DEPARTMENTS, departments: [] });
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  return (
    <div className="form d-manual-entry">
      {state.isLoadingDepartments && <Spinner />}
      <div className="d-manual-entry-header">Manual Entry</div>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container justify="space-around">
          <div className="row w-100">
            <div className="col-3">
              <label>Punch Type</label>
              <select
                className="form-control"
                value={state.punch.punchType}
                tabIndex="0"
                onChange={handleChange}
              >
                <option value="0">PunchType</option>
                <option value="1">Punch</option>
                <option value="2">Break</option>
                <option value="3">Lunch</option>
              </select>
              {state.errors.punchType && (
                <div className="alert alert-danger">
                  {state.errors.punchType}
                </div>
              )}
            </div>

            <div className="col-3">
              <KeyboardDatePicker
                format="MM/dd/yyyy"
                margin="normal"
                label="Date In"
                tabIndex="1"
                value={state.punch.punchIn}
                onChange={handleDateInChange}
                KeyboardButtonProps={{
                  "aria-label": "change date"
                }}
              />
              {state.errors.punchIn && (
                <div className="alert alert-danger">{state.errors.punchIn}</div>
              )}
            </div>
            <div className="col-3">
              <KeyboardTimePicker
                margin="normal"
                label="Time in"
                tabIndex="2"
                value={state.punch.timeIn}
                onChange={handleInChange}
                KeyboardButtonProps={{
                  "aria-label": "change time"
                }}
              />

              {state.errors.timeIn && (
                <div className="alert alert-danger">{state.errors.timeIn}</div>
              )}
            </div>
            <div className="col-3">
              <label>Department</label>
              <select
                className="form-control"
                value={state.punch.department}
                tabIndex="3"
                onChange={handleDepartmentChange}
              >
                <option value="-1">Select a Department</option>
                {state.departments.map((record, index) => (
                  <option key={`do-${index}`} value={record.id}>
                    {record.description}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row w-100">
            <div className="col-3">
              <label>Comment</label>
              <input
                type="text"
                tabIndex="4"
                value={state.punch.comment || ""}
                className="form-control"
                onChange={handleCommentChange}
              />
            </div>
            <div className="col-3">
              <KeyboardDatePicker
                format="MM/dd/yyyy"
                margin="normal"
                tabIndex="5"
                label="Date Out"
                value={state.punch.punchOut}
                onChange={handleDateOutChange}
                KeyboardButtonProps={{
                  "aria-label": "change date"
                }}
              />
            </div>
            <div className="col-3">
              <KeyboardTimePicker
                margin="normal"
                label="Time out"
                tabIndex="6"
                value={state.punch.timeOut}
                onChange={handleOutChange}
                KeyboardButtonProps={{
                  "aria-label": "change time"
                }}
              />
            </div>
            <div className="col-3">
              <div className="row">
                {isEditable && !isLocked ? (
                  <div className="col d-manual-entry-submit">
                    <button
                      className="btn btn-outline-dark"
                      onClick={handleClick}
                      tabIndex="7"
                    >
                      Submit
                    </button>
                  </div>
                ) : null}
                <div className="col d-manual-entry-total-div">
                  <span>
                    <span>Total</span>
                    <div className="d-manual-entry-total">
                      {state.punch.total}
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </MuiPickersUtilsProvider>
    </div>
  );
};

ManualPunch.propTypes = {
  settings: PropTypes.object,
  context: PropTypes.object,
  reRender: PropTypes.bool,
  setReRender: PropTypes.func,
  existingPunch: PropTypes.object,
  setExistingPunch: PropTypes.func,
  isEditable: PropTypes.bool,
  isLocked: PropTypes.bool
};

export default ManualPunch;
