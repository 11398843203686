import axios from "axios";
import { formatDate } from "../utils";
import qs from "qs";

function salesPie(config, success, failure) {
  const { url, token, storeid, startDate, endDate, searchType } = config;
  axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: " Bearer " + token
    },
    url: url + "charts/pie",
    params: {
      storeid,
      startDate,
      endDate,
      searchType
    }
  })
    .then((response) => {
      if (success !== null) {
        success(response);
      }
    })
    .catch((err) => {
      if (failure !== null) {
        failure(err);
      }
    });
}

function salesLine(config, success, failure) {
  const { url, token, storeid, startDate, endDate, searchType } = config;
  axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "widgets/linesalesd3",
    params: {
      searchType,
      storeid,
      startDate,
      endDate
    }
  })
    .then((response) => {
      if (success !== null) {
        success(response);
      }
    })
    .catch((err) => {
      if (failure !== null) {
        failure(err);
      }
    });
}

function PieGroups(config, success, failure) {
  const { url, token, storeid, searchType } = config;
  axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "widgets/piegroups",
    params: {
      searchType,
      storeid
    }
  })
    .then((response) => {
      if (success !== null) {
        success(response);
      }
    })
    .catch((err) => {
      if (failure !== null) {
        console.log(err);
      }
    });
}

async function weekLineSales(config) {
  const {
    url,
    token,
    storeid,
    searchType,
    startDate,
    endDate,
    direction,
    numrows
  } = config;
  try {
    if (parseInt(numrows) === 0) return;
  } catch (e) {
    console.log(e);
    return;
  }
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "widgets/linesalesd3",
    params: {
      searchType,
      storeid,
      startDate,
      endDate,
      direction,
      numrows
    }
  });
  return json;
}

async function rptFinD3(config) {
  const {
    url,
    token,
    searchType,
    storeid,
    startDate,
    endDate,
    direction,
    numrows,
    totalizer,
    type
  } = config;
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "widgets/rpt_fin_d3",
    params: {
      searchType: parseInt(searchType) === 1 ? "Stores" : "Group",
      storeid,
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      direction,
      numrows,
      totalizer,
      type
    }
  });
  return json;
}
async function readCharts(url, token) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "widgets/charts"
  });
  return json;
}

async function createChart(url, token) {
  let json = await axios({
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "widgets/addchart"
  });
  return json;
}

async function deleteChart(url, token, id) {
  let json = await axios({
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "widgets/deletechart",
    params: {
      id
    }
  });
  return json;
}

async function updateChart(
  url,
  token,
  id,
  direction,
  numrows,
  totalizer,
  format
) {
  let json = await axios({
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "widgets/updatechart",
    params: {
      id,
      direction,
      numrows,
      totalizer,
      format
    }
  });
  return json;
}

async function readSalesByUser(url, token, tlz) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "charts/salesbyuser",
    params: {
      tlz
    }
  });
  return json;
}

async function readBannerPanels(url, token) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "charts/bannerpanel"
  });
  return json;
}

async function readDeptGroups(url, token) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "charts/deptgroups"
  });
  return json;
}

async function readRefunds(url, token, tlz) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "charts/refunds",
    params: {
      tlz
    }
  });
  return json;
}

async function readSubSales(url, token) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "charts/subs"
  });
  return json;
}

async function getBannerStores(url, token, id) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "charts/bannerstores",
    params: {
      id
    }
  });
  return json;
}

async function getPivotItems(
  url,
  token,
  startDate,
  endDate,
  rowSchema,
  columnSchema,
  valueSchema
) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "charts/itempivot",
    params: {
      startDate,
      endDate,
      rowSchema: qs.stringify(rowSchema),
      columnSchema: qs.stringify(columnSchema),
      valueSchema: qs.stringify(valueSchema)
    }
  });
  return json;
}

async function getPivotItemsAttributes(url, token, startDate, endDate) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "charts/itempivotattributes",
    params: {
      startDate,
      endDate
    }
  });
  return json;
}

async function readMapData(url, token) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "charts/map"
  });
  return json;
}

async function readTopItems(
  url,
  token,
  topcount,
  groupid,
  enddate,
  useGroups,
  useSingleStore,
  singleStore
) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "charts/topitems",
    params: {
      topcount,
      groupid,
      enddate,
      useGroups,
      useSingleStore,
      singleStore
    }
  });
  return json;
}

async function getItemsByUpc(
  url,
  token,
  queryDate,
  upc,
  useGroups,
  singleStore,
  groupid,
  storeid
) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "charts/byupc",
    params: {
      queryDate,
      upc,
      useGroups,
      singleStore,
      groupid,
      storeid
    }
  });
  return json;
}

export {
  salesPie,
  salesLine,
  PieGroups,
  weekLineSales,
  rptFinD3,
  readCharts,
  createChart,
  deleteChart,
  updateChart,
  readSalesByUser,
  readBannerPanels,
  readDeptGroups,
  readRefunds,
  readSubSales,
  getBannerStores,
  getPivotItems,
  getPivotItemsAttributes,
  readMapData,
  readTopItems,
  getItemsByUpc
};
