import React, { useState, useEffect, useContext } from "react";
import { ContextConfig } from "../App";
import { isValid } from "../utils";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { readStores, manageLinks } from "../api/GroupApi";
import ToggleSwitch from "./ToggleSwitch";
import { isMobile } from "react-device-detect";

const GroupList = ({ group }) => {
  const [stores, setStores] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const context = useContext(ContextConfig);
  const [data, setData] = useState(false);

  useEffect(() => {
    if (!isValid(group)) {
      return;
    }
    const config = {
      url: context.url,
      token: context.token,
      group: group
    };
    readStores(
      config,
      (response) => {
        const j = response.data;
        if (j.error === 0) {
          setStores(j.stores);
          setTotalCount(j.totalCount);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      },
      (err) => {
        console.log(err);
        toast.show("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      }
    );
  }, [group, context.url, context.token, data]);

  const handleToggle = (storeid, toggleState) => {
    let uri = "groups/createlinkreact";
    let method = "POST";
    if (toggleState === 0 || toggleState === false) {
      uri = "groups/removelink";
      method = "DELETE";
    }

    const config = {
      method: method,
      url: context.url + uri,
      token: context.token,
      group: group,
      storeid: storeid
    };

    manageLinks(
      config,
      (response) => {
        const j = response.data;
        if (j.error === 0) {
          setData(!data);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      },
      (err) => {
        console.log(err);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      }
    );
  };

  return (
    <div
      className="container-fluid mt-4"
      style={{ overflow: "auto" }}
      id="desktop-group-list"
    >
      <div className="container-fluid">
        There are currently {totalCount} records being displayed.
      </div>
      <table className="table table-dark table-striped table-hover">
        <thead className="thead-light">
          <tr>
            {!isMobile ? <th scope="col">Id</th> : null}
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Active</th>
          </tr>
        </thead>
        <tbody>
          {stores.length === 0 ? (
            <tr>
              <td colSpan="3">Sorry, There are no results to show</td>
            </tr>
          ) : (
            stores.map((store) => {
              return (
                <tr key={store.storeid}>
                  {!isMobile ? <td>{store.storeid}</td> : null}
                  <td>{store.store_Number}</td>
                  <td>{store.store_Name}</td>
                  <td>
                    <ToggleSwitch
                      id={store.storeid}
                      handleClick={handleToggle}
                      active={store.active === 1 ? true : false}
                    />
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </div>
  );
};

GroupList.propTypes = {
  group: PropTypes.string
};

export default GroupList;
