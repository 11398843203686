import React, { useState, useEffect, useContext } from "react";
import { BrowserView, MobileView, isBrowser } from "react-device-detect";
import MobileTime from "./Mobile/MobileTime";
import DesktopTime from "./Desktop/DesktopTime";
import HeaderRb from "../common/HeaderRb";
import { usePrevious, addDays, handleCsv } from "../utils";
import { isEqual } from "../fp";
import Spinner from "../common/Spinner";
import { ContextConfig } from "../App";
import { readTotalEmpHours, readEmpHours } from "../api/timeApi";
import Header from "../Header/Header";
import "./Time.css";

const Time = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [punches, setPunches] = useState([]);
  const [timeDays, setTimeDays] = useState([]);
  const [reRender, setReRender] = useState(false);
  const [startDate, setStartDate] = useState(addDays(new Date(), -7));
  const [endDate, setEndDate] = useState(new Date());

  const context = useContext(ContextConfig);

  const previousPunches = usePrevious(punches);
  const previousTimeDays = usePrevious(timeDays);

  useEffect(() => {
    listPunches();
    TimeByDate();
  }, [reRender]);

  useEffect(() => {
    if (!isEqual(previousPunches, punches)) {
      listPunches();
    }
    if (!isEqual(previousTimeDays, timeDays)) {
      TimeByDate();
    }
  }, [punches, timeDays]);

  const listPunches = () => {
    setIsLoading(true);
    let storeid = 0;
    let searchType = 1;
    if (context.lastSearchType == 2) {
      searchType = 2;
      storeid = context.lastGroup;
    } else {
      storeid = context.lastStoreid;
    }
    readEmpHours(
      context.url,
      context.token,
      searchType,
      storeid,
      context.lastStoreid,
      context.startDate,
      context.endDate
    )
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          setPunches(j.hours);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const TimeByDate = () => {
    setIsLoading(true);
    let storeid = 0;
    let searchType = 1;
    if (context.lastSearchType == 2) {
      searchType = 2;
      storeid = context.lastGroup;
    } else {
      storeid = context.lastStoreid;
    }
    readTotalEmpHours(
      context.url,
      context.token,
      startDate,
      endDate,
      searchType,
      context.lastGroup,
      storeid
    )
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          setTimeDays(j.hours);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const exportTime = () => {
    handleCsv(punches, "timeExport.csv");
  };

  return (
    <div>
      {!isLoading ? null : <Spinner />}
      <HeaderRb />
      <div className="container-fluid">
        <div className="main-time-header">
          <span className="main-time-logo">
            <i className="far fa-clock"></i>
          </span>
          Time &nbsp;<i className="fas fa-download" onClick={exportTime}></i>
        </div>
        <Header
          reRender={reRender}
          setReRender={setReRender}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          setLoading={setIsLoading}
          showSingleStore={isBrowser ? true : false}
        />
        <BrowserView>
          <DesktopTime punchList={punches} timeDays={timeDays} />
        </BrowserView>
        <MobileView>
          <MobileTime punchList={punches} />
        </MobileView>
      </div>
    </div>
  );
};

Time.propTypes = {};

export default Time;
