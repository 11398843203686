import React, { useState, useEffect, useContext } from "react";
import LoadingOverlay from "react-loading-overlay";
import HeaderRB from "../common/HeaderRb";
import { ContextConfig } from "../App";
import { GetUsersByCompany } from "../api/UsersApi";
import { toast } from "react-toastify";
import { isEqual } from "../fp";
import { usePrevious, encrypt } from "../utils";
import DesktopLookup from "./DesktopLookup";
import MobileLookup from "./MobileLookup";
import { BrowserView, MobileView } from "react-device-detect";
import PasswordStrengthMeter from "../common/PasswordStrengthMeter";
import Axios from "axios";
import qs from "qs";

const ResetPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [userid, setUserid] = useState(0);

  const context = useContext(ContextConfig);

  const previousUsers = usePrevious(users);

  useEffect(() => {
    if (!isEqual(previousUsers, users)) {
      loadUsers();
    }
  }, []);

  const handleUserSuccess = (response) => {
    setIsLoading(false);
    const j = response.data;
    if (j.error === 0) {
      setUsers(j.users);
    } else {
      toast.error(j.msg, {
        position: toast.POSITION.TOP_LEFT
      });
    }
  };

  const handleUserFailure = () => {
    setIsLoading(false);
    toast.error("An error occured fetching your Companys Users", {
      position: toast.POSITION.TOP_LEFT
    });
  };

  const loadUsers = () => {
    setIsLoading(true);
    GetUsersByCompany(context.url, context.token, context.company)
      .then(handleUserSuccess)
      .catch(handleUserFailure);
  };

  const handleMobileUserSelect = (e) => {
    console.log("userid selected: " + e);
    setUserid(e);
  };

  const resetPassword = (password) => {
    setIsLoading(true);
    const encryptedPassword = encrypt(password, context.key).toString();
    Axios({
      method: "POST",
      cors: true,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + context.token
      },
      url: context.url + "mobile/reset",
      data: qs.stringify({
        userid: userid,
        password: encryptedPassword
      })
    })
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          toast.success("Your password has been reset", {
            position: toast.POSITION.TOP_LEFT
          });
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  return (
    <LoadingOverlay active={isLoading} spinner text="Loading Users...">
      <HeaderRB />
      <div className="container-fluid">
        <BrowserView>
          <DesktopLookup users={users} />
        </BrowserView>
        <MobileView>
          <MobileLookup users={users} selectUser={handleMobileUserSelect} />
        </MobileView>
        <form>
          <PasswordStrengthMeter
            userid={userid}
            confirmPasswordChange={resetPassword}
          />
        </form>
      </div>
    </LoadingOverlay>
  );
};

export default ResetPassword;
