import React, { useState, useEffect, useContext } from "react";
import { getLoyaltyList, setLoyaltyFlag } from "../../api/StoreApi";
import { ContextConfig } from "../../App";
import { toast } from "react-toastify";
import ToggleSwitch from "../../Groups/ToggleSwitch";
import HeaderRB from "../../common/HeaderRb";
import { debounce } from "../../fp";
import { handleCsv } from "../../utils";

const AssignLoyaltyStores = () => {
  const [stores, setStores] = useState([]);
  const [input, setInput] = useState("");
  const [filteredStores, setFilteredStores] = useState([]);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const context = useContext(ContextConfig);

  useEffect(() => {
    fetchStores();

    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);

    findDivHeight();
    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, []);

  useEffect(() => {
    filterStores(stores);
  }, [input]);

  const filterStores = (stores) => {
    if (input.length === 0) {
      setFilteredStores(stores);
      return;
    }
    const filterdStores = stores.filter((s) => s.storeNumber.includes(input));
    setFilteredStores(filterdStores);
  };

  const findDivHeight = () => {
    const navbar = document
      .getElementById("master-navbar")
      .getBoundingClientRect().height;
    const controls = document.getElementById("controls").getBoundingClientRect()
      .height;
    const body = document.getElementById("body");
    const usedSpace = navbar + controls + 50;
    const remainingSpace = window.innerHeight - usedSpace;
    body.style.height = remainingSpace + "px";
    body.style.overflowY = "auto";
  };

  const fetchStores = () => {
    getLoyaltyList(context.url, context.token)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          if (input.length > 0) {
            filterStores(j.stores);
          } else {
            setStores(j.stores);
            setFilteredStores(j.stores);
          }
        } else {
          setStores([]);
          setFilteredStores([]);
          toast.error(j.msg, {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      })
      .catch((err) => {
        setStores([]);
        setFilteredStores([]);
        console.log(err);
        toast.error("An internal error occured", {
          position: toast.POSITION.TOP_RIGHT
        });
      });
  };

  const handleToggle = (storeid, toggleState) => {
    let enabled = toggleState ? "1" : "0";
    setLoyaltyFlag(context.url, context.token, storeid, enabled)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          fetchStores();
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message, {
          postition: toast.POSITION.TOP_RIGHT
        });
      });
  };

  const download = () => {
    handleCsv(stores, "loyaltyStores.csv");
  };

  return (
    <div>
      <HeaderRB />

      <div className="container">
        <div id="controls">
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              marginTop: "5px"
            }}
          >
            <h3>Assign Loyalty Stores</h3>
            <span>
              <button className="btn btn-primary" onClick={download}>
                Download
              </button>
            </span>
          </div>
          <div className="row justify-content-center align-items-center mb-5">
            <div className="col-6">
              <input
                className="form-control"
                value={input}
                onChange={(e) => setInput(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div id="body">
          <table
            className="table table-striped"
            style={{ width: "90%", margin: "auto" }}
          >
            <thead>
              <th>Store ID</th>
              <th>#</th>
              <th>Name</th>
              <th>Active</th>
            </thead>
            <tbody>
              {filteredStores.map((store, i) => (
                <tr key={`s-${i}`}>
                  <td>{store.storeid}</td>
                  <td>{store.storeNumber}</td>
                  <td>{store.storeName}</td>
                  <td>
                    <ToggleSwitch
                      id={store.storeid}
                      handleClick={handleToggle}
                      active={store.loyalty === "1" ? true : false}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AssignLoyaltyStores;
