import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { getClockUsers, getClockCompanyUsers } from "../../api/UsersApi";
import ToggleSwitch from "../../Groups/ToggleSwitch";
import Popup from "reactjs-popup";
import RolesCopy from "../Roles/RolesCopy";

const contentStyle = {
  width: "500px",
  padding: "10px",
  maxHeight: "400px",
  overflowY: "auto",
  overflowX: "hidden"
};
const overlayStyle = { background: "rgba(0,0,0,0.8)" };
const arrowStyle = { color: "#000" }; // style for an svg element
import { getUserRoles, createRole, copyUserRoles } from "../../api/rolesApi";

const UserRoles = ({ context, user }) => {
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [copy, setCopy] = useState(false);
  const [copyUser, setCopyUser] = useState(false);
  const [warning, setWarning] = useState("");
  const [changeUserLevel, setChangeUserLevel] = useState(false);

  useEffect(() => {
    if (user.company != "0") {
      loadUsers();
      loadMenu();
    }
  }, [user]);

  const isNumber = (input) => {
    const number = parseFloat(input);
    if (number.toString() == "NaN") return false;
    if (number >= 0) return true;
  };

  const loadUsers = () => {
    let company = user.company;
    if (!isNumber(user.company)) {
      if (isNumber(user.company_Id)) {
        company = user.company_Id;
      } else {
        toast.error("Could not find company for user");
        return;
      }
    }
    // we need to swap out the function based on if they base company is dcr or not
    let userLookupFunction = getClockUsers;
    if (context.company === 1) {
      userLookupFunction = getClockCompanyUsers;
    }
    userLookupFunction(context.clockUrl, context.clockToken, "", 0, company)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setUsers(j.users);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An error has occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const handleCheckChange = (e) => {
    setCopy(e.target.checked);
  };

  const getUserLevel = (userid) => {
    const user = users.filter((u) => u.userid == userid);
    return {
      userId: user[0].userid,
      level: user[0].userLevel,
      levelDescription: user[0].levelDescription
    };
  };

  const loadMenu = () => {
    console.log("getting user roles");
    getUserRoles(context.clockUrl, context.clockToken, user.userid, 1)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          if (j.roles && j.roles.length > 0) {
            setRoles(j.roles);
          }
        } else {
          setRoles([]);
          if (j.error === 1) {
            toast.error(j.msg, {
              position: toast.POSITION.TOP_LEFT
            });
          }
        }
      })
      .catch((err) => {
        if (user) {
          console.log(err);
          toast.error("An error has occured processing your request", {
            position: toast.POSITION.TOP_LEFT
          });
        }

        // by default, we need to clear out the roles
        setRoles([]);
      });
  };

  const handleToggleRole = (roleid, toggleState) => {
    createRole(
      context.clockUrl,
      context.clockToken,
      user.userid,
      roleid,
      toggleState
    )
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          loadMenu();
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const handleCopyRoles = (e) => {
    const newUserLevel = getUserLevel(copyUser);
    e.preventDefault();
    copyUserRoles(
      context.clockUrl,
      context.clockToken,
      copyUser,
      user.userid,
      changeUserLevel,
      newUserLevel.level
    )
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          toast.success("Your Roles have been copied over");
          setWarning("");
          loadMenu();
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const handleCopyUserChanged = (e) => {
    // first let's check to see if these two users have the same level or not
    setCopyUser(e.target.value);
    const sourceUser = getUserLevel(e.target.value);
    const destUser = getUserLevel(user.userid);
    if (sourceUser.level != destUser.level) {
      setWarning(
        "The Source User has a level of (" +
          sourceUser.level +
          ") " +
          sourceUser.levelDescription +
          " and the destination user has a level of (" +
          destUser.level +
          ") " +
          destUser.levelDescription +
          ". Do you also want to make the user levels match? If not, the destination user will not see all the available roles in their menu."
      );
      return;
    }
    setCopyUser(e.target.value);
  };

  const handleCheckUserLevelChange = (e) => {
    setChangeUserLevel(e.target.checked);
  };

  return (
    <div className="row">
      <div
        className="col justify-content-center"
        style={{ textAlign: "center" }}
      >
        <Popup
          trigger={<button className="btn btn-primary">Select Roles</button>}
          position="top center"
          {...{ contentStyle, overlayStyle, arrowStyle }}
        >
          <div style={{ width: "500px" }}>
            <h4>Select Roles to assign</h4>
            <RolesCopy
              handleCopyRoles={handleCopyRoles}
              handleCheckChange={handleCheckChange}
              copy={copy}
              setCopyUser={handleCopyUserChanged}
              users={users}
              warning={warning}
              handleCheckUserLevelChange={handleCheckUserLevelChange}
            />
            <table className="table table-striped role-table">
              <thead>
                <tr>
                  <th>Role</th>
                  <th className="role-toggle-header">Assigned</th>
                </tr>
              </thead>
              <tbody>
                {roles.map((role, index) => (
                  <tr key={`m-${index}`}>
                    <td className="role-description">
                      <span className="role-description-span">
                        {role.description}
                      </span>
                    </td>
                    <td className="role-toggle">
                      <ToggleSwitch
                        id={role.id}
                        handleClick={handleToggleRole}
                        active={role.active === "1" ? true : false}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Popup>
      </div>
    </div>
  );
};

UserRoles.propTypes = {
  context: PropTypes.object,
  user: PropTypes.object
};

export default UserRoles;
