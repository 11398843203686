import React from "react";
import PropTypes from "prop-types";
import Column from "../../../common/Column";
import ReactTooltip from "react-tooltip";
import { formatDate } from "../../../utils";
import { formatTotalizer } from "./PayServerTransactions-Reducer";

const MobilePayserverTransactionsTable = ({
  filteredData,
  dispatch,
  state
}) => {
  return (
    <React.Fragment>
      {filteredData.length === 0 ? (
        <div
          className="container"
          style={{ fontSize: "1.3rem", fontWeight: "bold" }}
        >
          No records were returned
        </div>
      ) : (
        <div className="container">
          <ReactTooltip />
          <table className="table">
            <thead>
              <tr>
                <Column
                  filterField={"filterF254"}
                  sortField={"sortF254"}
                  label={"Date"}
                  dispatch={dispatch}
                  state={state}
                  sortable={false}
                  filterable={false}
                />
                <Column
                  filterField={"filterF1056"}
                  sortField={"sortF1056"}
                  label={"Store"}
                  dispatch={dispatch}
                  state={state}
                  sortable={true}
                  filterable={false}
                />
                <Column
                  filterField={"filterF1148"}
                  sortField={"sortF1148"}
                  label={"Customer"}
                  dispatch={dispatch}
                  state={state}
                  sortable={true}
                  filterable={false}
                />
                <Column
                  filterField={"filterF1034"}
                  sortField={"sortF1034"}
                  label={"Totalizer"}
                  dispatch={dispatch}
                  state={state}
                  sortable={true}
                  filterable={false}
                />
                <Column
                  filterField={"filterF1032"}
                  sortField={"sortF1032"}
                  label={"Trans"}
                  dispatch={dispatch}
                  state={state}
                  sortable={true}
                  filterable={false}
                />
                <Column
                  filterField={"filterF64"}
                  sortField={"sortF64"}
                  label={"Points"}
                  dispatch={dispatch}
                  state={state}
                  sortable={true}
                  filterable={false}
                />
              </tr>
            </thead>
            <tbody>
              {filteredData.map((record, index) => (
                <tr key={`tr-ps-${index}`}>
                  <td>{formatDate(record.f254)}</td>
                  <td>{record.f1056}</td>
                  <td className="ps-customer">
                    <span className="ps-customer-number">{record.f1148}</span>
                  </td>
                  <td>{formatTotalizer(record.f1034)}</td>
                  <td>{record.f1032}</td>
                  <td>{record.f64}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </React.Fragment>
  );
};

MobilePayserverTransactionsTable.propTypes = {
  filteredData: PropTypes.array,
  dispatch: PropTypes.func,
  state: PropTypes.object
};

export default MobilePayserverTransactionsTable;
