import React, { useState, useEffect, useContext } from "react";
import { ContextConfig } from "../App";
import { toast } from "react-toastify";
import { Row, Col } from "reactstrap";
import { getItemsets, getItemsetDetails } from "../api/predictApi";
import BouncyLoader from "../common/loaders/BouncyLoader";
import BoxLoader from "../common/loaders/BoxLoader";
import { debounce } from "../fp";
import DatePicker from "react-datepicker";
import { getCurrentState, formatDate, handleCsv, usePrevious } from "../utils";
import { savePrefs } from "../api/GlobalApi";
import { readGroupAsync } from "../api/GroupApi";
import { getAssignedStoresAsync } from "../api/StoreApi";
import ReactTooltip from "react-tooltip";
import ModalItemsetDetails from "./ModalItemsetDetails";
import Tooltip from "../Tooltip/Tooltip";
import { useSelector, useDispatch } from "react-redux";
import TypeAheadDropDown from "../common/TypeAheadDropDown";
import PagingToolbar from "../common/PagingToolbar";
import * as actions from "../actions/actionTypes";

const pickerData = [
  { id: 1, name: "Stores" },
  { id: 2, name: "Group" },
  { id: 3, name: "Single Store" }
];

const modalStyle = {
  animationDuration: "500ms",
  WebKitAnimationDuration: "500ms"
};

const Itemsets = () => {
  const [support, setSupport] = useState(2);
  // eslint-disable-next-line no-unused-vars
  const [showGroups, setShowGroups] = useState(false);
  // eslint-disable-next-line
  const [showSelection, setShowSelection] = useState(false);
  const [reRender, setReRender] = useState(false);
  // eslint-disable-next-line
  const [searchType, setSearchType] = useState(1);
  const [groups, setGroups] = useState([]);
  // eslint-disable-next-line
  const [group, setGroup] = useState([]);
  const [orderBy, setOrderBy] = useState("size");
  const [orderType, setOrderType] = useState("desc");
  const [details, setDetails] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const state = useSelector((app) => app.itemsetsReducer);
  const dispatch = useDispatch();

  const context = useContext(ContextConfig);
  // eslint-disable-next-line
  let lastSearchType = context.lastSearchType;

  const previousGroup = usePrevious(group);
  const previouslySearching = usePrevious(state.isSearching);

  useEffect(() => {
    getCurrentState(context, setShowGroups, setShowSelection);
    savePrefs(
      context.url,
      context.token,
      context.lastStoreid,
      context.lastGroup,
      context.lastSearchType,
      context.lastGroupName,
      prefsSuccess,
      prefsFailure
    );
    fetchItemSets();

    if (context.lastSearchType == 3) {
      fetchStores();
    } else if (context.lastSearchType == 2) {
      fetchGroups();
    }

    if (state.currentStore == "") {
      dispatch({
        type: actions.IS_SET_CURRENT_STORE,
        store: context.lastStoreid
      });
    }

    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);

    findDivHeight();

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, [support, reRender, orderBy, orderType]);

  useEffect(() => {
    if (state.itemsets.length > 0) {
      setIntiallyFilteredItemsets();
    }
  }, [state.itemsets]);

  useEffect(() => {
    if (group != previousGroup && group != 0) {
      fetchItemSets();
    }
  }, [group]);

  useEffect(() => {
    console.log("search results changed", state.searchResults.length);
    if (state.searchResults.length > 0) {
      setIntiallyFilteredItemsets();
    }
  }, [state.searchResults]);

  useEffect(() => {
    if (!state.isSearching && previouslySearching) {
      setIntiallyFilteredItemsets();
    }
  }, [state.isSearching]);

  const prefsSuccess = () => {};

  const prefsFailure = (err) => {
    console.log(err);
  };

  const findDivHeight = () => {
    const navbar = document
      .getElementById("master-navbar")
      .getBoundingClientRect().height;
    const controls = document
      .getElementById("itemset-controls")
      .getBoundingClientRect().height;
    const usedSpace = navbar + controls + 100;
    const remaining = window.innerHeight - usedSpace;

    const body = document.getElementById("itemset-body");
    body.style.height = remaining + "px";
    body.style.overflow = "auto";
  };

  const fetchItemSets = () => {
    dispatch({ type: actions.IS_IS_LOADING, isLoading: true });
    let useGroups = 0;
    let singleStore = 0;
    if (context.lastSearchType == 2) useGroups = 1;
    if (context.lastSearchType == 3) singleStore = 1;
    getItemsets(
      context.url,
      context.token,
      support,
      formatDate(state.startDate),
      formatDate(state.endDate),
      useGroups,
      context.lastSearchType == 2 ? context.lastGroup : context.lastStoreid,
      singleStore,
      orderBy,
      orderType
    )
      .then((response) => {
        dispatch({ type: actions.IS_IS_LOADING, isLoading: false });
        const j = response.data;
        if (j.error === 0) {
          dispatch({ type: actions.IS_SET_ITEMSETS, itemsets: j.itemsets });
        } else {
          dispatch({ type: actions.IS_SET_ITEMSETS, itemsets: [] });
          dispatch({ type: actions.IS_SET_PAGED_ITEMSETS, itemsets: [] });
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: actions.IS_IS_LOADING, isLoading: false });
        dispatch({ type: actions.IS_SET_ITEMSETS, itemsets: [] });
        dispatch({ type: actions.IS_SET_PAGED_ITEMSETS, itemsets: [] });
        toast.error("An error occured fetching your itemsets", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const fetchGroups = () => {
    dispatch({ type: actions.IS_IS_LOADING_GROUPS, isLoading: true });
    readGroupAsync(context.url, context.token)
      .then((response) => {
        dispatch({ type: actions.IS_IS_LOADING_GROUPS, isLoading: false });
        const j = response.data;
        if (j.error === 0) {
          setGroups(j.groups);
        } else {
          setGroups([]);
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log("done loading groups");
        dispatch({ type: actions.IS_IS_LOADING_GROUPS, isLoading: false });
        console.log(err);
        toast.error("An error occured fetching your groups", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const fetchStores = () => {
    dispatch({ type: actions.IS_IS_LOADING_STORES, isLoading: true });
    getAssignedStoresAsync(context.url, context.token)
      .then((response) => {
        dispatch({ type: actions.IS_IS_LOADING_STORES, isLoading: false });
        const j = response.data;
        if (j.error === 0) {
          dispatch({ type: actions.IS_ASSIGNED_STORES, stores: j.items });
        } else {
          dispatch({ type: actions.IS_ASSIGNED_STORES, stores: [] });
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: actions.IS_IS_LOADING_STORES, isLoading: false });
        dispatch({ type: actions.IS_ASSIGNED_STORES, stores: [] });
        toast.error("An error occured getting your assigned stores", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const handleSupportChange = (e) => {
    setSupport(e.target.value);
  };

  const handleStartDateChange = (e) => {
    dispatch({ type: actions.IS_SET_START_DATE, startDate: e });
    setReRender(!reRender);
  };

  const handleEndDateChange = (e) => {
    dispatch({ type: actions.IS_SET_END_DATE, endDate: e });
    setReRender(!reRender);
  };

  const handleStoreChange = (e) => {
    dispatch({ type: actions.IS_SET_CURRENT_STORE, store: e.storeid });
    context.lastStoreid = e.storeid;
    context.lastSearchType = 3;
    setReRender(!reRender);
  };

  const handleSelectionChange = (e) => {
    if (parseInt(e.target.value) === 2) {
      context.lastSearchType = 2;
      setSearchType(2);
      setShowGroups(true);
      fetchGroups();
    } else if (parseInt(e.target.value) === 3) {
      context.lastSearchType = 3;
      setSearchType(3);
      setShowGroups(true);
      lastSearchType = 3;
      fetchStores();
    } else {
      context.lastSearchType = 1;
      setShowGroups(false);
    }
    setReRender(!reRender);
  };

  const handleGroupChange = (e) => {
    setGroup(e.target.value);
    context.lastSearchType = 2;
    context.lastGroup = e.target.value;
    setReRender(!reRender);
  };

  const handleOrderByChange = (e) => {
    setOrderBy(e.target.value);
  };

  const handleOrderTypeChange = (e) => {
    setOrderType(e.target.value);
  };

  const handleDetails = (record) => {
    dispatch({ type: actions.IS_IS_LOADING, isLoading: true });
    let useGroups = 0;
    let singleStore = 0;
    if (context.lastSearchType == 2) useGroups = 1;
    if (context.lastSearchType == 3) singleStore = 1;
    getItemsetDetails(
      context.url,
      context.token,
      record.item1,
      record.item2,
      record.item3,
      record.item4,
      record.item5,
      formatDate(state.startDate),
      formatDate(state.endDate),
      useGroups,
      context.lastSearchType == 2 ? context.lastGroup : context.lastStoreid,
      singleStore
    )
      .then((response) => {
        dispatch({ type: actions.IS_IS_LOADING, isLoading: false });
        const j = response.data;
        if (j.error === 0) {
          ReactTooltip.rebuild();
          setDetails(j.transItems);
          setShowDetails(true);
        } else {
          setDetails([]);
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setDetails([]);
        dispatch({ type: actions.IS_IS_LOADING, isLoading: false });
        toast.error("An error occured getting the details", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const hide = () => {
    setShowDetails(false);
  };

  const buildItems = (record) => {
    return (
      <div
        style={{
          backgroundColor: "rgb(31, 30, 46)",
          color: "#fff",
          zIndex: "99999"
        }}
      >
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Item 1</th>
              <th>Item 2</th>
              <th>Item 3</th>
              <th>Item 4</th>
              <th>Item 5</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{record.desc1}</td>
              <td>{record.desc2}</td>
              <td>{record.desc3}</td>
              <td>{record.desc4}</td>
              <td>{record.desc5}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const setIntiallyFilteredItemsets = () => {
    const newArray = [];
    if (state.isSearching) {
      state.searchResults.map((record, i) => {
        if (i >= state.pagingOptions.start && i < state.pagingOptions.end) {
          newArray.push(record);
        }
      });
      dispatch({ type: actions.IS_SET_PAGED_ITEMSETS, itemsets: newArray });
      return;
    }
    state.itemsets.map((record, i) => {
      if (i >= state.pagingOptions.start && i <= state.pagingOptions.end) {
        newArray.push(record);
      }
    });

    dispatch({ type: actions.IS_SET_PAGED_ITEMSETS, itemsets: newArray });
  };

  const handlePageChange = (e) => {
    dispatch({ type: actions.IS_SET_CURRENT_PAGE, currentPage: e });

    const start = e * state.recordsPerPage;
    const end = (e + 1) * state.recordsPerPage;

    dispatch({
      type: actions.IS_SET_PAGING_OPTIONS,
      pagingOptions: { start, end }
    });

    const newArray = [];
    if (state.isSearching) {
      state.searchResults.map((record, i) => {
        if (i >= start && i <= end) {
          newArray.push(record);
        }
      });

      dispatch({ type: actions.IS_SET_PAGED_ITEMSETS, itemsets: newArray });
      return;
    }
    state.itemsets.map((record, i) => {
      if (i >= start && i < end) {
        newArray.push(record);
      }
    });

    dispatch({ type: actions.IS_SET_PAGED_ITEMSETS, itemsets: newArray });
  };

  const handleRecordsPerPageChange = (e) => {
    const start = state.currentPage * parseInt(e.target.value);
    const end = (state.currentPage + 1) * parseInt(e.target.value);

    dispatch({
      type: actions.IS_SET_PAGING_OPTIONS,
      pagingOptions: { start, end }
    });

    dispatch({
      type: actions.IS_SET_RECORDS_PER_PAGE,
      recordsPerPage: e.target.value
    });

    const newArray = [];

    //now enumerate our itemsets to build a new page with updated result count
    state.itemsets.map((record, i) => {
      if (i >= start && i < end) {
        newArray.push(record);
      }
    });

    dispatch({ type: actions.IS_SET_PAGED_ITEMSETS, itemsets: newArray });
  };

  const handleUpcSearch = () => {
    const newArray = [];
    const searchUpc = document.getElementById("searchUpc").value;
    if (searchUpc.length === 0) {
      dispatch({ type: actions.IS_SET_IS_SEARCHING, isSearching: false });
      return;
    }
    state.itemsets.map((record) => {
      if (
        record.item1 == searchUpc ||
        record.item2 == searchUpc ||
        record.item3 == searchUpc ||
        record.item4 == searchUpc ||
        record.item5 == searchUpc
      ) {
        newArray.push(record);
      }
    });

    dispatch({ type: actions.IS_SET_IS_SEARCHING, isSearching: true });
    dispatch({ type: actions.IS_SET_SEARCH_RESULTS, itemsets: newArray });
  };

  return (
    <div>
      {state.isLoading && <BoxLoader />}
      {state.isLoadingGroups && <BoxLoader />}
      {state.isLoadingStores && <BouncyLoader label={"Loading Stores..."} />}
      <ReactTooltip />
      <div id="itemset-controls">
        <Row
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <Col xs="4">
            <span style={{ marginRight: "10px" }}>Start:</span>
            <DatePicker
              selected={state.startDate}
              onChange={handleStartDateChange}
            />
          </Col>
          <Col xs="4">
            <span style={{ marginRight: "10px" }}>End:</span>
            <DatePicker
              selected={state.endDate}
              onChange={handleEndDateChange}
            />
          </Col>
          {state.itemsets.length > 0 ? (
            <Col xs="4">
              <button
                type="button"
                className="btn btn-info"
                onClick={() => handleCsv(state.itemsets, "itemsets.csv")}
              >
                Download Csv
              </button>
            </Col>
          ) : null}
        </Row>
        <Row
          style={{
            marginTop: "10px",
            display: "flex",
            justifyContent: "center"
          }}
        >
          <Col xs="3">
            <select
              className="form-control"
              id="comboSelectionType"
              value={context.lastSearchType}
              onChange={handleSelectionChange}
            >
              {pickerData.map((record) => (
                <option key={record.id} value={record.id}>
                  {record.name}
                </option>
              ))}
            </select>
          </Col>

          {context.lastSearchType == 2 ? (
            <Col xs="4">
              <Row>
                <Col xs="2">
                  <span
                    style={{
                      display: "flex",
                      marginRight: "10px",
                      marginTop: "5px"
                    }}
                  >
                    Group:
                  </span>
                </Col>
                <Col xs="10">
                  <select
                    id="comboGroup"
                    className="form-control"
                    onChange={handleGroupChange}
                    value={context.lastGroup}
                  >
                    {groups.length === 0
                      ? null
                      : groups.map((group) => (
                          <option key={group.id} value={group.id}>
                            {group.group_name}
                          </option>
                        ))}
                  </select>
                </Col>
              </Row>
            </Col>
          ) : null}

          {context.lastSearchType == 3 ? (
            <Col xs="4">
              <Row>
                <Col xs="12">
                  <TypeAheadDropDown
                    data={state.assignedStores}
                    keyValue={"storeid"}
                    displayValue={"store_Name"}
                    value={parseInt(state.currentStore)}
                    setSelection={handleStoreChange}
                    label="Store"
                  />
                </Col>
              </Row>
            </Col>
          ) : null}

          <Col xs="4">
            <Row>
              <Col xs="8">
                <input
                  id="searchUpc"
                  className="form-control"
                  type="text"
                  value={state.search}
                  placeholder="Search for UPC"
                  onChange={(e) =>
                    dispatch({
                      type: actions.IS_SET_SEARCH,
                      search: e.target.value
                    })
                  }
                />
              </Col>
              <Col xs="4">
                <Tooltip
                  message={
                    "Further filter your results by using the search feature. To clear the search just empty the Search box and press this button again"
                  }
                  position={"top"}
                >
                  <div
                    onClick={handleUpcSearch}
                    style={{ cursor: "pointer", fontSize: "2.2rem" }}
                  >
                    <i className="far fa-info-square"></i>
                  </div>
                </Tooltip>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px"
          }}
        >
          <Col xs="3">
            <label>Select a support level</label>
            <input
              type="number"
              className="form-control"
              min="0"
              max="10"
              value={support}
              onChange={handleSupportChange}
              data-tip="Support is the number of times a UPC combination appears in a transaction. Ex: Banana and Cherries appear together in 54 transactions."
            />
          </Col>
          <Col xs="3">
            <label>Order by:</label>
            <select
              className="form-control"
              value={orderBy}
              onChange={handleOrderByChange}
              data-tip="The field to order the Items by"
            >
              <option value="size">Size</option>
              <option value="freq">Frequency</option>
            </select>
          </Col>
          <Col xs="3">
            <label>Select order type:</label>
            <select
              className="form-control"
              value={orderType}
              onChange={handleOrderTypeChange}
            >
              <option value="asc">Ascending</option>
              <option value="desc">Descending</option>
            </select>
          </Col>
        </Row>
        <Row className="justify-content-center mt-2 mb-2">
          <PagingToolbar
            data={state.filteredItemsets}
            currentPage={state.currentPage}
            totalRecords={
              state.isSearching
                ? state.searchResults.length
                : state.itemsets.length
            }
            handlePageChange={handlePageChange}
            recordsPerPage={state.recordsPerPage}
            handleRecordsPerPageChange={handleRecordsPerPageChange}
            start={state.pagingOptions.start}
            end={state.pagingOptions.end}
          />
        </Row>
      </div>

      <div id="itemset-body">
        {state.filteredItemsets.length > 0 ? (
          <React.Fragment>
            <ReactTooltip />
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Details</th>
                  <th>
                    <p data-tip="The number of Upcs">Size</p>
                  </th>
                  <th>Item 1</th>
                  <th>Item 2</th>
                  <th>Item 3</th>
                  <th>Item 4</th>
                  <th>Item 5</th>
                  <th>
                    <p data-tip="The number of Transactions including these items">
                      Frequency
                    </p>
                  </th>
                </tr>
              </thead>
              <tbody>
                {state.filteredItemsets.map((record, index) => (
                  <tr key={`is-r-${index}`}>
                    <td>
                      <p data-tip="View all Transactions including these items">
                        <button
                          className="btn btn-info"
                          onClick={() => handleDetails(record)}
                        >
                          Details
                        </button>
                      </p>
                    </td>
                    <td style={{ textAlign: "center" }}>{record.size}</td>
                    <td>
                      {index < 5 ? (
                        <Tooltip
                          message={buildItems(record)}
                          position={"bottom"}
                        >
                          {record.item1}
                        </Tooltip>
                      ) : (
                        <Tooltip message={buildItems(record)} position={"top"}>
                          {record.item1}
                        </Tooltip>
                      )}
                    </td>
                    <td>
                      <Tooltip message={record.desc2} position={"top"}>
                        {record.item2}
                      </Tooltip>
                    </td>
                    <td>
                      <Tooltip message={record.desc3} position={"top"}>
                        {record.item3}
                      </Tooltip>
                    </td>
                    <td>
                      <Tooltip message={record.desc4} position={"top"}>
                        {record.item4}
                      </Tooltip>
                    </td>
                    <td>
                      <Tooltip message={record.desc5} position={"top"}>
                        {record.item5}
                      </Tooltip>
                    </td>
                    <td style={{ textAlign: "center" }}>{record.frequency}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </React.Fragment>
        ) : (
          <div className="empty-itemset">
            There are currently no items to show
          </div>
        )}
      </div>
      {showDetails && (
        <ModalItemsetDetails
          hide={hide}
          modalStyle={modalStyle}
          modalClass={"fade"}
          isShowing={showDetails}
          data={details}
        />
      )}
    </div>
  );
};

export default Itemsets;

// <ItemsetTable Styles={Styles} columns={columns} data={itemsets} />
