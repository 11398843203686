import axios from "axios";

async function readMobileSales(url, token, queryDate, useGroups, searchValue) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "mobile/sales",
    params: {
      queryDate,
      useGroups,
      searchValue
    }
  });

  return json;
}

async function readMobileSalesTwoDates(
  url,
  token,
  startDate,
  endDate,
  useGroups,
  searchValue,
  singleStore,
  sort,
  exclusions
) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "mobile/salestwodates",
    params: {
      startDate,
      endDate,
      useGroups,
      searchValue,
      singleStore,
      sort,
      exclusions
    }
  });

  return json;
}

async function readWeeklySales(
  url,
  token,
  startDate,
  endDate,
  storeid,
  exclusions
) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "sales/weekly",
    params: {
      startDate,
      endDate,
      storeid,
      exclusions
    }
  });

  return json;
}

async function readHourlySales(url, token, storeid, queryDate) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "mobile/hourly",
    params: {
      storeid,
      queryDate
    }
  });
  return json;
}

async function getHourlyPivot(url, token, storeid, startDate, endDate) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "sales/hourlypivot",
    params: {
      storeid,
      startDate,
      endDate
    }
  });
  return json;
}

async function getWeeklyHourly(url, token, storeid, startDate, endDate, hour) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "sales/hourweek",
    params: {
      storeid,
      startDate,
      endDate,
      hour
    }
  });
  return json;
}

async function getStoreDepartments(url, token, storeid) {
  let json = await axios({
    method: "GET",
    cors: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "sales/depts",
    params: {
      storeid
    }
  });
  return json;
}

export {
  readMobileSales,
  readWeeklySales,
  readHourlySales,
  getHourlyPivot,
  getWeeklyHourly,
  readMobileSalesTwoDates,
  getStoreDepartments
};
