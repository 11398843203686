import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { variables } from "../../common/variables";
import { ContextConfig } from "../../App";
import { isEqual } from "../../fp";
import { getUserClockStores } from "../../api/StoreApi";
import { usePrevious, formatDate } from "../../utils";
import { toast } from "react-toastify";
import Tools from "./Tools";
import { copyWeek, deleteSchedule, publish } from "../../api/scheduleApi";
import ConfirmDelete from "../../common/ConfirmDelete";
import ReactTooltip from "react-tooltip";

const SchedulerHeader = (props) => {
  const {
    startDate,
    endDate,
    handlePreviousWeek,
    handleNextWeek,
    store,
    setStore,
    setReloadSchedule,
    reloadSchedule,
    departments,
    handleDepartmentChange,
    handleDeptCheckChange,
    defaultDepartment,
    deptFilterType
  } = props;

  const [stores, setStores] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [confirmText, setConfirmText] = useState("");
  const [showCopy, setShowCopy] = useState(false);
  const [showPublish, setShowPublish] = useState(false);
  const [error, setError] = useState("");

  const context = useContext(ContextConfig);
  const previousStores = usePrevious(stores);

  useEffect(() => {
    if (!isEqual(previousStores, stores)) {
      loadStores();
    }
  }, [stores]);

  const handleChange = () => {};

  const loadStores = () => {
    getUserClockStores(context.clockUrl, context.clockToken)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setStores(j.stores);
        } else {
          console.log(j.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleStoreChange = (e) => {
    const storeid = e.target.value;
    context.lastClockStore = storeid;
    setStore(storeid);
  };

  const handleCheckCopy = () => {
    setShowCopy(true);
  };

  const copyScheduleWeek = () => {
    if (confirmText != "copy schedule") {
      setError(`${confirmText} does not match "copy schedule"`);
      return;
    }
    copyWeek(context.clockUrl, context.clockToken, store, startDate, endDate)
      .then((response) => {
        const j = response.data;
        setShowCopy(false);
        setConfirmText("");
        if (j.error === 0) {
          toast.success("Your Schedule has been copied", {
            position: toast.POSITION.TOP_LEFT
          });
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setShowCopy(false);
        console.log(err);
        toast.error("An Internal error has occured", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const clearScheduleWeek = () => {
    setShowDelete(true);
  };

  const hide = () => {
    setShowDelete(false);
    setShowCopy(false);
  };

  const handleConfirmText = (e) => {
    setConfirmText(e.target.value);
  };

  const compareDeleteText = () => {
    if (confirmText != "delete schedule") {
      setError(`${confirmText} does not match "delete schedule"`);
      return;
    }

    deleteSchedule(
      context.clockUrl,
      context.clockToken,
      store,
      startDate,
      endDate
    )
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setReloadSchedule(!reloadSchedule);
          setConfirmText("");
          hide();
        } else {
          setReloadSchedule(!reloadSchedule);
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An Internal error occured", {
          position: toast.POSITION.TOP_LEFT
        });
        setReloadSchedule(!reloadSchedule);
      });
  };

  const comparePublishText = () => {
    if (confirmText != "publish me") {
      setError(`$confirmText} does not equal publish me`);
      return;
    }
    publish(
      context.clockUrl,
      context.clockToken,
      formatDate(startDate),
      formatDate(endDate),
      store
    )
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          toast.success("Your Schedule has been published", {
            position: toast.POSITION.TOP_LEFT
          });
          setShowPublish(false);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An Internal error has occured", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const publishWeek = () => {
    setShowPublish(true);
  };

  return (
    <div style={variables.desktopHeader} id="schedulHeaderControls">
      {showDelete && (
        <ConfirmDelete
          isShowing={showDelete}
          hide={hide}
          confirmText={confirmText}
          setConfirmText={handleConfirmText}
          compareDeleteText={compareDeleteText}
          error={error}
          title="Confirm Delete Schedule"
          instructionsText={"delete schedule"}
        />
      )}
      {showCopy && (
        <ConfirmDelete
          isShowing={showCopy}
          hide={hide}
          confirmText={confirmText}
          setConfirmText={handleConfirmText}
          compareDeleteText={copyScheduleWeek}
          error={error}
          title="Confirm Copy Schedule"
          instructionsText={"copy schedule"}
        />
      )}
      {showPublish && (
        <ConfirmDelete
          isShowing={showPublish}
          hide={() => setShowPublish(false)}
          confirmText={confirmText}
          setConfirmText={handleConfirmText}
          compareDeleteText={comparePublishText}
          error={error}
          title="Confirm Publishing Schedule"
          instructionsText={"publish me"}
        />
      )}
      <ReactTooltip />
      <div className="row jusfify-content-center">
        <div
          className="col-1 align-items-center justify-content-center d-flex mt-1"
          style={{ color: "#fff" }}
          onClick={handlePreviousWeek}
        >
          <i
            className="far fa-backward"
            data-tip="Previous Week"
            style={{
              fontSize: "1.5rem",
              fontWeight: "bold",
              color: "#fff",
              cursor: "pointer"
            }}
          ></i>
        </div>
        <div className="col align-items-center justify-content-center d-flex mt-1">
          <span className="schedule-start-text">Start: &nbsp;</span>
          <input
            className="form-control"
            onChange={handleChange}
            type="text"
            value={formatDate(startDate)}
          />
        </div>
        <div className="col align-items-center justify-content-center d-flex mt-1">
          <span className="schedule-start-text">End: &nbsp;</span>
          <input
            className="form-control"
            onChange={handleChange}
            type="text"
            value={formatDate(endDate)}
          />
        </div>
        <div
          className="col-1 align-items-center justify-content-center d-flex mt-1"
          style={{ color: "#fff" }}
          onClick={handleNextWeek}
        >
          <i
            className="far fa-forward"
            data-tip="Next Week"
            style={{
              fontSize: "1.5rem",
              fontWeight: "bold",
              color: "#fff",
              cursor: "pointer"
            }}
          ></i>
        </div>
        <div className="col align-items-center justify-content-center d-flex mt-1 mr-2">
          <select
            className="form-control"
            onChange={handleDepartmentChange}
            value={defaultDepartment}
          >
            <option value="0">Select Department</option>
            {departments.map((department, i) => (
              <option key={`dept-${i}`} value={department.id}>
                {department.description}
              </option>
            ))}
          </select>
          <input
            type="checkbox"
            onChange={handleDeptCheckChange}
            value={deptFilterType}
            style={{ marginLeft: "5px" }}
            data-tip="If checked, filter by actual shift, otherwise filter by employee default department"
          ></input>
          <label style={{ marginLeft: "5px", marginTop: "5px" }}>Shift</label>
        </div>
        <div className="col align-items-center justify-content-center d-flex mt-1 mr-3">
          <label className="px-1" htmlFor="storeselector">
            Store:
          </label>
          <select
            className="form-control"
            value={store}
            onChange={handleStoreChange}
            style={{ width: "220px" }}
          >
            {stores.length === 0 ? (
              <option key={`s-s-0`} value="0">
                No Stores to Select
              </option>
            ) : (
              <React.Fragment>
                {/* <option key={`s-1-0`} value="0">
                  All Stores
                </option> */}
                {stores.map((record, index) => (
                  <option key={`ss-${index}`} value={record.id}>
                    {record.storeName}
                  </option>
                ))}
              </React.Fragment>
            )}
          </select>
        </div>
        <div className="col-1 align-items-center justify-content-center d-flex mt-1 mr-4">
          <Tools
            copyWeek={handleCheckCopy}
            clearWeek={clearScheduleWeek}
            publishWeek={publishWeek}
          />
        </div>
      </div>
    </div>
  );
};

SchedulerHeader.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  handlePreviousWeek: PropTypes.func,
  handleNextWeek: PropTypes.func,
  store: PropTypes.any,
  setStore: PropTypes.func,
  setReloadSchedule: PropTypes.func,
  reloadSchedule: PropTypes.bool,
  departments: PropTypes.array,
  handleDepartmentChange: PropTypes.func,
  handleDeptCheckChange: PropTypes.func,
  defaultDepartment: PropTypes.any,
  deptFilterType: PropTypes.any
};

export default SchedulerHeader;
