import { formatDate, formatTimeAmPm } from "../utils";

function padLeft(input, length, padChar) {
  if (input.length < length) {
    input = padChar + input;
  }
  if (input.length < length) {
    padLeft(input, length, padChar);
  }
  return input;
}

function roundPunch(punch, settings) {
  let totalTime = 0;
  let roundOut = "";

  var roundingMinutes = 1;
  if (settings.roundingMethod === 1) {
    roundingMinutes = 5;
  } else if (settings.roundingMethod === 2) {
    roundingMinutes = 10;
  } else if (settings.roundingMethod === 3) {
    roundingMinutes = 15;
  } else if (settings.roundingMethod === 4) {
    // no rounding at all
    punch.punchIn = punch.rawPunchIn;
    punch.punchOut = punch.rawPunchOut;
    return [
      (totalTime = punch.total),
      (roundIn = punch.rawPunchIn),
      (roundOut = punch.rawPunchOut)
    ];
  }

  let epochDate = new Date("1/1/1970");
  let timezoneOffset = epochDate.getTimezoneOffset();

  var coeff = 1000 * 60 * roundingMinutes;

  let epochIn = new Date(punch.rawPunchIn) - new Date("1/1/1970");

  let roundedIn = Math.round(epochIn / coeff) * coeff;
  let roundedInDate = new Date(roundedIn);
  roundedInDate = addMinutes(roundedInDate, timezoneOffset);

  let roundIn =
    formatDate(new Date(roundedInDate)) +
    " " +
    formatTimeAmPm(new Date(roundedInDate));

  if (punch.rawPunchOut) {
    if (settings.roundingMethod === 5) {
      let timeIn = getCustomTime(
        punch.rawPunchIn.trim(),
        settings.roundingScheme
      );
      let timeOut = getCustomTime(
        punch.rawPunchOut.trim(),
        settings.roundingScheme
      );
      if (timeIn !== null && timeOut !== null) {
        let testOutDate = new Date(punch.rawPunchOut);
        let dIn = parseFloat(timeIn);
        let dOut = parseFloat(timeOut);
        if (testOutDate.getDate() != roundedInDate.getDate()) {
          // here we are just going for if the dates are different or not
          totalTime = (dOut + 24 - dIn).toFixed(settings.timePrecision);
        } else {
          totalTime = (dOut - dIn).toFixed(settings.timePrecision);
        }
      }
    } else {
      totalTime = 0;
    }

    let endEpoch = new Date(punch.rawPunchOut) - new Date("1/1/1970");
    let roundedOut = Math.round(endEpoch / coeff) * coeff;
    let roundedOutDate = new Date(roundedOut);
    roundedOutDate = addMinutes(roundedOutDate, timezoneOffset);

    roundOut =
      formatDate(roundedOutDate) + " " + formatTimeAmPm(roundedOutDate);

    if (settings.roundingMethod !== 5) {
      let diff = roundedOutDate - roundedInDate;

      let diffHours = Math.floor((diff % 86400000) / 3600000); // hours
      let diffMins = Math.floor((diff % 86400000) / 3600000 / 60000); // hours)))

      let remainder = diffMins / 86400000;
      if (remainder >= 1) {
        let days = parseInt(remainder);
        diffHours = diffHours + 24 * days;
      }

      let hourMins = diffHours * 60;
      let totalMinutes = ((hourMins + diffMins) * 0.16667).toFixed(
        settings.timePrecision
      );
      totalTime = totalMinutes;
    }
  } else {
    totalTime = 0;
  }

  return { totalTime, roundIn, roundOut };
}

function totalPunch(punchin, punchout, settings) {
  var diffMs = punchout - punchin;
  console.log("diffsMs: " + diffMs);

  var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
  var difMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
  // now we need a quick check to see if more than 24 hours has passed as that will modulo to the same value
  var remainder = diffMs / 86400000;
  if (remainder >= 1) {
    diffHrs = diffHrs + 24 * parseInt(remainder.toString());
  }
  // there is one more gotcha that we need to check for
  // if the remainder is less than zero, then the user is trying to move the out date prior to the end date
  // which is not dimensionally possible at least in this galaxy that I know of so we throw an error
  if (remainder < 0) {
    return -1;
  }

  var hourMins = diffHrs * 60;
  var totalMinutes = (hourMins + difMins) / 60;
  return totalMinutes.toFixed(settings.timePrecision);
}

function calculateHours(minutes, settings) {
  if (typeof minutes === "undefined" || minutes === null || minutes === "")
    return 0;

  const hours = minutes / 60;
  if (typeof settings === "undefined" || settings === null) {
    return hours.toFixed(1);
  } else {
    return hours.toFixed(settings.timePrecision);
  }
}

const getCustomTime = (input, scheme) => {
  let inputDate = new Date();

  if (typeof input === "string") {
    inputDate = new Date(input);
  } else if (typeof input === "object") {
    inputDate = input.toString();
  } else {
    return input;
  }

  const minutes = padLeft(inputDate.getMinutes().toString(), 2, "0");
  const result = scheme.find((r) => r.actualPunch == minutes);
  let hour = inputDate.getHours();
  const breakPoint = scheme.find((r) => r.breakPoint == "1").actualPunch;
  if (parseInt(minutes) >= parseInt(breakPoint)) {
    hour = parseInt(hour) + 1;
  }

  if (result) {
    if (result.roundsTo) {
      return hour.toString() + "." + result.roundsTo;
    } else {
      return input;
    }
  } else {
    return input;
  }
};

const addHours = (date, hours) => {
  date.setTime(date.getTime() + hours * 60 * 60 * 1000);
  return date;
};

const addMinutes = (date, minutes) => {
  return new Date(date.getTime() + minutes * 60000);
};

export {
  padLeft,
  roundPunch,
  totalPunch,
  calculateHours,
  getCustomTime,
  addHours,
  addMinutes
};
