import axios from "axios";

async function createForm4Request(url, token, storeid) {
  let json = await axios({
    method: "POST",
    cors: true,
    url: url + "form4/schedule",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    params: {
      storeid
    }
  });
  return json;
}

async function getForm4Requests(url, token, storeid) {
  let json = await axios({
    method: "GET",
    cors: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "form4/requests",
    params: {
      storeid
    }
  });

  return json;
}

export { createForm4Request, getForm4Requests };
