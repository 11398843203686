import * as actions from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  history: [],
  stores: [],
  currentStore: ""
};

const form4Reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FORM4_LOADING:
      return {
        ...state,
        isLoading: action.isLoading
      };
    case actions.FORM4_SET_STORES:
      return {
        ...state,
        stores: action.stores
      };
    case actions.FORM4_SET_STORE:
      return {
        ...state,
        currentStore: action.store
      };
    case actions.FORM4_SET_HISTORY:
      return {
        ...state,
        history: action.history
      };
    default:
      return {
        ...state
      };
  }
};

export default form4Reducer;
