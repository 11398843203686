import React from "react";
import PropTypes from "prop-types";
import ToggleSwitch from "../../Groups/ToggleSwitch";

const RoleList = ({ roles, handleToggle }) => {
  return (
    <div className="rolelist">
      <h3>Assign Roles to User</h3>
      {roles.length === 0 ? (
        <div className="emptyroles">
          There are currently no roles to display
        </div>
      ) : (
        <table className="table table-striped role-table">
          <thead>
            <tr>
              <th>Role</th>
              <th className="role-toggle-header">Assigned</th>
            </tr>
          </thead>
          <tbody>
            {roles.map((role, index) => (
              <tr key={`m-${index}`}>
                <td className="role-description">
                  <span className="role-description-span">
                    {role.description}
                  </span>
                </td>
                <td className="role-toggle">
                  <ToggleSwitch
                    id={role.id}
                    handleClick={handleToggle}
                    active={role.active === "1" ? true : false}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

RoleList.propTypes = {
  roles: PropTypes.array,
  handleToggle: PropTypes.func
};

export default RoleList;
