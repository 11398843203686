import React, { useEffect } from "react";
import PropTypes from "prop-types";
import DateFnsUtils from "@date-io/date-fns";
import Grid from "@material-ui/core/Grid";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from "@material-ui/pickers";
import { useSelector, useDispatch } from "react-redux";

import * as actions from "../../actions/actionTypes";
import { isValid } from "date-fns";

const PunchcardCmp = (props) => {
  const state = useSelector((app) => app.punchcardReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("punch", props.punch);
  }, [props.punch]);

  const handleInTime = (e) => {
    if (!isValid(e)) return;
    if (e.toString().toLowerCase() == "invalid date") return;
    const hours = e.getHours();
    const minutes = e.getMinutes();

    const tempDate = new Date(props.punch.punchDate);
    const day = tempDate.getDate();
    const month = tempDate.getMonth() + 1;
    const year = tempDate.getFullYear();

    const temp = month + "/" + day + "/" + year + " " + hours + ":" + minutes;
    dispatch({
      type: actions.PC_UPDATE_PUNCH,
      field: "punchIn",
      value: temp
    });
  };

  const handleOutTime = (e) => {
    if (!isValid(e)) return;
    if (e.toString().toLowerCase() == "invalid date") return;
    const hours = e.getHours();
    const minutes = e.getMinutes();

    const tempDate = new Date(props.punch.punchDate);
    const day = tempDate.getDate();
    const month = tempDate.getMonth() + 1;
    const year = tempDate.getFullYear();

    const temp = month + "/" + day + "/" + year + " " + hours + ":" + minutes;
    dispatch({
      type: actions.PC_UPDATE_PUNCH,
      field: "punchOut",
      value: temp
    });
  };

  const backgroundColor =
    props.punch.total >= 0 ? "#1f1e2e" : "rgb(43, 42, 42)";

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="space-around">
        <div
          className="punch-entry"
          style={{
            backgroundColor: `${backgroundColor}`
          }}
        >
          <div className="row">
            <div className="col">
              <KeyboardDatePicker
                format="MM/dd/yyyy"
                margin="normal"
                label="Date"
                value={props.punch.punchDate}
                onChange={(e) =>
                  dispatch({
                    type: actions.PC_UPDATE_PUNCH,
                    field: "punchDate",
                    value: e
                  })
                }
                KeyboardButtonProps={{
                  "aria-label": "change date"
                }}
              />
            </div>
            <div className="col">
              <KeyboardTimePicker
                margin="normal"
                label="Time in"
                value={props.punch.punchIn}
                minutesStep={15}
                onChange={(e) => handleInTime(e)}
                KeyboardButtonProps={{
                  "aria-label": "change time"
                }}
              />
            </div>
            <div className="col">
              <KeyboardTimePicker
                margin="normal"
                label="Time Out"
                value={props.punch.punchOut}
                minutesStep={15}
                onChange={(e) => handleOutTime(e)}
                KeyboardButtonProps={{
                  "aria-label": "change time"
                }}
              />
            </div>

            {props.punch.total >= 0 ? (
              <div className="col justify-content-center d-flex">
                <button
                  className="btn btn-primary"
                  style={{ maxHeight: "40px" }}
                  onClick={() => props.handleDelete(props.punch.id)}
                >
                  Delete
                </button>
              </div>
            ) : (
              <div className="col justify-context-center d-flex">
                <button
                  className="btn btn-outline-light"
                  style={{ maxHeight: "40px" }}
                  onClick={() => props.handlePunchSubmit(props.punch.id)}
                >
                  Submit
                </button>
                <button
                  className="btn btn-primary"
                  style={{ maxHeight: "40px" }}
                  onClick={() => props.cancelEdit(props.punch.id)}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col">
              <select
                className="form-control"
                value={props.punch.departmentId}
                onChange={(e) =>
                  dispatch({
                    type: actions.PC_UPDATE_PUNCH,
                    field: "department",
                    value: e.target.value
                  })
                }
              >
                <option value="0">Select a Department</option>
                {state.departments.map((d, i) => (
                  <option key={`pd-${i}`} value={d.id}>
                    {d.description}
                  </option>
                ))}
              </select>
            </div>
            <div className="col">
              <input
                type="text"
                className="form-control"
                value={props.punch.comment}
                onChange={(e) =>
                  dispatch({
                    type: actions.PC_UPDATE_PUNCH,
                    field: "comment",
                    value: e.target.value
                  })
                }
              />
            </div>
            <div className="col">
              <span className="punch-entry-total">{props.punch.total}</span>
            </div>
          </div>
        </div>
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

PunchcardCmp.propTypes = {
  handlePunchSubmit: PropTypes.func,
  punch: PropTypes.object,
  handleDelete: PropTypes.func,
  cancelEdit: PropTypes.func
};

export default PunchcardCmp;
