import React, { useContext } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { NavLink } from "react-router-dom";
import "./Header.css";
import { NavDropdown } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { ContextConfig } from "../App";
// import { readAceRewards } from "../api/ReportsApi";

const HeaderRB = () => {
  const activeStyle = { color: "#F15B2A" };

  const context = useContext(ContextConfig);

  return (
    <React.Fragment>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="dark"
        variant="dark"
        className="sticky-top"
        id="master-navbar"
      >
        {context.security == "1" ? (
          <Navbar.Brand href="/" className="logo-offset">
            <img
              src="img/BarryLogo.svg"
              alt="MikTo"
              style={{ height: "70px" }}
            />
          </Navbar.Brand>
        ) : (
          <Navbar.Brand href="/" className="logo-offset">
            <img src="img/homepage.svg" alt="MikTo" />
            MikTo
          </Navbar.Brand>
        )}

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto">
            <NavLink to="/home" className="menu-item" activeStyle={activeStyle}>
              Home
            </NavLink>
            {context.userLevel >= 9 ? (
              <NavLink
                to="/clock"
                className="menu-item"
                activeStyle={activeStyle}
              >
                Clock
              </NavLink>
            ) : null}
            {context.userLevel == 1 ? (
              <React.Fragment>
                <NavLink
                  to="/reports"
                  className="menu-item"
                  activeStyle={activeStyle}
                >
                  Reports
                </NavLink>
                <NavDropdown
                  title="Payserver"
                  id="navutils1"
                  className="menu-dd"
                >
                  <NavLink to="/ps" className="menu-item menu-item-sub">
                    Customer Points
                  </NavLink>
                  {!isMobile ? (
                    <NavLink to="/ledger" className="menu-item menu-item-sub">
                      Expiration Ledger
                    </NavLink>
                  ) : null}
                  {!isMobile ? (
                    <NavLink
                      to="/panalysis"
                      className="menu-item menu-item-sub"
                    >
                      Analysis
                    </NavLink>
                  ) : null}
                  {!isMobile && context.userLevel > 7 ? (
                    <NavLink to="/assign" className="menu-item menu-item-sub">
                      Assign
                    </NavLink>
                  ) : null}
                </NavDropdown>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <NavLink
                  to="/security"
                  className="menu-item"
                  activeStyle={activeStyle}
                >
                  Security
                </NavLink>
                <NavLink
                  to="/groups"
                  className="menu-item"
                  activeStyle={activeStyle}
                >
                  Groups
                </NavLink>
                <NavLink
                  to="/sales"
                  className="menu-item"
                  activeStyle={activeStyle}
                >
                  Sales
                </NavLink>
                <NavLink
                  to="/hourly"
                  className="menu-item"
                  activeStyle={activeStyle}
                >
                  Hourly
                </NavLink>

                <NavLink
                  to="/cashiers"
                  className="menu-item"
                  activeStyle={activeStyle}
                >
                  Cashiers
                </NavLink>
                {!isMobile ? (
                  <NavLink
                    to="/time"
                    className="menu-item"
                    activeStyle={activeStyle}
                  >
                    Time
                  </NavLink>
                ) : null}

                {!isMobile ? (
                  <NavLink
                    to="/predict"
                    className="menu-item"
                    activeStyle={activeStyle}
                  >
                    Analysis
                  </NavLink>
                ) : null}

                {!isMobile ? (
                  <NavLink
                    to="/settings"
                    className="menu-item"
                    activeStyle={activeStyle}
                  >
                    Settings
                  </NavLink>
                ) : null}

                <NavLink
                  to="/reports"
                  className="menu-item"
                  activeStyle={activeStyle}
                >
                  Reports
                </NavLink>
                <NavDropdown
                  title="Payserver"
                  id="navutils1"
                  className="menu-dd"
                >
                  <NavLink to="/ps" className="menu-item menu-item-sub">
                    Customer Points
                  </NavLink>
                  {!isMobile ? (
                    <NavLink to="/ledger" className="menu-item menu-item-sub">
                      Expiration Ledger
                    </NavLink>
                  ) : null}
                  {!isMobile ? (
                    <NavLink
                      to="/panalysis"
                      className="menu-item menu-item-sub"
                    >
                      Analysis
                    </NavLink>
                  ) : null}
                  {!isMobile && context.userLevel > 7 ? (
                    <NavLink to="/assign" className="menu-item menu-item-sub">
                      Assign
                    </NavLink>
                  ) : null}
                </NavDropdown>

                {!isMobile && context.userLevel > 7 ? (
                  <NavDropdown
                    title="Freshop"
                    id="navutils1"
                    className="menu-dd"
                  >
                    <NavLink to="/freshop" className="menu-item menu-item-sub">
                      Api
                    </NavLink>
                  </NavDropdown>
                ) : null}

                {!isMobile ? (
                  <NavDropdown
                    title="Widgets"
                    id="navutils2"
                    className="menu-dd"
                  >
                    <NavLink
                      to="/exceptions"
                      className="menu-item menu-item-sub"
                    >
                      Exceptions
                    </NavLink>
                    <NavLink to="/charges" className="menu-item menu-item-sub">
                      Charge Accounts
                    </NavLink>
                    <NavLink to="/form4" className="menu-item menu-item-sub">
                      Form4
                    </NavLink>
                    <NavLink
                      to="/querybuilder"
                      className="menu-item menu-item-sub"
                    >
                      Query Builder
                    </NavLink>
                    <NavLink
                      to="/customreports"
                      className="menu-item menu-item-sub"
                    >
                      Custom Reports
                    </NavLink>
                  </NavDropdown>
                ) : null}

                <NavDropdown title="Utils" id="navutils3" className="menu-dd">
                  <NavLink to="/reset" className="menu-item menu-item-sub">
                    Reset Password
                  </NavLink>
                  <NavLink to="/addcashier" className="menu-item menu-item-sub">
                    Add Cashier
                  </NavLink>
                  <NavLink
                    to="/cashierhistory"
                    className="menu-item menu-item-sub"
                  >
                    Cashier History
                  </NavLink>
                  <NavLink to="/storename" className="menu-item menu-item-sub">
                    Storename
                  </NavLink>
                </NavDropdown>
                {/* <NavLink
                  to="/login?logout=1"
                  exact
                  className="menu-item"
                  activeStyle={activeStyle}
                >
                  Logout
                </NavLink> */}
              </React.Fragment>
            )}
            <NavLink
              to="/login?logout=1"
              exact
              className="menu-item"
              activeStyle={activeStyle}
            >
              Logout
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </React.Fragment>
  );
};

export default HeaderRB;
