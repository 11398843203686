import axios from "axios";

async function getSecurityExceptions(
  url,
  token,
  storeid,
  searchtype,
  startdate,
  enddate
) {
  let json = await axios({
    method: "GET",
    cors: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "security/securityexceptions",
    params: {
      storeid,
      searchtype,
      startdate,
      enddate
    }
  });

  return json;
}

async function getTicker(
  url,
  token,
  storeid,
  searchtype,
  startdate,
  enddate,
  totalizer
) {
  let json = await axios({
    method: "GET",
    cors: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "security/ticker",
    params: {
      storeid,
      searchtype,
      startdate,
      enddate,
      totalizer
    }
  });

  return json;
}

export { getTicker, getSecurityExceptions };
