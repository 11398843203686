import React from "react";
import "./ToggleSwitch.css";
import PropTypes from "prop-types";

const ToggleSwitch = ({ id, handleClick, active }) => {
  const onClick = (e) => {
    handleClick(id, e.target.checked);
  };

  return (
    <div className="toggle-switch">
      <input
        type="checkbox"
        className="toggle-switch-checkbox"
        name={id}
        id={id}
        onChange={onClick}
        checked={active}
      />
      <label className="toggle-switch-label" htmlFor={id}>
        <span className="toggle-switch-inner" />
        <span className="toggle-switch-switch" />
      </label>
    </div>
  );
};

ToggleSwitch.propTypes = {
  id: PropTypes.any.isRequired,
  handleClick: PropTypes.func,
  active: PropTypes.bool
};

export default ToggleSwitch;
