import React, { useState, useEffect, useContext, useRef } from "react";
import PropTypes from "prop-types";
import { formatDate, usePrevious, isValid } from "../utils";
import { Picker, ModalDatePicker } from "c5-react-library";
import { getAssignedStoresAsync } from "../api/StoreApi";
import { ContextConfig } from "../App";
import { isEqual } from "lodash";
import { toast } from "react-toastify";
import "./MobileReportController.css";

const MobileReportController = ({ rerender, setRerender }) => {
  const [isShowing, setIsShowing] = useState(false);
  const [store, setStore] = useState({});
  const [time, setTime] = useState(new Date());
  const [showDate, setShowDate] = useState(false);
  const [stores, setStores] = useState([]);

  const context = useContext(ContextConfig);

  const previousStore = usePrevious(store);

  useEffect(() => {
    if (!isValid(context.endDate)) {
      setTime(new Date());
      context.endDate = new Date();
    } else {
      setTime(context.endDate);
    }

    if (!isEqual(previousStore, store)) {
      loadStores();
    }
  }, []);

  const handleToggleStores = () => {
    setIsShowing(!isShowing);
  };

  const loadStores = () => {
    getAssignedStoresAsync(context.url, context.token)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setStores(
            j.items.map((s) => {
              return { ...s, store_Name: s.store_Name.substring(0, 20) };
            })
          );
          if (!isValid(context.store)) {
            setTimeout(() => {
              setIsShowing(true);
              return;
            }, 250);
          }
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log("stores error", err);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const handleStoreSelect = (e) => {
    context.lastStoreid = e.storeid;
    context.lastSearchType = 1;
    context.store = e;
    if (
      typeof context.reportStore === "undefined" ||
      context.reportStore === null
    ) {
      context.reportStore = {};
    }
    context.reportStore.name = e.store_Name;
    setIsShowing(false);
    setStore(e);
    setRerender(!rerender);
  };

  const handleShowDate = () => {
    setShowDate(true);
  };

  const handleDateSelect = (e) => {
    context.endDate = e;
    setShowDate(false);
    setTime(e);
    setRerender(!rerender);
  };

  const handleDateCancel = () => {
    setShowDate(false);
  };

  return (
    <div className="rpt-ctrl-wrapper">
      <div id="mr-controls">
        <h3>Reports</h3>
        <div className="p-2 rpt-ctrl">
          <div className="rpt-header-selection-btn">
            <button
              type="button"
              className="btn btn-outline-dark"
              onClick={handleToggleStores}
            >
              <i className="far fa-users"></i>
            </button>
          </div>
          <div>
            <div className="rpt-hdr-m-header">
              <div className="rpt-hdr-m-header-store">
                {context.store && context.store.store_Name}
              </div>
              <div className="rpt-hourly-m-header-date">
                {time && formatDate(time)}
              </div>
            </div>
          </div>
          <div className="rpt-header-date-btn">
            <button
              className="btn btn-outline-dark"
              type="button"
              onClick={handleShowDate}
            >
              <i className="far fa-calendar-alt"></i>
            </button>
          </div>
        </div>
      </div>

      <ModalDatePicker
        className="bottom-picker"
        value={time}
        isOpen={showDate}
        onSelect={handleDateSelect}
        onCancel={handleDateCancel}
      />
      <Picker
        className="bottom-picker"
        isShowing={isShowing}
        hide={handleToggleStores}
        header="stores"
        store={stores}
        displayField="store_Name"
        valueField="storeid"
        handleSelect={handleStoreSelect}
        useEffect={useEffect}
        useState={useState}
        useRef={useRef}
        debug={false}
        debugListeners={false}
      />
    </div>
  );
};

MobileReportController.propTypes = {
  setRerender: PropTypes.func,
  rerender: PropTypes.bool
};

export default MobileReportController;
