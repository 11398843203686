import React from "react";
import PropTypes from "prop-types";

const Th = (props) => {
  return (
    <div className={`sudo-cell schedule-header-text ${props.className}`}>
      {props.children}
    </div>
  );
};

Th.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any
};

export default Th;
