import React, { useState, useEffect, useCallback } from "react";
import { debounceFn } from "../../fp";
import { checkEmail, checkAnswer, reset } from "./Login-I";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import PasswordStrengthMeter from "./PasswordStrengthMeter";
import Spinner from "../../common/Spinner";
import { encrypt } from "../../utils";
import { useDispatch } from "react-redux";

import * as actions from "../../actions/actionTypes";

const Forgot = ({
  context,
  changePasswordRef,
  confirmPasswordRef,
  setInputName,
  emailRef,
  answerRef,
  email,
  setEmail,
  answer,
  setAnswer,
  newPassword,
  setNewPassword,
  confirmPassword,
  setConfirmPassword
}) => {
  const [message, setMessage] = useState("");
  const [question, setQuestion] = useState("");

  const [userid, setUserid] = useState("");
  const [showPasswordReset, setShowPasswordReset] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  //

  const dispatch = useDispatch();

  const debouncedCheckEmail = useCallback(debounceFn(validateEmail, 500), []);

  useEffect(() => {
    if (email.length > 0) {
      debouncedCheckEmail(email);
    }
  }, [email, message]);

  function validateEmail(email) {
    checkEmail(email, context)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setQuestion(j.question);
          setUserid(j.userid);
          setMessage("");
        } else {
          setMessage(j.msg);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An error occured validating your email address", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleAnswerChange = (e) => {
    setAnswer(e.target.value);
  };

  const handleCheckAnswer = () => {
    checkAnswer(context, userid, answer)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          // we have success
          setQuestion(false);
          setShowPasswordReset(true);
        } else {
          toast.warning(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An error occured validating your answer", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const resetPassword = (password) => {
    debugger;
    setIsLoading(true);
    const encryptedPassword = encrypt(password, context.key).toString();
    reset(context, userid, encryptedPassword)
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          context.resetPassword = "0";
          setShowPasswordReset(false);
          dispatch({ type: actions.LOGIN_SHOW_FORGOT, forgot: false });
          toast.success("Your password has been reset", {
            position: toast.POSITION.TOP_LEFT
          });
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  return (
    <div className="forgotWrapper keyboardfade-in">
      {isLoading && <Spinner />}
      <div className="row justify-content-center">
        <div className="col align-items-center mb-3">
          <input
            type="text"
            className="form-control"
            value={email}
            onChange={(e) => handleEmailChange(e)}
            placeholder="Enter Email"
            ref={emailRef}
            onFocus={() => setInputName("email")}
          />
        </div>
      </div>
      <div className="row w-100 justify-content-center">
        <div className="col email-message" style={{ color: "red" }}>
          {message}
        </div>
      </div>
      {question.length > 0 ? (
        <div className="keyboardfade-in">
          <div className="row mt-3 justify-content-center">
            <div className="col align-items-center d-flex justify-content-center">
              {question}
            </div>
          </div>
          <div className="row mt-3 justify-content-center">
            <div className="col align-items-center d-flex justify-content-center">
              <input
                type="text"
                placeholder="Enter Answer"
                className="form-control"
                value={answer}
                onChange={handleAnswerChange}
                ref={answerRef}
                onFocus={() => setInputName("answer")}
              />
            </div>
          </div>
          <div
            className="row mt-3 justify-content-center"
            style={{ textAlign: "center" }}
          >
            <div className="col">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleCheckAnswer}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      ) : null}
      {showPasswordReset ? (
        <div className="keyboardfade-in">
          <PasswordStrengthMeter
            userid={userid}
            confirmPasswordRef={confirmPasswordRef}
            changePasswordRef={changePasswordRef}
            setInputName={setInputName}
            newPassword={newPassword}
            setNewPassword={setNewPassword}
            confirmPassword={confirmPassword}
            setConfirmPassword={setConfirmPassword}
            confirmPasswordChange={resetPassword}
          />
        </div>
      ) : null}
    </div>
  );
};

Forgot.propTypes = {
  context: PropTypes.object,
  email: PropTypes.string,
  setEmail: PropTypes.func,
  setInputName: PropTypes.func,
  emailRef: PropTypes.any,
  answer: PropTypes.string,
  setAnswer: PropTypes.func,
  answerRef: PropTypes.any,
  changePasswordRef: PropTypes.any,
  confirmPasswordRef: PropTypes.any,
  newPassword: PropTypes.string,
  setNewPassword: PropTypes.func,
  confirmPassword: PropTypes.string,
  setConfirmPassword: PropTypes.func
};

export default Forgot;
