import axios from "axios";
import qs from "qs";

function readGroups(url, token, success, failure) {
  axios({
    method: "GET",
    url: url + "users/usergroups",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    }
  })
    .then((response) => {
      if (success !== null) {
        success(response);
      }
    })
    .catch((err) => {
      if (failure !== null) {
        failure(err);
      }
    });
}

async function readGroupAsync(url, token) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "users/usergroups"
  });
  return json;
}

function createGroup(config, success, failure) {
  const { url, token, groupname } = config;
  axios({
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: "Bearer " + token
    },
    url: url + "groups",
    data: qs.stringify({
      group: groupname
    })
  }).then(
    (response) => {
      if (success !== null) success(response);
    },
    (err) => {
      if (failure !== null) {
        failure(err);
      }
    }
  );
}

function readStores(config, success, failure) {
  const { url, token, group } = config;
  axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "groups/readstores",
    params: {
      group: group
    }
  })
    .then((response) => {
      if (success !== null) {
        success(response);
      }
    })
    .catch((err) => {
      if (failure !== null) {
        failure(err);
      }
    });
}

function readLinkedStores(config, success, failure) {
  const { url, token, group } = config;
  axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "groups/linked",
    params: {
      group: group
    }
  })
    .then((response) => {
      if (success !== null) {
        success(response);
      }
    })
    .catch((err) => {
      if (failure !== null) {
        failure(err);
      }
    });
}

function manageLinks(config, success, failure) {
  const { method, url, token, group, storeid } = config;
  axios({
    method: method,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: "Bearer " + token
    },
    url: url,
    data: qs.stringify({
      group: group,
      storeid: storeid
    })
  })
    .then((response) => {
      if (success !== null) {
        success(response);
      }
    })
    .catch((err) => {
      if (failure !== null) {
        failure(err);
      }
    });
}

function deleteGroup(config, success, failure) {
  const { url, token, id } = config;
  axios({
    method: "DELETE",
    headers: {
      "Content-Type": "application.json",
      Authorization: "Bearer " + token
    },
    url: url + "groups",
    params: {
      id: id
    }
  })
    .then((response) => {
      if (success !== null) {
        success(response);
      }
    })
    .catch((err) => {
      if (failure !== null) {
        failure(err);
      }
    });
}

export {
  readGroups,
  createGroup,
  readStores,
  manageLinks,
  deleteGroup,
  readLinkedStores,
  readGroupAsync
};
