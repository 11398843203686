import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  Col,
  CardHeader,
  CardTitle,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { Line } from "react-chartjs-2";
import { chartDefaults } from "../options/Charts";

const Refunds = (props) => {
  let chartExample2 = {
    data: (canvas) => {
      let ctx = canvas.getContext("2d");

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
      gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
      gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

      return {
        labels: props.refundLabels,
        datasets: [
          {
            label: "Data",
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: "#1f8ef1",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#1f8ef1",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#1f8ef1",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: props.refunds
          }
        ]
      };
    },
    options: chartDefaults
  };
  return (
    <Col lg="4">
      <Card className="card-chart">
        <CardHeader>
          <h5 className="card-category">All assigned stores</h5>
          <CardTitle tag="h3">
            <i className="fad fa-store-slash text-info" />
            Total {props.tlz}
          </CardTitle>
          <UncontrolledDropdown>
            <DropdownToggle
              caret
              className="btn-icon"
              color="link"
              data-toggle="dropdown"
              type="button"
            >
              <i className="fal fa-cog"></i>
              <i className="tim-icons fal-fa-cog" />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem
                href="#pablo"
                onClick={() => props.loadRefunds("3011")}
                className={
                  props.tlz == "Refund"
                    ? "dropdown-active tlz-dropdown"
                    : "dropdown-inactive tlz-dropdown"
                }
              >
                Refunds
              </DropdownItem>
              <DropdownItem
                href="#pablo"
                onClick={() => props.loadRefunds("3002")}
                className={
                  props.tlz == "Void"
                    ? "dropdown-active tlz-dropdown"
                    : "dropdown-inactive tlz-dropdown"
                }
              >
                Voids
              </DropdownItem>
              <DropdownItem
                href="#pablo"
                onClick={() => props.loadRefunds("3001")}
                className={
                  props.tlz == "Error Correct"
                    ? "dropdown-active tlz-dropdown"
                    : "dropdown-inactive tlz-dropdown"
                }
              >
                Error Correct
              </DropdownItem>
              <DropdownItem
                href="#pablo"
                onClick={() => props.loadRefunds("3003")}
                className={
                  props.tlz == "Cancel"
                    ? "dropdown-active tlz-dropdown"
                    : "dropdown-inactive tlz-dropdown"
                }
              >
                Cancels
              </DropdownItem>
              <DropdownItem
                href="#pablo"
                onClick={() => props.loadRefunds("3004")}
                className={
                  props.tlz == "Correction"
                    ? "dropdown-active tlz-dropdown"
                    : "dropdown-inactive tlz-dropdown"
                }
              >
                Corrections
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </CardHeader>
        <CardBody>
          <div className="chart-area">
            <Line data={chartExample2.data} options={chartExample2.options} />
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

Refunds.propTypes = {
  tlz: PropTypes.string,
  refundLabels: PropTypes.array,
  refunds: PropTypes.array,
  loadRefunds: PropTypes.func
};

export default Refunds;
