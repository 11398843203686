import axios from "axios";

async function readSubMargins(
  url,
  token,
  storeid,
  queryDate,
  orderby,
  direction,
  filterMin,
  filterMax
) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "margins/subs",
    params: {
      storeid,
      queryDate,
      orderby,
      direction,
      filterMin,
      filterMax
    }
  });
  return json;
}

async function readCatMargins(
  url,
  token,
  storeid,
  queryDate,
  orderby,
  direction,
  filterMin,
  filterMax
) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "margins/cat",
    params: {
      storeid,
      queryDate,
      orderby,
      direction,
      filterMin,
      filterMax
    }
  });
  return json;
}

async function readSubItemMargins(
  url,
  token,
  storeid,
  queryDate,
  sub,
  orderby,
  direction,
  filterMin,
  filterMax
) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "margins/subitems",
    params: {
      storeid,
      queryDate,
      sub,
      orderby,
      direction,
      filterMin,
      filterMax
    }
  });
  return json;
}

async function readCatItemMargins(
  url,
  token,
  storeid,
  queryDate,
  cat,
  orderby,
  direction,
  filterMin,
  filterMax
) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "margins/catitems",
    params: {
      storeid,
      queryDate,
      cat,
      orderby,
      direction,
      filterMin,
      filterMax
    }
  });
  return json;
}

export {
  readSubMargins,
  readCatMargins,
  readSubItemMargins,
  readCatItemMargins
};
