import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Gravator from "react-gravatar";
import ClockButtonsMobile from "./ClockButtonsMobile";

const ClockInMobile = ({
  email,
  status,
  links,
  showLinkOptions,
  handleLinkSubmit,
  handleLinkChange,
  currentStore,
  generalSettings,
  clockIn,
  handleCommentChange,
  comment,
  startBreak,
  clockOut,
  startLunch,
  endBreak,
  endLunch,
  employeeid,
  username,
  departments,
  handleDepartmentChange,
  currentDepartment,
  storename
}) => {
  useEffect(() => {}, [generalSettings, status]);

  return (
    <div>
      <h3 className="m-clockin-title"></h3>
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="m-outer-gravatar">
              <Gravator
                email={email}
                size={100}
                rating="pg"
                className="m-clockin-gravatar"
              />
            </div>
            <div className="m-employee-name">{username}</div>
            <div className="m-employeeid">
              <label>EmployeeId:&nbsp;</label>
              <span id="employeeid">{employeeid}</span>
            </div>
            {parseInt(status) > 0 ? (
              <div style={{ color: "#fff" }}>Store: {storename}</div>
            ) : null}
          </div>
          <ClockButtonsMobile
            status={status}
            generalSettings={generalSettings}
            clockIn={clockIn}
            clockOut={clockOut}
            startBreak={startBreak}
            startLunch={startLunch}
            endBreak={endBreak}
            endLunch={endLunch}
          />
        </div>
        {!showLinkOptions ? null : (
          <div className="m-clockin-select-store-list">
            <form onSubmit={handleLinkSubmit}>
              <div className="cc-form-item">
                <label>Select a Store to Clock Into</label>
                <select
                  className="form-control"
                  onChange={handleLinkChange}
                  value={currentStore}
                >
                  <option value="0">Select a Store to Clock into</option>
                  {links.map((link, index) => (
                    <option key={`l-${index}`} value={link.storeid}>
                      {link.storeName}
                    </option>
                  ))}
                </select>
              </div>
              <button
                className="btn btn-outline-dark mt-2"
                onClick={handleLinkSubmit}
              >
                Submit
              </button>
            </form>
          </div>
        )}
        {departments.length > 0 && status == "0" ? (
          <select
            className="form-control"
            value={currentDepartment}
            onChange={handleDepartmentChange}
          >
            <option value="0">Select a department</option>
            {departments.map((record, index) => (
              <option key={`ds-${index}`} value={record.id}>
                {record.description}
              </option>
            ))}
          </select>
        ) : null}
        <div className="m-clockin-comment">
          <label htmlFor="comment">Comment:</label>
          <input
            className="form-control"
            type="textarea"
            name="comment"
            rows="10"
            onChange={handleCommentChange}
            value={comment}
          />
        </div>
      </div>
    </div>
  );
};

ClockInMobile.propTypes = {
  email: PropTypes.string,
  status: PropTypes.any,
  links: PropTypes.array,
  showLinkOptions: PropTypes.bool,
  handleLinkSubmit: PropTypes.func,
  handleLinkChange: PropTypes.func,
  currentStore: PropTypes.any,
  generalSettings: PropTypes.object,
  clockIn: PropTypes.func,
  handleCommentChange: PropTypes.func,
  comment: PropTypes.string,
  startBreak: PropTypes.func,
  clockOut: PropTypes.func,
  startLunch: PropTypes.func,
  endBreak: PropTypes.func,
  endLunch: PropTypes.func,
  employeeid: PropTypes.string,
  username: PropTypes.string,
  departments: PropTypes.array,
  handleDepartmentChange: PropTypes.func,
  currentDepartment: PropTypes.any,
  storename: PropTypes.string
};

export default ClockInMobile;
