import React, { useState, useEffect, useContext } from "react";
import LoadingOverlay from "react-loading-overlay";
import HeaderRB from "../common/HeaderRb";
import {
  getAssignedStoresAsync,
  getStoreDeptsAsync,
  createCashierRequest
} from "../api/StoreApi";
import { ContextConfig } from "../App";
import { toast } from "react-toastify";
import { usePrevious, isValid } from "../utils";
import { isEqual } from "lodash";
import { BrowserView, MobileView } from "react-device-detect";
import DesktopCashierForm from "./DesktopCashierForm";
import MobileCashierForm from "./MobileCashierForm";
import "./addCashier.css";

const initialEmployee = {
  storeid: null,
  firstname: "",
  lastname: "",
  shortname: "",
  level: 0,
  department: 0,
  department_Description: "",
  empid: "",
  expedite: false,
  finished: 1,
  add: 1
};

const AddCashier = () => {
  const [stores, setStores] = useState([]);
  const [store, setStore] = useState(0); // eslint-disable-line
  const [departments, setDepartments] = useState([]);
  const [isLoading, setIsLoading] = useState(false); //eslint-disable-line
  const [errors, setErrors] = useState({});
  const [employee, setEmployee] = useState(initialEmployee);
  const context = useContext(ContextConfig);
  const previousStores = usePrevious(stores);
  const previousDepartments = usePrevious(departments);

  useEffect(() => {
    if (!isEqual(previousStores, stores)) {
      loadStores();
    }

    if (!isEqual(previousDepartments, departments)) {
      if (store !== 0) {
        loadDepartments(store);
      }
    }
  }, [stores]);

  function formIsValid() {
    const _errors = {};
    if (!employee.storeid) _errors.storeid = "Store is required";
    if (!employee.firstname) _errors.firstname = "First Name is required";
    if (employee.level === 0) _errors.level = "User Level is required";
    if (employee.department === 0)
      _errors.department = "Department is required";
    if (!employee.empid) _errors.empid = "Employee Id is required";

    setErrors(_errors);
    // for is valid is errors object has no properties
    return Object.keys(_errors).length === 0;
  }

  const handleChange = ({ target }) => {
    if (target.name === "expedite") {
      setEmployee({
        ...employee,
        expedite: target.checked
      });
    } else if (target.name === "department") {
      setEmployee({
        ...employee,
        department: target.value
      });
    } else {
      setEmployee({
        ...employee,
        [target.name]: target.value
      });
    }

    if (target.name === "storeid") {
      loadDepartments(target.value);
    }
  };

  const handleMobileError = (e) => {
    toast.error(e, {
      position: toast.POSITION.TOP_LEFT
    });
  };

  const loadStores = () => {
    getAssignedStoresAsync(context.url, context.token)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setStores(j.items);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An error occured processing your storelist request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const loadDepartments = (storeid) => {
    getStoreDeptsAsync(context.url, context.token, storeid).then((response) => {
      const j = response.data;
      if (j.error === 0) {
        setDepartments(j.items);
      }
    });
  };

  const handleSubmitNewUser = (e) => {
    e.preventDefault();
    if (!formIsValid()) return;

    setIsLoading(true);
    const description = departments.filter((d) => {
      return d.department === employee.department;
    });
    let descrip = "";
    try {
      if (isValid(description) && description.length > 0) {
        descrip = description[0].department_Description;
      }
    } catch (e) {
      console.log(e);
    }

    const exp = employee.expedite ? 1 : 0;

    createCashierRequest(context, {
      ...employee,
      department_Description: descrip,
      expedite: exp
    })
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          toast.success("You employee will be deployed soon", {
            position: toast.POSITION.TOP_LEFT
          });
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  return (
    <LoadingOverlay active={isLoading} spinner text="Loading Data...">
      <HeaderRB />
      <div className="container-fluid">
        <BrowserView>
          <div className="desktop-cashier-form mt-2">
            <DesktopCashierForm
              employee={employee}
              onChange={handleChange}
              stores={stores}
              onSubmit={handleSubmitNewUser}
              departments={departments}
              title="Add Cashier"
              errors={errors}
            />
          </div>
        </BrowserView>
        <MobileView>
          <MobileCashierForm
            employee={employee}
            onChange={handleChange}
            stores={stores}
            onSubmit={handleSubmitNewUser}
            departments={departments}
            handleMobileError={handleMobileError}
            title="Add Cashier"
            errors={errors}
          />
        </MobileView>
      </div>
    </LoadingOverlay>
  );
};

export default AddCashier;
