import React, { useState, useEffect, useContext } from "react";
import ClockSidebar from "../ClockSidebar";
//import colors from "../../common/colors";
import { usePrevious, isValid, formatDate, formatTimeAmPm } from "../../utils";
import { isEqual } from "../../fp";
import { ContextConfig } from "../../App";
import { toast } from "react-toastify";
import { readCompanies } from "../../api/companyApi";
import { BrowserView, MobileView } from "react-device-detect";
import ClockDesktopMenu from "../ClockDesktopMenu";
import FloatingSidebar from "../../Dashboard/FloatingSidebar";
import RoleControls from "../Roles/RoleControls";
import {
  statusCheck,
  getClockUsers,
  getClockCompanyUsers,
  changeStatus
} from "../../api/UsersApi";

import { getUserClockStores, setLastStoreid } from "../../api/StoreApi";
import "./Status.css";

const Status = () => {
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState("");
  const [currentCompany, setCurrentCompany] = useState("1");
  const [companies, setCompanies] = useState([]);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [result, setResult] = useState("");
  const [stores, setStores] = useState([]);

  const context = useContext(ContextConfig);

  const previousUsers = usePrevious(users);
  const previousUser = usePrevious(user);
  const previousCompanies = usePrevious(companies);
  const previousCompany = usePrevious(currentCompany);

  useEffect(() => {
    if (!isEqual(previousUsers, users)) {
      loadUsers();
    }
    if (!isEqual(previousUser, user)) {
      loadStatus();
    }
    if (!isEqual(previousCompanies, companies)) {
      loadCompanies();
    }
    if (!isEqual(previousCompany, currentCompany)) {
      loadUsers();
    }
    getClockStores();
  }, [user, companies, currentCompany]);

  const loadStatus = () => {
    if (!isValid(user)) return;

    statusCheck(context.clockUrl, context.clockToken, user)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          switch (j.status) {
            case 0:
              setStatus("Clocked Out");
              break;
            case 1:
              setStatus("Clocked In");
              break;
            case 2:
              setStatus("On Break");
              break;
            case 3:
              setStatus("At Lunch");
              break;
            default:
              setStatus("Unknown");
              break;
          }
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const loadUsers = () => {
    // we need to swap out the function based on if they base company is dcr or not
    let userLookupFunction = getClockUsers;
    if (context.company === 1) {
      userLookupFunction = getClockCompanyUsers;
    }
    userLookupFunction(
      context.clockUrl,
      context.clockToken,
      search,
      0,
      currentCompany,
      context.lastClockStore
    )
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setUsers(j.users);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An error has occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const loadCompanies = () => {
    readCompanies(context.clockUrl, context.clockToken).then((response) => {
      const j = response.data;
      if (j.error === 0) {
        setCompanies(j.companies);
      } else {
        toast.error(j.msg, {
          position: toast.POSITION.TOP_LEFT
        });
      }
    });
  };

  const handleCompanyChange = (e) => {
    setCurrentCompany(e.target.value);
    setUser("0");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    loadStatus();
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSearchClick = () => {
    loadUsers();
  };

  const handleUserChange = (e) => {
    setUser(e.target.value);
  };

  const handleStatusChange = (e) => {
    const status = e.target.value;
    changeStatus(context.clockUrl, context.clockToken, user, status)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          const resultDate = new Date();
          setResult(
            "User status has been changed: " +
              formatDate(resultDate) +
              " " +
              formatTimeAmPm(resultDate)
          );
          loadStatus();
        } else {
          setResult("");
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setResult("");
        console.log(err);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const getClockStores = () => {
    getUserClockStores(context.clockUrl, context.clockToken)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setStores(j.stores);
        } else {
          setStores([]);
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setStores([]);
        console.log(err);
        toast.error("An Internal error occured");
      });
  };

  const handleStoreChange = (e) => {
    const storeid = e.target.value;
    context.lastClockStore = parseInt(storeid);
    loadUsers();
    setLastStoreid(context.clockUrl, context.clockToken, storeid)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          console.log(storeid + " saved as last storeid");
        } else {
          console.log(j.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <React.Fragment>
      <MobileView>
        <div className="clock-mobile">
          <div className="mobile-clock-header">
            <ClockSidebar />
            <div className="container cc-main-body">
              <div
                style={{
                  width: "100%",
                  marginTop: "20px",
                  textAlign: "center",
                  fontSize: "1.3rem",
                  fontWeight: "bold"
                }}
              >
                Status
              </div>
              <div className="role-header">
                <RoleControls
                  handleSubmit={handleSubmit}
                  handleUserChange={handleUserChange}
                  user={user}
                  users={users}
                  company={context.company}
                  handleCompanyChange={handleCompanyChange}
                  companies={companies}
                  currentCompany={currentCompany}
                  search={search}
                  handleSearchChange={handleSearchChange}
                  handleSearchClick={handleSearchClick}
                  handleStoreChange={handleStoreChange}
                  context={context}
                  stores={stores}
                />
              </div>
              <div className="row status-current">
                <div className="col-12 justify-content-center align-items-center">
                  Users current Status: {status}
                </div>
              </div>
              <div className="status-change">
                <h3>Change Status to</h3>
                <select className="form-control" onChange={handleStatusChange}>
                  <option value="-1">Set new Status</option>
                  <option value="0">Clocked Out</option>
                  <option value="1">Clocked In</option>
                  <option value="2">On Break</option>
                  <option value="3">At Lunch</option>
                </select>
              </div>
              <div className="status-result">{result}</div>
            </div>
          </div>
        </div>
      </MobileView>
      <BrowserView>
        <div>
          <ClockDesktopMenu />
          <FloatingSidebar />
          <div className="container mt-4">
            <div
              style={{
                width: "100%",
                marginTop: "20px",
                textAlign: "center",
                fontSize: "1.3rem",
                fontWeight: "bold"
              }}
            >
              Status
            </div>
            <div className="role-header">
              <RoleControls
                handleSubmit={handleSubmit}
                handleUserChange={handleUserChange}
                user={user}
                users={users}
                company={context.company}
                handleCompanyChange={handleCompanyChange}
                companies={companies}
                currentCompany={currentCompany}
                search={search}
                handleSearchChange={handleSearchChange}
                handleSearchClick={handleSearchClick}
                handleStoreChange={handleStoreChange}
                context={context}
                stores={stores}
              />
            </div>
            <div>
              <div className="row status-current">
                <div className="col-12 justify-content-center align-items-center">
                  Users current Status: {status}
                </div>
              </div>
              <div className="row status-change">
                <div className="col-12">
                  <h3>Change Status to</h3>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <select
                    className="form-control"
                    onChange={handleStatusChange}
                  >
                    <option value="-1">Set new Status</option>
                    <option value="0">Clocked Out</option>
                    <option value="1">Clocked In</option>
                    <option value="2">On Break</option>
                    <option value="3">At Lunch</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-12 status-result">{result}</div>
              </div>
            </div>
          </div>
        </div>
      </BrowserView>
    </React.Fragment>
  );
};

export default Status;
