import React, { useState, useEffect, useContext } from "react";
import HeaderRB from "../../common/HeaderRb";
import { ContextConfig } from "../../App";
import { flyout } from "../../api/CashierApi";
import { gridEnums } from "../../api/enums";
import { toast } from "react-toastify";
import { BrowserView, MobileView, isBrowser } from "react-device-detect";
import { usePrevious } from "../../utils";
import { isEqual } from "../../fp";
import MobileFlyout from "./MobileFlyout";
import DesktopFlyout from "./DesktopFlyout";

const CashierFlyout = () => {
  const [cashiers, setCashiers] = useState([]);
  const context = useContext(ContextConfig);
  const [finishedLoading, setFinishedLoading] = useState(false);

  const previousCashiers = usePrevious(cashiers);

  useEffect(() => {
    if (!isEqual(previousCashiers, cashiers)) {
      loadCashiers();
    }
  }, [cashiers]);

  const loadCashiers = () => {
    setFinishedLoading(false);
    const config = {
      url: context.url,
      token: context.token,
      startDate: context.endDate,
      endDate: context.endDate,
      storeid: context.selectedRecord.storeid
    };
    if (isBrowser) {
      config.startDate = context.startDate;
    }

    flyout(config)
      .then((response) => {
        setFinishedLoading(true);
        const j = response.data;
        if (j.error === 0) {
          setCashiers(j.flyout);
        } else {
          setFinishedLoading(true);
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setFinishedLoading(true);
        console.log(err);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  return (
    <div>
      <HeaderRB />
      {finishedLoading && cashiers.length === 0 ? (
        <div key="0">{gridEnums.emptyGrid}</div>
      ) : (
        <React.Fragment>
          <BrowserView>
            <DesktopFlyout cashiers={cashiers} context={context} />
          </BrowserView>
          <MobileView>
            <MobileFlyout cashiers={cashiers} context={context} />
          </MobileView>
        </React.Fragment>
      )}
    </div>
  );
};

export default CashierFlyout;
