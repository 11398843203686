import * as actions from "../actions/actionTypes";

const initialState = {
  username: "",
  password: "",
  rememberMe: false,
  autoLogin: false,
  isLoading: false,
  toHome: false,
  showForgot: false
};

const loginClockReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOGIN_SET_USERNAME:
      return {
        ...state,
        username: action.username
      };
    case actions.LOGIN_SET_PASSWORD:
      return {
        ...state,
        password: action.password
      };
    case actions.LOGIN_SET_REMEMBER_ME:
      return {
        ...state,
        rememberMe: action.rememberMe
      };
    case actions.LOGIN_AUTO_LOGIN:
      return {
        ...state,
        autoLogin: action.autoLogin
      };
    case actions.LOGIN_SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading
      };
    case actions.LOGIN_SET_TO_HOME:
      return {
        ...state,
        toHome: action.toHome
      };
    case actions.LOGIN_SHOW_FORGOT:
      return {
        ...state,
        showForgot: action.forgot
      };
    default:
      return state;
  }
};

export default loginClockReducer;
