import React from "react";
import PropTypes from "prop-types";
import Td from "../Schedule/components/Td";
import { formatDate } from "../../utils";
import { compose, curry } from "../../fp";

const Footer = (props) => {
  let accumulatedTotal = 0;

  const getSchedules = () => {
    const newArray = [];

    props.hours.map((record) => {
      if (record) {
        newArray.push(record);
      }
    });

    return newArray;
  };

  const getDaysArray = () => {
    for (
      var arr = [], dt = new Date(props.startDate);
      dt <= props.endDate;
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(new Date(dt));
    }
    return arr;
  };

  const filterByDay = (day, schedules) => {
    const dates = getDaysArray();
    return schedules.filter(
      (d) => formatDate(d.punchDate) === formatDate(dates[day])
    );
  };

  // const addMinutes = (filteredData) => {
  //   return filteredData.reduce((acc, cur) => {
  //     return acc + cur.totalMinutes;
  //   }, 0);
  // };

  const curriedFilterByDay = curry(filterByDay);

  const totalHours = (filteredData) => {
    return filteredData.reduce((acc, cur) => {
      return acc + parseFloat(cur.totalHours);
    }, 0);
  };

  const getDayTotal = (day) => {
    if (props.hours.length === 0) return 0;
    const filterFn = curriedFilterByDay(day);

    const resultFn = compose(totalHours, filterFn, getSchedules);
    const dayTotal = resultFn();
    accumulatedTotal = parseFloat(dayTotal) + parseFloat(accumulatedTotal);
    return parseFloat(dayTotal).toFixed(props.settings.timePrecision);
  };

  return (
    <div className="scheduler-footer" id="scheduleFoot">
      <div className={`schedule-employee`}>Totals</div>
      {props.weekStart
        ? props.days.map((day, index) => {
            return (
              <Td key={`footer-c-${index}`} className="day-f-c-t">
                {getDayTotal(day)}
              </Td>
            );
          })
        : null}
      <div className="footer-total">
        {accumulatedTotal.toFixed(props.settings.timePrecision)}
      </div>
    </div>
  );
};

Footer.propTypes = {
  weekStart: PropTypes.any,
  days: PropTypes.array,
  hours: PropTypes.array,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  settings: PropTypes.object
};

export default Footer;
