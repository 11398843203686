import React, { useState, useEffect, useContext } from "react";
import HeaderRb from "../common/HeaderRb";
import { ContextConfig } from "../App";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "../common/Spinner";
import * as actions from "../actions/actionTypes";
import { debounce } from "../fp";
import TypeAheadDropDown from "../common/TypeAheadDropDown";
import { getAssignedStoresAsync } from "../api/StoreApi";
import { toast } from "react-toastify";
import { createForm4Request, getForm4Requests } from "../api/form4Api";
import { formatDate, formatTimeAmPm, isValid } from "../utils";

import "./Form4.css";

const Form4 = () => {
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const state = useSelector((app) => app.form4Reducer);
  const dispatch = useDispatch();

  const context = useContext(ContextConfig);

  useEffect(() => {
    fetchStores();
    if (state.currentStore == "") {
      dispatch({ type: actions.FORM4_SET_STORE, store: context.lastStoreid });
    }
    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }

      return () => {
        dispatch({ type: actions.REF_ENABLE_CASHIER, enableCashier: false });
      };
    }, 500);

    findDivHeight();

    window.addEventListener("resize", debounceHandleResize);

    if (isValid(state.currentStore)) {
      requestHistory();
    }

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, [state.currentStore]);

  const findDivHeight = () => {
    const navbar = document
      .getElementById("master-navbar")
      .getBoundingClientRect().height;
    const controls = document.getElementById("controls").getBoundingClientRect()
      .height;
    const usedSpace = navbar + controls;
    const remaining = window.innerHeight - usedSpace;

    const body = document.getElementById("body");
    body.style.height = remaining + "px";
    body.style.overflow = "auto";
  };

  const fetchStores = () => {
    dispatch({ type: actions.FORM4_LOADING, isLoading: true });
    getAssignedStoresAsync(context.url, context.token)
      .then((response) => {
        dispatch({ type: actions.FORM4_LOADING, isLoading: false });
        const j = response.data;
        if (j.error === 0) {
          dispatch({ type: actions.FORM4_SET_STORES, stores: j.items });
        } else {
          dispatch({ type: actions.FORM4_SET_STORES, stores: [] });
          toast.warning(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        dispatch({ type: actions.FORM4_LOADING, isLoading: false });
        dispatch({ type: actions.FORM4_SET_STORES, stores: [] });
        console.log(err);
        toast.error("An internal error occured", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const handleStoreChange = (e) => {
    dispatch({ type: actions.FORM4_SET_STORE, store: e.storeid });
    requestHistory();
  };

  const createRequest = () => {
    if (!state.currentStore) {
      toast.warning("Please select a store", {
        position: toast.POSITION.TOP_LEFT
      });
    }
    dispatch({ type: actions.FORM4_LOADING, isLoading: true });
    createForm4Request(context.url, context.token, state.currentStore)
      .then((response) => {
        dispatch({ type: actions.FORM4_LOADING, isLoading: false });
        const j = response.data;
        if (j.error === 0) {
          toast.success("Your request is being processed", {
            position: toast.POSITION.TOP_LEFT
          });
        } else {
          toast.warning(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        dispatch({ type: actions.FORM4_LOADING, isLoading: false });
        console.log(err);
        toast.error("An Internal error occured", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const requestHistory = () => {
    dispatch({ type: actions.FORM4_LOADING, isLoading: true });
    getForm4Requests(context.url, context.token, state.currentStore)
      .then((response) => {
        dispatch({ type: actions.FORM4_LOADING, isLoading: false });
        const j = response.data;
        if (j.error === 0) {
          dispatch({ type: actions.FORM4_SET_HISTORY, history: j.requests });
        } else {
          toast.warning(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: actions.FORM4_LOADING, isLoading: false });
        toast.error("An Internal error occured", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  return (
    <div>
      {state.isLoading && <Spinner />}
      <HeaderRb />
      <div id="controls">
        <h3 style={{ marginTop: "10px" }}>Form 4 Utility</h3>
        <div className="container" style={{ marginTop: "20px" }}>
          <div className="row justify-content-center">
            <div className="col-md-6">
              <TypeAheadDropDown
                data={state.stores}
                keyValue={"storeid"}
                displayValue={"store_Name"}
                value={parseInt(state.currentStore)}
                setSelection={handleStoreChange}
                label="Select Store"
              />
            </div>
            <div className="col-md-6">
              <button
                className="btn btn-outline-secondary"
                onClick={() => createRequest()}
              >
                Email Me Report
              </button>
            </div>
          </div>
        </div>
      </div>
      <div id="body">
        <div className="container">
          <h3>History</h3>
          {state.history.length > 0 ? (
            <div className="form4-history">
              <div className="form4-history-item">
                <div className="form4-header-item">Id</div>
                <div className="form4-header-item">Created</div>
                <div
                  className="form4-header-item"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  Received
                </div>
                <div className="form4-header-item">Received Date</div>
                <div className="form4-header-item">Created by</div>
              </div>
              {state.history.map((record, i) => (
                <div
                  className={`form4-history-item ${
                    record.id % 2 === 0 ? "even-form4" : "odd-form4"
                  }`}
                  key={`f4-hi-${i}`}
                >
                  <div>{record.id}</div>
                  <div>
                    {formatDate(record.create_Date)}{" "}
                    {formatTimeAmPm(record.create_Date)}
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <input
                      type="checkbox"
                      className="form-control"
                      style={{ width: "15px", height: "15px" }}
                      value={parseInt(record.downloaded_Time)}
                      checked={parseInt(record.downloaded_Time)}
                      readOnly={true}
                    />
                  </div>
                  <div>
                    {formatDate(record.downloaded_Time)}{" "}
                    {formatTimeAmPm(record.downloaded_Time)}
                  </div>
                  <div>{record.create_UserName}</div>
                </div>
              ))}
            </div>
          ) : (
            <div className="empty-history">
              No Records were returned for this store
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Form4;
