/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

const GroupBy = ({ groups, setGroups, data, id, activeGroup }) => {
  // const [group, setGroup] = useState(false);
  const [currentGroup, setCurrentGroup] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [direction, setDirection] = useState(0);

  useEffect(() => {
    updateGroups();
  }, [currentGroup, direction]);

  useEffect(() => {
    if (activeGroup && activeGroup.group) {
      setCurrentGroup(activeGroup.group);
    }
  }, [activeGroup]);

  const changeGroup = (e) => {
    setCurrentGroup(e.target.value);
  };

  // const changeDirection = (e) => {
  //   setDirection(e.target.value);
  // };

  const removeGroup = () => {
    const otherGroups = groups.filter((g) => g.id !== id);
    setGroups(otherGroups);
  };

  const updateGroups = () => {
    if (Object.keys(currentGroup).length === 0) {
      return;
    }
    const selectedGroup = groups.find((g) => g.id === id);
    selectedGroup.group = currentGroup;
    selectedGroup.direction = direction;

    const otherGroups = groups.filter((g) => g.id !== id);
    setGroups([...otherGroups, selectedGroup]);
  };

  return (
    <div className="row justify-content-center mt-2 w-100">
      <div
        className="row justify-content-center mt-2"
        style={{
          userSelect: "none",
          padding: 10,
          margin: "0 0 8px 0",
          border: "1px solid #fff",
          borderRadius: "10px",

          backgroundColor: "#456C86",
          color: "white"
        }}
      >
        <div className="col-8">
          <select
            className="form-control mt-2"
            value={currentGroup}
            onChange={changeGroup}
          >
            <option value="0">Select Group</option>
            {data.map((record, i) => (
              <option key={`${id}-group-field-${i}`} value={record.accessor}>
                {record.label}
              </option>
            ))}
          </select>
        </div>

        <div className="col-2">
          <button className="btn btn-danger" onClick={removeGroup}>
            X
          </button>
        </div>
      </div>
    </div>
  );
};

GroupBy.propTypes = {
  groups: PropTypes.array,
  setGroups: PropTypes.func,
  data: PropTypes.array,
  id: PropTypes.any,
  activeGroup: PropTypes.any
};

export default GroupBy;
