import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import PasswordStrengthMeter from "../../common/PasswordStrengthMeter";
import "./ResetDialog.css";

const ResetDialog = ({ isShowing, hide, handleResetClick, context }) =>
  isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <div className="modal-reset-overlay" />
          <div
            className="modal-reset-wrapper"
            aria-modal
            aria-hidden
            tabIndex={-1}
            role="dialog"
          >
            <div className="modal-reset">
              <div className="modal-reset-header">
                <span className="modal-reset-confirm-title">
                  Reset Password
                </span>
                <span className="modal-reset-close-box">
                  <button
                    type="button"
                    className="modal-reset-close-button"
                    data-dismiss="modal"
                    aria-label="close"
                    onClick={hide}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </span>
              </div>
              <div className="modal-reset-body p-4">
                <form>
                  <div className="form-group">
                    <label htmlFor="newPassword">Enter new Password</label>
                    <PasswordStrengthMeter
                      userid={context.userid}
                      confirmPasswordChange={handleResetClick}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </React.Fragment>,
        document.body
      )
    : null;

ResetDialog.propTypes = {
  isShowing: PropTypes.bool,
  hide: PropTypes.func,
  handleResetClick: PropTypes.func
};

export default ResetDialog;
