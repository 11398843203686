import React from "react";
import PropTypes from "prop-types";

const DepartmentControls = (props) => {
  return (
    <div className="container">
      <div className="new-department mt-2">
        <form className="form-inline justify-content-center">
          <div className="form-group mb-2">
            <label htmlFor="dept">Departments</label>
            <input
              type="text"
              className="form-control ml-2"
              placeholder="Department"
              value={props.department}
              onChange={props.handleDepartmentChange}
            />
          </div>
          <div className="form-group mx-sm-3 mb-2">
            <label htmlFor="dept-group">Group</label>
            <input
              type="text"
              className="form-control ml-2"
              placeholder="Group"
              value={props.group}
              onChange={props.handleGroupChange}
            />
          </div>
          <div className="form-group mx-sm-3 mb-2">
            <label htmlFor="pos-link">POS Link</label>
            <input
              type="text"
              className="form-control ml-2"
              placeholder="POS Link"
              value={props.posLink}
              onChange={props.handlePosLinkChange}
            />
          </div>
          <button
            type="submit"
            className="btn btn-outline-dark mb-2"
            onClick={props.AddDepartment}
          >
            Add Department
          </button>
        </form>
      </div>
    </div>
  );
};

DepartmentControls.propTypes = {
  department: PropTypes.string,
  handleDepartmentChange: PropTypes.func,
  group: PropTypes.string,
  handleGroupChange: PropTypes.func,
  posLink: PropTypes.string,
  handlePosLinkChange: PropTypes.func,
  AddDepartment: PropTypes.func
};

export default DepartmentControls;
