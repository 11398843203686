import axios from "axios";

const savePrefs = (
  url,
  token,
  storeid,
  groupid,
  searchtype,
  groupname,
  success,
  failure
) => {
  axios({
    method: "PUT",
    cors: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "prefs/saveprefs",
    params: {
      storeid,
      groupid,
      searchtype,
      groupname
    }
  })
    .then((response) => {
      if (success !== null) {
        success(response);
      }
    })
    .catch((err) => {
      if (failure !== null) {
        failure(err);
      }
    });
};

const readPrefs = (url, token, success, failure) => {
  axios({
    method: "GET",
    cors: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "prefs"
  })
    .then((response) => {
      if (success !== null) {
        success(response);
      }
    })
    .catch((err) => {
      if (failure !== null) {
        failure(err);
      }
    });
};

export { savePrefs, readPrefs };
