import axios from "axios";

async function getPredictedItems(url, token, items, recordSize) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "basket/predict",
    params: {
      items: JSON.stringify(items),
      recordSize
    }
  });
  return json;
}

async function getItemDetails(url, token, items, itemSize) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "basket/items",
    params: {
      items: JSON.stringify(items),
      itemSize
    }
  });
  return json;
}

async function getCoupons(
  url,
  token,
  startDate,
  endDate,
  useGroups,
  searchValue,
  singleStore
) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "basket/coupons",
    params: {
      startDate,
      endDate,
      useGroups,
      searchValue,
      singleStore
    }
  });
  return json;
}

async function getCouponPrediction(
  url,
  token,
  upc,
  itemSize,
  startDate,
  endDate,
  useGroups,
  searchValue,
  singleStore
) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "basket/predictcoupon",
    params: {
      upc,
      itemSize,
      startDate,
      endDate,
      useGroups,
      searchValue,
      singleStore
    }
  });
  return json;
}

async function getItemsets(
  url,
  token,
  support,
  startDate,
  endDate,
  useGroups,
  searchValue,
  singleStore,
  sortBy,
  direction
) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "basket/itemsets",
    params: {
      support,
      startDate,
      endDate,
      useGroups,
      searchValue,
      singleStore,
      sortBy,
      direction
    }
  });
  return json;
}

async function getItemsetDetails(
  url,
  token,
  item1,
  item2,
  item3,
  item4,
  item5,
  startDate,
  endDate,
  useGroups,
  searchValue,
  singleStore
) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "basket/itemsetdetails",
    params: {
      item1,
      item2,
      item3,
      item4,
      item5,
      startDate,
      endDate,
      useGroups,
      searchValue,
      singleStore
    }
  });
  return json;
}

async function getCpnQty(
  url,
  token,
  f01,
  startDate,
  endDate,
  useGroups,
  searchValue,
  singleStore
) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "basket/cpnqty",
    params: {
      f01,
      startDate,
      endDate,
      useGroups,
      searchValue,
      singleStore
    }
  });
  return json;
}

export {
  getPredictedItems,
  getItemDetails,
  getCoupons,
  getCouponPrediction,
  getItemsets,
  getItemsetDetails,
  getCpnQty
};
