import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  Row,
  Col,
  CardHeader,
  CardTitle,
  CardBody,
  ButtonGroup
} from "reactstrap";
import { Button } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import { chartDefaults } from "../options/Charts";
import { isMobile } from "react-device-detect";

const SalesChart = (props) => {
  let salesOptions = {
    data1: (canvas) => {
      let ctx = canvas.getContext("2d");

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
      gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
      gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

      return {
        labels: props.salesLabels,
        datasets: [
          {
            label: "Sales by Users Stores",
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: "#1f8ef1",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#1f8ef1",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#1f8ef1",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: props.totalSales
          }
        ]
      };
    },
    data2: (canvas) => {
      let ctx = canvas.getContext("2d");

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
      gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
      gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

      return {
        labels: props.salesLabels,
        datasets: [
          {
            label: "My First dataset",
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: "#1f8ef1",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#1f8ef1",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#1f8ef1",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: props.netSales
          }
        ]
      };
    },
    data3: (canvas) => {
      let ctx = canvas.getContext("2d");

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
      gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
      gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

      return {
        labels: props.salesLabels,
        datasets: [
          {
            label: "My First dataset",
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: "#1f8ef1",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#1f8ef1",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#1f8ef1",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: props.taxes
          }
        ]
      };
    },
    options: chartDefaults
  };
  return (
    <Col xs="12">
      <Card className="card-chart">
        <CardHeader>
          <Row>
            <Col className="text-left" sm="6">
              <h5 className="card-category">All assigned stores</h5>
              <CardTitle tag="h2">
                {props.salesSelector === "data1"
                  ? "Total Sales"
                  : props.salesSelector === "data2"
                  ? "Net Sales"
                  : "Taxes"}
              </CardTitle>
            </Col>
            {!isMobile && (
              <Col sm="6">
                <ButtonGroup
                  className="btn-group-toggle float-right"
                  data-toggle="buttons"
                >
                  <Button
                    color="#4785c2"
                    id="0"
                    size="sm"
                    className="btn-info btn-simple"
                    onClick={() => props.setSalesSelector("data1")}
                  >
                    <input defaultChecked name="options" type="radio" />
                    <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                      Total Sales
                    </span>
                    <span className="d-block d-sm-none">
                      <i className="tim-icons icon-single-02" />
                    </span>
                  </Button>

                  <Button
                    color="warning"
                    id="1"
                    size="sm"
                    tag="label"
                    className="btn-info btn-simple"
                    onClick={() => props.setSalesSelector("data2")}
                  >
                    <input name="options" type="radio" />
                    <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                      Net sales
                    </span>
                    <span className="d-block d-sm-none">
                      <i className="tim-icons icon-gift-2" />
                    </span>
                  </Button>

                  <Button
                    color="danger"
                    id="2"
                    size="sm"
                    tag="label"
                    className="btn-info btn-simple"
                    onClick={() => props.setSalesSelector("data3")}
                  >
                    <input name="options" type="radio" />
                    <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                      Taxes
                    </span>
                    <span className="d-block d-sm-none">
                      <i className="tim-icons icon-tap-02" />
                    </span>
                  </Button>
                </ButtonGroup>
              </Col>
            )}
          </Row>
        </CardHeader>
        <CardBody>
          <div className="chart-area">
            <Line
              data={salesOptions[props.salesSelector]}
              options={salesOptions.options}
            />
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

SalesChart.propTypes = {
  salesLabels: PropTypes.array,
  totalSales: PropTypes.array,
  netSales: PropTypes.array,
  taxes: PropTypes.any,
  salesSelector: PropTypes.string,
  setSalesSelector: PropTypes.func
};

export default SalesChart;
