import React from "react";
import PropTypes from "prop-types";
import { isMobile } from "react-device-detect";
import ReactTooltip from "react-tooltip";
import TextInput from "../../common/TextInput";
import Tooltip from "../../Tooltip/Tooltip";

const GSMobile = (props) => {
  return (
    <div id="settings" style={{ padding: "20px" }}>
      <ReactTooltip />
      <hr width="100%" />
      {isMobile ? (
        <form>
          <label>Select a Store</label>
          <div className="cc-form-item">
            <select
              className="form-control"
              name="stores"
              placeholder="Select Store"
              onChange={props.onStoreChange}
              value={props.store || ""}
            >
              <option value="0">Select a Store</option>
              {props.stores.length === 0 ? (
                <option value="0">No Stores returned</option>
              ) : (
                props.stores.map((s, index) => (
                  <option key={index} value={s.id}>
                    {s.storeName}
                  </option>
                ))
              )}
            </select>
          </div>
        </form>
      ) : null}

      <form className="justify-content-center">
        <div className="form group">
          <div className="form-check m-auto" style={{ textAlign: "center" }}>
            <input
              type="checkbox"
              className="form-check-input"
              name="checkCopy"
              onChange={props.handleCheckChange}
              style={{ opacity: 1, visibility: "visible" }}
            />
            <label>Copy from Another Store?</label>
            {props.copy ? (
              <React.Fragment>
                <select
                  className="form-control ml-3 mb-2"
                  name="copyStore"
                  onChange={props.setCopyStore}
                >
                  <option value="0">Select Store to copy from</option>
                  {props.stores.length > 0
                    ? props.stores.map((store, index) => (
                        <option key={`copy-store-${index}`} value={store.id}>
                          {store.storeName}
                        </option>
                      ))
                    : null}
                </select>
                <button
                  className="btn btn-outline-dark ml-3"
                  onClick={props.handleCopySettings}
                >
                  Copy
                </button>
              </React.Fragment>
            ) : null}
          </div>
        </div>
      </form>

      <form>
        <div className="row justify-content-center">
          <div className="col-sm-12 col-md-4">
            <label>Rounding Method</label>
            <div>
              <select
                className="form-control"
                name="roundingMethod"
                placeholder="Rounding Method"
                onChange={props.onChange}
                value={props.settings.roundingMethod || ""}
                style={{ marginTop: "0px" }}
              >
                <option value="0">Select a Rounding Method</option>
                {props.roundingMethods.length === 0 ? (
                  <option value="0">No Rounding Methods returned</option>
                ) : (
                  props.roundingMethods.map((s, index) => (
                    <option key={index} value={s.id}>
                      {s.description}
                    </option>
                  ))
                )}
              </select>
            </div>
          </div>

          <div className="col-sm-12 col-md-4">
            <Tooltip
              message={"How many decimal places to display hours as"}
              position={"bottom"}
            >
              <TextInput
                type="tel"
                id="txtTimePrecision"
                name="timePrecision"
                label="Time Precision"
                onChange={props.onChange}
                value={props.settings.timePrecision}
                error={props.errors.timePrecision}
                placeholder="Time Precision"
              />
            </Tooltip>
          </div>
        </div>

        {props.settings.roundingMethod == "5" ? (
          <div className="row justify-content-center align-items-center">
            <div className="col-sm-12 col-md-4">
              <select
                className="form-control"
                placeholder="Select a Scheme"
                name="selectedScheme"
                onChange={props.onChange}
                value={props.settings.selectedScheme}
              >
                {props.schemes.map((record, index) => (
                  <option key={`rs-${index}`} value={record.id}>
                    {record.name}
                  </option>
                ))}
              </select>
            </div>
            {props.createSchemeName ? (
              <div className="col-sm-12 col-md-4">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Scheme Name"
                  value={props.schemeName}
                  onChange={(e) => props.setSchemeName(e.target.value)}
                />
              </div>
            ) : null}
            <div className="col-sm-12 col-md-4">
              <button
                className="btn btn-outline-light"
                onClick={(e) => props.createNewScheme(e)}
              >
                {props.createSchemeName ? (
                  <span data-tip="Create Scheme">
                    <i className="fas fa-plus-square"></i>
                  </span>
                ) : (
                  <span data-tip="Edit Scheme">
                    <i className="fas fa-pen-square"></i>
                  </span>
                )}
              </button>
            </div>
          </div>
        ) : null}

        {/* ****************** BREAKS */}
        <div className="row justify-content-center">
          <h2 style={{ color: "#fff", marginTop: "20px" }}>Breaks</h2>
        </div>
        <div className="row justify-content-center">
          {props.settings.forceBreakTime == "1" ? (
            <div className="col-sm-12 col-md-4">
              <Tooltip
                message={
                  "Number of paid break minutes if Deduct Full Break is disabled"
                }
                position={"bottom"}
              >
                <TextInput
                  type="tel"
                  id="txtfreeBreakMinutes"
                  name="freeBreakMinutes"
                  label="Free Break Minutes"
                  onChange={props.onChange}
                  value={props.settings.freeBreakMinutes}
                  error={props.errors.freeBreakMinutes}
                  placeholder="Free Break Minutes"
                />
              </Tooltip>
            </div>
          ) : null}

          <div className="col-sm-12 col-md-4">&nbsp;</div>
        </div>
        <div className="row justify-content-center">
          <div className="col-sm-12 col-md-4">
            <Tooltip
              message={
                "if Force Break Time is enabled, employee cannot punch back in from Break before x minutes"
              }
              position={"bottom"}
            >
              <TextInput
                type="tel"
                id="txtdMandatoryBreakMinutes"
                name="mandatoryBreakMinutes"
                label="Mandatory Break Minutes"
                onChange={props.onChange}
                value={props.settings.mandatoryBreakMinutes}
                error={props.errors.mandatoryBreakMinutes}
                placeholder="Mandatory Break Minutes"
              />
            </Tooltip>
          </div>
          <div className="col-sm-12 col-md-4">
            <Tooltip
              message={
                "This is used for reporting. Report any user that has worked x hours and did not take a break"
              }
              position={"bottom"}
            >
              <label>Minumum Hours for Mandatory Break</label>
              <input
                type="text"
                className="form-control"
                name="minumumHoursForMandatoryBreak"
                value={props.settings.minumumHoursForMandatoryBreak}
                onChange={props.onChange}
              />
            </Tooltip>
          </div>
        </div>

        {props.settings.forceBreakTime == "1" ? (
          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-4">
              <Tooltip
                message={
                  "if Force Break Time is enabled, Grace period to allow Employee to return from Break"
                }
                position={"bottom"}
              >
                <TextInput
                  type="tel"
                  id="txtBreakGracePeriodBefore"
                  name="breakGracePeriodBefore"
                  label="Break Grace Period Before"
                  onChange={props.onChange}
                  value={props.settings.breakGracePeriodBefore}
                  error={props.errors.breakGracePeriodBefore}
                  placeholder="Grace Period Before"
                />
              </Tooltip>
            </div>
            <div className="col-sm-12 col-md-4">
              <Tooltip
                message={
                  "if Force Break Time is enabled, Grace period to allow Employee to return from Break after the time has passed"
                }
                position={"bottom"}
              >
                <TextInput
                  type="tel"
                  id="txtBreakGracePeriodAfter"
                  name="breakGracePeriodAfter"
                  label="Break Grace Period After"
                  onChange={props.onChange}
                  value={props.settings.breakGracePeriodAfter}
                  error={props.errors.breakGracePeriodAfter}
                  placeholder="Grace Period After"
                />
              </Tooltip>
            </div>
          </div>
        ) : null}

        <div className="row justify-content-center mt-3">
          <div className="col-sm-12 col-md-4">
            <div className="form-check m-auto" style={{ textAlign: "center" }}>
              <input
                className="form-check-input"
                type="checkbox"
                name="forceBreakTime"
                value={props.settings.forceBreakTime}
                onChange={props.onChange}
                error={props.errors.forceBreakTime}
                checked={props.settings.forceBreakTime}
                style={{ opacity: 1, visibility: "visible" }}
              />
              <label>
                <Tooltip
                  message={"Enable to enforce the Mandatory Break Minutes"}
                  position={"bottom"}
                >
                  Force Break Time
                </Tooltip>
              </label>
            </div>
          </div>
          <div className="col-sm-12 col-md-4">
            <div className="form-check m-auto" style={{ textAlign: "center" }}>
              <input
                className="form-check-input"
                type="checkbox"
                name="enableBreak"
                value={props.settings.enableBreak}
                onChange={props.onChange}
                checked={props.settings.enableBreak}
                style={{ opacity: 1, visibility: "visible" }}
              />
              <label>
                <Tooltip
                  message={
                    "If disabled, the Break button will not be an option on the Clock"
                  }
                  position={"bottom"}
                >
                  Enable Break
                </Tooltip>
              </label>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-sm-12 col-md-4">
            <div className="form-check m-auto" style={{ textAlign: "center" }}>
              <Tooltip
                message={
                  "if enabled, entire Break minutes will be deducted from the Employees Time"
                }
                position={"bottom"}
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="deductFullBreak"
                  value={props.settings.deductFullBreak}
                  onChange={props.onChange}
                  error={props.errors.deductFullBreak}
                  checked={props.settings.deductFullBreak}
                  style={{ opacity: 1, visibility: "visible" }}
                />
                <label>Deduct Full Break</label>
              </Tooltip>
            </div>
          </div>
          <div className="col-sm-12 col-md-4">
            <div className="form-check m-auto" style={{ textAlign: "center" }}>
              &nbsp;
            </div>
          </div>
        </div>

        {/* ************** LUNCHES */}
        <div className="row justify-content-center">
          <h1 style={{ color: "#fff", marginTop: "20px" }}>Lunches</h1>
        </div>

        <div className="row justify-content-center">
          <div className="col-sm-12 col-md-4">
            <Tooltip
              message={
                "Amount of paid lunch minutes if Deduct Full Lunch is disabled"
              }
              position={"bottom"}
            >
              <TextInput
                type="tel"
                id="txtmfl"
                name="minutesForLunch"
                label="Minutes for Lunch"
                onChange={props.onChange}
                value={props.settings.minutesForLunch}
                error={props.errors.minutesForLunch}
                placeholder="Minutes for Lunch"
              />
            </Tooltip>
          </div>
          <div className="col-sm-12 col-md-4">
            <Tooltip
              message={
                "used for Reporting. Report on any Employee who has worked x hours and has not taken a Lunch"
              }
              position={"bottom"}
            >
              <label>Minumum Hours for Mandatory Lunch</label>
              <input
                type="text"
                className="form-control"
                name="minumumHoursForMandatoryLunch"
                value={props.settings.minumumHoursForMandatoryLunch}
                onChange={props.onChange}
              />
            </Tooltip>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-sm-12 col-md-4">
            <Tooltip
              message={
                "if Force Lunch Time is enabled, grace period in minutes for Employee to return from Lunch early"
              }
              position={"bottom"}
            >
              <TextInput
                type="tel"
                id="txtlgpb"
                name="lunchGracePeriodBefore"
                label="Lunch Grace Period Before"
                onChange={props.onChange}
                value={props.settings.lunchGracePeriodBefore}
                error={props.errors.lunchGracePeriodBefore}
              />
            </Tooltip>
          </div>
          <div className="col-sm-12 col-md-4">
            <Tooltip
              message={
                "if Force Lunch Time is enabled, grace period in minutes for Employee to return from Lunch late"
              }
              position={"bottom"}
            >
              <TextInput
                type="tel"
                id="txtlgpa"
                name="lunchGracePeriodAfter"
                label="Lunch Grace Period After"
                onChange={props.onChange}
                value={props.settings.lunchGracePeriodAfter}
                error={props.errors.lunchGracePeriodAfter}
              />
            </Tooltip>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-sm-12 col-md-4">
            <div className="form-check m-auto" style={{ textAlign: "center" }}>
              <Tooltip
                message={"Enabled Lunch grace periods"}
                position={"bottom"}
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="forceLunch"
                  value={props.settings.forceLunch}
                  onChange={props.onChange}
                  error={props.errors.forceLunch}
                  checked={props.settings.forceLunch}
                  style={{ opacity: 1, visibility: "visible" }}
                />
                <label>Force Lunch Time</label>
              </Tooltip>
            </div>
          </div>
          <div className="col-sm-12 col-md-4">
            <div className="form-check m-auto" style={{ textAlign: "center" }}>
              <Tooltip
                message={"Adds lunch button to clock"}
                position={"bottom"}
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="enableLunch"
                  value={props.settings.enableLunch}
                  onChange={props.onChange}
                  checked={props.settings.enableLunch}
                  style={{ opacity: 1, visibility: "visible" }}
                />
                <label>Enable Lunch</label>
              </Tooltip>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-sm-12 col-md-4">
            <div className="form-check m-auto" style={{ textAlign: "center" }}>
              <Tooltip
                message={"If enabled, entire lunch minutes will be deducted"}
                position={"bottom"}
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="deductFullLunch"
                  value={props.settings.deductFullLunch}
                  onChange={props.onChange}
                  checked={props.settings.deductFullLunch}
                  error={props.errors.deductFullLunch}
                  style={{ opacity: 1, visibility: "visible" }}
                />
                <label>Deduct Full Lunch</label>
              </Tooltip>
            </div>
          </div>

          <div className="col-sm-12 col-md-4">
            <div className="form-check m-auto" style={{ textAlign: "center" }}>
              <Tooltip
                message={"Deducts all time over the Minutes for lunch setting"}
                position={"bottom"}
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="deductLunchOverMax"
                  value={props.settings.deductLunchOverMax}
                  onChange={props.onChange}
                  checked={props.settings.deductLunchOverMax}
                  error={props.errors.deductLunchOverMax}
                  style={{ opacity: 1, visibility: "visible" }}
                />
                <label>Only deduct Lunch over Minutes for Lunch</label>
              </Tooltip>
            </div>
          </div>
        </div>

        {/* ***************************** GRACE PERIODS */}
        <div className="row justify-content-center">
          <h2 style={{ color: "#fff", marginTop: "20px" }}>Grace Periods</h2>
        </div>

        <div className="row justify-content-center">
          <div className="col-sm-12 col-md-4">
            <Tooltip
              message={
                "minutes allowed to clock in early before scheduled shift if Enforce Schedule is enabled"
              }
              position={"bottom"}
            >
              <TextInput
                type="tel"
                id="txtclockIngracePeriodBefore"
                name="clockInGracePeriodBefore"
                label="Clock in Grace Period Before"
                onChange={props.onChange}
                value={props.settings.clockInGracePeriodBefore}
                error={props.errors.clockInGracePeriodBefore}
                placeholder="Clock in Grace Period Before"
              />
            </Tooltip>
          </div>
          <div className="col-sm-12 col-md-4">
            <Tooltip
              message={
                "minutes allowed to clock in late after scheduled shift if Enforce schedule is enabled"
              }
              position={"bottom"}
            >
              <TextInput
                type="tel"
                id="txtclockIngracePeriodAfter"
                name="clockInGracePeriodAfter"
                label="Clock in Grace Period After"
                onChange={props.onChange}
                value={props.settings.clockInGracePeriodAfter}
                error={props.errors.clockInGracePeriodAfter}
                placeholder="Clock in Grace Period After"
              />
            </Tooltip>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-sm-12 col-md-4">
            <Tooltip
              message={
                "minutes allowed to clock out from shift early when Enforce Schedule is enabled"
              }
              position={"bottom"}
            >
              <TextInput
                type="tel"
                id="txtclockOutgracePeriodBefore"
                name="punchOutGracePeriodBefore"
                label="Clock Out Grace Period Before"
                onChange={props.onChange}
                value={props.settings.punchOutGracePeriodBefore}
                error={props.errors.punchOutGracePeriodBefore}
                placeholder="Clock Out Grace Period Before"
              />
            </Tooltip>
          </div>
          <div className="col-sm-12 col-md-4">
            <Tooltip
              message={
                "minutes allowed to clock out from shift late when Enforce Schedule is enabled"
              }
              position={"bottom"}
            >
              <TextInput
                type="tel"
                id="txtclockOutgracePeriodAfter"
                name="punchOutGracePeriodAfter"
                label="Clock Out Grace Period After"
                onChange={props.onChange}
                value={props.settings.punchOutGracePeriodAfter}
                error={props.errors.punchOutGracePeriodAfter}
                placeholder="Clock out Grace Period After"
              />
            </Tooltip>
          </div>
        </div>

        {/* ************ TIMEOUTS */}
        <div className="row justify-content-center">
          <h2 style={{ color: "#fff", marginTop: "20px" }}>Timeouts</h2>
        </div>
        <div className="row justify-content-center">
          <div className="col-sm-12 col-md-4">
            <Tooltip
              message={
                "seconds before clock resets after a manager login occurs"
              }
              position={"bottom"}
            >
              <TextInput
                type="tel"
                id="txtManagerTimeout"
                name="managerTimeout"
                label="Manager Timeout"
                onChange={props.onChange}
                value={props.settings.managerTimeout}
                error={props.errors.managerTimeout}
                placeholder="Manager Timeout"
              />
            </Tooltip>
          </div>
          <div className="col-sm-12 col-md-4">
            <Tooltip
              message={
                "seconds before clock resets after an employee has entered their login information"
              }
              position={"bottom"}
            >
              <TextInput
                type="tel"
                id="txtPunchInTimeout"
                name="punchInTimeout"
                label="Punch In Timeout"
                onChange={props.onChange}
                value={props.settings.punchInTimeout}
                error={props.errors.punchInTimeout}
                placeholder="Punch In Timeout"
              />
            </Tooltip>
          </div>
        </div>

        {/* ************* SCHEDULE */}
        <div className="row justify-content-center">
          <h2 style={{ color: "#fff", marginTop: "20px" }}>Schedule</h2>
        </div>
        <div className="row justify-content-center">
          <div className="col-sm-12 col-md-4">
            <Tooltip
              message={"number of weeks to auto advance schedule when opened"}
              position={"bottom"}
            >
              <TextInput
                type="tel"
                id="txtdefaultScheduleWeek"
                name="defaultScheduleWeek"
                label="Default Schedule Week"
                onChange={props.onChange}
                value={props.settings.defaultScheduleWeek}
                error={props.errors.defaultScheduleWeek}
                placeholder="Default Schedule Week"
              />
            </Tooltip>
          </div>
          <div className="col-sm-12 col-md-4" style={{ paddingTop: "35px" }}>
            <div
              className=" col form-check m-auto"
              style={{ textAlign: "center" }}
            >
              <Tooltip
                message={"Enables scheduling features for grace periods"}
                position={"bottom"}
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="enforceSchedule"
                  value={props.settings.enforceSchedule}
                  onChange={props.onChange}
                  error={props.errors.enforceSchedule}
                  checked={props.settings.enforceSchedule}
                  style={{ opacity: 1, visibility: "visible" }}
                />
                <label>Enforce Schedule</label>
              </Tooltip>
            </div>
          </div>
          <div className="col-sm-12 col-md-4" style={{ textAlign: "center" }}>
            <Tooltip
              message={"Automatically deduct these minutes from each shift"}
              position={"bottom"}
            >
              <TextInput
                type="tel"
                id="txtAutoDeductMinutesFromShift"
                name="autoDeductMinutesFromShift"
                label="Auto Deduct from Shift"
                onChange={props.onChange}
                value={props.settings.autoDeductMinutesFromShift}
                placeholder="Auto deduct minutes from shift"
              />
            </Tooltip>
          </div>
        </div>

        {/* ************** EXTRAS*/}
        <div className="row justify-content-center">
          <h2 style={{ color: "#fff", marginTop: "20px" }}>Extras</h2>
        </div>
        <div className="row justify-content-center">
          <div className="col-sm-12 col-md-4">
            <Tooltip
              message={
                "Used for reporting widget when employees are approaching 40 hours"
              }
              position={"bottom"}
            >
              <TextInput
                type="tel"
                id="txtApproachingFortyThreshold"
                name="approachingFortyThreshold"
                label="Approaching Forty Threshold"
                onChange={props.onChange}
                value={props.settings.approachingFortyThreshold}
                error={props.errors.approachingFortyThreshold}
                placeholder="Approaching Forty Threshold"
              />
            </Tooltip>
          </div>
          <div className="col-sm-12 col-md-4">
            <Tooltip
              message={
                "Time that rolls punches and shift hours over to the next day"
              }
              position={"bottom"}
            >
              <TextInput
                type="tel"
                id="txtDayRollOver"
                name="dayRollOver"
                label="Day Roll Over"
                onChange={props.onChange}
                value={props.settings.dayRollOver}
                error={props.errors.dayRollOver}
                placeholder="Day Roll Over"
              />
            </Tooltip>
          </div>
        </div>

        <div className="row justify-content-center mt-3">
          <div className="col-sm-12 col-md-4" style={{ textAlign: "center" }}>
            <input
              className="form-check-input"
              type="checkbox"
              name="enableClockPassword"
              value={props.settings.enableClockPassword}
              onChange={props.onChange}
              error={props.errors.enableClockPassword}
              checked={props.settings.enableClockPassword}
              style={{ opacity: 1, visibility: "visible" }}
            />
            <label>Enable Clock Password</label>
          </div>
          <div className="col-sm-12 col-md-4" style={{ textAlign: "center" }}>
            <Tooltip
              message={"Allows or disallows editing of your own time"}
              position={"bottom"}
            >
              <input
                className="form-check-input"
                type="checkbox"
                name="dissalowEditingOfYourOwnTime"
                value={props.settings.dissalowEditingOfYourOwnTime}
                onChange={props.onChange}
                error={props.errors.dissalowEditingOfYourOwnTime}
                checked={props.settings.dissalowEditingOfYourOwnTime}
                style={{ opacity: 1, visibility: "visible" }}
              />
              <label>Disallow editing of your own time</label>
            </Tooltip>
          </div>
          {/* <div className="col-sm-12 col-md-4" style={{ textAlign: "center" }}>
            <input
              className="form-check-input"
              type="checkbox"
              name="useMilitaryTime"
              value={props.settings.useMilitaryTime}
              onChange={props.onChange}
              checked={props.settings.useMilitaryTime}
              style={{ opacity: 1, visibility: "visible" }}
            />
            <label>Use Military Time</label>
          </div> */}
        </div>

        <div className="row justify-content-center">
          <div className="col-sm-12 col-md-4" style={{ textAlign: "center" }}>
            <input
              className="form-check-input"
              type="checkbox"
              name="showRawPunches"
              value={props.settings.showRawPunches}
              onChange={props.onChange}
              error={props.errors.showRawPunches}
              checked={props.settings.showRawPunches}
              style={{ opacity: 1, visibility: "visible" }}
            />
            <label>Show Raw Punches</label>
          </div>
          <div className="col-sm-12 col-md-4" style={{ textAlign: "center" }}>
            <input
              className="form-check-input"
              type="checkbox"
              name="showRawPunchesOnEditScreen"
              value={props.settings.showRawPunchesOnEditScreen}
              onChange={props.onChange}
              error={props.errors.showRawPunchesOnEditScreen}
              checked={props.settings.showRawPunchesOnEditScreen}
              style={{ opacity: 1, visibility: "visible" }}
            />
            <label>Show Raw Punches on Edit Screen</label>
          </div>
          <div className="col-sm-12 col-md-4" style={{ textAlign: "center" }}>
            <input
              className="form-check-input"
              type="checkbox"
              name="uniqueEmployeeIds"
              value={props.settings.uniqueEmployeeIds}
              onChange={props.onChange}
              error={props.errors.uniqueEmployeeIds}
              checked={props.settings.uniqueEmployeeIds}
              style={{ opacity: 1, visibility: "visible" }}
            />
            <label>Use Unique Employee Ids for each store</label>
          </div>
        </div>

        <div className="row justify-content-center mt-4">
          <div
            className="col-sm-12 col-md-4"
            style={{
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center"
            }}
          >
            <button
              type="submit"
              className="btn btn-outline-dark"
              onClick={props.handleSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

GSMobile.propTypes = {
  settings: PropTypes.object,
  errors: PropTypes.array,
  onChange: PropTypes.func,
  onStoreChange: PropTypes.func,
  stores: PropTypes.array,
  handleSubmit: PropTypes.func,
  roundingMethods: PropTypes.array,
  store: PropTypes.any,
  handleCheckChange: PropTypes.func,
  copy: PropTypes.bool,
  setCopyStore: PropTypes.func,
  handleCopySettings: PropTypes.func,
  schemes: PropTypes.array,
  createSchemeName: PropTypes.bool,
  schemeName: PropTypes.string,
  setSchemeName: PropTypes.func,
  createNewScheme: PropTypes.func
};

export default GSMobile;
