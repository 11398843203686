import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { debounce } from "../../fp";
import { formatDate, formatTimeAmPm } from "../../utils";

const PunchList = ({ punches, total, generalSettings }) => {
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  useEffect(() => {
    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);

    findDivHeight();

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, []);

  const findDivHeight = () => {
    const usedSpace = 290;
    const remaining = window.innerHeight - usedSpace;

    const widget = document.getElementById("punch-list");
    widget.style.height = remaining + "px";
    widget.style.overflow = "auto";
  };

  return (
    <div className="m-punchlist" id="punch-list">
      {punches.length === 0 ? (
        <div className="m-empty-punches">There are no punches to display.</div>
      ) : (
        <table className="table table-striped punch-list-table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Type</th>
              <th>In</th>
              <th>Out</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {punches.map((punch, index) => (
              <tr key={`p-${index}`}>
                <td>{formatDate(punch.punchDate)}</td>
                <td>{punch.punchType}</td>
                <td>
                  {generalSettings.showRawPunches
                    ? formatTimeAmPm(punch.rawPunchIn)
                    : formatTimeAmPm(punch.punchIn)}
                </td>
                <td>
                  {generalSettings.showRawPunhes
                    ? formatTimeAmPm(punch.rawPunchOut)
                    : formatTimeAmPm(punch.punchOut)}
                </td>
                <td>{punch.total}</td>
              </tr>
            ))}
            <tr>
              <td colSpan="4" className="m-total-hours-text">
                Total:
              </td>
              <td className="m_total_hours">
                {parseFloat(total).toFixed(generalSettings.timePrecision)}
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
};

PunchList.propTypes = {
  punches: PropTypes.array,
  total: PropTypes.any,
  generalSettings: PropTypes.object
};

export default PunchList;
