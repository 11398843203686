import React from "react";
import PropTypes from "prop-types";
import {
  convertConcern,
  convertColor,
  generateScore,
  generateIcon
} from "./ChromeCart-I";

const DesktopChromeCart = ({ store, index, handleClick }) => {
  return (
    <div
      key={index}
      className="d-inline col-sm-6 col-md-4 col-lg-3 cc-d-store m-3"
      onClick={() => handleClick(store)}
    >
      <div>
        <div className="cc-d-name">{store.store_Name}</div>
      </div>
      <div className="cc-d-score">
        <div className="cc-d-score-m-label">
          Score:
          <span
            className="cc-d-m-score"
            style={{
              backgroundColor: convertColor(store.score)
            }}
          >{`${store.score}`}</span>
        </div>
      </div>

      <div
        className={`cc-d-header-m ${convertConcern(store.score, store.purple)}`}
      >
        <i className={generateIcon(store.score, store.purple)}></i>
      </div>
      <div className="cc-d-footer">
        <div className="cc-footer-concern">{`Concern: ${generateScore(
          store.score
        )}`}</div>
        <div className="cc-footer-purple">{`Outliers: ${store.purple}`}</div>
      </div>
    </div>
  );
};

DesktopChromeCart.propTypes = {
  store: PropTypes.object,
  index: PropTypes.number,
  handleClick: PropTypes.func
};

export default DesktopChromeCart;
