import axios from "axios";
import qs from "qs";
import { encrypt } from "../utils";

async function signin(username, password, context, impersonate) {
  const encryptedUsername = encrypt(username, context.key).toString();
  const encryptedPassword = encrypt(password, context.key).toString();
  let json = await axios({
    method: "POST",
    url: context.url + "auth/reacttoken",
    cors: true,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    data: qs.stringify({
      username: encryptedUsername,
      password: encryptedPassword,
      Ov: impersonate ? 1 : 0
    })
  });

  return json;
}

export { signin };
