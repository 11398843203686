import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { formatDate, convertAmount, handleCsv } from "../../utils";
import CashierNavigation from "../CashierNavigation";
import { debounce } from "../../fp";
import { Row, Col } from "reactstrap";
import Tooltip from "../../Tooltip/Tooltip";
import "./DesktopTransactions.css";

const DesktopTransactions = ({ data, context }) => {
  const [redirect, setRedirect] = useState(false);
  const [goBack, setGoBack] = useState(false);
  const [step, setStep] = useState(2);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  useEffect(() => {
    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);

    findDivHeight();

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, [data]);

  const findDivHeight = () => {
    const navbar = document
      .getElementById("master-navbar")
      .getBoundingClientRect().height;
    const controls = document
      .getElementById("d-trans-controls")
      .getBoundingClientRect().height;
    const spaceUsed = navbar + controls + 20;
    const remaining = window.innerHeight - spaceUsed;

    const widgets = document.getElementById("d-trans-body");
    widgets.style.height = remaining + "px";
    widgets.style.overflowY = "auto";
    widgets.style.overflowX = "hidden";
  };

  const handleGoback = (currentStep) => {
    setStep(currentStep);
    setGoBack(true);
  };

  const handleClick = (trans) => {
    context.transactionDetails = trans;
    setRedirect(true);
  };

  const handleExportClick = () => {
    debugger;
    handleCsv(data, "transactions.csv");
  };
  return (
    <div className="d-trans-body">
      {redirect && <Redirect to="/transaction" />}
      {goBack && step === 2 && <Redirect to="/totalizers" />}
      {goBack && step === 1 && <Redirect to="/flyout" />}
      {goBack && step === 0 && <Redirect to="/cashiers" />}

      <div className="d-trans-wrapper">
        <div className="d-trans-controls" id="d-trans-controls">
          <div className="d-trans-store">
            {context.selectedRecord.store_Name}
          </div>
          <div className="d-trans-info">
            <div className="d-trans-date">
              Transaction Date: {formatDate(context.totalizer.post_Date)}
            </div>
            <div className="d-trans-totalizer">{context.totalizer.f1039}</div>
            <div className="d-trans-cashier">{context.cashier.cashier}</div>
          </div>

          <CashierNavigation step="2" goBack={handleGoback} />

          <div className="row">
            <div className="col-6">
              <div className="d-trans-h3">Transaction List</div>
            </div>
            <div className="col-6 d-flex justify-content-end">
              <div
                className="transactions-csv-export"
                style={{
                  fontSize: "1.3rem",
                  fontWeight: "bold",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center"
                }}
                onClick={handleExportClick}
              >
                <Tooltip
                  message={"Export data to CSV File"}
                  position={"bottom"}
                >
                  <i className="fas fa-download"></i>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>

        <div id="d-trans-body">
          <Row className="d-trans-row">
            {data.map((trans, index) => (
              <Col
                xs="4"
                className="d-trans-trans"
                key={index}
                onClick={() => handleClick(trans)}
              >
                <div className="d-trans-tlz-name">
                  {context.totalizer.f1039}
                </div>
                <div className="d-trans-trans-info">
                  <div className="d-trans-one">
                    <div className="d-trans-trans-number">
                      Trans #: {trans.f1032}
                    </div>
                    <div className="d-trans-tlz-total">
                      Tlz Total: {convertAmount(trans.tlzTotal)}
                    </div>
                  </div>
                  <div className="d-trans-itemcount">
                    {trans.itemCount} items
                  </div>
                  <div className="d-trans-three">
                    <div className="d-trans-time">
                      Time: {trans.transactionTime}
                    </div>
                    <div className="d-trans-term">Term: {trans.f1057}</div>
                  </div>
                </div>

                <div className="d-trans-trans-total">
                  Trans Total: ${convertAmount(trans.total)}
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </div>
  );
};

DesktopTransactions.propTypes = {
  data: PropTypes.array,
  context: PropTypes.object
};

export default DesktopTransactions;
