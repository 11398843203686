import React, { useState, useEffect, useContext, useReducer } from "react";
import HeaderRb from "../../../common/HeaderRb";
import { toast } from "react-toastify";
import { ContextConfig } from "../../../App";
import { isValid, addDays } from "../../../utils";
import { getTransactions, getCustomerHistory } from "../../../api/PayServerApi";
import { getStoreNumberById } from "../../../api/ReportsApi";
import Spinner from "../../../common/Spinner";
import { debounce } from "../../../fp";
import ScrollDivToTop from "../../../common/ScrollDivToTop";
import { tableReducer, formatTotalizer } from "./PayServerTransactions-Reducer";
import { MobileView, BrowserView, isMobile } from "react-device-detect";
import PayserverCustomerHistory from "./PayServerCustomerHistory";
import PayserverTransactionsControls from "./PayserverTransactionsControls";
import PayserverTransactionsTable from "./PayserverTransactionsTable";
import MobilePayserverTransactionsControls from "./MobilePayserverTransactionControls";
import MobilePayserverTransactionsTable from "./MobilePayserverTransactionsTable";

import "./PayserverTransactions.css";

const initialTableState = {
  sortF64Asc: true,
  sortF254Asc: true,
  sortF1032Asc: true,
  sortF1034Asc: true,
  sortF1056Asc: true,
  sortF1148Asc: true,
  sortF1057Asc: true,
  sortF253Asc: true,
  sortField: null,
  filterF64: false,
  filterF254: false,
  filterF1032: false,
  filterF1034: false,
  filterF1056: false,
  filterF1148: false,
  filterF1057: false,
  filterValue: "",
  filterField: null,
  filterF253: false,
  sortDataType: "string",
  startDate: new Date(),
  endDate: new Date(),
  stores: [],
  storeid: 0,
  storenumber: 0,
  customer: 0
};

const PayserverTransactions = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [showHistory, setShowHistory] = useState(false);
  const [currentCustomerNumber, setCurrentCustomerNumber] = useState("");
  const [customerHistory, setCustomerHistory] = useState([]);
  const [mousePos, setMousePos] = useState({
    clientX: 0,
    clientY: 0
  });
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });
  const [state, dispatch] = useReducer(tableReducer, initialTableState);

  const context = useContext(ContextConfig);

  useEffect(() => {
    getStoreNumber(context.lastStoreid);

    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);

    findDivHeight();

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, []);

  useEffect(() => {
    if (state.sortField === null) return;
    sortRecords(state.sortField);
  }, [state]);

  useEffect(() => {
    filterRecords();
  }, [state.filterValue]);

  useEffect(() => {
    loadData(state.storenumber);
  }, [state.storenumber, state.startDate, state.endDate]);

  const findDivHeight = () => {
    const navbar = document
      .getElementById("master-navbar")
      .getBoundingClientRect().height;
    const controls = document
      .getElementById("r-controls")
      .getBoundingClientRect().height;
    const usedSpace = navbar + controls + 30;
    const remaining = window.innerHeight - usedSpace;

    const body = document.getElementById("r-body");
    body.style.height = remaining + "px";
    body.style.overflow = "auto";
  };

  const loadData = (storenumber) => {
    setIsLoading(true);
    if (!isValid(context.startDate)) {
      context.startDate = addDays(new Date(), -7);
    }

    let customer = state.customer;
    if (customer.length === 0) {
      customer = "0";
    }

    getTransactions(
      context.psanalysis,
      storenumber,
      state.startDate,
      state.endDate,
      "0",
      customer,
      context.apikey
    )
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          setData(j.items);
          setFilteredData(j.items);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const getStoreNumber = (storeid) => {
    setIsLoading(true);
    getStoreNumberById(context.url, context.token, storeid)
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          loadData(j.storenumber);
          dispatch({
            type: "storeid",
            storeid: context.lastStoreid,
            context: context
          });
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const getSortField = () => {
    switch (state.sortField) {
      case "f1056":
        return state.sortF1056Asc;
      case "f1148":
        return state.sortF1148Asc;
      case "f1032":
        return state.sortF1032Asc;
      case "f254":
        return state.sortF254Asc;
      case "f1057":
        return state.sortF1057Asc;
      case "f1034":
        return state.sortF1034Asc;
      case "f64":
        return state.sortF64Asc;
      default:
        return null;
    }
  };

  const compare = (a, b) => {
    let fieldA = a[state.sortField].toUpperCase();
    let fieldB = b[state.sortField].toUpperCase();

    if (state.sortDataType == "number") {
      fieldA = parseFloat(a[state.sortField]);
      fieldB = parseFloat(b[state.sortField]);
    }

    let comparison = 0;
    if (fieldA > fieldB) {
      comparison = 1;
    } else if (fieldA < fieldB) {
      comparison = -1;
    }

    if (getSortField()) {
      return comparison * -1;
    } else {
      return comparison;
    }
  };

  const sortRecords = () => {
    setIsLoading(true);
    setFilteredData([...data].sort(compare));
    setIsLoading(false);
  };

  const filterRecords = () => {
    setIsLoading(true);
    if (state.filterValue.length == 0) {
      setFilteredData(data);
      setIsLoading(false);
      return;
    }
    const newArray = [...data].filter((r) =>
      r[state.filterField]
        .toUpperCase()
        .includes(state.filterValue.toUpperCase())
    );
    setFilteredData(newArray);
    setIsLoading(false);
  };

  const fetchCustomerHistory = (customer) => {
    setIsLoading(true);
    getCustomerHistory(context.psanalysis, customer, context.apikey)
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          setCustomerHistory(j.items);
        } else {
          setCustomerHistory([]);
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setCustomerHistory([]);
        toast.error("An error occured getting the customers history", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const handleCustomerHistory = (e, record) => {
    fetchCustomerHistory(record.f1148);
    if (isMobile) {
      setMousePos({ clientX: 0, clientY: 0 });
    } else {
      setMousePos({ clientX: e.clientX, clientY: e.clientY });
    }
    setCurrentCustomerNumber(record.f1148);
    setShowHistory(true);
  };

  const hideCustomerHistory = () => {
    setShowHistory(false);
  };

  const handleSearchClick = () => {
    console.log("search clicked");
    loadData(state.storenumber);
  };

  return (
    <React.Fragment>
      {isLoading && <Spinner />}
      {showHistory && (
        <PayserverCustomerHistory
          isShowing={showHistory}
          hide={hideCustomerHistory}
          currentCustomer={currentCustomerNumber}
          mousePos={mousePos}
          customerHistory={customerHistory}
          formatTotalizer={formatTotalizer}
        />
      )}
      <HeaderRb />
      <div className="container-fluid" id="redemption">
        <BrowserView>
          <PayserverTransactionsControls
            data={data}
            context={context}
            state={state}
            dispatch={dispatch}
            handleSearchClick={handleSearchClick}
          />
        </BrowserView>
        <MobileView>
          <MobilePayserverTransactionsControls
            data={data}
            context={context}
            state={state}
            dispatch={dispatch}
            handleSearchClick={handleSearchClick}
          />
        </MobileView>

        <div id="r-body" style={{ marginTop: "20px" }}>
          <BrowserView>
            <PayserverTransactionsTable
              filteredData={filteredData}
              dispatch={dispatch}
              state={state}
              handleCustomerHistory={handleCustomerHistory}
            />
          </BrowserView>
          <MobileView>
            <MobilePayserverTransactionsTable
              filteredData={filteredData}
              dispatch={dispatch}
              state={state}
              handleCustomerHistory={handleCustomerHistory}
            />
          </MobileView>

          <ScrollDivToTop id={"r-body"} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default PayserverTransactions;
