import React, { useState, useEffect, useContext } from "react";
import { createPortal } from "react-dom";
import TypeAhead from "../../PayServer/Analysis/TypeAhead";
import { singleToDate } from "../../api/ReportsApi";
import { ContextConfig } from "../../App";
import { toast } from "react-toastify";
import { formatDate, handleCsv } from "../../utils";
import Spinner from "../../common/Spinner";
import { readGroups } from "../../api/GroupApi";
import DatePicker from "react-datepicker";
import ReactTooltip from "react-tooltip";
import "./UnitsSold.css";

const UnitsSold = ({ isShowing, hide }) => {
  const [storeGroups, setStoreGroups] = useState([]);
  const [groups, setGroups] = useState([]);
  const [sales, setSales] = useState([]);
  const [date, setDate] = useState(new Date());
  const context = useContext(ContextConfig);
  const [selectedGroup, setSelectedGroup] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedStoreGroup, setSelectedStoreGroup] = useState(
    context.lastGroup
  );
  const [filteredSales, setFilteredSales] = useState([]);

  useEffect(() => {
    loadGroups();
  }, []);

  useEffect(() => {
    if (selectedStoreGroup && date) {
      loadSales();
      setSelectedStoreGroup(parseInt(context.lastGroup));
    }
  }, [selectedStoreGroup, date]);

  const loadGroups = () => {
    readGroups(
      context.url,
      context.token,
      (response) => {
        const j = response.data;
        if (j.error == "0") {
          setStoreGroups(j.groups);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      },
      (err) => {
        toast.error(err.message, {
          position: toast.POSITION.TOP_LEFT
        });
      }
    );
  };

  const handleStoreGroupSelection = (selection) => {
    context.lastGroup = selection.id;
    setSelectedStoreGroup(parseInt(selection.id));
  };

  const loadSales = () => {
    setIsLoading(true);
    singleToDate(
      context.url,
      context.token,
      formatDate(date),
      selectedStoreGroup
    )
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error == "0") {
          setGroups(j.groups);
          setSales(j.sales);
          setFilteredSales(j.sales);
        } else {
          setSales([]);
          setGroups([]);
          toast.warning(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setSales([]);
        setGroups([]);
        toast.error(err.message, {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const handleDeptGroupChange = (g) => {
    setSelectedGroup(g.target.value);
    if (g.target.value == "0") {
      setFilteredSales(sales);
    } else {
      const tempSales = sales.filter((s) => s.f1132 == g.target.value);
      setFilteredSales(tempSales);
    }
  };

  if (isShowing) {
    return createPortal(
      <React.Fragment>
        {isLoading && <Spinner />}
        <div className="us-overlay">
          <div
            className="us-wrapper"
            aria-modal
            aria-hidden
            tabIndex={-1}
            role="dialog"
          >
            <div className="us-content">
              <ReactTooltip />
              <div className="us-header">
                <span className="us-title">Units Sold</span>
                <span className="us-close-box" data-tip="Close">
                  <button
                    type="button"
                    className="us-close-button"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={hide}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </span>
              </div>
              <div className="us-body">
                <ReactTooltip />
                <div className="row us-title-bar">
                  <div className="us-descrip col-6 d-flex justify-content-between">
                    <span>Units Sold</span>
                    <span
                      style={{ cursor: "pointer" }}
                      data-tip="Save as CSV"
                      onClick={() => handleCsv(filteredSales, "unitsSold.csv")}
                    >
                      <i className="far fa-save"></i>
                    </span>
                  </div>
                  {selectedStoreGroup && date ? (
                    <div className="col-6">
                      <select
                        className="form-control"
                        value={selectedGroup}
                        onChange={(g) => handleDeptGroupChange(g)}
                      >
                        <option value="0">All Groups</option>
                        {groups && groups.length > 0 ? (
                          <React.Fragment>
                            {groups.map((g, i) => (
                              <option key={`sg-${i}`} value={g.f1132}>
                                {g.f1132}
                              </option>
                            ))}
                          </React.Fragment>
                        ) : null}
                      </select>
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  <div className="col-6">
                    <TypeAhead
                      placeholder={"Select Group"}
                      value={selectedStoreGroup}
                      data={storeGroups}
                      keyValue="id"
                      displayValue="group_name"
                      setSelection={handleStoreGroupSelection}
                    />
                  </div>
                  <div className="col-6">
                    <DatePicker selected={date} onChange={setDate} />
                  </div>
                </div>
                <div className="row us-report-contents">
                  {filteredSales && filteredSales.length > 0 ? (
                    <table className="rc-table">
                      <thead>
                        <tr className="us-table-header">
                          <th>#</th>
                          <th>Name</th>
                          <th>Group</th>
                          <th style={{ textAlign: "right" }}>TY</th>
                          <th style={{ textAlign: "right" }}>LY</th>
                          <th style={{ textAlign: "right" }}>Diff</th>
                          <th style={{ textAlign: "center" }}>Dif %</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredSales.map((s, i) => (
                          <tr key={`dr-${i}`}>
                            <td>{s.storeNumber}</td>
                            <td>{s.storeName}</td>
                            <th>{s.f1132}</th>
                            <td
                              className={
                                +s.f64 > +s.lastYear ? "tr-green" : "tr-red"
                              }
                            >
                              {s.f64}
                            </td>
                            <td
                              className={
                                +s.f64 > +s.lastYear ? "tr-red" : "tr-green"
                              }
                            >
                              {s.lastYear}
                            </td>
                            <td
                              className={
                                +s.difference >= 0 ? "tr-green" : "tr-red"
                              }
                            >
                              {s.difference}
                            </td>
                            <td
                              className={
                                +s.diffPerc >= 0
                                  ? "tr-green-center"
                                  : "tr-red-center"
                              }
                            >
                              {s.diffPerc}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>,
      document.body
    );
  } else {
    return null;
  }
};

export default UnitsSold;
