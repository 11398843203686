import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import PropTypes from "prop-types";

const OpenOrderWidget = ({ freshopState }) => {
  return (
    <div>
      <h5 className="error-item-title">Open Orders</h5>
      <Row className="justify-content-center align-items-center">
        {freshopState.unclosedOrders.map((order, index) => (
          <Col lg="6" md="12" key={`uos-${index}`} className="freshop-fade-in">
            <Card className="card-unclosed-freshop-order">
              <CardBody>
                <Row>
                  <Col xs="4">
                    <div className="card-unclosed-freshop-order-id">
                      Id: {order.id}
                    </div>
                  </Col>
                  <Col xs="4">
                    <div>Order Id: {order.orderid}</div>
                  </Col>
                  <Col xs="4">
                    <div>Fulfillment: {order.fulfillment}</div>
                  </Col>
                </Row>
                <Row>
                  <Col xs="6">
                    <div>Customer: {order.customer}</div>
                  </Col>
                  <Col xs="6">
                    <div>Payment Type: {order.paymenttype}</div>
                  </Col>
                </Row>
                <Row>
                  <Col xs="6">
                    <div>Status: {order.status}</div>
                  </Col>
                  <Col xs="6">
                    <div>Items: {order.itemcount}</div>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <div>Received: {order.received}</div>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <div style={{ textAlign: "right", marginRight: "10px" }}>
                      Estimated Total: {order.estimatedTotal}
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

OpenOrderWidget.propTypes = {
  freshopState: PropTypes.object
};

export default OpenOrderWidget;
