import React, { useEffect } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import ClockMobile from "./ClockMobile";
import ClockDesktop from "./ClockDesktop";
import { useDispatch } from "react-redux";
import * as actions from "../actions/actionTypes";

import "./Clock.css";

const Clock = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: actions.LOGIN_SET_TO_HOME, toHome: false });
    Date.prototype.stdTimezoneOffset = function() {
      var jan = new Date(this.getFullYear(), 0, 1);
      var jul = new Date(this.getFullYear(), 6, 1);
      return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
    };

    Date.prototype.isDstObserved = function() {
      return this.getTimezoneOffset() < this.stdTimezoneOffset();
    };
  }, []);
  return (
    <div className="clock-body">
      <BrowserView>
        <ClockDesktop />
      </BrowserView>
      <MobileView>
        <ClockMobile />
      </MobileView>
    </div>
  );
};

export default Clock;
