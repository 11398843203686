import * as actions from "../actions/actionTypes";
import { addDays } from "../utils";

const initialState = {
  isLoading: false,
  isLoadingGroups: false,
  isLoadingStores: false,
  assignedStores: [],
  currentStore: "",
  itemsets: [],
  filteredItemsets: [],
  pagedItemsets: [],
  currentPage: 0,
  recordsPerPage: 50,
  pagingOptions: {
    start: 0,
    end: 50
  },
  search: "",
  startDate: addDays(new Date(), -7),
  endDate: new Date(),
  isSearching: false,
  searchResults: []
};

const itemsetsReducer = (state = initialState, action) => {
  let newEnd = 0;

  switch (action.type) {
    case actions.IS_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading
      };
    case actions.IS_IS_LOADING_GROUPS:
      return {
        ...state,
        isLoadingGroups: action.isLoading
      };
    case actions.IS_IS_LOADING_STORES:
      return {
        ...state,
        isLoadingStores: action.isLoading
      };
    case actions.IS_ASSIGNED_STORES:
      return {
        ...state,
        assignedStores: action.stores
      };
    case actions.IS_SET_CURRENT_STORE:
      return {
        ...state,
        currentStore: action.store
      };
    case actions.IS_SET_ITEMSETS:
      return {
        ...state,
        itemsets: action.itemsets
      };
    case actions.IS_SET_PAGED_ITEMSETS:
      return {
        ...state,
        filteredItemsets: action.itemsets
      };
    case actions.IS_SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.currentPage
      };
    case actions.IS_SET_RECORDS_PER_PAGE:
      return {
        ...state,
        recordsPerPage: action.recordsPerPage
      };
    case actions.IS_SET_PAGING_OPTIONS:
      return {
        ...state,
        pagingOptions: action.pagingOptions
      };
    case actions.IS_SET_SEARCH:
      return {
        ...state,
        search: action.search
      };
    case actions.IS_SET_START_DATE:
      return {
        ...state,
        startDate: action.startDate
      };
    case actions.IS_SET_END_DATE:
      return {
        ...state,
        endDate: action.endDate
      };
    case actions.IS_SET_IS_SEARCHING:
      if (!action.isSearching) {
        state.pagingOptions.end = state.recordsPerPage;
      }
      return {
        ...state,
        isSearching: action.isSearching,
        currentPage: 0
      };
    case actions.IS_SET_SEARCH_RESULTS:
      if (state.pagingOptions.end > action.itemsets.length) {
        newEnd = action.itemsets.length;
      }

      return {
        ...state,
        searchResults: action.itemsets,
        pagingOptions: {
          start: 0,
          end: newEnd > 0 ? newEnd : state.pagingOptions.end
        },
        currentPage: 0
      };
    default:
      return state;
  }
};

export default itemsetsReducer;
