import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { formatDate } from "../../../utils";
import { readEmpPunches } from "../../../api/timeApi";
import { ContextConfig } from "../../../App";

const EmployeePunches = ({ cashier, setIsLoading }) => {
  const [empPunches, setEmpPunches] = useState([]);

  const context = useContext(ContextConfig);

  useEffect(() => {
    if (cashier.f1185) loadPunches();
  }, [cashier]);

  const loadPunches = () => {
    setIsLoading(true);
    readEmpPunches(
      context.url,
      context.token,
      context.lastStoreid,
      cashier.f1185,
      cashier.f254
    )
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          setEmpPunches(j.punches);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };
  return (
    <div>
      {empPunches.length === 0 ? (
        <div>No records to show</div>
      ) : (
        <table
          className="table table-striped"
          style={{ width: "90%", margin: "auto" }}
        >
          <thead>
            <tr className="tl-l-header">
              <th>In Time</th>
              <th>Out Time</th>
              <th>Type</th>
              <th>Hours</th>
              <th>Adjustments</th>
            </tr>
          </thead>
          <tbody>
            {empPunches.map((record, index) => (
              <tr key={`tl-r-${index}`}>
                <td>{formatDate(record.f1820)}</td>
                <td>{formatDate(record.f1821)}</td>
                <td>{record.f1068}</td>
                <td>{record.f1340.toFixed(1)}</td>
                <td>{record.f1404}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

EmployeePunches.propTypes = {
  cashier: PropTypes.object,
  setIsLoading: PropTypes.func
};

export default EmployeePunches;
