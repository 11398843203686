import React from "react";
import ReactDOM from "react-dom";
import "./ConfirmDelete.css";

const ConfirmDelete = ({
  isShowing,
  hide,
  confirmText,
  setConfirmText,
  compareDeleteText,
  error,
  title = "Confirm Delete",
  instructionsText = "delete me",
  moreInfo = ""
}) =>
  isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <div className="confirm-delete-overlay" />
          <div
            className="confirm-delete-wrapper"
            aria-modal
            aria-hidden
            tabIndex={-1}
            role="dialog"
          >
            <div className="confirm-delete">
              <div className="confirm-delete-header">
                <span className="confirm-delete-title">{title}</span>
                <span className="confirm-delete-close-box">
                  <button
                    type="button"
                    className="confirm-delete-close-button"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={hide}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </span>
              </div>
              <div className="confirm-delete-body">
                <p>
                  Type{" "}
                  <i>
                    <b>{instructionsText}</b>
                  </i>{" "}
                  in the box below to confirm.
                </p>
                {moreInfo && moreInfo.length > 0 ? <div>{moreInfo}</div> : null}
                <div>
                  <div className="form-group">
                    <label htmlFor="txtDeleteConfirm">{title}</label>
                    <input
                      type="text"
                      className="form-control mb-3"
                      id="txtDeleteConfirm"
                      value={confirmText}
                      onChange={setConfirmText}
                    />
                    {error.length > 0 && (
                      <div className="alert alert-danger">{error}</div>
                    )}
                    <div className="confirm-delete-buttons">
                      <button className="btn btn-outline-dark" onClick={hide}>
                        Cancel
                      </button>
                      <button
                        className="btn btn-outline-dark"
                        onClick={compareDeleteText}
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>,
        document.body
      )
    : null;

export default ConfirmDelete;
