import React, { useState, useEffect, useRef, useContext } from "react";
import PropTypes from "prop-types";
import Pickers from "./Pickers";
import { ContextConfig } from "../App";
import { addDays, formatDate } from "../utils";
import "./mobileHeader.css";

const MobileHeader = ({
  endDate,
  reRender,
  setReRender,
  setStartDate,
  setEndDate,
  showSelection,
  pickerData,
  groupData
}) => {
  const [showDate, setShowDate] = useState(false);
  const [isShowing, setIsShowing] = useState(false); // this is for the stores/groups picker
  const [showGroups, setShowGroups] = useState(false);

  const context = useContext(ContextConfig);

  useEffect(() => {
    if (showSelection === true) {
      setIsShowing(true);
      return;
    }
  }, []);

  const handleShowDate = () => {
    setShowDate(!showDate);
  };

  const handleDateCancel = () => {
    setShowDate(false);
  };

  const handleDateSelect = (time) => {
    context.endDate = time;
    context.startDate = addDays(time, -7);
    setEndDate(context.endDate);
    setStartDate(context.startDate);
    setShowDate(false);
    setReRender(!reRender);
  };

  const handleShowSelection = () => {
    setIsShowing(!isShowing);
  };

  const handleSelection = (e) => {
    context.lastSearchType = parseInt(e.id);
    if (parseInt(e.id) === 1) {
      setReRender(!reRender);
    } else {
      // we need to display the list of groups to pick from
      setShowGroups(true);
    }
  };

  const handleGroupSelect = (e) => {
    context.lastSearchType = 2;
    context.lastGroup = e.id;
    context.lastGroupName = e.group_name;
    setReRender(!reRender);
  };

  const handleToggleGroups = () => {
    setShowGroups(!showGroups);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col header-selection-btn">
          <button
            type="button"
            className="btn btn-outline-dark"
            onClick={handleShowSelection}
          >
            <i className="far fa-users"></i>
          </button>
        </div>
        <div>
          <div className="mobile-header-selection">
            {context.lastSearchType === 1 || context.lastSearchType === "1"
              ? "Stores"
              : context.lastGroupName}
          </div>
          <div className="mobile-header-date">
            {formatDate(context.endDate)}
          </div>
        </div>
        <div className="col ml-auto header-date-btn">
          <button
            className="btn btn-outline-dark"
            type="button"
            onClick={handleShowDate}
          >
            <i className="far fa-calendar-alt"></i>
          </button>
        </div>
      </div>
      <Pickers
        time={endDate}
        useState={useState}
        useEffect={useEffect}
        useRef={useRef}
        showDate={showDate}
        handleDateCancel={handleDateCancel}
        handleDateSelect={handleDateSelect}
        isShowing={isShowing}
        pickerData={pickerData}
        handleToggleSelectionType={handleShowSelection}
        handleTypeSelect={handleSelection}
        showGroups={showGroups}
        groupData={groupData}
        handleGroupSelect={handleGroupSelect}
        handleToggleGroups={handleToggleGroups}
      />
    </div>
  );
};
MobileHeader.propTypes = {
  handleShowDate: PropTypes.func,
  handleShowGroups: PropTypes.func,
  endDate: PropTypes.any,
  reRender: PropTypes.bool,
  setReRender: PropTypes.func,
  setStartDate: PropTypes.func,
  setEndDate: PropTypes.func,
  showSelection: PropTypes.bool,
  pickerData: PropTypes.array,
  groupData: PropTypes.array
};

export default MobileHeader;
