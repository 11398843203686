import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import "./react-datepicker.css";
import { ContextConfig } from "../App";
//import { Typeahead } from "react-bootstrap-typeahead";
import TypeAheadDropDown from "../common/TypeAheadDropDown";

const DesktopHeader = ({
  pickerData,
  groupData,
  reRender,
  setReRender,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  setGroup,
  lastSearchType,
  assignedStores
}) => {
  const [showGroups, setShowGroups] = useState(false);
  const [searchType, setSearchType] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [filteredStores, setFilteredStores] = useState([]);
  const context = useContext(ContextConfig);

  useEffect(() => {
    setFilteredStores(assignedStores);
  }, []);

  useEffect(() => {
    setSearchType(lastSearchType);
  }, [searchType, showGroups, reRender]);

  const handleSelectionChange = (e) => {
    if (parseInt(e.target.value) === 2) {
      context.lastSearchType = 2;
      setSearchType(2);
      setShowGroups(true);
    } else if (parseInt(e.target.value) === 3) {
      context.lastSearchType = 3;
      setSearchType(3);
      setShowGroups(true);
      lastSearchType = 3;
    } else {
      context.lastSearchType = 1;
      setShowGroups(false);
    }
    setReRender(!reRender);
  };

  const handleGroupChange = (e) => {
    setGroup(e.target.value);
    context.lastSearchType = 2;
    context.lastGroup = e.target.value;
    setReRender(!reRender);
  };

  const handleStoreChange = (e) => {
    context.lastStoreid = e.storeid;
    context.lastSearchType = 3;
    setReRender(!reRender);
  };

  const handleStartDateChange = (e) => {
    context.startDate = e;
    setStartDate(context.startDate);
    setReRender(!reRender);
  };

  const handleEndDateChange = (e) => {
    context.endDate = e;
    setEndDate(context.endDate);
    setReRender(!reRender);
  };

  return (
    <form className="form-inline" style={{ margin: "auto" }}>
      <div className="form-group row w-75 justify-context-center m-auto">
        <div className="col-xl-3 col-lg-4 col-sm-6 align-items-center justify-content-center d-flex mt-1">
          <span style={{ marginRight: "10px" }}>Selection Type:</span>

          <select
            className="form-control"
            id="comboSelectionType"
            value={context.lastSearchType}
            onChange={handleSelectionChange}
          >
            {pickerData.map((record) => (
              <option key={record.id} value={record.id}>
                {record.name}
              </option>
            ))}
          </select>
        </div>

        {context.lastSearchType == 1 ? (
          <div className="col-xl-3 col-lg-4 col-sm-6 align-items-center justify-content-center d-flex mt-1">
            &nbsp;
          </div>
        ) : null}

        {context.lastSearchType == 2 ? (
          <div className="col-xl-3 col-lg-4 col-sm-6 align-items-center justify-content-center d-flex mt-1">
            <div>
              <span style={{ marginRight: "10px" }}>Group:</span>
              <select
                id="comboGroup"
                className="form-control"
                onChange={handleGroupChange}
                value={context.lastGroup}
              >
                {groupData.length === 0
                  ? null
                  : groupData.map((group) => (
                      <option key={group.id} value={group.id}>
                        {group.group_name}
                      </option>
                    ))}
              </select>
            </div>
          </div>
        ) : null}

        {context.lastSearchType == 3 ? (
          <div className="col-xl-3 col-lg-4 col-sm-6 align-items-center justify-content-center d-flex mt-1">
            <div>
              <TypeAheadDropDown
                data={assignedStores}
                keyValue={"storeid"}
                displayValue={"store_Name"}
                value={parseInt(context.lastStoreid)}
                setSelection={handleStoreChange}
                label="Store"
              />
            </div>
          </div>
        ) : null}

        <div className="col-xl-3 col-lg-4 col-sm-6 align-items-center  justify-content-center d-flex mt-1">
          <span style={{ marginRight: "10px" }}>Start:</span>
          <DatePicker selected={startDate} onChange={handleStartDateChange} />
        </div>
        <div className="col-xl-3 col-lg-4 col-sm-6 align-items-center justify-content-center d-flex mt-1">
          <span style={{ marginRight: "10px" }}>End:</span>
          <DatePicker selected={endDate} onChange={handleEndDateChange} />
        </div>
      </div>
    </form>
  );
};

DesktopHeader.propTypes = {
  pickerData: PropTypes.array,
  groupData: PropTypes.array,
  reRender: PropTypes.bool,
  setReRender: PropTypes.func,
  startDate: PropTypes.any,
  endDate: PropTypes.any,
  setStartDate: PropTypes.func,
  setEndDate: PropTypes.func,
  setGroup: PropTypes.func,
  lastSearchType: PropTypes.number,
  assignedStores: PropTypes.array
};

export default DesktopHeader;
