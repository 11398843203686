import React, { useState, useEffect, useContext } from "react";
import { getTotals } from "../../api/PayServerApi";
import { ContextConfig } from "../../App";
import { toast } from "react-toastify";
import { formatMoney } from "../../utils";
import colors from "./colors";
import ErrorBoundary from "../../common/ErrorBoundary";
import "./Total.css";

const Totals = () => {
  const [totals, setTotals] = useState({});

  const context = useContext(ContextConfig);

  useEffect(() => {
    fetchTotals();
  }, []);

  const fetchTotals = () => {
    getTotals(context.psanalysis, context.apikey)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setTotals(j.totals[0]);
        } else {
          setTotals({});
          toast.error(j.msg, {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setTotals({});
        toast.error("An internal error occured", {
          position: toast.POSITION.TOP_RIGHT
        });
      });
  };

  return (
    <ErrorBoundary>
      <div
        className="container psas-totals-container"
        style={{ marginTop: "45px", textAlign: "center" }}
      >
        <div>
          <div className="psas-totals-widget">
            <div>
              <i
                style={{ color: colors.green }}
                className="fas fa-file-invoice-dollar fa-5x"
              ></i>
            </div>
            <div
              className="psas-totals-widget-description"
              style={{ color: colors.green }}
            >
              Transactions
            </div>
            <div className="psas-totals-widget-value">
              {formatMoney(totals.transactions, 0)}
            </div>
          </div>
        </div>

        <div>
          <div className="psas-totals-widget">
            <div>
              <i
                style={{ color: colors.blue }}
                className="fad fa-coins fa-5x"
              ></i>
            </div>
            <div
              className="psas-totals-widget-description"
              style={{ color: colors.blue }}
            >
              Accrued
            </div>
            <div className="psas-totals-widget-value">
              {formatMoney(totals.accrued, 0)} pts
            </div>
          </div>
        </div>

        <div>
          <div className="psas-totals-widget">
            <div>
              <i
                style={{ color: colors.purple }}
                className="fad fa-gift fa-5x"
              ></i>
            </div>
            <div
              className="psas-totals-widget-description"
              style={{ color: colors.purple }}
            >
              Redemptions
            </div>
            <div className="psas-totals-widget-value">
              {formatMoney(totals.redemptions, 0)} pts
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default Totals;
