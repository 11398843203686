import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Col, Card, CardHeader, CardFooter, CardBody } from "reactstrap";
import { usePrevious } from "../utils";
import { debounce } from "../fp";
import { assign } from "../api/StoreApi";
import { toast } from "react-toastify";
import Spinner from "../common/Spinner";
import { useSelector, useDispatch } from "react-redux";

import * as actions from "../actions/actionTypes";

const Assigned = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [storeid, setStoreid] = useState(0);
  //const [search, setSearch] = useState("");
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const state = useSelector((app) => app.settingsReducer);
  const dispatch = useDispatch();

  const previousSearch = usePrevious(state.assignedSearch);
  // const previousReRender = usePrevious(props.reRender);
  // const previousStores = usePrevious(props.stores);

  useEffect(() => {
    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);

    findDivHeight();

    window.addEventListener("resize", debounceHandleResize);

    if (parseInt(storeid) > 0) {
      assignStore();
    }

    if (state.assignedSearch != previousSearch) {
      searchStores();
    }

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, [storeid, state.assignedSearch, props.stores]);

  const drop = (e) => {
    e.preventDefault();

    const store_id = e.dataTransfer.getData("store_id");

    const store = document.getElementById(store_id);
    store.style.display = "block";
    store.style.opacity = "1";

    //document.getElementById("as-body").appendChild(store);
    //e.target.appendChild(store);

    const pos = store_id.indexOf("-");
    const storeid = store_id.substr(pos + 1);
    setStoreid(storeid);
  };

  const dragStart = (e) => {
    const target = e.target;
    target.style.opacity = "0.7";

    e.dataTransfer.setData("store_id", target.id);

    setTimeout(() => {
      target.style.display = "none";
    }, 0);
  };

  const dragOver = (e) => {
    e.preventDefault();

    e.dataTransfer.dropEffect = "move";
  };

  const dragEnter = (e) => {
    e.target.classList.add("over");
  };

  const dragLeave = (e) => {
    e.target.classList.remove("over");
  };

  const homeStoreDragOver = (e) => {
    e.stopPropagation();
  };

  const findDivHeight = () => {
    setTimeout(() => {
      // const header = document
      //   .getElementById("as-header")
      //   .getBoundingClientRect().height;
      // const footer = document
      //   .getElementById("as-footer")
      //   .getBoundingClientRect().height;
      // const cmp = document.getElementById("assigned").getBoundingClientRect()
      //   .height;
      // const usedSpace = header + footer + 10;
      // const remaining = cmp - usedSpace;

      const body = document.getElementById("as-body");
      //body.style.height = remaining + "px";
      body.style.overflowY = "auto";
    }, 250);
  };

  const assignStore = () => {
    setIsLoading(true);
    assign(props.context.url, props.context.token, props.userid, storeid)
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          setStoreid(0);
          props.setRerender(!props.reRender);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        toast.error("An error occured adding your Store to the Assingn list", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const searchStores = () => {
    if (state.assignedSearch.length === 0) {
      props.setFilteredAssigned(props.assigned);
      return;
    }
    const matches = props.assigned.filter((s) =>
      s.store_Name.toLowerCase().includes(state.assignedSearch.toLowerCase())
    );
    props.setFilteredAssigned(matches);
  };

  return (
    <Col xs="4" className="assigned" id="assigned" style={{ height: "100%" }}>
      {isLoading && <Spinner />}
      <Card style={{ height: "100%" }}>
        <CardHeader tag="h3" id="as-header" style={{ marginBottom: "20px" }}>
          <span>Assigned Stores</span>
          <input
            type="text"
            className="form-control"
            placeholder="Search Stores"
            onChange={(e) =>
              dispatch({
                type: actions.SETTINGS_SET_ASSIGNED_SEARCH,
                search: e.target.value
              })
            }
            value={state.assignedSearch}
            style={{ marginBotton: "5px" }}
          />
          <div
            className="as-drop-zone"
            onDrop={drop}
            onDragOver={dragOver}
            onDragEnter={dragEnter}
            onDragLeave={dragLeave}
          >
            Drop Store Here
          </div>
        </CardHeader>

        <CardBody className="assigned-stores" id="as-body">
          {props.stores.length > 0 ? (
            props.stores.map((store, index) => (
              <div
                key={`as-${index}`}
                className="us-store"
                draggable="true"
                onDragStart={dragStart}
                onDragOver={homeStoreDragOver}
                id={`store-${store.storeid}`}
              >
                {store.store_Name}
              </div>
            ))
          ) : (
            <div className="empty-assigned">There are no stores to display</div>
          )}
        </CardBody>
        <CardFooter id="as-footer">{props.stores.length} Stores</CardFooter>
      </Card>
    </Col>
  );
};

Assigned.propTypes = {
  stores: PropTypes.array,
  handleSearch: PropTypes.func,
  search: PropTypes.string,
  context: PropTypes.object,
  userid: PropTypes.any,
  reRender: PropTypes.bool,
  setRerender: PropTypes.func,
  setFilteredAssigned: PropTypes.func,
  assigned: PropTypes.array
};

export default Assigned;
