import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Col, Card, CardBody, CardHeader, CardFooter } from "reactstrap";
import { debounce } from "../fp";
import { usePrevious } from "../utils";
import { useSelector, useDispatch } from "react-redux";

import * as actions from "../actions/actionTypes";

const Unassigned = (props) => {
  //const [search, setSearch] = useState("");
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const state = useSelector((app) => app.settingsReducer);
  const dispatch = useDispatch();

  const previousSearch = usePrevious(state.unassignedSearch);
  //const previousReRender = usePrevious(props.reRender);
  //const previousStores = usePrevious(props.stores);

  useEffect(() => {
    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);

    findDivHeight();

    window.addEventListener("resize", debounceHandleResize);

    if (state.unassignedSearch != previousSearch) {
      searchStores();
    }

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, [state.unassignedSearch, props.stores]);

  const dragStart = (e) => {
    const target = e.target;
    target.style.opacity = "0.7";

    e.dataTransfer.setData("store_id", target.id);

    setTimeout(() => {
      target.style.display = "none";
    }, 0);
  };

  const dragOver = (e) => {
    e.stopPropagation();
  };

  const findDivHeight = () => {
    setTimeout(() => {
      // const header = document
      //   .getElementById("us-header")
      //   .getBoundingClientRect().height;
      // const footer = document
      //   .getElementById("us-footer")
      //   .getBoundingClientRect().height;
      // const cmp = document.getElementById("unassigned").getBoundingClientRect()
      //   .height;
      // const usedSpace = header + footer + 20;
      // const remaining = cmp - usedSpace;
      const body = document.getElementById("us-body");
      //body.style.height = remaining + "px";
      body.style.overflowY = "auto";
    }, 3000);
  };

  const searchStores = () => {
    if (state.unassignedSearch.length === 0) {
      props.setFilteredUnAssigned(props.unassigned);
      return;
    }
    const matches = props.unassigned.filter((s) =>
      s.store_Name.toLowerCase().includes(state.unassignedSearch.toLowerCase())
    );
    props.setFilteredUnAssigned(matches);
  };

  return (
    <Col
      xs="4"
      className="unassigned"
      id="unassigned"
      style={{ height: "100%" }}
    >
      <Card style={{ height: "100%" }}>
        <CardHeader tag="h3" id="us-header" style={{ marginBottom: "20px" }}>
          <span>Unassigned Stores</span>
          <input
            type="text"
            className="form-control"
            placeholder="Search Stores"
            onChange={(e) =>
              dispatch({
                type: actions.SETTINGS_SET_UNASSIGNED_SEARCH,
                search: e.target.value
              })
            }
            value={state.unassignedsearch}
            style={{ marginBotton: "5px" }}
          />
        </CardHeader>
        <CardBody className="unassigned-stores" id="us-body">
          <div>
            {props.stores.length > 0 ? (
              props.stores.map((store) => (
                <div
                  key={`us-${store.storeid}`}
                  className="us-store"
                  onDragStart={dragStart}
                  onDragOver={dragOver}
                  draggable="true"
                  id={`store-${store.storeid}`}
                >
                  {store.store_Name}
                </div>
              ))
            ) : (
              <div className="empty-unassigned">
                There are no stores to display
              </div>
            )}
          </div>
        </CardBody>
        <CardFooter id="us-footer">{props.stores.length} Stores</CardFooter>
      </Card>
    </Col>
  );
};

Unassigned.propTypes = {
  stores: PropTypes.array,
  handleSearch: PropTypes.func,
  search: PropTypes.string,
  reRender: PropTypes.bool,
  setFilteredUnAssigned: PropTypes.func,
  unassigned: PropTypes.array
};

export default Unassigned;
