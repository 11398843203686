import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import "./DesktopTot.css";
import { formatDate } from "../../utils";
import CashierNavigation from "../CashierNavigation";
import Tot from "./Tot";
import { debounce } from "../../fp";

const DesktopTot = ({ data, context, dates }) => {
  const [redirect, setRedirect] = useState(false);
  const [goBack, setGoBack] = useState(false);
  const [step, setStep] = useState(1);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  useEffect(() => {
    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);

    findDivHeight();

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, [data]);

  const findDivHeight = () => {
    const navbar = document
      .getElementById("master-navbar")
      .getBoundingClientRect().height;
    const controls = document
      .getElementById("desktoptot-controls")
      .getBoundingClientRect().height;
    const usedSpace = navbar + controls + 20;
    const remaining = window.innerHeight - usedSpace;
    const widgets = document.getElementById("desktoptot-body");
    widgets.style.height = remaining + "px";
    widgets.style.overflow = "auto";
  };

  const handleGoback = (currentStep) => {
    setStep(currentStep);
    setGoBack(true);
  };

  const handleClick = (totalizer) => {
    context.totalizer = totalizer;
    setRedirect(true);
  };

  const setColor = (f1034) => {
    if (f1034 == "3001") {
      return "rgba(79, 38, 237, 0.9)"; // error correct
    } else if (f1034 == "3002") {
      return "rgba(246, 72, 97, 0.9)"; // void
    } else if (f1034 == "3004") {
      return "rgba(227, 122, 36, 0.9)"; // corrections
    } else if (f1034 == "3101") {
      return "rgba(237, 51, 38, 0.9)"; // no sales
    } else if (f1034 == "9999") {
      return "rgb(62, 184, 173)"; // scan percent
    } else if (f1034 == "3011") {
      return "rgba(182, 88, 174, 0.9)"; // refund
    } else if (f1034 == "3003") {
      return "rgb(203, 45, 214)"; // cancel order
    }
  };

  const getTotalizersForDate = (date) => {
    const dateArray = data.filter((d) => d.post_Date === date);
    return dateArray;
  };

  return (
    <React.Fragment>
      {redirect && <Redirect to="/transactions" />}
      <div className="container desktop-tot">
        {goBack && step == 1 && <Redirect to="/flyout" />}
        {goBack && step == 0 && <Redirect to="/cashiers" />}

        <div className="container desktoptot-controls" id="desktoptot-controls">
          <div className="desktoptot-store">
            {context.selectedRecord.store_Name}
          </div>
          <div className="desktoptot-info">
            <div className="desktoptot-date">
              Start Date: {formatDate(context.startDate)} - EndDate:{" "}
              {formatDate(context.endDate)}
            </div>
            <div className="desktoptot-cashier">{context.cashier.cashier}</div>
          </div>

          <div className="container desktoptot-body">
            <CashierNavigation step="1" goBack={handleGoback} />
          </div>
        </div>

        <div
          className="container desktoptot-body"
          id="desktoptot-body"
          style={{ textAlign: "center" }}
        >
          {dates.length > 0 ? (
            dates.map((date, index) => (
              <div className="row" key={`d-${index}`}>
                <div className="col-12">
                  <div className="totalizer-date">
                    {formatDate(date.post_Date)}
                  </div>
                  <div className="row tot-widgets">
                    {getTotalizersForDate(date.post_Date).map((tot, i) => {
                      return (
                        <div
                          className="col-sm-3"
                          key={`tot-${i}`}
                          onClick={() => handleClick(tot)}
                        >
                          <Tot
                            tot={tot}
                            setColor={setColor}
                            f1034={tot.f1034}
                            median={tot.median}
                            f65={tot.f65}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div>There are currently no dates to display</div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

DesktopTot.propTypes = {
  data: PropTypes.array,
  context: PropTypes.object,
  dates: PropTypes.array
};

export default DesktopTot;
