import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// import { usePrevious } from "../../utils";
// import { isEqual } from "../../fp";
//import { getClockUsers } from "../../api/UsersApi";
import { readUsersFromStore } from "../../api/UsersApi";
import { toast } from "react-toastify";

const ReportUserParams = ({ context, setUserid, setUserParamsFinished }) => {
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState({});

  //const previousUsers = usePrevious(users);

  useEffect(() => {
    // if (!isEqual(previousUsers, users)) {
    //   loadUsers();
    // }
    loadUsers();
  }, [context.lastClockStore]);

  const loadUsers = () => {
    //(context.clockUrl, context.clockToken)
    readUsersFromStore(
      context.clockUrl,
      context.clockToken,
      context.lastClockStore
    )
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setUsers(j.users);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An error occured getting a list of users", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const handleChange = (e) => {
    setUser(e.target.value);
    setUserid(e.target.value);

    // lets get all the users information so we can display it on the report
    const userProfile = users.find(
      (u) => u.userid === parseInt(e.target.value)
    );
    context.reportUserData = userProfile;
  };

  const handleClick = () => {
    setUserParamsFinished(true);
  };

  return (
    <React.Fragment>
      <div className="row mt-2">
        <div
          style={{ textAlign: "center" }}
          className="col mx-3 justify-content-center align-items-center d-flex"
        >
          <select
            style={{ width: "50%" }}
            className="form-control"
            value={user}
            onChange={handleChange}
          >
            <option value="0">Select a User</option>
            {users.length === 0 ? (
              <option key={`r-s-u-o`} value="0">
                There are not users to select
              </option>
            ) : (
              <React.Fragment>
                {users.map((record, index) => (
                  <option key={`r-s-${index}`} value={record.userid}>
                    {record.firstName} {record.lastName}
                  </option>
                ))}
              </React.Fragment>
            )}
          </select>
        </div>
        <div
          style={{ textAlign: "center" }}
          className="col mx-3 justify-content-center align-items-center d-flex"
        >
          <button className="btn btn-outline-dark" onClick={handleClick}>
            Launch
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

ReportUserParams.propTypes = {
  context: PropTypes.object,
  setUserid: PropTypes.func,
  setUserParamsFinished: PropTypes.func
};

export default ReportUserParams;
