import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import { ContextConfig } from "../../App";
import { isValid, isValidDate, addDays, usePrevious } from "../../utils";
import { isEqual } from "../../fp";
import {
  getUserClockStores,
  setLastStoreid,
  getWeekStart
} from "../../api/StoreApi";
import { variables } from "../../common/variables";
import * as actions from "../../actions/actionTypes";
import { useDispatch, useSelector } from "react-redux";

const Header = ({ reRender, setReRender, tempStartDate }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [store, setStore] = useState("");
  const [stores, setStores] = useState([]);
  const [weekStart, setWeekStart] = useState();

  const context = useContext(ContextConfig);
  const previousStores = usePrevious(stores);

  // eslint-disable-next-line no-unused-vars
  const state = useSelector((app) => app.appReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isValid(tempStartDate)) {
      const sd = new Date(tempStartDate);
      setStartDate(sd);
      const tempEnd = addDays(sd, 6);
      const ed = new Date(tempEnd);
      setEndDate(ed);
    } else {
      if (isValidDate(context.startDate)) {
        setStartDate(context.startDate);
      } else {
        let sdate = new Date();
        setStartDate(addDays(sdate, -7));
      }
      if (isValidDate(context.endDate)) {
        setEndDate(context.endDate);
      }
    }
    if (!isEqual(previousStores, stores)) {
      loadStores();
    }
    if (isValid(context.lastClockStore)) {
      setStore(context.lastClockStore);
      if (!isValidDate(weekStart)) {
        fetchWeekStart();
      }
    }
  }, [stores, weekStart]);

  const handleStartDateChange = (e) => {
    context.startDate = e;
    //context.endDate = addDays(e, 6);
    setStartDate(context.startDate);
    //setEndDate(context.endDate);
    setReRender(!reRender);
    dispatch({ type: actions.APP_SET_START_DATE, startDate: e });
    dispatch({ type: actions.APP_SET_RERENDER });
  };

  const loadStores = () => {
    getUserClockStores(context.clockUrl, context.clockToken)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setStores(j.stores);
        } else {
          setStores([]);
          console.log(j.msg);
        }
      })
      .catch((err) => {
        setStores([]);
        console.log(err);
      });
  };

  const handleEndDateChange = (e) => {
    context.endDate = e;
    //context.startDate = addDays(e, -7);
    //setStartDate(context.startDate);
    setEndDate(context.endDate);
    setReRender(!reRender);
    dispatch({ type: actions.APP_SET_END_DATE, endDate: e });
    dispatch({ type: actions.APP_SET_RERENDER });
  };

  const handleStoreChange = (e) => {
    const storeid = e.target.value;
    context.lastClockStore = storeid;
    context.weekStartLoaded = false;
    setStore(storeid);
    setReRender(!reRender);
    dispatch({ type: actions.APP_SET_STORE, store: storeid });
    dispatch({ type: actions.APP_SET_RERENDER });
    setLastStoreid(context.clockUrl, context.clockToken, storeid)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          console.log(storeid + " saved as last storeid");
        } else {
          console.log(j.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchWeekStart = () => {
    getWeekStart(context.clockUrl, context.clockToken, context.lastClockStore)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          context.startDate = new Date(j.weekstart);
          context.endDate = new Date(addDays(context.startDate, 7));
          setWeekStart(context.startDate);
          context.lastBatchClose = j.lastBatchClose;
          context.weekStartLoaded = true;
        } else {
          console.log(j.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //{/* <div className="d=clock-header px-5 mt-2"> */}

  return (
    <div style={variables.desktopHeader}>
      <div className="row">
        <div className="col align-items-center justify-content-center d-flex mt-1">
          Start:&nbsp;
          <DatePicker selected={startDate} onChange={handleStartDateChange} />
        </div>
        <div className="col align-items-center justify-content-center d-flex mt-1">
          End:&nbsp;
          <DatePicker selected={endDate} onChange={handleEndDateChange} />
        </div>
        <div className="col align-items-center justify-content-center d-flex mt-1">
          <label className="px-1" htmlFor="storeselector">
            Store:
          </label>
          <select
            className="form-control"
            value={store}
            onChange={handleStoreChange}
            style={{ width: "200px" }}
          >
            {stores.length === 0 ? (
              <option key={`s-s-0`} value="0">
                No Stores to Select
              </option>
            ) : (
              <React.Fragment>
                {/* <option key={`s-1-0`} value="0">
                  All Stores
                </option> */}
                {stores.map((record, index) => (
                  <option key={`ss-${index}`} value={record.id}>
                    {record.storeName}
                  </option>
                ))}
              </React.Fragment>
            )}
          </select>
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  setReRender: PropTypes.func,
  reRender: PropTypes.bool,
  tempStartDate: PropTypes.string
};

export default Header;
