import React, { useState, useEffect, useContext } from "react";
import HeaderRB from "../../common/HeaderRb";
import { getSummary } from "../../api/PayServerApi";
// import { getAssignedStoresAsync } from "../../api/StoreApi";
import { getUserAssignedLoyaltyStores } from "../../api/StoreApi";
import { ContextConfig } from "../../App";
import Box from "./Box";
import Line from "./Line";
import StoreCustomerCount from "./StoreCustomerCount";
import Totals from "./Totals";
import Spinner from "../../common/Spinner";
import { debounce } from "../../fp";
import { toast } from "react-toastify";
import colors from "./colors";
import TypeAhead from "./TypeAhead";

const months = [
  { month: 1, name: "January" },
  { month: 2, name: "February" },
  { month: 3, name: "March" },
  { month: 4, name: "April" },
  { month: 5, name: "May" },
  { month: 6, name: "June" },
  { month: 7, name: "July" },
  { month: 8, name: "August" },
  { month: 9, name: "September" },
  { month: 10, name: "October" },
  { month: 11, name: "November" },
  { month: 12, name: "December" }
];

const Analysis = () => {
  const [summary1, setSummary1] = useState([]);
  const [summary2, setSummary2] = useState([]);
  const [summary3, setSummary3] = useState([]);
  const [stores, setStores] = useState([]);
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [year, setYear] = useState(new Date().getFullYear().toString());
  const [selectedStoreid, setSelectedStoreid] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const context = useContext(ContextConfig);

  useEffect(() => {
    getStores();

    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);

    findDivHeight();
    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, []);
  useEffect(() => {
    if (selectedStoreid !== 0) {
      getCustomerSummary();
    }
  }, [selectedStoreid, month, year]);

  const findDivHeight = () => {
    const navbar = document
      .getElementById("master-navbar")
      .getBoundingClientRect().height;
    const controls = document.getElementById("controls").getBoundingClientRect()
      .height;
    const body = document.getElementById("body");
    const usedSpace = navbar + controls;
    const remainingSpace = window.innerHeight - usedSpace;
    body.style.height = remainingSpace + "px";
    body.style.overflowY = "auto";
  };

  const getCustomerSummary = () => {
    setIsLoading(true);
    const storenumber = getStoreNumber();
    if (!storenumber || !month || !year) return;

    getSummary(context.psanalysis, storenumber, month, year, context.apikey)
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          if (j.summary1) {
            setSummary1(j.summary1);
          } else {
            setSummary1([]);
          }
          if (j.summary2) {
            setSummary2(j.summary2);
          } else {
            setSummary2([]);
          }
          if (j.summary3) {
            setSummary3(j.summary3);
          } else {
            setSummary3([]);
          }
        } else {
          setSummary1([]);
          setSummary2([]);
          setSummary3([]);
          toast.error(j.msg, {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        setSummary1([]);
        setSummary2([]);
        setSummary3([]);
        toast.error("An internal error occured", {
          position: toast.POSITION.TOP_RIGHT
        });
      });
  };

  const getStoreNumber = () => {
    const storenumber = stores.filter(
      (s) => s.storeid === Number(selectedStoreid)
    )[0];
    if (storenumber) {
      return storenumber.store_Number;
    }
    return null;
  };

  const getStores = () => {
    getUserAssignedLoyaltyStores(context.url, context.token)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setStores(j.stores);
        } else {
          setStores([]);
          console.log(j.msg);
          toast.error(j.msg, {
            postition: toast.POSITION.TOP_RIGHT
          });
        }
      })
      .catch((err) => {
        setStores([]);
        console.log(err);
        toast.error("An internal error occured", {
          position: toast.POSITION.TOP_RIGHT
        });
      });
  };

  const handleStoreChange = (e) => {
    context.lastStoreid = e.storeid;
    context.lastSearchType = 3;
    setSelectedStoreid(e.storeid);
  };
  return (
    <div>
      <HeaderRB />
      {isLoading && <Spinner />}
      <div className="container-fluid">
        <div id="controls">
          <div style={{ width: "100%", textAlign: "center" }}>
            <h3>Loyalty Summary</h3>
          </div>

          <div className="row justify-content-center align-items-center">
            <div className="col-3">
              <label style={{ marginLeft: "10px" }}>Select a Store</label>
              <TypeAhead
                data={stores}
                keyValue={"storeid"}
                displayValue={"store_Name"}
                value={context.lastStoreid}
                setSelection={handleStoreChange}
              />
            </div>
            <div className="col-3">
              <label>Select a month</label>
              <select
                className="form-control"
                value={month}
                onChange={(e) => setMonth(e.target.value)}
              >
                {months.map((month) => (
                  <option key={`m-${month.month}`} value={month.month}>
                    {month.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-1">
              <label>Select a year</label>
              <select
                className="form-control"
                value={year}
                onChange={(e) => setYear(e.target.value)}
              >
                <option value={`${new Date().getFullYear().toString()}`}>
                  {`${new Date().getFullYear().toString()}`}
                </option>
                <option
                  value={`${new Date().getFullYear() - 1}`}
                >{`${new Date().getFullYear() - 1}`}</option>
                <option
                  value={`${new Date().getFullYear() - 2}`}
                >{`${new Date().getFullYear() - 2}`}</option>
              </select>
            </div>
          </div>
        </div>
        <div className="container-fluid" id="body" style={{ padding: "10px" }}>
          {summary1.length > 0 && summary2.length > 0 && summary3.length > 0 ? (
            <div
              className="row justify-content-center align-items-center"
              style={{ width: "90%", margin: "auto" }}
            >
              <div className="col-4">
                <Box
                  color={colors.red}
                  s1={summary1}
                  s2={summary2}
                  s3={summary3}
                  months={months}
                  month={month}
                  totalizer="3407"
                />
              </div>
              <div className="col-4">
                <Box
                  color={colors.blue}
                  s1={summary1}
                  s2={summary2}
                  s3={summary3}
                  months={months}
                  month={month}
                  totalizer="7701"
                />
              </div>
              <div className="col-4">
                <Box
                  color={colors.purple}
                  s1={summary1}
                  s2={summary2}
                  s3={summary3}
                  months={months}
                  month={month}
                  totalizer="7801"
                />
              </div>
            </div>
          ) : null}
          <StoreCustomerCount
            storenumber={getStoreNumber()}
            month={month}
            year={year}
          />
          <div style={{ width: "100%", textAlign: "center" }}>
            <h2>Program Summary</h2>
          </div>
          <Totals />
          <Line />
        </div>
      </div>
    </div>
  );
};

export default Analysis;
