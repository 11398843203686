import React from "react";
import ReactDOM from "react-dom";
import "./Popup.css";

const Popup = ({
  isShowing,
  hide,
  title = "Create Custom Column",
  custom,
  setCustom,
  changeCustom,
  popupId
}) =>
  isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <div className="popup-overlay" />
          <div
            className="popup-wrapper"
            aria-modal
            aria-hidden
            tabIndex={-1}
            role="dialog"
          >
            <div className="popup">
              <div className="popup-header">
                <span className="popup-title">{title}</span>
                <span className="popup-close-box">
                  <button
                    type="button"
                    className="popup-close-button"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={hide}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </span>
              </div>
              <div className="popup-body">
                <p>Enter the customized column entry.</p>
                <div>
                  <div className="form-group">
                    <label htmlFor="txtDeleteConfirm">{title}</label>
                    <input
                      type="text"
                      className="form-control mb-3"
                      id="txtDeleteConfirm"
                      value={custom}
                      onChange={(e) => setCustom(e.target.value)}
                    />

                    <div className="popup-buttons">
                      <button className="btn btn-outline-dark" onClick={hide}>
                        Cancel
                      </button>
                      <button
                        className="btn btn-outline-dark"
                        onClick={() => changeCustom(popupId, custom)}
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>,
        document.body
      )
    : null;

Popup.propTypes = {};

export default Popup;
