import React, { useState, useEffect, useContext } from "react";
import EntryButtons from "./EntryButtons";
import Info from "./Info";
import Display from "./Display";
import UserInfo from "./UserInfo";
import ClockinButtons from "./ClockinButtons";
import "./DesktopTimeClockContainer.css";
import { getUserStatus } from "../../api/UsersApi";
import { ContextConfig } from "../../App";
import { createPunch } from "../../api/punchApi";
import Spinner from "../../common/Spinner";
import { isValid } from "../../utils";
import {
  loadGeneralSettings,
  loadPunches
} from "./DEsktopTimeClockContainer-I";
import ModalHours from "./ModalHours";

const DesktopTimeClockContainer = () => {
  // eslint-disable-next-line
  const [message, setMessage] = useState("");
  const [displayText, setDisplayText] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [fakeText, setFakeText] = useState("");
  const [passwordMode, setPasswordMode] = useState(false);
  const [usersname, setUsersname] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [employeeid, setEmployeeid] = useState("");
  const [status, setStatus] = useState("");
  const [email, setEmail] = useState("");
  const [links, setLinks] = useState([]);
  const [currentStore, setCurrentStore] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [punches, setPunches] = useState([]);
  const [total, setTotal] = useState("");
  const [isShowing, setIsShowing] = useState(false);

  const context = useContext(ContextConfig);

  let generalSettings;
  if (isValid(context.settings)) {
    generalSettings = context.settings;
  } else {
    loadGeneralSettings(
      context.currentClockStore,
      setIsLoading,
      context,
      setGeneralSettings,
      setPunches,
      setMessage
    );
  }

  const setGeneralSettings = (e) => {
    generalSettings = e;
  };

  useEffect(() => {
    console.log("useEffect for Clock Main");
    if (username.length > 0 && password.length > 0) {
      getStatus();
    }

    if (currentStore && typeof currentStore === "string") {
      loadPunches(
        currentStore,
        setIsLoading,
        context,
        setPunches,
        setGeneralSettings,
        generalSettings,
        setTotal,
        setMessage,
        punchesLoaded
      );
    }
  }, [displayText, status, currentStore]);

  const handleButtonClick = (e) => {
    switch (e) {
      case "E":
        processEntry();
        break;
      case "B":
        setDisplayText(displayText.substring(0, displayText.length - 1));
        break;
      case "C":
        setDisplayText("");
        setPasswordMode(false);
        setFakeText("");
        setUsername("");
        setPassword("");
        break;
      default:
        if (displayText.toUpperCase() == "ENTER PASSWORD") {
          setPasswordMode(true);
          setDisplayText(e);
          setFakeText("*");
        } else {
          setDisplayText(`${displayText}${e}`);
        }
        if (passwordMode) {
          setFakeText(`${fakeText}*`);
        }

        break;
    }
  };

  const processEntry = () => {
    if (username.length === 0) {
      // this not going to be our username
      setUsername(displayText);
      setDisplayText("Enter Password");
    } else {
      // we are entering the password
      setPassword(displayText);
      setDisplayText("");
      setFakeText("");
      setPasswordMode(false);
    }
  };

  const getStatus = () => {
    getUserStatus(context.clockUrl, context.clockToken, username, password)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setUsersname(j.username);
          setFirstname(j.firstName);
          setLastname(j.lastName);
          setDisplayText(j.firstName);
          setEmployeeid(j.employeeid);
          setEmail(j.email);
          setStatus(j.status);
          setLinks(j.links);
          if (j.links.length == 1) {
            setCurrentStore(j.links[0].storeid);
          }
        } else {
          setMessage(j.msg);
          setLinks([]);
        }
      })
      .catch((err) => {
        console.log(err);
        setMessage("An error occured connecting");
        setLinks([]);
      });
  };

  const handleLinkSubmit = (e) => {
    e.preventDefault();
  };

  const handleLinkChange = (e) => {
    setCurrentStore(e.target.value);
  };

  const ClockIn = () => {
    processPunch(1);
  };

  const StartBreak = () => {
    processPunch(3);
  };

  const finishBreak = () => {
    processPunch(4);
  };

  const startLunch = () => {
    processPunch(5);
  };

  const finishLunch = () => {
    processPunch(6);
  };

  const clockOut = () => {
    processPunch(2);
  };

  const processPunch = (punchType) => {
    setIsLoading(true);
    const punch = {
      userid: context.userid,
      punchStatus: punchType,
      storeid: currentStore,
      comment: ""
    };

    punch.mode = process.env.NODE_ENV === "development" ? "debug" : "live";
    const dte = new Date();
    punch.timeZoneOffset = dte.getTimezoneOffset();
    punch.isDst = dte.isDstObserved();

    createPunch(context.clockUrl, context.clockToken, punch)
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          setPunches(j.punches);
          setStatus(9);
          setLinks([]);
          setTimeout(() => {
            getStatus();
          }, generalSettings.punchInTimeout * 1000);
        } else {
          setMessage(j.msg);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        setMessage("An error occured");
      });
  };

  const hide = () => {
    setIsShowing(false);
    console.log("closing hours");
  };

  const punchesLoaded = () => {
    setIsShowing(true);
  };

  return (
    <div className="">
      {isLoading && <Spinner />}
      {isShowing && (
        <ModalHours
          isShowing={isShowing}
          hide={hide}
          punches={punches}
          total={total}
        />
      )}
      <div className="col m-auto clock-container">
        <Display
          output={displayText}
          passwordMode={passwordMode}
          fakeText={fakeText}
        />
        <Info message={message} />
        <div className="row">
          <div className="col-3">
            <UserInfo
              username={usersname}
              status={status}
              firstname={firstname}
              lastname={lastname}
              email={email}
              employeeid={employeeid}
              links={links}
              handleLinkSubmit={handleLinkSubmit}
              handleLinkChange={handleLinkChange}
              currentStore={currentStore}
              generalSettings={generalSettings}
            />
          </div>
          <div className="col-6">
            <EntryButtons handleButtonClick={handleButtonClick} />
          </div>
          <div className="col-3">
            <ClockinButtons
              status={status}
              currentStore={currentStore}
              clockin={ClockIn}
              startBreak={StartBreak}
              finishBreak={finishBreak}
              startLunch={startLunch}
              finishLunch={finishLunch}
              clockOut={clockOut}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesktopTimeClockContainer;
