import React, { useState } from "react";
import ReactDOM from "react-dom";
import { formatDate } from "../../utils";
import PropTypes from "prop-types";
import "./ReviewRequest.css";

const ReviewRequest1 = ({
  isShowing,
  hide,
  pto,
  handleDeny,
  handleConfirm
}) => {
  const [showDenyReason, setShowDenyReason] = useState(false);
  const [denyReason, setDenyReason] = useState("");

  const deny = () => {
    setShowDenyReason(false);
    handleDeny(denyReason);
  };

  return (
    <React.Fragment>
      {isShowing
        ? ReactDOM.createPortal(
            <React.Fragment>
              <div className="modal-reset-overlay" />
              <div
                className="p-4 modal-reset-wrapper"
                aria-modal
                aria-hidden
                tabIndex={-1}
                role="dialog"
              >
                <div className="modal-reset">
                  <div className="modal-reset-header">
                    <span className="modal-reset-confirm-title">
                      Review Time Off Request
                    </span>
                    <span className="modal-reset-close-box">
                      <button
                        type="button"
                        className="modal-reset-close-button"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={hide}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </span>
                  </div>
                  <div className="modal-reset-body">
                    {pto && (
                      <React.Fragment>
                        <div className="pto-user">
                          {pto.firstName} {pto.lastName}
                        </div>
                        <div>Type: {pto.type == "1" ? "PTO" : "RTO"}</div>
                        <div className="pto-date">
                          <span className="pto-d1">
                            Dates Requested: {formatDate(pto.requestStart)}-
                            {formatDate(pto.requestEnd)}
                          </span>
                        </div>
                        <div className="pto-date">
                          <span className="pto=d2">
                            Requested on: {formatDate(pto.dateOfRequest)}
                          </span>
                        </div>
                        <div>Hours: {pto.hours}</div>
                        <div className="pto-comment">Comment:</div>
                        <div className="pto-comment-body">{pto.comments}</div>
                        {pto.denied === 1 ? (
                          <div className="mt-3">
                            <div>Denied by: {pto.denyUser}</div>
                            <div>Denied on {pto.deniedDate}</div>
                            <div>Reason: {pto.denyReason}</div>
                          </div>
                        ) : null}
                        {pto.approved === 1 ? (
                          <div className="mt-3">
                            <div>Approved by: {pto.approveUser}</div>
                            <div>Appvoed on {pto.approvedDate}</div>
                          </div>
                        ) : null}
                        {showDenyReason ? (
                          <div>
                            <div>
                              <label htmlFor="reason">Comment</label>
                              <textarea
                                style={{ padding: "5px" }}
                                className="form-control"
                                rows="4"
                                readOnly={pto.denied === 1 ? true : false}
                                onChange={(e) => setDenyReason(e.target.value)}
                                value={denyReason}
                              />
                            </div>
                            {pto.denied === 0 ? (
                              <div className="mt-2 d-flex justify-content-center align-items-center">
                                <button
                                  className="btn btn-danger"
                                  onClick={deny}
                                >
                                  Confirm Deny
                                </button>
                              </div>
                            ) : null}
                          </div>
                        ) : null}
                        {pto.denied === 0 && pto.approved === 0 ? (
                          <React.Fragment>
                            <div className="pto-buttons">
                              <button
                                className="btn btn-primary"
                                onClick={handleConfirm}
                              >
                                Confirm
                              </button>
                              {!showDenyReason ? (
                                <button
                                  className="btn btn-danger"
                                  onClick={() => setShowDenyReason(true)}
                                >
                                  Deny
                                </button>
                              ) : null}
                            </div>
                          </React.Fragment>
                        ) : null}
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </div>
            </React.Fragment>,
            document.body
          )
        : null}
    </React.Fragment>
  );
};

ReviewRequest1.propTypes = {
  isShowing: PropTypes.bool,
  hide: PropTypes.func,
  handleDeny: PropTypes.func,
  pto: PropTypes.object,
  handleConfirm: PropTypes.func
};

export default ReviewRequest1;
