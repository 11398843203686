import React, { useState, useEffect, useContext } from "react";
import { getStoreCustomerCounts } from "../../api/PayServerApi";
import { ContextConfig } from "../../App";
import { toast } from "react-toastify";
import { PropTypes } from "prop-types";
import { formatMoney } from "../../utils";
import ErrorBoundary from "../../common/ErrorBoundary";
import colors from "./colors";
import "./Line.css";

const StoreCustomerCount = ({ storenumber, month, year }) => {
  const [customers, setCustomers] = useState({});

  const context = useContext(ContextConfig);

  useEffect(() => {
    fetchCustomers();
  }, [storenumber, month, year]);

  const fetchCustomers = () => {
    if (!storenumber) return;
    getStoreCustomerCounts(
      context.psanalysis,
      storenumber,
      month,
      year,
      context.apikey
    )
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setCustomers(j.count[0]);
        } else {
          setCustomers({});
          toast.error(j.msg, {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setCustomers({});
        toast.error("An internal error occured", {
          position: toast.POSITION.TOP_RIGHT
        });
      });
  };

  const calculatePercent = (value) => {
    if (Object.keys(customers).length === 0) {
      return;
    }

    const { activeCustCnt, prevCustCnt, sixMonthCustCnt } = customers;
    const total =
      Number(activeCustCnt) + Number(sixMonthCustCnt) + Number(prevCustCnt);
    const percent = (customers[value] / total) * 100;

    console.log(`percent for ${value} is ${Math.round(percent, 0)}`);
    return Math.round(Math.ceil(percent), 0).toString() + "%";
  };

  return (
    <ErrorBoundary>
      <div className="container justify-content-center align-items-center mt-5">
        <div className="psas-line-description">Store Customer Makeup</div>
        <div className="psas-line-body flex-d justify-content-start">
          <span
            className="psas-line-last-thirty"
            style={{
              width: calculatePercent("activeCustCnt"),
              backgroundColor: colors.green
            }}
          >
            &nbsp;
          </span>
          <span
            className="psas-line-last-sixty"
            style={{
              width: calculatePercent("prevCustCnt"),
              backgroundColor: colors.orange
            }}
          >
            &nbsp;
          </span>
          <span
            className="psas-line-last-ninety"
            style={{
              width: calculatePercent("sixMonthCustCnt"),
              backgroundColor: colors.purple
            }}
          >
            &nbsp;
          </span>
        </div>
        <div className="psas-line-legend">
          <div>
            <div className="psas-line-legend-title">Current month</div>
            <div
              className="psas-line-legend-value psas-line-last-thirty-value"
              style={{ color: colors.green }}
            >
              {formatMoney(customers.activeCustCnt, 0)}
            </div>
          </div>
          <div>
            <div className="psas-line-legend-title">Previous month</div>
            <div
              className="psas-line-legend-value psas-line-last-sixty-value"
              style={{ color: colors.orange }}
            >
              {formatMoney(customers.prevCustCnt, 0)}
            </div>
          </div>
          <div>
            <div className="psas-line-legend-title">Last 6 months</div>
            <div
              className="psas-line-legend-value psas-line-last-ninety-value"
              style={{ color: colors.purple }}
            >
              {formatMoney(customers.sixMonthCustCnt, 0)}
            </div>
          </div>
          <div>
            <div className="psas-line-legend-title">Total Customers</div>
            <div className="psas-line-legend-value" style={{ color: "#fff" }}>
              {formatMoney(customers.totalCustCnt, 0)}
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

StoreCustomerCount.propTypes = {
  storenumber: PropTypes.number,
  month: PropTypes.number,
  year: PropTypes.string
};

export default StoreCustomerCount;
