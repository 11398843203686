import React, { useState, useEffect, useContext } from "react";
//import colors from "../../common/colors";
import "../Clock.css";
import ClockSidebar from "../ClockSidebar";
import { isMobile } from "react-device-detect";
import GSMobile from "./GSMobile";
import { ContextConfig } from "../../App";
import { usePrevious, isValid } from "../../utils";
import { isEqual, debounce } from "../../fp";
import { readClockStores } from "../../api/StoreApi";
import Spinner from "../../common/Spinner";
import {
  saveSettings,
  getGeneralSettings,
  copySettings
} from "../../api/settingsApi";
import {
  getSchemes,
  createScheme,
  getRounding,
  updateScheme,
  setSchemeBreakPoint
} from "../../api/scheduleApi";
import { toast } from "react-toastify";
import ClockDesktopMenu from "../ClockDesktopMenu";
import Header from "../widgets/Header";
import FloatingSidebar from "../../Dashboard/FloatingSidebar";
import Rounding from "./Rounding";
import "./GeneralSettings.css";

const initialState = {
  storeid: 0,
  roundingMethod: 0,
  minutesForLunch: 0,
  hoursForBreak: 0,
  clockInGracePeriodAfter: 0,
  clockInGracePeriodBefore: 0,
  timePrecision: 0,
  freeBreakMinutes: 0,
  forceLunch: 0,
  managerTimeout: 0,
  punchInTimeout: 0,
  defaultScheduleWeek: 0,
  disclaimer: "",
  forceBreakTime: 0,
  mandatoryBreakMinutes: 0,
  deductFullBreak: 0,
  approachingFortyThreshold: 0,
  dayRollOver: "",
  enableClockPassword: 0,
  enforceSchedule: 0,
  enableBreak: 0,
  enableLunch: 1,
  deductFullLunch: 1,
  deductLunchOverMax: 0,
  showRawPunches: 0,
  showRawPunchesOnEditScreen: 0,
  dissalowEditingOfYourOwnTime: 0,
  minumumHoursForMandatoryBreak: 4,
  minumumHoursForMandatoryLunch: 5,
  selectedScheme: 0,
  uniqueEmployeeIds: 0,
  breakGracePeriodBefore: 0,
  breakGracePeriodAfter: 0,
  lunchGracePeriodBefore: 0,
  lunchGracePeriodAfter: 0,
  PunchOutGracePeriodBefore: 0,
  PunchOutGracePeriodAfter: 0,
  useMilitaryTime: 0
};

const roundingMethods = [
  { id: 1, description: "Nearest 5 Minutes" },
  { id: 2, description: "Nearest 10 Minutes" },
  { id: 3, description: "Nearest Half Hour" },
  { id: 4, description: "No Rounding" },
  { id: 5, description: "Custom Rounding" }
];

const GeneralSettings = () => {
  const [settings, setSettings] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [store, setStore] = useState({});
  const [stores, setStores] = useState([]);
  const [reRender, setReRender] = useState(false);
  const [copy, setCopy] = useState(false);
  const [copyStore, setCopyStore] = useState("");
  const [schemes, setSchemes] = useState([]);
  const [createSchemeName, setCreateSchemeName] = useState(false);
  const [schemeName, setSchemeName] = useState("");
  const [showRounding, setShowRounding] = useState(false);
  const [currentScheme, setCurrentScheme] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [schemeid, setSchemeid] = useState(0);
  const [breakPoint, setBreakPoint] = useState("");
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const context = useContext(ContextConfig);

  const previousStores = usePrevious(stores);

  useEffect(() => {
    fetchSchemes();
    if (!isEqual(previousStores, stores)) {
      loadStores();
    }
    if (!isMobile) {
      if (isValid(context.lastClockStore)) {
        const target = {
          value: context.lastClockStore
        };
        const e = {
          target
        };
        handleStoreChange(e);
      }
    }

    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);

    findDivHeight();

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, [stores, store, reRender]);

  const findDivHeight = () => {
    if (isMobile) return;
    const navbar = document
      .getElementById("master-navbar")
      .getBoundingClientRect().height;

    const usedSpace = navbar + 190;
    const remaining = window.innerHeight - usedSpace;

    const widget = document.getElementById("settings");
    widget.style.height = remaining + "px";
    widget.style.overflow = "auto";
  };

  const formIsValid = () => {
    const _errors = [];

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  };

  const loadStores = () => {
    readClockStores(context.clockUrl, context.clockToken)
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          setStores(j.stores);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const handleStoreChange = (e) => {
    setStore(e.target.value);
    getGeneralSettings(context.clockUrl, context.clockToken, e.target.value)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          if (j.settings !== null) {
            setSettings(j.settings);
          } else {
            setSettings(initialState);
          }
        } else {
          setSettings(initialState);
        }
      })
      .catch((error) => {
        console.log(error);
        setSettings(initialState);
      });
  };

  const handleChange = ({ target }) => {
    if (target.name == "forceLunch") {
      setSettings({
        ...settings,
        forceLunch: target.checked
      });
    } else if (target.name == "forceBreakTime") {
      setSettings({
        ...settings,
        forceBreakTime: target.checked
      });
    } else if (target.name == "deductFullBreak") {
      setSettings({
        ...settings,
        deductFullBreak: target.checked
      });
    } else if (target.name == "enableClockPassword") {
      setSettings({
        ...settings,
        enableClockPassword: target.checked
      });
    } else if (target.name == "enforceSchedule") {
      setSettings({
        ...settings,
        enforceSchedule: target.checked
      });
    } else if (target.name == "enableBreak") {
      setSettings({
        ...settings,
        enableBreak: target.checked
      });
    } else if (target.name == "enablelunch") {
      setSettings({
        ...settings,
        enableLunch: target.checked
      });
    } else if (target.name == "deductFullLunch") {
      setSettings({
        ...settings,
        deductFullLunch: target.checked
      });
    } else if (target.name == "deductLunchOverMax") {
      setSettings({
        ...settings,
        deductLunchOverMax: target.checked
      });
    } else if (target.name == "showRawPunches") {
      setSettings({
        ...settings,
        showRawPunches: target.checked
      });
    } else if (target.name == "dissalowEditingOfYourOwnTime") {
      setSettings({
        ...settings,
        dissalowEditingOfYourOwnTime: target.checked
      });
    } else if (target.name == "useMilitaryTime") {
      setSettings({
        ...settings,
        useMilitaryTime: target.checked
      });
    } else if (target.name == "roundingMethod") {
      setSettings({
        ...settings,
        [target.name]: target.value
      });
    } else if (target.name == "selectedScheme") {
      if (target.value == "0") {
        setCreateSchemeName(true);
      } else {
        setCreateSchemeName(false);
      }
      setSettings({
        ...settings,
        selectedScheme: target.value
      });
    } else if (target.name == "uniqueEmployeeIds") {
      setSettings({
        ...settings,
        uniqueEmployeeIds: target.checked
      });
    } else {
      setSettings({
        ...settings,
        [target.name]: target.value
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formIsValid()) {
      return;
    }

    saveSettings(context.clockUrl, context.clockToken, settings, store)
      .then((response) => {
        const j = response.data;
        if (j.error == "0") {
          context.settings = j.generalSettings;
          showSuccess();
          getGeneralSettings(
            context.clockUrl,
            context.clockToken,
            context.lastClockStore
          )
            .then((response) => {
              const j = response.data;
              if (j.error === 0) {
                if (j.settings !== null) {
                  context.settings = j.settings;
                  setSettings(j.settings);
                } else {
                  setSettings(initialState);
                }
              } else {
                setSettings(initialState);
              }
            })
            .catch((error) => {
              console.log(error);
              setSettings(initialState);
            });
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const showSuccess = () => {
    toast.success("Your settings have been saved.", {
      position: toast.POSITION.TOP_LEFT
    });
  };

  const handleCheckChange = (e) => {
    setCopy(e.target.checked);
  };

  const handleCopyStoreChange = (e) => {
    setCopyStore(e.target.value);
  };

  const handleCopySettings = (e) => {
    e.preventDefault();
    copySettings(context.clockUrl, context.clockToken, copyStore, store)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setReRender(!reRender);
          toast.success("Your settings have been copied", {
            position: toast.POSITION.TOP_LEFT
          });
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An error occured trying to copy your Settings", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const fetchSchemes = () => {
    getSchemes(context.clockUrl, context.clockToken)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          const s = j.schemes;
          s.push({
            name: "Add Scheme",
            id: 0
          });
          setSchemes(s);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An internal error as occured", {
          position: toast.POSITION_TOP_LEFT
        });
      });
  };

  const createNewScheme = (e) => {
    e.preventDefault();
    if (createSchemeName) {
      createScheme(context.clockUrl, context.clockToken, schemeName)
        .then((response) => {
          const j = response.data;
          if (j.error === 0) {
            setSettings({
              ...settings,
              selectedScheme: j.id
            });
            setCreateSchemeName(false);
          } else {
            toast.error(j.msg, {
              position: toast.POSITION_TOP_LEFT
            });
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error("An Internal error occured", {
            position: toast.POSITION_TOP_LEFT
          });
        });
    } else {
      getRounding(context.clockUrl, context.clockToken, settings.selectedScheme)
        .then((response) => {
          const j = response.data;
          if (j.error === 0) {
            setCurrentScheme(j.scheme);
            setSchemeName(j.schemeName);
            setSchemeid(j.schemeId);
            setBreakPoint(j.breakPoint);
            setShowRounding(true);
          } else {
            toast.error(j.msg);
            setCurrentScheme([]);
          }
        })
        .catch((err) => {
          console.log(err);
          setCurrentScheme([]);
          toast.error("An Internal error occured", {
            position: toast.POSITION_TOP_LEFT
          });
        });
    }
  };

  const hideRounding = () => {
    setShowRounding(false);
  };

  const updateCurrentScheme = (record, value) => {
    const index = currentScheme.findIndex(
      (s) => s.actualPunch === record.actualPunch
    );
    const scheme = [...currentScheme];
    scheme[index] = { ...record, roundsTo: value };
    setCurrentScheme(scheme);
  };

  const saveCurrentScheme = () => {
    updateScheme(
      context.clockUrl,
      context.clockToken,
      currentScheme,
      settings.selectedScheme
    )
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          toast.success("Your Rounding Scheme has been saved", {
            position: toast.POSITION_TOP_LEFT
          });
          setShowRounding(false);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION_TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An Internal error has occuree", {
          position: toast.POSITION_TOP_LEFT
        });
      });
  };

  const updateBreakPoint = (e) => {
    setBreakPoint(e);
    setSchemeBreakPoint(context.clockUrl, context.clockToken, schemeid, e)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          toast.success("Your Break Point has been set", {
            position: toast.POSITION_TOP_LEFT
          });
        } else {
          toast.error(j.msg, {
            position: toast.POSITION_TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An Internal error occured", {
          position: toast.POSITION_TOP_LEFT
        });
      });
  };

  return (
    <div className="clock-mobile">
      {isLoading ? <Spinner /> : null}
      {showRounding && (
        <Rounding
          isShowing={showRounding}
          hide={hideRounding}
          currentScheme={currentScheme}
          schemeName={schemeName}
          updateScheme={updateCurrentScheme}
          saveCurrentScheme={saveCurrentScheme}
          breakPoint={breakPoint}
          setBreakPoint={updateBreakPoint}
        />
      )}
      {isMobile ? (
        <div className="mobile-clock-header">
          <ClockSidebar />
          <div className="container cc-main-body">
            <div
              style={{
                width: "100%",
                marginTop: "20px",
                textAlign: "center",
                fontSize: "1.3rem",
                fontWeight: "bold"
              }}
            >
              General Settings
            </div>
            <GSMobile
              initialState={initialState}
              stores={stores}
              errors={errors}
              setStore={setStore}
              onChange={handleChange}
              onStoreChange={handleStoreChange}
              settings={settings}
              handleSubmit={handleSubmit}
              roundingMethods={roundingMethods}
              store={store}
              handleCheckChange={handleCheckChange}
              copy={copy}
              setCopy={setCopy}
              setCopyStore={handleCopyStoreChange}
              handleCopySettings={handleCopySettings}
              createSchemeName={createSchemeName}
              setCreateSchemeName={setCreateSchemeName}
              schemes={schemes}
              schemeName={schemeName}
              setSchemeName={setSchemeName}
              createNewScheme={createNewScheme}
            />
          </div>
        </div>
      ) : (
        <div>
          <ClockDesktopMenu />
          <FloatingSidebar />
          <div
            style={{
              width: "100%",
              marginTop: "20px",
              textAlign: "center",
              fontSize: "1.3rem",
              fontWeight: "bold"
            }}
          >
            General Settings
          </div>
          <Header reRender={reRender} setReRender={setReRender} />
          <div className="container">
            <GSMobile
              initialState={initialState}
              stores={stores}
              errors={errors}
              setStore={setStore}
              onChange={handleChange}
              onStoreChange={handleStoreChange}
              settings={settings}
              handleSubmit={handleSubmit}
              roundingMethods={roundingMethods}
              store={store}
              handleCheckChange={handleCheckChange}
              copy={copy}
              setCopy={setCopy}
              setCopyStore={handleCopyStoreChange}
              handleCopySettings={handleCopySettings}
              createSchemeName={createSchemeName}
              setCreateSchemeName={setCreateSchemeName}
              schemes={schemes}
              schemeName={schemeName}
              setSchemeName={setSchemeName}
              createNewScheme={createNewScheme}
            />
          </div>
        </div>
      )}

      {/* end of main div */}
    </div>
  );
};

export default GeneralSettings;
