import React, { useEffect, useState, useContext } from "react";
import { getDistrictStoreDesktop } from "../api/BannerApi";
import {
  usePrevious,
  convertAmount,
  print,
  handleCsv,
  formatDate
} from "../utils";
import { isEqual } from "lodash";
import { ContextConfig } from "../App";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import HeaderRb from "../common/HeaderRb";

const BannerStoreSalesDesktop = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const context = useContext(ContextConfig);

  const previousSales = usePrevious(data);

  useEffect(() => {
    console.log(context);
    if (!isEqual(previousSales, data)) {
      loadData();
    }
  }, [data]);

  const loadData = () => {
    setIsLoading(true);
    getDistrictStoreDesktop(
      context.url,
      context.token,
      context.bannerGroup,
      context.startDate,
      context.endDate
    )
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          setData(j.items);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const handlePrint = () => {
    print(document.getElementById("banner").innerHTML);
  };

  return (
    <LoadingOverlay active={isLoading} spinner text="Loading Data...">
      <HeaderRb />
      <div className="container-fluid" id="banner">
        <h3>Banner Sales</h3>
        <div className="banner-group">
          Group Id: {context.bannerGroup} - {context.bannerGroupName}
        </div>
        <div className="banner-dates">
          Start: {formatDate(context.startDate)} End:{" "}
          {formatDate(context.endDate)}
        </div>
        <div className="export-report">
          <span className="print-report" onClick={handlePrint}>
            <i className="far fa-print"></i>
          </span>
          <span
            className="csv-report"
            onClick={() => handleCsv(data, "Banner.csv")}
          >
            <i className="far fa-save"></i>
          </span>
        </div>
        {data.length === 0 ? (
          <div>No records were returned</div>
        ) : (
          <table style={{ width: "90%", margin: "auto" }}>
            <thead>
              <tr>
                <th style={{ width: "50%" }}>Store</th>
                <th style={{ width: "50%", textAlign: "right" }}>Sales</th>
              </tr>
            </thead>
            <tbody>
              {data.map((record, index) => (
                <tr key={index}>
                  <td>{record.store_Name}</td>
                  <td style={{ textAlign: "right" }}>
                    {convertAmount(record.f65)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </LoadingOverlay>
  );
};

export default BannerStoreSalesDesktop;
