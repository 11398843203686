const convertConcern = (score, purples) => {
  if (purples > 0) return "ccpurple";

  let result = "ccdefault";

  if (score <= 0.3) {
    return "ccgreen";
  } else if (score >= 0.301 && score <= 0.499) {
    return "ccyellow";
  } else if (score >= 0.5 && score <= 0.754) {
    return "ccorange";
  } else if (score >= 0.755) {
    return "ccred";
  } else {
    return result;
  }
};

const convertColor = (score) => {
  let result = "#000";
  if (score <= 0.3) {
    return "#1CA68A";
  } else if (score >= 0.301 && score <= 0.499) {
    return "#FFD769";
  } else if (score >= 0.5 && score <= 0.754) {
    return "#ffab00";
  } else if (score >= 0.755) {
    return "#FF3E4C";
  } else {
    return result;
  }
};

const generateScore = (score) => {
  let result = 0;
  if (score <= 0.3) {
    return 0;
  } else if (score >= 0.301 && score <= 0.499) {
    return 1;
  } else if (score >= 0.5 && score <= 0.754) {
    return 2;
  } else if (score >= 0.755) {
    return 3;
  } else {
    result = 0;
  }
  return result;
};

const generateIcon = (score, purple) => {
  let result = "";
  if (score <= 0.3) {
    result = "far fa-check-circle";
  } else if (score >= 0.301 && score <= 0.499) {
    result = "far fa-exclamation-triangle";
  } else if (score >= 0.5 && score <= 0.754) {
    result = "far fa-exclamation-circle";
  } else if (score >= 0.755) {
    result = "far fa-bomb";
  } else {
    result = "";
  }
  if (purple > 0) {
    result = "fal fa-question-circle";
  }
  return result;
};

export { generateIcon, generateScore, convertColor, convertConcern };
