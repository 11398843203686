import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { formatDate } from "../../utils";
import { debounce } from "../../fp";

const OperatorReportData = ({ data, operator, reportDate }) => {
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  useEffect(() => {
    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);

    findDivHeight();

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, []);

  const findDivHeight = () => {
    const navbar = document
      .getElementById("master-navbar")
      .getBoundingClientRect().height;
    const controls = document
      .getElementById("r-controls")
      .getBoundingClientRect().height;
    const usedSpace = navbar + controls + 30;
    const remaining = window.innerHeight - usedSpace;

    const body = document.getElementById("r-body");
    body.style.height = remaining + "px";
    body.style.overflow = "auto";
  };

  return (
    <div className="om-operator">
      <div id="r-controls">
        <div className="om-operator-name">
          #{operator.num} - {operator.name}
        </div>
        <div className="om=operator-date">{formatDate(reportDate)}</div>
      </div>
      <div id="r-body">
        <table style={{ width: "90%", margin: "auto", marginBottom: "30px" }}>
          <thead>
            <tr>
              <th style={{ width: "40%" }}>Name</th>
              <th style={{ width: "20%" }}>Tlz</th>
              <th style={{ width: "20%" }}>Section</th>
              <th style={{ width: "10%" }}>Qty</th>
              <th style={{ width: "10%", textAlign: "right" }}>Amount</th>
            </tr>
          </thead>
          <tbody>
            {data
              .filter((o) => o.num == operator.num)
              .map((record, index) => (
                <tr key={index}>
                  <td>{record.description}</td>
                  <td>{record.tlz}</td>
                  <td>{record.section}</td>
                  <td>{record.qty}</td>
                  <td style={{ textAlign: "right" }}>{record.sales}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

OperatorReportData.propTypes = {
  data: PropTypes.array,
  operator: PropTypes.object,
  reportDate: PropTypes.any
};

export default OperatorReportData;
