import React, { useState, useEffect, useContext } from "react";
import HeaderRB from "../../common/HeaderRb";
import { getTransaction } from "../../api/CashierApi";
import { ContextConfig } from "../../App";
import { BrowserView, MobileView } from "react-device-detect";
import { toast } from "react-toastify";
import MobileTransaction from "./MobileTransaction";
import DesktopTransaction from "./DesktopTransaction";
import Spinner from "../../common/Spinner";

const Transaction = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [hdr, setHdr] = useState([]);
  const [reg, setReg] = useState([]);
  const [ttl, setTtl] = useState([]);
  const context = useContext(ContextConfig);

  useEffect(() => {
    fetchTransaction();
  }, []);

  const fetchTransaction = () => {
    setIsLoading(true);
    const { f254, f1032, storeid, term, f1185 } = context.transactionDetails;
    const transList = `${f254},${f1032},${storeid},${term},${f1185}`;
    getTransaction(context.url, context.token, transList)
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          setHdr(j.hdr);
          setReg(j.reg);
          setTtl(j.ttl);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        toast.error("An error has occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  return (
    <React.Fragment>
      {isLoading && <Spinner />}
      <HeaderRB />
      <div>
        <BrowserView>
          <DesktopTransaction hdr={hdr} reg={reg} ttl={ttl} />
        </BrowserView>
        <MobileView>
          <MobileTransaction hdr={hdr} reg={reg} ttl={ttl} />
        </MobileView>
      </div>
    </React.Fragment>
  );
};

export default Transaction;
