import React from "react";
import PropTypes from "prop-types";

const classname = ({ punchList }) => {
  return (
    <div>
      {punchList.length === 0 ? (
        <div>No records were returned</div>
      ) : (
        <table style={{ width: "90%", margin: "auto" }}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Hours</th>
            </tr>
          </thead>
          <tbody>
            {punchList.map((record, index) => (
              <tr key={index}>
                <td>
                  {record.first_Name} {record.last_Name}
                </td>
                <td>{record.totalHours}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

classname.propTypes = {
  punchList: PropTypes.array
};

export default classname;
