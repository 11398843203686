import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import ClockDesktopMenu from "../ClockDesktopMenu";
import { ContextConfig } from "../../App";
import { usePrevious, isValid, formatDate } from "../../utils";
import { isEqual, debounce } from "../../fp";
import { getReportsList } from "../../api/clockReportsApi";
import { toast } from "react-toastify";
import Header from "../widgets/Header";
import { withRouter } from "react-router-dom";
import { getReportData } from "./ClockReports-I";
import Viewer from "./Viewer";
import { generatePdf } from "./pdf";
import ReportUserParams from "./ReportUserParams";
import FloatingSidebar from "../../Dashboard/FloatingSidebar";
import ErrorBoundary from "../../common/ErrorBoundary";
import "./Reports.css";

const ClockReports = () => {
  const [reports, setReports] = useState([]);
  const [report, setReport] = useState({});
  const [contents, setContents] = useState("");
  const [showReport, setShowReport] = useState(false);
  const [reportName, setReportName] = useState("");
  const [userid, setUserid] = useState(0);
  const [showUserParams, setShowUserParams] = useState(false);
  const [signOffModel, setSignOffModel] = useState([]);
  const [storeName, setStoreName] = useState("");
  const [showPrint, setShowPrint] = useState(false);
  const [reRender, setReRender] = useState(false);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const context = useContext(ContextConfig);

  const previousReports = usePrevious(reports);
  const previousReportName = usePrevious(reportName);

  useEffect(() => {
    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);

    findDivHeight();

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, []);

  useEffect(() => {
    if (!isEqual(previousReports, reports)) {
      loadReports();
    }

    const params = {
      startDate: context.startDate,
      endDate: context.endDate,
      storename: storeName
    };

    if (
      report.reportName === "Employee Hours" ||
      report.reportName == "Rounding Helper"
    ) {
      if (context.reportUserData) {
        params.usersName =
          context.reportUserData.firstName +
          " " +
          context.reportUserData.lastName;
        params.levelDescription = context.reportUserData.levelDescription;
        params.employeeId = context.reportUserData.employeeId;
      }
    }
    if (previousReportName != reportName) {
      if (isValid(reportName)) {
        // if we need header data like starta and end dates, we need to pass a second argument as params
        if (report.route === "departments") {
          generatePdf(report, null, null, contents);
        } else {
          generatePdf(report, params, signOffModel);
        }
      }
    }
    if (isValid(report)) {
      if (report.route == "userlist" && contents.length == 0) {
        loadReportData();
      } else if (report.route === "signoff" && contents.length === 0) {
        loadReportData();
      } else if (report.route === "missingpunchess" && contents.length === 0) {
        loadReportData();
      } else if (report.route === "deletes" && contents.length === 0) {
        loadReportData();
      } else if (report.route === "longlunch" && contents.length === 0) {
        loadReportData();
      } else if (report.route === "latelunch" && contents.length === 0) {
        loadReportData();
      } else if (
        report.route === "puncheswithoutdepartment" &&
        contents.length === 0
      ) {
        loadReportData();
      } else if (report.route == "departments" && contents.length === 0) {
        loadReportData();
      } else if (report.route == "ap" && contents.length === 0) {
        loadReportData();
      } else if (report.route == "missingbreaks" && contents.length === 0) {
        loadReportData();
      } else if (report.route == "missinglunches" && contents.length === 0) {
        loadReportData();
      } else if (report.route == "ptosummary" && contents.length === 0) {
        loadReportData();
      } else if (report.route == "ptodeny" && contents.length === 0) {
        loadReportData();
      } else if (report.route == "ptoapproved" && contents.length === 0) {
        loadReportData();
      } else if (report.route == "rtoapproved" && contents.length === 0) {
        loadReportData();
      } else if (report.route == "totalhours" && contents.length === 0) {
        loadReportData();
      }
    }
    if (showReport === true) {
      if (report.route === "departments") {
        generatePdf(report, null, null, contents);
      } else {
        generatePdf(report, params, signOffModel);
      }
    }
  }, [reportName, showUserParams, report, showReport, showPrint]);

  const findDivHeight = () => {
    const navbar = document
      .getElementById("master-navbar")
      .getBoundingClientRect().height;

    const controls = document.getElementById("controls").getBoundingClientRect()
      .height;

    const usedSpace = navbar + controls + 75;
    const remaining = window.innerHeight - usedSpace;

    const widget = document.getElementById("body");
    widget.style.height = remaining + "px";
    widget.style.overflow = "auto";
  };

  const loadReports = () => {
    getReportsList(context.clockUrl, context.clockToken)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setReports(j.reports);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          "An error occured getting a list of Reports from the Server.",
          {
            position: toast.POSITION.TOP_LEFT
          }
        );
      });
  };

  const handleClick = (report) => {
    setReport(report);
    switch (report.route) {
      case "employeeHours":
        setShowUserParams(true);
        setSignOffModel([]);
        setShowPrint(false);
        break;
      case "missingpunchesc":
        setShowUserParams(true);
        setSignOffModel([]);
        setShowPrint(false);
        break;
      case "missingpunchess":
        setShowUserParams(false);
        setSignOffModel([]);
        setShowPrint(false);
        break;
      case "employeeHoursRounded":
        break;
      case "exporthours":
        break;
      case "signoff":
        setShowUserParams(false);
        setShowPrint(false);
        break;
      case "userlist":
        setShowUserParams(false);
        setSignOffModel([]);
        setShowPrint(false);
        break;
      case "svw":
        break;
      case "hourlylabor":
        break;
      case "hourlysales":
        break;
      case "deptsales":
        break;
      case "deptsingle":
        break;
      case "deptlabor":
        break;
      case "deptsinglelabor":
        break;
      case "deletes":
        setShowUserParams(false);
        setSignOffModel([]);
        setShowPrint(false);
        break;
      case "longlunch":
        setShowUserParams(false);
        setSignOffModel([]);
        setShowPrint(false);
        break;
      case "latelunch":
        setShowUserParams(false);
        setSignOffModel([]);
        setShowPrint(false);
        break;
      case "puncheswithoutdepartment":
        setShowUserParams(false);
        setSignOffModel([]);
        setShowPrint(false);
        break;
      case "departments":
        setShowUserParams(false);
        setSignOffModel([]);
        setShowPrint(true);
        break;
      case "ap":
        setShowUserParams(false);
        setSignOffModel([]);
        setShowPrint(false);
        break;
      case "missingbreaks":
        setShowUserParams(false);
        setSignOffModel([]);
        setShowPrint(false);
        break;
      case "missinglunches":
        setShowUserParams(false);
        setSignOffModel([]);
        setShowPrint(false);
        break;
      case "helper":
        setShowUserParams(true);
        setSignOffModel([]);
        setShowPrint(false);
        break;
      case "ptosummary":
        setShowUserParams(false);
        setSignOffModel([]);
        setShowPrint(false);
        break;
      case "ptodeny":
        setShowUserParams(false);
        setSignOffModel([]);
        setShowPrint(false);
        break;
      case "ptoapproved":
        setShowUserParams(false);
        setSignOffModel([]);
        setShowPrint(false);
        break;
      case "rtoapproved":
        setShowUserParams(false);
        setSignOffModel([]);
        setShowPrint(false);
        break;
      case "totalhours":
        setShowUserParams(false);
        setSignOffModel([]);
        setShowPrint(false);
        break;
      default:
        // employee list
        break;
    }
  };

  const loadReportData = () => {
    getReportData(
      context.clockUrl,
      context.clockToken,
      report.route,
      formatDate(context.startDate),
      formatDate(context.endDate),
      userid,
      context.lastClockStore
    )
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          if (report.route === "signoff") {
            setSignOffModel(j.signOffModel);
            buildHtml(j.htmlTables);
          } else {
            setContents(j.html);
            setShowReport(true);
          }

          setStoreName(j.storename);
          report.css = j.css;
          setReportName(report.reportName);
        } else {
          setReportName("");
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An error occured getting your Report data", {
          position: toast.POSITION.TOP_LEFT
        });
        setReportName("");
      });
  };

  const userParamsFinished = (value) => {
    if (value === true) {
      loadReportData();
    }
  };

  const buildHtml = (tables) => {
    debugger;
    let html = "";
    tables.map((table, index) => {
      html += `<div key={${index}}>${table}</div>`;
    });
    setContents(html);
    setShowReport(true);
  };

  const closeReport = () => {
    setReport({});
    setShowReport(false);
    setReportName("");
    setContents("");
    setShowUserParams(false);
  };

  const print = () => {};

  return (
    <div className="d-reports-list">
      <ErrorBoundary>
        <Viewer
          isShowing={showReport}
          hide={closeReport}
          contents={contents}
          reportName={report.reportName}
          tables={signOffModel}
          showPrint={showPrint}
          print={print}
        />
      </ErrorBoundary>
      <ClockDesktopMenu />
      <FloatingSidebar />
      <div id="controls">
        <div
          style={{
            width: "100%",
            marginTop: "20px",
            textAlign: "center",
            fontSize: "1.3rem",
            fontWeight: "bold"
          }}
        >
          Reports
        </div>
        <Header reRender={reRender} setReRender={setReRender} />
      </div>
      {showUserParams && (
        <ReportUserParams
          context={context}
          setUserid={setUserid}
          setUserParamsFinished={userParamsFinished}
        />
      )}
      <div
        className="container d-reports-list-table justify-content-center"
        id="body"
      >
        {reports.length > 0 ? (
          <div className="row d-reports-list-table">
            {reports.map((report, index) => (
              <div
                key={`d-r-i-${index}`}
                className="col-3 justify-content-center d-report-item mx-3"
                onClick={() => handleClick(report)}
              >
                <div className="d-report-line1">
                  <span className="d-report-icon">
                    <i className={report.icon}></i>
                  </span>
                  <span className="d-report-name">{report.reportName}</span>
                </div>
                <div className="d-report-line2">
                  <div className="d-report-description">
                    {report.description}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="d-reports-list-empty">
            There are no reports to display
          </div>
        )}
      </div>
    </div>
  );
};

ClockReports.propTypes = {
  history: PropTypes.object
};

export default withRouter(ClockReports);
