import * as actions from "../actions/actionTypes";

const initialState = {
  isLoadingPunches: false,
  isLoadingDepartments: false,
  settings: {},
  reRender: false,
  existingPunch: {},
  users: [],
  punchUser: 0,
  isEditable: true,
  isLocked: false,
  singleDay: false,
  punches: [],
  filteredPunches: [],
  showDelete: false,
  delPunch: {},
  error: "",
  confirmText: "",
  punch: {
    punchIn: null,
    punchOut: null,
    timeIn: null,
    timeOut: null
  },
  errors: [],
  departments: []
};

const editReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.EDIT_SET_IS_EDITABLE:
      return {
        ...state,
        isEditable: action.isEditable
      };
    case actions.EDIT_SET_SETTINGS:
      return {
        ...state,
        settings: action.settings
      };
    case actions.EDIT_SET_RERENDER:
      return {
        ...state,
        reRender: !state.reRender
      };
    case actions.EDIT_SET_EXISTING_PUNCH:
      return {
        ...state,
        existingPunch: action.existingPunch
      };
    case actions.EDIT_SET_USERS:
      return {
        ...state,
        users: action.users
      };
    case actions.EDIT_SET_PUNCH_USER:
      return {
        ...state,
        punchUser: action.punchUser
      };
    case actions.EDIT_SET_IS_LOCKED:
      return {
        ...state,
        isLocked: action.isLocked
      };
    case actions.EDIT_SET_SINGLE_DAY:
      return {
        ...state,
        singleDay: action.singleDay
      };
    case actions.EDIT_SET_PUNCHES:
      return {
        ...state,
        punches: action.punches
      };
    case actions.EDIT_SET_FILTERED_PUNCHES:
      return {
        ...state,
        filteredPunches: action.punches
      };
    case actions.EDIT_SET_SHOW_DELETE:
      return {
        ...state,
        showDelete: action.showDelete
      };
    case actions.EDIT_SET_DEL_PUNCH:
      return {
        ...state,
        delPunch: action.punch
      };
    case actions.EDIT_SET_ERROR:
      return {
        ...state,
        errors: action.errors
      };
    case actions.EDIT_SET_CONFIRM_TEXT:
      return {
        ...state,
        confirmText: action.confirmText
      };
    case actions.EDIT_SET_PUNCH:
      return {
        ...state,
        punch: action.punch
      };
    case actions.EDIT_SET_DEPARTMENTS:
      return {
        ...state,
        departments: action.departments
      };
    case actions.EDIT_SET_IS_LOADING_DEPARTMENTS:
      return {
        ...state,
        isLoadingDepartments: action.isLoading
      };
    case actions.EDIT_SET_ERRORS:
      return {
        ...state,
        errors: action.errors
      };
    case actions.EDIT_RESET:
      return {
        ...state,
        punch: {}
      };
    default:
      return state;
  }
};

export default editReducer;
