import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import HeaderRB from "../common/HeaderRb";
import Header from "../Header/Header";
import Spinner from "../common/Spinner";
import { Row, Col } from "reactstrap";
import SalesChart from "./SalesChart";
import {
  readMobileSalesTwoDates,
  readWeeklySales,
  getStoreDepartments
} from "../api/SalesApi";
import { ContextConfig } from "../App";
import { formatDate, addDays } from "../utils";
import { debounce } from "../fp";
import { toast } from "react-toastify";
import SalesPanel from "./SalesPanel";
import { withRouter } from "react-router-dom";
import { isMobile } from "react-device-detect";
import MultiSelect from "../common/MultiSelect";

import "./Sales.css";

const Sales = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [reRender, setReRender] = useState(false);
  const [startDate, setStartDate] = useState(addDays(new Date(), -7));
  const [endDate, setEndDate] = useState(new Date());
  const [salesLabels, setSalesLabels] = useState([]);
  const [sort, setSort] = useState("f65");
  const [chartSales, setChartSales] = useState([]);
  // eslint-disable-next-line
  const [currentStoreid, setCurrentStoreid] = useState("");
  const [sales, setSales] = useState([]);
  const [filteredSales, setFilteredSales] = useState([]);
  // eslint-disable-next-line
  const [weeklySales, setWeeklySales] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [checkedDepartments, setCheckedDepartments] = useState([]);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });
  const [storename, setStoreName] = useState("");

  const context = useContext(ContextConfig);

  useEffect(() => {
    loadSales();
    loadDepartments();

    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);

    findDivHeight();

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, [reRender, sort]);

  const findDivHeight = () => {
    const navbar = document.getElementById("master-navbar");
    const controls = document.getElementById("sales-controls");
    let usedSpace =
      navbar.getBoundingClientRect().height +
      controls.getBoundingClientRect().height +
      30;
    const salesContent = document.getElementById("sales-content");
    salesContent.style.height = window.innerHeight - usedSpace;
    // const salesChart = document.getElementById("sales-chart");
    // const salesGrid = document.getElementById("sales-grid");

    salesContent.style.height = window.innerHeight - usedSpace + "px";
    salesContent.style.overflow = "auto";
  };

  const handleUpdate = () => {
    loadSales();
  };

  const loadSales = () => {
    setIsLoading(true);
    let useGroups = 0;
    let singleStore = 0;
    if (context.lastSearchType == 2) useGroups = 1;
    if (context.lastSearchType == 3) singleStore = 1;
    const exclusions = checkedDepartments.join(",");
    readMobileSalesTwoDates(
      context.url,
      context.token,
      formatDate(startDate),
      formatDate(endDate),
      useGroups,
      context.lastSearchType == 2 ? context.lastGroup : context.lastStoreid,
      singleStore,
      sort,
      exclusions
    )
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          setSales(j.items);
          setFilteredSales(j.items);
        } else {
          setSales([]);
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setSales([]);
        setIsLoading(false);
        console.log(err);
        toast.error("An error occured processing your reqest", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const loadDepartments = () => {
    if (context.lastSearchType == 2) return;
    getStoreDepartments(context.url, context.token, context.lastStoreid)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setDepartments(j.depts);
        } else {
          toast.error(j.msg);
          setDepartments([]);
        }
      })
      .catch((err) => {
        console.log(err);
        setDepartments([]);
        toast.error("An Internal error occured");
      });
  };

  const loadWeekly = (storeid) => {
    setIsLoading(true);
    setCurrentStoreid(storeid);
    const exclusions = checkedDepartments.join(",");
    readWeeklySales(
      context.url,
      context.token,
      startDate,
      endDate,
      storeid,
      exclusions
    )
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          getLabels(j.sales);
        } else {
          setWeeklySales([]);
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setWeeklySales([]);
        setIsLoading(false);
        console.log(err);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const getLabels = (sales) => {
    const labels = sales.map((record) => {
      return formatDate(record.f254);
    });

    const f65 = sales.map((record) => {
      return record.f65;
    });

    setSalesLabels(labels);
    setChartSales(f65);
    setWeeklySales(sales);
  };

  function handleWeeklyClick(storeid) {
    loadWeekly(storeid);
    getStorename(storeid);
    setCurrentStoreid(storeid);
  }

  const getStorename = (storeid) => {
    const store = sales.find((r) => r.storeid === storeid);
    setStoreName(store.store_Name);
  };

  const redirect = (url) => {
    props.history.push(url);
  };

  const handleSortChange = (e) => {
    let orderby = "f65";
    switch (parseInt(e.target.value)) {
      case 1:
        orderby = "f65";
        break;
      case 2:
        orderby = "lastYearSales";
        break;
      case 3:
        orderby = "f64";
        break;
      case 4:
        orderby = "lastYearQty";
        break;
      case 5:
        orderby = "grossMargin";
        break;
      default:
        orderby = "f65";
        break;
    }

    setSort(orderby);
  };

  return (
    <div>
      {isLoading && <Spinner />}
      <HeaderRB />
      <div className="container-fluid sales-container" id="sales-container">
        <div id="sales-controls">
          <h3 style={{ width: "100%", textAlign: "center" }}>Sales</h3>
          <Header
            reRender={reRender}
            setReRender={setReRender}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            setLoading={setIsLoading}
            showSingleStore={true}
          />
          {isMobile ? (
            <Row id="sort mt-3 justify-content-center">
              <Col xs="6" className="mb-2">
                <select
                  className="form-control"
                  onChange={handleSortChange}
                  value={sort}
                >
                  <option value="0">Select Order by</option>
                  <option value="1">This Years Sales</option>
                  <option value="2">Last Years Sales</option>
                  <option value="3">This Years Quantity</option>
                  <option value="4">Last Years Quantity</option>
                  <option value="5">Gross Margin</option>
                </select>
              </Col>
            </Row>
          ) : (
            <div className="container">
              <Row id="sort mt-3" style={{ justifyContent: "center" }}>
                <Col xs="3" className="mb-2 mt-2">
                  <select
                    className="form-control"
                    onChange={handleSortChange}
                    value={sort}
                  >
                    <option value="0">Select Order by</option>
                    <option value="1">This Years Sales</option>
                    <option value="2">Last Years Sales</option>
                    <option value="3">This Years Quantity</option>
                    <option value="4">Last Years Quantity</option>
                    <option value="5">Gross Margin</option>
                  </select>
                </Col>
                {context.lastSearchType == 3 ? (
                  <React.Fragment>
                    <Col xs="3" className="mb-2 mt-2">
                      <MultiSelect
                        data={departments}
                        defaultOption={"Select a department to exclude"}
                        keyDescriptor={"dept"}
                        optionValue={"f03"}
                        optionText={"f238"}
                        checkedItems={checkedDepartments}
                        setCheckedItems={setCheckedDepartments}
                      />
                    </Col>
                    <Col xs="3" className="mb-2 mt-2">
                      <button
                        onClick={handleUpdate}
                        className="btn btn-primary"
                      >
                        Update
                      </button>
                    </Col>
                  </React.Fragment>
                ) : null}
              </Row>
            </div>
          )}
        </div>
        <div className="container sales-content" id="sales-content">
          {!isMobile ? (
            <Row id="sales-chart">
              <SalesChart
                sales={chartSales}
                salesLabels={salesLabels}
                storename={storename}
              />
            </Row>
          ) : null}

          <Row
            id="sales-grid"
            className="sales-grid"
            style={{ paddingBottom: "50px" }}
          >
            <SalesPanel
              stores={filteredSales}
              context={context}
              onClick={handleWeeklyClick}
              redirect={redirect}
            />
          </Row>
        </div>
      </div>
    </div>
  );
};

Sales.propTypes = {
  history: PropTypes.any
};

export default withRouter(Sales);
