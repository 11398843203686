import React, { useState, useEffect, useContext } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import HeaderRB from "../common/HeaderRb";
import MobileBanner from "./MobileBanner";
import { getGroups } from "../api/BannerApi";
import { usePrevious, addDays } from "../utils";
import { isEqual } from "lodash";
import { toast } from "react-toastify";
import { ContextConfig } from "../App";
import LoadingOverlay from "react-loading-overlay";
import BannerSalesDesktop from "./BannerSalesDesktop";

const BannerSales = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [reRender, setReRender] = useState(false);
  const [startDate, setStartDate] = useState(addDays(new Date(), -7)); // eslint-disable-line
  const [endDate, setEndDate] = useState(new Date()); // eslint-disable-line
  const [groups, setGroups] = useState([]);

  const context = useContext(ContextConfig);

  const previousGroups = usePrevious(groups);

  useEffect(() => {
    if (!isEqual(previousGroups, groups)) {
      loadData();
    }
  }, [groups, reRender]);

  const loadData = () => {
    setIsLoading(true);
    getGroups(context.url, context.token).then((response) => {
      setIsLoading(false);
      const j = response.data;
      if (j.error === 0) {
        setGroups(j.items);
      } else {
        toast.error(j.msg, {
          position: toast.POSITION.TOP_LEFT
        });
      }
    });
  };

  return (
    <LoadingOverlay active={isLoading} spinner text="Loading Data...">
      <HeaderRB />
      <div className="container-fluid">
        <MobileView>
          <MobileBanner
            setIsLoading={setIsLoading}
            reRender={reRender}
            setReRender={setReRender}
            groups={groups}
          />
        </MobileView>
        <BrowserView>
          <BannerSalesDesktop setIsLoading={setIsLoading} groups={groups} />
        </BrowserView>
      </div>
    </LoadingOverlay>
  );
};

export default BannerSales;
