/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { createRequest, getRequests, getPtoStats } from "../../api/requestapi";
import { ContextConfig } from "../../App";
import { toast } from "react-toastify";
import { formatDate } from "../../utils";
import MobileRequests from "./MobileRequests";
import DesktopRequests from "./DesktopRequests";
import "../Clock.css";

const Requests = () => {
  const [requestStart, setRequestStart] = useState(new Date());
  const [requestEnd, setRequestEnd] = useState(new Date());
  const [comment, setComment] = useState("");
  const [requests, setRequests] = useState([]);
  const [reRender, setReRender] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [requestType, setRequestType] = useState(2);
  const [requestPeriod, setRequestPeriod] = useState(2);
  const [requestDuration, setRequestDuration] = useState(1);

  const [ptoStats, setPtoStats] = useState({
    daysUsed: 0,
    remaining: 0,
    ptoDaysPerYear: 0
  });

  const context = useContext(ContextConfig);

  useEffect(() => {
    getStats();
    fetchRequests();
  }, []);

  const sendRequest = () => {
    // if a range of dates is used, we need to calculate the hours
    let duration = requestDuration;
    if (requestPeriod == "1") {
      const date1 = new Date(formatDate(requestStart));
      const date2 = new Date(formatDate(requestEnd));
      const timeDifference = date2 - date1;
      const dayDifference = timeDifference / (1000 * 60 * 60 * 24);
      duration = (dayDifference + 1) * 8;
    }

    let endDate = requestEnd;
    if (requestPeriod == "2") {
      endDate = requestStart;
    }

    createRequest(
      context.clockUrl,
      context.clockToken,
      requestStart,
      endDate,
      comment,
      requestType,
      duration
    )
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          // here we need to reload the request grid
          setComment("");
          fetchRequests();
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const fetchRequests = () => {
    getRequests(context.clockUrl, context.clockToken)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setRequests(j.requests);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const getStats = () => {
    getPtoStats(context.clockUrl, context.clockToken)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setPtoStats({
            daysUsed: j.daysUsed,
            remaining: j.remaining,
            ptoDaysPerYear: j.ptoDaysPerYear
          });
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An internal error occured procssing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const toggle = () => {
    setShowTooltip(!showTooltip);
  };

  return (
    <div>
      <MobileView>
        <MobileRequests />
      </MobileView>
      <BrowserView>
        <DesktopRequests />
      </BrowserView>
    </div>
  );
};

export default Requests;
