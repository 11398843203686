import React, { useState, useEffect, useContext } from "react";
//import colors from "../../common/colors";
// import ClockSidebar from "../ClockSidebar";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import { createRequest, getRequests, getPtoStats } from "../../api/requestapi";
import { ContextConfig } from "../../App";
import { toast } from "react-toastify";
import RequestList from "./RequestList";
import { formatDate } from "../../utils";
import { debounce } from "../../fp";
import ClockDesktopMenu from "../ClockDesktopMenu";
import "../Clock.css";

const DesktopRequests = () => {
  const [requestStart, setRequestStart] = useState(new Date());
  const [requestEnd, setRequestEnd] = useState(new Date());
  const [comment, setComment] = useState("");
  const [requests, setRequests] = useState([]);
  // const [reRender, setReRender] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [requestType, setRequestType] = useState(2);
  const [requestPeriod, setRequestPeriod] = useState(2);
  const [requestDuration, setRequestDuration] = useState(1);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const [ptoStats, setPtoStats] = useState({
    daysUsed: 0,
    remaining: 0,
    ptoDaysPerYear: 0
  });

  const context = useContext(ContextConfig);

  useEffect(() => {
    getStats();
    fetchRequests();
  }, []);

  useEffect(() => {
    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);

    setTimeout(() => {
      findDivHeight();
    }, 500);

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, [requests]);

  const findDivHeight = () => {
    const controls = document.getElementById("controls").getBoundingClientRect()
      .height;

    const usedSpace = controls + 70;
    const remaining = window.innerHeight - usedSpace;

    const widget = document.getElementById("body");
    widget.style.height = remaining + "px";
    widget.style.overflow = "auto";
  };

  const handleRequestStart = (e) => {
    setRequestStart(e);
  };

  const handleRequestEnd = (e) => {
    setRequestEnd(e);
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleClick = () => {
    sendRequest();
  };

  const handleDurationChange = (e) => {
    const duration = parseInt(e.target.value);
    setRequestDuration(duration);
    if (duration > 7 || duration < 1) {
      toast.error("The duration can only be between 1 and 7", {
        position: toast.POSITION.TOP_LEFT
      });
    }
  };

  const handleTypeChange = (e) => {
    setRequestPeriod(e.target.value);
    if (requestType == 1) {
      setRequestDuration(8);
    }
  };

  const sendRequest = () => {
    // if a range of dates is used, we need to calculate the hours
    let duration = requestDuration;
    if (requestPeriod == "1") {
      const date1 = new Date(formatDate(requestStart));
      const date2 = new Date(formatDate(requestEnd));
      const timeDifference = date2 - date1;
      const dayDifference = timeDifference / (1000 * 60 * 60 * 24);
      duration = (dayDifference + 1) * 8;
    }

    let endDate = requestEnd;
    if (requestPeriod == "2") {
      endDate = requestStart;
    }

    createRequest(
      context.clockUrl,
      context.clockToken,
      requestStart,
      endDate,
      comment,
      requestType,
      duration
    )
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          // here we need to reload the request grid
          setComment("");
          toast.success("Your request is pending", {
            position: toast.POSITION.TOP_LEFT
          });
          fetchRequests();
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const fetchRequests = () => {
    getRequests(context.clockUrl, context.clockToken)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setRequests(j.requests);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const getStats = () => {
    getPtoStats(context.clockUrl, context.clockToken)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setPtoStats({
            daysUsed: j.daysUsed,
            remaining: j.remaining,
            ptoDaysPerYear: j.ptoDaysPerYear
          });
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An internal error occured procssing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const toggle = () => {
    setShowTooltip(!showTooltip);
  };

  return (
    <div>
      <div className="clock-mobile">
        <div>
          <ClockDesktopMenu />
          <div>
            <div className="modal-hours-body container">
              <div id="controls">
                <h3
                  style={{
                    width: "100%",
                    textAlign: "center",
                    marginBottom: "5px"
                  }}
                >
                  Request Time Off
                </h3>
                <div
                  style={{
                    color: "#fff",
                    marginBottom: "10px",
                    fontSize: "1.2rem"
                  }}
                >
                  <div
                    style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
                  >
                    <span>PTO Days: {ptoStats.ptoDaysPerYear}</span>
                    <span style={{ textAlign: "right" }}>
                      Days used: {ptoStats.daysUsed}
                    </span>
                  </div>

                  <div style={{ width: "100%", textAlign: "center" }}>
                    Days remaining: {ptoStats.remaining}
                  </div>
                </div>
                <div className="container">
                  <React.Fragment>
                    <div className="row">
                      {ptoStats.remaining == "0" ? (
                        <div className="col-12">
                          <p
                            style={{
                              textAlign: "center",
                              width: "100%",
                              color: "red"
                            }}
                          >
                            Sorry, you have used up all of your PTO days for
                            this year.
                          </p>
                        </div>
                      ) : null}
                      <div className="col-12">
                        <select
                          className="form-control"
                          value={requestType}
                          onChange={(e) => setRequestType(e.target.value)}
                        >
                          {ptoStats.remaining == "0" ? null : (
                            <option value="1">PTO Request</option>
                          )}

                          <option value="2">RTO Request</option>
                        </select>
                      </div>
                    </div>
                    <div className="row mt-2 mb-2">
                      <div className="col-12">
                        <select
                          className="form-control"
                          value={requestPeriod}
                          onChange={handleTypeChange}
                        >
                          <option value="1">Full Day</option>
                          <option value="2">Partial Day</option>
                        </select>
                      </div>
                    </div>
                    {requestPeriod == "1" ? (
                      <div>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Grid container justify="space-around">
                            <div
                              className="row justify-content-center align-items-center"
                              style={{
                                textAlign: "center",
                                marginTop: "10px"
                              }}
                            >
                              <div className="col-12">
                                <KeyboardDatePicker
                                  format="MM/dd/yyyy"
                                  margin="normal"
                                  label="Start Date"
                                  value={requestStart}
                                  onChange={handleRequestStart}
                                  KeyboardButtonProps={{
                                    "aria-label": "start date"
                                  }}
                                />
                              </div>
                              <div className="col-12">
                                <KeyboardDatePicker
                                  format="MM/dd/yyyy"
                                  margin="normal"
                                  label="End Date"
                                  value={requestEnd}
                                  onChange={handleRequestEnd}
                                  KeyboardButtonProps={{
                                    "aria-label": "end date"
                                  }}
                                />
                              </div>
                            </div>
                          </Grid>
                        </MuiPickersUtilsProvider>
                      </div>
                    ) : (
                      <div>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Grid container justify="space-around">
                            <div
                              className="row justify-content-center align-items-center"
                              style={{
                                textAlign: "center",
                                marginTop: "10px"
                              }}
                            >
                              <div className="col-12">
                                <KeyboardDatePicker
                                  format="MM/dd/yyyy"
                                  margin="normal"
                                  label="Start Date"
                                  value={requestStart}
                                  onChange={handleRequestStart}
                                  KeyboardButtonProps={{
                                    "aria-label": "start date"
                                  }}
                                />
                              </div>
                              <div className="row">
                                <div
                                  className="col-8"
                                  style={{ paddingTop: "10px" }}
                                >
                                  <label>Request Hours: </label>
                                </div>
                                <div className="col-4">
                                  <input
                                    className="form-control"
                                    type="number"
                                    min="1"
                                    max="7"
                                    step="1"
                                    value={requestDuration}
                                    onChange={(e) => handleDurationChange(e)}
                                  />
                                </div>
                              </div>
                            </div>
                          </Grid>
                        </MuiPickersUtilsProvider>
                      </div>
                    )}
                    <div className="row justify-content-center mt-2 w-100 p-2">
                      <div className="col-12">
                        <label htmlFor="reason">Comment</label>
                        <textarea
                          className="form-control"
                          style={{ padding: "5px" }}
                          rows="4"
                          onChange={handleCommentChange}
                          value={comment}
                        />
                      </div>
                    </div>
                    <div className="row mt-1 w-100 p-2 justify-content-center">
                      <button
                        className="btn btn-outline-dark"
                        onClick={handleClick}
                      >
                        Submit
                      </button>
                      <button className="btn btn-outline-info" onClick={toggle}>
                        Reasons
                      </button>
                    </div>
                  </React.Fragment>
                </div>
              </div>
            </div>
            <div id="body">
              <RequestList requests={requests} showTooltip={showTooltip} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesktopRequests;
