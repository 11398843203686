import React, { useState, useEffect, useContext } from "react";
import HeaderRB from "../../common/HeaderRb";
import { ContextConfig } from "../../App";
import { readSubMargins } from "../../api/marginApi";
import { toast } from "react-toastify";
import Spinner from "../../common/Spinner";
import { formatDate } from "../../utils";
import { debounce } from "../../fp";
import { Row, Col } from "reactstrap";
import PropTypes from "prop-types";

import "./SubMargins.css";

const SubMargins = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [margins, setMargins] = useState([]);
  const [orderby, setOrderby] = useState("f04");
  const [direction, setDirection] = useState("asc");
  const [filterMin, setFilterMin] = useState("");
  const [filterMax, setFilterMax] = useState("");
  const [reRender, setReRender] = useState(false);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const context = useContext(ContextConfig);

  useEffect(() => {
    loadMargins();

    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);

    findDivHeight();

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, [orderby, direction, reRender]);

  const findDivHeight = () => {
    const navbar = document
      .getElementById("master-navbar")
      .getBoundingClientRect().height;
    const controls = document
      .getElementById("sub-margin-controls")
      .getBoundingClientRect().height;
    const usedSpace = navbar + controls + 20;

    const remaining = window.innerHeight - usedSpace;

    const body = document.getElementById("sub-margin-body");
    body.style.height = remaining + "px";
    body.style.overflow = "auto";
  };

  const handleOrderByChange = (e) => {
    setOrderby(e.target.value);
  };

  const handleDirectionChange = (e) => {
    setDirection(e.target.value);
  };

  const handleFilterMinChange = (e) => {
    setFilterMin(e.target.value);
  };

  const handleFilterMaxChange = (e) => {
    setFilterMax(e.target.value);
  };

  const handleClearFilter = () => {
    setFilterMax("");
    setFilterMin("");
    HandleUpdate();
  };

  const HandleUpdate = () => {
    if (orderby == "f04") {
      toast.warning("Please select a column to use for the min and max values");
      return;
    }
    setReRender(!reRender);
  };

  const handleRowClick = (record) => {
    context.marginSub = record.f04;
    context.marginSubDescription = record.f1022;
    props.history.push("/sales/subitems");
  };

  const loadMargins = () => {
    setIsLoading(true);
    readSubMargins(
      context.url,
      context.token,
      context.marginStoreid,
      context.marginDate,
      orderby,
      direction,
      filterMin,
      filterMax
    )
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          setMargins(j.subs);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        toast.error("An error occured getting sub Margins from the server", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };
  return (
    <div>
      {isLoading && <Spinner />}
      <HeaderRB />
      <div className="container-fluid sales-container" id="sales-container">
        <div id="sub-margin-controls">
          <h3>Subdepartment Margins</h3>
          <div className="container">
            <Row>
              <Col xs="4">
                <select
                  className="form-control"
                  value={orderby}
                  onChange={handleOrderByChange}
                >
                  <option value="f04">Sub Department</option>
                  <option value="retail">Retail</option>
                  <option value="margin">Margin</option>
                  <option value="grossprofit">Gross Profit</option>
                  <option value="markup">Markup</option>
                  <option value="f1301">Cost</option>
                  <option value="f65">Sales</option>
                  <option value="f64">Quantity</option>
                  <option value="retail">Retail</option>
                </select>
              </Col>
              <Col xs="4">
                <div className="marginstore">{context.marginStore}</div>
              </Col>
              <Col xs="4">
                <Row className="cat-filters">
                  <Col xs="5">
                    <span className="filtermin">Filter Min:</span>
                    <span className="filterminvalue">
                      <input
                        type="number"
                        className="form-control"
                        value={filterMin}
                        min="-100"
                        max="99999"
                        onChange={handleFilterMinChange}
                      />
                    </span>
                  </Col>
                  <Col xs="5">
                    <span className="filtermax">Filter Max:</span>
                    <span className="filtermaxvalue">
                      <input
                        type="number"
                        className="form-control"
                        min="0"
                        max="99999"
                        value={filterMax}
                        onChange={handleFilterMaxChange}
                      />
                    </span>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xs="4">
                {" "}
                <select
                  className="form-control"
                  value={direction}
                  onChange={handleDirectionChange}
                >
                  <option value="asc">Ascending</option>
                  <option value="desc">Descending</option>
                </select>
              </Col>
              <Col xs="4">
                <div className="margindate">
                  {formatDate(context.marginDate)}
                </div>
              </Col>
              <Col xs="4">
                <Row className="cat-buttons">
                  <Col xs="3">
                    <button className="catmarginButton" onClick={HandleUpdate}>
                      Update
                    </button>
                  </Col>
                  <Col xs="3">
                    <button
                      className="submarginButton"
                      onClick={handleClearFilter}
                    >
                      Clear
                    </button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div className="marginrecords">{margins.length} records returned</div>
        </div>
        <div id="sub-margin-body">
          {margins.length > 0 ? (
            <table className="table table-striped margin-table">
              <thead>
                <tr>
                  <th scope="col">SubDepartment</th>
                  <th scope="col">Qty</th>
                  <th scope="col">Sales</th>
                  <th scope="col">Cost</th>
                  <th scope="col">Retail</th>
                  <th scope="col">Profit</th>
                  <th scope="col">Margin</th>
                </tr>
              </thead>
              <tbody>
                {margins.map((record, index) => (
                  <tr
                    key={`margin-${index}`}
                    onClick={() => handleRowClick(record)}
                    className="sub-item-margin-row"
                  >
                    <td>{record.f1022}</td>
                    <td>{record.f64}</td>
                    <td>{parseFloat(record.f65).toFixed(2)}</td>
                    <td>{parseFloat(record.cost).toFixed(2)}</td>
                    <td>{parseFloat(record.retail).toFixed(2)}</td>
                    <td>{parseFloat(record.grossProfit).toFixed(2)}</td>
                    <td>{parseFloat(record.margin).toFixed(2)}%</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="emptymargins">
              There were no records returned for this date and store
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
SubMargins.propTypes = {
  history: PropTypes.any
};

export default SubMargins;
