import React, { useEffect } from "react";
import PropTypes from "prop-types";

import "./MobileReports.css";

const MobileReports = ({ reports, handleClick }) => {
  useEffect(() => {}, [reports]);

  return (
    <table className="table table-striped table-hover scrollable-report-list">
      <thead>
        <tr>
          <th scope="col">Name</th>
        </tr>
      </thead>
      <tbody>
        {reports.length === 0 ? (
          <tr>
            <td>No records to display</td>
          </tr>
        ) : (
          reports.map((report, index) => (
            <tr
              key={index}
              className="reportrow"
              onClick={() => handleClick(report)}
            >
              <td scope="row">{report.report_Name}</td>
            </tr>
          ))
        )}
      </tbody>
    </table>
  );
};

MobileReports.propTypes = {
  reports: PropTypes.array,
  handleClick: PropTypes.func
};

export default MobileReports;
