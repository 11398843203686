import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import ModalLogin from "../widgets/ModalLogin";
import { checkManagerLogin } from "../../api/UsersApi";
import { ContextConfig } from "../../App";
import { toast } from "react-toastify";
import { Redirect, withRouter } from "react-router-dom";
import "./ClockInButtons.css";

const ClockinButtons = (props) => {
  const [taps, setTaps] = useState(0);
  const [showModalLogin, setShowModalLogin] = useState(false);
  const [goHome, setGoHome] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordMode, setPasswordMode] = useState(false);
  const [fakeText, setFakeText] = useState("");
  const [displayText, setDisplayText] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);

  const context = useContext(ContextConfig);

  useEffect(() => {
    if (taps > 5) {
      setTaps(0);
      setShowModalLogin(true);
      return;
    }
    if (loggedIn) {
      checkLogin();
    }
  }, [taps, loggedIn]);

  useEffect(() => {}, [props.managerLevel]);

  const handleTap = () => {
    setTaps(taps + 1);
  };

  const hideModalLogin = () => {
    setShowModalLogin(false);
  };

  const checkLogin = () => {
    checkManagerLogin(
      context.clockUrl,
      context.clockToken,
      username,
      password,
      context.settings.storeid
    )
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          if (j.cnt == 0) {
            toast.error("Could not find your user for this store", {
              position: toast.POSITION.TOP_LEFT
            });
            setLoggedIn(false);
            setTaps(0);
            setUsername("");
            setPassword("");
          } else {
            const user = j.users[0];

            if (parseInt(user.userLevel) >= 5) {
              // now we need to redirect back to the home screen
              setManager(user);
            } else {
              toast.error("You do not have permission", {
                position: toast.POSITION.TOP_LEFT
              });
              setLoggedIn(false);
              setTaps(0);
              setUsername("");
              setPassword("");
            }
          }
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
          setLoggedIn(false);
          setTaps(0);
          setUsername("");
          setPassword("");
        }
      })
      .catch((err) => {
        setLoggedIn(false);
        setTaps(0);
        setUsername("");
        setPassword("");
        console.log(err);
        // check localStorage
        const localStorageManagers = JSON.parse(
          localStorage.getItem("managers")
        );
        if (localStorageManagers && localStorageManagers.length > 0) {
          const currentManager = localStorageManagers.find(
            (m) => m.userid == username && m.clockPassword == password
          );
          if (currentManager) {
            setManager(currentManager);
            return;
          }
        }
        toast.error("An error occured verifying your user", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const setManager = (user) => {
    setShowModalLogin(false);
    props.setShowDesktopButton(true);
    props.setManagerLevel(parseInt(user.userLevel));

    // then we need to reset our state so it does not perisist
    setUsername("");
    setPassword("");
    setLoggedIn(false);
    setTaps(0);
  };

  const handleButtonClick = (e) => {
    switch (e) {
      case "E":
        processEntry();
        break;
      case "B":
        setDisplayText(displayText.substring(0, displayText.length - 1));
        break;
      case "C":
        setDisplayText("");
        setPasswordMode(false);
        setFakeText("");
        setUsername("");
        setPassword("");
        setLoggedIn(false);
        setTaps(0);
        break;
      default:
        if (displayText.toUpperCase() == "ENTER PASSWORD") {
          setPasswordMode(true);
          setDisplayText(e);
          setFakeText("*");
        } else {
          setDisplayText(`${displayText}${e}`);
        }
        if (passwordMode) {
          setFakeText(`${fakeText}*`);
        }

        break;
    }
  };

  const processEntry = () => {
    if (username.length === 0) {
      // this not going to be our username
      setUsername(displayText);
      setDisplayText("Enter Password");
      props.setStartTimer(true);
    } else {
      // we are entering the password
      setPassword(displayText);
      setDisplayText("");
      setFakeText("");
      setPasswordMode(false);
      setLoggedIn(true);
    }
  };

  const handleManagerMode = (enabled) => {
    props.setManagerMode(enabled);
    props.setShowDesktopButton(false);
    props.hideStats();
  };

  return (
    <div className="clock-in-function-buttons">
      {goHome && <Redirect to="clockhome" />}
      {showModalLogin && (
        <ModalLogin
          isShowing={showModalLogin}
          hide={hideModalLogin}
          handleButtonClick={handleButtonClick}
          passwordMode={passwordMode}
          fakeText={fakeText}
          displayText={displayText}
        />
      )}
      <div className="col m-auto">
        {props.status === 0 && props.currentStore ? (
          <div className="clock-btn" onClick={props.clockin}>
            Clock In
          </div>
        ) : (
          <React.Fragment>
            {props.status === 1 && props.currentStore ? (
              <React.Fragment>
                {context.settings.enableBreak == "1" ? (
                  <div className="clock-btn" onClick={props.startBreak}>
                    Start Break
                  </div>
                ) : null}
                {context.settings.enableLunch == "1" ? (
                  <div className="clock-btn" onClick={props.startLunch}>
                    Go to Lunch
                  </div>
                ) : null}
                <div className="clock-btn" onClick={props.clockOut}>
                  Clock Out
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {props.status === 2 && props.currentStore ? (
                  <div className="clock-btn" onClick={props.finishBreak}>
                    Back from Break
                  </div>
                ) : (
                  <React.Fragment>
                    {props.status === 3 && props.currentStore ? (
                      <div className="clock-btn" onClick={props.finishLunch}>
                        Back from Lunch
                      </div>
                    ) : null}
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>
      {process.env.NODE_ENV === "development" ? (
        <div>
          <div>Taps: {taps}</div>
          <div>isLogged In: {loggedIn}</div>
        </div>
      ) : null}
      {props.showDesktopButton ? (
        <React.Fragment>
          <div className="clock-btn" onClick={() => setGoHome(true)}>
            Go to Desktop
          </div>
          <div className="clock-btn" onClick={() => handleManagerMode(true)}>
            Manager Override
          </div>
        </React.Fragment>
      ) : null}
      {props.managerLevel >= 8 ? (
        <div className="clock-btn" onClick={() => props.showStats()}>
          Show Stats
        </div>
      ) : null}
      <div className="d-clock-magic" onClick={handleTap}>
        <i className="fas fa-user-lock fa-3x"></i>
      </div>
    </div>
  );
};

ClockinButtons.propTypes = {
  status: PropTypes.any,
  currentStore: PropTypes.any,
  startBreak: PropTypes.func,
  finishBreak: PropTypes.func,
  startLunch: PropTypes.func,
  finishLunch: PropTypes.func,
  clockOut: PropTypes.func,
  clockin: PropTypes.func,
  setShowModalLogin: PropTypes.func,
  history: PropTypes.object,
  setStartTimer: PropTypes.func,
  setManagerMode: PropTypes.func,
  showDesktopButton: PropTypes.bool,
  setShowDesktopButton: PropTypes.func,
  showStats: PropTypes.func,
  managerLevel: PropTypes.any,
  setManagerLevel: PropTypes.func,
  hideStats: PropTypes.func
};

export default withRouter(ClockinButtons);
