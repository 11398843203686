import React, { useState, useEffect, useContext } from "react";
import { PropTypes } from "prop-types";
import { checkEmail, checkAnswer, setForgotPassword } from "../api/UsersApi";
import { ContextConfig } from "../App";
import { toast } from "react-toastify";
import SuccessSvg from "../assets/img/success.svg";
import ErrorSvg from "../assets/img/error.svg";
import PasswordStrengthMeter from "../common/PasswordStrengthMeter";
import { encrypt } from "../utils";

const Forgot = ({ cancel, setForgot }) => {
  const [email, setEmail] = useState("");
  const [userid, setUserid] = useState("");
  const [step2, setStep2] = useState(false);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [step3, setStep3] = useState(false);
  const context = useContext(ContextConfig);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (email) {
        testEmail();
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [email]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (answer) {
        testAnswer();
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [answer]);

  const testEmail = () => {
    checkEmail(context.url, email)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          if (j.question.length > 0) {
            setUserid(j.userid);
            setQuestion(j.question);
            setStep2(true);
          } else {
            toast.error(
              "You dont seem to have a security question set. Contact administrator",
              {
                position: toast.POSITION.TOP_RIGHT,
                duration: 8000
              }
            );
          }
        } else {
          if (j.error == "99") return;
          toast.error(j.msg, {
            position: toast.POSITION.TOP_RIGHT,
            duration: 8000
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An internal error occured", {
          position: toast.POSITION.TOP_RIGHT
        });
      });
  };

  const testAnswer = () => {
    checkAnswer(context.url, userid, answer)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setStep3(true);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An internal error occured");
      });
  };

  const confirmPasswordChange = (password) => {
    console.log("confirm", password);
    const encryptedPassword = encrypt(password, context.key).toString();
    setForgotPassword(context.url, userid, encryptedPassword)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setForgot(false);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An internal error occured");
      });
  };

  return (
    <div style={{ marginTop: "5px" }}>
      {!step2 ? (
        <div className="row mt-5 justify-content-center">
          <div className="col-12">
            <label>Enter Email</label>
            <div style={{ display: "flex" }}>
              <input
                type="text"
                className="form-control"
                name="forgot-email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{ width: "90%" }}
              />
              <span
                style={{
                  width: "10%",
                  marginLeft: "10px",
                  marginTop: "3px",
                  textAlign: "center"
                }}
              >
                {step2 ? (
                  <img src={SuccessSvg} height="30px" alt="success" />
                ) : (
                  <img src={ErrorSvg} height="30px" alt="error" />
                )}
              </span>
            </div>
          </div>
        </div>
      ) : null}

      {step2 && !step3 ? (
        <React.Fragment>
          <div className="row justify-content-center mt-3">
            <div className="row d-flex justify-content-center align-items-center">
              <div className="row flex-d justify-content-center align-items-center">
                <label>
                  Question:{" "}
                  <span style={{ fontSize: "1.2rem", fontWeight: "600" }}>
                    {question}
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 d-flex justify-content-center align-items-center">
              <input
                type="text"
                className="form-control"
                value={answer}
                onChange={(e) => setAnswer(e.target.value)}
                style={{ width: "90%" }}
              />
              <span
                style={{
                  width: "10%",
                  marginLeft: "10px",
                  marginTop: "3px",
                  textAlign: "center"
                }}
              >
                {step3 ? (
                  <img src={SuccessSvg} height="30px" alt="success" />
                ) : (
                  <img src={ErrorSvg} height="30px" alt="error" />
                )}
              </span>
            </div>
          </div>
        </React.Fragment>
      ) : null}
      {step3 ? (
        <div className="row justify-content-center align-items-center">
          <div className="col-12 d-flex justify-content-center align-items-center">
            <PasswordStrengthMeter
              userid={+userid}
              confirmPasswordChange={confirmPasswordChange}
            />
          </div>
        </div>
      ) : null}
      <div className="row justify-content-center mt-2">
        <div className="col-12 d-flex justify-content-center align-items-center">
          <button className="btn btn-danger" onClick={cancel}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

Forgot.propTypes = {
  cancel: PropTypes.func,
  setForgot: PropTypes.func
};

export default Forgot;
