import React from "react";

import "./BoxLoader.scss";

const BoxLoader = () => {
  return (
    <div className="box-loader-container">
      <div className="box-body">
        <div className="boxes-loader">
          <div className="box-loader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className="box-loader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className="box-loader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className="box-loader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoxLoader;
