import React, { useContext, useEffect } from "react";
import { ContextConfig } from "../App";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { TrueUpErrors } from "./Freshop-I";
import FreshopCard from "./FreshopCard";

import * as actions from "../actions/actionTypes";

const TrueUpError = () => {
  const freshopState = useSelector((app) => app.freshopReducer);
  const dispatch = useDispatch();

  const context = useContext(ContextConfig);

  useEffect(() => {
    if (freshopState.trueupErrorMsg == "An Internal error occured") return;
    getTrueUpErrors();

    if (freshopState.trueupErrorMsg) {
      toast.warning(freshopState.trueupErrorMsg, {
        position: toast.POSITION.TOP_LEFT
      });
      dispatch({ type: actions.FRESHOP_SET_TRUEUP_ERROR_MSG, error: null });
    }
  }, [
    freshopState.selectedStore.id,
    freshopState.startDate,
    freshopState.endDate,
    freshopState.trueupErrorMsg
  ]);

  const getTrueUpErrors = () => {
    if (freshopState.selectedStore.id) {
      TrueUpErrors(
        context.freshopApi,
        freshopState.selectedStore.id,
        freshopState.startDate,
        freshopState.endDate,
        context.freshopAppKey,
        dispatch
      );
    }
  };

  return (
    <FreshopCard
      headerText={"True Up Errors"}
      count={freshopState.trueupErrors.length}
      state={freshopState}
      handler={"showTrueupErrors"}
      actionType={"SHOW_TRUEUP_ERRORS"}
    />
  );
};

export default TrueUpError;
