import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { formatDate, convertAmount } from "../utils";
import {
  showFlags,
  showKeysFunction
} from "../Cashiers/Transaction/TransUtils";
import * as actions from "../actions/actionTypes";

import "./Transaction.css";

const Transaction = ({ handleEmail, state, dispatch }) =>
  state.showTransaction
    ? ReactDOM.createPortal(
        <React.Fragment>
          <div className="modal-trans-overlay" />
          <div
            className="modal-trans-wrapper"
            aria-modal
            aria-hidden
            tabIndex={-1}
            role="dialog"
          >
            <div className="modal-trans">
              <div className="modal-trans-header">
                <span className="modal-trans-confirm-title">Transaction</span>
                <span className="modal-trans-close-box">
                  <button
                    type="button"
                    className="modal-trans-close-button"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() =>
                      dispatch({
                        type: actions.REF_SHOW_TRANSACTION,
                        showTransaction: false
                      })
                    }
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </span>
              </div>
              <div className="modal-trans-body">
                <div className="container d-t-body" id="d-t-body">
                  {state.hdr.length === 0 ? (
                    <div>There are no records to Display</div>
                  ) : (
                    state.hdr.map((record, index) => (
                      <div className="d-t-d-body" key={index}>
                        <div className="m-trans-hdr-1">
                          <div className="m-trans-type">
                            Type:{record.f1068}
                          </div>
                          <div className="m-trans-number">
                            Transaction: {record.f1032}
                          </div>
                        </div>
                        <div className="m-trans-hdr-2">
                          <div className="m-trans-date">
                            Date: {formatDate(record.f254)}
                          </div>
                          <div className="m-trans-invoice">
                            Invoice: {record.f1764}
                          </div>
                        </div>
                        <div className="m-trans-hdr-3">
                          <div className="m-trans-time">
                            Time: {record.transStartTime} -{" "}
                            {record.transEndTime}
                          </div>
                          <div className="m-trans-term">{record.f1056}</div>
                        </div>
                        <div className="m-trans-cashier">
                          Cashier: {record.cashier}
                        </div>
                        <div className="d-trans-hdr-4">
                          {state.showKeys ? (
                            <div>
                              <button
                                className="btn btn-outline-dark"
                                onClick={() =>
                                  dispatch({
                                    type: actions.REF_SHOW_KEYS,
                                    showKeys: false
                                  })
                                }
                              >
                                Hide Keys
                              </button>
                            </div>
                          ) : (
                            <div>
                              <button
                                className="btn btn-outline-dark"
                                onClick={() =>
                                  dispatch({
                                    type: actions.REF_SHOW_KEYS,
                                    showKeys: true
                                  })
                                }
                              >
                                Keystrokes
                              </button>
                            </div>
                          )}
                          <div className="m-trans-email">
                            <button
                              className="btn btn-outline-dark"
                              onClick={() =>
                                handleEmail(
                                  record.f1032,
                                  record.storeid,
                                  state.hdr[0].cashier,
                                  state.hdr[0].f254,
                                  state.hdr[0].f1056
                                )
                              }
                            >
                              Email
                            </button>
                          </div>
                        </div>

                        <div className="exc-trans-body">
                          <div className="m-trans-reg">
                            {state.reg.length === 0
                              ? null
                              : state.reg.map((rec, index) => (
                                  <table
                                    key={`table-${state.hdr[0].f1032}-${rec.f1101}`}
                                    className="m-trans-table"
                                  >
                                    <tbody>
                                      <tr key={`reg-${rec.f1101}-${index}`}>
                                        <td className="m-trans-f01">
                                          {rec.f01}
                                        </td>
                                        <td className="m-trans-f1041">
                                          {rec.f1041}
                                        </td>
                                        <td className="m-trans-f64">
                                          {rec.f64}
                                        </td>
                                        <td className="m-trans-f65">
                                          {convertAmount(rec.f65)}
                                        </td>
                                        {/*<td className="m-trans-f67">{setDecimal(rec.f67)}</td>*/}
                                        <td className="m-trans-flags">
                                          {showFlags(
                                            rec.f79,
                                            rec.f178,
                                            rec.f81,
                                            rec.f96,
                                            rec.f97,
                                            rec.f98
                                          )}
                                        </td>
                                      </tr>
                                      {state.showKeys === false
                                        ? null
                                        : showKeysFunction(rec).map(
                                            (result, index) => (
                                              <tr
                                                className="m-trans-keystroke"
                                                key={`key-${rec.f1101}[${index}]`}
                                              >
                                                <td>{result.name}</td>
                                                <td colSpan="4">
                                                  {result.value}
                                                </td>
                                              </tr>
                                            )
                                          )}
                                    </tbody>
                                  </table>
                                ))}
                          </div>
                          <div className="m-trans-ttl">
                            {state.ttl.length === 0
                              ? null
                              : state.ttl.map((total, index) => (
                                  <table
                                    className="m-trans-ttl-table"
                                    key={index}
                                  >
                                    <tbody>
                                      <tr>
                                        <td className="m-trans-f1034">
                                          {total.f1034}
                                        </td>
                                        <td className="m-trans-f1039">
                                          {total.f1039}
                                        </td>
                                        <td className="m-trans-ttl-f64">
                                          {total.f64}
                                        </td>
                                        <td className="m-trans-ttl-f65">
                                          {convertAmount(total.f65)}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                ))}
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>,
        document.body
      )
    : null;

Transaction.propTypes = {
  isShowing: PropTypes.bool,
  hide: PropTypes.func,
  hdr: PropTypes.array,
  reg: PropTypes.array,
  ttl: PropTypes.array,
  showKeys: PropTypes.func,
  handleHideKeys: PropTypes.func,
  handleEmail: PropTypes.func,
  state: PropTypes.object,
  dispatch: PropTypes.func
};

export default Transaction;
