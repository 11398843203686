import React from "react";
import PropTypes from "prop-types";

import "./Tools.css";

const Tools = ({ copyWeek, clearWeek, publishWeek }) => {
  return (
    <div className="s-tools-dropdown">
      <span>
        <i className="far fa-toolbox"></i>
      </span>
      <div className="s-tools-dropdown-content">
        <p className="s-tools-item" onClick={copyWeek}>
          Copy Week
        </p>
        <p className="s-tools-item" onClick={clearWeek}>
          Clear Week
        </p>
        <p className="s-tools-item" onClick={publishWeek}>
          Publish Week
        </p>
      </div>
    </div>
  );
};

Tools.propTypes = {
  copyWeek: PropTypes.func,
  clearWeek: PropTypes.func,
  publishWeek: PropTypes.func
};

export default Tools;
