import React, { useState, useContext, useEffect } from "react";
import { ContextConfig } from "../App";
import PropTypes from "prop-types";
import { readGroups } from "../api/GroupApi";
import { toast } from "react-toastify";

const GroupPicker = ({ groupCallback, updateState }) => {
  const [mygroups, setMyGroups] = useState([]);
  const context = useContext(ContextConfig);

  useEffect(() => {
    readGroups(
      context.url,
      context.token,
      (response) => {
        var j = response.data;
        if (j.error === 0) {
          setMyGroups(j.groups);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      },
      (err) => {
        console.log(err);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      }
    );
  }, [context.token, context.url, updateState]);

  const onComboChange = (e) => {
    groupCallback(e.target.value);
  };

  return (
    <div className="row">
      <form style={{ width: "100%" }}>
        <div className="form-group">
          <label htmlFor="combogroups">Select a Group:</label>
          <select
            className="form-control"
            id="combogroups"
            onChange={onComboChange}
          >
            <option key="0" value="0">
              Please select a Group to manage links
            </option>
            {mygroups.length === 0 ? (
              <option key="1">
                No Groups to Display. Please Create a Group.
              </option>
            ) : (
              mygroups.map((group) => {
                return (
                  <option key={group.id} value={group.id}>
                    {group.group_name}
                  </option>
                );
              })
            )}
          </select>
        </div>
      </form>
    </div>
  );
};

GroupPicker.propTypes = {
  groupCallback: PropTypes.func,
  updateState: PropTypes.bool
};

export default GroupPicker;
