import React, { useState } from "react";
import Tab from "./Tab";
import PropTypes from "prop-types";
import "./Tabs.css";

const Tabs = (props) => {
  const [activeTab, setActiveTab] = useState(props.children[0].props.label);

  const handleTabClick = (tab) => {
    setActiveTab(tab.label);
  };

  return (
    <div className="tabs">
      <ol className="tab-list">
        {props.children.map((child, index) => (
          <Tab
            activeTab={activeTab}
            key={`tabs-li-${index}`}
            label={child.props.label}
            disabled={child.props.disabled}
            onClick={handleTabClick}
            id={child.props.id}
          />
        ))}
      </ol>
      <div className="tab-content">
        {props.children.map((child) => {
          if (child.props.label !== activeTab) return undefined;
          return child.props.children;
        })}
      </div>
    </div>
  );
};

Tabs.propTypes = {
  children: PropTypes.array
};

export default Tabs;
