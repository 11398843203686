import React, { useState, useEffect, useContext } from "react";
import HeaderRB from "../../common/HeaderRb";
import MobileReportController from "../MobileReportController";
import { usePrevious, formatDate } from "../../utils";
import { ContextConfig } from "../../App";
import { readManagerOverridePunches } from "../../api/ReportsApi";
import { toast } from "react-toastify";
import Spinner from "../../common/Spinner";
import { debounce, isEqual } from "../../fp";

const ManagerOverridePunches = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [rerender, setRerender] = useState(false);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const context = useContext(ContextConfig);

  const previousData = usePrevious(data);
  const previousRerender = usePrevious(rerender);

  useEffect(() => {
    if (!isEqual(previousData, data)) {
      loadData();
    }
    if (!isEqual(previousRerender, rerender)) {
      loadData();
    }
    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);

    findDivHeight();

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, [data, rerender]);

  const findDivHeight = () => {
    const navbar = document
      .getElementById("master-navbar")
      .getBoundingClientRect().height;
    const controls = document
      .getElementById("r-controls")
      .getBoundingClientRect().height;
    const usedSpace = navbar + controls + 30;
    const remaining = window.innerHeight - usedSpace;

    const body = document.getElementById("r-body");
    body.style.height = remaining + "px";
    body.style.overflow = "auto";
  };

  const loadData = () => {
    setIsLoading(true);
    readManagerOverridePunches(
      context.url,
      context.token,
      context.endDate,
      context.lastStoreid
    )
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          setData(j.items);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  return (
    <React.Fragment>
      {isLoading && <Spinner />}
      <HeaderRB />
      <div className="container-fluid">
        <div id="r-controls">
          <div className="report-spacer">&nbsp;</div>
          <MobileReportController
            rerender={rerender}
            setRerender={setRerender}
          />

          <div className="rpt-name">Manager Punch Overrides</div>
        </div>
        <div id="r-body">
          {data.length === 0 ? (
            <div>No records were returned</div>
          ) : (
            <table>
              <thead>
                <tr>
                  <th style={{ width: "20%" }}>Out</th>
                  <th style={{ width: "20%" }}>In</th>
                  <th style={{ width: "20%" }}>Username</th>
                  <th style={{ width: "20%" }}>Manager Name</th>
                  <th style={{ width: "20%" }}>Posted</th>
                </tr>
              </thead>
              <tbody>
                {data.map((record, index) => (
                  <tr key={index}>
                    <td>{record.out}</td>
                    <td>{record.in}</td>
                    <td>{record.username}</td>
                    <td>{record.manager_Name}</td>
                    <td>{formatDate(record.posted)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ManagerOverridePunches;
