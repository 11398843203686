import React from "react";
import PropTypes from "prop-types";
import { formatDate } from "../../utils";
import ReactTooltip from "react-tooltip";

const ReviewThumbnail = (props) => {
  const style = {
    backgroundColor: props.backgroundColor,
    borderRadius: "10px",
    marginRight: "10px",
    padding: "10px",
    cursor: "pointer",
    marginBottom: "10px",
    minWidth: "110px",
    color: "#fff",
    WebkitBoxShadow:
      "7px 7px 5px 0px rgba(0,0,0,0.29), inset 5px 5px 29px 4px rgba(0, 0, 0, 0.29)",
    MozBoxShadow:
      "7px 7px 5px 0px rgba(0,0,0,0.29), inset 5px 5px 29px 4px rgba(0, 0, 0, 0.29)",
    boxShadow:
      "7px 7px 5px 0px rgba(0,0,0,0.29), inset 5px 5px 29px 4px rgba(0, 0, 0, 0.29)"
  };

  return (
    <div className="open-punch-thumbnail" style={style}>
      <ReactTooltip />
      <div className="opt-header" style={{ display: "flex" }}>
        <div className="opt-id" style={{ flex: "0 0 85%" }}>
          {props.record.id}
        </div>
        <div style={{ flex: "1", display: "flex", justifyContent: "end" }}>
          <span
            className="opt-edit"
            style={{ cursor: "pointer" }}
            data-tip="Edit"
            onClick={() => props.handleReview(props.record)}
          >
            <i className="far fa-edit"></i>
          </span>
        </div>
      </div>
      <div className="opt-body">
        <div className="opt-user">
          {props.record.firstName} {props.record.lastName}
        </div>
        <div className="opt-date">
          {formatDate(props.record.requestStart)}-
          {formatDate(props.record.requestEnd)}
        </div>
      </div>
    </div>
  );
};

ReviewThumbnail.propTypes = {
  backgroundColor: PropTypes.string,
  handleReview: PropTypes.func,
  record: PropTypes.object
};

export default ReviewThumbnail;
