import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { formatDate } from "../utils";
import Tooltip from "../Tooltip/Tooltip";
import * as actions from "../actions/actionTypes";

import "./ModalRefunds.css";

const ModalRefunds = ({
  isShowing,
  hide,
  transactions,
  Csv,
  subs,
  handleSubChange,
  inclusionList,
  inclusionType,
  handleInclusionTypeChange,
  handleUpdateResults,
  handleTransactionClick,
  dispatch,
  state
}) =>
  isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <div className="modal-punch-overlay" />
          <div
            className="modal-punch-wrapper"
            aria-modal
            aria-hidden
            tabIndex={-1}
            role="dialog"
          >
            <div className={`modal-punch`} id="modal-punch">
              <div className="modal-punch-header">
                <span className="modal-punch-confirm-title">Transactions</span>
                <span className="modal-punch-close-box">
                  <button
                    type="button"
                    className="modal-punch-close-button"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={hide}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </span>
              </div>
              <div className="modal-punch-body">
                <div
                  className="trans-count"
                  style={{ color: "limegreen", marginBottom: "10px" }}
                >
                  {transactions.length} total Transactions showing
                </div>
                <div>
                  <p>
                    Use the controls below to either include or exclude certain
                    Sub Departments
                  </p>
                  <select
                    className="form-control"
                    style={{ marginTop: "10px" }}
                    value={inclusionList}
                    onChange={handleSubChange}
                  >
                    <option value="0">Select a Sub Department</option>
                    {subs.map((sub, i) => (
                      <option key={`me-s-${i}`} value={sub.id}>
                        {sub.description}
                      </option>
                    ))}
                  </select>
                  <select
                    className="form-control"
                    style={{ marginTop: "10px", marginBottom: "10px" }}
                    value={inclusionType}
                    onChange={handleInclusionTypeChange}
                  >
                    <option value="0">Select a type</option>
                    <option value="1">Include only this Sub Department</option>
                    <option value="2">Exclude this Sub Department</option>
                  </select>
                </div>
                <div className="buttons" style={{ marginBottom: "10px" }}>
                  <div className="row justify-content-center">
                    <div className="col d-flex justify-content-center">
                      <button
                        className="btn btn-info"
                        onClick={handleUpdateResults}
                      >
                        Update Results
                      </button>
                    </div>
                    {state.showBackpack ? (
                      <div className="col d-flex justify-content-center">
                        <Tooltip
                          message={
                            "Unpack your back pack to get your original data set"
                          }
                          position={"bottom"}
                        >
                          <button
                            className="btn btn-info"
                            onClick={() =>
                              dispatch({ type: actions.REF_RETREIVE_BACKPACK })
                            }
                          >
                            <i className="far fa-backpack"></i>
                          </button>
                        </Tooltip>
                      </div>
                    ) : null}

                    <div className="col d-flex justify-content-center">
                      <button className="btn btn-primary" onClick={Csv}>
                        Export to Csv
                      </button>
                    </div>
                  </div>
                </div>
                {transactions.map((transaction, index) => (
                  <div key={`mt-${index}`}>
                    <div
                      onClick={() => handleTransactionClick(transaction)}
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        padding: "5px",
                        backgroundColor: `${index % 2 === 0 ? "#456" : null}`,
                        marginBottom: "5px",
                        cursor: "pointer"
                      }}
                    >
                      <div>
                        <div>Transaction: {transaction.f1032}</div>
                        <div>Date: {formatDate(transaction.f254)}</div>
                        <div>
                          ItemCount:{" "}
                          {parseFloat(transaction.itemCount).toFixed(0)}
                        </div>
                        <div>Cashier: {transaction.f1127}</div>
                      </div>
                      <div>
                        <div>Terminal: {transaction.f1057}</div>
                        <div>Time: {transaction.transactionTime}</div>
                        <div>
                          Net Sales: {parseFloat(transaction.total).toFixed(2)}
                        </div>
                        <div>
                          Tlz Total:{" "}
                          {parseFloat(transaction.tlzTotal).toFixed(2)}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </React.Fragment>,
        document.body
      )
    : null;

ModalRefunds.propTypes = {
  isShowing: PropTypes.bool,
  hide: PropTypes.func,
  transactions: PropTypes.array,
  Csv: PropTypes.func
};

export default ModalRefunds;
