import React from "react";
import store from "./reduxStore";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import "react-toastify/dist/ReactToastify.css";
import PropTypes from "prop-types";
import { isMobile } from "react-device-detect";
import LoginPage from "./Login/LoginPage";
import HomePage from "./HomePage/Homepage";
import Groups from "./Groups/Groups";
import Cashiers from "./Cashiers/Stores/Cashiers";
import CashierFlyout from "./Cashiers/CashierFlyout/CashierFlyout";
import PayServer from "./PayServer/PayServer";
import ResetPassword from "./Reset/ResetPassword";
import PageNotFound from "./PageNotFound";
import AddCashier from "./AddCashier/AddCashier";
import Sales from "./Sales/Sales";
import Reports from "./Reports/Reports";
import ReportViewer from "./Reports/ReportViewer";
import Hourly from "./Hourly/Hourly";
import Totalizers from "./Cashiers/Totalizers/Totalizers";
import Transactions from "./Cashiers/Transactions/Transactions";
import Transaction from "./Cashiers/Transaction/Transaction";
import PointHistory from "./PayServer/PointHistory";
import CryptoTest from "./Login/Cryptotest";
import StoreMulti from "./Reports/mobile_components/StoreMulti";
import AceRewards from "./Reports/mobile_components/AceRewards";
import DeptSingle from "./Reports/mobile_components/DeptSingle";
import GroupSingle from "./Reports/mobile_components/GroupSingle";
import OperatorMulti from "./Reports/mobile_components/OperatorMulti";
import ManagerOverridePunches from "./Reports/mobile_components/ManagerOveridePunches";
import Overtime from "./Reports/mobile_components/Overtime";
import Division from "./Reports/mobile_components/Division";
import Balancing from "./Reports/mobile_components/Balancing";
import SubDepartments from "./Reports/mobile_components/SubDepartments";
import dStoreMulti from "./Reports/desktop_components/dStoreMulti";
import dAceScanReport from "./Reports/desktop_components/dAceScanReport";
import dDeptSingle from "./Reports/desktop_components/dDeptSingle";
import dGroupSingle from "./Reports/desktop_components/dGroupSingle";
import dOperatorMulti from "./Reports/desktop_components/dOperatorMulti";
import dManagerOverridePunches from "./Reports/desktop_components/dManagerOverridePunches";
import dDivision from "./Reports/desktop_components/dDivision";
import dOvertime from "./Reports/desktop_components/dOvertime";
import dBalancing from "./Reports/desktop_components/dBalancing";
import dSubs from "./Reports/desktop_components/dSubs";
import dRedemptions from "./Reports/desktop_components/dRedemptions";
import dReceivers from "./Reports/desktop_components/dReceivers";

import BannerSales from "./BannerSales/BannerSales";
import BannerStoreSales from "./BannerSales/BannerStoreSales";
import BannerStoreSalesDesktop from "./BannerSales/BannerStoreSalesDesktop";
import Settings from "./Settings/Settings";
import PrivateClockRoute from "./PrivateClockRoute";
import ClockLogin from "./Clock/Login/Login";
import ClockHome from "./Clock/Clock";
import ClockInOut from "./Clock/ClockInOut/ClockInOut";
import CreateCompany from "./Clock/Company/CreateCompany";
import Users from "./Clock/Users/Users";
import Stores from "./Clock/store/Stores";
import Time from "./Time/Time";
import GeneralSettings from "./Clock/GeneralSettings/GeneralSettings";
import CashiersRealTime from "./AddCashier/CashiersRealTime";
import Storename from "./Storename/Storename";

import VendorReport from "./Reports/mobile_components/VendorReport";
import VendorReportDesktop from "./Reports/desktop_components/VendorReportDesktop";
import { addDays } from "./utils";

import SubMargin from "./Sales/Margins/SubMargins";
import CatMargin from "./Sales/Margins/CatMargins";
import SubItemMargins from "./Sales/Margins/SubItemMargins";
import CatItemMargins from "./Sales/Margins/CatItemMargins";
import Predict from "./Predict/Predict";

import Roles from "./Clock/Roles/Roles";
import Links from "./Clock/Links/Links";
import DesktopTimeClockContainer from "./Clock/DesktopTimeclock/DesktopTimeClockContainer";
import ClockReports from "./Clock/Reports/ClockReports";
import Edit from "./Clock/Edit/Edit";
import Timecard from "./Clock/Mobile/Timecard";
import Status from "./Clock/Status/Status";
import BetaClock from "./Clock/DesktopTimeclock/BetaClock";
import Departments from "./Clock/Departments/Departments";
import GridFile from "./Clock/Grid/GridFile";
import Schedule from "./Clock/Schedule/Schedule";
import Requests from "./Clock/Requests/Requests";
import Reset from "./Clock/Reset/Reset";
import PayserverTransactions from "./Reports/desktop_components/PayserverTransactions/PayserverTransactions";
import Ledger from "./PayServer/Ledger/Ledger";
import Eod from "./Clock/Eod/Eod";
import Punchcard from "./Clock/Punchcard/Punchcard";
import Playground from "./Clock/Playground/Playground";
import Refunds from "./Refunds/Refunds";
import Charges from "./Customers/Charges";

import Freshop from "./Freshop/Freshop";
import SecurityMain from "../src/HomePage/Pages/security/SecurityMain";
import Form4 from "../src/Form4/Form4";

import Calendar from "./Clock/Calendar/Calendar";
import Consent from "./Clock/Consent/Consent";
import StartQuery from "./Customers/components/StartQuery";
import ReportList from "./Customers/components/ReportList";
import ClockNotFound from "./Clock/ClockNotFound";
import LiveSchedule from "./Clock/LiveSchedule/LiveSchedule";
import Analysis from "./PayServer/Analysis/Analysis";
import AssignLoyaltyStore from "./PayServer/Assign/AssignLoyaltyStores";

import "./css/dates.css";
import "./css/switcher.css";
import "./css/Picker.css";
import "./App.css";

export const ContextConfig = React.createContext();
export const ClockConfig = React.createContext();

const configValue = {
  //url: "https://localhost:44354/api/",
  url: "https://www.datacashreg.com/rReportsApi/api/",
  //clockUrl: "https://q14a3mziea.execute-api.us-east-2.amazonaws.com/Prod/api/",
  clockUrl: "https://64s5gydoci.execute-api.us-east-2.amazonaws.com/Prod/api/",
  //clockUrl: "http://localhost:57784/api/",
  basketUrl: "https://www.dmsonline.info/basketapi/api/",
  basketApiKey: "1627309573649176",
  key: "8080808080808080",
  lastSearchType: null,
  lastStoreid: null,
  lastGroup: null,
  token: null,
  clockToken: null,
  apikey: "7801882436271592",
  basketApi: "8261035427835714",
  psapi: "https://localhost:44387/api/",
  psanalysis:
    "https://gbz4j6265j.execute-api.us-east-2.amazonaws.com/Prod/api/",
  // psanalysis: "http://localhost:52242/api/",
  freshopApi: "https://www.datacashreg.com/dcrfreshopapi/api/",
  //freshopApi: "https://localhost:44397/api/",
  freshopAppKey: "4782601894762849",
  authenticated: false,
  userLevel: 0,
  endDate: new Date(),
  startDate: addDays(new Date(), -7),
  email: "",
  resetPassword: "0",
  lastBatchClose: "",
  defaultDepartment: 0,
  singleDay: new Date(),
  security: null,
  punchId: 0
};

const prodConfigValue = {
  url: "https://www.datacashreg.com/rReportsApi/api/",
  clockUrl: "https://64s5gydoci.execute-api.us-east-2.amazonaws.com/Prod/api/",
  basketUrl: "https://www.dmsonline.info/basketapi/api/",
  basketApiKey: "1627309573649176",
  key: "8080808080808080",
  lastSearchType: null,
  lastStoreid: null,
  lastGroup: null,
  token: null,
  clockToken: null,
  apikey: "7801882436271592",
  basketApi: "8261035427835714",
  psapi: "https://v5o2brn6il.execute-api.us-east-2.amazonaws.com/Prod/api/",
  psanalysis:
    "https://gbz4j6265j.execute-api.us-east-2.amazonaws.com/Prod/api/",
  freshopApi: "https://www.datacashreg.com/dcrfreshopapi/api/",
  freshopAppKey: "4782601894762849",
  authenticated: false,
  userLevel: 0,
  endDate: new Date(),
  startDate: addDays(new Date(), -7),
  email: "",
  resetPassword: "0",
  latBatchClose: "",
  defaultDepartment: 0,
  singleDay: new Date(),
  security: null,
  punchId: 0
};

const App = () => {
  if (!isMobile) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflowY = "scroll";
  }
  return (
    <Provider store={store}>
      <ContextConfig.Provider
        value={
          process.env.NODE_ENV === "development" ? configValue : prodConfigValue
        }
      >
        <ToastContainer autoClose={3000} hideProgressBar />

        <Switch>
          <Route exact path="/" component={LoginPage} />
          <Route exact path="/login" component={LoginPage} />
          <PrivateRoute path="/home" component={HomePage} />
          <PrivateRoute path="/security" component={SecurityMain} />
          <PrivateRoute exact path="/groups" component={Groups} />
          <PrivateRoute exact path="/cashiers" component={Cashiers} />
          <PrivateRoute exact path="/flyout" component={CashierFlyout} />
          <PrivateRoute exact path="/ps" component={PayServer} />
          <PrivateRoute exact path="/reset" component={ResetPassword} />
          <PrivateRoute exact path="/addcashier" component={AddCashier} />
          <PrivateRoute
            exact
            path="/cashierhistory"
            component={CashiersRealTime}
          />
          <PrivateRoute exact path="/storename" component={Storename} />
          <PrivateRoute exact path="/sales" component={Sales} />
          <PrivateRoute exact path="/hourly" component={Hourly} />
          <PrivateRoute exact path="/reports" component={Reports} />
          <PrivateRoute exact path="/reportviewer" component={ReportViewer} />
          <PrivateRoute exact path="/banner" component={BannerSales} />
          <PrivateRoute exact path="/settings" component={Settings} />
          <PrivateRoute exact path="/bsales" component={BannerStoreSales} />
          <PrivateRoute exact path="/totalizers" component={Totalizers} />
          <PrivateRoute exact path="/transactions" component={Transactions} />
          <PrivateRoute exact path="/transaction" component={Transaction} />
          <PrivateRoute exact path="/history" component={PointHistory} />
          <PrivateRoute exact path="/crypto" component={CryptoTest} />
          <PrivateRoute exact path="/storemulti" component={StoreMulti} />
          <PrivateRoute exact path="/ace" component={AceRewards} />
          <PrivateRoute exact path="/division" component={Division} />
          <PrivateRoute exact path="/deptsingle" component={DeptSingle} />
          <PrivateRoute exact path="/groupsingle" component={GroupSingle} />
          <PrivateRoute exact path="/operatormulti" component={OperatorMulti} />
          <PrivateRoute exact path="/dace" component={dAceScanReport} />
          <PrivateRoute exact path="/predict" component={Predict} />
          <PrivateRoute exact path="/exceptions" component={Refunds} />
          <PrivateRoute exact path="/charges" component={Charges} />
          <PrivateRoute
            exact
            path="/overrides"
            component={ManagerOverridePunches}
          />
          <PrivateRoute exact path="/freshop" component={Freshop} />
          <PrivateRoute exact path="/overtime" component={Overtime} />
          <PrivateRoute exact path="/division" component={Division} />
          <PrivateRoute exact path="/balancing" component={Balancing} />
          <PrivateRoute exact path="/subs" component={SubDepartments} />
          <PrivateRoute exact path="/multi" component={dStoreMulti} />
          <PrivateRoute exact path="/single" component={dDeptSingle} />
          <PrivateRoute exact path="/gsingle" component={dGroupSingle} />
          <PrivateRoute exact path="/operator" component={dOperatorMulti} />
          <PrivateRoute exact path="/dovertime" component={dOvertime} />
          <PrivateRoute exact path="/ddivision" component={dDivision} />
          <PrivateRoute exact path="/dbalancing" component={dBalancing} />
          <PrivateRoute exact path="/dsubs" component={dSubs} />
          <PrivateRoute exact path="/dredemption" component={dRedemptions} />
          <PrivateRoute exact path="/form4" component={Form4} />
          <PrivateRoute
            exact
            path="/ptransactions"
            component={PayserverTransactions}
          />
          <PrivateRoute exact path="/vendorreport" component={VendorReport} />
          <PrivateRoute exact path="/sales/subs" component={SubMargin} />
          <PrivateRoute exact path="/sales/cat" component={CatMargin} />
          <PrivateRoute
            exact
            path="/sales/subitems"
            component={SubItemMargins}
          />
          <PrivateRoute
            exact
            path="/sales/catitems"
            component={CatItemMargins}
          />
          <PrivateRoute exact path="/ledger" component={Ledger} />
          <PrivateRoute exact path="/panalysis" component={Analysis} />
          <PrivateRoute exact path="/assign" component={AssignLoyaltyStore} />
          <PrivateRoute exact path="/recievers" component={dReceivers} />

          <PrivateRoute
            exact
            path="/dvendorreport"
            component={VendorReportDesktop}
          />
          <PrivateRoute
            exact
            path="/moverrides"
            component={dManagerOverridePunches}
          />
          <PrivateRoute
            exact
            path="/bdsales"
            component={BannerStoreSalesDesktop}
          />
          <PrivateRoute exact path="/querybuilder" component={StartQuery} />
          <PrivateRoute exact path="/customreports" component={ReportList} />
          <PrivateRoute exact path="/time" component={Time} />
          <Route exact path="/clock" component={ClockLogin} />
          <PrivateClockRoute exact path="/clocklogout" component={ClockLogin} />
          <PrivateClockRoute exact path="/clockhome" component={ClockHome} />
          <PrivateClockRoute exact path="/clockinout" component={ClockInOut} />
          <PrivateClockRoute exact path="/company" component={CreateCompany} />
          <PrivateClockRoute exact path="/clockusers" component={Users} />
          <PrivateClockRoute exact path="/stores" component={Stores} />
          <PrivateClockRoute exact path="/clockstores" component={Stores} />
          <PrivateClockRoute exact path="/eod" component={Eod} />
          <PrivateClockRoute
            exact
            path="/generalsettings"
            component={GeneralSettings}
          />
          <PrivateClockRoute exact path="/roles" component={Roles} />
          <PrivateClockRoute exact path="/links" component={Links} />
          <PrivateClockRoute
            exact
            path="/timeclock"
            component={DesktopTimeClockContainer}
          />
          <PrivateClockRoute
            exact
            path="/clockreports"
            component={ClockReports}
          />
          <PrivateClockRoute exact path="/clockedits" component={Edit} />
          <PrivateClockRoute exact path="/timecard" component={Timecard} />
          <PrivateClockRoute exact path="/status" component={Status} />
          <PrivateClockRoute exact path="/beta" component={BetaClock} />
          <PrivateClockRoute
            exact
            path="/departments"
            component={Departments}
          />
          <PrivateClockRoute exact path="/grid" component={GridFile} />
          <PrivateClockRoute exact path="/schedule" component={Schedule} />
          <PrivateClockRoute exact path="/request" component={Requests} />
          <PrivateClockRoute
            exact
            path="/resetclockpassword"
            component={Reset}
          />
          <PrivateClockRoute exact path="/punchcard" component={Punchcard} />
          <PrivateClockRoute exact path="/playground" component={Playground} />
          <PrivateClockRoute exact path="/calendar" component={Calendar} />
          <PrivateClockRoute exact path="/consent" component={Consent} />
          <PrivateClockRoute
            exact
            path="/liveschedule"
            component={LiveSchedule}
          />
          <PrivateClockRoute component={ClockNotFound} />
          <PrivateRoute component={PageNotFound} />
        </Switch>
      </ContextConfig.Provider>
    </Provider>
  );
};

App.propTypes = {
  history: PropTypes.array
};

// testing offline mode
export default App;
