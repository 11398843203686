import React, { useState, useEffect, useContext } from "react";
import Spinner from "../../common/Spinner";
import Display from "./Display";
import Info from "./Info";
import BetaEntryButtons from "./BetaEntryButtons";
import Clock from "react-live-clock";
import UserInfo from "./UserInfo";
import ClockinButtons from "./ClockinButtons";
import { ContextConfig } from "../../App";
import { getUserStatus } from "../../api/UsersApi";
import { formatDate, isValid, decrypt } from "../../utils";
import {
  loadGeneralSettings,
  loadPunches
} from "./DEsktopTimeClockContainer-I";
import ModalHours from "./ModalHours";
import "./BetaClock.css";
import { createPunch, getManagers } from "../../api/punchApi";
import { loadDepartments } from "../../api/departmentApi";
import { toast } from "react-toastify";
import { debounce } from "../../fp";
import axios from "axios";
import qs from "qs";
import Stats from "./Stats";
import CryptoJS from "crypto-js";

const BetaClock = () => {
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [displayText, setDisplayText] = useState("");
  const [passwordMode, setPasswordMode] = useState(false);
  const [fakeText, setFakeText] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [usersname, setUsersname] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [employeeid, setEmployeeid] = useState("");
  const [status, setStatus] = useState("");
  const [email, setEmail] = useState("");
  const [links, setLinks] = useState([]);
  const [punches, setPunches] = useState([]);
  const [total, setTotal] = useState("");
  const [isShowing, setIsShowing] = useState(false);
  const [startTimer, setStartTimer] = useState(false);
  const [timeRemainingInSeconds, setTimeRemainingInSeconds] = useState(0);
  const [resetTimer, setResetTimer] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [currentDepartment, setCurrentDepartment] = useState("");
  const [managerMode, setManagerMode] = useState(false);
  const [showDesktopButton, setShowDesktopButton] = useState(false);
  const [offline, setOffline] = useState(false);
  const [showStatsModal, setShowStatsModal] = useState(false);
  const [managerLevel, setManagerLevel] = useState(0);
  const [disableBackspace, setDisableBackspace] = useState(false);
  const [disableEnter, setDisableEnter] = useState(false);
  const [managers, setManagers] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [storeGroup, setStoreGroup] = useState(0);
  const [userid, setUserid] = useState(0);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const context = useContext(ContextConfig);

  let generalSettings;
  if (isValid(context.settings)) {
    generalSettings = context.settings;
  } else {
    loadGeneralSettings(
      context.currentClockStore,
      setIsLoading,
      context,
      setGeneralSettings,
      setPunches,
      setMessage
    );
  }

  const setGeneralSettings = (e) => {
    generalSettings = e;
  };

  useEffect(() => {
    Date.prototype.stdTimezoneOffset = function() {
      var jan = new Date(this.getFullYear(), 0, 1);
      var jul = new Date(this.getFullYear(), 6, 1);
      return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
    };

    Date.prototype.isDstObserved = function() {
      return this.getTimezoneOffset() < this.stdTimezoneOffset();
    };
    if (
      typeof context.defaultDepartment !== "undefined" &&
      context.defaultDepartment !== null
    ) {
      setCurrentDepartment(context.defaultDepartment);
    }

    if (username.length > 0 && password.length > 0) {
      if (!isValid(status)) {
        if (navigator.onLine && localStorage.getItem("punches")) {
          const punches = JSON.parse(localStorage.getItem("punches"));
          if (punches && punches.length > 0) {
            const filteredPunches = punches.filter((P) => P.type == true);
            if (filteredPunches.length > 0) {
              forwardPunches();
              setOffline(false);
              return;
            }
          }
        }
        if (!isValid(displayText)) {
          fetchDepartments();
          getStatus();
        }
      }
    }
    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);

    findDivHeight();

    window.addEventListener("resize", debounceHandleResize);

    // loadPunches(
    //   context.settings.storeid,
    //   setIsLoading,
    //   context,
    //   setPunches,
    //   setGeneralSettings,
    //   generalSettings,
    //   setTotal,
    //   setMessage,
    //   punchesLoaded
    // );

    if (managers.length === 0) {
      fetchManagers();
    }

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, [
    displayText,
    status,
    context.settings.storeid,
    resetTimer,
    showStatsModal
  ]);

  useEffect(() => {
    loadPunches(
      context.settings.storeid,
      setIsLoading,
      context,
      setPunches,
      setGeneralSettings,
      generalSettings,
      setTotal,
      setMessage,
      punchesLoaded
    );
  }, []);

  useEffect(() => {}, [offline]);

  useEffect(() => {
    let interval;

    if (startTimer) {
      if (isValid(interval)) clearTimeout(interval);
      interval = setInterval(() => {
        setTimeRemainingInSeconds(
          (timeRemainingInSeconds) => timeRemainingInSeconds + 0.25
        );
      }, 250);
    } else {
      setTimeRemainingInSeconds(0);
    }

    return () => {
      clearInterval(interval);
    };
  }, [startTimer]);

  useEffect(() => {
    if (managerMode) {
      setStartTimer(true);
    }
  }, [managerMode]);

  useEffect(() => {
    console.log("useEffect for fetchDepartment");
    if (context.settings.storeid) {
      fetchDepartments();
    }
  }, [context.settings.storeid]);

  const findDivHeight = () => {
    const header = document
      .getElementById("clock-header")
      .getBoundingClientRect().height;

    const usedSpace = header;
    const remaining = window.innerHeight - usedSpace;

    document.getElementById("clock-body").style.height = remaining + "px";
  };

  const handleButtonClick = (e) => {
    switch (e) {
      case "E":
        processEntry();
        break;
      case "B":
        setDisplayText(displayText.substring(0, displayText.length - 1));
        setFakeText(fakeText.substring(0, fakeText.length - 1));
        break;
      case "C":
        clearClock();
        break;
      default:
        if (displayText.toUpperCase() == "ENTER PASSWORD") {
          setPasswordMode(true);
          setDisplayText(e);
          setFakeText("*");
        } else {
          setDisplayText(`${displayText}${e}`);
        }
        if (passwordMode) {
          setFakeText(`${fakeText}*`);
        }
        break;
    }
  };

  const processEntry = () => {
    if (username.length === 0) {
      // this not going to be our username
      setUsername(displayText);
      setDisplayText("Enter Password");
      setStartTimer(true);
      setTimeRemainingInSeconds(0);
      setResetTimer(!resetTimer);
    } else {
      // we are entering the password
      setPassword(displayText);
      setDisplayText("");
      setFakeText("");
      setPasswordMode(false);
      setTimeRemainingInSeconds(0);
      setResetTimer(!resetTimer);
      setDisableBackspace(true);
      setDisableEnter(true);
    }
  };

  const handleLinkSubmit = (e) => {
    e.preventDefault();
  };

  // const handleLinkChange = (e) => {
  //   setCurrentStore(e.target.value);
  // };

  const getStatus = () => {
    console.log("getting status", new Date());
    console.log("status is for user " + username);
    getUserStatus(
      context.clockUrl,
      context.clockToken,
      username,
      password,
      context.settings.storeid
    )
      .then((response) => {
        console.log("status response is back", new Date());
        const j = response.data;
        if (j.error === 0) {
          setUsersname(j.username);
          setFirstname(j.firstName);
          setLastname(j.lastName);
          setDisplayText(j.firstName);
          setEmployeeid(j.employeeid);
          setEmail(j.email);
          setStatus(j.status);
          setLinks(j.links);
          setOffline(false);
          setMessage("");
          setStoreGroup(j.storeGroup);
          setUserid(j.id);
          // if (j.links.length >= 1) {
          //   setCurrentStore(j.links[0].storeid);
          // } else if (j.storeid) {
          //   setCurrentStore(j.storeid);
          // }
        } else {
          setMessage(j.msg);
          setLinks([]);
        }
      })
      .catch((err) => {
        console.log(err);
        //setMessage("An error occured connecting");
        setLinks([]);
        setOffline(true);
        getOfflineStatus();
      });
  };

  const getOfflineStatus = () => {
    const status = JSON.parse(localStorage.getItem("status"));
    if (status) {
      const user = status.filter((u) => u.userid == username)[0];
      if (user) {
        // check to make sure the password matches
        const decryptedPassword = decrypt(user.p, context.key).toString(
          CryptoJS.enc.Utf8
        );
        if (decryptedPassword != password) {
          setMessage("Invalid Password");
          return;
        }
        setStatus(parseInt(user.status));
        //setCurrentStore(context.settings.storeid);
      } else {
        setMessage("Could not find Status");
      }
    } else {
      setMessage("No Local Storage");
    }
  };

  const punchesLoaded = () => {};

  const ClockIn = () => {
    processPunch(1);
  };

  const StartBreak = () => {
    processPunch(3);
  };

  const finishBreak = () => {
    processPunch(4);
  };

  const startLunch = () => {
    processPunch(5);
  };

  const finishLunch = () => {
    processPunch(6);
  };

  const clockOut = () => {
    processPunch(2);
  };

  const hide = () => {
    setIsShowing(false);
    console.log("closing hours");
  };

  const processPunch = (punchType) => {
    if (offline) {
      if (punchType == 3) {
        const punch1 = getPunchFromLocalStorage(1);
        if (
          typeof punch1 === "undefined" ||
          punch1 === null ||
          punch1.length === 0
        ) {
          setMessage("Could not find a punch to marry to");
          return;
        }
      } else if (punchType === 4) {
        // back from break
        const punch2 = getPunchFromLocalStorage(punchType - 1); // this will give us the previous status which was the start of the break
        if (
          typeof punch2 === "undefined" ||
          punch2 === null ||
          punch2.length === 0
        ) {
          setMessage("Could not find break to marry to");
          return;
        }
        if (generalSettings.forceBreakTime == "1") {
          // we need to know what the original punch in was
          const punchInReference = new Date();
          const punchIn = new Date(punch2[0].punchDate);
          const breakInMinimum = new Date(
            punchIn.getTime() +
              parseInt(generalSettings.mandatoryBreakMinutes) * 60000
          );
          if (punchInReference < breakInMinimum) {
            const diff = breakInMinimum - punchIn;
            const minutesLeft = Math.floor(diff / (1000 * 60));
            if (
              minutesLeft > parseInt(generalSettings.breakGracePeriodBefore) &&
              managerMode === false
            ) {
              const gracePeriodLeft =
                minutesLeft - parseInt(generalSettings.breakGracePeriodBefore);
              setMessage(gracePeriodLeft + " minutes left on break");
              return;
            }
          } else if (
            punchInReference > breakInMinimum &&
            parseInt(generalSettings.breakGracePeriodAfter) >= 0
          ) {
            const tolerance = punchInReference - breakInMinimum;
            const toleranceMinutes = Math.floor(tolerance / (1000 * 60));
            if (
              toleranceMinutes >
                parseInt(generalSettings.breakGracePeriodAfter) &&
              managerMode === false
            ) {
              setMessage(
                "You have exceeded the grace period from returning from break"
              );
              return;
            }
          }
        }
      } else if (punchType === 5) {
        // going to lunch
        const punch3 = getPunchFromLocalStorage(1);
        if (
          typeof punch3 === "undefined" ||
          punch3 === null ||
          punch3.length === 0
        ) {
          setMessage("Could not find a punch to marry to");
          return;
        }
      } else if (punchType === 6) {
        // back from lunch
        const punch4 = getPunchFromLocalStorage(punchType - 1);
        if (
          typeof punch4 === "undefined" ||
          punch4 === null ||
          punch4.length === 0
        ) {
          setMessage("Could not find a lunch to marry to");
          return;
        }

        if (generalSettings.forceLunch == "1" && managerMode === false) {
          const referenceDate = new Date();
          const punchIn = new Date(punch4[0].punchDate);
          const lunchInMinimum = new Date(
            punchIn.getTime() +
              parseInt(generalSettings.minutesForLunch) * 60000
          );

          const lunchOutMaximum = new Date(
            lunchInMinimum.getTime() +
              parseInt(generalSettings.lunchGracePeriodAfter) * 60000
          );

          if (referenceDate < lunchInMinimum) {
            const lunchDiff = lunchInMinimum - referenceDate;
            const lunchMinutesLeft = Math.floor(lunchDiff / (1000 * 60));
            if (
              lunchMinutesLeft >
              parseInt(generalSettings.lunchGracePeriodBefore)
            ) {
              setMessage(
                lunchMinutesLeft + " before you can return from lunch"
              );
              return;
            }
          } else if (referenceDate > lunchOutMaximum) {
            setMessage("Exceeded grace period for returning from lunch");
            return;
          }
        }
      }
    }

    setIsLoading(true);
    const punch = {
      userid: userid,
      punchStatus: punchType,
      storeid: context.settings.storeid,
      comment: "",
      departmentId: currentDepartment,
      online: offline ? "0" : "1"
    };

    punch.mode = process.env.NODE_ENV === "development" ? "debug" : "live";
    const dte = new Date();
    punch.timeZoneOffset = dte.getTimezoneOffset();
    punch.isDst = dte.isDstObserved();

    if (managerMode) {
      punch.managerOverride = 1;
    }

    sendPunch(punch);
  };

  const getPunchFromLocalStorage = (punchType) => {
    const punches = JSON.parse(localStorage.getItem("punches"));
    if (punches) {
      const punch = punches.filter(
        (p) => p.userid == username && p.punchStatus == punchType
      );
      return punch;
    } else {
      return null;
    }
  };

  const sendPunch = async (punch) => {
    console.log("sending punch", punch);
    await createPunch(context.clockUrl, context.clockToken, punch)
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          setOffline(false);

          setTotal(j.totalHours);
          setStartTimer(true);
          clearClock();
          setIsShowing(true);

          // now save the status to local storage
          if (j.statusList) {
            if (j.statusList.length > 0) {
              localStorage.setItem("status", JSON.stringify(j.statusList));
            }
          }

          // maybe make this a little more dynamic

          setPunches(j.punches);
          if (
            j.punch.punchStatus == "2" ||
            j.punch.punchStatus == "4" ||
            j.punch.punchStatus == "6"
          ) {
            removePunchFromLocalStorage(j.punch);
          } else {
            savePunchToLocalStorage(punch, true);
          }

          setTimeout(() => {
            setIsShowing(false);
            setPunches([]);
          }, 10000);
        } else {
          setMessage(j.msg);
        }
      })
      .catch((err) => {
        setOffline(true);
        setIsLoading(false);
        console.log(err);
        savePunchToLocalStorage(punch, false);
      });
  };

  const savePunchToLocalStorage = (punch, online) => {
    const punchId = context.punchId;
    const today = new Date();
    punch.punchDate = today;
    punch.online = online;
    context.punchId++;
    punch.punchId = punchId;
    const punches = JSON.parse(localStorage.getItem("punches"));
    if (punches) {
      const newPunches = [punch, ...punches];
      localStorage.setItem("punches", JSON.stringify(newPunches));
    } else {
      const punchArray = [];
      punchArray.push(punch);
      localStorage.setItem("punches", JSON.stringify(punchArray));
    }

    const status = JSON.parse(localStorage.getItem("status"));
    const user = status.filter((u) => u.userid == punch.userid)[0];
    switch (parseInt(punch.punchStatus)) {
      case 1:
        user.status = 1;
        break;
      case 2:
        user.status = 0;
        break;
      case 3:
        user.status = 2;
        break;
      case 4:
        user.status = 1;
        break;
      case 5:
        user.status = 3;
        break;
      case 6:
        user.status = 1;
        break;
      default:
        user.status = 0;
        break;
    }
    const remainingUsers = status.filter((u) => u.userid != punch.userid);
    const newArray = [user, ...remainingUsers];
    localStorage.setItem("status", JSON.stringify(newArray));

    setStartTimer(true);

    clearClock();
    setIsShowing(true);

    setTimeout(() => {
      setIsShowing(false);
      setPunches([]);
    }, 15000);
  };

  const removePunchFromLocalStorage = (punch) => {
    const punches = JSON.parse(localStorage.getItem("punches"));
    const punchesCopy = Array.from(punches);
    const punchIndex = punchesCopy.findIndex(
      (p) =>
        p.userid == punch.userid &&
        p.punchStatus == parseInt(punch.punchStatus) - 1
    );
    if (punchIndex >= 0) {
      punchesCopy.splice(punchIndex, 1);
      localStorage.setItem("punches", JSON.stringify(punchesCopy));
    }
  };

  async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

  const forwardPunches = () => {
    const punches = JSON.parse(localStorage.getItem("punches"));

    if (punches) {
      const filteredPunches = punches.filter((p) => p.online === false);
      const sortedPunches = filteredPunches.sort((a, b) =>
        parseInt(a.punchStatus) > parseInt(b.punchStatus) ? 1 : -1
      );

      if (sortedPunches.length > 0) {
        setMessage("Syncing Punches");
        makeMultipleRequests(sortedPunches);
      }
    } // end of if statement
  }; // end of forwardPunches

  const makeMultipleRequests = async (punchArray) => {
    setIsLoading(true);
    await asyncForEach(punchArray, async (punch) => {
      await axios({
        method: "POST",
        cors: true,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + context.clockToken
        },
        url: context.clockUrl + "punches",
        data: qs.stringify({
          punch
        })
      })
        .then((response) => {
          const j = response.data;

          removePunchFromLocalStorage(j.punch);
          if (j.error === 0) {
            if (j.statusList) {
              if (j.statusList.length > 0) {
                localStorage.setItem("status", JSON.stringify(j.statusList));
              }
            }
          } else {
            toast.warning(j.msg, {
              position: toast.POSITION.TOP_LEFT
            });
          }
        })
        .catch((err) => {
          console.log("error", err);
          toast.error("an Internal error occurred", {
            position: toast.POSITION.TOP_LEFT
          });
        });
    });

    setIsLoading(false);

    clearClock();
    setTimeout(() => {
      setMessage("Please clock in");
    }, 500);
    //localStorage.removeItem("punches");
  };

  const clearClock = () => {
    console.log("clearing clock");
    setManagerMode(false);
    setShowDesktopButton(false);
    setStartTimer(false);
    setMessage("");
    setUsername("");
    setUsersname("");
    setLastname("");
    setFirstname("");
    setPassword("");
    setEmail("");
    setEmployeeid("");
    setFakeText("");
    setPasswordMode(false);
    setLinks([]);
    setStatus("");
    setDisplayText("");
    setTimeRemainingInSeconds(0);
    setDepartments([]);
    setDisableBackspace(false);
    setDisableEnter(false);
    setShowStatsModal(false);
    setManagerLevel(0);
    // setIsShowing(false);
    // setPunches([]);
  };

  const fetchDepartments = () => {
    console.log("fetching departments", new Date());
    if (Object.keys(generalSettings).length > 0) {
      localStorage.setItem("generalsettings", JSON.stringify(generalSettings));
    }
    if (
      typeof context.settings.storeid === "undefined" ||
      context.settings.storeid === null
    )
      return;
    loadDepartments(
      context.clockUrl,
      context.clockToken,
      context.settings.storeid
    )
      .then((response) => {
        console.log("done fetching departments", new Date());
        const j = response.data;
        if (j.error == "0") {
          setDepartments(j.departments);
          localStorage.setItem("departments", JSON.stringify(j.departments));
          setOffline(false);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setOffline(true);
        console.log(err);
        const od = localStorage.getItem("departments");
        if (od) {
          const offlineDepartments = JSON.parse(od);
          setDepartments(offlineDepartments);
        }
        // toast.error("An internal Error occured", {
        //   position: toast.POSITION.TOP_LEFT
        // });
      });
  };

  const handleDepartmentChange = (e) => {
    setCurrentDepartment(e.target.value);
  };

  const showStats = () => {
    setShowStatsModal(true);
  };

  const hideStats = () => {
    setShowStatsModal(false);
    setManagerLevel(0);
  };

  const getLocalStorageDepartments = () => {
    return JSON.parse(localStorage.getItem("departments"));
  };

  const getLocalStorageStatus = () => {
    return JSON.parse(localStorage.getItem("status"));
  };

  const getLocalStorageUser = () => {
    if (localStorage.getItem("cUsername")) {
      return context.username;
    }
  };

  const getLocalStorageUserid = () => {
    if (localStorage.getItem("cUsername")) {
      return context.userid;
    }
  };

  const getPunchesFromLocalStorage = () => {
    return JSON.parse(localStorage.getItem("punches"));
  };

  const fetchManagers = () => {
    getManagers(context.clockUrl, context.clockToken, context.lastClockStore)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setManagers(j.managers);
          localStorage.setItem("managers", JSON.stringify(j.managers));
        } else {
          setManagers([]);
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setManagers([]);
        toast.error("An internal error occured", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  return (
    <div className="container-fluid clock-body">
      {isLoading && <Spinner />}
      {isShowing && (
        <ModalHours
          isShowing={isShowing}
          hide={hide}
          punches={punches}
          total={total}
          context={context}
        />
      )}
      {showStatsModal && (
        <Stats
          isShowing={showStatsModal}
          hide={hideStats}
          departments={getLocalStorageDepartments()}
          status={getLocalStorageStatus()}
          username={getLocalStorageUser()}
          storeid={context.settings.storeid}
          punches={getPunchesFromLocalStorage()}
          userid={getLocalStorageUserid()}
        />
      )}
      <div className="clock-rows">
        <div className="clock-header" id="clock-header">
          <Display
            output={displayText}
            passwordMode={passwordMode}
            fakeText={fakeText}
          />
          <div className="clock-current-date-time">
            <div className="clock-cdt-table">
              <div className="clock-cdt-date">
                <div>{formatDate(new Date())}</div>
                {offline ? (
                  <div
                    style={{
                      color: "red",
                      fontSize: "1.2rem",
                      fontWeight: "bold",
                      textAlign: "center",
                      width: "100%"
                    }}
                  >
                    Offline
                  </div>
                ) : null}
              </div>

              <div>
                <Info message={message} />
              </div>
              <div className="clock-cdt-time">
                {<Clock format={"h:mm:ssa"} interval={1000} ticking={true} />}
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid" id="clock-body">
          <div className="row clock-main">
            <div className="d-flex col-xs-12 col-sm-4 col-md-3 col-lg-2 clock-left">
              <UserInfo
                username={usersname}
                status={status}
                firstname={firstname}
                lastname={lastname}
                email={email}
                employeeid={employeeid}
                links={links}
                handleLinkSubmit={handleLinkSubmit}
                //handleLinkChange={handleLinkChange}
                currentStore={context.settings.storeid}
                timeRemainingInSeconds={timeRemainingInSeconds}
                generalSettings={generalSettings}
                resetClock={clearClock}
                departments={departments}
                handleDepartmentChange={handleDepartmentChange}
                currentDepartment={currentDepartment}
                context={context}
                managerMode={managerMode}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-6 col-lg-8 clock-middle">
              <BetaEntryButtons
                handleButtonClick={handleButtonClick}
                backspace={disableBackspace}
                enter={disableEnter}
              />
            </div>
            <div className="d-flex col-xs-12 col-sm-4 col-md-3 col-lg-2 clock-right">
              <ClockinButtons
                status={status}
                currentStore={context.settings.storeid}
                clockin={ClockIn}
                startBreak={StartBreak}
                finishBreak={finishBreak}
                startLunch={startLunch}
                finishLunch={finishLunch}
                clockOut={clockOut}
                setStartTimer={setStartTimer}
                setManagerMode={setManagerMode}
                showDesktopButton={showDesktopButton}
                setShowDesktopButton={setShowDesktopButton}
                context={context}
                showStats={showStats}
                managerLevel={managerLevel}
                setManagerLevel={setManagerLevel}
                setMessage={setMessage}
                hideStats={hideStats}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BetaClock;
