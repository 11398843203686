import React from "react";
import ReactDOM from "react-dom";
import "./ModalLogin.css";
import EntryButtons from "../DesktopTimeclock/EntryButtons";
import Display from "../DesktopTimeclock/Display";

const ModalLogin = ({
  isShowing,
  hide,
  passwordMode,
  fakeText,
  displayText,
  handleButtonClick
}) =>
  isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <div className="modal-login-overlay" />
          <div
            className="modal-login-wrapper"
            aria-modal
            aria-hidden
            tabIndex={-1}
            role="dialog"
          >
            <div className="modal-login">
              <div className="modal-loginheader">
                <span className="modal-login-confirm-title">Login</span>
                <span className="modal-login-close-box">
                  <button
                    type="button"
                    className="modal-login-close-button"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={hide}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </span>
              </div>
              <div className="modal-login-body">
                <Display
                  output={displayText}
                  passwordMode={passwordMode}
                  fakeText={fakeText}
                />
                <EntryButtons handleButtonClick={handleButtonClick} />
              </div>
            </div>
          </div>
        </React.Fragment>,
        document.body
      )
    : null;

export default ModalLogin;
