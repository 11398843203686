import * as actions from "../actions/actionTypes";
import { addDays } from "../utils";

const initialState = {
  isLoading: false,
  isLoadingExceptions: false,
  startDate: addDays(new Date(), -7),
  endDate: new Date(),
  showDate: false,
  threshold: 1,
  cashiers: [],
  cashier: 0,
  searchType: "",
  refunds: [],
  filteredRefunds: [],
  enableCashier: false,
  transactions: [],
  filteredTransactions: [],
  showModal: false,
  exceptions: [],
  totalizer: 3001,
  hdr: [],
  reg: [],
  ttl: [],
  showKeys: false,
  currentTransaction: {},
  showTransaction: false,
  showBackpack: false
};

const refundReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.REF_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading
      };
    case actions.REF_SET_START_DATE:
      return {
        ...state,
        startDate: action.startDate
      };
    case actions.REF_SET_END_DATE:
      return {
        ...state,
        endDate: action.endDate
      };
    case actions.REF_SET_THRESHOLD:
      return {
        ...state,
        threshold: action.threshold
      };
    case actions.REF_SET_CASHIER:
      return {
        ...state,
        cashier: action.cashier
      };
    case actions.REF_SET_CASHIERS:
      return {
        ...state,
        cashiers: action.cashiers
      };
    case actions.REF_SET_REFUNDS:
      return {
        ...state,
        refunds: action.refunds,
        filteredRefunds: action.refunds
      };
    case actions.REF_SET_FILTERED_REFUNDS:
      return {
        ...state,
        filteredRefunds: action.filteredData
      };
    case actions.REF_ENABLE_CASHIER:
      return {
        ...state,
        enableCashier: action.enableCashier
      };
    case actions.REF_LOAD_TRANSACTIONS:
      return {
        ...state,
        transactions: action.transactions
      };
    case actions.REF_SHOW_MODAL:
      return {
        ...state,
        showModal: action.showModal
      };
    case actions.REF_SET_EXCEPTIONS:
      return {
        ...state,
        exceptions: action.exceptions
      };
    case actions.REF_SET_TOTALIZER:
      return {
        ...state,
        totalizer: action.totalizer
      };
    case actions.REF_IS_LOADING_EXCEPTIONS:
      return {
        ...state,
        isLoadingExceptions: action.isLoading
      };
    case actions.REF_SET_HDR:
      return {
        ...state,
        hdr: action.hdr
      };
    case actions.REF_SET_REG:
      return {
        ...state,
        reg: action.reg
      };
    case actions.REF_SET_TTL:
      return {
        ...state,
        ttl: action.ttl
      };
    case actions.REF_SHOW_KEYS:
      return {
        ...state,
        showKeys: action.showKeys
      };
    case actions.REF_SET_TRANSACTION:
      return {
        ...state,
        currentTransaction: action.transaction
      };
    case actions.REF_SHOW_TRANSACTION:
      if (!action.showTransaction) {
        state.currentTransaction = {};
        state.hdr = [];
        state.ref = [];
        state.ttl = [];
      }
      return {
        ...state,
        showTransaction: action.showTransaction
      };
    case actions.REF_SET_FILTERED_TRANSACTIONS:
      return {
        ...state,
        filteredTransactions: action.transactions
      };
    case actions.REF_SET_CURRENT_TRANSACTION:
      return {
        ...state,
        currentTransaction: action.transaction
      };
    case actions.REF_RETREIVE_BACKPACK:
      return {
        ...state,
        filteredTransactions: state.transactions,
        showBackpack: false
      };
    case actions.REF_SHOW_BACKPACK:
      return {
        ...state,
        showBackpack: action.showBackpack
      };
    default:
      return state;
  }
};

export default refundReducer;
