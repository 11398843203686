import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./flyout.css";
import { formatDate, setDecimal } from "../../utils";
import "../Stores/ChromeCart.css";
import { Redirect, withRouter } from "react-router-dom";
import { createIcon, createColor } from "./CashierFlyout-I";
import CashierNavigation from "../CashierNavigation";
import { debounce } from "../../fp";

const MobileFlyout = ({ cashiers, context, ...props }) => {
  const [goBack, setGoBack] = useState(false);
  const [step, setStep] = useState(0);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  useEffect(() => {
    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);

    findDivHeight();

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, []);

  const findDivHeight = () => {
    const navbar = document
      .getElementById("master-navbar")
      .getBoundingClientRect().height;
    const controls = document
      .getElementById("flyout-controls")
      .getBoundingClientRect().height;
    const usedSpace = navbar + controls + 20;

    const remaining = window.innerHeight - usedSpace;
    const target = document.getElementById("flyout-holder");
    target.style.height = remaining + "px";
    target.style.overflow = "auto";
    target.style.marginBottom = "30px";
  };

  const handleClick = (cashier) => {
    context.cashier = cashier;
    props.history.push("/totalizers");
  };

  const handleGoback = (currentStep) => {
    setStep(currentStep);
    setGoBack(true);
  };

  return (
    <div className="container-fluid">
      {goBack && step == 0 && <Redirect to="/cashiers" />}
      <div className="container flyout-controls" id="flyout-controls">
        <div className="cfo-title">
          <div className="cfo-storename">
            {context.selectedRecord.store_Name}
          </div>
          <div className="cfo=date">Date: {formatDate(context.endDate)}</div>
        </div>
        <CashierNavigation step="0" goBack={handleGoback} />
      </div>

      <div id="flyout-holder" style={{ padding: "10px" }}>
        {cashiers.map((cashier, index) => (
          <div
            className="cfo-cashier"
            key={index}
            onClick={() => handleClick(cashier)}
          >
            <div className="cfo-cashier-name">
              <span
                className={createColor(
                  cashier.finalCashierConcern,
                  cashier.purples
                )}
              >
                <i
                  className={createIcon(
                    cashier.finalCashierConcern,
                    cashier.purples
                  )}
                ></i>
              </span>
              &nbsp;{`${cashier.cashier}`}
            </div>
            <div className="cfo-contents">
              <div className="cfo-left">
                <div className="cfo-info">Seq: {cashier.f1185}</div>
                <div className="cfo-cashier-perc">{`${setDecimal(
                  cashier.totalCashierScore
                )}%/${cashier.maxThreshold}`}</div>
              </div>
              <div className="cfo-right">
                <div className="cfo-concern">
                  Concern: {cashier.finalCashierConcern}
                </div>
                <div className="cfo-cashier-level">
                  User Level:{cashier.cashierLevel}
                </div>
                <div className="cfo-cashier-purple">
                  Outliers: {cashier.purples}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

MobileFlyout.propTypes = {
  cashiers: PropTypes.array,
  context: PropTypes.object,
  history: PropTypes.object
};

export default withRouter(MobileFlyout);
