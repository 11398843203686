import React, { useState, useEffect, useContext, useCallback } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { NavLink, Redirect } from "react-router-dom";
import { ContextConfig } from "../App";
import { isEqual } from "../fp";
import { usePrevious } from "../utils";
import { isMobile, isBrowser } from "react-device-detect";
import { readMenu } from "./Clock-I";
import { toast } from "react-toastify";

const ClockDesktopMenu = () => {
  // eslint-disable-next-line
  const [redirect, setRedirect] = useState(false);
  const [menu, setMenu] = useState([]);
  const activeStyle = { color: "#F15B2A" };

  const context = useContext(ContextConfig);

  const previousMenu = usePrevious(menu);

  useEffect(() => {
    if (!isEqual(previousMenu, menu)) {
      loadMenuItems();
    }
  }, []);

  const loadMenuItems = useCallback(() => {
    readMenu(context.clockUrl, context.clockToken, isMobile).then(
      (response) => {
        const j = response.data;
        if (j.error === 0) {
          filterMenu(j.menu);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      }
    );
  }, [context]);

  const filterMenu = (data) => {
    let newArray = [];
    if (isBrowser) {
      newArray = data.filter(
        (m) => m.description != "Clock In/Out" || m.description === "Punchcard"
      );
    } else {
      setMenu(data);
    }

    if (context.company != "1") {
      newArray = data.filter((m) => m.description != "Clock In/Out");
    }

    setMenu(newArray);
  };

  return (
    <React.Fragment>
      {redirect && <Redirect to="/clockhome" />}
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="dark"
        variant="dark"
        className="sticky-top"
        id="master-navbar"
      >
        <Navbar.Brand href="/clockhome" className="logo-offset">
          <img src="img/homepage.svg" alt="MikTo" />
          <span className="d-clock-title">Timeclock</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto">
            {menu.length > 0 ? (
              <React.Fragment>
                {menu.map((item, index) => (
                  <NavLink
                    key={index}
                    to={item.route}
                    className="menu-item"
                    activeStyle={activeStyle}
                  >
                    {item.description}
                  </NavLink>
                ))}
              </React.Fragment>
            ) : null}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </React.Fragment>
  );
};

export default ClockDesktopMenu;
