import React, { useState, useEffect, useContext } from "react";
import PropTypes from 'prop-types'
import Login from "./Login";
import { Redirect } from "react-router-dom";
import { readPrefs } from "../api/GlobalApi";
import { ContextConfig } from "../App";
import Spinner from "../common/Spinner";

const LoginPage = (props) => {
  const [toHome, setToHome] = useState(false);
  const [toSecurity, setToSecurity] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const context = useContext(ContextConfig);

  useEffect(() => {
    const queryString = props.history.location.search;
    if (queryString) {
      if (queryString === "?logout=1") {
        localStorage.removeItem("rUsername");
        localStorage.removeItem("rPassword");
      }
    }
  }, [toHome]);

  const handleLoading = () => {
    setIsLoading(true);
  };

  const finishedLoading = () => {
    setIsLoading(false);
  };

  const handleFinishedLogin = () => {
    setIsLoading(true);
    readPrefs(
      context.url,
      context.token,
      (response) => {
        const j = response.data;
        if (j.error === 0) {
          const prefs = j.prefs[0];
          context.lastSearchType = prefs.lastSearchType;
          context.lastGroup = prefs.lastGroup;
          context.lastStoreid = prefs.lastSearch;
          context.lastGroupName = prefs.rlast_Group_Name;
          context.resetPassword = prefs.passwordChangeNeeded;
          setIsLoading(false);

          if (context.security === 1) {
            setToSecurity(true);
          } else {
            setToHome(true);
          }
        } else {
          console.log(j.msg);
          setIsLoading(false);
        }
      },
      (err) => {
        console.log(err);
        setIsLoading(false);
      }
    );
  };

  return (
    <React.Fragment>
      {!isLoading ? null : <Spinner />}
      {toSecurity ? <Redirect to="/security" /> : null}
      {toHome ? <Redirect to="/home" /> : null}
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-sm-6 col-md-8 text-center">
            <img className="py-5 logo" src="img/homepage.svg" alt="logo" />
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-xs-8 col-md-8 col-lg-6 col-xl-4">
            <Login
              handleFinishedLogin={handleFinishedLogin}
              isLoading={handleLoading}
              finishedLoading={finishedLoading}
              props={props}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

LoginPage.propTypes = {
  history: PropTypes.object
}

export default LoginPage;
