/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React, { useState, useEffect, useContext } from "react";
import Controls from "./Controls";
import VisibleFields from "./VisibleFields";
import Where from "./Where";
import GroupBy from "./GroupBy";
import OrderBys from "./OrderBys";
import PropTypes from "prop-types";
import {
  testQuery,
  saveQuery,
  getCategories,
  getSubs
} from "../../api/basketApi";
import { ContextConfig } from "../../App";
import { readGroupAsync } from "../../api/GroupApi";
import { getAssignedStoresAsync } from "../../api/StoreApi";
import { toast } from "react-toastify";
import { formatDate } from "../../utils";
import { Input } from "reactstrap";
import Tooltip from "../../Tooltip/Tooltip";

import "./QueryBuilder.css";

const initialOrderByData = {
  columns: {
    "column-1": {
      id: "column-1",
      orderbyIds: []
    }
  }
};

const QueryBuilder = (props) => {
  const [columns, setColumns] = useState([]);
  const [query, setQuery] = useState("SELECT * FROM BASKET_ITEMSEJ");
  const [wheres, setWheres] = useState([]);
  const [group, setGroup] = useState(false);
  const [groups, setGroups] = useState([]);
  const [orderby, setOrderby] = useState(false);
  const [orderBys, setOrderBys] = useState(["1"]);
  const [reload, setReload] = useState(false);
  const [reloadSelectors, setReloadSelectors] = useState(false);
  const [orderByState, setOrderByState] = useState(initialOrderByData);
  const [copiedData, setCopiedData] = useState(props.data);
  const [reportName, setReportName] = useState("");
  const [msg, setMsg] = useState({});
  const [userGroups, setUserGroups] = useState([]);
  const [reportDescription, setReportDescription] = useState("");
  // const [recordsToReturn, setRecordsToReturn] = useState(100);
  const [stores, setStores] = useState([]);
  const [editQuery, setEditQuery] = useState(false);
  const [reportId, setReportId] = useState("");
  const [categories, setCategories] = useState([]);
  const [subs, setSubs] = useState([]);
  const [readOnly, setReadOnly] = useState(true);
  const [showSave, setShowSave] = useState(false);

  const context = useContext(ContextConfig);

  useEffect(() => {
    readGroupStores();
    readStoreList();
    readCategories();
    readSubs();
    if (props.editQuery) {
      if (Object.keys(props.editQuery).length > 0) {
        setEditQuery(true);
        setReportDescription(props.editQuery.description);
        setReportName(props.editQuery.reportName);
        setColumns(props.editQuery.columns);
        setCopiedData(props.editQuery.columns);
        setWheres(props.editQuery.wheres);

        setReportId(props.editQuery.id);

        setGroups(props.editQuery.groupBys);
        if (props.editQuery.groupBys && props.editQuery.groupBys.length > 0) {
          setGroup(true);
        }
        if (props.editQuery.orderBys && props.editQuery.orderBys.length > 0) {
          setOrderby(true);
          const newOrderbyState = {
            columns: {
              "column-1": {
                id: "column-1",
                orderbyIds: props.editQuery.orderBys
              }
            }
          };
          setOrderByState(newOrderbyState);
        }
      }
    }
  }, [props.editQuery]);

  useEffect(() => {
    buildQuery();
  }, [
    columns,
    wheres,
    groups,
    orderBys,
    reload,
    reloadSelectors,
    copiedData,
    orderByState,
    readOnly
  ]);

  useEffect(() => {}, [query, copiedData]);

  const addWhere = () => {
    if (wheres.length >= 1) {
      const newId = wheres.length;
      const newList = wheres.concat({ id: newId });
      setWheres(newList);
    } else {
      const tempArray = [{ id: 0 }];
      setWheres(tempArray);
    }
  };

  const updateWhere = (id, field, operator, value, andOr, value2) => {
    if (!field) return;
    if (field.length === 0) return;

    const tempArray = wheres;
    const currentWhere = tempArray.find((w) => w.id === id);
    if (currentWhere) {
      currentWhere.field = field;
      currentWhere.operator = operator;
      currentWhere.value = value;
      currentWhere.andOr = andOr;
      currentWhere.value2 = value2;

      if (wheres.length == 1) {
        setWheres([currentWhere]);
      } else {
        const index = tempArray.findIndex((w) => w.id === id);
        tempArray[index] = currentWhere;
        setWheres(tempArray);
      }
      setReload(!reload);
    }
  };

  const buildQuery = () => {
    // check to see if we are using the raw sql field
    const raw = columns.find((c) => c.label == "Raw SQL");
    if (raw) {
      setReadOnly(false);
      if (columns.length > 1) {
        toast.warn(
          "If you are using the Raw SQL Column, you cannot use any other columns. Please adjust your column selection appropriately.",
          {
            autoClose: 5000
          }
        );
      }
    } else {
      setReadOnly(true);
    }

    let tempQuery = "SELECT ";
    if (columns.length === 0) {
      tempQuery = tempQuery + " * ";
    }
    columns.map((col) => {
      if (col.custom && col.custom.length > 0) {
        tempQuery = tempQuery + col.custom + ", ";
        return;
      }
      let aggregate = "";
      if (col.aggregate === "1") {
        aggregate = "MIN(";
      } else if (col.aggregate === "2") {
        aggregate = "MAX(";
      } else if (col.aggregate === "3") {
        aggregate = "AVG(";
      } else if (col.aggregate === "4") {
        aggregate = "CNT(";
      } else if (col.aggregate === "5") {
        aggregate = "SUM(";
      } else if (col.aggregate === "6") {
        aggregate = "DISTINCT(";
      }
      tempQuery += aggregate + col.accessor.toUpperCase();
      if (aggregate.length > 0) {
        tempQuery = tempQuery + ") AS " + col.alias.toUpperCase() + ", ";
      } else {
        tempQuery = tempQuery + ", ";
      }
    });

    // remove the last comma from the fields
    if (columns.length > 0) {
      tempQuery = tempQuery.substr(0, tempQuery.length - 2);
    }
    tempQuery = tempQuery + " FROM BASKET_ITEMSEJ ";

    // NOW LETS CHECK TO SEE IF WE NEED TO JOIN OUR GROUPS OR NOT
    const groupWhere = wheres.find((w) => w.field === "group");
    if (groupWhere) {
      tempQuery =
        tempQuery +
        " LEFT JOIN RREPORTS_LNK ON BASKET_ITEMSEJ.STOREID=RREPORTS_LNK.STOREID ";
    }

    // now add our where clauses if applicable
    if (wheres.length > 0) {
      tempQuery = tempQuery + "WHERE ";

      wheres.map((where, i) => {
        if (i > 0 && where.andOr == "1") {
          tempQuery = tempQuery + " AND ";
        } else if (i > 0 && where.andOr == "2") {
          tempQuery = tempQuery + " OR ";
        }
        if (i > 0 && where.andOr == "2") {
          tempQuery = tempQuery + "(";
        }

        if (Object.keys(where).length > 1) {
          const accessorObject = props.data.find(
            (d) => d.accessor.toUpperCase() === where.field.toUpperCase()
          );
          if (accessorObject) {
            let operatorValue = "=";
            switch (parseInt(where.operator)) {
              case 1:
                operatorValue = "=";
                break;
              case 2:
                operatorValue = ">";
                break;
              case 3:
                operatorValue = "<";
                break;
              case 4:
                operatorValue = ">=";
                break;
              case 5:
                operatorValue = "<=";
                break;
              case 6:
                operatorValue = "Between";
                break;
              case 7:
                operatorValue = "Like";
                break;
              case 8:
                operatorValue = "Not";
                break;
              case 9:
                operatorValue = "In";
                break;
              case 10:
                operatorValue = "<>";
                break;
              default:
                operatorValue = "=";
                break;
            }
            if (parseInt(where.operator) === 9) {
              const fields = where.value.split(",");
              let valueList = "";
              for (var o = 0; o < fields.length; o++) {
                valueList += "'" + fields[o] + "',";
              }
              valueList = valueList.substr(0, valueList.length - 1);
              tempQuery =
                tempQuery +
                where.field.toUpperCase() +
                " " +
                operatorValue +
                " (" +
                valueList +
                " ) ";
            } else if (parseInt(where.operator) === 7) {
              tempQuery =
                tempQuery +
                where.field.toUpperCase() +
                " " +
                operatorValue +
                " '%" +
                where.value +
                "%' ";
            } else if (parseInt(where.operator) === 6) {
              tempQuery =
                tempQuery +
                where.field.toUpperCase() +
                " " +
                operatorValue +
                " '" +
                formatDate(where.value) +
                "' AND '" +
                formatDate(where.value2) +
                "' ";
            } else if (where.field === "group") {
              tempQuery =
                tempQuery +
                " RREPORTS_LNK.GROUPID " +
                operatorValue +
                " '" +
                where.value +
                "' ";
            } else if (where.field == "f254") {
              tempQuery =
                tempQuery +
                where.field.toUpperCase() +
                " " +
                operatorValue +
                " '" +
                formatDate(where.value) +
                "' ";
            } else {
              tempQuery =
                tempQuery +
                where.field.toUpperCase() +
                " " +
                operatorValue +
                " '" +
                where.value +
                "' ";
            }
          }
        }
        if (i > 0 && where.andOr == "2") {
          tempQuery = tempQuery + ") ";
        }
      });
    }

    if (groups && groups.length > 0) {
      tempQuery = tempQuery + " GROUP BY ";
      groups.map((group) => {
        tempQuery = tempQuery + group.group + ",";
      });

      tempQuery = tempQuery.substr(0, tempQuery.length - 1);

      if (orderByState.columns["column-1"].orderbyIds.length > 0) {
        tempQuery = tempQuery + " ORDER BY ";
        orderByState.columns["column-1"].orderbyIds.map((orderby) => {
          let direction = orderby.direction;
          let dir = "";
          if (direction == "1") {
            dir = "ASC";
          } else if (direction == "2") {
            dir = "DESC";
          }
          tempQuery = tempQuery + orderby.orderby + " " + dir + ",";
        });
        tempQuery = tempQuery.substr(0, tempQuery.length - 1);
      }
    }

    setQuery(tempQuery);
  };

  const deleteWhere = (id) => {
    const otherWheres = wheres.filter((w) => w.id !== id);
    setWheres(otherWheres);
  };

  const addGroup = () => {
    setGroups([...groups, { id: groups.length + 1 }]);
  };

  const addOrderBy = () => {
    let newState;
    if (orderByState.columns["column-1"].orderbyIds.length === 0) {
      newState = {
        columns: {
          "column-1": {
            id: "column-1",
            orderbyIds: [
              {
                id: "0",
                orderby: "",
                direction: 2
              }
            ]
          }
        }
      };
    } else {
      newState = {
        columns: {
          "column-1": {
            id: "column-1",
            orderbyIds: [
              ...orderByState.columns["column-1"].orderbyIds,
              {
                id: orderByState.columns[
                  "column-1"
                ].orderbyIds.length.toString()
              }
            ]
          }
        }
      };
    }

    setOrderByState(newState);
  };

  const changeAggregate = (item, value) => {
    const column = props.data.find((i) => i.id === item.id);
    if (column) {
      column.aggregate = value;
    }

    const index = props.data.findIndex((i) => i.id === item.id);
    const tempColumns = Array.from(props.data);
    tempColumns.splice(index, 1, column);
    setCopiedData(tempColumns);
    setReloadSelectors(!reloadSelectors);
  };

  const changeAlias = (item, value) => {
    const column = props.data.find((i) => i.id === item.id);
    if (column) {
      column.alias = value;
    }

    const index = props.data.findIndex((i) => i.id === item.id);
    const tempColumns = Array.from(props.data);
    tempColumns.splice(index, 1, column);
    setCopiedData(tempColumns);
    setReloadSelectors(!reloadSelectors);
  };

  const changeCss = (item, value) => {
    const column = copiedData.find((i) => i.accessor === item.accessor);
    if (column) {
      column.css = value;
    }

    const index = copiedData.findIndex((i) => i.accessor === item.accessor);
    const tempColumns = Array.from(copiedData);
    tempColumns.splice(index, 1, column);
    setCopiedData(tempColumns);
    setReloadSelectors(!reloadSelectors);
  };

  const changeCustom = (id, value) => {
    const column = copiedData.find((i) => i.id === id);
    if (column) {
      column.custom = value;
    }

    const index = copiedData.findIndex((i) => i.id === id);
    const tempColumns = Array.from(copiedData);
    tempColumns.splice(index, 1, column);
    setCopiedData(tempColumns);
    setReloadSelectors(!reloadSelectors);
  };

  const unLink = (item) => {
    const column = copiedData.find((i) => i.id == item.id);
    if (column) {
      column.custom = "";
    }

    const index = copiedData.findIndex((i) => i.id == item.id);
    const tempColumns = Array.from(copiedData);
    tempColumns.splice(index, 1, column);
    setCopiedData(tempColumns);
    setReloadSelectors(!reloadSelectors);
  };

  const test = () => {
    testQuery(context.url, query, context.basketApi)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setMsg({
            msg: "Query Successful. " + j.rowCount + " records returned.",
            type: "success"
          });
          setShowSave(true);
        } else {
          setMsg({ msg: j.msg, type: "danger" });
        }
      })
      .catch((err) => {
        console.log(err);
        setMsg({ msg: err.message, type: "danger" });
      });
  };

  const save = () => {
    setMsg("");
    if (reportName.length === 0) {
      setMsg("Please enter a report name");
      return;
    }

    saveQuery(
      context.url,
      context.basketApi,
      reportName,
      query.replace(/'/g, "|"),
      columns,
      wheres,
      groups,
      orderByState.columns["column-1"].orderbyIds,
      reportDescription,
      context.username
    )
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setMsg({ msg: "Query saved", type: "success" });
        } else {
          setMsg({ msg: j.msg, type: "danger" });
        }
      })
      .catch((err) => {
        console.log(err);
        setMsg({ msg: err.message, type: "danger" });
      });
  };

  const update = () => {
    setMsg("");
    if (reportName.length === 0) {
      setMsg("Please enter a report name");
      return;
    }

    saveQuery(
      context.url,
      context.basketApi,
      reportName,
      query.replace(/'/g, "|"),
      columns,
      wheres,
      groups,
      orderByState.columns["column-1"].orderbyIds,
      reportDescription,
      context.username,
      1,
      reportId
    )
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setMsg({ msg: "Query Updated", type: "success" });
        } else {
          setMsg({ msg: j.msg, type: "danger" });
        }
      })
      .catch((err) => {
        console.log(err);
        setMsg({ msg: err.message, type: "danger" });
      });
  };

  const readGroupStores = () => {
    readGroupAsync(context.url, context.token)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setUserGroups(j.groups);
        } else {
          setUserGroups([]);
          toast.warn(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setUserGroups([]);
        console.log(err);
        toast.error("An internal error occured", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const readStoreList = () => {
    getAssignedStoresAsync(context.url, context.token)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setStores(j.items);
        } else {
          setStores([]);
          toast.warn(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setStores([]);
        toast.error("An Internal error occured", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const readCategories = () => {
    getCategories(context.url, context.basketApi)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setCategories(j.categories);
        } else {
          setCategories([]);
        }
      })
      .catch((err) => {
        console.log(err);
        setCategories([]);
        toast.error(err, {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const readSubs = () => {
    getSubs(context.url, context.basketApi)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setSubs(j.subs);
        } else {
          setSubs([]);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err, {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const exit = () => {
    // we need to set the columns and the copiedColumns to make this work
    setReadOnly(true);
    setColumns([]);
    setCopiedData([]);
    setReloadSelectors(!reloadSelectors);
  };

  return (
    <div>
      <div className="container">
        <h4>Query Builder</h4>
        <div className="row mt-2">
          {readOnly ? (
            <div className="col-12 query">{query}</div>
          ) : (
            <Input
              type="textarea"
              cols="3"
              rows="3"
              style={{ backgroundColor: "#fff", color: "#000", padding: "5px" }}
              className="form-control"
              readOnly={false}
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
          )}
        </div>
        <div className="row jusify-content-space-between mt-2">
          <div className="col-12">
            <label>Enter a name for your Report</label>
          </div>
          <div className="col-6">
            <input
              className="form-control"
              type="text"
              value={reportName}
              onChange={(e) => setReportName(e.target.value)}
            />
          </div>
          <div className="col-2" style={{ textAlign: "center" }}>
            <button className="btn btn-warning" onClick={test}>
              Test
            </button>
          </div>
          <div className="col-4">
            {editQuery ? (
              <React.Fragment>
                {showSave ? (
                  <React.Fragment>
                    <button className="btn btn-primary" onClick={update}>
                      Update
                    </button>
                    <button className="btn btn-info" onClick={save}>
                      Save As
                    </button>
                  </React.Fragment>
                ) : null}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {showSave ? (
                  <button className="btn btn-primary" onClick={save}>
                    Save
                  </button>
                ) : null}
              </React.Fragment>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <label>Enter a Description for your Report:</label>
            <textarea
              className="form-control rounded-1"
              rows="3"
              value={reportDescription}
              onChange={(e) => setReportDescription(e.target.value)}
              style={{ padding: "8px" }}
            ></textarea>
          </div>
        </div>
      </div>
      {Object.keys(msg).length > 0 ? (
        <div className="row">
          <div
            className="col-12"
            style={{
              textAlign: "center",
              color: msg.type == "danger" ? "red" : "limegreen",
              marginTop: "10px"
            }}
          >
            {msg.msg}
          </div>
        </div>
      ) : null}
      {readOnly ? (
        <div className="row justify=content-center mt-2">
          <div className="col-12">
            <div className="queryBuilder">
              <VisibleFields
                data={copiedData}
                setColumns={setColumns}
                changeAggregate={changeAggregate}
                changeAlias={changeAlias}
                changeCss={changeCss}
                columns={columns}
                editQuery={editQuery}
                changeCustom={changeCustom}
                unLink={unLink}
              />
              <Controls addWhere={addWhere} />
              {props.data.length > 0 ? (
                <Where
                  setWheres={setWheres}
                  wheres={wheres}
                  data={props.data}
                  updateWhere={updateWhere}
                  deleteWhere={deleteWhere}
                  reloadSelectors={reloadSelectors}
                  setReloadSelectors={setReloadSelectors}
                  userGroups={userGroups}
                  stores={stores}
                  subs={subs}
                  categories={categories}
                />
              ) : null}
              <div className="row mt-3 groupby">
                <div className="col-2">
                  <label className="me-2">Group By</label>
                  <input
                    type="checkbox"
                    checked={group}
                    style={{ marginLeft: "10px" }}
                    onChange={(e) => setGroup(e.target.checked)}
                  />
                </div>
                {group ? (
                  <React.Fragment>
                    <div
                      className="col-10 justify-content-end"
                      style={{ textAlign: "right" }}
                    >
                      <button className="btn btn-primary" onClick={addGroup}>
                        Add Group
                      </button>
                    </div>

                    {groups.length > 0 ? (
                      <React.Fragment>
                        {groups.map((group, i) => (
                          <GroupBy
                            key={i}
                            data={props.data}
                            groups={groups}
                            setGroups={setGroups}
                            id={group.id}
                            activeGroup={group}
                          />
                        ))}
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                ) : null}
              </div>

              <div className="row mt-3 orderby">
                <div className="col-2">
                  <label className="me-2">Order By</label>
                  <input
                    type="checkbox"
                    checked={orderby}
                    style={{ marginLeft: "10px" }}
                    onChange={(e) => setOrderby(e.target.checked)}
                  />
                </div>
                {orderby ? (
                  <React.Fragment>
                    <div
                      className="col-10 justify-content-end"
                      style={{ textAlign: "right" }}
                    >
                      <button className="btn btn-primary" onClick={addOrderBy}>
                        Add OrderBy
                      </button>
                    </div>

                    {orderBys.length > 0 ? (
                      <React.Fragment>
                        <OrderBys
                          orderBys={orderBys}
                          setOrderBys={setOrderBys}
                          data={props.data}
                          reloadSelectors={reloadSelectors}
                          setReloadSelectors={setReloadSelectors}
                          state={orderByState}
                          setState={setOrderByState}
                        />
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="row justify-content-center mt-3">
          <div className="row-12">
            <Tooltip
              message={"Exit out of Raw SQL Mode back to column select mode"}
              position="top"
            >
              <div className="btn btn danger" onClick={exit}>
                Eject
              </div>
            </Tooltip>
          </div>
        </div>
      )}
    </div>
  );
};

QueryBuilder.propTypes = {
  data: PropTypes.array,
  editQuery: PropTypes.object
};

export default QueryBuilder;
