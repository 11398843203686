/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { ContextConfig } from "../../App";
import {
  readSalesByUser,
  readBannerPanels,
  readDeptGroups,
  readRefunds,
  readSubSales
} from "../../api/DashboardApi";
import { usePrevious, encrypt } from "../../utils";
import "../Homepage.css";
import { toast } from "react-toastify";
import { Row, Col } from "reactstrap";
import { isEqual } from "../../fp";

import FixedPlugin from "../components/FixedPlugin";
import SalesChart from "../components/SalesChart";
import Panels from "../components/Panels";
import Depts from "../components/Depts";
import Refunds from "../components/Refunds";
import Subs from "../components/Subs";

import { withRouter } from "react-router-dom";
import Spinner from "../../common/Spinner";
import TopSellingItemsContainer from "../Pages/TopItems/TopSellingItemsContainer";
import TopItems from "../Pages/TopItems/TopItems";
import QueryBuilder from "./query/QueryBuilder";
import Collapsible from "../../common/Collapsible";
import ResetDialog from "../../Clock/Login/ResetDialog";
import { resetPassword } from "../../api/UsersApi";
import { isMobile } from "react-device-detect";

const Main = (props) => {
  const [isLoading, setLoading] = useState(false);
  const [storeGroupsData, setstoreGroupsData] = useState([]); // eslint-disable-line
  const [totalSales, setTotalSales] = useState([]);
  const [netSales, setNetSales] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [salesSelector, setSalesSelector] = useState("data1");
  const [salesLabels, setSalesLabels] = useState([]);
  const [bannerData, setBannerData] = useState([]);
  const [deptSales, setDeptSales] = useState([]);
  const [deptLabels, setDeptLabels] = useState([]);
  const [refunds, setRefunds] = useState([]);
  const [refundLabels, setRefundLabels] = useState([]);
  const [subSales, setSubSales] = useState([]);
  const [subLabels, setSubLabels] = useState([]);
  const [tlz, setTlz] = useState("Refund");
  const [activeColor, setActiveColor] = useState("blue");
  const [sidebarMini, setSidebarMini] = useState(true);
  const [showReset, setShowReset] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  // const [mapStyle, setMapStyle] = useState([]);

  const context = useContext(ContextConfig);

  const previousSalesData = usePrevious(totalSales);
  const previousBannerData = usePrevious(bannerData);
  const previousDeptSales = usePrevious(deptSales);
  const previousRefunds = usePrevious(refunds);
  const previousSubSales = usePrevious(subSales);

  useEffect(() => {
    if (!isEqual(previousSalesData, totalSales)) {
      loadChartData();
    }
    if (!isEqual(previousBannerData, bannerData)) {
      loadBannerData();
    }
    if (!isEqual(previousDeptSales, deptSales)) {
      loadDeptSales();
    }
    if (!isEqual(previousRefunds, refunds)) {
      loadRefunds("3011");
    }
    if (!isEqual(previousSubSales, subSales)) {
      loadSubSales();
    }

    if (context.resetPassword.toString() == "1") {
      setShowReset(true);
    }
  }, [totalSales, bannerData, deptSales, showReset]);

  const handleActiveClick = () => {};

  const handleMiniClick = () => {};

  const loadChartData = () => {
    setLoading(true);
    readSalesByUser(context.url, context.token)
      .then((response) => {
        setLoading(false);
        const j = response.data;
        if (j.error === 0) {
          mergeSalesDataByUser(j.totalSales, j.netSales, j.taxes);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const loadBannerData = () => {
    readBannerPanels(context.url, context.token)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setBannerData(j.panels);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An erro occured loading the banner data", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const loadDeptSales = () => {
    readDeptGroups(context.url, context.token)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          extractDeptLabels(j.sales);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An error occured proccssing dept sales", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const loadRefunds = (tlz) => {
    switch (tlz) {
      case "3001":
        setTlz("Error Correct");
        break;
      case "3002":
        setTlz("Void");
        break;
      case "3011":
        setTlz("Refund");
        break;
      case "3003":
        setTlz("Cancel");
        break;
      case "3004":
        setTlz("Correction");
        break;
    }
    readRefunds(context.url, context.token, tlz)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          mergeRefunds(j.refunds);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An error occured processing the refunds", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const loadSubSales = () => {
    readSubSales(context.url, context.token)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          mergSubSales(j.sales);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An error occured processing Sub Sales", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const mergeSalesDataByUser = (totalSales, netSales, tax) => {
    const newTotalSales = totalSales.map((record) => {
      return record.f65.toFixed(2, 10);
    });

    const newNetSales = netSales.map((record) => {
      return record.f65.toFixed(2, 10);
    });

    const newTaxes = tax.map((record) => {
      return record.f65.toFixed(2, 10);
    });

    const labels = totalSales.map((record) => {
      return record.month;
    });

    setSalesLabels(labels);
    setTotalSales(newTotalSales);
    setNetSales(newNetSales);
    setTaxes(newTaxes);
  };

  const extractDeptLabels = (sales) => {
    const labels = sales.map((record) => {
      return record.group;
    });

    const depts = sales.map((record) => {
      return record.f65.toFixed(2, 10);
    });

    setDeptLabels(labels);
    setDeptSales(depts);
  };

  const mergeRefunds = (refunds) => {
    const sales = refunds.map((record) => {
      return record.f65.toFixed(2, 10);
    });

    const labels = refunds.map((record) => {
      return record.month;
    });

    setRefunds(sales);
    setRefundLabels(labels);
  };

  const mergSubSales = (sales) => {
    const labels = sales.map((record) => {
      return record.group;
    });

    const subs = sales.map((record) => {
      return record.f65.toFixed(2, 10);
    });

    setSubLabels(labels);
    setSubSales(subs);
  };

  const handleBannerDrill = (record) => {
    const drill = {
      banner: record
    };
    context.drill = drill;
    props.history.push(`/home/banners/${record.groupNum}`);
  };

  const hide = () => {
    setShowReset(false);
    context.resetPassword = "0";
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleResetPassword = (e) => {
    const encryptedPassword = encrypt(e, context.key).toString();
    resetPassword(context.url, context.token, context.userid, encryptedPassword)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          hide();
        } else {
          toast.warning(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An Internal Error occured", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  return (
    <div>
      {isLoading && <Spinner />}
      {context.resetPassword.toString() === "1" && (
        <ResetDialog
          isShowing={showReset}
          hide={hide}
          newPassword={newPassword}
          handleNewPasswordChange={handleNewPasswordChange}
          confirmPassword={confirmPassword}
          handleConfirmNewPasswordChange={handleConfirmPasswordChange}
          handleResetClick={handleResetPassword}
          context={context}
        />
      )}
      <div className="dashboard-content">
        <Row>
          <SalesChart
            salesLabels={salesLabels}
            totalSales={totalSales}
            netSales={netSales}
            taxes={taxes}
            salesSelector={salesSelector}
            setSalesSelector={setSalesSelector}
          />

          <Row
            style={{
              padding: "20px",
              display: "flex",
              justifyContent: "center"
            }}
          >
            <Panels bannerData={bannerData} handleClick={handleBannerDrill} />
          </Row>
          <Row
            className="w-100"
            style={{
              margin: "auto",
              display: "flex",
              justifyContent: "center"
            }}
          >
            <Depts deptLabels={deptLabels} deptSales={deptSales} />

            <Refunds
              tlz={tlz}
              loadRefunds={loadRefunds}
              refundLabels={refundLabels}
              refunds={refunds}
            />

            <Subs subLabels={subLabels} subSales={subSales} />
          </Row>
        </Row>
        {/* {!isMobile ? (
          <React.Fragment>
            <Row>
              <Collapsible label="Query Builder">
                <QueryBuilder />
              </Collapsible>
            </Row>
            <Row>
              <Col xs="12">
                <TopItems />
              </Col>
            </Row>
          </React.Fragment>
        ) : null} */}
      </div>
      <FixedPlugin
        activeColor={activeColor}
        sidebarMini={sidebarMini}
        handleActiveClick={handleActiveClick}
        handleMiniClick={handleMiniClick}
      />
    </div>
  );
};

Main.propTypes = {
  history: PropTypes.object
};

export default withRouter(Main);
