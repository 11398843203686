import React from "react";
import PropTypes from "prop-types";
//import colors from "../common/colors";
import "./Clock.css";
import ClockSidebar from "./ClockSidebar";
import { withRouter } from "react-router-dom";
//import { formatDate } from "../utils";

const ClockMobile = (props) => {
  const handleClockInOutDivClick = () => {
    props.history.push("/clockinout");
  };

  // const handleLoginClick = () => {
  //   props.history.push("/clock");
  // };

  const handleTimecardClick = () => {
    props.history.push("/timecard");
  };

  const handleRequest = () => {
    props.history.push("/request");
  };

  const formatMainDate = () => {
    const date = new Date();
    const dayOfWeek = getDayOfWeek();
    return dayOfWeek + ", " + date.getDate() + " " + getMonth();
  };

  const getDayOfWeek = () => {
    const date = new Date();
    const day = date.getDay();
    switch (day) {
      case 0:
        return "Sunday";
      case 1:
        return "Monday";
      case 2:
        return "Tuesday";
      case 3:
        return "Wednesday";
      case 4:
        return "Thursday";
      case 5:
        return "Friday";
      case 6:
        return "Saturday";
      default:
        return "";
    }
  };

  const getMonth = () => {
    const date = new Date();
    const month = date.getMonth() + 1;
    switch (month) {
      case 1:
        return "January";
      case 2:
        return "February";
      case 3:
        return "March";
      case 4:
        return "April";
      case 5:
        return "May";
      case 6:
        return "June";
      case 7:
        return "July";
      case 8:
        return "August";
      case 9:
        return "September";
      case 10:
        return "October";
      case 11:
        return "November";
      case 12:
        return "December";
    }
  };

  return (
    <div className="clock-mobile">
      <div
        className="mobile-clock-header"
        style={{ backgroundColor: "#1f1e2e" }}
      >
        <ClockSidebar />
        <div
          className="mobile-clock-home-screen"
          style={{ display: "grid", gridTemplateRows: "30% 70%" }}
        >
          <div
            className="clock-mobile-desktop-upperdiv"
            style={{ color: "#fff" }}
          >
            <div className="clock-mobile-main-date">{formatMainDate()}</div>
          </div>
          <div className="clock-mobile-desktop-lowerDiv">
            <div className="clock-mobile-lower-icon">
              <div className="clock-mobile-lower-circle">
                <i className="far fa-clock fa-3x"></i>
              </div>
            </div>
            <div className="clock-mobile-main-buttons">
              <div
                className="clock-mobile-main-button"
                onClick={handleClockInOutDivClick}
              >
                <div
                  className="clock-mobile-main-button-icon"
                  style={{ backgroundColor: "limegreen" }}
                >
                  <i className="far fa-fingerprint fa-3x"></i>
                </div>

                <div className="clock-mobile-main-button-text">
                  Clock in/Out
                </div>
              </div>

              <div
                className="clock-mobile-main-button"
                style={{ marginTop: "30px" }}
              >
                <div
                  className="clock-mobile-main-button-icon"
                  style={{ backgroundColor: "steelblue", color: "#ddd" }}
                  onClick={handleTimecardClick}
                >
                  <i className="far fa-clock fa-3x"></i>
                </div>

                <div className="clock-mobile-main-button-text">Timecard</div>
              </div>

              <div
                className="clock-mobile-main-button"
                style={{ marginTop: "30px" }}
                onClick={handleRequest}
              >
                <div
                  className="clock-mobile-main-button-icon"
                  style={{ backgroundColor: "orange", color: "#ddd" }}
                >
                  <i className="fal fa-island-tropical fa-3x"></i>
                </div>

                <div className="clock-mobile-main-button-text">PTO Request</div>
              </div>

              <div
                className="clock-mobile-main-button mobile-disabled"
                style={{ marginTop: "30px" }}
              >
                <div
                  className="clock-mobile-main-button-icon"
                  style={{ backgroundColor: "red", color: "#ddd" }}
                >
                  <i className="far fa-calendar-week fa-3x"></i>
                </div>

                <div className="clock-mobile-main-button-text">Schedule</div>
              </div>
            </div>
          </div>
          {/* <div className="mobile-clock-home-left">
            <div onClick={handleClockInOutDivClick} className="mc-d-div">
              <div className="mc-d-text-l">Clock In/out</div>
              <div className="mc-d-icon-l">
                <i className="far fa-fingerprint"></i>
              </div>
            </div>
            <div className="mc-d-div mobile-disabled">
              <div className="mc-d-text-l">Schedule</div>
              <div className="mc-d-icon-l">
                <i className="far fa-calendar-week"></i>
              </div>
            </div>
            <div className="mc-d-div mobile-disabled">
              <div className="mc-d-text-l">Me</div>
              <div className="mc-d-icon-l">
                <i className="fal fa-user"></i>
              </div>
            </div>
          </div>
          <div className="mobile-clock-home-right">
            <div onClick={handleLoginClick} className="mc-d-div">
              <div className="mc-d-text-r">Login</div>
              <div className="mc-d-icon-r">
                <i className="fal fa-key-skeleton"></i>
              </div>
            </div>
            <div onClick={handleTimecardClick} className="mc-d-div">
              <div className="mc-d-text-r">Timecard</div>
              <div className="mc-d-icon-r">
                <i className="far fa-clock"></i>
              </div>
            </div>
            <div className="mc-d-div mobile-disabled">
              <div className="mc-d-text-r">Trade Shift</div>
              <div className="mc-d-icon-r">
                <i className="far fa-exchange"></i>
              </div>
            </div>
            <div onClick={handleRequest} className="mc-d-div">
              <div className="mc-d-text-r">Requests</div>
              <div className="mc-d-icon-r">
                <i className="fal fa-plane-departure"></i>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

ClockMobile.propTypes = {
  history: PropTypes.object
};

export default withRouter(ClockMobile);
