import React, { useState, useEffect, useContext } from "react";
import LoadingOverlay from "react-loading-overlay";
import HeaderRB from "../common/HeaderRb";
import { ContextConfig } from "../App";
import { getMonthOverview } from "../api/PayServerApi";
import { toast } from "react-toastify";
import { usePrevious, formatDate } from "../utils";
import { isEqual } from "lodash";
import "./PointHistory.css";
import { Redirect } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";
import PointHistoryMobile from "./PointHistoryMobile";
import PointHistoryDesktop from "./PointHistoryDesktop";
import { debounce } from "../fp";

const PointHistory = () => {
  const [activity, setActivity] = useState([]);
  const [header, setHeader] = useState([]);
  const [balance, setBalance] = useState(0);
  const [redirect, setRedirect] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const context = useContext(ContextConfig);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const previousActivity = usePrevious(activity);
  useEffect(() => {
    if (!isEqual(previousActivity, activity)) {
      loadHistory();
    }

    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);

    findDivHeight();
    window.addEventListener("resize", debouncedHandleResize);

    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  }, [history, balance, header]);

  const findDivHeight = () => {
    const navbar = document.getElementById("master-navbar");
    const controls = document.getElementById("ps-history-controls");
    const remaining =
      window.innerHeight -
      controls.getBoundingClientRect().height -
      navbar.getBoundingClientRect().height -
      50;
    const div = document.getElementById("ps-history-body");
    div.style.height = remaining + "px";
    div.style.overflow = "auto";
  };

  const handleBackButton = () => {
    setRedirect(true);
  };

  const loadHistory = () => {
    setIsLoading(true);
    const { phonenumber, activityDate } = context.loyalty;
    getMonthOverview(context.psapi, phonenumber, activityDate, context.apikey)
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          setActivity(j.activity);
          setHeader(j.header);
          setBalance(j.balance);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        toast.error("An error has occured getting your Customer Activity", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  return (
    <LoadingOverlay active={isLoading} spinner text="Loading Data...">
      {redirect && <Redirect to="/ps" />}
      <HeaderRB />
      <div id="ps-history-controls">
        <h3 className="pa-h3">Customer Activity</h3>
        <div className="pa-header">
          <div className="pa-customer-phone">
            Customer Phone #:{" "}
            {`(${context.loyalty.phonenumber.substring(
              0,
              3
            )}) ${context.loyalty.phonenumber.substring(
              3,
              6
            )}-${context.loyalty.phonenumber.substring(6, 10)}`}
          </div>
          <div className="pa-customer-activity-date">
            Date: {formatDate(context.loyalty.activityDate)}
          </div>
        </div>
      </div>

      <div id="ps-history-body" style={{ marginBottom: "50px" }}>
        <MobileView>
          <PointHistoryMobile
            context={context}
            header={header}
            activity={activity}
            balance={balance}
            handleBackButton={handleBackButton}
          />
        </MobileView>
        <BrowserView>
          <PointHistoryDesktop
            context={context}
            header={header}
            activity={activity}
            balance={balance}
            handleBackButton={handleBackButton}
          />
        </BrowserView>
      </div>
    </LoadingOverlay>
  );
};

export default PointHistory;
