import React, { useState, useEffect, useContext } from "react";
import { ContextConfig } from "../../App";
import {
  openPunches,
  openPunchesByStore,
  updatePunch
} from "../../api/widgetsApi";
import { deletePunch } from "../../api/punchApi";
import ConfirmDelete from "../../common/ConfirmDelete";
import { toast } from "react-toastify";
import OpenPunchesSpinner from "./OpenPunchesSpinner";
import OpenPunchThumbnail from "./OpenPunchThumbnail";
import ModalPunch from "./ModalPunch";
import {
  usePrevious,
  isValid,
  formatDate,
  formatTimeAmPm,
  isValidDate
} from "../../utils";
import { isEqual } from "../../fp";
import { roundPunch, totalPunch } from "../clockUtils";
import { variables } from "../../common/variables";
import "./OpenPunches.css";

const OpenPunches = () => {
  const [punches, setPunches] = useState([]);
  const [showDeletePunch, setShowDeletePunch] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [confirmText, setConfirmText] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPunch, setShowPunch] = useState(false);
  const [punchToEdit, setPunchToEdit] = useState({});
  const [modalClass, setModalClass] = useState("modal-fade-enter");
  const [moreInfo, setMoreInfo] = useState("");
  const [modalStyle, setModalStyle] = useState({
    animationDuration: "500ms",
    WebkitAnimationDuration: "500ms"
  });
  const [modalSeconds, setModalSeconds] = useState("500ms");

  //const colors = ["#f23d4c", "#2a2d40", "#2e6ff2", "#2291f2", "#04d9c4", "#3565f2"];
  const colors = ["#2A2D40", "#3565F2", "#2980F2", "#2291F2", "#04D9C4"];

  const getBackgroundColor = () => {
    return colors[Math.floor(Math.random() * colors.length)];
  };

  const context = useContext(ContextConfig);

  const previousStoreid = usePrevious(context.lastClockStore);
  const previousStartDate = usePrevious(context.startDate);
  const previousEndDate = usePrevious(context.endDate);
  const previousPunchToEdit = usePrevious(punchToEdit);

  useEffect(() => {
    if (
      previousStoreid != context.lastClockStore ||
      previousStartDate != context.startDate ||
      previousEndDate != context.endDate
    ) {
      loadPunches();
    }
  }, [
    punches,
    context.lastClockStore,
    context.startDate,
    context.endDate,
    modalClass
  ]);

  useEffect(() => {
    // this use effect is going to be for editing punches and shit
    if (isValid(punchToEdit)) {
      if (!isEqual(previousPunchToEdit !== punchToEdit)) {
        let { roundIn, roundOut, totalTime } = roundPunch(
          punchToEdit,
          context.settings
        );
        let out = "";

        if (isValidDate(punchToEdit.punchOutDate)) {
          out = roundOut;

          if (context.settings.roundingMethod !== 5) {
            totalTime = totalPunch(roundIn, roundOut, context.settings);
          }
          if (totalTime < 0) {
            toast.error(
              "You cannot have an out date that is previous to the in Date",
              {
                position: toast.POSITION.TOP_LEFT
              }
            );
            return;
          }
        }

        setPunchToEdit({
          ...punchToEdit,
          punchInDate: formatDate(roundIn),
          punchIn: formatTimeAmPm(roundIn),
          //punchOutDate: formatDate(out),
          punchOUt: formatTimeAmPm(out),
          total: totalTime
        });
        punchToEdit.total = totalTime;
      }
    }
  }, [punchToEdit.punchOut, punchToEdit.punchIn]);

  const reloadPunches = () => {
    loadPunches();
  };

  const loadPunches = () => {
    setIsLoading(true);
    if (isValid(context.lastClockStore) && context.lastClockStore != "0") {
      openPunchesByStore(
        context.clockUrl,
        context.clockToken,
        context.startDate,
        context.endDate,
        context.lastClockStore
      )
        .then((response) => {
          setIsLoading(false);
          const j = response.data;
          if (j.error === 0) {
            if (j.punches.length > 0) {
              setPunches(j.punches);
            } else {
              setPunches([]);
            }
          } else {
            console.log(j.msg);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    } else {
      setIsLoading(true);
      openPunches(
        context.clockUrl,
        context.clockToken,
        context.startDate,
        context.endDate
      )
        .then((response) => {
          setIsLoading(false);
          const j = response.data;
          if (j.error === 0) {
            if (j.punches.length > 0) {
              setPunches(j.punches);
            } else {
              setPunches([]);
            }
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  const handleDelete = (id) => {
    const punch = punches.find((p) => p.id === id);
    const div = punch.username + "-" + punch.punchInDate;
    setMoreInfo(div);
    setDeleteId(id);
    setShowDeletePunch(true);
  };

  const handleConfirmTextChange = (e) => {
    setConfirmText(e.target.value);
  };

  const compareDeleteText = () => {
    if (confirmText == "delete me") {
      setShowDeletePunch(false);
      deletePunch(context.clockUrl, context.clockToken, deleteId)
        .then((response) => {
          const j = response.data;
          if (j.error === 0) {
            reloadPunches();
          } else {
            toast.error(j.msg, {
              position: toast.POSITION.TOP_LEFT
            });
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error("An error occured deleting your punch", {
            position: toast.POSITION.TOP_LEFT
          });
        });
    } else {
      setError(confirmText + " does not match delete me");
    }
  };

  const handleEdit = (punch) => {
    if (!isValid(punch.punchOutDate)) {
      const newDate = new Date();
      const day = newDate.getDate();
      const month = newDate.getMonth() + 1;
      const year = newDate.getFullYear();
      punch.punchOutDate = month + "/" + day + "/" + year;
    }
    setPunchToEdit(punch);
    setShowPunch(true);
  };

  const handleChange = (e) => {
    setPunchToEdit({
      ...punchToEdit,
      comment: e.target.value
    });
    punchToEdit.comment = e.target.value;
  };

  const handleInChange = (punchin) => {
    debugger;
    if (
      typeof punchToEdit.punchInDate === "undefined" ||
      punchToEdit.punchInDate === null
    ) {
      punchToEdit.punchInDate = new Date();
    }
    if (punchToEdit.punchInDate.length === 0) {
      punchToEdit.punchInDate = new Date();
    }
    setPunchToEdit({
      ...punchToEdit,
      rawPunchIn:
        formatDate(punchToEdit.punchInDate) + " " + formatTimeAmPm(punchin),
      punchIn: formatTimeAmPm(punchin),
      punchOutDate: punchToEdit.punchOutDate
    });
    punchToEdit.rawPunchIn = punchin;
    // setPunchToEdit({
    //   ...punchToEdit,
    //   rawPunchIn: punchin,
    //   punchIn: formatTimeAmPm(punchin)
    // });
    // punchToEdit.rawPunchIn = punchin;
  };

  const handleOutChange = (punchout) => {
    if (
      typeof punchToEdit.punchOutDate === "undefined" ||
      punchToEdit.punchOutDate === null
    ) {
      punchToEdit.punchOutDate = new Date();
    }
    if (punchToEdit.punchOutDate.length === 0) {
      punchToEdit.punchOutDate = new Date();
    }
    setPunchToEdit({
      ...punchToEdit,
      rawPunchOut:
        formatDate(punchToEdit.punchOutDate) + " " + formatTimeAmPm(punchout),
      punchOut: formatTimeAmPm(punchout)
    });
    punchToEdit.rawPunchOut = punchout;
  };

  const handleDateInChange = (date) => {
    const newDate = new Date(date);
    const day = newDate.getDate();
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();
    const indate = month + "/" + day + "/" + year;
    setPunchToEdit({
      ...punchToEdit,
      punchInDate: indate
    });
    punchToEdit.punchInDate = date;
  };

  const handleDateOutChange = (date) => {
    const newDate = new Date(date);
    const day = newDate.getDate();
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();
    const outdate = month + "/" + day + "/" + year;
    setPunchToEdit({
      ...punchToEdit,
      punchOutDate: outdate
    });
    punchToEdit.punchOutDate = date;
  };

  const sendPunchChange = () => {
    setIsLoading(true);
    punchToEdit.punchIn =
      formatDate(punchToEdit.rawPunchIn) +
      " " +
      formatTimeAmPm(punchToEdit.rawPunchIn);
    if (
      typeof punchToEdit.rawPunchOut !== "undefined" &&
      punchToEdit.rawPunchOut !== null
    ) {
      punchToEdit.punchOut =
        punchToEdit.punchOutDate +
        " " +
        formatTimeAmPm(punchToEdit.rawPunchOut);
    }
    punchToEdit.punchId = punchToEdit.id;
    updatePunch(context.clockUrl, context.clockToken, punchToEdit)
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          loadPunches();
          setShowPunch(false);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const handleSavePunch = (e) => {
    e.preventDefault();
    if (typeof punchToEdit.punchType === "string") {
      if (punchToEdit.punchType.toUpperCase() === "PUNCH") {
        punchToEdit.punchType = 1;
      } else if (punchToEdit.punchType.toUpperCase() === "BREAK") {
        punchToEdit.punchType = 2;
      } else if (punchToEdit.punchType.toUpperCase() === "LUNCH") {
        punchToEdit.punchType = 3;
      }
    }

    const dte = new Date();
    punchToEdit.timeZoneOffset = dte.getTimezoneOffset();
    punchToEdit.isDst = dte.isDstObserved();
    process.env.NODE_ENV === "development"
      ? (punchToEdit.mode = "debug")
      : (punchToEdit.mode = "live");

    sendPunchChange();
  };

  const handleClassChange = (e) => {
    setModalClass(`modal-${e.target.value}-enter`);
  };

  const handleStyleChange = (e) => {
    let duration = e.target.value;
    const pos = duration.indexOf("s");
    if (pos <= 0) {
      duration = duration + "s"; // default to seconds but allow for milliseconds
    }
    setModalStyle({
      animationDuration: `${duration}`,
      WebkitAnimationDuration: `${duration}`
    });
    setModalSeconds(e.target.value);
  };

  const hidePunch = () => {
    // const newClass = modalClass.replace("-enter", "");
    // setModalClass(`${newClass}-leave`);
    setShowPunch(false);
  };

  return (
    <div style={variables.widgetStyle} className="op">
      {isLoading && <OpenPunchesSpinner />}
      <ConfirmDelete
        isShowing={showDeletePunch}
        hide={() => setShowDeletePunch(false)}
        confirmText={confirmText}
        setConfirmText={handleConfirmTextChange}
        compareDeleteText={compareDeleteText}
        error={error}
        moreInfo={moreInfo}
      />
      <ModalPunch
        isShowing={showPunch}
        hide={hidePunch}
        punch={punchToEdit}
        handleButtonClick={handleSavePunch}
        handleChange={handleChange}
        handleInChange={handleInChange}
        handleOutChange={handleOutChange}
        handleDateInChange={handleDateInChange}
        handleDateOutChange={handleDateOutChange}
        modalClass={modalClass}
        style={modalStyle}
      />
      <h3>Open Punches ({punches.length})</h3>
      <div
        className="row justify-content-center p-3  w-50 m-auto mt-2 mb-2 anim-container"
        style={{
          borderRadius: "20px",
          opacity: "0.8",
          backgroundColor: "#2291F2"
        }}
      >
        <div className="col-8">
          <select className="form-control" onChange={handleClassChange}>
            <option value="">Select an Animation</option>
            <option value="fade">Fade</option>
            <option value="zoom">Zoom</option>
            <option value="slideDown">Slide Down</option>
            <option value="slideUp">Slide Up</option>
            <option value="slideRight">Slide Right</option>
            <option value="slideLeft">Slide Left</option>
            <option value="flip">Flip</option>
            <option value="rotate">Rotate</option>
            <option value="door">Door</option>
          </select>
        </div>
        <div className="col-3">
          <input
            type="text"
            className="form-control"
            onChange={handleStyleChange}
            value={modalSeconds}
          />
        </div>
      </div>
      <div className="d-op-body mt-3">
        {punches.length === 0 ? (
          <div className="d-op-empty-list">
            There are currently no open punches
          </div>
        ) : (
          <React.Fragment>
            <div className="row justify-content-center">
              {punches.map((punch, index) => (
                <div className="col-2 d-flex" key={`opt-${index}`}>
                  <OpenPunchThumbnail
                    backgroundColor={getBackgroundColor()}
                    punch={punch}
                    handleDelete={handleDelete}
                    handleEdit={handleEdit}
                  />
                </div>
              ))}
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default OpenPunches;
