import React, { useReducer, useContext, useEffect, useState } from "react";
import HeaderRb from "../../common/HeaderRb";
import Spinner from "../../common/Spinner";
import { ContextConfig } from "../../App";
import { Row, Col } from "reactstrap";
import DatePicker from "react-datepicker";
import {
  ledgerReducer,
  tableReducer,
  LEDGER_REDUCER_TYPES
} from "./Ledger-Reducer";
import { getExpired } from "../../api/PayServerApi";
import { debounce } from "../../fp";
import { toast } from "react-toastify";
import { handleAjax, isValid, formatDate, isValidDate } from "../../utils";
import Column from "../../common/Column";

import "./Ledger.css";

const initialState = {
  isLoading: false,
  expired: [],
  filteredExpired: [],
  startDate: null,
  endDate: null,
  error: null,
  customer: 0,
  mounted: true
};

const initialTableState = {
  sortEarnedPointsAsc: false,
  sortExpiredPointsAsc: false,
  sortPostedAsc: false,
  sortF1148Asc: false,
  sortRedeemedPoints: false,
  sortDataType: "number",
  sortField: ""
};

const Ledger = () => {
  const [state, dispatch] = useReducer(ledgerReducer, initialState);
  const [tableState, tableDispatch] = useReducer(
    tableReducer,
    initialTableState
  );
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const context = useContext(ContextConfig);

  useEffect(() => {
    if (state.sortField === null) return;
    sortRecords(state.sortField);
  }, [tableState]);

  useEffect(() => {
    dispatch({
      type: LEDGER_REDUCER_TYPES.INIT,
      startDate: context.startDate,
      endDate: context.endDate,
      storeid: context.lastStoreid
    });

    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);

    findDivHeight();

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
      dispatch({ type: "unmount" });
    };
  }, []);

  useEffect(() => {
    if (isValid(state.error) && state.error.length > 0) {
      toast.error(state.error, {
        position: toast.POSITION.TOP_LEFT
      });
    }
  }, [state.error]);

  useEffect(() => {
    dispatch({ type: "filter", data: state.expired });
  }, [state.expired]);

  useEffect(() => {
    if (!isValidDate(state.startDate)) return;
    if (!isValidDate(state.endDate)) return;

    fetchExpired();
  }, [state.startDate, state.endDate, state.store]);

  const findDivHeight = () => {
    const navbar = document
      .getElementById("master-navbar")
      .getBoundingClientRect().height;
    const controls = document.getElementById("controls").getBoundingClientRect()
      .height;
    const usedSpace = navbar + controls + 30;
    const remaining = window.innerHeight - usedSpace;

    const body = document.getElementById("r-body");
    body.style.height = remaining + "px";
    body.style.overflow = "auto";
  };

  const fetchExpired = () => {
    dispatch({ type: "loaddata", data: "expired" });
    let customer = state.customer;
    if (customer.toString().length === 0) {
      customer = "0";
    }
    getExpired(
      context.psanalysis,
      context.apikey,
      customer,
      state.startDate,
      state.endDate
    )
      .then((response) => {
        handleAjax(response, dispatch, "expired");
      })
      .catch((err) => {
        dispatch({
          type: "fail",
          msg: "An error occured getting records from the server",
          error: err,
          store: "expired"
        });
      });
  };

  const getSortField = () => {
    switch (tableState.sortField) {
      case "posted":
        return tableState.sortPostedAsc;
      case "earnedPoints":
        return tableState.sortEarnedPointsAsc;
      case "redeemedPoints":
        return tableState.sortRedeemedPointsAsc;
      case "expiredPoints":
        return tableState.sortExpiredPointsAsc;
      case "f1148":
        return tableState.sortF1148Asc;
      default:
        return null;
    }
  };

  const compare = (a, b) => {
    if (tableState.sortField.length === 0) return;
    let fieldA = a[tableState.sortField].toUpperCase();
    let fieldB = b[tableState.sortField].toUpperCase();

    if (tableState.sortDataType == "number") {
      fieldA = parseFloat(a[tableState.sortField]);
      fieldB = parseFloat(b[tableState.sortField]);
    }

    let comparison = 0;
    if (fieldA > fieldB) {
      comparison = 1;
    } else if (fieldA < fieldB) {
      comparison = -1;
    }

    if (getSortField()) {
      return comparison * -1;
    } else {
      return comparison;
    }
  };

  const sortRecords = () => {
    dispatch({ type: "sortdata", store: "sorting" });
    dispatch({ type: "sortfilter", data: [...state.expired].sort(compare) });
  };

  const handleCustomerChange = (e) => {
    dispatch({ type: "customer", customer: e.target.value });
  };

  const handleCustomerSearch = () => {
    fetchExpired();
  };

  return (
    <div>
      {state.isLoading && <Spinner />}
      <HeaderRb />
      <div className="container ledger-wrapper-container">
        <div id="controls">
          <div className="ledger-upper-title">Point Expiration Ledger</div>
          <Row
            className="justify-content-center"
            style={{ marginBottom: "20px" }}
          >
            <Col md="3" style={{ textAlign: "center" }}>
              <label>Enter Customer/Phone Number:</label>
              <input
                type="number"
                className="form-control"
                value={state.customer}
                onChange={handleCustomerChange}
              />
            </Col>
            <Col
              md="3"
              style={{
                textAlign: "center",
                paddingTop: "30px"
              }}
            >
              <label>Start Date: </label>
              <DatePicker
                selected={state.startDate}
                onChange={(e) => dispatch({ type: "startdate", date: e })}
              />
            </Col>
            <Col
              md="3"
              style={{
                textAlign: "center",
                paddingTop: "30px"
              }}
            >
              <label>EndDate: </label>
              <DatePicker
                selected={state.endDate}
                onChange={(e) => dispatch({ type: "enddate", date: e })}
              />
            </Col>
            <Col
              md="3"
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "25px"
              }}
            >
              <button
                type="button"
                className="btn btn-outline-light"
                onClick={handleCustomerSearch}
              >
                Search
              </button>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <p
                style={{
                  width: "100%",
                  textAlign: "center",
                  color: "limegreen"
                }}
              >
                FYI folks, we only expire points on the first of the month, so
                if you are not seeing any results and are wondering why... Make
                sure you have the first day of the month in your date selection
                criteria.
              </p>
            </Col>
          </Row>
        </div>
        <div id="r-body">
          <table className="table table-striped">
            <thead>
              <tr>
                <Column
                  sortField={"sortPosted"}
                  filterable={false}
                  sortable={true}
                  state={tableState}
                  dispatch={tableDispatch}
                  label={"Date"}
                  field={"sortPostedAsc"}
                />
                <Column
                  sortField={"sortF1148"}
                  filterable={false}
                  sortable={true}
                  state={tableState}
                  dispatch={tableDispatch}
                  label={"Customer"}
                  field={"sortF1148Asc"}
                />
                <Column
                  sortField={"sortEarnedPoints"}
                  filterable={false}
                  sortable={true}
                  state={tableState}
                  dispatch={tableDispatch}
                  label={"Earned Points"}
                  field={"sortEarnedPointsAsc"}
                />
                <Column
                  sortField={"sortRedeemedPoints"}
                  filterable={false}
                  sortable={true}
                  state={tableState}
                  dispatch={tableDispatch}
                  label={"Redeemed Points"}
                  field={"sortRedeemedPointsAsc"}
                />
                <Column
                  sortField={"sortExpiredPoints"}
                  filterable={false}
                  sortable={true}
                  state={tableState}
                  dispatch={tableDispatch}
                  label={"Expired Points"}
                  field={"sortExpiredPointsAsc"}
                />
              </tr>
            </thead>
            <tbody>
              {state.filteredExpired.map((record, index) => (
                <tr key={`tr-e=${index}`}>
                  <td>{formatDate(record.posted)}</td>
                  <td>{record.f1148}</td>
                  <td>{record.earnedPoints}</td>
                  <td>{record.redeemedPoints}</td>
                  <td>{record.expiredPoints}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Ledger;
