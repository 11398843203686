const LEDGER_REDUCER_TYPES = {
  INIT: "init"
};

const ledgerReducer = (state, action) => {
  switch (action.type) {
    case LEDGER_REDUCER_TYPES.INIT:
      return {
        ...state,
        error: null,
        startDate: action.startDate,
        endDate: action.endDate,
        storeid: action.storeid
      };
    case "loaddata":
      return {
        ...state,
        isLoading: true
      };
    case "success":
      return {
        ...state,
        [action.store]: action.data.items,
        isLoading: false,
        error: null
      };
    case "customer":
      return {
        ...state,
        customer: action.customer
      };
    case "startdate":
      return {
        ...state,
        startDate: action.date
      };
    case "enddate":
      return {
        ...state,
        endDate: action.date
      };
    case "filter":
      return {
        ...state,
        filteredExpired: action.data,
        isLoading: false,
        error: null
      };
    case "sortdata":
      return {
        ...state,
        isLoading: true
      };
    case "sortfilter":
      return {
        ...state,
        filteredExpired: action.data,
        isLoading: false
      };
    case "error":
      return {
        ...state,
        error: action.error,
        isLoading: false,
        [action.store]: []
      };
    case "fail":
      console.log(action.error);
      return {
        ...state,
        error: action.msg,
        isLoading: false,
        [action.store]: []
      };
    case "unmount":
      return {
        ...state,
        mounted: false
      };
    default:
      return state;
  }
};

const tableReducer = (state, action) => {
  switch (action.type) {
    case "sortPosted":
      return {
        ...state,
        sortPostedAsc: !state.sortPostedAsc,
        sortDataType: "string",
        sortField: "posted"
      };
    case "sortF1148":
      return {
        ...state,
        sortF1148Asc: !state.sortF1148Asc,
        sortDataType: "string",
        sortField: "f1148"
      };
    case "sortEarnedPoints":
      return {
        ...state,
        sortEarnedPointsAsc: !state.sortEarnedPointsAsc,
        sortDataType: "number",
        sortField: "earnedPoints"
      };
    case "sortRedeemedPoints":
      return {
        ...state,
        sortRedeemedPointsAsc: !state.sortRedeemedPointsAsc,
        sortDataType: "number",
        sortField: "redeemedPoints"
      };
    case "sortExpiredPoints":
      return {
        ...state,
        sortExpiredPointsAsc: !state.sortExpiredPointsAsc,
        sortDataType: "humber",
        sortField: "expiredPoints"
      };
    default:
      return state;
  }
};

export { ledgerReducer, tableReducer, LEDGER_REDUCER_TYPES };
