import React, { useState, useEffect, useContext } from "react";
import { ContextConfig } from "../../../App";
import { usePrevious, formatMoney, formatDate, addDays } from "../../../utils";
import { isEqual } from "../../../fp";
import { getBannerStores } from "../../../api/DashboardApi";
import { toast } from "react-toastify";
import Spinner from "../../../common/Spinner";
import { Row, Col, Card, CardBody, CardTitle, CardFooter } from "reactstrap";
import { sortedIndex } from "lodash";
import ReactTooltip from "react-tooltip";
import { useSelector, useDispatch } from "react-redux";
import { getKpi } from "../../../api/widgetsApi";
import DatePicker from "react-datepicker";
import * as actions from "../../../actions/actionTypes";
import Kpi from "../../../widgets/Kpi";
import "./Banner.css";

const Banners = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [sales, setSales] = useState([]);
  const context = useContext(ContextConfig);

  const state = useSelector((app) => app.kpiReducer);
  const dispatch = useDispatch();

  const banner = context.drill.banner;

  const previousSales = usePrevious(sales);

  useEffect(() => {
    if (!isEqual(previousSales, sales)) {
      loadSales();
    }
    dispatch({
      type: actions.KPI_SET_START_DATE,
      date: addDays(new Date(), -6)
    });
  }, [sales]);

  useEffect(() => {
    if (state.storeData1.length > 0 && state.storeData2.length > 0) {
      dispatch({ type: actions.KPI_SHOW_MODAL, showModal: true });
    }
  }, [state.storeData1, state.storeData2]);

  const loadSales = () => {
    setIsLoading(true);
    getBannerStores(context.url, context.token, banner.groupNum)
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          setSales(j.sales);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        toast.error("An error occured processing banner stores", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const fetchApiData = () => {
    dispatch({ type: actions.KPI_SET_IS_LOADING, isLoading: true });
    getKpi(
      context.url,
      context.token,
      state.leftSide.storeid,
      state.startDate,
      state.endDate
    )
      .then((response) => {
        dispatch({ type: actions.KPI_SET_IS_LOADING, isLoading: false });
        const j = response.data;
        if (j.error === 0) {
          dispatch({ type: actions.KPI_SET_STORE_DATA_1, data: j.kpi });
          fetchSecondStore();
        } else {
          toast.warning(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: actions.KPI_SET_IS_LOADING, isLoading: false });
        toast.error("An Internal error occured", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const fetchSecondStore = () => {
    dispatch({ type: actions.KPI_SET_IS_LOADING, isLoading: true });
    getKpi(
      context.url,
      context.token,
      state.rightSide.storeid,
      state.startDate,
      state.endDate
    )
      .then((response) => {
        dispatch({ type: actions.KPI_SET_IS_LOADING, isLoading: false });
        const j = response.data;
        if (j.error === 0) {
          dispatch({ type: actions.KPI_SET_STORE_DATA_2, data: j.kpi });
        } else {
          toast.warning(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: actions.KPI_SET_IS_LOADING, isLoading: false });
        toast.error("An Internal error occured", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  return (
    <div>
      {isLoading && <Spinner />}
      {state.isLoading && <Spinner />}
      {state.showModal && <Kpi state={state} dispatch={dispatch} />}
      <div className="container">
        <div className="banner-title mb-3">
          <h1>{banner.bannerName} Comparisons</h1>
          <div className="row justify-content-center">
            <div className="col">
              <DatePicker
                selected={state.startDate}
                onChange={(e) =>
                  dispatch({ type: actions.KPI_SET_START_DATE, date: e })
                }
              />
            </div>
            <div className="col">
              <DatePicker
                selected={state.endDate}
                onChange={(e) =>
                  dispatch({ type: actions.KPI_SET_END_DATE, date: e })
                }
              />
            </div>
          </div>
          <p>
            You can use this widget to select a{" "}
            <span style={{ color: "rgba(204, 146, 39, 1)" }}>
              <i>`left for`</i>
            </span>{" "}
            and a{" "}
            <span style={{ color: "rgba(204, 146, 39, 1)" }}>
              <i>`right for`</i>
            </span>{" "}
            comparison between two stores.
          </p>
        </div>

        <Row className=" justify-content-center">
          <div className="comparetor-title">Comparator Details</div>
          <Row className="justify-content-center w-50 banner-comparator">
            <Col xs="6" className="pr-2">
              <Row className="justify-content-center">
                <Col xs="12" className="left-side-title">
                  Left Store
                </Col>
                <Col xs="12">
                  <div className="compare-store">
                    <div>Storeid: {state.leftSide.storeid}</div>
                    <div
                      className={`left-side-delete ${
                        Object.keys(state.leftSide).length > 0
                          ? "compare-delete-enabled"
                          : "compare-delete-disabled"
                      }`}
                      onClick={() =>
                        dispatch({ type: actions.KPI_DELETE_LEFT })
                      }
                    >
                      <i className="fal fa-minus-octagon"></i>
                    </div>
                  </div>
                </Col>
                <Col xs="12">Store Name: {state.leftSide.storeName}</Col>
              </Row>
            </Col>
            <Col xs="6">
              <Row className="justify-content-center">
                <Col xs="12" className="right-side-title">
                  Right Store
                </Col>
                <Col xs="12">
                  <div className="compare-store">
                    <div>Storeid: {state.rightSide.storeid}</div>
                    <div
                      className={`left-side-delete ${
                        Object.keys(state.rightSide).length > 0
                          ? "compare-delete-enabled"
                          : "compare-delete-disabled"
                      }`}
                      onClick={() =>
                        dispatch({ type: actions.KPI_DELETE_RIGHT })
                      }
                    >
                      <i className="fal fa-minus-octagon"></i>
                    </div>
                  </div>
                </Col>
                <Col xs="12">Store Name: {state.rightSide.storeName}</Col>
              </Row>
            </Col>
            <Row
              className={`justify-content-center mt-2 mb-2 ${
                Object.keys(state.leftSide).length > 0 &&
                Object.keys(state.rightSide).length > 0
                  ? "compare-enable"
                  : "compare-disable"
              }`}
            >
              <Col md="12">
                <button
                  className="btn btn-success"
                  onClick={() => fetchApiData()}
                >
                  Compare Stores
                </button>
              </Col>
            </Row>
          </Row>
        </Row>
        <Row>
          {sales.length > 0 ? (
            sales.map((store, index) => (
              <Col key={`store-${index}`} lg="3" md="6">
                <Card>
                  <CardBody>
                    <Row>
                      <Col xs="12">
                        <div className="banner-store-name">
                          <CardTitle tag="h3">{store.storeName}</CardTitle>
                          <hr className="card-divider" />
                        </div>
                      </Col>

                      <Col xs="5">
                        <div className="banner-store-version">
                          <CardTitle tag="h3">{store.version}</CardTitle>
                        </div>
                      </Col>
                      <Col xs="7">
                        <div className="banner-store-term">
                          <CardTitle tag="h3">
                            {store.termCount} terms
                          </CardTitle>
                        </div>
                      </Col>

                      <Col xs="5">
                        <div>SMS Date: {formatDate(store.versionDate)}</div>
                      </Col>
                      <Col xs="7">
                        <div className="">
                          <CardTitle tag="h3">
                            <span
                              className="text-success"
                              style={{ fontSize: "1.2rem" }}
                            >
                              ${formatMoney(store.f65)}
                            </span>
                          </CardTitle>
                        </div>
                      </Col>
                      <Col xs="5">
                        <div>{sortedIndex.keyNumber}</div>
                      </Col>
                    </Row>
                    <ReactTooltip />
                    <Row className="mt-5 banner-compare">
                      <Col
                        xs="6"
                        className={`banner-icon-left ${
                          Object.keys(state.leftSide).length > 0
                            ? "compare-disabled"
                            : "compare-enabled"
                        }`}
                        data-tip={`${
                          Object.keys(state.leftSide).length === 0
                            ? "Select for left side compare"
                            : "You have already selected a left side Store. Please remove it to select a different Store"
                        }`}
                        onClick={() =>
                          dispatch({
                            type: actions.KPI_SET_LEFT,
                            leftSide: store
                          })
                        }
                      >
                        <div className="banner-left-for">
                          <span className="enner-icon-holder">
                            <i className="far fa-compress-arrows-alt fa-2x text-center justify-content align-content-center text-light"></i>
                          </span>
                        </div>
                      </Col>
                      <Col
                        xs="6"
                        className={`banner-icon-left ${
                          Object.keys(state.rightSide).length > 0
                            ? "compare-disabled"
                            : "compare-enabled"
                        }`}
                        data-tip={`${
                          Object.keys(state.rightSide).length === 0
                            ? "Compare to left side Store"
                            : "You have already selected a right side Store. Please remove it to select a different Store"
                        }`}
                        onClick={() =>
                          dispatch({
                            type: actions.KPI_SET_RIGHT,
                            rightSide: store
                          })
                        }
                      >
                        <div className="banner-right-for">
                          <span className="benner-icon-holder">
                            <i className="fas fa-compress-alt fa-2x text-center justify-content align-content-center text-light"></i>
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <hr className="card-divider" />
                    <div className="banner-footer">
                      <i className="fal fa-shopping-cart"></i>
                      {` Qty: ${store.f64}`}
                    </div>
                  </CardFooter>
                </Card>
              </Col>
            ))
          ) : (
            <div>There are currently no stores to display</div>
          )}
        </Row>
      </div>
    </div>
  );
};

export default Banners;
