import React, { useState, useEffect, useContext } from "react";
import ClockSidebar from "../ClockSidebar";
//import colors from "../../common/colors";
import "../Clock.css";
import RoleControls from "./RoleControls";
import RoleList from "./RoleList";
import { usePrevious } from "../../utils";
import { isEqual, debounce } from "../../fp";
import { ContextConfig } from "../../App";
import { getUserRoles, createRole, copyUserRoles } from "../../api/rolesApi";
import { getClockUsers, getClockCompanyUsers } from "../../api/UsersApi";
import { toast } from "react-toastify";
import { readCompanies } from "../../api/companyApi";
import { BrowserView, MobileView, isBrowser } from "react-device-detect";
import ClockDesktopMenu from "../ClockDesktopMenu";
import RolesCopy from "./RolesCopy";
import FloatingSidebar from "../../Dashboard/FloatingSidebar";
import { getUserClockStores, setLastStoreid } from "../../api/StoreApi";
import "./Roles.css";

const Roles = () => {
  const [roles, setRoles] = useState([]);
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState("");
  const [currentCompany, setCurrentCompany] = useState("1");
  const [companies, setCompanies] = useState([]);
  const [search, setSearch] = useState("");
  const [copy, setCopy] = useState(false);
  const [copyUser, setCopyUser] = useState(false);
  const [warning, setWarning] = useState("");
  const [changeUserLevel, setChangeUserLevel] = useState(false);
  const [stores, setStores] = useState([]);

  const context = useContext(ContextConfig);

  const previousUsers = usePrevious(users);
  const previousUser = usePrevious(user);
  const previousCompanies = usePrevious(companies);
  const previousCompany = usePrevious(currentCompany);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  useEffect(() => {
    if (!isEqual(previousUsers, users)) {
      loadUsers();
    }
    if (!isEqual(previousUser, user)) {
      loadMenu();
    }
    if (!isEqual(previousCompanies, companies)) {
      loadCompanies();
    }
    if (!isEqual(previousCompany, currentCompany)) {
      loadUsers();
    }
    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);

    findDivHeight();

    getClockStores();

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, [user, companies, currentCompany]);

  const findDivHeight = () => {
    if (!isBrowser) return;
    const navbar = document
      .getElementById("master-navbar")
      .getBoundingClientRect().height;

    const usedSpace = navbar + 350;
    const remaining = window.innerHeight - usedSpace;

    const widget = document.getElementById("rolelist");
    widget.style.height = remaining + "px";
    widget.style.overflow = "auto";
  };

  const loadUsers = () => {
    console.log(context);
    // we need to swap out the function based on if the base company is dcr or not
    let userLookupFunction = getClockUsers;
    if (context.company === 1) {
      console.log("using getClockCompanyUsers as lookup function");
      userLookupFunction = getClockCompanyUsers;
    } else {
      console.log("using getClockUsers as lookup function");
    }
    userLookupFunction(
      context.clockUrl,
      context.clockToken,
      search,
      0,
      currentCompany,
      context.lastClockStore
    )
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setUsers(j.users);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An error has occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const loadMenu = () => {
    getUserRoles(context.clockUrl, context.clockToken, user, 1)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          if (j.roles && j.roles.length > 0) {
            setRoles(j.roles);
          }
        } else {
          setRoles([]);
          if (j.error === 1) {
            toast.error(j.msg, {
              position: toast.POSITION.TOP_LEFT
            });
          }
        }
      })
      .catch((err) => {
        if (user) {
          console.log(err);
          toast.error("An error has occured processing your request", {
            position: toast.POSITION.TOP_LEFT
          });
        }

        // by default, we need to clear out the roles
        setRoles([]);
      });
  };

  const loadCompanies = () => {
    readCompanies(context.clockUrl, context.clockToken).then((response) => {
      const j = response.data;
      if (j.error === 0) {
        setCompanies(j.companies);
      } else {
        toast.error(j.msg, {
          position: toast.POSITION.TOP_LEFT
        });
      }
    });
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleToggle = (roleid, toggleState) => {
    createRole(context.clockUrl, context.clockToken, user, roleid, toggleState)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          loadMenu();
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const handleUserChange = (e) => {
    setUser(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    loadMenu();
  };

  const handleCompanyChange = (e) => {
    setCurrentCompany(e.target.value);
    setUser("0");
  };

  const handleSearchClick = () => {
    loadUsers();
  };

  const handleCopyRoles = (e) => {
    const newUserLevel = getUserLevel(copyUser);
    e.preventDefault();
    copyUserRoles(
      context.clockUrl,
      context.clockToken,
      copyUser,
      user,
      changeUserLevel,
      newUserLevel.level
    )
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          toast.success("Your Roles have been copied over");
          setWarning("");
          loadMenu();
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const handleCheckChange = (e) => {
    setCopy(e.target.checked);
  };

  const handleCopyUserChanged = (e) => {
    // first let's check to see if these two users have the same level or not
    setCopyUser(e.target.value);
    const sourceUser = getUserLevel(e.target.value);
    const destUser = getUserLevel(user);
    if (sourceUser.level != destUser.level) {
      setWarning(
        "The Source User has a level of (" +
          sourceUser.level +
          ") " +
          sourceUser.levelDescription +
          " and the destination user has a level of (" +
          destUser.level +
          ") " +
          destUser.levelDescription +
          ". Do you also want to make the user levels match? If not, the destination user will not see all the available roles in their menu."
      );
      return;
    }
    setCopyUser(e.target.value);
  };

  const getUserLevel = (userid) => {
    const user = users.filter((u) => u.userid == userid);
    return {
      userId: user[0].userid,
      level: user[0].userLevel,
      levelDescription: user[0].levelDescription
    };
  };

  const handleCheckUserLevelChange = (e) => {
    setChangeUserLevel(e.target.checked);
  };

  const getClockStores = () => {
    getUserClockStores(context.clockUrl, context.clockToken)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setStores(j.stores);
        } else {
          setStores([]);
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setStores([]);
        console.log(err);
        toast.error("An Internal error occured");
      });
  };

  const handleStoreChange = (e) => {
    const storeid = e.target.value;
    console.log("setting lastClockStores to " + storeid);
    context.lastClockStore = parseInt(storeid);
    loadUsers();
    setLastStoreid(context.clockUrl, context.clockToken, storeid)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          console.log(storeid + " saved as last storeid");
        } else {
          console.log(j.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <React.Fragment>
      <MobileView>
        <div className="clock-mobile">
          <div className="mobile-clock-header">
            <ClockSidebar />
            <div className="container cc-main-body">
              <div
                style={{
                  width: "100%",
                  marginTop: "20px",
                  textAlign: "center",
                  fontSize: "1.3rem",
                  fontWeight: "bold"
                }}
              >
                Roles
              </div>
              <div className="role-header">
                <RoleControls
                  handleSubmit={handleSubmit}
                  handleUserChange={handleUserChange}
                  user={user}
                  users={users}
                  company={context.company}
                  handleCompanyChange={handleCompanyChange}
                  companies={companies}
                  currentCompany={currentCompany}
                  search={search}
                  handleSearchChange={handleSearchChange}
                  handleSearchClick={handleSearchClick}
                  stores={stores}
                  handleStoreChange={handleStoreChange}
                  context={context}
                />
              </div>
              <RolesCopy
                handleCopyRoles={handleCopyRoles}
                handleCheckChange={handleCheckChange}
                copy={copy}
                setCopyUser={handleCopyUserChanged}
                users={users}
                warning={warning}
                handleCheckUserLevelChange={handleCheckUserLevelChange}
              />
              <div className="role-list" id="role-list">
                <RoleList roles={roles} handleToggle={handleToggle} />
              </div>
            </div>
          </div>
        </div>
      </MobileView>
      <BrowserView>
        <div>
          <ClockDesktopMenu />
          <FloatingSidebar />
          <div className="container mt-4">
            <div
              style={{
                width: "100%",
                marginTop: "20px",
                textAlign: "center",
                fontSize: "1.3rem",
                fontWeight: "bold"
              }}
            >
              Roles
            </div>
            <div className="role-header">
              <RoleControls
                handleSubmit={handleSubmit}
                handleUserChange={handleUserChange}
                user={user}
                users={users}
                company={context.company}
                handleCompanyChange={handleCompanyChange}
                companies={companies}
                currentCompany={currentCompany}
                search={search}
                handleSearchChange={handleSearchChange}
                handleSearchClick={handleSearchClick}
                stores={stores}
                handleStoreChange={handleStoreChange}
                context={context}
              />
            </div>
            <RolesCopy
              handleCopyRoles={handleCopyRoles}
              handleCheckChange={handleCheckChange}
              copy={copy}
              setCopyUser={handleCopyUserChanged}
              users={users}
              warning={warning}
              handleCheckUserLevelChange={handleCheckUserLevelChange}
            />
            <div className="role-list" id="rolelist">
              <RoleList roles={roles} handleToggle={handleToggle} />
            </div>
          </div>
        </div>
      </BrowserView>
    </React.Fragment>
  );
};

export default Roles;
