import React, { useState, useContext, useEffect } from "react";
import ClockDesktopMenu from "../ClockDesktopMenu";
import FloatingSidebar from "../../Dashboard/FloatingSidebar";
import { useSelector, useDispatch } from "react-redux";
import { ContextConfig } from "../../App";
import Header from "../widgets/Header";
import {
  fetchDepartments,
  generalSettings,
  userPunches,
  userInfo
} from "./Punchcard-I";
import * as actions from "../../actions/actionTypes";
import { isValid, usePrevious } from "../../utils";
import { isEqual, debounce } from "../../fp";
import PunchcardCmp from "./PunchcardCmp";
import { enterManualPunch, deletePunch } from "../../api/punchApi";
import { toast } from "react-toastify";

import "./Punchcard.css";

const Punchcard = () => {
  const state = useSelector((app) => app.punchcardReducer);
  const appState = useSelector((app) => app.appReducer);
  const dispatch = useDispatch();
  const context = useContext(ContextConfig);
  const [reRender, setReRender] = useState(false);
  const [totalHours, setTotalHours] = useState(0);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const previousPunches = usePrevious(state.punches);

  useEffect(() => {
    console.log("useEffect for punchcard");
    dispatch({ type: actions.PC_SET_STOREID, storeid: context.lastClockStore });
    fetchDepartments(state, dispatch, context);
    generalSettings(state, dispatch, context);
    userInfo(state, dispatch, context);
  }, [context.lastClockStore]);

  useEffect(() => {
    if (isValid(state.storeid)) {
      if (!isEqual(previousPunches, state.punches)) {
        userPunches(state, dispatch, context);
      }
    }

    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);

    findDivHeight();

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, [state.storeid]);

  useEffect(() => {}, [reRender, state.punches]);

  useEffect(() => {
    userPunches(state, dispatch, context);
  }, [appState.startDate, appState.endDate]);

  useEffect(() => {
    const hours = state.punches.reduce((acc, cur) => {
      if (cur.total) {
        return parseFloat(acc) + parseFloat(cur.total);
      } else {
        return parseFloat(acc);
      }
    }, 0);
    setTotalHours(hours);
  }, [state.punches]);

  const findDivHeight = () => {
    const navbar = document
      .getElementById("master-navbar")
      .getBoundingClientRect().height;
    const controls = document.getElementById("controls").getBoundingClientRect()
      .height;

    const usedSpace = navbar + controls + 55;
    const remaining = window.innerHeight - usedSpace;

    const widget = document.getElementById("body");
    widget.style.height = remaining + "px";
    widget.style.overflowY = "auto";
  };

  const handlePunchSubmit = (id) => {
    const punch = state.punches.filter((p) => p.id === id)[0];
    if (punch.total <= 0) {
      toast.warn(
        "Your time cannot be negative. Please make an adjustment before you re-submit",
        {
          position: toast.POSITION.TOP_LEFT
        }
      );
      return;
    }
    // we need to actually convert the punch in and out to dates to make sure that the out is not
    // less than the in
    const pIn = new Date(punch.punchIn);
    const pOut = new Date(punch.punchOut);
    if (pOut < pIn) {
      toast.warn(
        "Your time cannot be negative. Please make an adjustment before you re-submit",
        {
          position: toast.POSITION.TOP_LEFT
        }
      );
      return;
    }
    punch.storeid = state.storeid;
    console.log(punch);
    enterManualPunch(context.clockUrl, context.clockToken, punch)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          userPunches(state, dispatch, context);
          dispatch({ type: actions.PC_RESET_PUNCH });
        } else {
          toast.error(j.msg);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const addPunch = () => {
    dispatch({ type: actions.PC_ADD_PUNCH });
  };

  const handleDelete = (id) => {
    deletePunch(context.clockUrl, context.clockToken, id)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          userPunches(state, dispatch, context);
        } else {
          toast.error(j.msg);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const cancelEdit = (id) => {
    dispatch({ type: actions.PC_CANCEL_EDIT, id });
  };

  return (
    <div>
      <ClockDesktopMenu />
      <FloatingSidebar />
      <div className="container">
        <div id="controls" style={{ marginBottom: "20px" }}>
          <div className="punchcard-title">Punchcard</div>
          <div className="row">
            <div className="col-sm-12 col-lg-4">
              <div className="punchcard-user-info">
                <div className="punchcard-user">
                  User: {state.user.firstName} {state.user.lastName}
                </div>
                <div className="punchcard-employeeid">
                  Employee Id: {state.user.employeeId}
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-lg-4">
              <div
                className="col"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <div>
                  Total:{" "}
                  <span
                    style={{
                      fontSize: "1.2rem",
                      fontWeight: "bold",
                      color: "white"
                    }}
                  >
                    {totalHours.toFixed(context.settings.timePrecision)}
                  </span>
                </div>
                <div>
                  Total Punches:{" "}
                  <span
                    style={{
                      fontSize: "1.2rem",
                      fontWeight: "bold",
                      color: "white"
                    }}
                  >
                    {state.punches.length}
                  </span>
                </div>
              </div>
            </div>
            <div className="d-flex col-sm-12 col-lg-4 justify-content-center align-items-center">
              <button className="btn btn-outline-primary" onClick={addPunch}>
                <i className="fal fa-plus-circle"></i>
              </button>
            </div>
          </div>

          <Header
            showAddButton={true}
            handleAddClick={addPunch}
            reRender={reRender}
            setReRender={setReRender}
          />
        </div>
        <div id="body" style={{ marginBottom: "20px" }}>
          {state.punches.map((punch, i) => (
            <PunchcardCmp
              key={`pcp-${i}`}
              punch={punch}
              handlePunchSubmit={handlePunchSubmit}
              handleDelete={handleDelete}
              cancelEdit={cancelEdit}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Punchcard;
