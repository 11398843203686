import React from "react";
import "./Approaching40Spinner.css";

const Approaching40Spinner = () => {
  return (
    <div className="lmask40">
      <div className="fixed40"></div>
    </div>
  );
};

export default Approaching40Spinner;
