import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { ContextConfig } from "./App";
import PropTypes from "prop-types";

const PrivateClockRoute = ({ component: Component, ...rest }) => {
  const context = useContext(ContextConfig);
  return (
    <Route
      {...rest}
      render={(props) =>
        context.authenticated === true ? (
          <Component {...props} />
        ) : (
          <Redirect to="/clock" />
        )
      }
    />
  );
};

PrivateClockRoute.propTypes = {
  component: PropTypes.any
};

export default PrivateClockRoute;
