import React from "react";
import PropTypes from "prop-types";
import { Col, Card, Row, CardHeader, CardTitle, CardBody } from "reactstrap";
import { Line } from "react-chartjs-2";
import { chartDefaults } from "../HomePage/options/Charts";

const SalesChart = (props) => {
  let salesOptions = {
    data1: (canvas) => {
      let ctx = canvas.getContext("2d");

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
      gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
      gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); // blue colors

      return {
        labels: props.salesLabels,
        datasets: [
          {
            label: "Sales",
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: "#1f8ef1",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#1f8ef1",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#1f8ef1",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: props.sales
          }
        ]
      };
    },
    options: chartDefaults
  };
  return (
    <Col xs="12">
      <Card className="sales-chart">
        <CardHeader>
          <Row>
            <Col className="sales-char-text-left" sm="6">
              <h5 className="card-category">Store Sales</h5>
              <CardTitle tag="h2">{props.storename}</CardTitle>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <div className="chart-area" style={{ maxHeight: "200px" }}>
            <Line data={salesOptions["data1"]} options={salesOptions.options} />
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

SalesChart.propTypes = {
  salesLabels: PropTypes.array,
  sales: PropTypes.array,
  storename: PropTypes.string
};

export default SalesChart;
