import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Popup from "reactjs-popup";
import Spinner from "../../common/Spinner";
import ToggleSwitch from "../../Groups/ToggleSwitch";
import { getStoreUserLinks } from "../../api/StoreApi";
import { manageLinks } from "../../api/UsersApi";
import PropTypes from "prop-types";

const contentStyle = {
  width: "500px",
  padding: "10px",
  maxHeight: "400px",
  overflowY: "auto",
  overflowX: "hidden"
};
const overlayStyle = { background: "rgba(0,0,0,0.8)" };
const arrowStyle = { color: "#000" }; // style for an svg element

const UserStores = ({ context, user }) => {
  const [stores, setStores] = useState([]);
  const [filteredStores, setFilteredStores] = useState([]);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (user.userid) {
      loadStores();
    }
  }, [user]);

  Array.prototype.containsStoreName = function(searchValue) {
    const newArray = [];
    this.forEach((item) => {
      if (
        item.storeName.toUpperCase().includes(searchValue.toUpperCase()) ||
        item.storeNumber.toString().includes(searchValue)
      ) {
        newArray.push(item);
      }
    });
    return newArray;
  };

  useEffect(() => {
    if (search.length > 0) {
      const newArray = stores.containsStoreName(search);
      setFilteredStores(newArray);
    } else {
      setFilteredStores(stores);
    }
  }, [search]);

  const loadStores = () => {
    setIsLoading(true);
    getStoreUserLinks(context.clockUrl, context.clockToken, user.userid)
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          setStores(j.stores);
          setFilteredStores(j.stores);
        } else {
          setStores([]);
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setStores([]);
        console.log(err);
        toast.error("An Internal error occured", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const handleToggle = (storeid, toggleState) => {
    manageLinks(
      context.clockUrl,
      context.clockToken,
      user.userid,
      storeid,
      toggleState ? 1 : 0,
      user.employeeId
    )
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          loadStores();
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  return (
    <div className="row">
      {isLoading && <Spinner />}
      <div
        className="col justify-content-center"
        style={{ textAlign: "center" }}
      >
        <Popup
          trigger={<button className="btn btn-primary">Select Stores</button>}
          position="top center"
          {...{ contentStyle, overlayStyle, arrowStyle }}
        >
          <div
            style={{ width: "500px", padding: "30px", paddingRight: "50px" }}
          >
            <h4>Select Stores to assign</h4>
            <input
              type="text"
              className="form-control"
              placeholder="Search Stores"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            {filteredStores.map((store, i) => (
              <div
                key={`ssid-${i}`}
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  marginTop: "10px"
                }}
              >
                <div>{store.storeNumber}</div>
                <div>{store.storeName}</div>
                <div>
                  <ToggleSwitch
                    id={store.id}
                    handleClick={handleToggle}
                    active={store.assigned === 1 ? true : false}
                  />
                </div>
              </div>
            ))}
          </div>
        </Popup>
      </div>
    </div>
  );
};

UserStores.propTypes = {
  context: PropTypes.object,
  user: PropTypes.object
};

export default UserStores;
