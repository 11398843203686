import axios from "axios";
import qs from "qs";

async function saveSettings(url, token, settings, storeid) {
  let json = await axios({
    method: "POST",
    cors: true,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: "Bearer " + token
    },
    url: url + "schedule/generalsettings",
    data: qs.stringify({
      storeid,
      roundingMethod: settings.roundingMethod,
      minutesForLunch: settings.minutesForLunch,
      hoursForBreak: settings.hoursForBreak,
      clockInGracePeriodBefore: settings.clockInGracePeriodBefore,
      clockInGracePeriodAfter: settings.clockInGracePeriodAfter,
      timePrecision: settings.timePrecision,
      freeBreakMinutes: settings.freeBreakMinutes,
      forceLunch: settings.forceLunch ? 1 : 0,
      managerTimeout: settings.managerTimeout,
      punchInTimeout: settings.punchInTimeout,
      defaultScheduleWeek: settings.defaultScheduleWeek,
      disclaimer: settings.disclaimer,
      forceBreakTime: settings.forceBreakTime ? 1 : 0,
      mandatoryBreakMinutes: settings.mandatoryBreakMinutes,
      deductFullBreak: settings.deductFullBreak ? 1 : 0,
      approachingFortyThreshold: settings.approachingFortyThreshold,
      dayRollOver: settings.dayRollOver,
      enableClockPassword: settings.enableClockPassword ? 1 : 0,
      enforceSchedule: settings.enforceSchedule ? 1 : 0,
      enableBreak: settings.enableBreak ? 1 : 0,
      enableLunch: settings.enableLunch ? 1 : 0,
      deductFullLunch: settings.deductFullLunch ? 1 : 0,
      deductLunchOverMax: settings.deductLunchOverMax ? 1 : 0,
      showRawPunches: settings.showRawPunches ? 1 : 0,
      showRawPunchesOnEditScreen: settings.showRawPunchesOnEditScreen ? 1 : 0,
      dissalowEditingOfYourOwnTime: settings.dissalowEditingOfYourOwnTime
        ? 1
        : 0,
      minumumHoursForMandatoryLunch: settings.minumumHoursForMandatoryLunch,
      minumumHoursForMandatoryBreak: settings.minumumHoursForMandatoryBreak,
      selectedScheme: settings.selectedScheme,
      uniqueEmployeeIds: settings.uniqueEmployeeIds ? 1 : 0,
      breakGracePeriodBefore: settings.breakGracePeriodBefore,
      breakGracePeriodAfter: settings.breakGracePeriodAfter,
      lunchGracePeriodBefore: settings.lunchGracePeriodBefore,
      lunchGracePeriodAfter: settings.lunchGracePeriodAfter,
      punchOutGracePeriodBefore: settings.punchOutGracePeriodBefore,
      punchOutGracePeriodAfter: settings.punchOutGracePeriodAfter,
      useMilitaryTime: settings.useMilitaryTime ? 1 : 0,
      autoDeductMinutesFromShift: settings.autoDeductMinutesFromShift
    })
  });
  return json;
}

async function getGeneralSettings(url, token, storeid) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "schedule/generalsettings",
    params: {
      storeid
    }
  });
  return json;
}

async function copySettings(url, token, source, dest) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "schedule/copysettings",
    params: {
      source,
      dest
    }
  });
  return json;
}

export { saveSettings, getGeneralSettings, copySettings };
