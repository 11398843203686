import React from "react";
import PropTypes from "prop-types";
import { formatDate } from "../utils";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter
} from "reactstrap";
import ReactTooltip from "react-tooltip";
import { isMobile } from "react-device-detect";

const SalesPanel = (props) => {
  //const [viewMargins, setViewMargins] = useState(false);

  const handleMarginClick = (record) => {
    props.context.marginDate = record.f254;
    props.context.marginStore = record.store_Name;
    props.context.marginStoreid = record.storeid;
    //setViewMargins(true);
    props.redirect("/sales/subs");
  };

  const handleCatMarginClick = (record) => {
    props.context.marginDate = record.f254;
    props.context.marginStore = record.store_Name;
    props.context.marginStoreid = record.storeid;
    props.redirect("/sales/cat");
  };

  return (
    <React.Fragment>
      {props.stores.length > 0 ? (
        props.stores.map((record, index) => (
          <Col
            lg="3"
            md="6"
            key={`sales-panel-${index}`}
            data-tip={`Sales for ${record.store_Name}`}
            style={{ cursor: "pointer" }}
            onClick={() => props.onClick(record.storeid)}
          >
            <Card className="sales-panel" key={`sp-${index}`}>
              <CardHeader tag="h2">{record.store_Name}</CardHeader>
              <CardBody>
                <Row>
                  <Col xs="12">
                    <p
                      style={{
                        textAlign: "center"
                      }}
                    >
                      Date
                    </p>
                    <CardTitle
                      tag="h3"
                      style={{
                        textAlign: "center"
                      }}
                    >
                      {formatDate(record.f254)}
                    </CardTitle>
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col xs="6">
                    <div className="sales-panel-lastyear">
                      <p>Last Year</p>
                      <CardTitle tag="h4">
                        <span
                          style={{
                            color:
                              parseFloat(record.f65) >
                              parseFloat(record.lastYearsSales)
                                ? "#e54949"
                                : "limegreen"
                          }}
                        >
                          ${parseFloat(record.lastYearsSales).toFixed(2)}
                        </span>
                      </CardTitle>
                    </div>
                  </Col>
                  <Col xs="6">
                    <div
                      className="sales-panel-thisyear"
                      style={{ textAlign: "right" }}
                    >
                      <p>
                        This Year&nbsp;
                        {parseFloat(record.f65) >
                        parseFloat(record.lastYearsSales) ? (
                          <i
                            style={{
                              color: "limegreen"
                            }}
                            className="far fa-arrow-circle-up"
                          ></i>
                        ) : (
                          <i
                            style={{
                              color: "#e54949"
                            }}
                            className="far fa-arrow-circle-down"
                          ></i>
                        )}
                      </p>
                      <CardTitle tag="h4">
                        <span
                          style={{
                            color:
                              parseFloat(record.f65) >
                              parseFloat(record.lastYearsSales)
                                ? "limegreen"
                                : "#e54949"
                          }}
                        >
                          ${parseFloat(record.f65).toFixed(2)}
                        </span>
                      </CardTitle>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <p
                      style={{
                        textAlign: "center"
                      }}
                    >
                      Gross Margin
                    </p>
                    <CardTitle tag="h3">
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          textAlign: "center",
                          color: "#e37a24"
                        }}
                      >
                        {parseFloat(record.grossMargin).toFixed(2)}%
                      </span>
                    </CardTitle>
                  </Col>
                </Row>
                {!isMobile ? (
                  <Row className="justify-content-center">
                    <Col xs="4" className="submargins">
                      <button
                        className="submarginButton"
                        onClick={() => handleMarginClick(record)}
                        data-tip="Click here to drill down into Subdepartment Margins"
                      >
                        Sub
                      </button>
                    </Col>
                    <Col xs="4" className="submargins">
                      <button
                        className="catmarginButton"
                        onClick={() => handleCatMarginClick(record)}
                        data-tip="Click here to drill down into Category Margins"
                      >
                        Cat
                      </button>
                    </Col>
                  </Row>
                ) : null}
              </CardBody>
              {!isMobile ? (
                <CardFooter>
                  <hr />
                  <div className="sales-panel-qty">
                    <span data-tip="This years Quantity">
                      TY QTY; {record.lastYearsQty}
                    </span>
                    <span
                      style={{
                        textAlign: "right",
                        marginRight: "10px"
                      }}
                      data-tip="Last Years Quantity"
                    >
                      LY QTY: {record.f64}
                    </span>
                  </div>
                </CardFooter>
              ) : null}
            </Card>
          </Col>
        ))
      ) : (
        <div
          className="container"
          style={{ fontSize: "1.3rem", fontWeight: "bold" }}
        >
          There are no stores to display
        </div>
      )}
      <ReactTooltip />
    </React.Fragment>
  );
};

SalesPanel.propTypes = {
  stores: PropTypes.array,
  onClick: PropTypes.func,
  context: PropTypes.object,
  redirect: PropTypes.func
};

export default SalesPanel;
