import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import { formatDate, formatTimeAmPm } from "../utils";

const ItemErrorWidget = ({ freshopState }) => {
  return (
    <div>
      <h5 className="error-item-title">Error Items</h5>
      <Row className="justify-content-center align-items-center">
        {freshopState.itemErrors.map((record, i) => (
          <Col lg="6" md="12" key={`fei-${i}`}>
            <Card className="freshop-price-mod-widget freshop-fade-in">
              <CardHeader>
                <div className="error-item-header">{record.description}</div>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs="6">
                    <div className="error-item-orderid">
                      OrderID: {record.orderid}
                    </div>
                  </Col>
                  <Col xs="6">
                    <div className="error-item-upc">UPC: {record.upc}</div>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">{record.errorMessage}</Col>
                </Row>
                <Row>
                  <Col xs="6">
                    <div className="error-item-qty">Qty: {record.quantity}</div>
                  </Col>
                  <Col xs="6">
                    <div className="error-item-price">
                      Price: {record.price}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs="6">
                    <div className="error-item-date">
                      Date: {formatDate(record.errorDate)}
                    </div>
                  </Col>
                  <Col xs="6">
                    <div className="error-item-time">
                      {formatTimeAmPm(record.errorTime)}
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

ItemErrorWidget.propTypes = {
  freshopState: PropTypes.object
};

export default ItemErrorWidget;
