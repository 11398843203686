import React, { useState, useEffect, useRef, useContext } from "react";
import PropTypes from "prop-types";
import { Picker, ModalDatePicker } from "c5-react-library";
import { formatDate, usePrevious } from "../utils";
import { getDistrictSales } from "../api/BannerApi";
import { ContextConfig } from "../App";
import { toast } from "react-toastify";
import { isEqual } from "lodash";
import { Redirect } from "react-router-dom";

const MobileBanner = ({ reRender, setReRender, groups, setIsLoading }) => {
  const [time, setTime] = useState(new Date());
  const [showDate, setShowDate] = useState(false);
  const [showGroups, setShowGroups] = useState(false);
  const [groupId, setGroupId] = useState("");
  const [groupName, setGroupName] = useState("");
  const [bannerSales, setBannerSales] = useState([]);
  const [stopLoading, setStopLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const context = useContext(ContextConfig);
  const previousBannerSales = usePrevious(bannerSales);

  useEffect(() => {
    console.log("useEffect for MobileBannerSales");
    console.log("groupid: " + groupId);
    if (!groupId || groupId.length === 0) {
      setTimeout(() => {
        setShowGroups(true);
        return;
      }, 250);
    } else {
      if (
        (!isEqual(previousBannerSales, bannerSales) ||
          bannerSales.length === 0) &&
        !stopLoading
      ) {
        readBannerSales();
      }
    }
  }, [groupId, groupName, bannerSales, reRender, redirect]);

  const handleToggleGroups = () => {
    setShowGroups(!showGroups);
    setReRender(!reRender);
  };

  const handleShowDate = () => {
    setShowDate(true);
  };

  const handleDateSelect = (e) => {
    console.log("setting time to " + e);
    setTime(e);
    context.endDate = e;
    setReRender(!reRender);
    setShowDate(false);
  };

  const handleDateCancel = () => {
    setShowDate(false);
  };

  const handleGroupSelect = (e) => {
    console.log(
      "setting groupId: " + e.group_Id + ", group_Name: " + e.group_Name
    );
    setGroupId(e.group_Id);
    setGroupName(e.group_Name);
    setReRender(!reRender);
  };

  const readBannerSales = () => {
    setIsLoading(true);
    getDistrictSales(context.url, context.token, groupId, context.endDate)
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          setBannerSales(j.items);
          setStopLoading(false);
        } else if (j.error === 1) {
          setStopLoading(true);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const handleClick = (record) => {
    context.bannerGroup = record.group_Id;
    setRedirect(true);
  };

  return (
    <React.Fragment>
      {redirect && <Redirect to="/bsales" />}
      <h3>Banner Sales</h3>
      <div className="row p-2">
        <div className="col header-selection-btn">
          <button
            type="button"
            className="btn btn-outline-dark"
            onClick={handleToggleGroups}
          >
            <i className="far fa-users"></i>
          </button>
        </div>
        <div>
          <div className="hourly-m-header">
            <div className="hourly-m-header-date">
              {time && formatDate(time)}
            </div>
            <div className="hourly-m-header-store">{groupName}</div>
          </div>
        </div>
        <div className="col ml-auto header-date-btn">
          <button
            className="btn btn-outline-dark"
            type="button"
            onClick={handleShowDate}
          >
            <i className="far fa-calendar-alt"></i>
          </button>
        </div>
      </div>
      <div className="banner-content">
        {bannerSales.length === 0 ? (
          <div>No records were returned</div>
        ) : (
          <table style={{ width: "90%", margin: "auto" }}>
            <thead>
              <tr>
                <th style={{ width: "50%" }}>Group</th>
                <th style={{ width: "50%" }}>Sales</th>
              </tr>
            </thead>
            <tbody>
              {bannerSales.map((record, index) => (
                <tr key={index} onClick={() => handleClick(record)}>
                  <td>{record.group_Name}</td>
                  <td>{record.f65}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <ModalDatePicker
        value={time}
        isOpen={showDate}
        onSelect={handleDateSelect}
        onCancel={handleDateCancel}
      />
      <Picker
        isShowing={showGroups}
        hide={handleToggleGroups}
        header="groups"
        store={groups}
        displayField="group_Name"
        valueField="group_Id"
        handleSelect={handleGroupSelect}
        useEffect={useEffect}
        useState={useState}
        useRef={useRef}
        debug={false}
        debugListeners={false}
      />
    </React.Fragment>
  );
};

MobileBanner.propTypes = {
  reRender: PropTypes.bool,
  setReRender: PropTypes.func,
  groups: PropTypes.array,
  setIsLoading: PropTypes.func
};

export default MobileBanner;
