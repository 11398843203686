import * as actions from "../actions/actionTypes";

const initialState = {
  isLoadingUsers: false,
  isLoadingCompanies: false,
  isLoadingStores: false,
  stores: [],
  users: [],
  user: "",
  companies: [],
  currentCompany: 0,
  search: "",
  employeeId: ""
};

const linkReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LK_IS_LOADING_USERS:
      return {
        ...state,
        isLoadingUsers: action.isLoading
      };
    case actions.LK_IS_LOADING_COMPANIES:
      return {
        ...state,
        isLoadingCompanies: action.isLoading
      };
    case actions.LK_IS_LOADING_STORES:
      return {
        ...state,
        isLoadingStores: action.isLoading
      };
    case actions.LK_SET_USERS:
      return {
        ...state,
        users: action.users
      };
    case actions.LK_SET_USER:
      return {
        ...state,
        user: action.user
      };
    case actions.LK_SET_COMPANIES:
      return {
        ...state,
        companies: action.companies
      };
    case actions.LK_SET_COMPANY:
      return {
        ...state,
        currentCompany: action.company
      };
    case actions.LK_SET_STORES:
      return {
        ...state,
        stores: action.stores
      };
    case actions.LK_SET_SEARCH:
      return {
        ...state,
        search: action.search
      };
    case actions.LK_SET_EMPLOYEEID:
      return {
        ...state,
        employeeId: action.employeeId
      };
    default:
      return state;
  }
};

export default linkReducer;
