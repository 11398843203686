import React from "react";
import PropTypes from "prop-types";
import { formatDate } from "../../../utils";

const StorePunchList = ({
  punchList,
  handleCashierClick,
  selectedCashierRow
}) => {
  return (
    <table
      className="table table-striped"
      style={{ width: "90%", margin: "auto" }}
    >
      <thead className="thead-primary">
        <tr className="tl-l-header">
          <th>Date</th>
          <th>Storename</th>
          <th>Cashier</th>
          <th>Name</th>
          <th>Term</th>
          <th>Total Hours</th>
          <th>Row</th>
        </tr>
      </thead>
      <tbody>
        {punchList.map((record) => (
          <tr
            key={`tl-l-${record.f1185}-${record.f254}-${record.f1057}`}
            className={
              parseInt(record.rownumber) === parseInt(selectedCashierRow)
                ? "tl-l-row-selected"
                : "tl-l-row"
            }
            onClick={() => handleCashierClick(record)}
          >
            <td>{formatDate(record.f254)}</td>
            <td>{record.store_Name}</td>
            <td>{record.f1185}</td>
            <td>{`${record.first_Name} ${record.last_Name}`}</td>
            <td>{record.f1057}</td>
            <td>{record.totalHours.toFixed(1)}</td>
            <td>{record.rownumber}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

StorePunchList.propTypes = {
  punchList: PropTypes.array.isRequired,
  handleCashierClick: PropTypes.func.isRequired,
  selectedCashierRow: PropTypes.any
};

export default StorePunchList;
