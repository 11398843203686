import React, { useState, useEffect, useContext, useRef } from "react";
import HeaderRB from "../common/HeaderRb";
import { ContextConfig } from "../App";
import { readReports } from "../api/ReportsApi";
import { toast } from "react-toastify";
import { isEqual } from "lodash";
import { usePrevious, isValid } from "../utils";
import "./Reports.css";
import { Redirect } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";
import MobileReports from "./MobileReports";
import MobileReportController from "./MobileReportController";
import DesktopReports from "./DesktopReports";
import Spinner from "../common/Spinner";

const Reports = () => {
  const [reports, setReports] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const context = useContext(ContextConfig);
  // these are for the mobile reports
  const [storemulti, setStoreMulti] = useState(false);
  const previousReports = usePrevious(reports);
  const [rerender, setRerender] = useState(false);
  const [ace, setAce] = useState(false);
  const [deptSingle, setDeptSingle] = useState(false);
  const [groupSingle, setGroupSingle] = useState(false);
  const [operatorMulti, setOperatorMulti] = useState(false);
  const [overrides, setOverrides] = useState(false);
  const [overtime, setOvertime] = useState(false);
  const [division, setDivision] = useState(false);
  const [balancing, setBalancing] = useState(false);
  const [subs, setSubs] = useState(false);
  // these are for the desktop reports
  const [multi, setMulti] = useState(false);
  const [dace, setDace] = useState(false);
  const [single, setSingle] = useState(false);
  const [gsingle, setgSingle] = useState(false);
  const [operator, setOperator] = useState(false);
  const [moverrides, setmOverrids] = useState(false);
  const [dovertime, setdOvertime] = useState(false);
  const [ddivision, setdDivision] = useState(false);
  const [dbalancing, setdBalancing] = useState(false);
  const [dsubs, setdSubs] = useState(false);
  const [dredemption, setdRedemption] = useState(false);
  const [vendor, setVendor] = useState(false);
  const [dvendor, setdvendor] = useState(false);
  const [payserverTransactions, setPayserverTransactions] = useState(false);
  const [recievers, setReceivers] = useState(false);
  const mainLockedBody = useRef();

  useEffect(() => {
    //disableBodyScroll(mainLockedBody.current);

    if (!isEqual(previousReports, reports)) {
      loadReports();
    }
    if (!isValid(context.endDate)) {
      context.endDate = new Date();
    }
  }, []);

  const loadReports = () => {
    setIsLoading(true);
    readReports(context.url, context.token)
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          setReports(j.items);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("error", err);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const handleClick = (e) => {
    context.report = e;
    console.log(e.reportName);
    switch (e.reportName) {
      case "storeMulti":
        setStoreMulti(true);
        break;
      case "AceRewardsReport":
        setAce(true);
        break;
      case "deptSingle":
        setDeptSingle(true);
        break;
      case "GroupSingle":
        setGroupSingle(true);
        break;
      case "OperatorMulti":
        setOperatorMulti(true);
        break;
      case "manager_override_punches":
        setOverrides(true);
        break;
      case "read_Overtime":
        setOvertime(true);
        break;
      case "sbSheet":
        setBalancing(true);
        break;
      case "SubDepartments":
        setSubs(true);
        break;
      case "SalDivision":
        setDivision(true);
        break;
      case "VendorReport":
        setVendor(true);
        break;
      case "transactions":
        setPayserverTransactions(true);
        break;
      case "receivers":
        setReceivers(true);
        break;
      default:
        console.log("defaulting to store multi");
        break;
    }
  };

  const handleDesktopClick = (e) => {
    switch (e.reportName) {
      case "storeMulti":
        setMulti(true);
        break;
      case "AceRewardsReport":
        setDace(true);
        break;
      case "deptSingle":
        setSingle(true);
        break;
      case "GroupSingle":
        setgSingle(true);
        break;
      case "OperatorMulti":
        setOperator(true);
        break;
      case "manager_override_punches":
        setmOverrids(true);
        break;
      case "read_Overtime":
        setdOvertime(true);
        break;
      case "SalDivision":
        setdDivision(true);
        break;
      case "sbSheet":
        setdBalancing(true);
        break;
      case "SubDepartments":
        setdSubs(true);
        break;
      case "redeemed":
        setdRedemption(true);
        break;
      case "VendorReport":
        setdvendor(true);
        break;
      case "transactions":
        setPayserverTransactions(true);
        break;
      case "receivers":
        setReceivers(true);
        break;
      default:
        console.log("defaulting to store multi");
        break;
    }
  };

  return (
    <div className="reports-wrapper" ref={mainLockedBody}>
      {isLoading && <Spinner />}
      {storemulti && <Redirect to="/storemulti" />}
      {ace && <Redirect to="/ace" />}
      {deptSingle && <Redirect to="/deptSingle" />}
      {groupSingle && <Redirect to="/groupSingle" />}
      {operatorMulti && <Redirect to="/operatormulti" />}
      {overrides && <Redirect to="/overrides" />}
      {overtime && <Redirect to="/overtime" />}
      {division && <Redirect to="/division" />}
      {balancing && <Redirect to="/balancing" />}
      {subs && <Redirect to="/subs" />}
      {multi && <Redirect to="/multi" />}
      {dace && <Redirect to="/dace" />}
      {single && <Redirect to="/single" />}
      {gsingle && <Redirect to="/gsingle" />}
      {operator && <Redirect to="/operator" />}
      {moverrides && <Redirect to="/moverrides" />}
      {dovertime && <Redirect to="/dovertime" />}
      {ddivision && <Redirect to="/ddivision" />}
      {dbalancing && <Redirect to="/dbalancing" />}
      {dsubs && <Redirect to="/dsubs" />}
      {dredemption && <Redirect to="/dredemption" />}
      {vendor && <Redirect to="/vendorreport" />}
      {dvendor && <Redirect to="/dvendorreport" />}
      {payserverTransactions && <Redirect to="/ptransactions" />}
      {recievers && <Redirect to="/recievers" />}

      <HeaderRB />

      <div className="container-fluid">
        <BrowserView>
          <DesktopReports reports={reports} handleClick={handleDesktopClick} />
        </BrowserView>

        <MobileView>
          <MobileReportController
            rerender={rerender}
            setRerender={setRerender}
          />

          <MobileReports
            reports={reports}
            handleClick={handleClick}
            rerender={rerender}
            setRerender={setRerender}
          />
        </MobileView>
      </div>
    </div>
  );
};

Reports.propTypes = {};

export default Reports;
