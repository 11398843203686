import React, { useState, useEffect, useContext } from "react";
import LoadingOverlay from "react-loading-overlay";
import HeaderRb from "../../common/HeaderRb";
import { toast } from "react-toastify";
import { ContextConfig } from "../../App";
import { isEqual } from "lodash";
import {
  usePrevious,
  isValidDate,
  addDays,
  formatDate,
  print,
  handleCsv
} from "../../utils";
import { readDesktopStoreMulti } from "../../api/ReportsApi";
import { debounce } from "../../fp";

const dStoreMulti = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const context = useContext(ContextConfig);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const previousData = usePrevious(data);

  useEffect(() => {
    if (!isEqual(previousData, data)) {
      loadData();
    }

    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);

    findDivHeight();

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, [data]);

  const findDivHeight = () => {
    const navbar = document
      .getElementById("master-navbar")
      .getBoundingClientRect().height;
    const controls = document
      .getElementById("sm-controls")
      .getBoundingClientRect().height;
    const usedSpace = navbar + controls + 30;
    const remaining = window.innerHeight - usedSpace;

    const body = document.getElementById("sm-body");
    body.style.height = remaining + "px";
    body.style.overflow = "auto";
  };

  const loadData = () => {
    if (!isValidDate(context.startDate)) {
      context.startDate = addDays(new Date(), -7);
    }
    readDesktopStoreMulti(
      context.url,
      context.token,
      context.startDate,
      context.endDate,
      context.lastStoreid
    )
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          setData(j.items);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const handlePrint = () => {
    print(document.getElementById("storemulti").innerHTML);
  };

  return (
    <LoadingOverlay active={isLoading} spinner text="Loading Data...">
      <HeaderRb></HeaderRb>
      <div className="container-fluid" id="storemulti">
        <div id="sm-controls">
          <div className="rpt-name">Store Multi</div>
          <div className="export-report">
            <span className="print-report" onClick={handlePrint}>
              <i className="far fa-print"></i>
            </span>
            <span
              className="csv-report"
              onClick={() => handleCsv(data, "StoreMulti.csv")}
            >
              <i className="far fa-save"></i>
            </span>
          </div>

          <div className="d-report-info">
            <div className="d-report-storename">
              <div className="d-report=date">{context.reportStore.name}</div>
              <span>Start: {formatDate(context.startDate)}</span>
              <span>&nbsp;</span>
              <span>End: {formatDate(context.endDate)}</span>
            </div>
          </div>
        </div>
        <div id="sm-body">
          <React.Fragment>
            {data.length === 0 ? (
              <div className="d-empty-report">
                There were no records returned
              </div>
            ) : (
              <table style={{ width: "90%", margin: "auto" }}>
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>&nbsp;</th>
                    <th>Section</th>
                    <th>Qty</th>
                    <th>$</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((record, index) => (
                    <tr key={index}>
                      <td>{record.description}</td>
                      <td>{record.f1179}</td>
                      <td>{record.section}</td>
                      <td>{record.qty}</td>
                      <td style={{ textAlign: "right" }}>{record.sales}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </React.Fragment>
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default dStoreMulti;
