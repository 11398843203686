import axios from "axios";
import qs from "qs";

async function getUserRoles(url, token, userid, type) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "roles",
    params: {
      userid,
      type
    }
  });
  return json;
}

async function createRole(url, token, roleuser, menuid, assigned) {
  let json = await axios({
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: "Bearer " + token
    },
    url: url + "roles",
    data: qs.stringify({
      roleuser,
      assigned: assigned ? 1 : 0,
      menuid
    })
  });
  return json;
}

async function copyUserRoles(
  url,
  token,
  source,
  dest,
  changeUserLevel,
  newUserLevel
) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "roles/copyroles",
    params: {
      source,
      dest,
      changeUserLevel,
      newUserLevel
    }
  });
  return json;
}

export { getUserRoles, createRole, copyUserRoles };
