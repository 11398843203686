import React from "react";
import PropTypes from "prop-types";
import { formatMoney } from "../../utils";
import { Card, Row, Col, CardTitle, CardBody, CardFooter } from "reactstrap";
// eslint-disable-next-line
import IcomoonReact, { iconList } from "icomoon-react";
import iconSet from "../../assets/selection.json";
import ReactTooltip from "react-tooltip";
import { colorVars } from "../options/Charts";

const Panels = (props) => {
  return (
    <React.Fragment>
      <ReactTooltip />
      {props.bannerData.length > 0
        ? props.bannerData.map((record, index) => (
            <Col
              lg="3"
              md="6"
              key={`banners-${index}`}
              style={{ cursor: "pointer" }}
              onClick={() => props.handleClick(record)}
              data-tip={`Click me to drill into ${record.group} stores`}
            >
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col xs="5">
                      <div
                        className={`info-icon text-center banner-circle icon-${
                          colorVars[
                            Math.floor(Math.random() * colorVars.length)
                          ]
                        }`}
                      >
                        <IcomoonReact
                          className="banner-logos"
                          iconSet={iconSet}
                          color="white"
                          size="60"
                          icon={record.icon}
                        />
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">{record.bannerName}</p>
                        <CardTitle tag="h3">
                          {formatMoney(record.f65)}
                        </CardTitle>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fal fa-shopping-cart"></i>
                    {`Qty: ${record.f64}`}
                  </div>
                </CardFooter>
              </Card>
            </Col>
          ))
        : null}
    </React.Fragment>
  );
};

Panels.propTypes = {
  bannerData: PropTypes.array,
  handleClick: PropTypes.func
};

export default Panels;
