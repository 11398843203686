import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import Sidebar from "react-sidebar";
import { withRouter } from "react-router-dom";
import { readMenu } from "./Clock-I";
import { ContextConfig } from "../App";
import { usePrevious } from "../utils";
import { isEqual } from "lodash";
import { isMobile } from "react-device-detect";
import { toast } from "react-toastify";

const ClockSidebar = (props) => {
  const [sideOpen, setSideOpen] = useState(false);
  const [menu, setMenu] = useState([]);

  const context = useContext(ContextConfig);

  const previousMenu = usePrevious(menu);

  useEffect(() => {
    if (!isEqual(previousMenu, menu)) {
      loadMenuItems();
    }
  }, [props.menu]);

  const loadMenuItems = () => {
    readMenu(context.clockUrl, context.clockToken, isMobile).then(
      (response) => {
        const j = response.data;
        if (j.error === 0) {
          filterMenu(j.menu);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      }
    );
  };

  const filterMenu = (data) => {
    const newArray = data.filter(
      (m) =>
        m.description !== "Edits" &&
        m.description !== "Clock" &&
        m.description !== "Reports" &&
        m.description !== "Calendar" &&
        m.description !== "Eod"
      // m.description !== "Punchcard"
    );
    setMenu(newArray);
  };

  const renderSideBar = () => {
    const filteredMenu = menu.filter(
      (f) => f.description != "Eod" || f.description != "Calendar"
    );
    return (
      <div>
        <div className="clock-mobile-sidebar">
          <div className="cms-title">TimeClock</div>
          {menu.length === 0 ? (
            <div>No records returned</div>
          ) : (
            filteredMenu.map((record) => (
              <div
                key={record.id}
                className="cms-item"
                onClick={() => handleSidebarClick(record.description)}
              >
                <span className="cms-item-icon">
                  <i className={record.iconCls}></i>
                </span>
                <span className="cms-item-text">{record.description}</span>
                {record.active === 1 ? (
                  <div className="cms-item-active">&nbsp;</div>
                ) : null}
              </div>
            ))
          )}
        </div>
      </div>
    );
  };

  const onSetSidebarOpen = (open) => {
    setSideOpen(open);
  };

  const handleSidebarClick = (description) => {
    setMenu(
      menu.map((m) => {
        if (m.description != "Calendar") {
          return {
            id: m.id,
            description: m.description,
            iconCls: m.iconCls,
            active: m.description === description ? 1 : 0
          };
        }
      })
    );
    switch (description) {
      case "Home":
        props.history.push("/clockhome");
        break;
      case "Clock In/Out":
        props.history.push("/clockinout");
        break;
      case "Company":
        props.history.push("/company");
        break;
      case "Users":
        props.history.push("/clockusers");
        break;
      case "Stores":
        props.history.push("/stores");
        break;
      case "Settings":
        props.history.push("/generalsettings");
        break;
      case "Roles":
        props.history.push("/roles");
        break;
      case "Links":
        props.history.push("/links");
        break;
      case "Timecard":
        props.history.push("/timecard");
        break;
      case "Status":
        props.history.push("/status");
        break;
      case "Clock In/Out Mobile":
        props.history.push("/clockinout");
        break;
      case "Departments":
        props.history.push("/departments");
        break;
      case "Request":
        props.history.push("/request");
        break;
      case "Reset":
        props.history.push("/resetclockpassword");
        break;
      case "Logout":
        props.history.push("/clock");
        break;
      default:
        break;
    }
  };
  return (
    <div>
      <Sidebar
        sidebar={<React.Fragment>{renderSideBar()}</React.Fragment>}
        open={sideOpen}
        onSetOpen={onSetSidebarOpen}
        styles={{ sidebar: { background: "black", color: "white" } }}
      >
        <span
          className="mobile-clock-header-button"
          onClick={() => onSetSidebarOpen(!sideOpen)}
        >
          <i className="far fa-bars"></i>
        </span>
        <span className="clock-header-text">TimeClock</span>
      </Sidebar>
    </div>
  );
};

ClockSidebar.propTypes = {
  history: PropTypes.object,
  menu: PropTypes.array
};

export default withRouter(ClockSidebar);
