const editReducer = (state, action) => {
  switch (action.type) {
    case "sortUsername":
      return {
        ...state,
        sortUsernameAsc: !state.sortUsernameAsc,
        sortField: "username",
        sortDataType: "string"
      };
    case "sortEmployeeid":
      return {
        ...state,
        sortEmployeeIdAsc: !state.sortEmployeeIdAsc,
        sortField: "employeeNumber",
        storDataType: "string"
      };
    case "sortType":
      return {
        ...state,
        sortTypeAsc: !state.sortTypeAsc,
        sortField: "punchType",
        sortDataType: "string"
      };
    case "sortIn":
      return {
        ...state,
        sortInAsc: !state.sortInAsc,
        sortField: action.sortField,
        sortDataType: "string"
      };
    case "sortOut":
      return {
        ...state,
        sortOutAsc: !state.sortOutAsc,
        sortField: action.sortField,
        sortDataType: "string"
      };
    case "sortTotal":
      return {
        ...state,
        sortTotalAsc: !state.sortTotalAsc,
        sortField: "total",
        sortDataType: "number"
      };
    case "sortDept":
      return {
        ...state,
        sortDeptAsc: !state.sortDeptAsc,
        sortField: "departmentDescription",
        sortDataType: "string"
      };
    default:
      return state;
  }
};

export default editReducer;
