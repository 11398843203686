import React from "react";
import Th from "./components/Th";
import Moment from "moment";
import PropTypes from "prop-types";

const Header = (props) => {
  const { weekStart, days } = props;
  return (
    <div className="dow-header" id="scheduleHead">
      <div className="schedule-employee">Employee</div>
      {Object.keys(weekStart).length > 0 ? (
        days.map((day, index) => {
          const monthDate = Moment(weekStart).add(index, "days");
          return (
            <Th key={`dow-h-${index}`} className="dow-h-c">
              <div className="dow-h-d">{monthDate.format("MM/DD/YYYY")}</div>
              <div className="dow-h-d-t">
                {monthDate
                  .format("dddd")
                  .toString()
                  .substring(0, 3)
                  .toUpperCase()}
              </div>
            </Th>
          );
        })
      ) : (
        <div className="empty-schedule">
          There is currently no Schedule to display
        </div>
      )}
      <div className="sudo-cell schedule-header-text">Total</div>
    </div>
  );
};

Header.propTypes = {
  weekStart: PropTypes.any,
  days: PropTypes.array
};

export default Header;
