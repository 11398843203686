import React from "react";
import PropTypes from "prop-types";
import { formatTimeAmPm } from "../../utils";
import Tooltip from "../../Tooltip/Tooltip";

const Shift = ({
  user,
  handleDragStart,
  day,
  shift,
  deletePunchSetup,
  editShift,
  context
}) => {
  return (
    <div
      id={`${user.userid}-${day}-${shift.id}`}
      draggable
      className="employee-shift fade-shift-in"
      onDragStart={(e) => {
        handleDragStart(e, {
          userid: user.userid,
          shiftid: shift.id,
          day
        });
      }}
    >
      {/* <div className="shift-info">
        {user.firstName} {user.lastName}
      </div> */}
      <div className="shift-info">
        {shift ? formatTimeAmPm(shift.startTime) : ""}-
      </div>
      <div className="shift-info">
        {shift ? formatTimeAmPm(shift.endTime) : ""}
      </div>
      <div className="shift-info">
        {shift
          ? shift.departmentDescription.length > 23
            ? shift.departmentDescription.substr(0, 23)
            : shift.departmentDescription
          : ""}
      </div>
      <div className="shift-buttons">
        <span
          className="shift-button-delete"
          onClick={() => deletePunchSetup(shift.id)}
        >
          <Tooltip message={"Delete Shift"} position={"top"}>
            <i className="fas fa-minus-circle"></i>
          </Tooltip>
        </span>

        <span
          className="shift-id"
          style={{ textAlign: "center", color: "red" }}
        >
          {shift.type == "PTO" ? "PTO" : null}

          <span style={{ display: "none" }}>{shift.ptoId}</span>
          <span style={{ marginLeft: "5px" }}>
            {(shift.totalMinutes * 0.016667).toFixed(
              context.settings.timePrecision
            )}
          </span>
        </span>
        <span className="shift-button-edit" onClick={() => editShift(shift)}>
          <Tooltip message={"Edit Shift"} position={"top"}>
            <i className="far fa-user-edit"></i>
          </Tooltip>
        </span>
      </div>
    </div>
  );
};

Shift.propTypes = {
  user: PropTypes.object,
  handleDragStart: PropTypes.func,
  day: PropTypes.any,
  shift: PropTypes.object,
  deletePunchSetup: PropTypes.func,
  editShift: PropTypes.func,
  context: PropTypes.object
};

export default Shift;
