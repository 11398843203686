import React, { useState, useEffect, useContext, useCallback } from "react";
import PropTypes from "prop-types";
import { BrowserView, MobileView, isBrowser } from "react-device-detect";
import { ContextConfig } from "../../App";
import { isEqual, debounce, debounceFn } from "../../fp";
import { usePrevious, encrypt, isValid } from "../../utils";
import { readCompanies } from "../../api/companyApi";
import {
  getUserLevels,
  createUser,
  updateClockUser,
  checkUsernameClock,
  checkEmployeeidClock
} from "../../api/UsersApi";
import { storeGroups } from "../../api/StoreApi";
import Spinner from "../../common/Spinner";
import MobileUsers from "./mobile/MobileUsers";
import { toast } from "react-toastify";
import { loadDepartments } from "../../api/departmentApi";

const initialState = {
  firstName: "",
  lastName: "",
  userLevel: 0,
  company_Id: 0,
  username: "",
  password: "",
  email: "",
  employeeId: "",
  mandatoryBreak: 0,
  mandatoryLunch: 0,
  payrate: 0,
  clockPassword: 0,
  showPasswordEdit: 0,
  defaultDepartment: 0,
  ptoDaysPerYear: 0,
  storeGroup: 0,
  clockNumber: ""
};

const CreateUser = ({ activeUser, editMode }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState(initialState);
  const [levels, setLevels] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [errors, setErrors] = useState({});
  const [departments, setDepartments] = useState([]);
  const [usernameTaken, setUsernameTaken] = useState(false);
  const [employeeIdTaken, setEmployeeIdTaken] = useState(false);
  const [groups, setGroups] = useState([]);
  const [newNumber, setNewNumber] = useState(0);

  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const context = useContext(ContextConfig);

  const previousLevels = usePrevious(levels);
  const previousCompanies = usePrevious(companies);

  useEffect(() => {
    getDepartments();
    if (!isEqual(previousLevels, levels)) {
      loadLevels();
    }
    if (!isEqual(previousCompanies, companies)) {
      loadCompanies();
    }
    if (isValid(activeUser)) {
      setUser({
        ...activeUser,
        showPasswordEdit: 0
      });
    }

    getStoreGroups();

    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);

    findDivHeight();

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, []);

  const findDivHeight = () => {
    if (!isBrowser) return;
    const navbar = document
      .getElementById("master-navbar")
      .getBoundingClientRect().height;
    const usedSpace = navbar + 190; // for some reason the user controls div shows a height of zero???
    const remaining = window.innerHeight - usedSpace;

    const createWidget = document.getElementById("users-create");
    createWidget.style.height = remaining + "px";
    createWidget.style.overflow = "auto";
  };

  const debouncedIsUserTaken = useCallback(
    debounceFn(isUsernameTaken, 500),
    []
  );

  useEffect(() => {
    if (user.username.length > 0 && !editMode) {
      debouncedIsUserTaken(user.username);
    }
  }, [user.username]);

  useEffect(() => {}, [newNumber]);

  function isUsernameTaken(username) {
    checkUsernameClock(context.clockUrl, context.clockToken, username)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setUsernameTaken(false);
        } else {
          setUsernameTaken(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setUsernameTaken(true);
      });
  }

  const debouncedIsEmployeeIdTaken = useCallback(
    debounceFn(isEmployeeIdTaken, 500),
    []
  );

  useEffect(() => {
    if ((user.employeeId.length > 0) & !editMode) {
      debouncedIsEmployeeIdTaken(user.employeeId);
    }
  }, [user.employeeId]);

  function isEmployeeIdTaken(employeeId) {
    checkEmployeeidClock(context.clockUrl, context.clockToken, employeeId)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setEmployeeIdTaken(false);
        } else {
          setEmployeeIdTaken(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setEmployeeIdTaken(true);
      });
  }

  const loadLevels = () => {
    setIsLoading(true);
    getUserLevels(context.clockUrl, context.clockToken)
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          setLevels(j.levels);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const loadCompanies = () => {
    readCompanies(context.clockUrl, context.clockToken)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setCompanies(j.companies);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const formIsValid = () => {
    const _errors = {};
    if (!user.firstName) _errors.firstName = "First name is required";
    if (!user.lastName) _errors.lastName = "Last name is required";
    if (!user.userLevel) _errors.userLevel = "Level is required";
    if (!user.company) _errors.company = "Company is required";
    if (user.company <= 0) _errors.company = "Company is required";
    if (!user.username) _errors.username = "Username is required";
    if (usernameTaken) _errors.usernamtTaken = "Username already Taken";
    if (employeeIdTaken) _errors.employeeIdTaken = "Employee Id already taken";
    if (!user.storeGroup) _errors.storeGroup = "This field is required";
    if (user.storeGroup == "0") _errors.storeGroup = "This field is required";

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  };

  const handleChange = ({ target }) => {
    if (target.name == "mandatoryBreak") {
      setUser({
        ...user,
        mandatoryBreak: target.checked ? 1 : 0
      });
    } else if (target.name == "mandatoryLunch") {
      setUser({
        ...user,
        mandatoryLunch: target.checked ? 1 : 0
      });
    } else if (target.name == "showPasswordEdit") {
      setUser({
        ...user,
        showPasswordEdit: target.checked ? 1 : 0
      });
    } else {
      setUser({
        ...user,
        [target.name]: target.value
      });
    }
  };

  const handleEditUser = (e) => {
    setIsLoading(true);
    updateClockUser(context.clockUrl, context.clockToken, user, e)
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          setNewNumber(0);
          toast.success("Your user has been updated", {
            position: toast.POSITION.TOP_LEFT
          });
        } else {
          if (j.error == "99") {
            setNewNumber(j.newNumber);
          }
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((error) => {
        setNewNumber(0);
        setIsLoading(false);
        console.log(error);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const handleSubmitEditUser = (e) => {
    if (typeof e == "string") {
      handleSubmitNewUser(e);
    } else {
      // this means that we got a form instead of a password and we dont want a redirect to happen here
      e.preventDefault();
      handleSubmitNewUser(null);
    }
  };

  const handleSubmitNewUser = (e) => {
    if (!formIsValid()) return;

    let encryptedPassword = null;
    if (isValid(e)) {
      encryptedPassword = encrypt(e, context.key).toString();
      setUser({
        ...user,
        password: encryptedPassword
      });
    }

    if (editMode) {
      handleEditUser(encryptedPassword);
      return;
    }

    setIsLoading(true);
    createUser(context.clockUrl, context.clockToken, user, encryptedPassword)
      .then((response) => {
        setIsLoading(false);
        const j = response.data;

        if (j.error === 0) {
          setUser(initialState);
          setNewNumber(0);
          toast.success("Your user has been added", {
            position: toast.POSITION.TOP_LEFT
          });
        } else {
          if (j.error == "99") {
            setNewNumber(j.newNumber);
          }
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((error) => {
        setNewNumber(0);
        setIsLoading(false);
        console.log(error);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const getDepartments = () => {
    setIsLoading(true);
    loadDepartments(
      context.clockUrl,
      context.clockToken,
      context.lastClockStore
    )
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          setDepartments(j.departments);
        } else {
          setIsLoading(false);
          setDepartments([]);
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        setDepartments([]);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const getStoreGroups = () => {
    storeGroups(context.clockUrl, context.clockToken)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setGroups(j.groups);
        } else {
          setGroups(j.groups([]));
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setGroups([]);
        toast.error("An internal error occred", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };
  return (
    <div>
      {!isLoading ? null : <Spinner />}
      <div className="create-user">
        <MobileView>
          <MobileUsers
            user={user}
            levels={levels}
            errors={errors}
            companies={companies}
            onChange={handleChange}
            onSubmit={handleSubmitNewUser}
            submitEditedUser={handleSubmitEditUser}
            editMode={editMode}
            departments={departments}
            usernameTaken={usernameTaken}
            employeeIdTaken={employeeIdTaken}
            context={context}
            group={groups}
            newNumber={newNumber}
          />
        </MobileView>
        <BrowserView>
          <MobileUsers
            user={user}
            levels={levels}
            errors={errors}
            companies={companies}
            onChange={handleChange}
            onSubmit={handleSubmitNewUser}
            submitEditedUser={handleSubmitEditUser}
            editMode={editMode}
            departments={departments}
            usernameTaken={usernameTaken}
            employeeIdTaken={employeeIdTaken}
            context={context}
            groups={groups}
            newNumber={newNumber}
          />
        </BrowserView>
      </div>
    </div>
  );
};

CreateUser.propTypes = {
  activeUser: PropTypes.object,
  editMode: PropTypes.bool
};

export default CreateUser;
