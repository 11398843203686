import axios from "axios";

async function getReportData(
  url,
  token,
  route,
  startDate,
  endDate,
  employeeid,
  storeid
) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "reports/" + route,
    params: {
      storeid,
      startDate,
      endDate,
      employeeid
    }
  });
  return json;
}

export { getReportData };
