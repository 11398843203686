import axios from "axios";

async function chromeCart(config) {
  const { url, token, group, search, startDate, endDate } = config;
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "cashiers/chromecart",
    params: {
      group,
      search,
      startDate,
      endDate
    }
  });

  return json;
}

async function flyout(config) {
  const { url, token, startDate, endDate, storeid } = config;
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "cashiers/flyout",
    params: {
      startDate,
      endDate,
      storeid
    }
  });

  return json;
}

async function flyoutDetails(url, token, startDate, endDate, storeid, f1185) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "cashiers/flyoutdetails",
    params: {
      startDate,
      endDate,
      storeid,
      f1185
    }
  });
  return json;
}

async function getTransactions(
  url,
  token,
  storeid,
  startDate,
  endDate,
  cashier,
  totalizer
) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "cashiers/transactions",
    params: {
      storeid,
      startDate,
      endDate,
      cashier,
      totalizer
    }
  });
  return json;
}

async function getTransaction(url, token, translist) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "cashiers/translist",
    params: {
      translist
    }
  });
  return json;
}

async function emailTransaction(
  url,
  token,
  storeid,
  transnumber,
  transdate,
  cashier,
  termId
) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "mobile/emailtrans",
    params: {
      storeid,
      transnumber,
      transdate,
      cashier,
      termId
    }
  });
  return json;
}

export {
  chromeCart,
  flyout,
  flyoutDetails,
  getTransactions,
  getTransaction,
  emailTransaction
};
