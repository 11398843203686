import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import {
  usePrevious,
  addDays,
  isValid,
  convertAmount,
  print,
  handleCsv
} from "../utils";
import { getDistrictSalesDesktop } from "../api/BannerApi";
import { ContextConfig } from "../App";
import { toast } from "react-toastify";
import { isEqual } from "lodash";
import { Redirect } from "react-router-dom";
import DatePicker from "react-datepicker";
import "./BannerSalesDesktop.css";

const BannerSalesDesktop = ({ setIsLoading, groups }) => {
  const [data, setData] = useState([]);
  const [groupid, setGroupid] = useState("");
  const [startDate, setStartDate] = useState(addDays(new Date(), -7));
  const [endDate, setEndDate] = useState(new Date());
  const [redirect, setRedirect] = useState(false);

  const context = useContext(ContextConfig);

  const previousData = usePrevious(data);

  useEffect(() => {
    if (data.length == 0) {
      readBannerSales();
    } else {
      if (!isEqual(previousData, data)) {
        readBannerSales();
      }
    }
  }, [data, startDate, endDate, groupid]);

  const readBannerSales = () => {
    if (!isValid(context.startDate)) {
      context.startDate = addDays(new Date(), -7);
    }
    if (!isValid(context.endDate)) {
      context.endDate = new Date();
    }
    setIsLoading(true);
    getDistrictSalesDesktop(
      context.url,
      context.token,
      groupid,
      context.startDate,
      context.endDate
    )
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          setData(j.items);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const handleSelectionChange = (e) => {
    context.bannerGroup = e.target.value;
    const temp = groups.filter((g) => g.group_Id == parseInt(e.target.value));
    if (isValid(temp)) {
      context.bannerGroupName = temp[0].group_Name;
    }
    setGroupid(e.target.value);
  };

  const handleStartDateChange = (e) => {
    context.startDate = e;
    setStartDate(context.startDate);
  };

  const handleEndDateChange = (e) => {
    context.endDate = e;
    setEndDate(context.endDate);
  };

  const handleClick = (record) => {
    context.bannerGroup = record.group_Id;
    setRedirect(true);
  };

  const handlePrint = () => {
    print(document.getElementById("banner").innerHTML);
  };

  return (
    <div className="container">
      {redirect && <Redirect to="/bdsales" />}
      <h3>Banner Sales</h3>
      <div className="export-report">
        <span className="print-report" onClick={handlePrint}>
          <i className="far fa-print"></i>
        </span>
        <span
          className="csv-report"
          onClick={() => handleCsv(data, "Banner.csv")}
        >
          <i className="far fa-save"></i>
        </span>
      </div>
      <form className="form-inline" style={{ margin: "auto" }}>
        <div className="form-group row w-100">
          <div className="col-xs-12 col-sm-6 col-lg-3 m-auto">
            Selection Type:{" "}
            <select
              className="form-control"
              id="combodSelectionType"
              value={groupid}
              onChange={handleSelectionChange}
            >
              {groups.map((record, index) => (
                <option key={index} value={record.group_Id}>
                  {record.group_Name}
                </option>
              ))}
            </select>
          </div>

          <div className="col-xs-12 col-sm-6 col-lg-3 mt-1">
            Start:&nbsp;
            <DatePicker selected={startDate} onChange={handleStartDateChange} />
          </div>
          <div className="col-xs-12 col-sm-6 col-lg-3 mt-1">
            End:&nbsp;
            <DatePicker selected={endDate} onChange={handleEndDateChange} />
          </div>
        </div>
      </form>
      <div className="d-report-spacer">&nbsp;</div>
      {data.length === 0 ? (
        <div>No records were returned</div>
      ) : (
        <table style={{ width: "90%", margin: "auto" }}>
          <thead>
            <tr>
              <th>Group</th>
              <th>Sales</th>
            </tr>
          </thead>
          <tbody>
            {data.map((record, index) => (
              <tr
                className="bsales"
                key={index}
                onClick={() => handleClick(record)}
              >
                <td>{record.group_Name}</td>
                <td>{convertAmount(record.f65)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

BannerSalesDesktop.propTypes = {
  setIsLoading: PropTypes.func,
  groups: PropTypes.array
};

export default BannerSalesDesktop;
