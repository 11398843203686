import axios from "axios";
import qs from "qs";

async function GetUsersByCompany(url, token, company) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "users/bycompany",
    params: {
      company
    }
  });
  return json;
}

async function createUser(url, token, user, password) {
  let json = await axios({
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: "Bearer " + token
    },
    url: url + "users",
    data: qs.stringify({
      firstName: user.firstName,
      lastName: user.lastName,
      userLevel: user.userLevel,
      company: user.company_Id,
      username: user.username,
      passwordHash: password,
      email: user.email,
      employeeId: user.employeeId,
      clockPassword: user.clockPassword,
      mandatoryBreak: user.mandatoryBreak,
      mandatoryLunch: user.mandatoryLunch,
      defaultDepartment: user.defaultDepartment,
      ptoDaysPerYear: user.ptoDaysPerYear,
      timeOffRequest: user.timeOffRequest,
      storeGroup: user.storeGroup,
      clockNumber: user.clockNumber
    })
  });
  return json;
}

async function getUserLevels(url, token) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "users/levels"
  });
  return json;
}

async function getClockUsers(url, token, search, bystore, companyid, storeid) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "users/list",
    params: {
      search,
      bystore,
      companyid,
      storeid
    }
  });
  return json;
}

async function deleteUser(url, token, userid) {
  let json = await axios({
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "users",
    params: {
      userid
    }
  });
  return json;
}

async function updateUser(url, token, user, password) {
  let json = await axios({
    method: "PUT",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: "Bearer " + token
    },
    url: url + "users",
    params: {
      userid: user.userid,
      firstName: user.firstName,
      lastName: user.lastName,
      userLevel: user.userLevel,
      company: user.company_Id,
      username: user.username,
      passwordHash: password,
      email: user.email,
      employeeId: user.employeeid,
      clockPassword: user.clockPassword,
      mandatoryBreak: user.mandatoryBreak,
      mandatoryLunch: user.mandatoryLunch,
      defaultDepartment: user.defaultDepartment,
      ptoDaysPerYear: user.ptoDaysPerYear,
      storeGroup: user.storeGroup,
      clockNumber: user.clockNumber
    }
  });
  return json;
}

async function getUserStatus(url, token, userid, password, storeid) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "users/status",
    params: {
      userid,
      password,
      storeid
    }
  });
  return json;
}

async function getUserMobileStatus(url, token) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "users/mobilestatus"
  });
  return json;
}

async function getClockCompanyUsers(
  url,
  token,
  search,
  bystore,
  companyid,
  storeid
) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "users/companyusers",
    params: {
      search,
      bystore,
      companyid,
      storeid
    }
  });
  return json;
}

async function updateClockUser(url, token, user, password) {
  let json = await axios({
    method: "PUT",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: "Bearer " + token
    },
    url: url + "users",
    data: qs.stringify({
      userid: user.userid,
      firstName: user.firstName,
      lastName: user.lastName,
      userLevel: user.userLevel,
      company: user.company,
      username: user.username,
      passwordHash: password,
      email: user.email,
      employeeId: user.employeeId,
      clockPassword: user.clockPassword,
      mandatoryBreak: user.mandatoryBreak,
      mandatoryLunch: user.mandatoryLunch,
      defaultDepartment: user.defaultDepartment,
      ptoDaysPerYear: user.ptoDaysPerYear,
      timeOffRequest: user.timeOffRequest,
      storeGroup: user.storeGroup,
      clockNumber: user.clockNumber
    })
  });
  return json;
}

async function manageLinks(url, token, userid, storeid, assigned, employeeid) {
  let json = await axios({
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: "Bearer " + token
    },
    url: url + "users/managelinks",
    data: qs.stringify({
      userid,
      storeid,
      assigned,
      employeeid
    })
  });
  return json;
}

async function linkEmployeeId(url, token, userid, storeid, employeeid) {
  let json = await axios({
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: "Bearer " + token
    },
    url: url + "users/linkid",
    data: qs.stringify({
      userid,
      storeid,
      employeeid
    })
  });
  return json;
}

async function checkManagerLogin(url, token, username, password, storeid) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "users/mangerclockin",
    params: {
      username,
      password,
      storeid
    }
  });
  return json;
}

async function readUsersFromStore(url, token, storeid) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "users/usersbystore",
    params: {
      storeid
    }
  });
  return json;
}

async function statusCheck(url, token, userid) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "users/statuscheck",
    params: {
      userid
    }
  });
  return json;
}

async function changeStatus(url, token, userid, status) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "users/changestatus",
    params: {
      userid,
      status
    }
  });
  return json;
}

async function getStorename(url, token) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "stores/getstore"
  });
  return json;
}

async function getUsersInfo(url, token) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "users/userinfo"
  });
  return json;
}

async function checkUsernameClock(url, token, username) {
  let json = await axios({
    method: "GET",
    url: url + "users/checkusernameclock",
    cors: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    params: {
      username
    }
  });

  return json;
}

async function checkEmployeeidClock(url, token, employeeid) {
  let json = await axios({
    method: "GET",
    url: url + "users/checkemployeeidclock",
    cors: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    params: {
      employeeid
    }
  });

  return json;
}

async function copyAssignedStores(url, token, srcUser, destUser) {
  let json = await axios({
    method: "GET",
    url: url + "users/copyassignedfromUser",
    cors: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    params: {
      sourceUserid: srcUser,
      destUserid: destUser
    }
  });

  return json;
}

async function resetPassword(url, token, userid, newPassword) {
  let json = await axios({
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: "Bearer " + token
    },
    url: url + "forgot/reset",
    data: qs.stringify({
      userid,
      newPassword
    })
  });
  return json;
}

async function getUserid(url, token, userid, password, storeid) {
  let json = await axios({
    method: "GET",
    cors: true,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: "Bearer " + token
    },
    url: url + "users/getuserid",
    params: {
      userid,
      password,
      storeid
    }
  });
  return json;
}

async function checkEmail(url, email) {
  let json = await axios({
    method: "GET",
    cors: true,
    headers: {
      "Content-Type": "application/json"
    },
    url: url + "users/checkemail",
    params: {
      email
    }
  });
  return json;
}

async function checkAnswer(url, userid, answer) {
  let json = await axios({
    method: "GET",
    cors: true,
    headers: {
      "Content-Type": "application/json"
    },
    url: url + "users/checkanswer",
    params: {
      userid,
      answer
    }
  });
  return json;
}

async function setForgotPassword(url, userid, password) {
  let json = await axios({
    method: "POST",
    cors: true,
    headers: {
      "Content-Type": "applicatio/json"
    },
    url: url + "users/setpass",
    params: {
      userid,
      password
    }
  });
  return json;
}

export {
  GetUsersByCompany,
  getUserLevels,
  createUser,
  getClockUsers,
  deleteUser,
  updateUser,
  getUserStatus,
  getClockCompanyUsers,
  updateClockUser,
  manageLinks,
  getUserMobileStatus,
  checkManagerLogin,
  readUsersFromStore,
  statusCheck,
  changeStatus,
  getStorename,
  getUsersInfo,
  linkEmployeeId,
  checkUsernameClock,
  checkEmployeeidClock,
  copyAssignedStores,
  resetPassword,
  getUserid,
  checkEmail,
  checkAnswer,
  setForgotPassword
};
