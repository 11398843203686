import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { getClockUsers, deleteUser, changeStatus } from "../../api/UsersApi";
// import { readUsersFromStore } from "../../api/UsersApi";
import { setLastStoreid } from "../../api/StoreApi";
import { usePrevious, isValid, encrypt } from "../../utils";
import { isEqual, debounce } from "../../fp";
import Spinner from "../../common/Spinner";
import { ContextConfig } from "../../App";
import ConfirmDelete from "../../common/ConfirmDelete";
import Tooltip from "../../Tooltip/Tooltip";
import { isBrowser } from "react-device-detect";
import Popup from "reactjs-popup";
import { toast } from "react-toastify";
import PasswordChangeMeter from "./PasswordChangeMeter";
import Axios from "axios";
import qs from "qs";

import "reactjs-popup/dist/index.css";
import "./UserList.css";

const contentStyle = {
  width: "300px",
  padding: "10px",
  maxHeight: "400px",
  overflowY: "auto",
  overflowX: "hidden"
};
const overlayStyle = { background: "rgba(0,0,0,0.8)" };
const arrowStyle = { color: "#000" }; // style for an svg element

const UserList = ({
  sendActiveUser,
  stores,
  departments,
  reloadDepartments,
  setReloadDepartments
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [confirmText, setConfirmText] = useState("");
  const [error, setError] = useState("");
  const [showingDelete, setShowingDelete] = useState(false);
  const [activeUser, setActiveUser] = useState({});
  const [deleteError, setDeleteError] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [result, setResult] = useState("");
  const [defaultDepartment, setDefaultDepartment] = useState(0);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const context = useContext(ContextConfig);

  const previousUsers = usePrevious(users);
  const previousSearch = usePrevious(search);

  useEffect(() => {
    console.log("isBrowser", isBrowser);
    if (!isEqual(previousUsers, users)) {
      loadUsers();
    }
    if (previousSearch !== search) {
      loadUsers();
    }

    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);

    findDivHeight();

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, [users]);

  useEffect(() => {
    if (defaultDepartment != "0") {
      filterUsers();
    } else {
      setFilteredUsers(users);
    }
  }, [defaultDepartment]);

  const findDivHeight = () => {
    if (!isBrowser) return;
    const navbar = document
      .getElementById("master-navbar")
      .getBoundingClientRect().height;
    const usedSpace = navbar + 180; // for some reason the user controls div shows a height of zero???
    const remaining = window.innerHeight - usedSpace;

    const widget = document.getElementById("users-list");
    if (isValid(widget)) {
      widget.style.height = remaining + "px";
      widget.style.overflow = "auto";
    }
  };

  const handleUserEditClick = (record) => {
    sendActiveUser(record);
  };

  const handleUserDeleteClick = (record) => {
    setActiveUser(record);
    setShowingDelete(true);
  };

  const hideMessages = () => {
    setTimeout(() => {
      var d1 = document.getElementById("deleteerror");
      if (typeof d1 !== "undefined" && d1 !== null) {
        d1.style.display = "none";
      }
      var d2 = document.getElementById("deleteok");
      if (typeof d2 !== "undefined" && d2 !== null) {
        d2.style.display = "none";
      }
    }, 2000);
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleClick = () => {
    loadUsers();
  };

  const handleDeleteUser = () => {
    setIsLoading(true);
    deleteUser(context.clockUrl, context.clockToken, activeUser.userid)
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          loadUsers();
          setDeleteError(false);
          setDeleteSuccess(true);
          hideMessages();
        } else {
          setDeleteError(true);
          setDeleteSuccess(false);
          hideMessages();
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setDeleteError(true);
        setDeleteSuccess(false);
        hideMessages();
      });
  };

  const handleConfirmTextChange = (e) => {
    setConfirmText(e.target.value);
  };

  const compareDeleteText = () => {
    if (confirmText == "delete me") {
      setShowingDelete(false);
      handleDeleteUser();
    } else {
      setError(confirmText + " does not match delete me");
    }
  };

  const loadUsers = () => {
    setIsLoading(true);
    getClockUsers(
      context.clockUrl,
      context.clockToken,
      search,
      0,
      context.company,
      context.lastClockStore
    )
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          setUsers(j.users);
          setFilteredUsers(j.users);
        } else {
          setUsers([]);
          setFilteredUsers([]);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setUsers([]);
        setFilteredUsers([]);
      });
  };

  const getStatus = (record) => {
    switch (parseInt(record.status)) {
      case 0:
        return "Clocked Out";
      case 1:
        return "Clocked In";
      case 2:
        return "On Break";
      case 3:
        return "At Lunch";
      default:
        return "Clocked Out";
    }
  };

  const handleStatusChange = (e, record) => {
    const status = e.target.value;
    changeStatus(context.clockUrl, context.clockToken, record.userid, status)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          toast.success("Status has been changed", {
            position: toast.POSITION.TOP_LEFT
          });
          loadUsers();
        } else {
          setResult("");
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setResult("");
        console.log(err);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const resetPassword = ({ password, userid }) => {
    setIsLoading(true);
    const encryptedPassword = encrypt(password, context.key).toString();
    Axios({
      method: "POST",
      cors: true,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + context.clockToken
      },
      url: context.clockUrl + "users/resetpassword",
      data: qs.stringify({
        userid,
        newPassword: encryptedPassword
      })
    })
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          toast.success("Your password has been reset", {
            position: toast.POSITION.TOP_LEFT
          });
          loadUsers();
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const handleStoreChange = (e) => {
    const storeid = e.target.value;
    context.lastClockStore = parseInt(storeid);
    loadUsers();
    setReloadDepartments(!reloadDepartments);
    setLastStoreid(context.clockUrl, context.clockToken, storeid)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          console.log(storeid + " saved as last storeid");
        } else {
          console.log(j.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const filterUsers = () => {
    const newArray = users.filter(
      (u) => u.defaultDepartment == defaultDepartment
    );
    setFilteredUsers(newArray);
  };

  return (
    <div>
      {isLoading ? <Spinner /> : null}
      <ConfirmDelete
        isShowing={showingDelete}
        hide={() => setShowingDelete(false)}
        confirmText={confirmText}
        setConfirmText={handleConfirmTextChange}
        compareDeleteText={compareDeleteText}
        error={error}
      />
      <div
        className="row user-search"
        id="user-controls"
        style={{ marginTop: "10px", marginBottom: "10px" }}
      >
        <div className="col-4">
          <input
            type="text"
            className="form-control user-search-box"
            autoComplete="timeclock-search-user"
            name="timeclock-search-user"
            value={search}
            onChange={handleSearchChange}
            placeholder="Search"
          />
        </div>
        <div className="col-1">
          <button
            className="btn btn-secondary user-search-button"
            type="submit"
            onClick={handleClick}
            style={{ textAlign: "center" }}
          >
            <i className="fa fa-search"></i>
          </button>
        </div>
        <div className="col-3">
          <select
            className="form-control"
            value={defaultDepartment}
            onChange={(e) => setDefaultDepartment(e.target.value)}
          >
            <option value="0">Select Department</option>
            {departments &&
              departments.map((dept, i) => (
                <option key={`ds-${i}`} value={dept.id}>
                  {dept.description}
                </option>
              ))}
          </select>
        </div>
        <div className="col-3">
          <select
            className="form-control"
            value={context.lastClockStore}
            onChange={handleStoreChange}
          >
            <option value="0">All Stores</option>
            {stores &&
              stores.map((store, i) => (
                <option key={`ss-${i}`} value={store.id}>
                  {store.storeName}
                </option>
              ))}
          </select>
        </div>
      </div>
      {!deleteError ? null : (
        <div id="deleteerror" className="row deleteerror">
          There was an error deleting your user
        </div>
      )}
      {!deleteSuccess ? null : (
        <div id="deleteok" className="row deleteok">
          Your user was deleted
        </div>
      )}
      <div className="users-list" id="users-list">
        {filteredUsers.length === 0 ? (
          <div>No records returned</div>
        ) : (
          <table className="table table-striped" style={{ width: "100%" }}>
            <thead>
              <tr>
                <th className="user-icon">Edit</th>
                {parseInt(context.userLevel) >= 5 && isBrowser ? (
                  <th>Status</th>
                ) : null}
                <th>Id</th>
                <th>Username</th>
                <th className="user-delete-icon">Delete</th>
                {parseInt(context.userLevel) >= 6 && isBrowser ? (
                  <th>Reset</th>
                ) : null}
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map((record, index) => (
                <tr key={index} className="user-line">
                  <td className="user-icon" style={{ cursor: "pointer" }}>
                    <Tooltip
                      message={"Edit User"}
                      position={"top"}
                      styles={{ backgroundColor: "#000", color: "#fff" }}
                    >
                      <i
                        onClick={() => handleUserEditClick(record)}
                        className="fal fa-user-edit"
                      ></i>
                    </Tooltip>
                  </td>
                  {parseInt(context.userLevel) >= 5 && isBrowser ? (
                    <td>
                      {parseInt(record.status) > 0 ? (
                        <Popup
                          trigger={
                            <i
                              className="far fa-user-clock"
                              style={{
                                color: "limegreen",
                                cursor: "pointer"
                              }}
                            ></i>
                          }
                          position="bottom center"
                          {...{ contentStyle, overlayStyle, arrowStyle }}
                        >
                          <div className="status-wrapper">
                            <div className="status-change">
                              <h3>Current Status: {getStatus(record)}</h3>
                              <h3>Change Status to</h3>
                              <select
                                className="form-control"
                                onChange={(e) => handleStatusChange(e, record)}
                              >
                                <option value="-1">Set new Status</option>
                                <option value="0">Clocked Out</option>
                                <option value="1">Clocked In</option>
                                <option value="2">On Break</option>
                                <option value="3">At Lunch</option>
                              </select>
                            </div>
                            <div className="status-result">{result}</div>
                          </div>
                        </Popup>
                      ) : (
                        <i
                          className="far fa-user-clock"
                          style={{ color: "red" }}
                        ></i>
                      )}
                    </td>
                  ) : null}

                  <td>{record.userid}</td>
                  <td>{record.username}</td>
                  <td
                    className="user-delete-icon"
                    style={{ cursor: "pointer" }}
                  >
                    <Tooltip message={"Delete user"} position={"top"}>
                      <i
                        onClick={() => handleUserDeleteClick(record)}
                        className="far fa-minus-octagon"
                      ></i>
                    </Tooltip>
                  </td>

                  {parseInt(context.userLevel) >= 6 && isBrowser ? (
                    <td>
                      <Popup
                        trigger={
                          <i
                            className="far fa-unlock-alt"
                            style={{ cursor: "pointer" }}
                          ></i>
                        }
                        position="bottom center"
                        {...{ contentStyle, overlayStyle, arrowStyle }}
                      >
                        <div>
                          <PasswordChangeMeter
                            userid={record.userid}
                            confirmPasswordChange={resetPassword}
                          />
                        </div>
                      </Popup>
                    </td>
                  ) : null}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

UserList.propTypes = {
  sendActiveUser: PropTypes.func,
  stores: PropTypes.array,
  departments: PropTypes.array,
  reloadDepartments: PropTypes.bool,
  setReloadDepartments: PropTypes.func
};

export default UserList;
