import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tooltip from "../Tooltip/Tooltip";

import "./PagingToolbar.css";

const initialPagingToolbarState = {
  data: [],
  recordsPerPage: 50,
  totalPages: 0,
  currentPage: 1
};

const PagingToolbar = ({
  data,
  currentPage,
  totalRecords,
  recordsPerPage,
  handlePageChange,
  handleRecordsPerPageChange,
  start,
  end
}) => {
  const [state, setState] = useState(initialPagingToolbarState);

  useEffect(() => {
    console.log("total records", totalRecords);
    console.log("records per page", recordsPerPage);

    let totalPages = Math.ceil(totalRecords / recordsPerPage);
    console.log("totalPages", totalPages);
    if (totalPages == 0) {
      totalPages = 1;
    }

    setState({
      ...state,
      totalPages,
      recordsPerPage,
      currentPage: currentPage + 1
    });
  }, [data, totalRecords]);

  const handleNext = () => {
    handlePageChange(state.currentPage);
  };

  const handlePrev = () => {
    handlePageChange(state.currentPage - 2);
  };

  const handleFirst = () => {
    handlePageChange(0);
  };

  const handleLast = () => {
    handlePageChange(state.totalPages - 1);
  };

  return (
    <div className="paging-toolbar-wrapper">
      <div>
        {state.currentPage == 1 ? (
          <React.Fragment>
            <span>
              <i className="far fa-chevron-double-left"></i>
            </span>

            <span style={{ marginLeft: "10px", marginRight: "10px" }}>
              <i className="fas fa-chevron-left"></i>
            </span>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Tooltip message={"First Page"} position={"bottom"}>
              <span className="Paging-button" onClick={handleFirst}>
                <i className="far fa-chevron-double-left"></i>
              </span>
            </Tooltip>

            <Tooltip message={"Previous Page"} position={"bottom"}>
              <span
                style={{ marginLeft: "10px", marginRight: "10px" }}
                className="Paging-button"
                onClick={handlePrev}
              >
                <i className="fas fa-chevron-left"></i>
              </span>
            </Tooltip>
          </React.Fragment>
        )}

        <span>Page</span>
        <input
          type="number"
          className="paging-toolbar-current-page"
          value={state.currentPage}
          onChange={() => console.log("changed")}
        />
        <span> of {state.totalPages}</span>

        {currentPage == state.totalPages - 1 ? (
          <React.Fragment>
            <span style={{ marginLeft: "10px", marginRight: "10px" }}>
              <i className="fas fa-chevron-right"></i>
            </span>

            <span>
              <i className="far fa-chevron-double-right"></i>
            </span>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Tooltip message={"Next Page"} position={"bottom"}>
              <span
                style={{ marginLeft: "10px", marginRight: "10px" }}
                className="Paging-button"
                onClick={handleNext}
              >
                <i className="fas fa-chevron-right"></i>
              </span>
            </Tooltip>

            <Tooltip message={"Last Page"} position={"bottom"}>
              <span className="Paging-button" onClick={handleLast}>
                <i className="far fa-chevron-double-right"></i>
              </span>
            </Tooltip>
          </React.Fragment>
        )}
      </div>
      <div>
        <span>Show</span>
        <input
          type="number"
          className="paging-records-per-page"
          value={recordsPerPage}
          onChange={handleRecordsPerPageChange}
        />
        <span>Records per Page</span>
      </div>
      <div className="paging-summary">
        <span>{`Displaying ${start} - ${end} of ${totalRecords}`}</span>
      </div>
    </div>
  );
};

PagingToolbar.propTypes = {
  data: PropTypes.array,
  currentPage: PropTypes.number,
  totalRecords: PropTypes.number,
  handlePageChange: PropTypes.func,
  recordsPerPage: PropTypes.number,
  handleRecordsPerPageChange: PropTypes.func,
  start: PropTypes.number,
  end: PropTypes.number
};

export default PagingToolbar;
