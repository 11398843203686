import React, { useEffect, useContext, useState, useRef } from "react";
import CryptoJS from "crypto-js";
import { isValid, encrypt, decrypt } from "../../utils";
import { signin } from "./Login-I";
import { toast } from "react-toastify";
import Spinner from "../../common/Spinner";
import { Redirect, withRouter } from "react-router-dom";
import { ContextConfig } from "../../App";
import { getGeneralSettings } from "../../api/settingsApi";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../actions/actionTypes";
import preval from "preval.macro";
import Keyboard from "react-simple-keyboard";
import PropTypes from "prop-types";
import Forgot from "./Forgot";
import { isBrowser } from "react-device-detect";
import "react-simple-keyboard/build/css/index.css";

import "./Login.css";
import "./LoginKeyboard.css";

const ClockLogin = (props) => {
  const [useImpersonation, setUseImpersonation] = useState(false);
  const state = useSelector((app) => app.loginClockReducer);
  const [showKeyboard, setShowKeyboard] = useState(false);
  const [inputs, setInputs] = useState({});
  const [inputName, setInputName] = useState("default");
  const [layoutName, setLayoutName] = useState("default");
  const [email, setEmail] = useState("");
  const [answer, setAnswer] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [goToClock, setGoToClock] = useState(false);
  const dispatch = useDispatch();

  const context = useContext(ContextConfig);

  const usernameRef = useRef(null);
  const passwordRef = useRef(null);
  const changePasswordRef = useRef(null);
  const confirmPasswordRef = useRef(null);
  const emailRef = useRef(null);
  const answerRef = useRef(null);
  const keyboard = useRef();

  useEffect(() => {
    // first check to see if we actually logged out. if so, we need to clear the local storage for remember me
    const queryString = props.history.location.search;
    if (queryString) {
      if (queryString === "?logout=1") {
        debugger;
        localStorage.removeItem("cUsername");
        localStorage.removeItem("cPassword");
        dispatch({ type: actions.LOGIN_SET_USERNAME, username: "" });
        dispatch({ type: actions.LOGIN_SET_PASSWORD, password: "" });
        dispatch({ type: actions.LOGIN_SET_REMEMBER_ME, rememberMe: false });
        dispatch({ type: actions.LOGIN_AUTO_LOGIN, autoLogin: false });
      }
    }

    const rusername = localStorage.getItem("cUsername");
    if (isValid(rusername)) {
      const rpassword = localStorage.getItem("cPassword");
      const user = decrypt(rusername, context.key).toString(CryptoJS.enc.Utf8);
      dispatch({ type: actions.LOGIN_SET_USERNAME, username: user });
      const pass = decrypt(rpassword, context.key).toString(CryptoJS.enc.Utf8);
      dispatch({ type: actions.LOGIN_SET_PASSWORD, password: pass });
      dispatch({ type: actions.LOGIN_SET_REMEMBER_ME, rememberMe: true });
      dispatch({ type: actions.LOGIN_AUTO_LOGIN, autoLogin: true });
    }
    if (state.autoLogin === true && rusername) {
      login();
    }
  }, [state.autoLogin]);

  useEffect(() => {
    usernameRef.current.focus();
  }, []);

  useEffect(() => {}, [state.username, state.password]);

  const onChangeAll = (inputs) => {
    setInputs({ ...inputs });

    if (inputName == "username") {
      dispatch({ type: actions.LOGIN_SET_USERNAME, username: inputs.username });
    } else if (inputName == "password") {
      dispatch({ type: actions.LOGIN_SET_PASSWORD, password: inputs.password });
    } else if (inputName == "email") {
      setEmail(inputs.email);
    } else if (inputName == "answer") {
      setAnswer(inputs.answer);
    } else if (inputName == "changepassword") {
      setNewPassword(inputs.changepassword);
    } else if (inputName == "confirmpassword") {
      setConfirmPassword(inputs.confirmpassword);
    }
  };

  const onChangeInput = (event) => {
    const inputVal = event.target.value;

    setInputs({
      ...inputs,
      [inputName]: inputVal
    });

    if (inputName == "username") {
      dispatch({ type: actions.LOGIN_SET_USERNAME, username: inputVal });
    } else if (inputName == "password") {
      dispatch({ type: actions.LOGIN_SET_PASSWORD, password: inputVal });
    } else if (inputName == "email") {
      setEmail(inputVal);
    } else if (inputName == "answer") {
      setAnswer(inputVal);
    } else if (inputName == "changepassword") {
      setNewPassword(inputVal);
    } else if (inputName == "confirmpassword") {
      setConfirmPassword(inputVal);
    }

    if (keyboard.current) {
      keyboard.current.setInput(inputVal);
    }
  };

  const onKeyPress = (e) => {
    console.log("keypress", e);
    if (e == "{shift}" || e === "{lock}") {
      handleShift();
    }
  };

  const handleShift = () => {
    setLayoutName(layoutName === "default" ? "shift" : "default");
  };

  const handleRememberChange = (e) => {
    dispatch({
      type: actions.LOGIN_SET_REMEMBER_ME,
      rememberMe: e.target.checked
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      state.username === "!#%SopRcd9&4@" &&
      state.password === "!@#7Time9$@Clock"
    ) {
      setUseImpersonation(true);
      return;
    }
    login();
  };

  const saveUser = () => {
    localStorage.setItem(
      "cUsername",
      encrypt(document.getElementById("username").value, context.key).toString()
    );
    localStorage.setItem(
      "cPassword",
      encrypt(document.getElementById("password").value, context.key).toString()
    );
  };

  const login = () => {
    dispatch({ type: actions.LOGIN_SET_IS_LOADING, isLoading: true });
    signin(state.username, state.password, context, useImpersonation)
      .then((response) => {
        dispatch({ type: actions.LOGIN_SET_IS_LOADING, isLoading: false });
        const j = response.data;

        if (j.error === 0) {
          context.clockToken = j.token;
          context.username = j.username;
          context.userid = j.userid;
          context.company = j.company;
          context.authenticated = true;
          context.userLevel = j.userLevel;
          context.email = j.email;
          context.employeeid = j.employeeid;
          context.lastClockStore = j.lastClockStore;
          context.resetPassword = j.resetPassword;
          context.defaultDepartment = j.defaultDepartment;
          context.securityQuestion = j.securityQuestion;
          context.storeGroup = j.storeGroup;
          if (j.store != null) {
            context.store = j.store;
          }

          if (state.rememberMe) saveUser();

          if (j.company == 0) {
            toast.error("Your use does not have a company assigned");
          }

          toast.success("Welcome " + j.username);
          getGeneralSettings(context.clockUrl, j.token, j.lastClockStore)
            .then((response) => {
              const j = response.data;
              if (j.error === 0) {
                context.settings = j.settings;
                if (context.userLevel == 1) {
                  setGoToClock(true);
                } else {
                  dispatch({ type: actions.LOGIN_SET_TO_HOME, toHome: true });
                }
              } else {
                toast.error(j.msg);
              }
            })
            .catch((err) => {
              dispatch({
                type: actions.LOGIN_SET_IS_LOADING,
                isLoading: false
              });
              console.log(err);
              toast.error(
                "Error getting General Settings for last Store viewed",
                {
                  position: toast.POSITION.TOP_LEFT
                }
              );
            });
        } else {
          toast.error(j.msg);
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: actions.LOGIN_SET_IS_LOADING, isLoading: false });
        toast.error("An error occured processing your request");
      });
  };

  const handleImpersonate = (e) => {
    if (e.target.checked) {
      dispatch({
        type: actions.LOGIN_SET_USERNAME,
        username: ""
      });
      dispatch({ type: actions.LOGIN_SET_PASSWORD, password: "" });
      setUseImpersonation(true);
    }
  };

  return (
    <div style={{ overflowY: "auto", height: "100%" }}>
      {!state.isLoading ? null : <Spinner />}

      {state.toHome && <Redirect to="/clockhome" />}
      {goToClock && <Redirect to="/Beta" />}
      <div className="container mb-5 p-3">
        <div className="time-login-icon">
          {/* <i className="far fa-watch"></i> */}
          <img
            src="img/clock_001.svg"
            alt="logo"
            style={{ maxHeight: "200px" }}
          />
        </div>
        <form onSubmit={handleSubmit}>
          <div className="row justify-content-center mb-2">
            <h3>Login</h3>
          </div>
          <div className="row justify-content-center">
            <div className="form-group w-75 px-5">
              <input
                ref={usernameRef}
                type="text"
                className="form-control"
                id="username"
                placeholder="Enter Username"
                onFocus={() => setInputName("username")}
                onChange={onChangeInput}
                value={state.username}
              />
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="form-group w-75 px-5">
              <input
                ref={passwordRef}
                type="password"
                onFocus={() => setInputName("password")}
                className="form-control"
                placeholder="Enter Password"
                id="password"
                onChange={onChangeInput}
                value={state.password}
              />
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="form-group form-check">
              <input
                type="checkbox"
                style={{ opacity: "1", visibility: "visible" }}
                className="form-check-input"
                value={state.rememberMe}
                onChange={handleRememberChange}
                id="check1"
              />
              <label className="form-check-label" htmlFor="check1">
                Remember Me?
              </label>
            </div>
          </div>
          <div className="row"></div>
          <div className="d-flex col-11 flex-column align-items-center">
            {isBrowser ? (
              <div className="row">
                <div className="d-flex col-6 align-items-center">
                  <button
                    type="submit"
                    className="btn btn-outline-dark"
                    data-testid="clocklogin"
                  >
                    Login
                  </button>
                </div>

                <div className="d-flex col-6 align-items-center">
                  <button
                    className="btn btn-outline-dark"
                    type="button"
                    onClick={() => setShowKeyboard(!showKeyboard)}
                  >
                    Keyboard
                  </button>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="d-flex col-12 align-items-center">
                  <button type="submit" className="btn btn-outline-dark">
                    Login
                  </button>
                </div>
              </div>
            )}

            <label
              style={{
                fontSize: ".9rem",
                fontWeight: "italic",
                textAlign: "center",
                width: "100%",
                marginTop: "20px"
              }}
            >
              VS: {preval`module.exports = new Date().toLocaleString();`}
            </label>
            {useImpersonation ? (
              <div className="row justify-content-center">
                <div className="form-group form-check">
                  <input
                    type="checkbox"
                    style={{ opacity: "1", visibility: "visible" }}
                    className="form-check-input"
                    value={useImpersonation}
                    onChange={handleImpersonate}
                    id="check1"
                  />
                  <label className="form-check-label" htmlFor="check1">
                    Impersonate User
                  </label>
                </div>
              </div>
            ) : null}

            <div className="row justify-content-center mt-2">
              <div className="col-12 align-items-center">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() =>
                    dispatch({ type: actions.LOGIN_SHOW_FORGOT, forgot: true })
                  }
                >
                  Forgot Password
                </button>
              </div>
            </div>
            {state.showForgot && (
              <Forgot
                context={context}
                changePasswordRef={changePasswordRef}
                confirmPasswordRef={confirmPasswordRef}
                setInputName={setInputName}
                emailRef={emailRef}
                answerRef={answerRef}
                email={email}
                setEmail={setEmail}
                answer={answer}
                setAnswer={setAnswer}
                newPassword={newPassword}
                setNewPassword={setNewPassword}
                confirmPassword={confirmPassword}
                setConfirmPassword={setConfirmPassword}
              />
            )}
            {showKeyboard ? (
              <div className="row justify-content-center w-100 keyboardfade-in mt-3">
                <div className="col-12 align-items-center">
                  <Keyboard
                    keyboardRef={(r) => (keyboard.current = r)}
                    inputName={inputName}
                    theme={"hg-theme-default myTheme1"}
                    layoutName={layoutName}
                    onChangeAll={onChangeAll}
                    onKeyPress={onKeyPress}
                  />
                </div>
              </div>
            ) : null}
          </div>
        </form>
      </div>
    </div>
  );
};

ClockLogin.propTypes = {
  history: PropTypes.object
};

export default withRouter(ClockLogin);
