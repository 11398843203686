import React, { useState, useEffect, useContext } from "react";
//import colors from "../../common/colors";
import "../Clock.css";
import ClockSidebar from "../ClockSidebar";
import { loadPunches } from "../DesktopTimeclock/DEsktopTimeClockContainer-I";
import { ContextConfig } from "../../App";
import Spinner from "../../common/Spinner";
import Moment from "react-moment";
import "moment-timezone";
import { formatDate, usePrevious } from "../../utils";
import { isEqual, debounce } from "../../fp";
import "../DesktopTimeclock/ModalHours.css";

const Timecard = () => {
  const [punches, setPunches] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [generalSettings, setGeneralSettings] = useState({});
  const [total, setTotal] = useState(0);
  const [store, setStore] = useState({});
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  // eslint-disable-next-line
  const [message, setMessage] = useState("");

  const context = useContext(ContextConfig);

  const previousPunches = usePrevious(punches);

  useEffect(() => {
    if (!isEqual(previousPunches, punches)) {
      getPunches();
    }

    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);

    findDivHeight();

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, [store]);

  const findDivHeight = () => {
    const controls = document.getElementById("controls").getBoundingClientRect()
      .height;
    const usedSpace = controls + 100;
    const remaining = window.innerHeight - usedSpace;
    const body = document.getElementById("punch-list");
    body.style.overflow = "auto";
    body.style.height = remaining + "px";
  };

  const getPunches = () => {
    loadPunches(
      context.lastClockStore,
      setIsLoading,
      context,
      setPunches,
      setGeneralSettings,
      generalSettings,
      setTotal,
      setMessage,
      punchesLoaded,
      setStore
    );
  };

  const punchesLoaded = () => {};

  return (
    <div className="clock-mobile">
      <div
        className="mobile-clock-header"

      >
        <ClockSidebar />
        <div className="mobile-clock-timecard">
          <div className="modal-hours-body">
            {isLoading && <Spinner />}
            <div id="controls">
              <div className="modal-hours-store">{store.storeName}</div>
              <div className="modal-hours-user">{context.username}</div>
              <div className="modal-hours-empid">{context.employeeid}</div>
            </div>
            <div className="modal-hours-list" id="punch-list">
              <table className="table table-striped" style={{ height: "100%" }}>
                <thead>
                  <tr>
                    <th className="modal-hours-data" scope="col">
                      Date
                    </th>
                    <th className="modal-hours-data" scope="col">
                      Type
                    </th>
                    <th className="modal-hours-data" scope="col">
                      In
                    </th>
                    <th className="modal-hours-data" scope="col">
                      Out
                    </th>
                    <th className="modal-hours-total" scope="row">
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {punches.length > 0
                    ? punches.map((punch, index) => (
                        <tr key={`punches-${index}`}>
                          <td className="modal-hours-data">
                            {formatDate(punch.punchDate)}
                          </td>
                          <td className="modal-hours-data">
                            {punch.punchType}
                          </td>
                          <td className="modal-hours-data">
                            {" "}
                            <Moment format="hh:mm a">{punch.punchIn}</Moment>
                          </td>
                          <td className="modal-hours-data">
                            {punch.punchOut.length > 0 ? (
                              <Moment format="hh:mm a">{punch.punchOut}</Moment>
                            ) : null}
                          </td>
                          <td className="modal-hours-total">{punch.total}</td>
                        </tr>
                      ))
                    : null}
                  {punches.length > 0 ? (
                    <tr>
                      <td
                        style={{ fontWeight: "bold", textAlign: "right" }}
                        colSpan="4"
                      >
                        Total
                      </td>
                      <td style={{ textAlign: "right", fontWeight: "bold" }}>
                        {total}
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timecard;
