import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import Employee from "./Employee";
import { dropShift } from "../../api/scheduleApi";
import { formatDate, addDays } from "../../utils";
import { toast } from "react-toastify";

import "./Employees.css";

const Employees = (props) => {
  const [dragging, setDragging] = useState(false);

  const dragItem = useRef(null);
  const dragNode = useRef(null);

  const handleDragStart = (e, params) => {
    dragItem.current = params;
    dragNode.current = e.target;
    dragNode.current.style.cursor = "grabbing";
    dragNode.current.addEventListener("dragend", handleDragEnd);
    setDragging(true);
    if (!e.ctrlKey) {
      setTimeout(() => {
        dragNode.current.style.display = "none";
      }, 0);
    }
  };

  const handleDragEnd = () => {
    setDragging(false);
    dragNode.current.removeEventListener("dragend", handleDragEnd);
    dragItem.current = null;
    dragNode.current.style.cursor = "default";
    dragNode.current = null;
  };

  const findShifts = (userid) => {
    if (props.storeSchedules.length === 0) return;
    const shifts = props.storeSchedules.filter((s) => s.user.userid === userid);
    if (shifts.length > 0) {
      if (props.defaultDepartment != 0 && props.deptFilterType == true) {
        const filteredShifts = shifts[0].schedule.filter(
          (s) => s.department == props.defaultDepartment
        );
        props.updateFilteredEmployeeShifts(filteredShifts);
        return filteredShifts;
      } else {
        return shifts[0].schedule;
      }
    }
    return [];
  };

  const findPto = (userid) => {
    if (props.storeSchedules.length === 0) return [];
    const shifts = props.storeSchedules.filter((s) => s.user.userid === userid);
    if (shifts.length > 0) {
      return shifts[0].pto;
    } else {
      return [];
    }
  };

  const handleBadDrop = (e) => {
    e.target.style.backgroundColor = "#1f1e2e";
    const id = dragNode.current.id;
    const div = document.getElementById(id);
    div.style.display = "revert";
    e.preventDefault();
  };

  const handleDrop = (e, params) => {
    e.target.style.background = "#1f1e2e";
    const newDate = addDays(props.startDate, params.day);

    let copied = false;
    if (e.ctrlKey) {
      copied = true;
    }
    dropShift(
      props.context.clockUrl,
      props.context.clockToken,
      dragItem.current.shiftid,
      params.user.userid,
      formatDate(newDate),
      props.store,
      formatDate(props.startDate),
      formatDate(props.endDate),
      copied
    )
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          props.setStoreSchedules(j.schedules);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION_TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An Internal error occured", {
          position: toast.POSITION_TOP_LEFT
        });
      });
  };

  return (
    <div className="employees-days">
      {props.users.length > 0
        ? props.users.map((user, i) => (
            <Employee
              key={`ur-${i}`}
              user={user}
              days={props.days}
              shifts={findShifts(user.userid)}
              pto={findPto(user.userid)}
              weekStart={props.weekStart}
              handleDragStart={handleDragStart}
              dragging={dragging}
              context={props.context}
              reloadSchedule={props.reloadSchedule}
              setReloadSchedule={props.setReloadSchedule}
              handleDateClick={props.handleDateClick}
              editShift={props.editShift}
              handleDrop={handleDrop}
              startDate={props.startDate}
              dragNode={dragNode}
              handleBadDrop={handleBadDrop}
            />
          ))
        : null}
    </div>
  );
};

Employees.propTypes = {
  users: PropTypes.array,
  days: PropTypes.array,
  storeSchedules: PropTypes.array,
  weekStart: PropTypes.instanceOf(Date),
  context: PropTypes.object,
  reloadSchedule: PropTypes.bool,
  setReloadSchedule: PropTypes.func,
  handleDateClick: PropTypes.func,
  editShift: PropTypes.func,
  setStoreSchedules: PropTypes.func,
  startDate: PropTypes.instanceOf(Date),
  store: PropTypes.any,
  endDate: PropTypes.instanceOf(Date),
  defaultDepartment: PropTypes.any,
  deptFilterType: PropTypes.any,
  updateFilteredEmployeeShifts: PropTypes.func
};

export default Employees;
