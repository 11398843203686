import React from "react";
import { Card, CardTitle, CardBody } from "reactstrap";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import * as actions from "../actions/actionTypes";

import "./FreshopCard.css";

const FreshopCard = ({ headerText, count, state, handler, actionType }) => {
  const dispatch = useDispatch();

  const min = 1;
  const max = 5;

  const getComponentColor = () => {
    if (headerText == "Orders") {
      return "limegreen";
    }
    if (count < min) {
      return "limegreen";
    } else if (count >= min && count <= max) {
      return "orange";
    } else {
      return "red";
    }
  };

  return (
    <Card className="freshop-fade-in">
      <CardTitle
        tag="h4"
        className="freshop-card-title"
        style={{ borderBottom: `2px solid ${getComponentColor()}` }}
      >
        {headerText}
      </CardTitle>
      <CardBody>
        <div className="freshop-card-icon">
          {headerText === "Orders" ? (
            <i
              className="fal fa-shopping-cart"
              style={{ color: "limegreen" }}
            ></i>
          ) : (
            <React.Fragment>
              {count < min ? (
                <i
                  className="fal fa-check-circle"
                  style={{ color: "limegreen" }}
                ></i>
              ) : (
                <React.Fragment>
                  {count >= min && count <= max ? (
                    <i
                      className="fal fa-exclamation-circle"
                      style={{ color: "orange" }}
                    ></i>
                  ) : (
                    <i
                      className="fal fa-radiation-alt"
                      style={{ color: "red" }}
                    ></i>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </div>
        <div className="freshop-card-count">{count}</div>
        {count > 0 ? (
          <div className="freshop-card-button">
            {state[handler] ? (
              <button
                className="btn btn-warning"
                onClick={() =>
                  dispatch({
                    type: actions.FRESHOP_CHANGE_STATE,
                    payloadType: actionType,
                    value: false
                  })
                }
              >
                Hide Results
              </button>
            ) : (
              <button
                className="btn btn-success"
                onClick={() =>
                  dispatch({
                    type: actions.FRESHOP_CHANGE_STATE,
                    payloadType: actionType,
                    value: true
                  })
                }
              >
                Show Results
              </button>
            )}
          </div>
        ) : null}
      </CardBody>
    </Card>
  );
};

FreshopCard.propTypes = {
  headerText: PropTypes.string,
  count: PropTypes.number,
  state: PropTypes.object,
  handler: PropTypes.string,
  actionType: PropTypes.string
};

export default FreshopCard;
