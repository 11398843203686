import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Picker } from "c5-react-library";
import "./MobileLookup.css";

const MobileLookup = ({ users, selectUser }) => {
  const [isShowing, setIsShowing] = useState(false);
  const [username, setUsername] = useState("");

  const togglePicker = () => {
    setIsShowing(!isShowing);
  };

  const handleUserSelect = e => {
    setUsername(e.username);
    selectUser(e.id);
    setIsShowing(false);
  };

  const handleShowUsers = () => {
    setIsShowing(!isShowing);
  };

  return (
    <React.Fragment>
      <div className="p-3">
        <h3>Reset a Users Password</h3>
        <div className="row">
          <div className="col-xs-2">
            <button className="btn btn-outline-dark" onClick={handleShowUsers}>
              <i className="fal fa-user"></i>
            </button>
          </div>
          <div className="col-xs-8 my-auto mobile-username">{username}</div>
        </div>
      </div>
      <Picker
        isShowing={isShowing}
        hide={togglePicker}
        header="Users"
        store={users}
        displayField="username"
        valueField="id"
        handleSelect={handleUserSelect}
        useEffect={useEffect}
        useState={useState}
        useRef={useRef}
      />
    </React.Fragment>
  );
};

MobileLookup.propTypes = {
  users: PropTypes.array,
  selectUser: PropTypes.func
};

export default MobileLookup;
