import axios from "axios";

async function getReportsList(url, token) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "reports"
  });
  return json;
}

export { getReportsList };
