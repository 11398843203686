import React, { useContext, useEffect } from "react";
import { ContextConfig } from "../App";
import { useSelector, useDispatch } from "react-redux";
import { getItemErrors } from "../api/freshopApi";
import { toast } from "react-toastify";
import * as actions from "../actions/actionTypes";
import FreshopCard from "./FreshopCard";

const ItemErrors = () => {
  const freshopState = useSelector((app) => app.freshopReducer);
  const dispatch = useDispatch();

  const context = useContext(ContextConfig);

  useEffect(() => {
    itemErrors();
  }, [
    freshopState.selectedStore.id,
    freshopState.startDate,
    freshopState.endDate
  ]);

  const itemErrors = () => {
    getItemErrors(
      context.freshopApi,
      freshopState.selectedStore.id,
      freshopState.startDate,
      freshopState.endDate,
      context.freshopAppKey
    )
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          dispatch({ type: actions.FRESHOP_SET_ITEM_ERRORS, items: j.items });
        } else {
          dispatch({ type: actions.FRESHOP_SET_ITEM_ERRORS, items: [] });
        }
      })
      .catch((err) => {
        toast.error("An Internal Error occured", {
          position: toast.POSITION.TOP_LEFT
        });
        console.log(err);
        dispatch({ type: actions.FRESHOP_SET_ITEM_ERRORS, items: [] });
      });
  };

  return (
    <FreshopCard
      headerText={"Item Errors"}
      count={freshopState.itemErrors.length}
      state={freshopState}
      handler={"showItemErrors"}
      actionType={"SHOW_ITEM_ERRORS"}
    />
  );
};

export default ItemErrors;
