import React from "react";
import PropTypes from "prop-types";
import { formatDate } from "../utils";

const CustomerMobile = ({ userInfo, isPhone }) => {
  return (
    <table className="table">
      <thead>
        <tr>
          <th scope="col">{isPhone ? "Customer" : "Phone #"}</th>
          <th scope="col">Changed</th>
        </tr>
      </thead>
      <tbody>
        {userInfo.map((user) => (
          <tr key={user.f1148}>
            <td>{isPhone ? user.f1148 : user.f1172}</td>
            <td>{formatDate(user.f253)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

CustomerMobile.propTypes = {
  userInfo: PropTypes.array,
  isPhone: PropTypes.bool
};

export default CustomerMobile;
