/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import OrderBy from "./OrderBy";
import PropTypes from "prop-types";

const OrderBys = ({
  orderBys,
  data,
  reloadSelectors,
  setReloadSelectors,
  state,
  setState
}) => {
  useEffect(() => {}, [orderBys, state]);

  useEffect(() => {
    console.log("reloading OrderBys because of reload command");
    console.log("orderBys", orderBys);
  }, [reloadSelectors]);

  const changeOrderBy = (id, currentOrderBy, direction) => {
    if (currentOrderBy.length === 0) {
      return;
    }
    const selectedOrderby = state.columns["column-1"].orderbyIds.find(
      (o) => o.id === id
    );
    if (selectedOrderby) {
      selectedOrderby.orderby = currentOrderBy;
      selectedOrderby.direction = direction;
    }

    const index = state.columns["column-1"].orderbyIds.findIndex(
      (o) => o.id === id
    );
    const copiedOrderBys = Array.from(state.columns["column-1"].orderbyIds);
    copiedOrderBys.splice(index, 1);
    copiedOrderBys.splice(index, 0, selectedOrderby);

    const newState = {
      columns: {
        "column-1": {
          id: "column-1",
          orderbyIds: copiedOrderBys
        }
      }
    };

    setState(newState);
    setReloadSelectors(!reloadSelectors);
  };

  const removeOrderBy = (id) => {
    const index = state.columns["column-1"].orderbyIds.findIndex(
      (o) => o.id === id
    );
    const copiedOrderBys = state.columns["column-1"].orderbyIds;
    copiedOrderBys.splice(index, 1);
    const reIndexedOrderBys = reIndex(copiedOrderBys);
    const newState = {
      columns: {
        "column-1": {
          id: "column-1",
          orderbyIds: reIndexedOrderBys
        }
      }
    };
    setState(newState);
    setReloadSelectors(!reloadSelectors);
  };

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const data = { ...state };
    const dataArray = Array.from(data.columns["column-1"].orderbyIds);

    const draggedItem = dataArray.find(
      (o) => parseInt(o.id) === parseInt(draggableId)
    );

    dataArray.splice(source.index, 1);

    dataArray.splice(destination.index, 0, draggedItem);
    const reIndexedArray = reIndex(dataArray);

    data.columns["column-1"].orderbyIds = reIndexedArray;
    setState(data);
    setReloadSelectors(!reloadSelectors);
  };

  const reIndex = (array) => {
    const newArray = array.map((item, i) => {
      return {
        ...item,
        id: i.toString()
      };
    });
    return newArray;
  };

  return (
    <div className="row w-100 justify-content-center">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={"orderbydrop"}>
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{ padding: "5px" }}
            >
              {state.columns["column-1"].orderbyIds.map((o, index) => (
                <Draggable
                  draggableId={o.id.toString()}
                  index={index}
                  key={`dobi=${index}`}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        userSelect: "none",
                        padding: 10,
                        margin: "0 0 8px 0",
                        border: "1px solid #fff",
                        borderRadius: "10px",

                        backgroundColor: snapshot.isDragging
                          ? "#263B4A"
                          : "#456C86",
                        color: "white",
                        ...provided.draggableProps.style
                      }}
                    >
                      <OrderBy
                        key={`o-${index}`}
                        index={index}
                        data={data}
                        changeOrderBy={changeOrderBy}
                        id={o.id}
                        removeOrderBy={removeOrderBy}
                        orderby={o}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

OrderBys.propTypes = {
  id: PropTypes.any,
  orderBys: PropTypes.array,
  setOrderBys: PropTypes.func,
  data: PropTypes.array,
  setReloadSelectors: PropTypes.func,
  reloadSelectors: PropTypes.bool,
  state: PropTypes.object,
  setState: PropTypes.func
};

export default OrderBys;
