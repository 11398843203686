import axios from "axios";
import qs from "qs";

function getAssignedStores(url, token, success, failure) {
  axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "stores/assignedstores"
  })
    .then((response) => {
      if (success !== null) {
        success(response);
      }
    })
    .catch((err) => {
      if (failure !== null) {
        failure(err);
      }
    });
}

async function getAssignedStoresAsync(url, token) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "stores/assignedstores"
  });
  return json;
}

async function getAssigned(url, token, userid) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "stores/assignedbyuser",
    params: {
      userid
    }
  });
  return json;
}

async function getUnAssigned(url, token, userid) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "stores/unassignedbyuser",
    params: {
      userid
    }
  });
  return json;
}

async function getStoreDeptsAsync(url, token, storeid) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "stores/depts",
    params: {
      storeid
    }
  });
  return json;
}

async function createCashierRequest(context, employee) {
  const { url, token } = context;
  const {
    lastname,
    firstname,
    shortname,
    empid,
    level,
    storeid,
    finished,
    add,
    expedite,
    department_Description,
    department
  } = employee;
  let json = await axios({
    method: "POST",
    headers: {
      "Context-Type": "application/x-www-form-urlencoded",
      Authorization: "Bearer " + token
    },
    url: url + "widgets/createemployee",
    data: qs.stringify({
      lastname,
      firstname,
      shortname,
      empid,
      level,
      storeid,
      finished,
      add,
      expedite,
      department_Description,
      department
    })
  });

  return json;
}

async function readStoresInGroup(url, token, groupid) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "mobile/groupstores",
    params: {
      groupid
    }
  });
  return json;
}

async function createClockStore(url, token, store) {
  let json = await axios({
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: "Bearer " + token
    },
    url: url + "stores",
    data: qs.stringify({
      id: store.id,
      storeName: store.storeName,
      storeNumber: store.storeNumber,
      address: store.address,
      city: store.city,
      state: store.state,
      zip: store.zip,
      phoneNumber: store.phoneNumber,
      openTime: store.openTime,
      closeTime: store.closeTime,
      weekStart: store.weekStart,
      companyId: store.companyId,
      storeGroup: store.storeGroup
    })
  });
  return json;
}

async function readClockStores(url, token) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "stores"
  });
  return json;
}

async function deleteClockStore(url, token, id) {
  let json = await axios({
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "stores",
    params: {
      id
    }
  });
  return json;
}

async function getStoreUserLinks(url, token, userid) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "stores/storeuserlinks",
    params: {
      userid
    }
  });
  return json;
}

async function setLastStoreid(url, token, storeid) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "users/laststore",
    params: {
      storeid
    }
  });
  return json;
}

async function getWeekStart(url, token, storeid) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "punches/weekstart",
    params: {
      storeid
    }
  });
  return json;
}

async function getUserClockStores(url, token) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "stores/storesbyuser"
  });
  return json;
}

async function assign(url, token, userid, storeids) {
  let json = await axios({
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: "Bearer " + token
    },
    url: url + "stores/assign",
    data: qs.stringify({
      userid,
      storeids
    })
  });
  return json;
}

async function unassign(url, token, userid, storeid) {
  let json = await axios({
    method: "GET",
    cors: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "stores/unassign",
    params: {
      userid,
      storeid
    }
  });
  return json;
}

async function changeStorename(url, token, storeid, storename) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "widgets/storename",
    params: {
      storeid,
      storename
    }
  });
  return json;
}

async function storeGroups(url, token) {
  let json = await axios({
    method: "GET",
    cors: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "stores/storegroups"
  });
  return json;
}

async function getLoyaltyList(url, token) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "loyalty/list"
  });

  return json;
}

async function setLoyaltyFlag(url, token, storeid, active) {
  let json = await axios({
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "loyalty/loyalty",
    params: {
      storeid,
      active
    }
  });

  return json;
}

async function getUserAssignedLoyaltyStores(url, token) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "loyalty/assigned"
  });
  return json;
}

export {
  getAssignedStores,
  getAssignedStoresAsync,
  getStoreDeptsAsync,
  createCashierRequest,
  readStoresInGroup,
  createClockStore,
  readClockStores,
  deleteClockStore,
  getStoreUserLinks,
  setLastStoreid,
  getWeekStart,
  getUserClockStores,
  getUnAssigned,
  getAssigned,
  assign,
  unassign,
  changeStorename,
  storeGroups,
  getLoyaltyList,
  setLoyaltyFlag,
  getUserAssignedLoyaltyStores
};
