import React, { useState, useEffect, useContext } from "react";
import { ContextConfig } from "../../App";
import { usePrevious, isValid } from "../../utils";
import { isEqual, debounce } from "../../fp";
import Spinner from "../../common/Spinner";
import { readStates } from "../../api/statesApi";
import ClockSidebar from "../ClockSidebar";
//import colors from "../../common/colors";
import "../Clock.css";
import "./CreateCompany.css";
import {
  createCompany,
  readCompanies,
  deleteCompany
} from "../../api/companyApi";
import { toast } from "react-toastify";
import NewCompanyForm from "./NewCompanyForm";
import CompanyList from "./CompanyList";
import ConfirmDelete from "../../common/ConfirmDelete";

const initialCompany = {
  company_Id: 0,
  company_Name: "",
  company_Address: "",
  company_City: "",
  company_State: "",
  company_Zip: "",
  company_Phone: ""
};

const CreateCompany = () => {
  const [states, setStates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [company, setCompany] = useState(initialCompany);
  const [hasErrors, setHasErrors] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [showingDelete, setShowingDelete] = useState(false);
  const [confirmText, setConfirmText] = useState("");
  const [error, setError] = useState("");
  const [companyid, setCompanyid] = useState("");
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const context = useContext(ContextConfig);

  const previousStates = usePrevious(states);
  const previousCompanies = usePrevious(companies);

  useEffect(() => {
    if (!isEqual(previousStates, states)) {
      loadStates();
    }
    if (!isEqual(previousCompanies, companies)) {
      getCompanyList();
    }
    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);
    findDivHeight();
    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, [states]);

  const findDivHeight = () => {
    const div = document.getElementById("companyList");
    const form = document.getElementById("companyForm");
    const formHeight = form.getBoundingClientRect().height;
    div.style.height = window.innerHeight - (50 + formHeight + 75) + "px";
    div.style.display = "inline-block";
  };

  const loadStates = () => {
    setIsLoading(true);
    readStates(context.clockUrl, context.clockToken)
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          setStates(j.states);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    saveCompany();
  };

  const saveCompany = () => {
    if (!company.company_Name || company.company_Name.length == 0) {
      setHasErrors(true);
      return;
    }
    setIsLoading(true);
    createCompany(context.clockUrl, context.clockToken, company)
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          setCompany(initialCompany);
          toast.success("Company success");
          getCompanyList();
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const getCompanyList = () => {
    readCompanies(context.clockUrl, context.clockToken)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setCompanies(j.companies);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const handleChange = (e) => {
    setCompany({
      ...company,
      [e.target.name]: e.target.value
    });
  };

  const handleConfirmTextChange = (e) => {
    setConfirmText(e.target.value);
  };

  const compareDeleteText = () => {
    if (confirmText == "delete me") {
      setShowingDelete(false);
      handleDelete();
    } else {
      setError(confirmText + " does not match delete me");
    }
  };

  const handleEdit = (id) => {
    const currentCompany = companies.find((c) => c.company_Id === id);
    if (isValid(currentCompany)) {
      setCompany(currentCompany);
    } else {
      toast.error("Could not find your Company, sorry :(", {
        position: toast.POSITION.TOP_LEFT
      });
    }
  };

  const handleDelete = (id) => {
    if (companyid.length === 0) {
      setCompanyid(id);
      setShowingDelete(true);
      return;
    } else {
      // now we can actually delete this company
      deleteCompany(context.clockUrl, context.clockToken, companyid)
        .then((response) => {
          const j = response.data;
          if (j.error === 0) {
            toast.success("Your Company has been deleted");
            getCompanyList();
          } else {
            toast.error(j.msg, {
              position: toast.POSITION.TOP_LEFT
            });
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error("An error has occured processing your request", {
            position: toast.POSITION.TOP_LEFT
          });
        });
    }
  };

  return (
    <div className="clock-mobile">
      <div
        className="mobile-clock-header"

      >
        <ClockSidebar />
        <ConfirmDelete
          isShowing={showingDelete}
          hide={() => setShowingDelete(false)}
          confirmText={confirmText}
          setConfirmText={handleConfirmTextChange}
          compareDeleteText={compareDeleteText}
          error={error}
        />
        <div className="cc-main-body" style={{ padding: "5px" }}>
          <h3>Add Company</h3>
          {isLoading ? <Spinner /> : null}
          <NewCompanyForm
            handleSubmit={handleSubmit}
            company={company}
            handleChange={handleChange}
            hasErrors={hasErrors}
            states={states}
          />
          <CompanyList
            companies={companies}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        </div>
      </div>
    </div>
  );
};

CreateCompany.propTypes = {};

export default CreateCompany;
