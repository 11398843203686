import React, { useState, useRef, useEffect } from "react";
// import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import "./Tooltip.css";

const Tooltip = (props) => {
  const [displayTooltip, setDisplayTooltip] = useState(false);

  let internalStyle = {
    backgroundColor: "#fff",
    color: "#000",
    padding: "5px"
  };

  const tooltipRef = useRef();
  const triggerRef = useRef();

  useEffect(() => {
    if (props.style) {
      internalStyle = props.style;
    }
    tooltipRef.current.addEventListener("mouseleave", hideTooltip);
    triggerRef.current.addEventListener("mouseover", showTooltip);
    return () => {
      tooltipRef.current.removeEventListener("mouseleave", hideTooltip);
      triggerRef.current.removeEventListener("mouseover", showTooltip);
    };
  }, []);

  const showTooltip = () => {
    console.log("showing tooltip");
    setDisplayTooltip(true);
  };

  const hideTooltip = () => {
    setDisplayTooltip(false);
  };

  return (
    <span className="c5-tooltip-wrapper" ref={tooltipRef}>
      {displayTooltip ? (
        <div
          className={`c5-tooltip-bubble c5-tooltip-${props.position}`}
          style={{ opacity: displayTooltip ? 0.8 : 0 }}
        >
          <div className="c5-tooltip-message" style={internalStyle}>
            {props.message}
          </div>
        </div>
      ) : null}

      <span ref={triggerRef} className="c5-tooltip-trigger">
        {props.children}
      </span>
    </span>
  );
};

Tooltip.propTypes = {
  position: PropTypes.any,
  children: PropTypes.any,
  message: PropTypes.any,
  style: PropTypes.any
};

export default Tooltip;
