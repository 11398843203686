import React from "react";
import { render } from "react-dom";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.css";

import "./assets/css/nucleo-icons.css";
import "./assets/css/black-dashboard-pro-react.css";

import "flexmonster/flexmonster.css";

import "./index.css";

render(
  <Router>
    <App />
  </Router>,
  document.getElementById("app")
);
