import React from "react";
import PropTypes from "prop-types";
import { formatDate } from "../utils";

const PointHistoryMobile = ({
  context,
  header,
  activity,
  balance,
  handleBackButton
}) => {
  return (
    <React.Fragment>
      <div className="pa-top">
        <div className="pa-circle-container">
          <div className="pa-circle">
            <div className="pa-circle-month">
              {context.loyalty.previous
                ? context.loyalty.lastMonth
                : context.loyalty.currentMonth}
            </div>
            <div className="pa-circle-points">{balance}</div>
            <div className="pa-circle-end">Points</div>
          </div>
        </div>

        <div className="pa-header-details mt-3">
          <div className="pa-header-details-left">
            {header.length === 0 ? (
              <div className="pa-header-details-left-zero-points">0</div>
            ) : (
              header.map((record, index) => (
                <div key={index} className="pa-header-details-left-points">
                  {record.startingBalance}
                </div>
              ))
            )}
            <div className="pa-header-details-left-text">
              Starting
              <br />
              Balance
            </div>
          </div>
          <div className="pa-header-details-middle">
            {header.length === 0 ? (
              <div className="pa-header-details-middle-zero-points">0</div>
            ) : (
              header.map((record, index) => (
                <div key={index} className="pa-header-details-middle-points">
                  {record.totalAccrued}
                </div>
              ))
            )}

            <div className="pa-header-details-left-text">
              Points
              <br />
              Earned
            </div>
          </div>
          <div className="pa-header-details-right">
            {header.length === 0 ? (
              <div className="pa-header-details-right-zero-points">0</div>
            ) : (
              header.map((record, index) => (
                <div key={index} className="pa-header-details-right-points">
                  {record.totalRedeemed}
                </div>
              ))
            )}

            <div className="pa-header-details-right-text">
              Points
              <br />
              Redeemed
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="pa-history-h4">
        <h4>History</h4>
      </div>
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">Date</th>
            <th scope="col">Type</th>
            <th scope="col">Points</th>
          </tr>
        </thead>
        <tbody>
          {activity.length === 0 ? (
            <tr>
              <td>There are no records to display</td>
            </tr>
          ) : (
            activity.map((record, index) => (
              <tr key={index}>
                <td scope="row">{formatDate(record.date)}</td>
                <td scope="row">{record.type}</td>
                <td scope="row">{record.points}</td>
              </tr>
            ))
          )}
        </tbody>
      </table>
      <div className="pa-footer-back" onClick={handleBackButton}>
        <i className="far fa-caret-circle-left"></i>
      </div>
    </React.Fragment>
  );
};

PointHistoryMobile.propTypes = {
  context: PropTypes.object,
  header: PropTypes.array,
  activity: PropTypes.array,
  balance: PropTypes.number,
  handleBackButton: PropTypes.func
};

export default PointHistoryMobile;
