import React, { useState, useEffect, useContext } from "react";
import HeaderRB from "../../common/HeaderRb";
import Header from "../../Header/Header";
import { addDays, formatDate } from "../../utils";
import { ContextConfig } from "../../App";
import { debounce } from "../../fp";
import { chromeCart } from "../../api/CashierApi";
import { toast } from "react-toastify";
import DesktopCashierGrid from "./DesktopCashierGrid";
import Spinner from "../../common/Spinner";
import { isMobile } from "react-device-detect";
import "./Cashiers.css";

const Cashiers = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [reRender, setReRender] = useState(false);
  const [startDate, setStartDate] = useState(addDays(new Date(), -7));
  const [endDate, setEndDate] = useState(new Date());
  const [showDate, setShowDate] = useState(false);
  const [data, setData] = useState([]);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const context = useContext(ContextConfig);

  useEffect(() => {
    console.log("useEffect for Cashiers");
    if (!context.hasOwnProperty("endDate")) {
      setShowDate(true);
      return;
    }

    fetchChromeCart();

    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);

    findDivHeight();

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, [reRender, showDate]);

  const findDivHeight = () => {
    const navbar = document
      .getElementById("master-navbar")
      .getBoundingClientRect().height;
    const controls = document
      .getElementById("cashier-controls")
      .getBoundingClientRect().height;
    const usedSpace = navbar + controls;
    const remaining = window.innerHeight - usedSpace;

    const grid = document.getElementById("desktop-cashier-grid");
    grid.style.height = remaining + "px";
    grid.style.overflow = "auto";
  };

  const fetchChromeCart = () => {
    setIsLoading(true);
    let group = context.lastGroup;
    if (context.lastSearchType == "3") {
      group = context.lastStoreid;
    }
    let search = parseInt(context.lastSearchType) === 2 ? "Group" : "Stores";
    if (parseInt(context.lastSearchType) === 3) {
      search = "Store";
    }
    const config = {
      url: context.url,
      token: context.token,
      group: group,
      search: search,
      startDate: formatDate(context.startDate),
      endDate: formatDate(context.endDate)
    };

    console.log("config", config);

    chromeCart(config)
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          setData(j.items);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        toast.error("An error occurred processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  return (
    <React.Fragment>
      {isLoading && <Spinner />}
      <HeaderRB />
      <div id="cashier-controls">
        <div className="container-fluid">
          <h3>Cashier Statistics</h3>
          <Header
            reRender={reRender}
            setReRender={setReRender}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            setLoading={setIsLoading}
            showSingleStore={true}
          />

          {!isMobile ? (
            <div className="cashier-instructions">
              <p>
                The Icon in the Center of each Store represents the final
                Concern of each Store. In the case where the center Icon is
                purple, this tells you that the store has outliers and that the
                value of the Score indicator represents what the Stores Actual
                Score is and what the color of the center Icon would be if the
                store did not have any outliers.
              </p>
            </div>
          ) : null}
        </div>
      </div>
      <DesktopCashierGrid stores={data} />
    </React.Fragment>
  );
};

export default Cashiers;
