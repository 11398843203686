import React from "react";
import PropTypes from "prop-types";

const DesktopLookup = ({ users }) => {
  return (
    <form className="p-3">
      <h3>Reset a Users Password</h3>
      <div className="form-group">
        <select className="form-control">
          <option value={0}>Select User</option>
          {users.length === 0
            ? null
            : users.map(user => (
                <option key={user.id} value={user.id}>
                  {user.username}
                </option>
              ))}
        </select>
      </div>
    </form>
  );
};

DesktopLookup.propTypes = {
  users: PropTypes.array
};

export default DesktopLookup;
