/* eslint-disable no-case-declarations */
import * as actions from "../actions/actionTypes";
import { formatDate } from "../utils";

const initialState = {
  storeid: 0,
  departments: [],
  isLoadingDepartments: false,
  generalSettings: {},
  punches: [],
  punch: {},
  user: {},
  id: 0
};

const punchcardReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.PC_SET_STOREID:
      return {
        ...state,
        storeid: action.storeid
      };
    case actions.PC_SET_DEPARTMENTS:
      return {
        ...state,
        departments: action.departments
      };

    case actions.PC_SET_ISLOADING_DEPARTMENTS:
      return {
        ...state,
        isLoading: action.isLoading
      };
    case actions.PC_SET_GENERAL_SETTINGS:
      return {
        ...state,
        generalSettings: action.generalSettings
      };
    case actions.PC_SET_PUNCHES:
      return {
        ...state,
        punches: action.punches
      };
    case actions.PC_UPDATE_PUNCH: {
      const newPunch = {
        ...state.punch,
        [action.field]: action.value
      };
      const newArray = Array.from(state.punches);
      const index = newArray.findIndex((p) => p.id === newPunch.id);
      newArray.splice(index, 1);
      newArray.splice(index, 0, newPunch);

      return {
        ...state,
        punch: {
          ...state.punch,
          [action.field]: action.value
        },
        punches: newArray
      };
    }
    case actions.PC_CANCEL_EDIT:
      const newArray = Array.from(state.punches);
      const index = newArray.findIndex((p) => p.id === action.id);
      newArray.splice(index, 1);
      return {
        ...state,
        punches: newArray,
        punch: {}
      };
    case actions.PC_ADD_PUNCH:
      const newPunch = {
        punchDate: formatDate(new Date()),
        userid: state.user.userid,
        department: state.user.defaultDepartment,
        id: state.id + 1,
        comment: ""
      };
      return {
        ...state,
        punches: [newPunch, ...state.punches],
        punch: newPunch
      };
    case actions.PC_SET_USERINFO:
      return {
        ...state,
        user: action.user
      };
    case actions.PC_RESET_PUNCH:
      return {
        ...state,
        punch: {}
      };
    default:
      return state;
  }
};

export default punchcardReducer;
