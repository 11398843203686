import React, { useState, useEffect, useContext } from "react";
import { readCashierRequestRealTime } from "../api/widgetsApi";
import { ContextConfig } from "../App";
import { toast } from "react-toastify";
import HeaderRB from "../common/HeaderRb";
import Spinner from "../common/Spinner";
import { getAssignedStoresAsync } from "../api/StoreApi";
import { debounce } from "../fp";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import "./CashierRealTime.css";

const CashiersRealTime = () => {
  const [requests, setRequests] = useState([]);
  const [stores, setStores] = useState([]);
  const [currentStore, setCurrentStore] = useState(0);
  const [daysBack, setDaysBack] = useState(90);
  const [isLoadingCashiers, setIsLoadingCashiers] = useState(false);
  const [isLoadingStores, setIsLoadingStores] = useState(false);
  const [showWaiting, setShowWaiting] = useState(false);
  const [messages, setMessages] = useState([]);
  const [currentMsg, setCurrentMsg] = useState("");
  const [filteredRequests, setFilteredRequests] = useState([]);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const context = useContext(ContextConfig);

  useEffect(() => {
    loadStores();
    getRequests();

    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });

      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);
    findDivHeight();

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, []);

  useEffect(() => {
    if (requests.length > 0) {
      getMessages();
    }
  }, [requests]);

  const findDivHeight = () => {
    const navbar = document
      .getElementById("master-navbar")
      .getBoundingClientRect().height;
    const controls = document.getElementById("controls").getBoundingClientRect()
      .height;
    const usedSpace = navbar + controls + 20;
    const remaining = window.innerHeight - usedSpace;

    const widget = document.getElementById("body");
    widget.style.height = remaining + "px";
    widget.style.overflow = "auto";
  };

  const getRequests = () => {
    setIsLoadingCashiers(true);
    readCashierRequestRealTime(
      context.url,
      context.token,
      currentStore,
      daysBack,
      showWaiting
    )
      .then((response) => {
        setIsLoadingCashiers(false);
        const j = response.data;
        if (j.error === 0) {
          setRequests(j.items);
          setFilteredRequests(j.items);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setIsLoadingCashiers(false);
        console.log(err);
        toast.error("An Internal error has occured", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const handleStoreChange = (e) => {
    setCurrentStore(e.target.value);
  };

  const loadStores = () => {
    setIsLoadingStores(true);
    getAssignedStoresAsync(context.url, context.token)
      .then((response) => {
        setIsLoadingStores(false);
        const j = response.data;
        if (j.error === 0) {
          setStores(j.items);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setIsLoadingStores(false);
        console.log("", err);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const handleDaysBackChange = (e) => {
    setDaysBack(e.target.value);
  };

  const handleUpdate = () => {
    getRequests();
  };

  const handleCheckChange = (e) => {
    setShowWaiting(e.target.checked);
  };

  const getUserLevelDescription = (level) => {
    switch (level) {
      case "1":
        return "No POS";
      case "2":
        return "Cashier";
      case "3":
        return "Clerk";
      case "4":
        return "Assistant";
      case "5":
        return "Manager";
    }
  };

  const getMessages = () => {
    const newArray = [];
    requests.map((request) => {
      if (!newArray.includes(request.returnmessage)) {
        if (request.returnmessage.length > 0) {
          newArray.push(request.returnmessage);
        }
      }
    });

    setMessages(newArray);
  };

  const handleMsgChange = (e) => {
    setCurrentMsg(e.target.value);
    if (e.target.value == "0") {
      setFilteredRequests(requests);
    } else {
      const newArray = requests.filter(
        (r) => r.returnmessage === e.target.value
      );
      setFilteredRequests(newArray);
    }
  };

  const renderTooltip = (request) => {
    return (
      <Tooltip>
        <div>Short Name: {request.shortname}</div>
        <div>
          <div>Employee ID: {request.empid}</div>
          <div>User that added Cashier: {request.username}</div>
        </div>
      </Tooltip>
    );
  };

  return (
    <div>
      {isLoadingCashiers && <Spinner />}
      {isLoadingStores && <Spinner />}
      <HeaderRB />
      <div className="container" id="controls">
        <div className="request-title">Cashier Requests</div>
        <div className="row hourly-d-header justify-content-center">
          <div className="col-xs-12 col-sm-6 col-lg-3">
            <label>Select a Store</label>
            <select
              className="form-control h-select"
              onChange={handleStoreChange}
              value={currentStore || 0}
            >
              <option value="0">All Stores</option>
              {stores.length === 0
                ? null
                : stores.map((store) => (
                    <option key={store.storeid} value={store.storeid}>
                      {store.store_Name}
                    </option>
                  ))}
            </select>
          </div>
          <div className="col-xs-12 col-sm-3 col-lg-2">
            <label>DaysBack:</label>
            <input
              type="number"
              className="form-control"
              value={daysBack}
              onChange={handleDaysBackChange}
            />
          </div>
          <div
            className="col-xs-12 col-sm-6 col-lg-3 justify-content-center"
            style={{
              paddingTop: "35px",
              display: "flex",
              justifyContent: "center"
            }}
          >
            <label>Only show waiting requests</label>
            <input
              type="checkbox"
              style={{ opacity: 1, visibility: "visible", marginLeft: "5px" }}
              onChange={handleCheckChange}
              value={showWaiting}
            />
          </div>
          <div
            className="col-xs-12 col-sm-6 col-lg-3 justify-content-center"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <button
              className="btn btn-outline-light"
              onClick={handleUpdate}
              style={{ marginTop: "25px" }}
            >
              Update
            </button>
          </div>
        </div>
        <div className="row justify-content-center mt-3">
          <div className="col-3" style={{ textAlign: "right" }}>
            <label style={{ marginTop: "10px" }}>Filter by Message</label>
          </div>
          <div className="col-6">
            {messages.length > 0 ? (
              <select
                className="form-control"
                value={currentMsg}
                onChange={handleMsgChange}
              >
                <option value="0">All Messages</option>
                {messages.map((msg, i) => (
                  <option key={`m-${i}`} value={msg}>
                    {msg}
                  </option>
                ))}
              </select>
            ) : null}
          </div>
          <div className="col-3">&nbsp;</div>
        </div>
      </div>
      <div className="container request-body" id="body">
        {filteredRequests.length === 0 ? (
          <div className="empty-requets-list">
            There are currently no Cashier Requests to view
          </div>
        ) : (
          <table className="table table-striped">
            <thead>
              <tr>
                <th style={{ textAlign: "center" }}>Deployed</th>
                <th>New #</th>
                <th>Name</th>
                <th>Posted</th>
                <th>Employee</th>
                <th>Level</th>
                <th style={{ textAlign: "center" }}>Add/Remove</th>
              </tr>
            </thead>
            <tbody>
              {filteredRequests.map((request, index) => (
                <OverlayTrigger
                  key={`eert-${index}`}
                  placement="top"
                  overlay={renderTooltip(request)}
                >
                  <tr key={`er-${index}`}>
                    <td style={{ textAlign: "center" }}>
                      <input
                        type="checkbox"
                        value={request.deployed}
                        readOnly={true}
                        checked={request.deployed == "1" ? true : false}
                        style={{ opacity: "1", visibility: "visible" }}
                      />
                    </td>
                    <td>{request.employee_number}</td>
                    <td>{request.store_name}</td>
                    <td>{request.post_date}</td>
                    <td>
                      {request.firstname} {request.lastname}
                    </td>
                    <td>{getUserLevelDescription(request.userlevel)}</td>
                    <td style={{ textAlign: "center" }}>
                      <input
                        type="checkbox"
                        value={request.addorremove}
                        readOnly={true}
                        checked={request.addorremove == "1" ? true : false}
                        style={{ opacity: "1", visibility: "visible" }}
                      />
                    </td>
                    <td>{request.returnmessage}</td>
                    {}
                  </tr>
                </OverlayTrigger>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default CashiersRealTime;
