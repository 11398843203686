import React, { useEffect } from "react";
import PropTypes from "prop-types";
import "./CashierNavigation.css";
import ReactTooltip from "react-tooltip";

const CashierNavigation = ({ step, goBack }) => {
  useEffect(() => {}, []);

  const handleClick = (currentStep) => {
    goBack(currentStep);
  };

  return (
    <div className="cash-nav-body">
      <span
        className="cash-nav-stores"
        data-tip="Go Back to Statistics"
        onClick={() => handleClick(0)}
      >
        <i className="fas fa-store-alt"></i>
      </span>
      {parseInt(step) < 1 ? null : (
        <span
          className="cash-nav-cashiers"
          data-tip="Go Back to Cashiers"
          onClick={() => handleClick(1)}
        >
          <i className="far fa-cash-register"></i>
        </span>
      )}
      {parseInt(step) < 2 ? null : (
        <span
          className="cash-nav-totalizers"
          data-tip="Go Back to Totalizers"
          onClick={() => handleClick(2)}
        >
          <i className="fas fa-funnel-dollar"></i>
        </span>
      )}
      {parseInt(step) < 3 ? null : (
        <span
          className="cash-nav-transactions"
          data-tip="Go Back to Transactions"
          onClick={() => handleClick(3)}
        >
          <i className="far fa-shopping-basket"></i>
        </span>
      )}

      <ReactTooltip place="bottom" />
    </div>
  );
};

CashierNavigation.propTypes = {
  step: PropTypes.string,
  goBack: PropTypes.func
};

export default CashierNavigation;
