import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./Transactions.css";
import { convertAmount, formatDate } from "../../utils";
import { Redirect, withRouter } from "react-router-dom";
import CashierNavigation from "../CashierNavigation";
import { debounce } from "../../fp";

const MobileTransactions = ({ data, context, ...props }) => {
  const [goBack, setGoBack] = useState(false);
  const [step, setStep] = useState(2);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  useEffect(() => {
    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);

    findDivHeight();

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, []);

  const findDivHeight = () => {
    const navbar = document
      .getElementById("master-navbar")
      .getBoundingClientRect().height;
    const controls = document
      .getElementById("d-trans-controls")
      .getBoundingClientRect().height;
    const spaceUsed = navbar + controls + 20;
    const remaining = window.innerHeight - spaceUsed;

    const widgets = document.getElementById("d-trans-body");
    widgets.style.height = remaining + "px";
    widgets.style.overflow = "auto";
  };

  const handleGoback = (currentStep) => {
    setStep(currentStep);
    setGoBack(true);
  };

  const handleClick = (trans) => {
    context.transactionDetails = trans;
    props.history.push("/transaction");
  };
  return (
    <div className="container">
      {goBack && step === 2 && <Redirect to="/totalizers" />}
      {goBack && step === 1 && <Redirect to="/flyout" />}
      {goBack && step === 0 && <Redirect to="/cashiers" />}

      <div id="d-trans-controls">
        <div className="mtrans-m-store">
          {context.selectedRecord.store_Name}
        </div>
        <div className="mtrans-m-cashier">{context.cashier.cashier}</div>
        <div className="mtrans-m-date">{formatDate(context.endDate)}</div>

        <CashierNavigation step="2" goBack={handleGoback} />
      </div>

      <div className="mtrans-contents" id="d-trans-body">
        {data.map((trans, index) => (
          <div
            className="mtrans-m-trans"
            key={index}
            onClick={() => handleClick(trans)}
          >
            <div className="mtrans-m-one">
              <div className="mtrans-m-trans-number">{trans.f1032}</div>
              <div className="mtrans-m-tlz-total">
                Tlz Total: {convertAmount(trans.tlzTotal)}
              </div>
            </div>
            <div className="mtrans-m-two">
              <div className="mtrans-m-tlz-name">{context.totalizer.f1039}</div>
              <div className="mtrans-m-trans-total">
                Trans Total: {convertAmount(trans.total)}
              </div>
            </div>
            <div className="mtrans-m-three">
              <div className="mtrans-m-time">Time: {trans.transactionTime}</div>
              <div className="mtrans-m-term">Term: {trans.f1057}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

MobileTransactions.propTypes = {
  data: PropTypes.array,
  context: PropTypes.object,
  history: PropTypes.object
};

export default withRouter(MobileTransactions);
