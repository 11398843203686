import React, { useState, useEffect, useContext } from "react";
import ClockDesktopMenu from "../ClockDesktopMenu";
// import FloatingSidebar from "../../Dashboard/FloatingSidebar";
import ScheduleHeader from "./ScheduleHeader";
import { ContextConfig } from "../../App";
import { getWeekStart } from "../../api/StoreApi";
import { isValid, formatDate } from "../../utils";
import { toast } from "react-toastify";
import Spinner from "../../common/Spinner";
import { readUsersFromStore } from "../../api/UsersApi";
import ModalShift from "./ModalShift";
import { loadDepartments } from "../../api/departmentApi";
import Moment from "moment";
import {
  createShift,
  updateShift,
  getStoreSchedules
} from "../../api/scheduleApi";
import Calendar from "./Calendar";
import "./Schedule.css";

const initialShiftState = {
  storeid: null,
  employeeId: null,
  startDate: null,
  endDate: null,
  startTime: null,
  endTime: null,
  department: null,
  departmentDescription: null,
  userid: 0
};

const Schedule = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [showShiftCreator, setShowShiftCreator] = useState(false);
  const [weekStart, setWeekstart] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [employee, setEmployee] = useState({});
  const [shiftDay, setShiftDay] = useState(-1);
  const [departments, setDepartments] = useState([]);
  const [store, setStore] = useState("");
  const [newShift, setNewShift] = useState(initialShiftState);
  const [dept, setDept] = useState(-1);
  const [errors, setErrors] = useState({});
  const [reRender, setReRender] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [storeSchedules, setStoreSchedules] = useState([]);
  const [filteredStoreSchedules, setFilteredStoreSchedules] = useState([]);
  const [systemReady, setSystemReady] = useState(false);
  const [reloadSchedule, setReloadSchedule] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [hasLoaded, setHasLoaded] = useState(false);
  const [weekOffset, setWeekOffset] = useState(0);
  const [defaultDepartment, setDefaultDepartment] = useState(0);
  const [deptFilterType, setDeptFilterType] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);

  const context = useContext(ContextConfig);

  const days = [0, 1, 2, 3, 4, 5, 6];

  useEffect(() => {
    if (isValid(store)) {
      fetchWeekStart();
      fetchUsers();
      fetchDepartments();
    } else {
      setStore(context.lastClockStore);
    }
  }, [store]);

  // useEffect(() => {
  //   if (isValid(store)) {
  //     fetchWeekStart();
  //     fetchUsers();
  //     fetchDepartments();
  //   } else {
  //     setStore(context.lastClockStore);
  //   }
  // }, [errors]);

  useEffect(() => {
    console.log("useEffect for systemReady");
    if (systemReady) {
      setReloadSchedule(!reloadSchedule);
    }
  }, [systemReady]);

  useEffect(() => {
    console.log("useEffect for reloadSchedule");
    getSchedules();
  }, [reloadSchedule]);

  useEffect(() => {
    console.log("useEffect for weekstart");
    getSchedules();
  }, [weekStart]);

  useEffect(() => {
    console.log("useEffect for lastClockStore");
    fetchDepartments();
    getSchedules();
  }, [context.lastClockStore]);

  useEffect(() => {
    if (defaultDepartment != 0) {
      if (deptFilterType == true) {
        filterShifts();
      } else {
        filterEmployees();
      }
    } else {
      setFilteredUsers(users);
      setFilteredStoreSchedules(storeSchedules);
    }
  }, [defaultDepartment, deptFilterType]);

  const fetchWeekStart = () => {
    // if (hasLoaded) return;
    setIsLoading(true);
    getWeekStart(context.clockUrl, context.clockToken, store)
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          if (isValid(j.weekstart)) {
            const d = Moment(j.weekstart, "MM/DD/YYYY");

            if (parseInt(context.settings.defaultScheduleWeek) >= 0) {
              const defaultWeekOffset =
                weekOffset + context.settings.defaultScheduleWeek;
              setWeekOffset(defaultWeekOffset);
              const newd = d.add(defaultWeekOffset, "w");
              mergeDates(newd);
            } else {
              mergeDates(d);
            }
          }
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const fetchUsers = () => {
    setIsLoading(true);
    readUsersFromStore(context.clockUrl, context.clockToken, store)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          if (j.users.length > 0) {
            const nonClockUsers = j.users.filter((u) => u.userLevel != "1");
            setUsers(nonClockUsers);
            setFilteredUsers(nonClockUsers);
          } else {
            toast.error("No users were returned", {
              position: toast.POSITION.TOP_LEFT
            });
          }
          setHasLoaded(true);
        } else {
          setUsers([]);

          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setUsers([]);
        console.log(err);
        setIsLoading(false);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const fetchDepartments = () => {
    setIsLoading(true);
    loadDepartments(
      context.clockUrl,
      context.clockToken,
      context.lastClockStore
    )
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          setDepartments(j.departments);
        } else {
          setIsLoading(false);
          setDepartments([]);
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        setDepartments([]);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const handleDateClick = (day, e) => {
    setEmployee(e);
    setShiftDay(day);
    const d = Moment(weekStart, "MM/DD/YYYY");
    const nd = d.add(day, "d");
    let defaultDepartment = 0;
    let departmentDescription = "";
    if (e.defaultDepartment) {
      defaultDepartment = e.defaultDepartment;
      departmentDescription = findDepartmentDescription(e.defaultDepartment);
    }

    setNewShift({
      ...newShift,
      startDate: new Date(nd.format("MM/DD/YYYY")),
      endDate: new Date(nd.format("MM/DD/YYYY")),
      department: defaultDepartment,
      departmentDescription
    });
    setShowShiftCreator(true);
  };

  const closeModal = () => {
    setShowShiftCreator(false);
  };

  const findDepartmentDescription = (id) => {
    if (id < 0) return;
    const record = departments.filter((d) => d.id === parseInt(id));
    return record[0].description;
  };

  const findEmployeeShifts = (userid) => {
    if (!storeSchedules) return;
    const user = storeSchedules.filter((s) => s.user.userid === userid);
    if (user.length > 0) {
      return user[0].schedule;
    } else {
      return [];
    }
  };

  const hasDuplicateShift = (shift) => {
    let result = false;
    const shifts = findEmployeeShifts(shift.userid);
    if (shifts.length == 0) {
      return false;
    } else {
      shifts.forEach((existingShift) => {
        if (
          new Date(existingShift.startTime).getTime() ===
          new Date(shift.startTime).getTime()
        ) {
          // start times are the same
          if (
            new Date(existingShift.endTime).getTime() ===
            new Date(shift.endTime).getTime()
          ) {
            result = true;
          }
        }
      });
    }
    return result;
  };

  const hasEarlyOverlappingShift = (shift) => {
    let result = false;
    const shifts = findEmployeeShifts(shift.userid);
    if (shifts.length === 0) {
      return false;
    } else {
      shifts.forEach((existingShift) => {
        if (
          new Date(existingShift.startTime).getTime() <
            new Date(shift.startTime).getTime() &&
          new Date(existingShift.endTime).getTime() >
            new Date(shift.startTime).getTime()
        ) {
          result = true;
          return;
        }
      });
    }

    return result;
  };

  const hasLateOverlappingShift = (shift) => {
    let result = false;
    const shifts = findEmployeeShifts(shift.userid);
    if (shifts.length === 0) {
      return false;
    } else {
      shifts.forEach((existingShift) => {
        if (
          new Date(shift.startTime).getTime() <
            new Date(existingShift.startTime).getTime() &&
          new Date(shift.endTime).getTime() >
            new Date(existingShift.startTime).getTime()
        ) {
          result = true;
          return;
        }
      });
    }

    return result;
  };

  const handleSubmitShift = (e, day, newShift) => {
    if (!isFormValid()) return;
    if (isEditing) {
      sendUpdate();
      return;
    }

    if (!isValid(e.employeeId)) {
      toast.error("You need a valid Employee ID to create a shift", {
        position: toast.POSITION.TOP_LEFT
      });
      return;
    }

    const shift = {
      storeid: store,
      startDate: Moment(newShift.startDate, "MM/DD/YYYY").format("MM/DD/YYYY"),
      endDate: Moment(newShift.endDate, "MM/DD/YYYY").format("MM/DD/YYYY"),
      startTime:
        Moment(newShift.startDate, "MM/DD/YYYY").format("MM/DD/YYYY") +
        " " +
        Moment(newShift.startTime, "MM/DD/YYYY hh:mm:ss").format("LT"),
      endTime:
        Moment(newShift.endDate, "MM/DD/YYYY").format("MM/DD/YYYY") +
        " " +
        Moment(newShift.endTime, "MM/DD/YYYY").format("LT"),
      punchOutDate: Moment(newShift.endDate, "MM/DD/YYYY").format("MM/DD/YYYY"),
      employeeId: e.employeeId,
      userid: e.userid,
      department: newShift.department,
      departmentDescription: findDepartmentDescription(newShift.department)
    };

    // now make sure the shift out is greater than the shift in
    if (
      new Date(shift.startTime).getTime() > new Date(shift.endTime).getTime()
    ) {
      toast.error(
        `The starting of the shift must be prior to the ending of the Shift. The shift Start is ${shift.startTime} and the end of the shift is ${shift.endTime}.`,
        {
          position: toast.POSITION.TOP_LEFT
        }
      );
      return;
    }

    // now let's check for a duplicate shift and stop that from happening
    if (hasDuplicateShift(shift)) {
      toast.error(
        "There is already a shift for this employee using these times",
        {
          position: toast.POSITION.TOP_LEFT
        }
      );
      return;
    }

    if (hasEarlyOverlappingShift(shift)) {
      toast.error("Your startTime is in the middle of another shift", {
        position: toast.POSITION.TOP_LEFT
      });
      return;
    }

    if (hasLateOverlappingShift(shift)) {
      toast.error("Your end time is in the middle of another shift", {
        position: toast.POSITION.TOP_LEFT
      });
      return;
    }

    createShift(context.clockUrl, context.clockToken, shift)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setNewShift(initialShiftState);
          // now we need to reload our punches
          //setNewShiftTrigger(e.userid);
          closeModal();
          setReRender(!reRender);
          getSchedules();
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const sendUpdate = () => {
    // new need to update the description of the department in case it changed
    const departmentDescription = findDepartmentDescription(
      newShift.department
    );
    newShift.startDate = Moment(
      formatDate(newShift.startDate),
      "MM/DD/YYYY"
    ).format("MM/DD/YYYY");

    newShift.endDate = Moment(
      formatDate(newShift.endDate),
      "MM/DD/YYYY"
    ).format("MM/DD/YYYY");

    newShift.startTime =
      Moment(formatDate(newShift.startDate), "MM/DD/YYYY").format(
        "MM/DD/YYYY"
      ) +
      " " +
      Moment(new Date(newShift.startTime), "MM/DD/YYYY hh:mm:ss").format("LT");
    newShift.endTime =
      Moment(new Date(newShift.endDate), "MM/DD/YYYY").format("MM/DD/YYYY") +
      " " +
      Moment(new Date(newShift.endTime), "MM/DD/YYYY").format("LT");
    newShift.punchOutDate = Moment(
      new Date(newShift.endDate),
      "MM/DD/YYYY"
    ).format("MM/DD/YYYY");
    newShift.departmentDescription = departmentDescription;

    if (
      new Date(newShift.startTime).getTime() >
      new Date(newShift.endTime).getTime()
    ) {
      toast.error(
        `The starting of the shift must be prior to the ending of the Shift. The shift Start is ${newShift.startTime} and the end of the shift is ${newShift.endTime}.`,
        {
          position: toast.POSITION.TOP_LEFT
        }
      );
      return;
    }

    if (hasEarlyOverlappingShift(newShift)) {
      toast.error("Your startTime is in the middle of another shift", {
        position: toast.POSITION.TOP_LEFT
      });
      return;
    }

    if (hasLateOverlappingShift(newShift)) {
      toast.error("Your end time is in the middle of another shift", {
        position: toast.POSITION.TOP_LEFT
      });
      return;
    }

    updateShift(context.clockUrl, context.clockToken, newShift)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setNewShift(initialShiftState);
          setIsEditing(false);
          closeModal();
          setReRender(!reRender);
          setReloadSchedule(!reloadSchedule);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An error has occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const mergeDates = (newd) => {
    setWeekstart(newd.format("MM/DD/YYYY"));
    setStartDate(new Date(newd.format("MM/DD/YYYY")));
    const ed = newd.add(6, "d");
    setEndDate(new Date(ed.format("MM/DD/YYYY")));
    setSystemReady(true);
  };

  const handlePreviousWeek = () => {
    const d = Moment(weekStart, "MM/DD/YYYY");
    const nd = d.add(-1, "w");
    setWeekOffset(weekOffset - 1);
    mergeDates(nd);
  };

  const handleNextWeek = () => {
    const d = Moment(weekStart, "MM/DD/YYYY");
    const nd = d.add(1, "w");
    setWeekOffset(weekOffset + 1);
    mergeDates(nd);
  };

  const handleDeptChange = (e) => {
    setDept(e.target.value);
    setNewShift({
      ...newShift,
      department: e.target.value,
      departmentDescription: findDepartmentDescription(e.target.value)
    });
  };

  const handleStartDateChange = (e) => {
    setNewShift({
      ...newShift,
      startDate: e
    });
  };

  const handleEndDateChange = (e) => {
    setNewShift({
      ...newShift,
      endDate: e
    });
  };

  const handleStartTimeChange = (e) => {
    setNewShift({
      ...newShift,
      startTime: e
    });
  };

  const handleEndTimeChange = (e) => {
    setNewShift({
      ...newShift,
      endTime: e
    });
  };

  const getSchedules = () => {
    console.log("getting schedules");
    if (typeof store === "undefined" || store === null) return;
    if (store.length === 0) return;
    if (formatDate(startDate) == formatDate(endDate)) return;
    getStoreSchedules(
      context.clockUrl,
      context.clockToken,
      store,
      formatDate(startDate),
      formatDate(endDate)
    )
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          const nonClockUserSchedules = j.schedules.filter(
            (s) => s.user.userLevel != "1"
          );
          setStoreSchedules(nonClockUserSchedules);
          setFilteredStoreSchedules(nonClockUserSchedules);
          // setStoreSchedules(j.schedules);
        } else {
          setStoreSchedules([]);
          toast.error(j.msg, {
            position: toast.POSITION_TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An Internal error occured", {
          position: toast.POSITION_TOP_LEFT
        });
      });
  };

  const isFormValid = () => {
    const _errors = {};
    if (!newShift.startDate) _errors.startDate = "Field is required";
    if (!newShift.endDate) _errors.endDate = "Field is required";
    if (!newShift.startTime) _errors.startTime = "Field is required";
    if (!newShift.endTime) _errors.endTime = "Field is required";
    // if (!newShift.department) _errors.department = "Field is required";

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  };

  const editShift = (shift) => {
    setIsEditing(true);
    setShowShiftCreator(true);
    setNewShift(shift);
  };

  const handleDepartmentChange = (e) => {
    setDefaultDepartment(e.target.value);
  };

  const handleDeptCheckChange = (e) => {
    setDeptFilterType(e.target.checked);
  };

  const getDistinctUsers = (filtered) => {
    const newArray = [];
    filtered.map((s) => {
      if (!newArray.includes(s.user.userid)) {
        if (s.schedule.length > 0) {
          newArray.push(s.user.userid);
        }
      }
    });
    return newArray;
  };

  const filterByDefaultDepartment = (shift) => {
    if (shift.department == defaultDepartment) {
      return shift;
    }
  };

  const filterScheduleByExistingShift = (schedule) => {
    const newArray = schedule.schedule.filter(filterByDefaultDepartment);
    if (newArray.length > 0) {
      return newArray;
    }
  };

  const filterShifts = () => {
    // const newArray = storeSchedules.filter((s) =>
    //   s.schedule.filter((s) => s.department == defaultDepartment)
    // );
    const newArray = storeSchedules.filter(filterScheduleByExistingShift);
    const distinctUsers = getDistinctUsers(newArray);
    const newUsers = [];
    users.forEach((user) => {
      if (distinctUsers.includes(user.userid)) {
        newUsers.push(user);
      }
    });

    setFilteredUsers(newUsers);
    setFilteredStoreSchedules(newArray);
  };

  const filterEmployees = () => {
    const newArray = users.filter(
      (u) => u.defaultDepartment == defaultDepartment
    );
    const distinctUsers = [];
    newArray.map((r) => {
      if (!distinctUsers.includes(r.userid)) {
        distinctUsers.push(r.userid);
      }
    });
    const newSchedules = [];
    storeSchedules.forEach((s) => {
      if (distinctUsers.includes(s.user.userid)) {
        newSchedules.push(s);
      }
    });
    setFilteredUsers(newArray);
    setFilteredStoreSchedules(newSchedules);
  };

  const updateFilteredEmployeeShifts = (shifts) => {
    if (shifts.length === 0) return;
    let hasChanges = false;
    const index = filteredStoreSchedules.findIndex(
      (s) => s.user.userid == shifts[0].userid
    );

    //const newArray = Array.from(filteredStoreSchedules);
    const newArray = filteredStoreSchedules.slice(0);

    const recordToUpdate = {
      user: newArray[index].user,
      pto: newArray[index].pto,
      schedule: []
    };
    const newSchedule = newArray[index].schedule.filter(
      (r) => r.department == defaultDepartment
    );
    recordToUpdate.schedule = newSchedule;
    newArray.splice(index, 1);
    if (
      recordToUpdate.schedule.length !=
      filteredStoreSchedules[index].schedule.length
    ) {
      hasChanges = true;
      newArray.splice(index, 0, recordToUpdate);
    }
    if (hasChanges) {
      setFilteredStoreSchedules(newArray);
    }
  };

  return (
    <div className="d-schedule">
      {isLoading && <Spinner />}
      {
        <ModalShift
          isShowing={showShiftCreator}
          day={shiftDay}
          employee={employee}
          handleSubmitShift={handleSubmitShift}
          departments={departments}
          hide={closeModal}
          newShift={newShift}
          dept={dept}
          setDept={handleDeptChange}
          handleStartDateChange={handleStartDateChange}
          handleEndDateChange={handleEndDateChange}
          handleStartTimeChange={handleStartTimeChange}
          handleEndTimeChange={handleEndTimeChange}
          errors={errors}
        />
      }
      <ClockDesktopMenu />
      {/* <FloatingSidebar /> */}
      <div className="schedule-header-wrapper" id="schedule-header-wrapper">
        <div className="schedule-header-bar1">
          <div
            style={{
              width: "100%",
              marginTop: "20px",
              textAlign: "center",
              fontSize: "1.3rem",
              fontWeight: "bold"
            }}
          >
            Scheduling
          </div>
          <ScheduleHeader
            startDate={startDate}
            endDate={endDate}
            handlePreviousWeek={handlePreviousWeek}
            handleNextWeek={handleNextWeek}
            store={store}
            setStore={setStore}
            setReloadSchedule={setReloadSchedule}
            reloadSchedule={reloadSchedule}
            departments={departments}
            handleDepartmentChange={handleDepartmentChange}
            handleDeptCheckChange={handleDeptCheckChange}
            defaultDepartment={defaultDepartment}
            deptFilterType={deptFilterType}
          />
        </div>
      </div>
      <div className="calendar">
        <div>
          <Calendar
            settings={context.settings}
            weekStart={weekStart}
            startDate={startDate}
            endDate={endDate}
            days={days}
            storeSchedules={filteredStoreSchedules}
            setStoreSchedules={setFilteredStoreSchedules}
            users={filteredUsers}
            context={context}
            reloadSchedule={reloadSchedule}
            setReloadSchedule={setReloadSchedule}
            handleDateClick={handleDateClick}
            editShift={editShift}
            store={store}
            defaultDepartment={defaultDepartment}
            deptFilterType={deptFilterType}
            updateFilteredEmployeeShifts={updateFilteredEmployeeShifts}
          />
        </div>
      </div>
    </div>
  );
};

export default Schedule;
