import React from "react";
import PropTypes from "prop-types";

const SortColumn = ({
  state,
  dispatch,
  title,
  dispatchType,
  sortField,
  dbField
}) => {
  const handleClick = () => {
    dispatch({ type: dispatchType, sortField: dbField });
  };
  return (
    <div className="punchlist-column-header">
      <span>{title}</span>
      <span onClick={handleClick}>
        {state[sortField] ? (
          <span style={{ cursor: "pointer" }}>
            <i className="fas fa-sort-up"></i>
          </span>
        ) : (
          <span style={{ cursor: "pointer" }}>
            <i className="fas fa-sort-down"></i>
          </span>
        )}
      </span>
    </div>
  );
};

SortColumn.propTypes = {
  state: PropTypes.object,
  dispatch: PropTypes.func,
  title: PropTypes.string,
  dispatchType: PropTypes.string,
  sortField: PropTypes.any,
  dbField: PropTypes.string
};

export default SortColumn;
