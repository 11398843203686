import React from "react";
import "./OpenPunchesSpinner.css";

const OpenPunchesSpinner = () => {
  return (
    <div className="lmaskop">
      <div className="fixedop"></div>
    </div>
  );
};

export default OpenPunchesSpinner;
