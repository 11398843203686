import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { NavLink } from "react-router-dom";
import "../src/common/Header.css";

const PageNotFound = () => {
  const activeStyle = { color: "#F15B2A" };
  return (
    <React.Fragment>
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Navbar.Brand href="/" className="logo-offset">
          <img src="img/homepage.svg" alt="MikTo" />
          Mikto
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nf">
          <Nav className="ml-auto">
            <NavLink to="/" className="menu-item" activeStyle={activeStyle}>
              Login
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <h3 className="mt-4 text-center">Oops! Page not found.</h3>
      <p className="text-center p-4 mt-4">
        We are sorry, but you are trying to access a route which either you do
        not have permission to view or that does not exist. We are sorry for
        your inconvenience. Please click the Login link to login to the
        application.
      </p>
    </React.Fragment>
  );
};

export default PageNotFound;
