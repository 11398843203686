import React from "react";
import "./HappyLoader.css";

const HappyLoader = () => {
  return (
    <div className="happy-window">
      <div className="happy-wrapper">
        <div className="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default HappyLoader;
