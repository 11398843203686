import { loadDepartments } from "../../api/departmentApi";
import { getGeneralSettings } from "../../api/scheduleApi";
import { getUserPunchesByDates } from "../../api/punchApi";
import { getUsersInfo } from "../../api/UsersApi";
import { toast } from "react-toastify";
import { formatDate } from "../../utils";

import * as actions from "../../actions/actionTypes";

const fetchDepartments = (state, dispatch, context) => {
  if (typeof state.storeid === "undefined" || state.storeid === null) return;
  dispatch({ type: actions.PC_SET_ISLOADING_DEPARTMENTS, isLoading: true });
  loadDepartments(context.clockUrl, context.clockToken, context.lastClockStore)
    .then((response) => {
      dispatch({
        type: actions.PC_SET_ISLOADING_DEPARTMENTS,
        isLoading: false
      });
      const j = response.data;
      if (j.error == "0") {
        dispatch({
          type: actions.PC_SET_DEPARTMENTS,
          departments: j.departments
        });
      } else {
        dispatch({ type: actions.PC_SET_DEPARTMENTS, departments: [] });
        toast.error(j.msg, {
          position: toast.POSITION.TOP_LEFT
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: actions.PC_SET_ISLOADING_DEPARTMENTS,
        isLoading: false
      });
      console.log(err);
      dispatch({ type: actions.PC_SET_DEPARTMENTS, departments: [] });
      toast.error("An internal Error occured", {
        position: toast.POSITION.TOP_LEFT
      });
    });
};

const generalSettings = (state, dispatch, context) => {
  getGeneralSettings(context.clockUrl, context.clockToken, state.storeid)
    .then((response) => {
      const j = response.data;
      if (j.error === 0) {
        dispatch({
          type: actions.PC_SET_GENERAL_SETTINGS,
          generalSettings: j.settings
        });
      } else {
        toast.error(j.msg, {
          position: toast.POSITION.TOP_LEFT
        });
      }
    })
    .catch((err) => {
      console.log(err);
      toast.error("An Internal error occured", {
        position: toast.POSITION.TOP_LEFT
      });
    });
};

const userPunches = (state, dispatch, context) => {
  getUserPunchesByDates(
    context.clockUrl,
    context.clockToken,
    context.userid,
    formatDate(context.startDate),
    formatDate(context.endDate),
    state.storeid,
    false
  )
    .then((response) => {
      const j = response.data;
      if (j.error === 0) {
        dispatch({ type: actions.PC_SET_PUNCHES, punches: j.punches });
      } else {
        toast.error(j.msg, {
          position: toast.POSITION.TOP_LEFT
        });
      }
    })
    .catch((err) => {
      console.log(err);
      toast.error("An Internal error occured", {
        position: toast.POSITION.TOP_LEFT
      });
    });
};

const userInfo = (state, dispatch, context) => {
  getUsersInfo(context.clockUrl, context.clockToken)
    .then((response) => {
      const j = response.data;
      if (j.error === 0) {
        dispatch({ type: actions.PC_SET_USERINFO, user: j.user });
      } else {
        toast.error(j.msg, {
          position: toast.POSITION.TOP_LEFT
        });
      }
    })
    .catch((err) => {
      console.log(err);
      toast.error("An Internal error occured", {
        position: toast.POSITION.TOP_LEFT
      });
    });
};

export { fetchDepartments, generalSettings, userPunches, userInfo };
