import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ToggleSwitch from "../../Groups/ToggleSwitch";
import { debounce } from "../../fp";
import { isBrowser } from "react-device-detect";
import Tooltip from "../../Tooltip/Tooltip";

const Storelist = ({
  stores,
  handleToggle,
  handleEmployeeIdChange,
  handleSync,
  context
}) => {
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  useEffect(() => {
    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);

    findDivHeight();

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, []);

  const RenderTooltip = () => {
    return (
      <div
        style={{
          backgroundColor: "#000",
          color: "#fff",
          minWidth: "300px",
          padding: "10px",
          opacity: "1"
        }}
      >
        <h3>Instructions on Sync</h3>
        <p>
          If there is an Employee ID present before you set the link toggle
          button to YES, then the Employee Id will automatically piggy back with
          the Request to the server.
        </p>
        <p>
          If you have already linked the store to the User and want to add an
          Employee ID, then after you have entered the Employee ID, press the
          Sync Button to send this Employee ID to the server to be saved.
        </p>
      </div>
    );
  };

  const findDivHeight = () => {
    if (!isBrowser) return;
    const navbar = document
      .getElementById("master-navbar")
      .getBoundingClientRect().height;

    const controls = document.getElementById("controls").getBoundingClientRect()
      .height;

    const usedSpace = navbar + controls + 50;
    const remaining = window.innerHeight - usedSpace;

    const widget = document.getElementById("link-list");
    widget.style.height = remaining + "px";
    widget.style.width = "100%";
    widget.style.overflow = "auto";
  };

  return (
    <div id="link-list">
      {stores.length === 0 ? (
        <div className="links-storelist-empty">
          There are no Stores to display
        </div>
      ) : (
        <table className="table table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Store</th>
              <th style={{ textAlign: "center" }}>Assign</th>
              {context.settings.uniqueEmployeeIds == "1" ? (
                <th>Employee Id for this Store</th>
              ) : null}
              {context.settings.uniqueEmployeeIds == "1" ? (
                <th style={{ textAlign: "center" }}>
                  <Tooltip message={<RenderTooltip />} position={"bottom"}>
                    Employee Id for this Store{" "}
                    <i
                      style={{ marginLeft: "10px" }}
                      className="far fa-question-circle"
                    ></i>
                  </Tooltip>
                </th>
              ) : null}
            </tr>
          </thead>
          <tbody>
            {stores.map((store, index) => (
              <tr key={`s-${index}`}>
                <td>{store.storeNumber}</td>
                <td>{store.storeName}</td>
                <td style={{ textAlign: "center" }}>
                  <ToggleSwitch
                    id={store.id}
                    handleClick={handleToggle}
                    active={store.assigned === 1 ? true : false}
                  />
                </td>
                {context.settings.uniqueEmployeeIds == "1" ? (
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      value={store.employeeId}
                      onChange={(e) => handleEmployeeIdChange(e, store)}
                    />
                  </td>
                ) : null}
                {context.settings.uniqueEmployeeIds == "1" ? (
                  <td style={{ textAlign: "center" }}>
                    <Tooltip message={"Sync"} position={"right"}>
                      <button
                        className="btn btn-secondary"
                        style={{
                          marginLeft: "10px",
                          cursor: "pointer"
                        }}
                        onClick={() => handleSync(store.id)}
                      >
                        <i className="fas fa-sync"></i>
                      </button>
                    </Tooltip>
                  </td>
                ) : null}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

Storelist.propTypes = {
  stores: PropTypes.array,
  handleToggle: PropTypes.func,
  handleEmployeeIdChange: PropTypes.func,
  employeeId: PropTypes.string,
  handleSync: PropTypes.func,
  context: PropTypes.object
};

export default Storelist;
