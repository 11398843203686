import React from "react";
import PropTypes from "prop-types";

const CustomerDesktop = ({ userInfo, isPhone }) => {
  return (
    <table className="table">
      <thead>
        <tr>
          <th scope="col">{isPhone ? "Customer" : "Phone #"}</th>
          <th scope="col">Changed</th>
          <th scope="col">Name</th>
          <th scope="col">Email</th>
        </tr>
      </thead>
      <tbody>
        {userInfo.map(user => (
          <tr key={user.f1148}>
            <td>{isPhone ? user.f1148 : user.f1172}</td>
            <td>{user.f253}</td>
            <td>{`${user.f1149} ${user.f1150}`}</td>
            <td>{user.f1573}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

CustomerDesktop.propTypes = {
  userInfo: PropTypes.array,
  isPhone: PropTypes.bool
};

export default CustomerDesktop;
