import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { ModalDatePicker, Picker } from "c5-react-library";

const Pickers = ({
  time,
  handleDateSelect,
  handleDateCancel,
  showDate,
  pickerData,
  handleToggleSelectionType,
  handleTypeSelect,
  isShowing,
  showGroups,
  groupData,
  handleGroupSelect,
  handleToggleGroups
}) => {
  return (
    <React.Fragment>
      <ModalDatePicker
        value={time}
        isOpen={showDate}
        onSelect={handleDateSelect}
        onCancel={handleDateCancel}
      />

      <Picker
        isShowing={showGroups}
        hide={handleToggleGroups}
        header="groups"
        store={groupData}
        displayField="group_name"
        valueField="id"
        handleSelect={handleGroupSelect}
        useEffect={useEffect}
        useState={useState}
        useRef={useRef}
        debug={false}
        debugListeners={false}
      />

      <Picker
        isShowing={isShowing}
        hide={handleToggleSelectionType}
        header="stores"
        store={pickerData}
        displayField="name"
        valueField="id"
        handleSelect={handleTypeSelect}
        useEffect={useEffect}
        useState={useState}
        useRef={useRef}
        debug={false}
        debugListeners={false}
      />
    </React.Fragment>
  );
};

Pickers.propTypes = {
  time: PropTypes.any,
  handleDateSelect: PropTypes.func,
  handleDateCancel: PropTypes.func,
  showDate: PropTypes.bool,
  pickerData: PropTypes.array,
  handleToggleSelectionType: PropTypes.func,
  handleTypeSelect: PropTypes.func,
  isShowing: PropTypes.bool,
  showGroups: PropTypes.bool,
  groupData: PropTypes.array,
  handleGroupSelect: PropTypes.func,
  handleToggleGroups: PropTypes.func
};

export default Pickers;
