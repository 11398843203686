import React from "react";
import PropTypes from "prop-types";
import { BrowserView, MobileView } from "react-device-detect";
import "./CompanyList.css";

const CompanyList = ({ companies, handleEdit, handleDelete }) => {
  return (
    <div className="company-list" id="companyList">
      <BrowserView>
        <div className="container">
          {companies.length === 0 ? (
            <div>No records were returned</div>
          ) : (
            <table
              className="table table-striped"
              style={{ width: "100%", margin: "auto" }}
            >
              <thead>
                <tr>
                  <th>&nbsp;</th>
                  <th>Name</th>
                  <th>Address</th>
                  <th>City</th>
                  <th>State</th>
                  <th>Zip</th>
                  <th>Phone</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {companies.map((record, index) => (
                  <tr key={index}>
                    <td
                      className="cl-icons"
                      onClick={() => handleEdit(record.company_Id)}
                    >
                      <i className="fal fa-edit"></i>
                    </td>
                    <td>{record.company_Name}</td>
                    <td>{record.company_Address}</td>
                    <td>{record.company_City}</td>
                    <td>{record.company_State}</td>
                    <td>{record.company_Zip}</td>
                    <td>{record.company_Phone}</td>
                    <td
                      className="cl-icons"
                      onClick={() => handleDelete(record.company_Id)}
                    >
                      <i className="far fa-trash-alt"></i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </BrowserView>
      <MobileView>
        <div className="container">
          {companies.length === 0 ? (
            <div>No records were returned</div>
          ) : (
            <table
              className="table table-striped"
              style={{ width: "100%", margin: "auto" }}
            >
              <thead>
                <tr>
                  <th>&nbsp;</th>
                  <th>Name</th>
                  <th>St</th>
                  <th>Phone</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {companies.map((record, index) => (
                  <tr key={index}>
                    <td
                      className="cl-icons"
                      onClick={() => handleEdit(record.company_Id)}
                    >
                      <i className="fal fa-edit"></i>
                    </td>
                    <td>{record.company_Name}</td>
                    <td>{record.company_State}</td>
                    <td>{record.company_Phone}</td>

                    <td
                      className="cl-icons"
                      onClick={() => handleDelete(record.company_Id)}
                    >
                      <i className="far fa-trash-alt"></i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </MobileView>
    </div>
  );
};

CompanyList.propTypes = {
  companies: PropTypes.array.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired
};

export default CompanyList;
