import React from "react";
import PropTypes from "prop-types";
import InputMask from "react-input-mask";

const NewCompanyForm = ({
  handleSubmit,
  company,
  handleChange,
  hasErrors,
  states
}) => {
  return (
    <form onSubmit={handleSubmit} id="companyForm">
      <div className="cc-form-item">
        <input
          type="text"
          className="form-control"
          name="company_Name"
          placeholder="Company Name"
          onChange={handleChange}
          value={company.company_Name}
        />
      </div>
      {hasErrors === true ? (
        <div className="alert alert-danger">This field is required</div>
      ) : null}
      <div className="cc-form-item">
        <input
          type="text"
          className="form-control"
          name="company_Address"
          placeholder="Address"
          onChange={handleChange}
          value={company.company_Address}
        />
      </div>
      <div className="cc-form-item">
        <input
          type="text"
          className="form-control"
          name="company_City"
          placeholder="City"
          onChange={handleChange}
          value={company.company_City}
        />
      </div>
      <div className="cc-form-item">
        <select
          className="form-control"
          name="company_State"
          placeholder="State"
          onChange={handleChange}
          value={company.company_State || ""}
        >
          {states.length === 0 ? (
            <option value="0">No States returned</option>
          ) : (
            states.map((s, index) => (
              <option key={index} value={s.abbr}>
                {s.name}
              </option>
            ))
          )}
        </select>
      </div>

      <div className="cc-form-item">
        <input
          className="form-control"
          type="number"
          name="company_Zip"
          placeholder="Zip"
          onChange={handleChange}
          value={company.company_Zip}
        />
      </div>

      <div className="cc-form-item">
        <label htmlFor="company_name">Phone Number</label>
        <InputMask
          mask="9(999)-999-9999"
          type="tel"
          name="company_Phone"
          placeholde="Phone Number"
          className="form-control"
          onChange={handleChange}
          value={company.company_Phone}
        />
      </div>

      <div className="cc-form-item" style={{ textAlign: "center" }}>
        <button
          type="submit"
          style={{ minWidth: "100px" }}
          className="btn btn-outline-dark"
        >
          Save
        </button>
      </div>
    </form>
  );
};

NewCompanyForm.propTypes = {
  handleSubmit: PropTypes.func,
  company: PropTypes.object,
  handleChange: PropTypes.func,
  hasErrors: PropTypes.bool,
  states: PropTypes.array
};

export default NewCompanyForm;
