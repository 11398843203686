import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import "./SecurityDialog.css";

const SecurityDialog = ({
  isShowing,
  hide,
  questions,
  answer,
  setAnswer,
  question,
  setQuestion,
  handleAnswerQuestion
}) =>
  isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <div className="modal-security-overlay" />
          <div
            className="modal-security-wrapper"
            aria-modal
            aria-hidden
            tabIndex={-1}
            role="dialog"
          >
            <div className="modal-security">
              <div className="modal-security-header">
                <span className="modal-security-confirm-title">
                  Set Security Question
                </span>
                <span className="modal-security-close-box">
                  <button
                    type="button"
                    className="modal-security-close-button"
                    data-dismiss="modal"
                    aria-label="close"
                    onClick={hide}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </span>
              </div>
              <div className="modal-security-body p-4">
                {questions.length > 0 ? (
                  <div>
                    <div className="row w-100 justify-content-center">
                      <div className="col-12 align-items-center">
                        <select
                          type="text"
                          className="form-control"
                          value={question}
                          onChange={setQuestion}
                        >
                          <option value="0">Select a question</option>
                          {questions.map((question, i) => (
                            <option key={`sq-${i}`} value={question.id}>
                              {question.question}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {question > 0 ? (
                      <div>
                        <div className="row w-100 justify-content-center mt-3">
                          <input
                            type="text"
                            className="form-control"
                            value={answer}
                            onChange={(e) => setAnswer(e.target.value)}
                            placeholder="Enter Answer"
                          />
                        </div>
                        {answer.length > 0 ? (
                          <div
                            className="row w-100 justify-content-center mt-3"
                            style={{ textAlign: "center" }}
                          >
                            <div className="flex-dt col-12 align-items-center">
                              <button
                                type="button"
                                style={{ marginLeft: "10px" }}
                                className="btn btn-primary"
                                onClick={() => handleAnswerQuestion()}
                              >
                                Submit
                              </button>
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={hide}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <div>There were no questions to display</div>
                )}
              </div>
            </div>
          </div>
        </React.Fragment>,
        document.body
      )
    : null;

SecurityDialog.propTypes = {
  isShowing: PropTypes.bool,
  hide: PropTypes.runc,
  questions: PropTypes.array,
  answer: PropTypes.string,
  setAnswer: PropTypes.func,
  handleAnswerQuestion: PropTypes.func
};

export default SecurityDialog;
