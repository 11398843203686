import React, { useState, useEffect, useContext } from "react";
import { ContextConfig } from "../../App";
import { getReportList, getCsvData, testReport } from "../../api/basketApi";
import { toast } from "react-toastify";
import { debounce } from "../../fp";
import { Row, Col, Card, CardBody, CardTitle, CardFooter } from "reactstrap";
import HeaderRB from "../../common/HeaderRb";
import DatePicker from "react-datepicker";
import ReactTooltip from "react-tooltip";
import { withRouter } from "react-router-dom";
import Viewer from "./Viewer";
import PropTypes from "prop-types";
import { readGroupAsync } from "../../api/GroupApi";
import { getAssignedStoresAsync } from "../../api/StoreApi";
import TypeHeadDropDown from "../../common/TypeAheadDropDown";
import { generatePdf1 } from "./pdf1";
import { data } from "./StartQuery";
import "./ReportList.css";

const ReportList = (props) => {
  const [reports, setReports] = useState([]);
  const [wheres, setWheres] = useState([]);
  const [selectedReport, setSelectedReport] = useState({});
  const [date1, setDate1] = useState(new Date());
  const [date2, setDate2] = useState(new Date());
  const [reportName, setReportName] = useState("");
  const [showReport, setShowReport] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [contents, setContents] = useState("");
  const [stores, setStores] = useState([]);
  const [userGroups, setUserGroups] = useState([]);
  const [selectedStore, setSelectedStore] = useState("");
  const [reload, setReload] = useState(false);
  const [top, settop] = useState("");
  const [selectedGroup, setSelectedGroup] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [params, setParams] = useState({});
  const [css, setCss] = useState([]);
  const [reportRows, setReportRows] = useState([]);
  const [reportColumns, setReportColumns] = useState([]);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const context = useContext(ContextConfig);

  useEffect(() => {
    readGroupStores();
    readStoreList();
    listReports();
    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);

    findDivHeight();

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, []);

  useEffect(() => {}, [wheres, reload]);

  useEffect(() => {
    if (showReport) {
      const reportOptions = {};
      if (wheres && wheres.length > 0) {
        wheres.map((where) => {
          if (where.field == "f254") {
            if (where.operator == "6") {
              reportOptions.startDate = where.value;
              reportOptions.endDate = where.value2;
            } else {
              reportOptions.startDate = where.value;
            }
          }
        });
      }
      if (selectedGroup && selectedGroup > 0) {
        const g = userGroups.find((g) => g.id == selectedGroup);
        if (g) {
          reportOptions.group = g.group_name;
        }
      }
      if (selectedStore && selectedStore > 0) {
        const s = stores.find((s) => (s.storeid = selectedStore));
        if (s) {
          reportOptions.store = s.store_name;
        }
      }

      const dataTypes = [];
      reportColumns.map((col) => {
        const originalColumn = data.find((c) => c.label == col);
        if (originalColumn) {
          dataTypes.push(originalColumn.type);
        }
      });
      selectedReport.css = css;

      generatePdf1(
        selectedReport,
        reportColumns,
        reportRows,
        css,
        reportOptions,
        dataTypes
      );
    }
  }, [showReport]);

  const findDivHeight = () => {
    const navbar = document
      .getElementById("master-navbar")
      .getBoundingClientRect().height;
    const controls = document.getElementById("controls").getBoundingClientRect()
      .height;
    const usedSpace = navbar + controls + 20;
    const remaining = window.innerHeight - usedSpace;

    const body = document.getElementById("body");
    body.style.height = remaining + "px";
    body.style.overflow = "auto";
  };

  const listReports = () => {
    getReportList(context.url, context.basketApi)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setReports(j.reports);
        } else {
          setReports([]);
          toast.warn(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An Internal error occured", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const selectReport = (report) => {
    console.log("report selected", report);
    setSelectedReport(report);
    const wheres = report.wheres;
    console.log("wheres", wheres);
    setWheres(wheres);
    setReportName(report.reportName);
  };

  const exportReport = () => {
    getCsvData(
      context.url,
      selectedReport.id,
      context.basketApi,
      date1,
      date2,
      top,
      selectedStore,
      selectedGroup
    )
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          var uriContent =
            "data:application/octec-stream," + encodeURIComponent(j.csv);
          save(uriContent, "dataExport.csv");
        } else {
          toast.warn(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An Internal error occurred", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const save = (uri, filename) => {
    var link = document.createElement("a");
    if (typeof link.download == "string") {
      document.body.appendChild(link);
      link.download = filename;
      link.href = uri;
      link.click();
    }
  };

  // const launchReport = () => {
  //   runQuery(
  //     context.url,
  //     selectedReport.id,
  //     context.basketApi,
  //     date1,
  //     date2,
  //     top,
  //     selectedStore,
  //     selectedGroup
  //   )
  //     .then((response) => {
  //       const j = response.data;
  //       if (j.error === 0) {
  //         setContents(j.html);
  //         setCss(j.css);
  //         setShowReport(true);
  //       } else {
  //         setShowReport(false);
  //         toast.warn(j.msg, {
  //           position: toast.POSITION.TOP_LEFT
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       setShowReport(false);
  //       console.log(err);
  //       toast.error("An Internal error occured", {
  //         position: toast.POSITION.TOP_LEFT
  //       });
  //     });
  // };

  const reportTest = () => {
    testReport(
      context.url,
      selectedReport.id,
      context.basketApi,
      date1,
      date2,
      top,
      selectedStore,
      selectedGroup
    )
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setCss(j.css);
          setReportColumns(j.cols);
          setReportRows(j.rows);
          setShowReport(true);
        } else {
          setShowReport(false);
          toast.warn(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setShowReport(false);
        console.log(err);
        toast.error("An Internal error occured", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const editQuery = (query) => {
    console.log("editing query", query);
    props.history.push("/queryBuilder?id=" + query.id);
  };

  const closeReport = () => {
    setShowReport(false);
  };

  const readGroupStores = () => {
    readGroupAsync(context.url, context.token)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setUserGroups(j.groups);
        } else {
          setUserGroups([]);
          toast.warn(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setUserGroups([]);
        console.log(err);
        toast.error("An internal error occured", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const readStoreList = () => {
    getAssignedStoresAsync(context.url, context.token)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setStores(j.items);
        } else {
          setStores([]);
          toast.warn(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setStores([]);
        toast.error("An Internal error occured", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const handleStoreChange = (e) => {
    setSelectedStore(e.storeid);
    setReload(!reload);
  };

  return (
    <div>
      <HeaderRB />
      <Viewer
        isShowing={showReport}
        hide={closeReport}
        contents={contents}
        reportName={selectedReport.reportName}
        showPrint={true}
        print={print}
      />
      <div className="container">
        <div id="controls">
          <h3>Custom Reports</h3>
          <div className="row justify-content-center mt-2 mb-4">
            <div
              className="col-12"
              style={{
                fontSize: "1.3rem",
                fontWeight: "bold",
                width: "100%",
                textAlign: "center"
              }}
            >
              {reportName}
            </div>
            {wheres.length > 0 ? (
              <React.Fragment>
                {wheres.map((where, i) => (
                  <React.Fragment key={`rddp-${i}`}>
                    {where.field === "f254" && where.operator === "6" ? (
                      <div
                        className="row w-100 justify-content-space-between"
                        style={{ textAlign: "center" }}
                      >
                        <div className="col-5">
                          <label>Start Date: &nbsp;</label>
                          <DatePicker
                            selected={date1}
                            onChange={(e) => setDate1(e)}
                          />
                        </div>
                        <div className="col-5">
                          <label>End Date: &nbsp;</label>
                          <DatePicker
                            selected={date2}
                            onChange={(e) => setDate2(e)}
                          />
                        </div>
                      </div>
                    ) : null}
                    {where.field === "storeid" ||
                    where.field === "store_number" ? (
                      <div
                        className="row w-100 justify-content-center mt-3"
                        style={{ textAlign: "center" }}
                      >
                        <div className="col-5">
                          <TypeHeadDropDown
                            placeholder={"Select Store"}
                            data={stores}
                            setSelection={handleStoreChange}
                            value={parseInt(selectedStore)}
                            keyValue={"storeid"}
                            displayValue={"store_Name"}
                            label={"Select Store"}
                            labelColor={"#fff"}
                            backgroundColor={"transparent"}
                            reload={reload}
                          />
                        </div>
                      </div>
                    ) : null}
                    {where.field === "group" ? (
                      <div
                        className="row w-100 justify-content-center mt-3"
                        style={{ textAlign: "center" }}
                      >
                        <div className="col-5">
                          <select
                            className="form-control"
                            value={selectedGroup}
                            onChange={(e) => setSelectedGroup(e.target.value)}
                          >
                            <option value="0">Select a Group</option>
                            {userGroups.map((group, i) => (
                              <option key={`gs-${i}`} value={group.id}>
                                {group.group_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    ) : null}
                  </React.Fragment>
                ))}
              </React.Fragment>
            ) : null}
          </div>
          {reportName.length > 0 ? (
            <div
              className="row justify-content-center"
              style={{ textAlign: "center", marginBottom: "20px" }}
            >
              <div className="col-4">
                <button className="btn btn-primary" onClick={reportTest}>
                  Launch Report
                </button>
              </div>
              <div className="col-4">
                <button
                  style={{ marginLeft: "10px" }}
                  className="btn btn-info"
                  onClick={exportReport}
                >
                  Export CSV
                </button>
              </div>

              <div
                className="col-4"
                style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
              >
                <div style={{ marginTop: "10px" }}>
                  <label>Select Top N rows: </label>
                </div>
                <div>
                  <input
                    type="number"
                    min="0"
                    className="form-control"
                    value={top}
                    onChange={(e) => settop(e.target.value)}
                  />
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <div id="body">
          {reports.length > 0 ? (
            <Row className="justify-content-center">
              {reports.map((report, i) => (
                <Col
                  key={`cr-${i}`}
                  lg="3"
                  md="6"
                  style={{ cursor: "pointer" }}
                  onClick={() => selectReport(report)}
                >
                  <Card>
                    <ReactTooltip />
                    <CardBody>
                      <Row>
                        <Col xs="12">
                          <div className="banner-store-name">
                            <CardTitle tag="h3">{report.reportName}</CardTitle>
                            <hr className="card-divider" />
                          </div>
                        </Col>
                        <Col xs="12">{report.description}</Col>
                        <Col
                          xs="12"
                          className="justify-content-center d-flex"
                          style={{ marginTop: "10px", cursor: "pointer" }}
                          onClick={() => editQuery(report)}
                        >
                          <i
                            data-top="Edit Query"
                            className="far fa-edit"
                            style={{ fontSize: "1.2rem" }}
                          ></i>
                        </Col>
                        <Col
                          xs="12"
                          className="mt-2 justify-content-center"
                          style={{ color: "limegreen", textAlign: "center" }}
                        >
                          Created by: {report.username}
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter>
                      <div>Created: {report.createDate}</div>
                    </CardFooter>
                  </Card>
                </Col>
              ))}
            </Row>
          ) : (
            <div className="empty">There are no reports to display</div>
          )}
        </div>
      </div>
    </div>
  );
};

ReportList.propTypes = {
  history: PropTypes.object
};

export default withRouter(ReportList);
