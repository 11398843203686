import React from "react";
import PropTypes from "prop-types";
import TextInput from "../common/TextInput";
import SelectField from "../common/SelectField";
import MobileStorePicker from "../common/MobileStorePicker";
import { userLevels } from "../utils";

function MobileCashierForm(props) {
  return (
    <form>
      <h3>{props.title}</h3>

      <MobileStorePicker
        sendError={props.handleMobileError}
        onChange={props.onChange}
        name="storeid"
        error={props.errors.storeid}
      />

      <TextInput
        type="text"
        label="First Name:"
        id="txtFirstname"
        name="firstname"
        value={props.employee.firstname}
        error={props.errors.firstname}
        placeholder="First Name"
        onChange={props.onChange}
      />

      <TextInput
        type="text"
        label="Last Name"
        id="txtLastname"
        name="lastname"
        value={props.employee.lastname}
        placeholder="Last Name"
        onChange={props.onChange}
      />

      <TextInput
        type="text"
        label="Short Name"
        id="txtShortname"
        name="shortname"
        value={props.employee.shortname}
        placeholder="Short Name"
        onChange={props.onChange}
      />

      <SelectField
        id="combolevel"
        name="level"
        label="User Level"
        value={props.employee.level || ""}
        error={props.errors.level}
        onChange={props.onChange}
        emptyMsg="Select Level"
        data={userLevels}
        valueField="id"
        displayField="name"
      />

      <SelectField
        id="comboDepartment"
        name="department"
        label="Department"
        value={props.employee.department || ""}
        onChange={props.onChange}
        error={props.errors.department}
        emptyMsg="Select Department"
        valueField="department"
        displayField="department_Description"
        data={props.departments.filter((d) => {
          return d.department_Description.length > 0;
        })}
      />

      <TextInput
        type="number"
        label="Employee Id"
        id="txtEmployeeId"
        name="empid"
        value={props.employee.empid}
        error={props.errors.empid}
        placeholder="Employee Id"
        onChange={props.onChange}
      />

      <div
        className="form-check mx-auto"
        style={{ width: "100%", textAlign: "center" }}
      >
        <input
          className="form-check-input"
          type="checkbox"
          id="checkExpedite"
          name="expedite"
          value={props.employee.expedite}
          onChange={props.onChange}
          style={{ opacity: "1", visibility: "visible" }}
        />
        <label className="form-check-label" htmlFor="checkExpedite">
          Expedite
        </label>
      </div>
      <div
        className="form-group mt-3"
        style={{ textAlign: "center", opacity: "1", visibility: "visible" }}
      >
        <button
          type="submit"
          className="btn btn-outline-dark"
          onClick={props.onSubmit}
          disabled={props.formValid}
        >
          Submit
        </button>
      </div>
    </form>
  );
}

MobileCashierForm.propTypes = {
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  employee: PropTypes.object.isRequired,
  stores: PropTypes.array,
  departments: PropTypes.array,
  formValid: PropTypes.bool,
  handleMobileError: PropTypes.func,
  errors: PropTypes.object
};

MobileCashierForm.defaultProps = {
  stores: []
};

export default MobileCashierForm;
