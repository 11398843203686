import React from "react";
import PropTypes from "prop-types";
import EntryButton from "./EntryButton";

const EntryButtons = (props) => {
  return (
    <div className="row clock-button-body">
      <div className="m-auto clock-button-body-main">
        <div className="d-flex row mt-3 justify-content-between clock-button-row">
          <EntryButton value="C" handleButtonClick={props.handleButtonClick} />
          <EntryButton
            value="B"
            disabled={props.backspace}
            handleButtonClick={props.handleButtonClick}
          />
          <EntryButton
            value="E"
            disabled={props.enter}
            handleButtonClick={props.handleButtonClick}
          />
        </div>
        <div className="d-flex row mt-3 justify-content-between clock-button-row">
          <EntryButton value="7" handleButtonClick={props.handleButtonClick} />
          <EntryButton value="8" handleButtonClick={props.handleButtonClick} />
          <EntryButton value="9" handleButtonClick={props.handleButtonClick} />
        </div>
        <div className="d-flex row mt-3 justify-content-between clock-button-row">
          <EntryButton value="4" handleButtonClick={props.handleButtonClick} />
          <EntryButton value="5" handleButtonClick={props.handleButtonClick} />
          <EntryButton value="6" handleButtonClick={props.handleButtonClick} />
        </div>
        <div className="d-flex row mt-3 justify-content-between clock-button-row">
          <EntryButton value="1" handleButtonClick={props.handleButtonClick} />
          <EntryButton value="2" handleButtonClick={props.handleButtonClick} />
          <EntryButton value="3" handleButtonClick={props.handleButtonClick} />
        </div>
        <div className="d-flex row mt-3 justify-content-between clock-button-row">
          <EntryButton value="" handleButtonClick={props.handleButtonClick} />
          <EntryButton value="0" handleButtonClick={props.handleButtonClick} />
          <EntryButton value="" handleButtonClick={props.handleButtonClick} />
        </div>
      </div>
    </div>
  );
};

EntryButtons.propTypes = {
  handleButtonClick: PropTypes.func,
  backspace: PropTypes.bool,
  enter: PropTypes.bool
};

export default EntryButtons;
