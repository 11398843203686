import React, { useState, useEffect } from "react";
import HeaderRB from "../common/HeaderRb";
import GroupPicker from "./GroupPicker";
import CreateGroup from "./CreateGroup";
import GroupList from "./GroupList";
import { debounce } from "../fp";

const Groups = () => {
  const [group, setGroup] = useState("");
  const [data, setData] = useState(false);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  useEffect(() => {
    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });

      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);
    findDivHeight();

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, [group, data]);

  const findDivHeight = () => {
    const header = document.getElementById("master-navbar");
    const groupControls = document.getElementById("group-controls");

    const windowPadding =
      header.getBoundingClientRect().height +
      groupControls.getBoundingClientRect().height +
      90;
    const remainingSpace = window.innerHeight - windowPadding;

    const list = document.getElementById("desktop-group-list");
    list.style.height = remainingSpace + "px";
  };

  const groupCallback = (group) => {
    setGroup(group);
  };

  const createCallback = () => {
    setData(!data);
  };

  return (
    <React.Fragment>
      <HeaderRB />
      <div className="container-fluid">
        <h3>Manage your Groups</h3>
        <div className="row" id="group-controls">
          <div className="col-md-12 col-lg-4">
            <CreateGroup createCallback={createCallback} updateState={data} />
          </div>

          <div className="col-md-10 col-lg-6 my-2 mx-5">
            <GroupPicker groupCallback={groupCallback} updateState={data} />
          </div>
          <div className="col-md-6 col-lg-4"></div>
        </div>
        <GroupList group={group} />
      </div>
    </React.Fragment>
  );
};

export default Groups;
