import React from "react";
import PropTypes from "prop-types";

const RolesCopy = (props) => {
  return (
    <div>
      <div
        className="form-inline justify-content-center"
        style={{ width: "100%", marginTop: "25px" }}
      >
        <div className="form group">
          <div className="row justify-content-center mb-2">
            <div className="col-12">
              <div className="form-check">
                <span>
                  <input
                    style={{
                      opacity: "1",
                      visibility: "visible",
                      position: "relative"
                    }}
                    type="checkbox"
                    className="form-check-input"
                    name="roleCopy"
                    onChange={props.handleCheckChange}
                  />
                </span>
                <span
                  style={{
                    marginLeft: "10px",
                    marginBottom: "5px",
                    marginTop: "5px"
                  }}
                >
                  <label htmlFor="checkCopy">Copy from Another User?</label>
                </span>
              </div>
            </div>
            {props.copy ? (
              <React.Fragment>
                <div className="row justify-content-center mb-2">
                  <div className="col-12 justify-content-center">
                    <select
                      className="form-control ml-3 mb-2"
                      name="copyRole"
                      onChange={props.setCopyUser}
                      style={{ marginTop: "10px" }}
                    >
                      <option value="0">Select User to copy from</option>
                      {props.users.length > 0
                        ? props.users.map((user, index) => (
                            <option
                              key={`copy-user-${index}`}
                              value={user.userid}
                            >
                              {user.username}
                            </option>
                          ))
                        : null}
                    </select>

                    <button
                      className="btn btn-outline-dark ml-3"
                      onClick={props.handleCopyRoles}
                    >
                      Copy
                    </button>
                  </div>
                </div>
              </React.Fragment>
            ) : null}
          </div>
        </div>
      </div>
      <div style={{ width: "90%", textAlign: "center", color: "red" }}>
        {props.warning}
      </div>
      {props.warning.length > 0 ? (
        <div style={{ width: "100%", textAlign: "center", padding: "20px" }}>
          <input
            type="checkbox"
            className="form-check-input"
            name="checkLevel"
            onChange={props.handleCheckUserLevelChange}
          />
          <label htmlFor="checkLevel">Update User</label>
        </div>
      ) : null}
    </div>
  );
};

RolesCopy.propTypes = {
  handleCopyRoles: PropTypes.func,
  handleCheckChange: PropTypes.func,
  copy: PropTypes.bool,
  setCopyUser: PropTypes.func,
  users: PropTypes.array,
  warning: PropTypes.string,
  handleCheckUserLevelChange: PropTypes.func
};

export default RolesCopy;
