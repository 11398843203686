import { isValid, formatDate } from "../../utils";
import { getGeneralSettings } from "../../api/scheduleApi";
import { createPunch, getUserPunches } from "../../api/punchApi";
import { getUserMobileStatus } from "../../api/UsersApi";
import { toast } from "react-toastify";

function UserStatus(
  setIsLoading,
  isMobile,
  context,
  setCurrentStore,
  setLinks,
  setStatus
) {
  setIsLoading(true);
  // if (isMobile) {
  getUserMobileStatus(context.clockUrl, context.clockToken)
    .then((response) => {
      setIsLoading(false);
      const j = response.data;
      if (j.error === 0) {
        // here we need to see if this user is assigned to multiple stores or not
        // if not, then we need to kick off the general settings so the user can get their buttons
        if (j.links.length === 1) {
          setCurrentStore(j.links[0].storeid);
        } else if (j.links.length == 0) {
          toast.error("There are no stores linked to your User", {
            position: toast.POSITION.TOP_LEFT
          });
        }
        setLinks(j.links);
        setStatus(j.status);
      } else {
        toast.error(j.msg, {
          position: toast.POSITION.TOP_LEFT
        });
      }
    })
    .catch((err) => {
      setIsLoading(false);
      console.log(err);
      toast.error("An error occurred processing your request", {
        position: toast.POSITION.TOP_LEFT
      });
    });
  // } else {
  //   getUserStatus(context.clockUrl, context.clockToken)
  //     .then((response) => {
  //       setIsLoading(false);
  //       const j = response.data;
  //       if (j.error === 0) {
  //         console.log(j);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       setIsLoading(false);
  //     });
  // }
}

function loadGeneralSettings(
  currentStore,
  setIsLoading,
  context,
  setGeneralSettings,
  setPunches
) {
  if (!isValid(currentStore) || currentStore == "") {
    // if we dont have a current store, there is no need to send a request off
    return;
  }
  setIsLoading(true);
  getGeneralSettings(context.clockUrl, context.clockToken, currentStore)
    .then((response) => {
      setIsLoading(false);
      const j = response.data;
      if (j.error === 0) {
        context.generalSettings = j.settings;
        setGeneralSettings(j.settings);

        // now that we have the general settings for the appropriate store,
        // we can take the users current status and based on the settings, we can decide what kink of button that they need to see
      } else if (j.error === 3) {
        setGeneralSettings(j.settings);
        toast.error("There are no General Settings for this Store", {
          position: toast.POSITION.TOP_LEFT
        });
        setPunches([]);
      } else {
        toast.error(j.msg, {
          position: toast.POSITION.TOP_LEFT
        });
        setGeneralSettings([]);
        setPunches([]);
      }
    })
    .catch((err) => {
      setIsLoading(false);
      setGeneralSettings([]);
      setPunches([]);
      console.log(err);
      toast.error("An error occured processing your request", {
        position: toast.POSITION.TOP_LEFT
      });
    });
}

function loadPunches(
  currentStore,
  setIsLoading,
  context,
  setPunches,
  setGeneralSettings,
  generalSettings,
  setTotal
) {
  const punchDate = new Date();
  if (!isValid(currentStore)) {
    return;
  }
  setIsLoading(true);
  getUserPunches(
    context.clockUrl,
    context.clockToken,
    context.userid,
    formatDate(punchDate),
    currentStore
  )
    .then((response) => {
      setIsLoading(false);
      const j = response.data;
      if (j.error === 0) {
        setPunches(j.punches);
        setTotal(j.totalHours);
        if (j.generalSettings) {
          if (!isValid(generalSettings)) {
            setGeneralSettings(j.generalSettings);
          }
        }
      } else {
        toast.error(j.msg, {
          position: toast.POSITION.TOP_LEFT
        });
      }
    })
    .catch((err) => {
      console.log(err);
      setIsLoading(false);
      toast.error("An error occured processing your request", {
        position: toast.POSITION.TOP_LEFT
      });
    });
}

function createClockPunch(
  setShowLinkOptions,
  setIsLoading,
  context,
  currentStore,
  comment,
  generalSettings,
  UserStatus,
  setPunches,
  setStatus,
  setLinks,
  punchType,
  department,
  setCurrentStore
) {
  const dte = new Date();
  const timezoneOffset = dte.getTimezoneOffset();
  const isDst = dte.isDstObserved();
  setShowLinkOptions(false);
  setIsLoading(true);
  const punch = {
    userid: context.userid,
    punchStatus: punchType,
    storeid: currentStore,
    comment: comment,
    mode: process.env.NODE_ENV === "development" ? "debug" : "live",
    timeZoneOffset: timezoneOffset,
    isDst: isDst,
    departmentId: department
  };

  createPunch(context.clockUrl, context.clockToken, punch)
    .then((response) => {
      setIsLoading(false);
      const j = response.data;

      if (j.error === 0) {
        setPunches(j.punches);
        setStatus(9);
        setLinks([]);
        setTimeout(() => {
          UserStatus(
            setIsLoading,
            null,
            context,
            setCurrentStore,
            setLinks,
            setStatus
          );
        }, 2000);
      } else {
        toast.error(j.msg, {
          position: toast.POSITION.TOP_LEFT
        });
      }
    })
    .catch((err) => {
      setIsLoading(false);
      console.log(err);
      toast.error("An error occured processing your request", {
        position: toast.POSITION.TOP_LEFT
      });
    });
}

export { loadGeneralSettings, loadPunches, createClockPunch, UserStatus };
