import React from "react";
import PropTypes from "prop-types";

function SelectField(props) {
  let wrapperClass = "form-group";
  if (props.error.length > 0) {
    wrapperClass += " has-error";
  }
  return (
    <div className={wrapperClass}>
      <label htmlFor={props.id}>{props.label}</label>
      <div className="field">
        <select
          id={props.id}
          onChange={props.onChange}
          name={props.name}
          className="form-control"
        >
          <option key="0" value={0}>
            {props.emptyMsg}
          </option>
          {props.data.length === 0
            ? null
            : props.data.map((item, index) => (
                <option key={index} value={parseInt(item[props.valueField])}>
                  {item[props.displayField]}
                </option>
              ))}
        </select>
      </div>
      {props.error.length === 0 ? null : (
        <div className="alert alert-danger">{props.error}</div>
      )}
    </div>
  );
}

SelectField.propTypes = {
  props: PropTypes.object,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  valueField: PropTypes.string.isRequired,
  displayField: PropTypes.string.isRequired,
  data: PropTypes.array,
  value: PropTypes.number,
  error: PropTypes.string,
  emptyMsg: PropTypes.string
};

SelectField.defaultProps = {
  error: ""
};

export default SelectField;
