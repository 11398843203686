//const backgroundColor = "#35340";
const backgroundColor = "#fff";
//const color = "#fff";
const color = "#fff";
const blue = "#245fc0";
const darkBackground = "rgb(39, 42, 61)";

const variables = {
  backgroundColor: backgroundColor,
  color: color,
  blue: blue,
  desktopHeader: {
    //backgroundColor: backgroundColor,
    color: color,
    borderRadius: "10px",
    width: "70%",
    margin: "0 auto",
    marginTop: "10px"
  },
  widgetStyle: {
    padding: "10px",
    border: "3px solid #222222",
    borderRadius: "10px",
    backgroundColor: darkBackground,
    marginBottom: "20px",
    color: "#fff",
    boxShadow:
      "5px 5px 5px 0px rgba(0, 0, 0, .29), inset 3px 3px 5px 0px rgba(0, 0, 0, .2)",
    WebkitBoxShadow:
      "5px 5px 5px 0px rgba(0, 0, 0, .29), inset 3px 3px 5px 0px rgba(0, 0, 0, .2)",
    MozBoxShadow:
      "5px 5px 5px 0px rgba(0, 0, 0, .29), inset 3px 3px 5px 0px rgba(0, 0, 0, .2)"
  }
};

export { variables };
