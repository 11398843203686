import React, { useState } from "react";
import { Button, Popover, PopoverHeader, PopoverBody } from "reactstrap";
import Tooltip from "../../Tooltip/Tooltip";

const TransactionFinder = () => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const toggle = () => setPopoverOpen(!popoverOpen);

  return (
    <div>
      <Tooltip message="Create custom lookup" position="bottom">
        <Button id="transPopup" type="button">
          <i className="far fa-record-vinyl"></i>
        </Button>
      </Tooltip>

      <Popover
        placement="top"
        isOpen={popoverOpen}
        target="transPopup"
        toggle={toggle}
      >
        <PopoverHeader>Transaction Options</PopoverHeader>
        <PopoverBody>
          <p>
            Here we need to add some options that we need in order to find
            entire transaction details
          </p>
        </PopoverBody>
      </Popover>
    </div>
  );
};

export default TransactionFinder;
