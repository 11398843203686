import React, { useState, useEffect, useContext } from "react";
//import colors from "../../common/colors";
import "../Clock.css";
import ClockSidebar from "../ClockSidebar";
import { isMobile, isBrowser } from "react-device-detect";
import { ContextConfig } from "../../App";
import Spinner from "../../common/Spinner";
import { toast } from "react-toastify";
import ClockDesktopMenu from "../ClockDesktopMenu";
import Header from "../widgets/Header";
import {
  loadDepartments,
  addDepartment,
  copyDepartments
} from "../../api/departmentApi";
import DepartmentControls from "./DepartmentControls";
import DepartmentList from "./DepartmentList";
import DepartmentCopy from "./DepartmentCopy";
import { readClockStores } from "../../api/StoreApi";
import FloatingSidebar from "../../Dashboard/FloatingSidebar";
import { debounce } from "../../fp";
import { isValid } from "../../utils";

const Departments = () => {
  const [departments, setDepartments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [reRender, setReRender] = useState(false);
  const [department, setDepartment] = useState("");
  const [group, setGroup] = useState("");
  const [posLink, setPosLink] = useState("");
  const [copy, setCopy] = useState(false);
  const [stores, setStores] = useState();
  const [copyStore, setCopyStore] = useState("");
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const context = useContext(ContextConfig);

  useEffect(() => {
    getDepartments();
    loadStores();

    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);

    findDivHeight();

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, [reRender]);

  const findDivHeight = () => {
    if (!isBrowser) return;
    const navbar = document
      .getElementById("master-navbar")
      .getBoundingClientRect().height;
    const controls = document
      .getElementById("dept-controls")
      .getBoundingClientRect().height;
    const usedSpace = navbar + controls + 20; // for some reason the user controls div shows a height of zero???
    const remaining = window.innerHeight - usedSpace;

    const widget = document.getElementById("dept-body");
    if (isValid(widget)) {
      widget.style.height = remaining + "px";
      widget.style.overflow = "auto";
    }
  };

  const loadStores = () => {
    readClockStores(context.clockUrl, context.clockToken)
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          setStores(j.stores);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const reload = () => {
    getDepartments();
  };

  const getDepartments = () => {
    setIsLoading(true);
    loadDepartments(
      context.clockUrl,
      context.clockToken,
      context.lastClockStore
    )
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          setDepartments(j.departments);
        } else {
          setIsLoading(false);
          setDepartments([]);
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        setDepartments([]);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const handleDepartmentChange = (e) => {
    setDepartment(e.target.value);
  };

  const handleGroupChange = (e) => {
    setGroup(e.target.value);
  };

  const handlePosLinkChange = (e) => {
    debugger;
    setPosLink(e.target.value);
  };

  const AddDepartment = (e) => {
    e.preventDefault();
    setIsLoading(true);
    addDepartment(
      context.clockUrl,
      context.clockToken,
      context.lastClockStore,
      department,
      posLink,
      group,
      context.userid
    )
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          getDepartments();
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const handleCheckChange = (e) => {
    setCopy(e.target.checked);
  };

  const handleCopySettings = (e) => {
    setIsLoading(true);
    e.preventDefault();
    copyDepartments(
      context.clockUrl,
      context.clockToken,
      copyStore,
      context.lastClockStore
    )
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          getDepartments();
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const handleCopyStoreChanged = (e) => {
    setCopyStore(e.target.value);
  };

  return (
    <React.Fragment>
      {isLoading ? <Spinner /> : null}
      {isMobile ? (
        <div
          className="mobile-clock-header"

        >
          <ClockSidebar />
          <div className="container cc-main-body">
            <div className="container mt-4">
              <div
                style={{
                  width: "100%",
                  marginTop: "20px",
                  textAlign: "center",
                  fontSize: "1.3rem",
                  fontWeight: "bold"
                }}
              >
                Departments
              </div>
              <Header reRender={reRender} setReRender={setReRender} />
              <DepartmentControls
                handleGroupChange={handleGroupChange}
                handlePosLinkChange={handlePosLinkChange}
                handleDepartmentChange={handleDepartmentChange}
                AddDepartment={AddDepartment}
                department={department}
                group={group}
                posLinkg={posLink}
              />
              <DepartmentCopy
                copy={copy}
                handleCheckChange={handleCheckChange}
                stores={stores}
                handleCopySettings={handleCopySettings}
                setCopyStore={handleCopyStoreChanged}
              />
              <DepartmentList departments={departments} reload={reload} />
            </div>
          </div>
        </div>
      ) : (
        <div>
          <ClockDesktopMenu />
          <FloatingSidebar />
          <div className="container mt-4">
            <div id="dept-controls">
              <div
                style={{
                  width: "100%",
                  marginTop: "20px",
                  textAlign: "center",
                  fontSize: "1.3rem",
                  fontWeight: "bold"
                }}
              >
                Departments
              </div>
              <Header reRender={reRender} setReRender={setReRender} />
              <DepartmentControls
                handleGroupChange={handleGroupChange}
                handlePosLinkChange={handlePosLinkChange}
                handleDepartmentChange={handleDepartmentChange}
                AddDepartment={AddDepartment}
                department={department}
                group={group}
                posLinkg={posLink}
              />
              <DepartmentCopy
                copy={copy}
                handleCheckChange={handleCheckChange}
                stores={stores}
                handleCopySettings={handleCopySettings}
                setCopyStore={handleCopyStoreChanged}
              />
            </div>
            <div id="dept-body">
              <DepartmentList departments={departments} reload={reload} />
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Departments;
