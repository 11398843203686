import React, { useState, useEffect, useContext } from "react";
import ClockSidebar from "../ClockSidebar";
//import colors from "../../common/colors";
import "../Clock.css";
import RoleControls from "../Roles/RoleControls";
import { isValid } from "../../utils";
import { ContextConfig } from "../../App";
import {
  getClockUsers,
  getClockCompanyUsers,
  manageLinks,
  linkEmployeeId
} from "../../api/UsersApi";
import Spinner from "../../common/Spinner";
import { toast } from "react-toastify";
import { readCompanies } from "../../api/companyApi";
import {
  getStoreUserLinks,
  setLastStoreid,
  getUserClockStores
} from "../../api/StoreApi";
import Storelist from "./StoreList";
// eslint-disable-next-line no-unused-vars
import { BrowserView, MobileView, isBrowser } from "react-device-detect";
import ClockDesktopMenu from "../ClockDesktopMenu";
import FloatingSidebar from "../../Dashboard/FloatingSidebar";
import { useSelector, useDispatch } from "react-redux";
import { debounce } from "../../fp";
import * as actions from "../../actions/actionTypes";

const Links = () => {
  const state = useSelector((app) => app.linkReducer);
  const [stores, setStores] = useState([]);
  const dispatch = useDispatch();
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const context = useContext(ContextConfig);

  useEffect(() => {
    loadCompanies();
    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);

    findDivHeight();

    getClockStores();

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, []);

  useEffect(() => {
    if (state.companies.length > 0) {
      dispatch({ type: actions.LK_SET_COMPANY, company: context.company });
    }
  }, [state.companies]);

  useEffect(() => {
    if (state.currentCompany != "0") {
      loadUsers();
      getClockStores();
    }
  }, [state.currentCompany]);

  useEffect(() => {
    getClockStores();
  }, [context.lastClockStore]);

  useEffect(() => {
    if (isValid(state.user)) {
      if (state.user != "0") {
        loadStores();
      }
    }
  }, [state.user]);

  const findDivHeight = () => {
    // if (!isBrowser) return;
    // const navbar = document
    //   .getElementById("master-navbar")
    //   .getBoundingClientRect().height;
    // const controls = document.getElementById("controls").getBoundingClientRect()
    //   .height;
    // const usedSpace = navbar + controls + 50;
    // const remaining = window.innerHeight - usedSpace;
    // // const widget = document.getElementById("body");
    // // widget.style.height = remaining + "px";
    // // widget.style.overflow = "auto";
  };

  const loadUsers = () => {
    dispatch({ type: actions.LK_IS_LOADING_USERS, isLoading: true });
    // we need to swap out the function based on if they base company is dcr or not
    let userLookupFunction = getClockUsers;
    if (context.company === 1) {
      userLookupFunction = getClockCompanyUsers;
    }
    userLookupFunction(
      context.clockUrl,
      context.clockToken,
      state.search,
      0,
      state.currentCompany,
      context.lastClockStore
    )
      .then((response) => {
        dispatch({ type: actions.LK_IS_LOADING_USERS, isLoading: false });
        const j = response.data;
        if (j.error === 0) {
          dispatch({ type: actions.LK_SET_USERS, users: j.users });
        } else {
          dispatch({ type: actions.LK_SET_USERS, users: [] });
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        dispatch({ type: actions.LK_IS_LOADING_USERS, isLoading: [] });
        console.log(err);
        toast.error("An error has occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const loadCompanies = () => {
    dispatch({ type: actions.LK_IS_LOADING_COMPANIES, isLoading: true });
    readCompanies(context.clockUrl, context.clockToken)
      .then((response) => {
        dispatch({ type: actions.LK_IS_LOADING_COMPANIES, isLoading: false });
        const j = response.data;
        if (j.error === 0) {
          dispatch({ type: actions.LK_SET_COMPANIES, companies: j.companies });
        } else {
          dispatch({ type: actions.LK_SET_COMPANIES, companies: [] });
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        dispatch({ type: actions.LK_IS_LOADING_COMPANIES, isLoading: true });
        dispatch({ type: actions.LK_SET_COMPANIES, companies: [] });
        console.log(err);
        toast.error("An Internal Error occured", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const loadStores = () => {
    dispatch({ type: actions.LK_IS_LOADING_STORES, isLoading: true });
    getStoreUserLinks(context.clockUrl, context.clockToken, state.user)
      .then((response) => {
        dispatch({ type: actions.LK_IS_LOADING_STORES, isLoading: false });
        const j = response.data;
        if (j.error === 0) {
          dispatch({ type: actions.LK_SET_STORES, stores: j.stores });
        } else {
          dispatch({ type: actions.LK_SET_STORES, stores: [] });

          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        dispatch({ type: actions.LK_IS_LOADING_STORES, isLoading: false });
        dispatch({ type: actions.LK_SET_STORES, stores: [] });
        console.log(err);
        toast.error("An Internal error occured", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const handleSearchChange = (e) => {
    dispatch({ type: actions.LK_SET_SEARCH, search: e.target.value });
  };

  const handleEmployeeIdChange = (e, store) => {
    const index = state.stores.findIndex((s) => s.id == store.id);
    const storeRecord = state.stores.filter((s) => s.id == store.id)[0];
    storeRecord.employeeId = e.target.value;
    const remainingStores = state.stores.filter((s) => s.id != store.id);
    remainingStores.splice(index, 0, storeRecord);
    dispatch({
      type: actions.LK_SET_STORES,
      stores: remainingStores
    });
    dispatch({ type: actions.LK_SET_EMPLOYEEID, employeeId: e.target.value });
  };

  const handleToggle = (storeid, toggleState) => {
    if (storeid == context.lastClockStore && toggleState == false) {
      toast.error(
        "You cannot unlink this store right now. It is your currently active store",
        {
          position: toast.POSITION.TOP_LEFT
        }
      );
      return;
    }
    debugger;
    manageLinks(
      context.clockUrl,
      context.clockToken,
      state.user,
      storeid,
      toggleState ? 1 : 0,
      state.employeeId
    )
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          loadStores();
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const sendEmployeeId = (storeid) => {
    let employeeId = state.employeeId;
    if (!isValid(employeeId)) {
      const storeRecord = state.stores.filter((s) => s.id === storeid)[0];
      employeeId = storeRecord.employeeId;
    }

    linkEmployeeId(
      context.clockUrl,
      context.clockToken,
      state.user,
      storeid,
      employeeId
    )
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          dispatch({ type: actions.LK_SET_EMPLOYEEID, employeeId: null });
          toast.success(
            "Your Employee ID was linked for this user to this store",
            {
              position: toast.POSITION.TOP_LEFT
            }
          );
        } else {
          dispatch({ type: actions.LK_SET_EMPLOYEEID, employeeId: null });
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: actions.LK_SET_EMPLOYEEID, employeeId: null });
        toast.error("An Internal error occured", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const handleUserChange = (e) => {
    dispatch({ type: actions.LK_SET_USER, user: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleCompanyChange = (e) => {
    dispatch({ type: actions.LK_SET_COMPANY, company: e.target.value });
    dispatch({ type: actions.LK_SET_USER, user: "0" });
  };

  const handleSearchClick = () => {
    loadUsers();
  };

  const handleSync = (storeid) => {
    sendEmployeeId(storeid);
  };

  const handleStoreChange = (e) => {
    const storeid = e.target.value;
    context.lastClockStore = parseInt(storeid);
    loadUsers();
    setLastStoreid(context.clockUrl, context.clockToken, storeid)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          console.log(storeid + " saved as last storeid");
        } else {
          console.log(j.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getClockStores = () => {
    getUserClockStores(context.clockUrl, context.clockToken)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setStores(j.stores);
        } else {
          setStores([]);
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setStores([]);
        console.log(err);
        toast.error("An Internal error occured");
      });
  };

  return (
    <React.Fragment>
      {state.isLoadingCompanies && <Spinner />}
      {state.isLoadingStores && <Spinner />}
      {state.isLoadingUsers && <Spinner />}
      <BrowserView>
        <div>
          <ClockDesktopMenu />
          <FloatingSidebar />

          <div className="container" id="controls">
            <div className="role-header">
              <div
                style={{
                  width: "100%",
                  marginTop: "20px",
                  textAlign: "center",
                  fontSize: "1.3rem",
                  fontWeight: "bold"
                }}
              >
                Store Links
              </div>
              <RoleControls
                handleSubmit={handleSubmit}
                handleUserChange={handleUserChange}
                user={state.user}
                users={state.users}
                company={context.company}
                handleCompanyChange={handleCompanyChange}
                companies={state.companies}
                currentCompany={state.currentCompany}
                search={state.search}
                handleSearchChange={handleSearchChange}
                handleSearchClick={handleSearchClick}
                stores={stores}
                handleStoreChange={handleStoreChange}
                context={context}
              />
            </div>
          </div>
          <div
            className="container"
            style={{ marginTop: "10px", textAlign: "center" }}
            id="body"
          >
            <Storelist
              stores={state.stores}
              handleToggle={handleToggle}
              handleEmployeeIdChange={handleEmployeeIdChange}
              empoyeeId={state.employeeId}
              handleSync={handleSync}
              context={context}
            />
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div className="clock-mobile">
          <div className="mobile-clock-header">
            <ClockSidebar />
            <div className="container cc-main-body">
              <h3>Links</h3>
              <div className="role-header">
                <RoleControls
                  handleSubmit={handleSubmit}
                  handleUserChange={handleUserChange}
                  user={state.user}
                  users={state.users}
                  company={context.company}
                  handleCompanyChange={handleCompanyChange}
                  companies={state.companies}
                  currentCompany={state.currentCompany}
                  search={state.search}
                  handleSearchChange={handleSearchChange}
                  handleSearchClick={handleSearchClick}
                />
              </div>

              <div className="links-storelist">
                <Storelist
                  stores={state.stores}
                  handleToggle={handleToggle}
                  handleEmployeeIdChange={handleEmployeeIdChange}
                  empoyeeId={state.employeeId}
                  handleSync={handleSync}
                  context={context}
                />
              </div>
            </div>
          </div>
        </div>
      </MobileView>
    </React.Fragment>
  );
};

export default Links;
