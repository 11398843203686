import React from "react";
import HeaderRB from "../common/HeaderRb";
import { BrowserView, MobileView } from "react-device-detect"; // eslint-disable-line
import MobileHourly from "./MobileHourly";
import DesktopHourly from "./DesktopHourly";

import "./Hourly.css";

const Hourly = () => {
  return (
    <React.Fragment>
      <HeaderRB />
      <div className="container-fluid">
        <MobileView>
          <MobileHourly />
        </MobileView>
        <BrowserView>
          <DesktopHourly />
        </BrowserView>
      </div>
    </React.Fragment>
  );
};

Hourly.propTypes = {};

export default Hourly;
