/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
// import { Draggable } from "react-beautiful-dnd";
import PropTypes from "prop-types";
import TypeHeadDropDown from "../../common/TypeAheadDropDown";
import DatePicker from "react-datepicker";
import { isValid } from "../../utils";

import "./Selector.css";

const Selector = ({
  id,
  // eslint-disable-next-line no-unused-vars
  index,
  data,
  update,
  deleteWhere,
  reloadSelectors,
  groups,
  where,
  stores,
  subs,
  categories
}) => {
  const [field, setField] = useState("");
  const [operator, setOperator] = useState("1");
  const [value, setValue] = useState("");
  const [value2, setValue2] = useState("");
  const [andOr, setAndOr] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [selectedStore, setSelectedStore] = useState(0);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    update(id, field, operator, value, andOr, value2);
  }, [field, operator, value, andOr, value2]);

  useEffect(() => {
    if (where) {
      if (Object.keys(where).length > 0) {
        if (isValid(where.field)) setField(where.field);
        if (isValid(where.operator)) setOperator(where.operator);
        if (isValid(where.value)) setValue(where.value);
        if (isValid(where.value2)) setValue2(where.value2);
      }
    }
  }, [where]);

  useEffect(() => {}, [reloadSelectors]);

  const handleChange = (e) => {
    if (e.target.name === "and" && e.target.checked) {
      setAndOr(1);
    } else if (e.target.name === "or" && e.target.checked) {
      setAndOr(2);
    } else {
      setAndOr(0);
    }
    setReload(!reload);
  };

  const handleStoreChange = (e) => {
    setSelectedStore(e.storeid);
    setValue(e.storeid);
    setReload(!reload);
  };

  const handleSubChange = (e) => {
    setValue(e.subDescription);
    setReload(!reload);
  };

  const handleCatChange = (e) => {
    setValue(e.catDescription);
    setReload(!reload);
  };

  return (
    <div
      className="row mt-2"
      key={`sel=${id}`}
      style={{
        backgroundColor: "#456C86",
        border: "1px solid #fff",
        borderRadius: "10px",
        padding: "10px",
        marginBottom: "5px"
      }}
    >
      <div className="col-3">
        <select
          className="form-control"
          value={field}
          onChange={(e) => setField(e.target.value)}
        >
          <option value="0">Select a field</option>
          {data.map((record, i) => (
            <option
              key={`${id}=${i}-sel-${record.label}`}
              value={record.accessor}
            >
              {record.label}
            </option>
          ))}
        </select>
      </div>
      <div className="col-1">
        <select
          className="form-control"
          value={operator}
          onChange={(e) => setOperator(e.target.value)}
        >
          <option value="1">=</option>
          {field != "group" ? (
            <React.Fragment>
              <option value="2">&gt;</option>
              <option value="3">&lt;</option>
              <option value="4">&gt;=</option>
              <option value="5">&lt;=</option>
              <option value="6">Between</option>
              <option value="7">Like</option>
              <option value="8">Not</option>
              <option value="9">In</option>
              <option value="10">&gt;&lt;</option>
            </React.Fragment>
          ) : null}
        </select>
      </div>
      {operator == "6" ? (
        <React.Fragment>
          {field == "f254" ? (
            <React.Fragment>
              <div className="col-2">
                <DatePicker
                  selected={new Date(value || new Date())}
                  onChange={(e) => setValue(e)}
                />
              </div>
              <div className="col-2">
                <DatePicker
                  selected={new Date(value2 || new Date())}
                  onChange={(e) => setValue2(e)}
                />
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className="col-2">
                <input
                  type="text"
                  className="form-control"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                />
              </div>
              <div className="col-2">
                <input
                  type="text"
                  className="form-control"
                  value={value2}
                  onChange={(e) => setValue2(e.target.value)}
                />
              </div>
            </React.Fragment>
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {field === "group" ? (
            <div className="col-4">
              <select
                className="form-control"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              >
                <option value="0">Select a group</option>
                {groups.map((group, i) => (
                  <option key={`gs-${i}`} value={group.id}>
                    {group.group_name}
                  </option>
                ))}
              </select>
            </div>
          ) : (
            <React.Fragment>
              {field == "storeid" ? (
                <div className="col-4">
                  <TypeHeadDropDown
                    placeholder={"Select Store"}
                    data={stores}
                    setSelection={handleStoreChange}
                    value={parseInt(value)}
                    keyValue={"storeid"}
                    displayValue={"store_Name"}
                    label={"Select Store"}
                    labelColor={"#fff"}
                    backgroundColor={"transparent"}
                    reload={reload}
                  />
                </div>
              ) : (
                <React.Fragment>
                  {field == "store_number" ? (
                    <div className="col-4">
                      <TypeHeadDropDown
                        placeholder={"Select Store"}
                        data={stores}
                        setSelection={handleStoreChange}
                        value={parseInt(value)}
                        keyValue={"storeid"}
                        displayValue={"store_Name"}
                        label={"Select Store"}
                        labelColor={"#fff"}
                        backgroundColor={"transparent"}
                        reload={reload}
                      />
                    </div>
                  ) : (
                    <React.Fragment>
                      {field == "f254" ? (
                        <div className="col-4">
                          <DatePicker
                            selected={new Date(value || new Date())}
                            onChange={(e) => setValue(e)}
                          />
                        </div>
                      ) : (
                        <React.Fragment>
                          {field == "subDescription" ? (
                            <div className="col-4">
                              <TypeHeadDropDown
                                placeholder={"Select Department"}
                                data={subs}
                                setSelection={handleSubChange}
                                value={value}
                                keyValue={"subDescription"}
                                displayValue={"subDescription"}
                                label={"Select Department"}
                                labelColor={"#fff"}
                                backgroundColor={"transparent"}
                                reload={reload}
                              />
                            </div>
                          ) : (
                            <React.Fragment>
                              {field == "catDescriptor" ? (
                                <div className="col-4">
                                  <TypeHeadDropDown
                                    placeholder={"Select Category"}
                                    data={categories}
                                    setSelection={handleCatChange}
                                    value={value}
                                    keyValue={"catDescription"}
                                    displayValue={"catDescription"}
                                    label={"Select Category"}
                                    labelColor={"#fff"}
                                    backgroundColor={"transparent"}
                                    reload={reload}
                                  />
                                </div>
                              ) : (
                                <div className="col-4">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={value}
                                    onChange={(e) => setValue(e.target.value)}
                                  />
                                </div>
                              )}
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      )}

      <div className="col-2" style={{ marginTop: "10px" }}>
        <input
          type="checkbox"
          checked={andOr === 1 ? true : false}
          name="and"
          onChange={handleChange}
        />
        <label className="ms-2" style={{ color: "#fff" }}>
          And
        </label>
        <input
          className="ms-4"
          type="checkbox"
          checked={andOr === 2 ? true : false}
          name="or"
          onChange={handleChange}
          style={{ marginLeft: "10px" }}
        />
        <label className="ms-2" style={{ color: "#fff" }}>
          Or
        </label>
      </div>
      <div className="col-1">
        <button className="btn btn-danger" onClick={() => deleteWhere(id)}>
          X
        </button>
      </div>
    </div>
  );
};

Selector.propTypes = {
  id: PropTypes.any,
  data: PropTypes.array,
  update: PropTypes.func,
  deleteWhere: PropTypes.func,
  reloadSelectors: PropTypes.bool,
  index: PropTypes.any,
  groups: PropTypes.array,
  stores: PropTypes.array,
  where: PropTypes.object,
  subs: PropTypes.array,
  categories: PropTypes.array
};

export default Selector;
