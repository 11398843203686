import React from "react";
import PropTypes from "prop-types";

const LinksDesktop = ({ links, isPhone }) => {
  return (
    <table className="table">
      <thead>
        <tr>
          <th scope="col">{isPhone ? "Customer" : "Phone #"}</th>
          <th scope="col">Type</th>
          <th scope="col">Changed</th>
        </tr>
      </thead>

      <tbody>
        {links.map((link, index) => (
          <tr key={`${index}-${link.f1578}`}>
            <td>{isPhone ? link.f1148 : link.f1577}</td>
            <td>{link.f1578}</td>
            <td>{link.f253}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

LinksDesktop.propTypes = {
  links: PropTypes.array,
  isPhone: PropTypes.bool
};

export default LinksDesktop;
