import React, { useEffect } from "react";
import PropTypes from "prop-types";
import TextInput from "../../../common/TextInput";
import PasswordStrengthMeter from "../../../common/PasswordStrengthMeter";
import UserStores from "../UserStores";
import UserRoles from "../UserRoles";
import { isBrowser } from "react-device-detect";

const MobileUsers = (props) => {
  useEffect(() => {}, [props.stores, props.roles, props.users]);

  return (
    <div className="p-3" id="users-create" style={{ overflow: "auto" }}>
      {props.editMode ? (
        <div className="row justify-content-center">
          <div className="user-edit-submit">
            <button
              className="btn btn-outline-dark"
              onClick={props.submitEditedUser}
            >
              Submit
            </button>
          </div>
        </div>
      ) : null}

      <TextInput
        type="text"
        id="txtfirstname"
        name="firstName"
        label="First Name"
        onChange={props.onChange}
        value={props.user.firstName}
        error={props.errors.firstName}
        placeholder="First Name"
        autoComplete="timeclock-firstname"
      />

      <TextInput
        type="text"
        id="txtlastname"
        name="lastName"
        label="Last Name"
        onChange={props.onChange}
        value={props.user.lastName}
        error={props.errors.lastName}
        placeholder="Last Name"
        autoComplete="timeclock-lastname"
      />

      <label style={{ marginBottom: "-3px" }}>User Level</label>
      <div className="cc-form-item">
        <select
          className="form-control"
          name="userLevel"
          placeholder="Select User Level"
          autoComplete="timeclock-userlevel"
          onChange={props.onChange}
          value={props.user.userLevel || ""}
        >
          <option value="-1">Select a User Level</option>
          {props.levels.length === 0 ? (
            <option value="0">No Levels found</option>
          ) : (
            props.levels.map((l, index) => (
              <option key={`l-${index}`} value={l.id}>
                {l.description}
              </option>
            ))
          )}
        </select>
        {props.errors.userLevel ? null : (
          <div style={{ color: "red" }}>{props.errors.userLevel}</div>
        )}
      </div>

      <label style={{ marginBottom: "-3px", marginTop: "3px" }}>
        Default Department
      </label>
      <div className="cc-form-item">
        <select
          className="form-control"
          name="defaultDepartment"
          autoComplete="timeclock-department"
          placeholder="Select a Department"
          onChange={props.onChange}
          value={parseInt(props.user.defaultDepartment) || ""}
        >
          <option value="0">Select a department</option>
          {props.departments.map((d, index) => (
            <option key={`ud-${index}`} value={d.id}>
              {d.description}
            </option>
          ))}
        </select>
      </div>

      <label style={{ marginBottom: "-3px" }}>Company</label>
      <div className="cc-form-item">
        <select
          className="form-control"
          name="company"
          placeholder="Select Company"
          autoComplete="timeclock-company"
          onChange={props.onChange}
          value={parseInt(props.user.company) || ""}
        >
          <option value="-1">Select a Company</option>
          {props.companies.length === 0 ? (
            <option value="0">No Companies returned</option>
          ) : (
            props.companies.map((c, index) => (
              <option key={`c-${index}`} value={c.company_Id}>
                {c.company_Name}
              </option>
            ))
          )}
        </select>
        {props.errors.company ? (
          <div style={{ color: "red" }}>{props.errors.company}</div>
        ) : null}
      </div>

      <label style={{ marginBottom: "-3px" }}>Store Group</label>
      <div className="cc-form-item">
        <select
          className="form-control"
          name="storeGroup"
          value={props.user.storeGroup}
          onChange={props.onChange}
        >
          <option value="0">Select a Store Group</option>
          {props.groups.map((group, i) => (
            <option key={`sg-${i}`} value={group.id}>
              {group.name}
            </option>
          ))}
        </select>
        {props.errors.storeGroup ? (
          <div style={{ color: "red" }}>{props.errors.storeGroup}</div>
        ) : null}
      </div>

      <TextInput
        type="text"
        id="txtUsername"
        name="username"
        label="Username"
        autoComplete="timeclock-username"
        readOnly={props.editMode}
        onChange={props.onChange}
        value={props.user.username}
        error={props.errors.username}
        placeholder="Enter Username"
      />
      {props.usernameTaken && props.user.username.length > 0 ? (
        <span style={{ color: "red", marginLeft: "20px" }}>
          <i className="far fa-exclamation-triangle"></i>
          <span style={{ marginLeft: "10px" }}>Username is already taken</span>
        </span>
      ) : null}

      <TextInput
        type="text"
        id="txtEmail"
        name="email"
        label="Email"
        autoComplete="timeclock-email"
        onChange={props.onChange}
        value={props.user.email}
        error={props.errors.email}
        placeholder="Enter Email"
      />

      {parseInt(props.context.userLevel) >= 6 &&
      props.user.userid &&
      isBrowser ? (
        <React.Fragment>
          <UserStores context={props.context} user={props.user} />

          <UserRoles context={props.context} user={props.user} />
        </React.Fragment>
      ) : null}

      <TextInput
        type="tel"
        id="txtEmployeeId"
        name="employeeId"
        label="Employee ID"
        autoComplete="timeclock-employeeid"
        readOnly={props.editMode}
        onChange={props.onChange}
        value={props.user.employeeId}
        error={props.errors.employeeId}
        placeholder="Enter Employee ID"
      />
      {props.employeeIdTaken && props.user.employeeId.length > 0 ? (
        <span style={{ color: "red", marginLeft: "20px" }}>
          <i className="far fa-exclamation-triangle"></i>
          <span style={{ marginLeft: "10px" }}>
            Employee Id is already taken
          </span>
        </span>
      ) : null}

      <div className="form-check w-50 m-auto">
        <input
          style={{ opacity: "1", visibility: "visible" }}
          className="form-check-input"
          type="checkbox"
          autoComplete="timeclock-mandatorybreak"
          id="checkMandatoryBreak"
          name="mandatoryBreak"
          onChange={props.onChange}
          value={props.user.mandatoryBreak}
          checked={props.user.mandatoryBreak}
        />
        <label htmlFor="mandatoryBreak">Mandatory Breaks</label>
      </div>

      <div className="form-check w-50 m-auto">
        <input
          style={{ opacity: "1", visibility: "visible" }}
          className="form-check-input"
          type="checkbox"
          id="checkMandatoryLunch"
          autoComplete="timeclock-mandatorylunch"
          name="mandatoryLunch"
          onChange={props.onChange}
          value={props.user.mandatoryLunch}
          checked={props.user.mandatoryLunch}
        />
        <label htmlFor="mandatoryLunch">Mandatory Lunch</label>
      </div>

      <TextInput
        type="tel"
        id="txtPto"
        name="ptoDaysPerYear"
        label="PTO Days"
        autoComplete="timeclock-pto"
        onChange={props.onChange}
        value={props.user.ptoDaysPerYear}
        placeholder="Enter PTO Days"
      />

      <TextInput
        type="tel"
        id="txtClockNumber"
        name="clockNumber"
        label="Clock Number"
        autoComplete="timeclock-clocknumber"
        onChange={props.onChange}
        value={props.user.clockNumber}
        placeHolder="Clock Number"
      />
      {props.newNumber ? (
        <div
          style={{
            width: "100%",
            textAlign: "center",
            color: "red",
            fontSize: "1.2rem",
            marginBottom: "10px"
          }}
        >{`The first available number is ${props.newNumber}`}</div>
      ) : null}

      <TextInput
        type="tel"
        id="txtClockPassword"
        name="clockPassword"
        label="Clock Password"
        autoComplete="timeclock-clockpassword"
        onChange={props.onChange}
        value={props.user.clockPassword}
        placeHolder="Clock Password"
        error={props.errors.clockPassword}
      />

      {props.editMode ? (
        <div className="row justify-content-center">
          <div className="form-check  m-auto">
            <input
              classame="form-check-input"
              type="checkbox"
              name="showPasswordEdit"
              onChange={props.onChange}
              value={props.user.showPasswordEdit}
              checked={props.user.showPasswordEdit}
              style={{ opacity: 1, visibility: "visible", marginTop: "5px" }}
            />
            <label className="ml-3" style={{ marginLeft: "10px !important" }}>
              Show Password Change
            </label>
          </div>
        </div>
      ) : null}

      {!props.editMode || props.user.showPasswordEdit ? (
        <PasswordStrengthMeter
          userid={1}
          confirmPasswordChange={props.onSubmit}
        />
      ) : (
        <div className="row justify-content-center">
          <div className="user-edit-submit">
            <button
              className="btn btn-outline-dark"
              onClick={props.submitEditedUser}
            >
              Submit
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

MobileUsers.propTypes = {
  user: PropTypes.object,
  onChange: PropTypes.func,
  errors: PropTypes.object,
  onSubmit: PropTypes.func,
  formValid: PropTypes.bool,
  levels: PropTypes.array,
  companies: PropTypes.array,
  handleSubmitNewUser: PropTypes.func,
  submitEditedUser: PropTypes.func,
  editMode: PropTypes.bool,
  departments: PropTypes.array,
  usernameTaken: PropTypes.bool,
  employeeIdTaken: PropTypes.bool,
  stores: PropTypes.array,
  handleToggle: PropTypes.func,
  roles: PropTypes.array,
  handleToggleRole: PropTypes.func,
  handleCheckUserLevelChange: PropTypes.func,
  handleCopyRoles: PropTypes.func,
  handleCheckChange: PropTypes.func,
  handleCopyUserChanged: PropTypes.func,
  warning: PropTypes.string,
  copy: PropTypes.bool,
  users: PropTypes.array,
  context: PropTypes.object,
  groups: PropTypes.array,
  newNumber: PropTypes.any
};

export default MobileUsers;
