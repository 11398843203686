import React, { useState, useEffect, useContext } from "react";
import { ContextConfig } from "../App";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import { usePrevious, convertAmount, addDays, formatDate } from "../utils";
import { getAssignedStoresAsync } from "../api/StoreApi";
import { readHourlySales, getWeeklyHourly } from "../api/SalesApi";
import { savePrefs } from "../api/GlobalApi";
import Spinner from "../common/Spinner";
import { debounce, isEqual } from "../fp";
import { Bar } from "react-chartjs-2";
import "./HourlyChart.css";

const DesktopHourly = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [endDate, setEndDate] = useState(new Date());
  const [stores, setStores] = useState([]);
  const [currentStore, setCurrentStore] = useState(0);
  const [hourly, setHourly] = useState([]);
  // eslint-disable-next-line
  const [weekHours, setWeekHours] = useState([]);
  const context = useContext(ContextConfig);
  const [hourlySales, setHourlySalses] = useState([]);
  const [hourlyDates, setHourlyDates] = useState([]);
  const [activeHour, setActiveHour] = useState(0);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const previousStores = usePrevious(stores);

  useEffect(() => {
    if (!isEqual(previousStores, stores)) {
      loadStores();
    }

    if (currentStore > 0) {
      loadHourly();
    } else {
      setCurrentStore(parseInt(context.lastStoreid));
    }

    if (context.hasOwnProperty("endDate")) {
      // if the date is todays date, then we need to back it up one day
      const testDate = new Date();
      if (formatDate(context.endDate) == formatDate(testDate)) {
        const newDate = addDays(context.endDate, -1);
        context.endDate = newDate;
        setEndDate(newDate);
      } else {
        setEndDate(context.endDate);
      }
    }

    savePref();

    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });

      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);
    findDivHeight();

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, [currentStore, endDate]);

  const findDivHeight = () => {
    const navbar = document
      .getElementById("master-navbar")
      .getBoundingClientRect().height;
    const controls = document
      .getElementById("hourly-controls")
      .getBoundingClientRect().height;
    const usedSpace = navbar + controls + 20;
    const remaining = window.innerHeight - usedSpace;

    const widget = document.getElementById("hourly-body");
    widget.style.height = remaining + "px";
    widget.style.overflow = "auto";
  };

  const loadStores = () => {
    setIsLoading(true);
    getAssignedStoresAsync(context.url, context.token)
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          setStores(j.items);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("", err);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const loadHourly = () => {
    setIsLoading(true);
    readHourlySales(context.url, context.token, currentStore, endDate)
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          setHourly(j.items);
          handleHourClick(j.items[0].f01);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const savePref = () => {
    savePrefs(
      context.url,
      context.token,
      context.lastStoreid,
      context.lastGroup,
      1,
      context.lastGroupName,
      null,
      null
    );
  };

  const handleEndDateChange = (e) => {
    context.endDate = e;
    setEndDate(e);
  };

  const handleStoreChange = (e) => {
    context.lastStoreid = e.target.value;
    const storeCopy = stores.filter((s) => {
      return s.storeid === parseInt(e.target.value);
    });
    setCurrentStore(storeCopy[0].storeid);
  };

  const handleHourClick = (e) => {
    setIsLoading(true);
    setActiveHour(e);
    const startDate = addDays(endDate, -7);
    getWeeklyHourly(
      context.url,
      context.token,
      currentStore,
      startDate,
      endDate,
      e
    )
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          setWeekHours(j.items);
          parseSales(j.items);
          parseDates(j.items);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("error", err);
      });
  };

  const chartData = {
    sales: (canvas) => {
      let ctx = canvas.getContext("2d");
      var gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(253,93,147,0.8)");
      gradientStroke.addColorStop(0, "rgba(253,93,147,0)"); //blue colors
      return {
        labels: hourlyDates,
        datasets: [
          {
            label: "Data",
            fill: true,
            backgroundColor: gradientStroke,
            hoverBackgroundColor: gradientStroke,
            borderColor: "#ff5991",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            data: hourlySales
          }
        ]
      };
    },
    options: {
      maintainAspectRatio: false,
      legend: {
        display: false
      },
      tooltips: {
        backgroundColor: "#f5f5f5",
        titleFontColor: "#333",
        bodyFontColor: "#666",
        bodySpacing: 4,
        xPadding: 12,
        mode: "nearest",
        intersect: 0,
        position: "nearest"
      },
      responsive: true,
      scales: {
        yAxes: [
          {
            gridLines: {
              drawBorder: false,
              color: "rgba(253,93,147,0.1)",
              zeroLineColor: "transparent"
            },
            ticks: {
              suggestedMin: 60,
              suggestedMax: 125,
              padding: 20,
              fontColor: "#9e9e9e"
            }
          }
        ],
        xAxes: [
          {
            gridLines: {
              drawBorder: false,
              color: "rgba(253,93,147,0.1)",
              zeroLineColor: "transparent"
            },
            ticks: {
              padding: 20,
              fontColor: "#9e9e9e"
            }
          }
        ]
      }
    }
  };

  const parseSales = (sales) => {
    const newArray = sales.map((record) => {
      return parseFloat(record.f65).toFixed(2);
    });
    setHourlySalses(newArray);
  };

  const parseDates = (sales) => {
    const newArray = sales.map((record) => {
      return formatDate(record.f254);
    });

    setHourlyDates(newArray);
  };

  return (
    <React.Fragment>
      {isLoading && <Spinner />}
      <div id="hourly-controls">
        <h3>Hourly Sales by Store</h3>
        <div className="container">
          <div className="row hourly-d-header">
            <div className="col-xs-12 col-sm-6 col-lg-3 mt-1">
              <select
                className="form-control h-select"
                onChange={handleStoreChange}
                value={currentStore || 0}
              >
                {stores.length === 0
                  ? null
                  : stores.map((store) => (
                      <option key={store.storeid} value={store.storeid}>
                        {store.store_Name}
                      </option>
                    ))}
              </select>
            </div>
            <div className="col-xs-12 col-sm-6 col-lg-3 mt-1 h-date">
              End:&nbsp;
              <DatePicker selected={endDate} onChange={handleEndDateChange} />
            </div>
          </div>
        </div>
      </div>

      <div className="container hourly-d-body mt-3" id="hourly-body">
        <div>
          {hourly.length === 0 ? (
            <div>There are no records to display.</div>
          ) : (
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Hour</th>
                  <th scope="col">Sales</th>
                  <th scope="col">Qty</th>
                </tr>
              </thead>
              <tbody>
                {hourly.map((hour) => (
                  <tr
                    className={`${
                      activeHour == hour.f01 ? "hour-row-active" : "hour-row"
                    }`}
                    key={hour.f01}
                    onClick={() => handleHourClick(hour.f01)}
                  >
                    <td>{hour.f01}</td>
                    <td>{convertAmount(hour.f65)}</td>
                    <td>{hour.f64}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        <div>
          <Bar data={chartData["sales"]} options={chartData.options} />
        </div>
      </div>
    </React.Fragment>
  );
};

DesktopHourly.propTypes = {};

export default DesktopHourly;
