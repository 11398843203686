import React, { useState, useEffect, useContext } from "react";
import { getCustomerCounts } from "../../api/PayServerApi";
import { ContextConfig } from "../../App";
import { toast } from "react-toastify";
import { formatMoney } from "../../utils";
import ErrorBoundary from "../../common/ErrorBoundary";
import colors from "./colors";
import "./Line.css";

const Line = () => {
  const [customers, setCustomers] = useState({});
  const context = useContext(ContextConfig);

  useEffect(() => {
    fetchCustomerCounts();
  }, []);

  const calculatePercent = (value) => {
    if (Object.keys(customers).length === 0) {
      return;
    }

    const { lastNinety, lastSixety, lastThirty } = customers;
    const total = Number(lastNinety) + Number(lastSixety) + Number(lastThirty);
    const percent = (customers[value] / total) * 100;
    return Math.round(percent, 2).toString() + "%";
  };

  const fetchCustomerCounts = () => {
    getCustomerCounts(context.psanalysis, context.apikey)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setCustomers(j.count[0]);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.Message, {
          position: toast.POSITION.TOP_RIGHT
        });
      });
  };

  return (
    <ErrorBoundary>
      <div className="container justify-content-center align-items-center mt-5">
        <div className="psas-line-description">Member Makeup</div>
        <div className="psas-line-body flex-d justify-content-start">
          <span
            className="psas-line-last-thirty"
            style={{
              width: calculatePercent("lastThirty"),
              backgroundColor: colors.green
            }}
          >
            &nbsp;
          </span>
          <span
            className="psas-line-last-sixty"
            style={{
              width: calculatePercent("lastSixety"),
              backgroundColor: colors.orange
            }}
          >
            &nbsp;
          </span>
          <span
            className="psas-line-last-ninety"
            style={{
              width: calculatePercent("lastNinety"),
              backgroundColor: colors.purple
            }}
          >
            &nbsp;
          </span>
        </div>
        <div className="psas-line-legend">
          <div>
            <div className="psas-line-legend-title">Last 30 days</div>
            <div
              className="psas-line-legend-value psas-line-last-thirty-value"
              style={{ color: colors.green }}
            >
              {formatMoney(customers.lastThirty, 0)}
            </div>
          </div>
          <div>
            <div className="psas-line-legend-title">Last 60 days</div>
            <div
              className="psas-line-legend-value psas-line-last-sixty-value"
              style={{ color: colors.orange }}
            >
              {formatMoney(customers.lastSixety, 0)}
            </div>
          </div>
          <div>
            <div className="psas-line-legend-title">Last 90 days</div>
            <div
              className="psas-line-legend-value psas-line-last-ninety-value"
              style={{ color: colors.purple }}
            >
              {formatMoney(customers.lastNinety, 0)}
            </div>
          </div>
          <div>
            <div className="psas-line-legend-title">Total Customers</div>
            <div className="psas-line-legend-value" style={{ color: "#fff" }}>
              {formatMoney(customers.totalCustomerCount, 0)}
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default Line;
