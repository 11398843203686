import React, { useContext, useEffect } from "react";
import { ContextConfig } from "../../App";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { formatDate } from "../../utils";

import FreshopCard from "../FreshopCard";

import * as actions from "../../actions/actionTypes";

import { PriceMods } from "../Freshop-I";

const PriceModifications = () => {
  const freshopState = useSelector((app) => app.freshopReducer);
  const dispatch = useDispatch();

  const context = useContext(ContextConfig);

  useEffect(() => {
    getMods();

    if (freshopState.priceModError !== null) {
      toast.warning(freshopState.priceModError, {
        position: toast.POSITION.TOP_LEFT
      });
      dispatch({ type: actions.FRESHOP_SET_PRICE_MOD_ERROR, error: null });
    }
  }, [
    freshopState.priceModError,
    freshopState.selectedStore.id,
    freshopState.startDate,
    freshopState.endDate
  ]);

  const getMods = () => {
    PriceMods(
      context.freshopApi,
      freshopState.selectedStore.id,
      formatDate(freshopState.startDate),
      formatDate(freshopState.endDate),
      context.freshopAppKey,
      dispatch
    );
  };

  return (
    <FreshopCard
      headerText={"Price Modifications"}
      count={freshopState.priceMods.length}
      state={freshopState}
      handler={"showPriceMods"}
      actionType={"SHOW_PRICE_MODS"}
    />
  );
};

export default PriceModifications;
