import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import TimeBox from "./TimeBox";

const TimeBars = ({ timeDays }) => {
  const [weekTotal, setWeekTotal] = useState({});
  useEffect(() => {
    let weekHours = timeDays.reduce((acc, cur) => {
      return parseFloat(cur.totalHours) + parseFloat(acc);
    }, 0);

    setWeekTotal({ f254: "total", totalHours: weekHours });
  });

  return (
    <div className="time-bars">
      {timeDays.length === 0 ? (
        <div>Currenly no records to display</div>
      ) : (
        <div className="row time-bars-row">
          {timeDays.map((record, index) => (
            <TimeBox key={index} record={record} />
          ))}
          {weekTotal === null ? null : (
            <TimeBox key="weektotal" record={weekTotal} />
          )}
        </div>
      )}
    </div>
  );
};

TimeBars.propTypes = {
  timeDays: PropTypes.array
};

export default TimeBars;
