import React, { useEffect } from "react";
import { Col } from "reactstrap";
import { useSelector } from "react-redux";
import { formatDate } from "../../utils";

const SmsItem = () => {
  const freshopState = useSelector((app) => app.freshopReducer);
  let item = {
    f126: "",
    f1008: "",
    f1009: "",
    f1007: "",
    f1006: "",
    f1014: "",
    f1015: "",
    f1013: "",
    f1012: "",
    f1011: "",
    f30: "",
    f31: "",
    f181: "",
    f182: "",
    f1133: "",
    f1134: "",
    f136: "",
    f135: ""
  };

  useEffect(() => {
    item = freshopState.currentPriceMod;
    console.log(item);
  }, [freshopState.currentPriceMod, item]);

  return (
    <React.Fragment>
      <div className="sms-item-info">
        <div className="sms-item-upc">{freshopState.currentPriceMod.upc}</div>
        <div className="sms-item-description">
          {freshopState.currentPriceMod.description}
        </div>
      </div>
      <div className="sms-price-row">
        <Col className="sms-pricemod-header-text">Lev</Col>
        <Col className="sms-pricemod-header-text">Last Date</Col>
        <Col className="sms-pricemod-header-text">time</Col>
        <Col className="sms-pricemod-header-text">Active Price</Col>
        <Col className="sms-pricemod-header-text">Next Date</Col>
        <Col className="sms-pricemod-header-text">time</Col>
        <Col className="sms-pricemod-header-text">Next Price</Col>
        <Col className="sms-pricemod-header-text">Source</Col>
      </div>

      <div className="sms-price-row">
        <Col>
          <div className="sms-header-box">
            {freshopState.currentPriceMod.f126}
          </div>
        </Col>
        <Col>
          <div className="sms-header-box">
            {formatDate(freshopState.currentPriceMod.f1008)}
          </div>
        </Col>
        <Col>
          <div className="sms-header-box">
            {freshopState.currentPriceMod.f1009}
          </div>
        </Col>
        <Col>
          <div className="green-background">
            {freshopState.currentPriceMod.f1007}/
            {freshopState.currentPriceMod.f1006}
          </div>
        </Col>
        <Col>
          <div className="sms-header-box">
            {freshopState.currentPriceMod.f1014}
          </div>
        </Col>
        <Col>
          <div className="sms-header-box">
            {freshopState.currentPriceMod.f1015}
          </div>
        </Col>
        <Col>
          <div className="yellow-background">
            {freshopState.currentPriceMod.f1013}/
            {freshopState.currentPriceMod.f1012}
          </div>
        </Col>
        <Col>
          <div className="sms-header-box">
            {freshopState.currentPriceMod.f1011}
          </div>
        </Col>
      </div>

      <div className="sms-price-row">
        <div className="sms-price-columns">
          <Col>
            <div className="sms-price-column-header">Reg</div>
          </Col>
          <Col>
            <div className="sms-price-column-header">Tpr</div>
          </Col>
          <Col>
            <div className="sms-price-column-header">Sale</div>
          </Col>
          <Col>
            <div className="sms-price-column-header">Store</div>
          </Col>
        </div>
      </div>

      <div className="sms-price-row sms-row-spacer">
        <div className="sms-price-columns">
          <div className="sms-triple-column">
            <span className="sms-item-label">Price</span>
            <span className="sms-price-box">
              {freshopState.currentPriceMod.retail}
            </span>
            <span className="sms-item-box">
              {freshopState.currentPriceMod.retailSplit}
            </span>
          </div>
          <div className="sms-triple-column">
            <span className="sms-item-label">&nbsp;</span>
            <span className="sms-price-box">
              {freshopState.currentPriceMod.f181}
            </span>
            <span className="sms-item-box">
              {freshopState.currentPriceMod.f182}
            </span>
          </div>
          <div className="sms-triple-column">
            <span className="sms-item-label">&nbsp;</span>
            <span className="sms-price-box">
              {freshopState.currentPriceMod.f136}
            </span>
            <span className="sms-item-box">
              {freshopState.currentPriceMod.f135}
            </span>
          </div>
          <div className="sms-triple-column">
            <span className="sms-item-label">&nbsp;</span>
            <span className="sms-price-box">
              {freshopState.currentPriceMod.f1133}
            </span>
            <span className="sms-item-box">
              {freshopState.currentPriceMod.f1134}
            </span>
          </div>
        </div>
      </div>

      <div className="sms-row-spacer"></div>

      <div className="sms-price_row">
        <div className="sms-price-columns">
          <div className="sms-triple-column">
            <div className="sms-item-label">Start</div>
            <div className="sms-price-box">
              {freshopState.currentPriceMod.f35}
            </div>
            <div>&nbsp;</div>
          </div>
          <div className="sms-triple-column">
            <div className="sms-item-label">&nbsp;</div>
            <div className="sms-price-box">
              {freshopState.currentPriceMod.f183}
            </div>
          </div>
          <div className="sms-triple-column">
            <div className="sms-item-label">&nbsp;</div>
            <div className="sms-price-box">
              {freshopState.currentPriceMod.f137}
            </div>
          </div>
          <div className="sms-triple-column">
            <div className="sms-item-label">&nbsp;</div>
            <div className="sms-price-box">
              {freshopState.currentPriceMod.f1216}
            </div>
          </div>
        </div>
      </div>

      <div className="sms-row-spacer"></div>

      <div className="sms-price-row">
        <div className="sms-price-columns">
          <div className="sms-triple-column">
            <div className="sms-item-label">End</div>
            <div className="sms-price-box">
              {freshopState.currentPriceMod.f129}
            </div>
            <div>&nbsp;</div>
          </div>
          <div className="sms-triple-column">
            <div className="sms-item-label">&nbsp;</div>
            <div className="sms-price-box">
              {freshopState.currentPriceMod.f184}
            </div>
          </div>
          <div className="sms-triple-column">
            <div className="sms-item-label">&nbsp;</div>
            <div className="sms-price-box">
              {freshopState.currentPriceMod.f138}
            </div>
          </div>
          <div className="sms-triple-column">
            <div className="sms-item-label">&nbsp;</div>
            <div className="sms-price-box">
              {freshopState.currentPriceMod.f1217}
            </div>
          </div>
        </div>
      </div>

      <div className="sms-row-spacer"></div>

      <div className="sms-price-row">
        <div className="sms-price-columns">
          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
            <div className="sms-item-label">Time</div>
            <div className="sms-time-box">
              <div
                className="sms-item-box"
                style={{ marginLeft: "3px", marginRight: "3px" }}
              >
                {freshopState.currentPriceMod.f36}
              </div>
              <div
                className="sms-item-box"
                style={{ marginLeft: "3px", marginRight: "3px" }}
              >
                {freshopState.currentPriceMod.f130}
              </div>
            </div>
          </div>
          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
            <div className="sms-item-label">Ad</div>
            <div className="sms-item-box" style={{ maxWidth: "100px" }}>
              &nbsp;
              {/* {freshopState.currentPriceMode.f179} */}
            </div>
          </div>
          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}>
            <div>&nbsp;</div>
            <div
              className="sms-item-box"
              style={{
                maxWidth: "100px",
                marginLeft: "3px",
                marginRight: "3px"
              }}
            >
              {freshopState.currentPriceMod.f144}
            </div>
            <div
              className="sms-item-box"
              style={{
                maxWidth: "100px",
                marginLeft: "3px",
                marginRight: "3px"
              }}
            >
              {freshopState.currentPriceMod.f145}
            </div>
          </div>
          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}>
            <div>&nbsp;</div>
            <div>
              <label style={{ marginRight: "5px" }}>Tpr</label>
              <input
                type="checkbox"
                readOnly
                checked={freshopState.currentPriceMod.f1194}
                value={freshopState.currentPriceMod.f1194}
                style={{ opacity: "1", visibility: "visible" }}
              />
            </div>
            <div>
              <label style={{ marginRight: "5px" }}>Sal</label>
              <input
                type="checkbox"
                readOnly
                checked={freshopState.currentPriceMod.f1195}
                value={freshopState.currentPriceMod.f1195}
                style={{ opacity: "1", visibility: "visible" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="sms-row-spacer"></div>

      <div className="sms-price-row sms-row-spacer">
        <div className="sms-price-columns">
          <div className="sms-triple-column">
            <span className="sms-item-label">Pkg Price</span>
            <span className="sms-price-box">
              {freshopState.currentPriceMod.f140}
            </span>
            <span className="sms-item-box">
              {freshopState.currentPriceMod.f142}
            </span>
          </div>
          <div className="sms-triple-column">
            <span className="sms-item-label">&nbsp;</span>
            <span className="sms-price-box">
              {freshopState.currentPriceMod.f1186}
            </span>
            <span className="sms-item-box">
              {freshopState.currentPriceMod.f1187}
            </span>
          </div>
          <div className="sms-triple-column">
            <span className="sms-item-label">&nbsp;</span>
            <span className="sms-price-box">
              {freshopState.currentPriceMod.f139}
            </span>
            <span className="sms-item-box">
              {freshopState.currentPriceMod.f143}
            </span>
          </div>
          <div className="sms-triple-column">
            <span className="sms-item-label">&nbsp;</span>
            <span className="sms-price-box">
              {freshopState.currentPriceMod.f1196}
            </span>
            <span className="sms-item-box">
              {freshopState.currentPriceMod.f1197}
            </span>
          </div>
        </div>
      </div>

      <div className="sms-price-row sms-row-spacer">
        <div className="sms-price-columns">
          <div className="sms-triple-column">
            <span className="sms-item-label">Limited Qty</span>
            <span className="sms-price-box">
              {freshopState.currentPriceMod.f63}
            </span>
            <span className="sms-item-box">
              {freshopState.currentPriceMod.f62}
            </span>
          </div>
          <div className="sms-triple-column">
            <span className="sms-item-label">&nbsp;</span>
            <span className="sms-price-box">
              {freshopState.currentPriceMod.f1188}
            </span>
            <span className="sms-item-box">
              {freshopState.currentPriceMod.f1189}
            </span>
          </div>
          <div className="sms-triple-column">
            <span className="sms-item-label">&nbsp;</span>
            <span className="sms-price-box">
              {freshopState.currentPriceMod.f148}
            </span>
            <span className="sms-item-box">
              {freshopState.currentPriceMod.f147}
            </span>
          </div>
          <div className="sms-triple-column">
            <span className="sms-item-label">&nbsp;</span>
            <span className="sms-price-box">
              {freshopState.currentPriceMod.f1198}
            </span>
            <span className="sms-item-box">
              {freshopState.currentPriceMod.f1199}
            </span>
          </div>
        </div>
      </div>

      <div className="sms-price-row sms-row-spacer">
        <div className="sms-price-columns">
          <div className="sms-triple-column">
            <span className="sms-item-label">Disc $|%</span>
            <span className="sms-price-box">
              {freshopState.currentPriceMod.f111}
            </span>
            <span className="sms-item-box">
              {freshopState.currentPriceMod.f112}
            </span>
          </div>
          <div className="sms-triple-column">
            <span className="sms-item-label">&nbsp;</span>
            <span className="sms-price-box">
              {freshopState.currentPriceMod.f1218}
            </span>
            <span className="sms-item-box">
              {freshopState.currentPriceMod.f1219}
            </span>
          </div>
          <div className="sms-triple-column">
            <span className="sms-item-label">&nbsp;</span>
            <span className="sms-price-box">
              {freshopState.currentPriceMod.f220}
            </span>
            <span className="sms-item-box">
              {freshopState.currentPriceMod.f221}
            </span>
          </div>
          <div className="sms-triple-column">
            <span className="sms-item-label">&nbsp;</span>
            <span className="sms-price-box">
              {freshopState.currentPriceMod.f1222}
            </span>
            <span className="sms-item-box">
              {freshopState.currentPriceMod.f1223}
            </span>
          </div>
        </div>
      </div>

      <div className="sms-price-row sms-row-spacer">
        <div className="sms-price-columns">
          <div className="sms-triple-column">
            <span className="sms-item-label">Mix/Meth</span>
            <span className="sms-price-box">
              {parseInt(freshopState.currentPriceMod.f32)}
            </span>
            <span className="sms-item-box">
              {freshopState.currentPriceMod.f33}
            </span>
          </div>
          <div className="sms-triple-column">
            <span className="sms-item-label">&nbsp;</span>
            <span className="sms-price-box">
              {parseInt(freshopState.currentPriceMod.f1190)}
            </span>
            <span className="sms-item-box">
              {freshopState.currentPriceMod.f221}
            </span>
          </div>
          <div className="sms-triple-column">
            <span className="sms-item-label">&nbsp;</span>
            <span className="sms-price-box">
              {parseInt(freshopState.currentPriceMod.f1193)}
            </span>
            <span className="sms-item-box">
              {freshopState.currentPriceMod.f146}
            </span>
          </div>
          <div className="sms-triple-column">
            <span className="sms-item-label">&nbsp;</span>
            <span className="sms-price-box">
              {parseInt(freshopState.currentPriceMod.f1200)}
            </span>
            <span className="sms-item-box">
              {freshopState.currentPriceMod.f1201}
            </span>
          </div>
        </div>
      </div>

      <div className="sms-price-row sms-row-spacer">
        <div className="sms-price-columns">
          <div className="sms-triple-column">
            <div className="sms-item-label">Points</div>
            <div className="sms-price-box">
              {parseInt(freshopState.currentPriceMod.f169)}
            </div>
            <div>&nbsp;</div>
          </div>
          <div className="sms-triple-column">
            <div className="sms-item-label">&nbsp;</div>
            <div className="sms-price-box">
              {parseInt(freshopState.currentPriceMod.f1191)}
            </div>
          </div>
          <div className="sms-triple-column">
            <div className="sms-item-label">&nbsp;</div>
            <div className="sms-price-box">
              {parseInt(freshopState.currentPriceMod.f1192)}
            </div>
          </div>
          <div className="sms-triple-column">
            <div className="sms-item-label">&nbsp;</div>
            <div className="sms-price-box">
              {parseInt(freshopState.currentPriceMod.f1202)}
            </div>
          </div>
        </div>
      </div>

      <div className="sms-price-row sms-row-spacer">
        <div className="sms-triple-column-even">
          <div className="sms-triple-column">
            <div className="sms-item-label">Start</div>
            <div className="sms-price-box">
              {freshopState.currentPriceMod.f1234}
            </div>
            <div>&nbsp;</div>
          </div>

          <div className="sms-triple-column-even">
            <div className="sms-item-label">Red/Free</div>
            <div className="sms-item-box">
              {freshopState.currentPriceMod.f1228}
            </div>
            <div className="sms-item-box">
              {freshopState.currentPriceMod.f1232}
            </div>
          </div>

          <div className="sms-triple-column">
            <div className="sms-item-label">Like Code</div>
            <div className="sms-price-box">
              {freshopState.currentPriceMod.f122}
            </div>
            <div>&nbsp;</div>
          </div>
        </div>
      </div>

      <div className="sms-price-row sms-row-spacer">
        <div className="sms-triple-column-even">
          <div className="sms-triple-column">
            <div className="sms-item-label">End</div>
            <div className="sms-price-box">
              {freshopState.currentPriceMod.f1235}
            </div>
            <div>&nbsp;</div>
          </div>

          <div className="sms-triple-column-even">
            <div className="sms-item-label">Disc/Prog</div>
            <div className="sms-item-box">
              {freshopState.currentPriceMod.f1229}
            </div>
            <div className="sms-item-box">
              {freshopState.currentPriceMod.f2744}
            </div>
          </div>

          <div className="sms-triple-column">
            <div className="sms-item-label">Item Link</div>
            <div className="sms-price-box">
              {freshopState.currentPriceMod.f164}
            </div>
            <div>&nbsp;</div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SmsItem;
