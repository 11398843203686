import React, { useState, useEffect, useContext } from "react";
import { ContextConfig } from "../../App";
import { isEqual } from "../../fp";
import Spinner from "../../common/Spinner";
import { getClockUsers } from "../../api/UsersApi";
import { toast } from "react-toastify";
import { usePrevious, encrypt } from "../../utils";
import PasswordStrengthMeter from "../../common/PasswordStrengthMeter";
import Axios from "axios";
import qs from "qs";
import ClockDesktopMenu from "../ClockDesktopMenu";
import FloatingSidebar from "../../Dashboard/FloatingSidebar";
import { BrowserView, MobileView } from "react-device-detect";
import ClockSidebar from "../ClockSidebar";
//import colors from "../../common/colors";
import RoleControls from "../Roles/RoleControls";

const Reset = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  //const [userid, setUserid] = useState(0);
  const [user, setUser] = useState("");
  // eslint-disable-next-line
  const [companies, setCompanies] = useState([]);
  const [search, setSearch] = useState("");
  const [currentCompany, setCurrentCompany] = useState("1");

  const context = useContext(ContextConfig);

  const previousUsers = usePrevious(users);

  useEffect(() => {
    if (!isEqual(previousUsers, users)) {
      loadUsers();
    }
  }, []);

  const loadUsers = () => {
    setIsLoading(true);
    getClockUsers(context.clockUrl, context.clockToken, search)
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          setUsers(j.users);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        toast.error("An error occured loading the users", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const resetPassword = (password) => {
    setIsLoading(true);
    const encryptedPassword = encrypt(password, context.key).toString();
    Axios({
      method: "POST",
      cors: true,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + context.clockToken
      },
      url: context.clockUrl + "users/resetpassword",
      data: qs.stringify({
        userid: user,
        newPassword: encryptedPassword
      })
    })
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          toast.success("Your password has been reset", {
            position: toast.POSITION.TOP_LEFT
          });
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleUserChange = (e) => {
    setUser(e.target.value);
  };

  const handleCompanyChange = (e) => {
    setCurrentCompany(e.target.value);
    setUser("0");
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSearchClick = () => {
    loadUsers();
  };

  return (
    <React.Fragment>
      {isLoading && <Spinner />}
      <MobileView>
        <div className="clock-mobile">
          <div className="mobile-clock-header">
            <ClockSidebar />
            <div className="container cc-main-body">
              <h3>Links</h3>
              <RoleControls
                handleSubmit={handleSubmit}
                handleUserChange={handleUserChange}
                user={user}
                users={users}
                company={context.company}
                handleCompanyChange={handleCompanyChange}
                companies={companies}
                currentCompany={currentCompany}
                search={search}
                handleSearchChange={handleSearchChange}
                handleSearchClick={handleSearchClick}
                context={context}
              />

              <form>
                <PasswordStrengthMeter
                  userid={user}
                  confirmPasswordChange={resetPassword}
                />
              </form>
            </div>
          </div>
        </div>
      </MobileView>
      <BrowserView>
        <div>
          <ClockDesktopMenu />
          <FloatingSidebar />
          <div className="container">
            <RoleControls
              handleSubmit={handleSubmit}
              handleUserChange={handleUserChange}
              user={user}
              users={users}
              company={context.company}
              handleCompanyChange={handleCompanyChange}
              companies={companies}
              currentCompany={currentCompany}
              search={search}
              handleSearchChange={handleSearchChange}
              handleSearchClick={handleSearchClick}
              context={context}
            />
          </div>

          <div
            style={{
              marginTop: "20px",
              width: "100%",
              textAlign: "center",
              fontSize: "1.3rem",
              fontWeight: "bold"
            }}
          >
            Users
          </div>
          <div className="container">
            <form>
              <PasswordStrengthMeter
                userid={user}
                confirmPasswordChange={resetPassword}
              />
            </form>
          </div>
        </div>
      </BrowserView>
    </React.Fragment>
  );
};

export default Reset;
