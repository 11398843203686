import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { v4 as uuidv4 } from "uuid";
import { data } from "./StartQuery";
import PropTypes from "prop-types";
import ErrorBoundary from "../../common/ErrorBoundary";
import Popup from "./Popup";
import TransFinder from "./TransactionFinder";
import NormalField from "./NormalField";

const Visiblefields = (props) => {
  const columnsFromBackend = {
    [uuidv4()]: {
      name: "Columns",
      items: data
    },
    [uuidv4()]: {
      name: "Used",
      items: []
    }
  };
  const [columns, setColumns] = useState(columnsFromBackend);
  const [custom, setCustom] = useState("");
  const [showCustom, setShowCustom] = useState(false);
  const [popupId, setPopupId] = useState(0);

  useEffect(() => {
    if (props.columns && props.columns.length > 0 && props.editQuery) {
      let editableColumn;
      let fixedColumn;
      Object.keys(columns).map((column) => {
        let currentColumn = columns[column];
        if (currentColumn.name === "Columns") {
          fixedColumn = currentColumn;
        } else if (currentColumn.name === "Used") {
          editableColumn = currentColumn;
          editableColumn.items = props.columns;
        }
      });

      const newColumns = {
        fixedColumn,
        editableColumn
      };
      setColumns(newColumns);
    }
  }, [props.columns]);

  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems
        }
      });
      if (destColumn.name === "Used") {
        // check to see if we are using the raw sql field or not

        props.setColumns(destItems);
      } else {
        props.setColumns(sourceItems);
      }
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems
        }
      });
      props.setColumns(copiedItems);
    }
  };

  const showPopup = (item) => {
    setPopupId(item.id);
    setShowCustom(true);
  };

  const hide = () => {
    setShowCustom(false);
  };

  const updateCustom = (id) => {
    props.changeCustom(id, custom);
    setShowCustom(false);
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", height: "300px" }}>
      {showCustom && (
        <Popup
          isShowing={showCustom}
          hide={hide}
          custom={custom}
          setCustom={setCustom}
          changeCustom={updateCustom}
          popupId={popupId}
        />
      )}
      <DragDropContext
        onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
      >
        {Object.entries(columns).map(([columnId, column]) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: column.name === "Used" ? "75%" : "45%"
              }}
              key={columnId}
            >
              <h3>{column.name}</h3>
              <div
                style={{
                  margin: 8,
                  minHeight: "200px",
                  overflowY: "auto",
                  width: column.name === "Used" ? "90%" : "100%"
                }}
              >
                <Droppable droppableId={columnId} key={columnId}>
                  {(provided, snapshot) => {
                    return (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                          background: snapshot.isDraggingOver
                            ? "lightblue"
                            : "lightgrey",
                          padding: 4,
                          minHeight: "200px",
                          width: "100%"
                        }}
                      >
                        {column.items.map((item, index) => {
                          return (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                            >
                              {(provided, snapshot) => {
                                return (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                      userSelect: "none",
                                      padding: 10,
                                      margin: "0 0 8px 0",

                                      backgroundColor: snapshot.isDragging
                                        ? "#263B4A"
                                        : "#456C86",
                                      color: "white",
                                      ...provided.draggableProps.style
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "grid",
                                        gridTemplateColumns:
                                          column.name === "Columns"
                                            ? "1fr 1fr"
                                            : item.accessor == "transfinder"
                                            ? "1fr 100px"
                                            : ".75fr 2fr 1fr 1fr 75px"
                                      }}
                                    >
                                      <div
                                        style={{
                                          marginTop:
                                            column.name === "Used"
                                              ? "10px"
                                              : "0px"
                                        }}
                                      >
                                        {" "}
                                        {item.label}
                                      </div>
                                      {column.name === "Columns" ? (
                                        <div>Accessor: ({item.accessor})</div>
                                      ) : null}
                                      {column.name === "Used" ? (
                                        <ErrorBoundary>
                                          {item.accessor == "transfinder" ? (
                                            <TransFinder />
                                          ) : (
                                            <NormalField
                                              item={item}
                                              changeAlias={props.changeAlias}
                                              changeAggregate={
                                                props.changeAggregate
                                              }
                                              unLink={props.unLink}
                                              changeCss={props.changeCss}
                                              showPopup={showPopup}
                                            />
                                          )}
                                        </ErrorBoundary>
                                      ) : null}
                                    </div>
                                  </div>
                                );
                              }}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    );
                  }}
                </Droppable>
              </div>
            </div>
          );
        })}
      </DragDropContext>
    </div>
  );
};

Visiblefields.propTypes = {
  setColumns: PropTypes.func,
  changeAggregate: PropTypes.func,
  changeAlias: PropTypes.func,
  changeCss: PropTypes.func,
  columns: PropTypes.array,
  editQuery: PropTypes.bool,
  changeCustom: PropTypes.func,
  unLink: PropTypes.func
};

export default Visiblefields;
