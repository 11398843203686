import React, { useState, useEffect, useContext } from "react";
import ClockDesktopMenu from "../ClockDesktopMenu";
import CalendarHeader from "./CalendarHeader";
import { ContextConfig } from "../../App";
import { getWeekStart } from "../../api/StoreApi";
import { isValid, formatDate, usePrevious } from "../../utils";
import { toast } from "react-toastify";
import Spinner from "../../common/Spinner";
import { readUsersFromStore } from "../../api/UsersApi";
import Moment from "moment";
import { getHoursByStore } from "../../api/widgetsApi";
import DetailCalendar from "./DetailCalendar";
import { loadDepartments } from "../../api/departmentApi";
import "./Calendar.css";

const Calendar = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [weekStart, setWeekstart] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [store, setStore] = useState("");
  const [hours, setHours] = useState([]);
  const [filteredHours, setFilteredHours] = useState([]);
  const [systemReady, setSystemReady] = useState(false);
  const [reloadSchedule, setReloadSchedule] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(0);
  const [showDepartments, setShowDepartments] = useState(false);

  const context = useContext(ContextConfig);
  const previousWeekStart = usePrevious(weekStart);
  //const previousFilteredUsers = usePrevious(filteredUsers);

  const days = [0, 1, 2, 3, 4, 5, 6];

  useEffect(() => {
    if (isValid(store)) {
      fetchWeekStart();
      fetchUsers();
      getDepartments();
    } else {
      setStore(context.lastClockStore);
    }
  }, [store]);

  useEffect(() => {
    if (systemReady) {
      setReloadSchedule(!reloadSchedule);
    }
  }, [systemReady]);

  useEffect(() => {
    if (reloadSchedule) {
      getHours();
      return;
    }
    if (weekStart.length > 0) {
      if (previousWeekStart != weekStart) {
        getHours();
      }
    }
  }, [reloadSchedule, weekStart]);

  useEffect(() => {
    if (hours.length > 0 && users.length > 0) {
      filterUsers(hours);
    }
  }, [hours, users, filteredHours]);

  useEffect(() => {
    if (showDepartments && selectedDepartment !== 0) {
      const newArray = hours.filter(
        (h) => parseInt(h.departmentId) === parseInt(selectedDepartment)
      );
      setFilteredHours(newArray);
      filterUsers(newArray);
    } else {
      setFilteredHours(hours);
      filterUsers(hours);
    }
  }, [showDepartments, selectedDepartment]);

  const fetchWeekStart = () => {
    setIsLoading(true);
    getWeekStart(context.clockUrl, context.clockToken, store)
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          if (isValid(j.weekstart)) {
            const d = Moment(j.weekstart, "MM/DD/YYYY");
            const newd = d.add(context.settings.defaultScheduleWeek, "w");

            mergeDates(newd);
          }
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const fetchUsers = () => {
    setIsLoading(true);
    readUsersFromStore(context.clockUrl, context.clockToken, store)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setUsers(j.users);
          setFilteredUsers(j.users);
        } else {
          setUsers([]);
          debugger;
          setFilteredUsers([]);
          setReloadSchedule(!reloadSchedule);
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setUsers([]);
        debugger;
        setFilteredUsers([]);
        console.log(err);
        setIsLoading(false);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const mergeDates = (newd) => {
    setWeekstart(newd.format("MM/DD/YYYY"));
    setStartDate(new Date(newd.format("MM/DD/YYYY")));
    const ed = newd.add(6, "d");
    setEndDate(new Date(ed.format("MM/DD/YYYY")));
    setSystemReady(true);
  };

  const handlePreviousWeek = () => {
    const d = Moment(weekStart, "MM/DD/YYYY");
    const nd = d.add(-1, "w");
    mergeDates(nd);
  };

  const handleNextWeek = () => {
    const d = Moment(weekStart, "MM/DD/YYYY");
    const nd = d.add(1, "w");
    mergeDates(nd);
  };

  const getHours = () => {
    if (typeof store === "undefined" || store === null) return;
    if (store.length === 0) return;
    if (formatDate(startDate) === formatDate(endDate)) return;
    setIsLoading(true);
    getHoursByStore(
      context.clockUrl,
      context.clockToken,
      store,
      formatDate(startDate),
      formatDate(endDate)
    )
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          if (j.hours) {
            setHours(j.hours);
            setFilteredHours(j.hours);
          } else {
            setHours([]);
            setFilteredHours([]);
          }
        } else {
          setHours([]);
          setFilteredHours([]);
          toast.error(j.msg, {
            position: toast.POSITION_TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        setHours([]);
        setFilteredHours([]);
        toast.error("An Internal error occured", {
          position: toast.POSITION_TOP_LEFT
        });
      });
  };

  const getDepartments = () => {
    setIsLoading(true);
    loadDepartments(
      context.clockUrl,
      context.clockToken,
      context.lastClockStore
    )
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          setDepartments(j.departments);
        } else {
          setIsLoading(false);
          setDepartments([]);
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        setDepartments([]);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  Array.prototype.contains = function(field, value) {
    let foundRecord = false;
    this.forEach((record) => {
      if (record[field] === value) {
        foundRecord = true;
      }
    });

    return foundRecord;
  };

  const filterUsers = (shifts) => {
    if (shifts.length === 0) return;
    const newArray = [];
    shifts.map((shift) => {
      if (!newArray.contains("employeeId", shift.employeeId)) {
        const employee = users.find((u) => u.employeeId === shift.employeeId);
        if (employee) {
          newArray.push(employee);
        }
      }
    });
    debugger;
    setFilteredUsers(newArray);
  };

  return (
    <div className="d-schedule">
      {isLoading && <Spinner />}
      <ClockDesktopMenu />

      <div className="schedule-header-wrapper" id="schedule-header-wrapper">
        <div className="schedule-header-bar1">
          <div
            style={{
              width: "100%",
              marginTop: "20px",
              textAlign: "center",
              fontSize: "1.3rem",
              fontWeight: "bold"
            }}
          >
            Calendar
          </div>

          <CalendarHeader
            startDate={startDate}
            endDate={endDate}
            handlePreviousWeek={handlePreviousWeek}
            handleNextWeek={handleNextWeek}
            store={store}
            setStore={setStore}
            setReloadSchedule={setReloadSchedule}
            reloadSchedule={reloadSchedule}
            departments={departments}
            selectedDepartment={selectedDepartment}
            setSelectedDepartment={setSelectedDepartment}
            showDepartments={showDepartments}
            setShowDepartments={setShowDepartments}
          />
        </div>
      </div>
      <div className="calendar">
        <div>
          <DetailCalendar
            settings={context.settings}
            weekStart={weekStart}
            startDate={startDate}
            endDate={endDate}
            days={days}
            hours={filteredHours}
            users={filteredUsers}
            context={context}
            reloadSchedule={reloadSchedule}
            setReloadSchedule={setReloadSchedule}
            store={store}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default Calendar;
