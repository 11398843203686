import { getGeneralSettings } from "../../api/scheduleApi";
import { getUserPunches } from "../../api/punchApi";
import { isValid } from "../../utils";

function loadGeneralSettings(
  currentStore,
  setIsLoading,
  context,
  setGeneralSettings,
  setPunches,
  setMessage
) {
  if (!isValid(currentStore) || currentStore == "") {
    // if we dont have a current store, there is no need to send a request off
    return;
  }
  setIsLoading(true);
  getGeneralSettings(context.clockUrl, context.clockToken, currentStore)
    .then((response) => {
      setIsLoading(false);
      const j = response.data;
      if (j.error === 0) {
        context.generalSettings = j.settings;
        setGeneralSettings(j.settings);

        // now that we have the general settings for the appropriate store,
        // we can take the users current status and based on the settings, we can decide what kink of button that they need to see
      } else if (j.error === 3) {
        setGeneralSettings(j.settings);
        setMessage("No General Settings found");
        setPunches([]);
      } else {
        setMessage(j.msg);
        setGeneralSettings([]);
        setPunches([]);
      }
    })
    .catch((err) => {
      setIsLoading(false);
      setGeneralSettings([]);
      setPunches([]);
      console.log(err);
      setMessage("An error occured");
    });
}

function loadPunches(
  currentStore,
  setIsLoading,
  context,
  setPunches,
  setGeneralSettings,
  generalSettings,
  setTotal,
  setMessage,
  punchesLoaded
) {
  console.log("loadPunches", new Date());
  // setIsLoading(true);
  getUserPunches(
    context.clockUrl,
    context.clockToken,
    context.userid,
    null,
    currentStore
  )
    .then((response) => {
      console.log("done with loadPunches", new Date());
      // setIsLoading(false);
      const j = response.data;
      if (j.error === 0) {
        setPunches(j.punches);

        if (j.generalSettings) {
          if (isValid(j.generalSettings)) {
            setGeneralSettings(j.generalSettings);
            const hours = Math.round(
              parseFloat(j.totalMinutes) * 0.016667,
              j.generalSettings.timePrecision
            );
            setTotal(hours);
            punchesLoaded();
          }
        }
      } else {
        setMessage(j.msg);
      }
    })
    .catch((err) => {
      console.log("error on loadPunches", new Date());
      console.log(err);
      // setIsLoading(false);
      setMessage("An error occured");
    });
}

export { loadGeneralSettings, loadPunches };
