import React from "react";
import ReactDOM from "react-dom";
import * as actions from "../actions/actionTypes";
import StoreDetail from "./StoreDetail";

import "../Clock/widgets/animations.css";

import "./Kpi.css";

const Kpi = ({ state, dispatch }) =>
  state.showModal
    ? ReactDOM.createPortal(
        <React.Fragment>
          <div className="modal-kpi-overlay" />
          <div
            className="modal-kpi-wrapper"
            aria-modal
            aria-hidden
            tabIndex={-1}
            role="dialog"
          >
            <div className="modal-kpi">
              <div className="modal-kpi-header">
                <span className="modal-kpi-confirm-title">
                  Store Comparison
                </span>
                <span className="modal-kpi-close-box">
                  <button
                    type="button"
                    className="modal-kpi-close-button"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() =>
                      dispatch({
                        type: actions.KPI_SHOW_MODAL,
                        showModal: false
                      })
                    }
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </span>
              </div>
              <div className="modal-kpi-body">
                <div>
                  <StoreDetail
                    data={state.storeData1}
                    store={state.leftSide}
                    other={state.storeData2}
                  />
                </div>
                <div>
                  <StoreDetail
                    data={state.storeData2}
                    store={state.rightSide}
                    other={state.storeData1}
                  />
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>,
        document.body
      )
    : null;

export default Kpi;
