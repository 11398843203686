const createIcon = (concern, purples) => {
  let result = "fas fa-check-circle";
  switch (concern) {
    case 0:
      result = "fas fa-check-circle";
      break;
    case 1:
      result = "fas fa-exclamation-triangle";
      break;
    case 2:
      result = "fas fa-exclamation-circle";
      break;
    case 3:
      result = "fas fa-bomb";
      break;
    default:
      result = "fas fa-check-circle";
      break;
  }

  return purples > 0 ? "fas fa-radiation" : result;
};

const createColor = (concern, purples) => {
  let result = "ccgreen";
  switch (concern) {
    case 1:
      result = "ccyellow";
      break;
    case 2:
      result = "ccorange";
      break;
    case 3:
      result = "ccred";
      break;
    default:
      result = "ccgreen";
      break;
  }
  return purples > 0 ? "ccpurple" : result;
};

export { createIcon, createColor };
