import React, { useContext, useState, useEffect } from "react";
import HeaderRB from "../common/HeaderRb";
import { ContextConfig } from "../App";
import * as actions from "../actions/actionTypes";
import ErrorBoundary from "../common/ErrorBoundary";
import { debounce, isEqual } from "../fp";
import { useSelector, useDispatch } from "react-redux";
import { isValid, usePrevious } from "../utils";
import { toast } from "react-toastify";
import {
  getLinkedStores,
  savePreferences,
  getPreferences,
  getPayload
} from "../api/freshopApi";
import DatePicker from "react-datepicker";
//import { Row, Col } from "reactstrap";
import UnclosedOrders from "./UnclosedOrders";
import PriceModifications from "./mods/PriceModifications";
import ItemErrors from "./ItemErrors";
import TrueUpError from "./TrueUpError";
import Orders from "./Orders";
import { isMobile } from "react-device-detect";

import OpenOrderWidget from "./OpenOrderWidget";
import PriceModWidget from "./mods/PriceModWidget";
import ItemErrorWidget from "./ItemErrorWidget";
import TrueUpWidget from "./TrueUpWidget";
import Metrics from "./Metrics";
import OrdersWidget from "./OrdersWidget";
import PayloadModal from "./PayloadModal";

import "./Freshop.css";

const Freshop = () => {
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const freshopState = useSelector((app) => app.freshopReducer);
  const dispatch = useDispatch();

  const context = useContext(ContextConfig);
  const previousStores = usePrevious(freshopState.stores);
  //const previousOrderid = usePrevious(freshopState.orderIdForPayload);

  useEffect(() => {
    if (!isEqual(previousStores, freshopState.stores)) {
      getStores();
    }
    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }

      return () => {
        dispatch({ type: actions.REF_ENABLE_CASHIER, enableCashier: false });
      };
    }, 500);

    findDivHeight();

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, [
    freshopState.startDate,
    freshopState.endDate,
    freshopState.selectedStore
  ]);

  useEffect(() => {
    if (freshopState.stores.length > 0) {
      getPrefs();
    }
  }, [freshopState.stores]);

  useEffect(() => {
    if (Object.keys(freshopState.payload).length > 0) {
      dispatch({ type: actions.FRESHOP_SHOW_ORDER_MODAL, isShowing: true });
    }
  }, [freshopState.payload]);

  useEffect(() => {
    if (freshopState.orderIdForPayload > 0) {
      fetchPayload();
    }
  }, [freshopState.orderIdForPayload]);

  const findDivHeight = () => {
    const navbar = document
      .getElementById("master-navbar")
      .getBoundingClientRect().height;
    const controls = document.getElementById("controls").getBoundingClientRect()
      .height;
    const usedSpace = navbar + controls;
    const remaining = window.innerHeight - usedSpace;

    const body = document.getElementById("body");
    body.style.height = remaining + "px";
    body.style.overflow = "auto";
  };

  const getPrefs = () => {
    getPreferences(context.freshopApi, context.userid, context.freshopAppKey)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          const selectedStore = freshopState.stores.find(
            (s) => s.id == j.prefs[0].lastStoreid
          );
          if (isValid(selectedStore)) {
            save(selectedStore);
            dispatch({
              type: actions.FRESHOP_SET_SELECTED_STORE,
              store: selectedStore
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An Internal error occured", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const save = (store) => {
    savePreferences(
      context.freshopApi,
      store.id,
      context.userid,
      context.freshopAppKey
    )
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          console.log("lastStoreid saved");
        } else {
          toast.warning(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An internal error occured", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const getStores = () => {
    getLinkedStores(
      context.freshopApi,
      context.userid,
      context.company,
      context.freshopAppKey
    )
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          dispatch({
            type: actions.FRESHOP_SET_STORELIST,
            stores: j.linkedStores
          });
        } else {
          toast.warning("There are no stores assigned to this user", {
            position: toast.POSITION.TOP_LEFT
          });
          dispatch({ type: actions.FRESHOP_SET_STORELIST, stores: [] });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An Internal Error occured", {
          position: toast.POSITION.TOP_LEFT
        });
        dispatch({ type: actions.FRESHOP_SET_STORELIST, stores: [] });
      });
  };

  const handleStoreChange = (e) => {
    const selectedStore = freshopState.stores.find(
      (s) => s.id == e.target.value
    );
    save(selectedStore);
    dispatch({
      type: actions.FRESHOP_SET_SELECTED_STORE,
      store: selectedStore
    });
  };

  const fetchPayload = () => {
    getPayload(
      context.freshopApi,
      freshopState.orderIdForPayload,
      context.freshopAppKey
    )
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          dispatch({
            type: actions.FRESHOP_SET_PAYLOAD,
            payload: JSON.parse(j.payload)
          });
        } else {
          dispatch({ type: actions.FRESHOP_SET_PAYLOAD, payload: {} });
          toast.warning(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: actions.FRESHOP_SET_PAYLOAD, payload: {} });
        toast.error("An internal error occured", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  return (
    <div>
      <HeaderRB />
      {freshopState.showOrderModal && (
        <PayloadModal
          state={freshopState}
          payload={freshopState.payload}
          dispatch={dispatch}
          dimensions={dimensions}
        />
      )}
      <ErrorBoundary>
        <div id="controls">
          <div className="container">
            <h3>Freshop</h3>
            <div className="row justify-content-center">
              <div className="col-xs-12 col-sm-6 col-md-4 align-items-center justify-content-center d-flex mt-1">
                <select
                  className="form-control"
                  value={freshopState.selectedStore.id}
                  onChange={handleStoreChange}
                >
                  <option value="0">Select a Store</option>
                  {freshopState.stores.length > 0 ? (
                    <React.Fragment>
                      {freshopState.stores.map((store, index) => (
                        <option key={`fs-${index}`} value={store.id}>
                          {store.name}
                        </option>
                      ))}
                    </React.Fragment>
                  ) : (
                    <option value="-1">There are no stores to display</option>
                  )}
                </select>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4 align-items-center justify-content-center d-flex mt-1">
                <span style={{ marginRight: "10px" }}>Start:</span>
                <DatePicker
                  selected={freshopState.startDate}
                  onChange={(e) =>
                    dispatch({ type: actions.FRESHOP_SET_START_DATE, date: e })
                  }
                />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4 align-items-center justify-content-center d-flex mt-1">
                <span style={{ marginRight: "10px" }}>End:</span>
                <DatePicker
                  selected={freshopState.endDate}
                  onChange={(e) =>
                    dispatch({ type: actions.FRESHOP_SET_END_DATE, date: e })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </ErrorBoundary>
      <div id="body">
        <div className="container" style={{ marginTop: "30px" }}>
          <ErrorBoundary>
            {!isMobile && <Metrics />}
            <div className="row justify-content-center w-100">
              <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <UnclosedOrders />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <PriceModifications />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <ItemErrors />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <TrueUpError />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <Orders />
              </div>
            </div>
            {freshopState.showOpenOrders && (
              <OpenOrderWidget freshopState={freshopState} />
            )}
            {freshopState.showPriceMods && (
              <PriceModWidget freshopState={freshopState} />
            )}
            {freshopState.showItemErrors && (
              <ItemErrorWidget freshopState={freshopState} />
            )}
            {freshopState.showTrueupErrors && (
              <TrueUpWidget freshopState={freshopState} />
            )}
            {freshopState.showOrders && (
              <OrdersWidget freshopState={freshopState} dispatch={dispatch} />
            )}
          </ErrorBoundary>
        </div>
      </div>
    </div>
  );
};

export default Freshop;
