import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from "@material-ui/pickers";
import "./ModalCreatePunch.css";
import { useSelector, useDispatch } from "react-redux";
import { isEqual } from "../../fp";
import { roundPunch } from "../clockUtils";
import { toast } from "react-toastify";
import { enterManualPunch } from "../../api/punchApi";
import {
  isValidDate,
  formatTimeAmPm,
  formatDate,
  usePrevious,
  isValid
} from "../../utils";

import * as actions from "../../actions/actionTypes";

const ModalCreatePunch = ({ isShowing, hide, context, punch, punchType }) => {
  const state = useSelector((app) => app.editReducer);
  const dispatch = useDispatch();

  const previousPunch = usePrevious(state.punch);

  useEffect(() => {
    // first lets determin if we are allowed to make edit or not
    if (!isEqual(previousPunch, state.punch)) {
      if (!isValid(previousPunch)) {
        dispatch({
          type: actions.EDIT_SET_PUNCH,
          punch: {
            ...punch,
            punchType,
            punchIn: new Date(punch.punchDate),
            punchOut: new Date(punch.punchDate),
            timeIn: null,
            timeOut: null
          }
        });
        return;
      }
      if (isValidDate(state.punch.timeOut)) {
        if (!state.punch.rawPunchIn) {
          state.punch.rawPunchIn =
            formatDate(state.punch.punchIn) +
            " " +
            formatTimeAmPm(state.punch.punchIn);
        }
        if (!state.punch.rawPunchOut) {
          state.punch.rawPunchOut =
            formatDate(state.punch.punchOut) +
            " " +
            formatTimeAmPm(state.punch.punchOut);
        }

        const { totalTime } = roundPunch(state.punch, context.settings);

        if (totalTime < 0) {
          toast.error(
            "You cannot have an out date that is previous to the in Date",
            {
              position: toast.POSITION.TOP_LEFT,
              duration: 5000
            }
          );

          dispatch({
            type: actions.EDIT_SET_PUNCH,
            punch: {
              ...punch,
              punchType,
              punchIn: new Date(punch.punchDate),
              punchOut: new Date(punch.punchDate),
              timeIn: null,
              timeOut: null
            }
          });
          return;
        }
        dispatch({
          type: actions.EDIT_SET_PUNCH,
          punch: { ...state.punch, total: totalTime }
        });
      }
    }
  }, [state.punch, state.existingPunch]);

  useEffect(() => {
    if (isValid(previousPunch)) {
      formIsValid();
    }
  }, [state.punch]);

  useEffect(() => {}, [state.errors]);

  function formIsValid() {
    const _errors = {};
    if (!state.punch.punchType) _errors.punchType = "Punch Type Required";
    if (!state.punch.punchIn) _errors.punchIn = "Punch In Required";
    if (!state.punch.timeIn) _errors.timeIn = "Time in Required";

    dispatch({ type: actions.EDIT_SET_ERROR, errors: _errors });
    // form is valid is errors object has no properties
    return Object.keys(_errors).length === 0;
  }

  const handleChange = (e) => {
    let changeText = state.punch.editType;
    if (state.punch.editType) {
      if (!state.punch.editType.toUpperCase().includes("PUNCHTYPE")) {
        changeText = changeText + "punchType,";
      }
    } else {
      changeText = "punchType,";
    }

    dispatch({
      type: actions.EDIT_SET_PUNCH,
      punch: {
        ...state.punch,
        storeid: context.lastClockStore,
        comment: "",
        punchType: e.target.value,
        edited: true,
        editType: changeText
      }
    });
  };

  const handleDateInChange = (date) => {
    if (date < context.startDate) {
      toast.error("Your Punch in date cannot be prior to week start", {
        position: toast.POSITION.TOP_LEFT,
        duration: 5000
      });

      return;
    }
    let changeText = state.punch.editType;
    if (state.punch.editType) {
      if (!state.punch.editType.toUpperCase().includes("DATEIN")) {
        changeText = changeText + "dateIn,";
      }
    } else {
      changeText = "dateIn,";
    }

    if (!isValidDate(state.punch.punchOut)) {
      dispatch({
        type: actions.EDIT_SET_PUNCH,
        punch: {
          ...state.punch,
          punchIn: date,
          punchOut: date,
          edited: true,
          editType: changeText
        }
      });
    } else {
      dispatch({
        type: actions.EDIT_SET_PUNCH,
        punch: {
          ...state.punch,
          punchIn: date,
          edited: true,
          editType: changeText
        }
      });
    }
  };

  const handleInChange = (punchin) => {
    let changeText = state.punch.editType;
    if (state.punch.editType) {
      if (state.punch.editType) {
        if (!state.punch.editType.toUpperCase().includes("TIMEIN")) {
          changeText = changeText + "timeIn,";
        }
      } else {
        changeText = "timeIn,";
      }
    } else {
      changeText = "timeIn,";
    }

    dispatch({
      type: actions.EDIT_SET_PUNCH,
      punch: {
        ...state.punch,
        timeIn: state.punch.punchIn
          ? formatDate(state.punch.punchIn) + " " + formatTimeAmPm(punchin)
          : formatDate(new Date()) + " " + formatTimeAmPm(punchin),
        rawPunchIn: state.punch.punchIn
          ? formatDate(state.punch.punchIn) + " " + formatTimeAmPm(punchin)
          : formatDate(new Date()) + " " + formatTimeAmPm(punchin),
        punchIn: state.punch.punchIn
          ? formatDate(state.punch.punchIn)
          : formatDate(new Date()),
        edited: true,
        editType: changeText
      }
    });
  };

  const handleDateOutChange = (date) => {
    let changeText = state.punch.editType;
    if (state.punch.editType) {
      if (!state.punch.editType.toUpperCase().includes("DATEOUT")) {
        changeText = changeText + "dateOut,";
      }
    } else {
      changeText = "dateOut,";
    }

    dispatch({
      type: actions.EDIT_SET_PUNCH,
      punch: {
        ...state.punch,
        punchOut: date,
        edited: true,
        editType: changeText
      }
    });
  };

  const handleOutChange = (punchout) => {
    let changeText = state.punch.editType;
    if (state.punch.editType) {
      if (!state.punch.editType.toUpperCase().includes("TIMEOUT")) {
        changeText = changeText + "timeout,";
      }
    } else {
      changeText = "timeout,";
    }

    dispatch({
      type: actions.EDIT_SET_PUNCH,
      punch: {
        ...state.punch,
        timeOut: state.punch.punchOut
          ? formatDate(state.punch.punchOut) + " " + formatTimeAmPm(punchout)
          : formatDate(new Date()) + " " + formatTimeAmPm(punchout),
        rawPunchOut: state.punch.punchOut
          ? formatDate(state.punch.punchOut) + " " + formatTimeAmPm(punchout)
          : formatDate(new Date()) + " " + formatTimeAmPm(punchout),
        edited: true,
        editType: changeText
      }
    });
  };

  const handleCommentChange = (e) => {
    let changeText = state.punch.editType;
    if (state.punch.editType) {
      if (!state.punch.editType.toUpperCase().includes("COMMENT")) {
        changeText = changeText + "comment,";
      }
    } else {
      changeText = "comment,";
    }

    dispatch({
      type: actions.EDIT_SET_PUNCH,
      punch: {
        ...state.punch,
        comment: e.target.value,
        edited: true,
        editType: changeText
      }
    });
  };

  const handleClick = () => {
    state.punch.punchType = punchType;
    if (!formIsValid()) return;

    if (!state.punch.storeid) {
      state.punch.storeid = context.lastClockStore;
    }
    if (state.punch.storeid === 0) {
      state.punch.storeid = context.lastClockStore;
    }
    debugger;
    if (isValid(context.punch)) {
      console.log("using context.punch", context.punch);
      if (isValid(context.punch.selectedUser)) {
        state.punch.userid = context.punch.selectedUser;
      } else {
        state.punch.userid = context.userid;
      }
      console.log("userid is " + state.punch.userid);
    } else {
      console.log("state.punch.userid", state.punch.userid);
      if (state.punch.userid === 0) {
        console.log("using userid from context", context.userid);
        state.punch.userid = context.userid;
      }
    }

    process.env.NODE_ENV === "development"
      ? (state.punch.mode = "debug")
      : (state.punch.mode = "live");

    if (isValidDate(state.punch.punchIn)) {
      const dte = new Date(state.punch.punchIn);
      state.punch.timeZoneOffset = dte.getTimezoneOffset();
      state.punch.isDst = dte.isDstObserved();
    }

    // make the punch in and punch out simple date strings to avoid the timezone offsets
    state.punch.punchIn =
      formatDate(state.punch.punchIn) +
      " " +
      formatTimeAmPm(state.punch.timeIn);

    if (
      typeof state.punch.timeOut !== "undefined" &&
      state.punch.timeOut !== null
    ) {
      state.punch.punchOut =
        formatDate(state.punch.punchOut) +
        " " +
        formatTimeAmPm(state.punch.timeOut);
    } else {
      state.punch.punchOut = null;
    }
    console.log("punch", punch);

    enterManualPunch(context.clockUrl, context.clockToken, state.punch)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          dispatch({ type: actions.EDIT_SET_PUNCH, punch: punch });
          dispatch({ type: actions.EDIT_SET_RERENDER });
          hide();
        } else {
          toast.warning(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An internal error occurred", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  if (isShowing) {
    return ReactDOM.createPortal(
      <React.Fragment>
        <div className="modal-punch-overlay" />
        <div
          className="modal-punch-wrapper"
          aria-modal
          aria-hidden
          tabIndex={-1}
          role="dialog"
        >
          <div className="modal-punch">
            <div className="modal-punchheader">
              <span className="modal-punch-confirm-title">punch</span>
              <span className="modal-punch-close-box">
                <button
                  type="button"
                  className="modal-punch-close-button"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={hide}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </span>
            </div>
            <div className="modal-punch-body">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-around">
                  <div className="row w-100">
                    <div className="row w-100">
                      <div className="col-3">
                        <label>Punch Type</label>
                        <select
                          className="form-control"
                          value={punchType}
                          tabIndex="0"
                          readOnly={true}
                          onChange={handleChange}
                        >
                          <option value="0">PunchType</option>
                          <option value="1">Punch</option>
                          <option value="2">Break</option>
                          <option value="3">Lunch</option>
                        </select>
                      </div>

                      <div className="col-3">
                        <KeyboardDatePicker
                          format="MM/dd/yyyy"
                          margin="normal"
                          tabIndex="5"
                          label="Date In"
                          value={state.punch.punchIn}
                          onChange={handleDateInChange}
                          KeyboardButtonProps={{
                            "aria-label": "change date"
                          }}
                        />
                        {state.errors.punchIn && (
                          <div className="alert alert-danger">
                            {state.errors.punchIn}
                          </div>
                        )}
                      </div>
                      <div className="col-3">
                        <KeyboardTimePicker
                          margin="normal"
                          label="Time In"
                          tabIndex="6"
                          value={state.punch.timeIn}
                          onChange={handleInChange}
                          KeyboardButtonProps={{
                            "aria-label": "change time"
                          }}
                        />

                        {state.errors.timeIn && (
                          <div className="alert alert-danger">
                            {state.errors.timeIn}
                          </div>
                        )}
                      </div>

                      <div className="col d-manual-entry-submit">
                        <button
                          className="btn btn-outline-dark"
                          onClick={handleClick}
                          tabIndex="7"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                    <div className="row w-100">
                      <div className="col-3">
                        <label>Comment</label>
                        <input
                          type="text"
                          tabIndex="4"
                          value={state.punch.comment || ""}
                          className="form-control"
                          onChange={handleCommentChange}
                        />
                      </div>
                      <div className="col-3">
                        <KeyboardDatePicker
                          format="MM/dd/yyyy"
                          margin="normal"
                          tabIndex="5"
                          label="Date Out"
                          value={state.punch.punchOut}
                          onChange={handleDateOutChange}
                          KeyboardButtonProps={{
                            "aria-label": "change date"
                          }}
                        />
                      </div>
                      <div className="col-3">
                        <KeyboardTimePicker
                          margin="normal"
                          label="Time out"
                          tabIndex="6"
                          value={state.punch.timeOut}
                          onChange={handleOutChange}
                          KeyboardButtonProps={{
                            "aria-label": "change time"
                          }}
                        />
                      </div>
                      <div className="col-3">
                        <div className="row">
                          <div className="col d-manual-entry-total-div">
                            <span>
                              <span>Total</span>
                              <div
                                className="d-manual-entry-total"
                                style={{ minHeight: "30px" }}
                              >
                                {state.punch.total}
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              </MuiPickersUtilsProvider>
            </div>
          </div>
        </div>
      </React.Fragment>,
      document.body
    );
  } else {
    return <div></div>;
  }
};

export default ModalCreatePunch;
