import React, { useState, useEffect, useContext } from "react";
import { Row, Col } from "reactstrap";
import HeaderRB from "../common/HeaderRb";
import Spinner from "../common/Spinner";
import { ContextConfig } from "../App";
import { GetUsersByCompany } from "../api/UsersApi";
import { toast } from "react-toastify";
import { getAssigned, getUnAssigned } from "../api/StoreApi";
import { debounce } from "../fp";
import Unassigned from "./Unassigned";
import Assigned from "./Assigned";
import Trash from "./Trash";
import Tooltip from "../Tooltip/Tooltip";
import { getLinkedStores } from "../api/freshopApi";
import FreshopAssigned from "./FreshopAssigned";
import FreshopUnassigned from "./FreshopUnassigned";
import FreshopTrash from "./FreshopTrash";
import "./Settings.css";

const Settings = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [userid, setUserid] = useState(0);
  const [assigned, setAssigned] = useState([]);
  const [unassigned, setUnaassigned] = useState([]);
  const [filteredUnassigned, setFilteredUnassigned] = useState([]);
  const [filteredAssigned, setFilteredAssigned] = useState([]);
  const [search, setSearch] = useState("");
  const [useFreshop, setUseFreshop] = useState(false);
  const [freshopAssigned, setFreshopAssigned] = useState([]);
  const [freshopUnassigned, setFreshopUnassigned] = useState([]);
  const [filteredFreshopAssigned, setFilteredFreshopAssigned] = useState([]);
  const [filteredFreshopUnassigned, setFilteredFreshopUnassigned] = useState(
    []
  );
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });
  const [reRender, setRerender] = useState(false);
  const [reRead, setReRead] = useState(false);

  const context = useContext(ContextConfig);

  useEffect(() => {
    loadUsers();

    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);

    findDivHeight();

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, []);

  useEffect(() => {
    console.log("useEffect from reRender");
    if (userid > 0) {
      if (useFreshop) {
        loadFreshopAssigned();
      } else {
        loadAssigned();
        loadUnassigned();
      }
    }
  }, [reRender]);

  useEffect(() => {
    console.log("useEffect for userid change");
    if (userid > 0) {
      if (useFreshop) {
        loadFreshopAssigned();
      } else {
        loadAssigned();
        loadUnassigned();
      }
    }
  }, [userid]);

  useEffect(() => {
    console.log("useEffect for freshop change");
    if (userid > 0) {
      if (useFreshop) {
        loadFreshopAssigned();
      } else {
        loadAssigned();
        loadUnassigned();
      }
    }
  }, [useFreshop]);

  useEffect(() => {}, [filteredFreshopAssigned]);

  const findDivHeight = () => {
    const navbar = document.getElementById("master-navbar");
    const controls = document.getElementById("settings-controls");
    const usedHeight =
      navbar.getBoundingClientRect().height +
      controls.getBoundingClientRect().height +
      40;

    const remaining = window.innerHeight - usedHeight;
    const body = document.getElementById("settings-body");
    body.style.height = remaining + "px";
    // const unnassignedDiv = document.getElementById("unassigned");
    // unnassignedDiv.style.height = remaining + "px";
    // const assignedDiv = document.getElementById("assigned");
    // assignedDiv.style.height = remaining + "px";
  };

  useEffect(() => {
    if (parseInt(userid) > 0) {
      if (useFreshop) {
        loadFreshopAssigned();
      } else {
        loadAssigned();
        loadUnassigned();
      }
    }
  }, [reRead]);

  const loadUsers = () => {
    setIsLoading(true);
    GetUsersByCompany(context.url, context.token, context.company)
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          setUsers(j.users);
          setFilteredUsers(j.users);
        } else {
          setUsers([]);
          setFilteredUsers([]);
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        setUsers([]);
        setFilteredUsers([]);
        toast.error("An error occured loading the users", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const handleSelect = (e) => {
    console.log("settings userid to " + e.target.value);
    setUserid(e.target.value);
  };

  const loadAssigned = () => {
    setIsLoading(true);
    getAssigned(context.url, context.token, userid)
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          setAssigned(j.stores);
          setFilteredAssigned(j.stores);
        } else {
          setAssigned([]);
          setFilteredAssigned([]);
          toast.warning(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setAssigned([]);
        setFilteredAssigned([]);
        toast.error("An error occured getting the assigned stores", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const loadUnassigned = () => {
    setIsLoading(true);
    getUnAssigned(context.url, context.token, userid)
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          setUnaassigned(j.stores);
          setFilteredUnassigned(j.stores);
        } else {
          setUnaassigned([]);
          setFilteredUnassigned([]);
          toast.warning(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setUnaassigned([]);
        setFilteredUnassigned([]);
        toast.error("An error occured getting the unassigned stores", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const loadFreshopAssigned = () => {
    // we need to get the company out of the user first to make sure we get the right results
    const selectedUser = users.filter((u) => u.id == userid)[0];
    let company = 0;
    if (typeof selectedUser !== "undefined" && selectedUser !== null) {
      company = selectedUser.company;
    } else {
      toast.warning("Cannot find users Company", {
        position: toast.POSITION.TOP_LEFT
      });
      return;
    }
    getLinkedStores(context.freshopApi, userid, company, context.freshopAppKey)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setFreshopAssigned(j.linkedStores);
          setFilteredFreshopAssigned(j.linkedStores);
          setFreshopUnassigned(j.unlinkedStores);
          setFilteredFreshopUnassigned(j.unlinkedStores);
        } else {
          toast.warning(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An internal error occured", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const handleSearchUsers = (e) => {
    const str = e.target.value;
    const filtered = users.filter((u) =>
      u.username.toLowerCase().includes(str.toLowerCase())
    );
    setFilteredUsers(filtered);
    setSearch(e.target.value);
    setUserid(0);
  };

  const handleFreshopChange = (e) => {
    if (e.target.checked) {
      setUseFreshop(true);
    } else {
      setUseFreshop(false);
    }
  };

  return (
    <div>
      {isLoading && <Spinner />}
      <HeaderRB />
      <div className="container sales-container" id="sales-container">
        <div id="settings-controls">
          <h3>Assign User Stores</h3>

          <Row>
            <Col xs="6">
              <Row>
                <Col xs="6">
                  <select
                    className="form-control"
                    onChange={handleSelect}
                    value={userid}
                  >
                    <option value="0">Please select a user</option>
                    {users.length > 0
                      ? filteredUsers.map((user, index) => (
                          <option key={`user-${index}`} value={user.id}>
                            {user.username}
                          </option>
                        ))
                      : null}
                  </select>
                </Col>
                <Col xs="6">
                  <label>
                    <Tooltip
                      message={
                        "Check this box if you want to assign and unassign Freshop Stores to users"
                      }
                      position={"bottom"}
                    >
                      Search Freshop Stores
                    </Tooltip>
                  </label>
                  <input
                    type="checkbox"
                    value={useFreshop}
                    onChange={handleFreshopChange}
                    checked={useFreshop}
                    style={{
                      opacity: 1,
                      visibility: "visible",
                      marginLeft: "5px"
                    }}
                  />
                </Col>
                <Col xs="12">
                  <label>Search for Users:</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleSearchUsers}
                    value={search}
                  />
                </Col>
              </Row>
            </Col>
            <Col cs="6">
              <div className="assign-title">Instructions</div>
              <p className="assign-instructions">
                Select a store from the first column and drag it into the
                Assigned column to give the user access to view that store. You
                can also take and drag an already assigned store into the trash
                area to unassign that store.
              </p>
            </Col>
          </Row>
        </div>
        <div id="settings-body">
          <Row
            style={{
              padding: "20px",
              justifyContent: "space-around",
              height: "100%"
            }}
          >
            {useFreshop ? (
              <FreshopUnassigned
                stores={filteredFreshopUnassigned}
                unassigned={freshopUnassigned}
                setFilteredUnAssigned={setFilteredFreshopUnassigned}
              />
            ) : (
              <Unassigned
                stores={filteredUnassigned}
                unassigned={unassigned}
                setFilteredUnAssigned={setFilteredUnassigned}
              />
            )}
            {useFreshop ? (
              <FreshopTrash
                context={context}
                reRender={reRender}
                setReRender={setRerender}
                userid={userid}
                users={users}
                reRead={reRead}
                setReRead={setReRead}
              />
            ) : (
              <Trash
                context={context}
                reRender={reRender}
                setReRender={setRerender}
                userid={userid}
                users={users}
                reRead={reRead}
                setReRead={setReRead}
              />
            )}

            {useFreshop ? (
              <FreshopAssigned
                stores={filteredFreshopAssigned}
                context={context}
                userid={userid}
                reRender={reRender}
                setRerender={setRerender}
                setFilteredAssigned={setFilteredFreshopAssigned}
                assigned={freshopAssigned}
              />
            ) : (
              <Assigned
                stores={filteredAssigned}
                context={context}
                userid={userid}
                reRender={reRender}
                setRerender={setRerender}
                setFilteredAssigned={setFilteredAssigned}
                assigned={assigned}
              />
            )}
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Settings;
