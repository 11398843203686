import { combineReducers } from "redux";
import punchcardReducer from "./punchcardReducer";
import loginClockReducer from "./loginClockReducer";
import hoursTableReducer from "./hoursTableReducer";
import linkReducer from "./linkReducer";
import editReducer from "./editReducer";
import appReducer from "./appReducer";
import refundReducer from "./refundReducer";
import refundTableReducer from "./refundTableReducer";
import chargesReducer from "./chargesReducer";
import freshopReducer from "./freshopReducer";
import kpiReducer from "./kpiReducer";
import form4Reducer from "./form4Reducer";
import settingsReducer from "./settingsReducer";
import itemsetsReducer from "./itemsetsReducer";

export default combineReducers({
  punchcardReducer,
  loginClockReducer,
  hoursTableReducer,
  linkReducer,
  editReducer,
  appReducer,
  refundReducer,
  refundTableReducer,
  chargesReducer,
  freshopReducer,
  kpiReducer,
  form4Reducer,
  settingsReducer,
  itemsetsReducer
});
