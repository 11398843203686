import React from "react";
import PropTypes from "prop-types";
import Tooltip from "../../Tooltip/Tooltip";

const NormalField = ({ item, showPopup, ...props }) => {
  return (
    <React.Fragment>
      {item.custom && item.custom.length > 0 ? (
        <div>
          <input
            type="text"
            readOnly={true}
            value={item.custom}
            className="form-control"
            style={{
              backgroundColor: "#fff",
              color: "#000"
            }}
          />
        </div>
      ) : (
        <React.Fragment>
          <div>
            <select
              className="form-control"
              value={item.aggregate}
              onChange={(e) => props.changeAggregate(item, e.target.value)}
            >
              <option value="0">Aggregate</option>
              <option value="1">Min</option>
              <option value="2">Max</option>
              <option value="3">Avg</option>
              <option value="4">Cnt</option>
              <option value="5">Sum</option>
              <option value="6">Distinct</option>
            </select>
          </div>
          {item.aggregate &&
          item.aggregate.length > 0 &&
          item.aggregate != "0" ? (
            <div
              style={{
                marginLeft: "5px"
              }}
            >
              <input
                className="form-control"
                type="text"
                placeholder="alias"
                value={item.alias}
                onChange={(e) => props.changeAlias(item, e.target.value)}
              />
            </div>
          ) : null}
        </React.Fragment>
      )}

      <div style={{ marginLeft: "5px" }}>
        <select
          className="form-control"
          value={item.css}
          onChange={(e) => props.changeCss(item, e.target.value)}
        >
          <option value="0">Left</option>
          <option value="1">Center</option>
          <option value="2">Right</option>
        </select>
      </div>
      <div data-tip="custom">
        <Tooltip message={"Customize"} position="left">
          <i
            style={{
              fontSize: "1.2rem",
              marginTop: "10px",
              marginLeft: "10px",
              cursor: "pointer"
            }}
            className="far fa-hat-wizard"
            onClick={() => showPopup(item)}
          ></i>
        </Tooltip>
        <Tooltip message={"UnLink Custom"} position="left">
          <i
            style={{
              fontSize: "1.2rem",
              marginTop: "10px",
              marginLeft: "10px",
              cursor: "pointer"
            }}
            className="fas fa-unlink"
            onClick={() => props.unLink(item)}
          ></i>
        </Tooltip>
      </div>
    </React.Fragment>
  );
};

NormalField.propTypes = {
  unLink: PropTypes.func,
  changeAlias: PropTypes.func,
  changeAggregate: PropTypes.func,
  showPopup: PropTypes.func,
  changeCss: PropTypes.func,
  item: PropTypes.object
};

export default NormalField;
