import { readGroups } from "../api/GroupApi";
import { getAssignedStores } from "../api/StoreApi";
import { toast } from "react-toastify";
import { isValid } from "../utils.js";

const loadGroupsForPicker = (setLoading, context, setGroups, setShowGroups) => {
  if (!isValid(context)) return;
  if (!isValid(context.token)) return;
  setLoading(true);
  readGroups(
    context.url,
    context.token,
    response => {
      setLoading(false);
      const j = response.data;
      if (j.error === 0) {
        setGroups(j.groups);
        if (context.lastSearchType === null) {
          setShowGroups(true);
        }
      } else {
        toast.error("loadGroupsForPicker: " + j.msg, {
          position: toast.POSITION.TOP_LEFT
        });
      }
    },
    err => {
      setLoading(false);
      console.log(err);
      toast.error(
        "loadGroupsForPicker: An error occured processing your request",
        {
          position: toast.POSITION.TOP_LEFT
        }
      );
    }
  );
};

const loadStores = (setLoading, context, setAssignedStores) => {
  if (!isValid(context)) return;
  if (!isValid(context.token)) return;
  setLoading(true);
  getAssignedStores(
    context.url,
    context.token,
    response => {
      setLoading(false);
      const j = response.data;
      if (j.error === 0) {
        setAssignedStores(j.items);
      } else {
        toast.error("loadStores: " + j.msg, {
          position: toast.POSITION.TOP_LEFT
        });
      }
    },
    err => {
      setLoading(false);
      console.log(err);
      toast.error("loadStores: An error occured processing your request", {
        position: toast.POSITION.TOP_LEFT
      });
    }
  );
};

export { loadGroupsForPicker, loadStores };
