import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Col, Card, CardHeader, CardBody, CardTitle } from "reactstrap";
import { toast } from "react-toastify";
import { link, copylinks } from "../api/freshopApi";
import Tooltip from "../Tooltip/Tooltip";

const FreshopTrash = (props) => {
  const [storeid, setStoreid] = useState(0);
  const [copy, setCopy] = useState(false);
  const [copyUserid, setCopyUserid] = useState("");
  const [search, setSearch] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);

  const drop = (e) => {
    console.log("store dropped on trash");
    e.preventDefault();

    const store_id = e.dataTransfer.getData("store_id");
    const store = document.getElementById(store_id);

    store.style.display = "block";
    store.style.opacity = "1";

    const pos = store_id.indexOf("-");
    const storeid = store_id.substr(pos + 1);
    setStoreid(storeid);
  };

  const dragOver = (e) => {
    e.preventDefault();

    e.dataTransfer.dropEffect = "move";
  };

  const dragEnter = (e) => {
    e.target.classList.add("over");
  };

  const dragLeave = (e) => {
    e.target.classList.remove("over");
  };

  useEffect(() => {
    if (storeid > 0) {
      unassignStore();
    }
  }, [storeid]);

  useEffect(() => {
    if (props.users.length > 0) {
      setFilteredUsers(props.users);
    }
  }, [props.users]);

  const handleCopyChange = (e) => {
    setCopy(e.target.checked);
  };

  const handleCopyUserChange = (e) => {
    setCopyUserid(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    const newArray = props.users.filter((u) =>
      u.username.toUpperCase().includes(e.target.value.toUpperCase())
    );
    setFilteredUsers(newArray);
  };

  const handleCopyClick = () => {
    if (typeof props.userid !== "undefined" && props.userid !== null) {
      if (typeof copyUserid !== "undefined" && copyUserid !== null) {
        copylinks(
          props.context.freshopApi,
          copyUserid,
          props.userid,
          props.context.freshopAppKey
        )
          .then((response) => {
            const j = response.data;
            if (j.error === 0) {
              props.setReRead(!props.reRead);
            } else {
              toast.warning(j.msg, {
                position: toast.POSITION.TOP_LEFT
              });
            }
          })
          .catch((err) => {
            console.log(err);
            toast.error("An Inernal error occured", {
              position: toast.POSITION.TOP_LEFT
            });
          });
      } else {
        toast.warning("Please select a user to copy from", {
          position: toast.POSITION.TOP_LEFT
        });
      }
    } else {
      toast.warning("Please select a user to copy to", {
        position: toast.POSITION.TOP_LEFT
      });
    }
  };

  const unassignStore = () => {
    link(
      props.context.freshopApi,
      props.userid,
      storeid,
      1,
      props.context.freshopAppKey
    )
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          props.setReRead(!props.reRead);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An error occured unassigning your Store", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };
  return (
    <Col xs="2">
      <Card
        className="as-trash"
        onDrop={drop}
        onDragEnter={dragEnter}
        onDragOver={dragOver}
        onDragLeave={dragLeave}
      >
        <CardHeader tag="h3">Trash</CardHeader>
        <CardBody>
          <CardTitle className="trash-title">
            Drag here to unassign Store
          </CardTitle>
          <span className="trash-icon">
            <i className="fad fa-recycle fa-4x"></i>
          </span>
        </CardBody>
      </Card>
      <div className="copy-assigned-stores" style={{ marginTop: "30px" }}>
        <label>
          <Tooltip
            message={
              "Copy stores from the selected user below to the user selected above"
            }
            position={"top"}
          >
            Copy Stores?
          </Tooltip>
        </label>
        <input
          type="checkbox"
          value={copy}
          onChange={handleCopyChange}
          checked={copy}
          style={{ opacity: 1, visibility: "visible", marginLeft: "5px" }}
        />
        {copy && (
          <div className="row justify-content_center">
            <div className="col" style={{ textAlign: "center" }}>
              <select
                className="form-control"
                style={{ marginTop: "5px" }}
                value={copyUserid}
                onChange={handleCopyUserChange}
              >
                <option value="0">Select a User</option>
                {filteredUsers.map((record, i) => (
                  <option key={`cuser-${i}`} value={record.id}>
                    {record.username}
                  </option>
                ))}
              </select>
              <label>Search for User:</label>
              <input
                className="form-control"
                type="text"
                value={search}
                onChange={handleSearchChange}
              />
              <button className="btn btn-dark mt-3" onClick={handleCopyClick}>
                Copy
              </button>
            </div>
          </div>
        )}
      </div>
    </Col>
  );
};

FreshopTrash.propTypes = {
  context: PropTypes.object,
  userid: PropTypes.any,
  reRender: PropTypes.bool,
  setReRender: PropTypes.func,
  users: PropTypes.array,
  reRead: PropTypes.bool,
  setReRead: PropTypes.func
};

export default FreshopTrash;
