import React from "react";
import ReactDOM from "react-dom";

const Viewer = ({ isShowing, hide, contents, reportName, showPrint, print }) =>
  isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <div className="report-overlay" />
          <div
            className="report-wrapper"
            aria-modal
            aria-hidden
            tabIndex={-1}
            role="dialog"
          >
            <div className="report-contents">
              <div className="report-contents-header">
                <span className="report-contents-title">{reportName}</span>
                <span className="report-contents-close-box">
                  <button
                    type="button"
                    className="report-confirm-delete-close-button"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={hide}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </span>
              </div>

              <div
                style={{
                  overflowY: "auto",
                  WebkitOverflowScrolling: "touch !important",
                  height: "90%"
                }}
              >
                {showPrint ? (
                  <div className="print-div" onClick={print}>
                    <i className="fal fa-print"></i>
                  </div>
                ) : null}
                <div
                  className="report-table-contents"
                  dangerouslySetInnerHTML={{ __html: contents }}
                />
                <iframe
                  style={{ height: "100%", width: "100%" }}
                  id="output"
                ></iframe>
              </div>
            </div>
          </div>
        </React.Fragment>,
        document.body
      )
    : null;

export default Viewer;
