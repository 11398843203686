import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import "../Clock/widgets/animations.css";
import "./ModalItemSetDetails.css";
import { handleCsv } from "../utils";
import ReactTooltip from "react-tooltip";

const ModalItemsetDetails = ({ isShowing, hide, style, data }) =>
  isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <ReactTooltip className="modal-itemset-tooltip" />
          <div className="modal-itemset-overlay" />
          <div
            className="modal-itemset-wrapper"
            aria-modal
            aria-hidden
            tabIndex={-1}
            role="dialog"
          >
            <div className={`modal-itemset`} style={style} id="modal-itemset">
              <div className="modal-itemset-header">
                <span className="modal-itemset-confirm-title">
                  Itemset Details
                </span>
                <span className="modal-itemset-close-box">
                  <button
                    type="button"
                    className="modal-itemset-close-buttun"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={hide}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </span>
              </div>
              <div className="modal-itemset-body">
                <div className="modal-itemset-csv-div">
                  <button
                    type="button"
                    className="btn btn-info"
                    onClick={() => handleCsv(data, "itemset.csv")}
                  >
                    Download Csv file
                  </button>
                </div>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>UPC</th>
                      <th className="item-truncate">Description</th>
                      <th style={{ textAlign: "center" }}>Sales</th>
                      <th style={{ textAlign: "center" }}>Qty</th>
                      <th className="cat-truncate">Cat</th>
                      <th>Sub</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length > 0 ? (
                      data.map((record, index) => (
                        <tr key={`ds-i-${index}`}>
                          <td>{record.f01}</td>
                          <td className="itemtruncate" data-tip={record.f02}>
                            {record.f02}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {parseFloat(record.f65).toFixed(2)}
                          </td>
                          <td style={{ textAlign: "center" }}>{record.f64}</td>
                          <td
                            className="cat-truncate"
                            data-tip={record.catDescription}
                          >
                            {record.catDescription}
                          </td>
                          <td
                            className="cat-truncate"
                            data-tip={record.subDescription}
                          >
                            {record.subDescription}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr key="item-0">
                        <td>There are no items to display</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </React.Fragment>,
        document.body
      )
    : null;

ModalItemsetDetails.propTypes = {
  isShowing: PropTypes.bool,
  hide: PropTypes.func,
  style: PropTypes.object
};

export default ModalItemsetDetails;
