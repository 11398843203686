import React from "react";
import PropTypes from "prop-types";
import "./Display.css";

const Display = (props) => {
  return (
    <div className="row mt-4">
      <div className="col-8 m-auto d-flex flex-column clock-display justify-content-center">
        <div className="align-items-center d-main-display-text">
          {props.output.length === 0 ? (
            <div>Enter Clock #</div>
          ) : (
            <React.Fragment>
              {props.passwordMode ? (
                <div>{props.fakeText}</div>
              ) : (
                <div>{props.output}</div>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

Display.propTypes = {
  output: PropTypes.string,
  passwordMode: PropTypes.bool,
  fakeText: PropTypes.string
};

export default Display;
