import axios from "axios";
import qs from "qs";

async function updateGeneralSettings(url, token, settings) {
  let json = await axios({
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: "Bearer " + token
    },
    url: url + "schedule/updategeneral",
    params: qs.stringify({
      storeid: settings.storeid,
      roundingMethod: settings.roundingMethod,
      minutesForLunch: settings.minutesForLunch,
      hoursForBreak: settings.hoursForBreak,
      clockInGracePeriodBefore: settings.clockInGracePeriodBefore,
      clockInGracePeriodAfter: settings.clockInGracePeriosAfter,
      timePrecision: settings.timePrecision,
      freeBreakMinutes: settings.freeBreakMinutes,
      forceLunch: settings.forceLunch,
      managerTimeout: settings.managerTimeout,
      punchInTimeout: settings.punchInTimeout,
      defaultScheduleWeek: settings.defaultScheduleWeek,
      disclaimer: settings.disclaimer,
      forceBreakTime: settings.forceBreakTime,
      dissalowEditingOfYourOwnTime: settings.dissalowEditingOfYourOwnTime,
      selectedScheme: settings.selectedScheme
    })
  });
  return json;
}

async function getGeneralSettings(url, token, storeid) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "schedule/generalsettings",
    params: {
      storeid
    }
  });
  return json;
}

async function getEmployeeSchedule(
  url,
  token,
  storeid,
  userid,
  startDate,
  endDate,
  employeeid
) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "schedule/emp",
    params: {
      userid,
      startDate,
      endDate,
      storeid,
      employeeid
    }
  });
  return json;
}

async function getStoreSchedules(url, token, storeid, startDate, endDate) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "schedule/schedules",
    params: {
      storeid,
      startDate,
      endDate
    }
  });
  return json;
}

async function deleteShift(url, token, id) {
  let json = await axios({
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "schedule",
    params: {
      id
    }
  });
  return json;
}

async function createShift(url, token, shift) {
  let json = await axios({
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: "Bearer " + token
    },
    url: url + "schedule",
    data: qs.stringify({
      storeid: shift.storeid,
      department: shift.department,
      departmentDescription: shift.departmentDescription,
      startDate: shift.startDate,
      employeeId: shift.employeeId,
      startTime: shift.startTime,
      endTime: shift.endTime,
      endDate: shift.endDate,
      userid: shift.userid
    })
  });
  return json;
}

async function updateShift(url, token, shift) {
  let json = await axios({
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: "Bearer " + token
    },
    url: url + "schedule",
    data: qs.stringify({
      storeid: shift.storeid,
      department: shift.department,
      departmentDescription: shift.departmentDescription,
      startDate: shift.startDate,
      employeeId: shift.employeeId,
      startTime: shift.startTime,
      endTime: shift.endTime,
      endDate: shift.endDate,
      userid: shift.userid,
      id: shift.id
    })
  });
  return json;
}

async function dropShift(
  url,
  token,
  shiftid,
  employeeid,
  newDate,
  storeid,
  startDate,
  endDate,
  copied
) {
  let json = await axios({
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: "Bearer " + token
    },
    url: url + "schedule/dragpunch",
    data: qs.stringify({
      punchId: shiftid,
      employeeId: employeeid,
      newPunchDate: newDate,
      storeid,
      startDate,
      endDate,
      copied
    })
  });
  return json;
}

async function getSchemes(url, token) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "schedule/schemes"
  });
  return json;
}

async function createScheme(url, token, schemeName) {
  let json = await axios({
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: "Bearer " + token
    },
    url: url + "schedule/createscheme",
    data: qs.stringify({
      schemeName
    })
  });
  return json;
}

async function getRounding(url, token, schemeid) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "schedule/getrounding",
    params: {
      schemeid
    }
  });
  return json;
}

async function updateScheme(url, token, scheme, schemeid) {
  const newSchemeData = scheme.map((record) => {
    return { ...record, schemeId: schemeid };
  });
  const schemeData = JSON.stringify(newSchemeData);
  let json = await axios({
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "schedule/updatescheme",
    data: schemeData
  });
  return json;
}

async function setSchemeBreakPoint(url, token, schemeid, breakpoint) {
  let json = await axios({
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: "Bearer " + token
    },
    url: url + "schedule/breakpoint",
    data: qs.stringify({
      schemeid,
      breakpoint
    })
  });
  return json;
}

async function copyWeek(url, token, storeid, startDate, endDate) {
  let json = await axios({
    method: "GET",
    url: url + "schedule/copy",
    cors: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    params: {
      storeid,
      startDate,
      endDate
    }
  });

  return json;
}

async function deleteSchedule(url, token, storeid, startDate, endDate) {
  let json = await axios({
    method: "GET",
    url: url + "schedule/delschedule",
    cors: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    params: {
      storeid,
      startDate,
      endDate
    }
  });

  return json;
}

async function publish(url, token, startdate, enddate, storeid) {
  let json = await axios({
    method: "GET",
    cors: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "schedule/publish",
    params: {
      startdate,
      enddate,
      storeid
    }
  });
  return json;
}

async function liveSchedule(url, token, storeid, userid, startdate, enddate) {
  let json = await axios({
    method: "GET",
    cors: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "schedule/liveschedule",
    params: {
      storeid,
      userid,
      startdate,
      enddate
    }
  });
  return json;
}

export {
  updateGeneralSettings,
  getGeneralSettings,
  createShift,
  getEmployeeSchedule,
  deleteShift,
  updateShift,
  dropShift,
  getSchemes,
  createScheme,
  getRounding,
  updateScheme,
  setSchemeBreakPoint,
  getStoreSchedules,
  copyWeek,
  deleteSchedule,
  publish,
  liveSchedule
};
