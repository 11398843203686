import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import "./DesktopTransaction.css";
import { Redirect } from "react-router-dom";
import { formatDate, convertAmount } from "../../utils";
import { showFlags, showKeysFunction } from "./TransUtils";
import { toast } from "react-toastify";
import { ContextConfig } from "../../App";
import { email } from "./EmailTrans";
import CashierNavigation from "../CashierNavigation";
import Spinner from "../../common/Spinner";
import { debounce } from "../../fp";

const DesktopTransaction = ({ hdr, reg, ttl }) => {
  const [isEmailing, setIsEmailing] = useState(false);
  const [showKeys, setShowKeys] = useState(false);
  const [goBack, setGoBack] = useState(false);
  const [step, setStep] = useState(3);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const context = useContext(ContextConfig);

  useEffect(() => {
    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);

    findDivHeight();

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, []);

  const findDivHeight = () => {
    const navbar = document
      .getElementById("master-navbar")
      .getBoundingClientRect().height;
    const controls = document
      .getElementById("dt-controls")
      .getBoundingClientRect().height;
    const usedSpace = navbar + controls;
    const remaining = window.innerHeight - usedSpace;

    const trans = document.getElementById("d-t-body");
    trans.style.height = remaining + "px";
    trans.style.overflow = "auto";
  };

  const handleGoback = (currentStep) => {
    setStep(currentStep);
    setGoBack(true);
  };

  const handleHideKeys = () => {
    setShowKeys(false);
  };

  const handleShowKeys = () => {
    setShowKeys(true);
  };

  const handleEmail = (f1032, storeid, cashier, f254, f1056) => {
    setIsEmailing(true);
    email(f1032, storeid, cashier, f254, f1056, context)
      .then((response) => {
        setIsEmailing(false);
        const j = response.data;
        if (j.error === 0) {
          toast.success("Your transaction has been emailed", {
            position: toast.POSITION.TOP_LEFT
          });
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setIsEmailing(false);
        console.log(err);
        toast.error("An error occured sending your email", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  return (
    <React.Fragment>
      {isEmailing && <Spinner />}
      {goBack && step === 0 && <Redirect to="/cashiers" />}
      {goBack && step === 1 && <Redirect to="/flyout" />}
      {goBack && step === 2 && <Redirect to="/totalizers" />}
      {goBack && step === 3 && <Redirect to="/transactions" />}

      <div className="container dt-controls" id="dt-controls">
        <CashierNavigation step="3" goBack={handleGoback} />
      </div>
      <div className="container d-t-body" id="d-t-body">
        {hdr.length === 0 ? (
          <div>There are no records to Display</div>
        ) : (
          hdr.map((record, index) => (
            <div className="d-t-d-body" key={index}>
              <div className="m-trans-hdr-1">
                <div className="m-trans-type">Type:{record.f1068}</div>
                <div className="m-trans-number">
                  Transaction: {record.f1032}
                </div>
              </div>
              <div className="m-trans-hdr-2">
                <div className="m-trans-date">
                  Date: {formatDate(record.f254)}
                </div>
                <div className="m-trans-invoice">Invoice: {record.f1764}</div>
              </div>
              <div className="m-trans-hdr-3">
                <div className="m-trans-time">
                  Time: {record.transStartTime} - {record.transEndTime}
                </div>
                <div className="m-trans-term">{record.f1056}</div>
              </div>
              <div className="m-trans-cashier">Cashier: {record.cashier}</div>
              <div className="d-trans-hdr-4">
                {showKeys ? (
                  <div>
                    <button
                      className="btn btn-outline-dark"
                      onClick={handleHideKeys}
                    >
                      Hide Keys
                    </button>
                  </div>
                ) : (
                  <div>
                    <button
                      className="btn btn-outline-dark"
                      onClick={handleShowKeys}
                    >
                      Keystrokes
                    </button>
                  </div>
                )}
                <div className="m-trans-email">
                  <button
                    className="btn btn-outline-dark"
                    onClick={() =>
                      handleEmail(
                        record.f1032,
                        record.storeid,
                        hdr[0].cashier,
                        hdr[0].f254,
                        hdr[0].f1056
                      )
                    }
                  >
                    Email
                  </button>
                </div>
              </div>
              <div className="m-trans-reg">
                {reg.length === 0
                  ? null
                  : reg.map((rec, index) => (
                      <table
                        key={`table-${hdr[0].f1032}-${rec.f1101}`}
                        className="m-trans-table"
                      >
                        <tbody>
                          <tr key={`reg-${rec.f1101}-${index}`}>
                            <td className="m-trans-f01">{rec.f01}</td>
                            <td className="m-trans-f1041">{rec.f1041}</td>
                            <td className="m-trans-f64">{rec.f64}</td>
                            <td className="m-trans-f65">
                              {convertAmount(rec.f65)}
                            </td>
                            {/*<td className="m-trans-f67">{setDecimal(rec.f67)}</td>*/}
                            <td className="m-trans-flags">
                              {showFlags(
                                rec.f79,
                                rec.f178,
                                rec.f81,
                                rec.f96,
                                rec.f97,
                                rec.f98
                              )}
                            </td>
                          </tr>
                          {showKeys === false
                            ? null
                            : showKeysFunction(rec).map((result, index) => (
                                <tr
                                  className="m-trans-keystroke"
                                  key={`key-${rec.f1101}[${index}]`}
                                >
                                  <td>{result.name}</td>
                                  <td colSpan="4">{result.value}</td>
                                </tr>
                              ))}
                        </tbody>
                      </table>
                    ))}
              </div>
              <div className="m-trans-ttl">
                {ttl.length === 0
                  ? null
                  : ttl.map((total, index) => (
                      <table className="m-trans-ttl-table" key={index}>
                        <tbody>
                          <tr>
                            <td className="m-trans-f1034">{total.f1034}</td>
                            <td className="m-trans-f1039">{total.f1039}</td>
                            <td className="m-trans-ttl-f64">{total.f64}</td>
                            <td className="m-trans-ttl-f65">
                              {convertAmount(total.f65)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    ))}
              </div>
            </div>
          ))
        )}
      </div>
    </React.Fragment>
  );
};

DesktopTransaction.propTypes = {
  hdr: PropTypes.array,
  reg: PropTypes.array,
  ttl: PropTypes.array
};

export default DesktopTransaction;
