import React, { useState, useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ContextConfig } from "../App";
import { fetchMetrics } from "./Freshop-I";
import { toast } from "react-toastify";
import * as actions from "../actions/actionTypes";
import { formatDate } from "../utils";
import { Row } from "reactstrap";
import { setMetricsOrderby } from "../api/freshopApi";
import SortableFilterableColumn from "./SortableFilterableColumn";

import "./Metrics.css";

const Metrics = () => {
  const [showTable, setShowTable] = useState(true);
  const freshopState = useSelector((app) => app.freshopReducer);

  const context = useContext(ContextConfig);

  const dispatch = useDispatch();

  useEffect(() => {
    getMetricsData();
    if (freshopState.metricsError != null) {
      toast.warning(freshopState.metricsError, {
        position: toast.POSITION.TOP_LEFT
      });

      dispatch({ type: actions.FRESHOP_SET_METRICS_ERROR, error: null });
    }
  }, [freshopState.startDate, freshopState.endDate, freshopState.metricsError]);

  useEffect(() => {
    if (freshopState.sortField !== null) {
      sortRecords();
      if (freshopState.sortState) {
        setOrderBy("desc");
      } else {
        setOrderBy("asc");
      }
    }
  }, [freshopState.sortField, freshopState.sortState]);

  useEffect(() => {
    filterRecords();
  }, [freshopState.filteredValue]);

  const getMetricsData = () => {
    fetchMetrics(
      context.freshopApi,
      context.userid,
      context.startDate,
      context.endDate,
      context.freshopAppKey,
      dispatch
    );
  };

  useEffect(() => {
    handleShowTable();
  }, [showTable]);

  const getSortField = () => {
    switch (freshopState.sortField) {
      case "openOrders":
        return freshopState.sortOpenOrderAsc;
      case "plastic":
        return freshopState.sortPlasticAsc;
      case "paper":
        return freshopState.sortPaperAsc;
      case "orderDate":
        return freshopState.sortDateAsc;
      case "count":
        return freshopState.sortOrdersAsc;
      case "pickupOrders":
        return freshopState.sortPickupAsc;
      case "deliveryOrders":
        return freshopState.sortDeliveryAsc;
      case "priceModifications":
        return freshopState.sortPriceModsAsc;
      case "itemErrors":
        return freshopState.sortItemErrorsAsc;
      case "orderErrors":
        return freshopState.sortOrderErrorsAsc;
      default:
        return null;
    }
  };

  const compare = (a, b) => {
    let fieldA = a[freshopState.sortField];
    let fieldB = b[freshopState.sortField];

    if (freshopState.sortDataType !== "number") {
      fieldA = fieldA.toUpperCase();
      fieldB = fieldB.toUpperCase();
    } else {
      fieldA = parseFloat(a[freshopState.sortField]);
      fieldB = parseFloat(b[freshopState.sortField]);
    }

    let comparison = 0;
    if (fieldA > fieldB) {
      comparison = 1;
    } else if (fieldA < fieldB) {
      comparison = -1;
    }

    if (getSortField()) {
      return comparison * -1;
    } else {
      return comparison;
    }
  };

  const sortRecords = () => {
    dispatch({ type: actions.FRESHOP_IS_LOADING, isLoading: true });
    dispatch({
      type: actions.FRESHOP_SET_FILTERED_METRICS,
      orders: [...freshopState.metrics].sort(compare)
    });
    dispatch({ type: actions.FRESHOP_IS_LOADING, isLoading: false });
  };

  const filterRecords = () => {
    if (freshopState.filteredValue.length === 0) {
      dispatch({
        type: actions.FRESHOP_SET_FILTERED_METRICS,
        orders: freshopState.metrics
      });
      return;
    }

    let newArray = [];
    if (freshopState.sortDataType == "number") {
      newArray = freshopState.metrics.filter((r) =>
        parseInt(r[freshopState.filterField])
          .toFixed(0)
          .toString()
          .includes(freshopState.filteredValue)
      );
    } else if (freshopState.sortDataType == "double") {
      newArray = freshopState.metrics.filter((r) =>
        parseFloat(r[freshopState.filterField])
          .toFixed(2)
          .toString()
          .includes(freshopState.filteredValue)
      );
    } else {
      newArray = freshopState.metrics.filter((r) =>
        r[freshopState.filterField]
          .toUpperCase()
          .includes(freshopState.filteredValue.toUpperCase())
      );
    }

    dispatch({
      type: actions.FRESHOP_SET_FILTERED_METRICS,
      orders: newArray
    });
  };

  const setOrderBy = (direction) => {
    const orderByClause = freshopState.sortField + " " + direction;
    setMetricsOrderby(
      context.freshopApi,
      context.userid,
      orderByClause,
      context.freshopAppKey
    )
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          console.log("order by was saved to the server");
        } else {
          toast.warning(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An internal error occured", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const handleShowTable = () => {
    const div = document.getElementById("metricsdiv");
    if (div) {
      if (showTable) {
        div.classList.remove("hideMetrics");
      } else {
        div.classList.add("hideMetrics");
      }
    }
  };

  return (
    <div>
      {freshopState.metrics.length > 0 ? (
        <div
          className="container freshop-fade-in freshop-metrics"
          style={{ textAlign: "center" }}
        >
          <h5 style={{ marginTop: "5px", marginBottom: "5px" }}>
            The table shows data for all stores assigned to you. The rest of the
            widgets are just for the selected store.{" "}
            {showTable ? (
              <i
                className="fas fa-eye-slash"
                style={{ cursor: "pointer" }}
                onClick={() => setShowTable(false)}
              ></i>
            ) : (
              <i
                className="fas fa-eye"
                style={{ cursor: "pointer" }}
                onClick={() => setShowTable(true)}
              ></i>
            )}
          </h5>
          <Row className="w-100 justify-content-center mt-2 mb-4">
            <div className="tableFixHead" id="metricsdiv">
              <table
                id="metricsTable"
                className="table table-striped"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr>
                    <SortableFilterableColumn
                      state={freshopState}
                      dispatch={dispatch}
                      title={"Date"}
                      sortable={true}
                      sortField={"sortDateAsc"}
                      dbField={"orderDate"}
                      sortFieldAction={"FRESHOP_SET_SORT_FIELD"}
                      sortDataType={"date"}
                      filterable={false}
                    />
                    <th>Store</th>
                    <SortableFilterableColumn
                      state={freshopState}
                      dispatch={dispatch}
                      title={"Orders"}
                      sortable={true}
                      sortField={"sortOrdersAsc"}
                      dbField={"count"}
                      sortFieldAction={"FRESHOP_SET_SORT_FIELD"}
                      sortDataType={"number"}
                      filterable={true}
                      filterField={"count"}
                      filterFieldAction={"FRESHOP_SET_FILTER_FIELD"}
                      filterValueChangeAction={"FRESHOP_CHANGE_FILTER_VALUE"}
                      maxInputWidth={"50px"}
                    />
                    <SortableFilterableColumn
                      state={freshopState}
                      dispatch={dispatch}
                      title={"Pickups"}
                      sortable={true}
                      sortField={"sortPickupAsc"}
                      dbField={"pickupOrders"}
                      sortFieldAction={"FRESHOP_SET_SORT_FIELD"}
                      sortDataType={"number"}
                      filterable={true}
                      filterField={"pickupOrders"}
                      filterFieldAction={"FRESHOP_SET_FILTER_FIELD"}
                      filterValueChangeAction={"FRESHOP_CHANGE_FILTER_VALUE"}
                      maxInputWidth={"50px"}
                    />
                    <SortableFilterableColumn
                      state={freshopState}
                      dispatch={dispatch}
                      title={"delivery"}
                      sortable={true}
                      sortField={"sortDeliveryAsc"}
                      dbField={"deliveryOrders"}
                      sortFieldAction={"FRESHOP_SET_SORT_FIELD"}
                      sortDataType={"number"}
                      filterable={true}
                      filterField={"deliveryOrders"}
                      filterFieldAction={"FRESHOP_SET_FILTER_FIELD"}
                      filterValueChangeAction={"FRESHOP_CHANGE_FILTER_VALUE"}
                      maxInputWidth={"50px"}
                    />
                    <SortableFilterableColumn
                      state={freshopState}
                      dispatch={dispatch}
                      title={"Open"}
                      sortable={true}
                      sortField={"sortOpenOrderAsc"}
                      dbField={"openOrders"}
                      sortFieldAction={"FRESHOP_SET_SORT_FIELD"}
                      sortDataType={"number"}
                      filterable={true}
                      filterField={"openOrders"}
                      filterFieldAction={"FRESHOP_SET_FILTER_FIELD"}
                      filterValueChangeAction={"FRESHOP_CHANGE_FILTER_VALUE"}
                      maxInputWidth={"50px"}
                    />
                    <SortableFilterableColumn
                      state={freshopState}
                      dispatch={dispatch}
                      title={"Price Mods"}
                      sortable={true}
                      sortField={"sortPriceModsAsc"}
                      dbField={"priceModifications"}
                      sortFieldAction={"FRESHOP_SET_SORT_FIELD"}
                      sortDataType={"number"}
                      filterable={true}
                      filterField={"priceModifications"}
                      filterFieldAction={"FRESHOP_SET_FILTER_FIELD"}
                      filterValueChangeAction={"FRESHOP_CHANGE_FILTER_VALUE"}
                      maxInputWidth={"50px"}
                    />
                    <SortableFilterableColumn
                      state={freshopState}
                      dispatch={dispatch}
                      title={"Item Errors"}
                      sortable={true}
                      sortField={"sortItemErrorsAsc"}
                      dbField={"itemErrors"}
                      sortFieldAction={"FRESHOP_SET_SORT_FIELD"}
                      sortDataType={"number"}
                      filterable={true}
                      filterField={"itemErrors"}
                      filterFieldAction={"FRESHOP_SET_FILTER_FIELD"}
                      filterValueChangeAction={"FRESHOP_CHANGE_FILTER_VALUE"}
                      maxInputWidth={"50px"}
                    />
                    <SortableFilterableColumn
                      state={freshopState}
                      dispatch={dispatch}
                      title={"Paper"}
                      sortable={true}
                      sortField={"sortPaperAsc"}
                      dbField={"paper"}
                      sortFieldAction={"FRESHOP_SET_SORT_FIELD"}
                      sortDataType={"number"}
                      filterable={true}
                      filterField={"paper"}
                      filterFieldAction={"FRESHOP_SET_FILTER_FIELD"}
                      filterValueChangeAction={"FRESHOP_CHANGE_FILTER_VALUE"}
                      maxInputWidth={"50px"}
                    />
                    <SortableFilterableColumn
                      state={freshopState}
                      dispatch={dispatch}
                      title={"Plastic"}
                      sortable={true}
                      sortField={"sortPlasticAsc"}
                      dbField={"plastic"}
                      sortFieldAction={"FRESHOP_SET_SORT_FIELD"}
                      sortDataType={"number"}
                      filterable={true}
                      filterField={"plastic"}
                      filterFieldAction={"FRESHOP_SET_FILTER_FIELD"}
                      filterValueChangeAction={"FRESHOP_CHANGE_FILTER_VALUE"}
                      maxInputWidth={"50px"}
                    />
                    <SortableFilterableColumn
                      state={freshopState}
                      dispatch={dispatch}
                      title={"Order Errors"}
                      sortable={true}
                      sortField={"sortOrderErrorsAsc"}
                      dbField={"orderErrors"}
                      sortFieldAction={"FRESHOP_SET_SORT_FIELD"}
                      sortDataType={"number"}
                      filterable={true}
                      filterField={"orderErrors"}
                      filterFieldAction={"FRESHOP_SET_FILTER_FIELD"}
                      filterValueChangeAction={"FRESHOP_CHANGE_FILTER_VALUE"}
                      maxInputWidth={"50px"}
                    />
                  </tr>
                </thead>
                <tbody style={{ maxHeight: "200px", overflowY: "auto" }}>
                  {freshopState.filteredMetrics.map((record, i) => (
                    <tr key={`fm-${i}`}>
                      <td style={{ textAlign: "center" }}>
                        {formatDate(record.orderDate)}
                      </td>
                      <td style={{ textAlign: "center" }}>{record.name}</td>
                      <td style={{ textAlign: "center" }}>{record.count}</td>
                      <td style={{ textAlign: "center" }}>
                        {record.pickupOrders}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {record.deliveryOrders}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {record.openOrders}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {record.priceModifications}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {record.itemErrors}
                      </td>
                      <td style={{ textAlign: "center" }}>{record.paper}</td>
                      <td style={{ textAlign: "center" }}>{record.plastic}</td>
                      <td style={{ textAlign: "center" }}>
                        {record.orderErrors}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Row>
        </div>
      ) : null}
    </div>
  );
};

export default Metrics;
