import React from "react";
import ReactDOM from "react-dom";
import "./Pin.css";

const Pin = ({
  isShowing,
  hide,
  password,
  setPassword,
  makeConsent,
  disclaimer
}) =>
  isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <div className="modal-pin-overlay" />
          <div
            className="p-4 modal-pin-wrapper"
            aria-modal
            aria-hidden
            tabIndex={-1}
            role="dialog"
          >
            <div className="modal-pin">
              <div className="modal-pin-header">
                <span className="modal-pin-confirm-title">Confirm User</span>
                <span className="modal-pin-close-box">
                  <button
                    type="button"
                    className="modal-pin-close-button"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={hide}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </span>
              </div>
              <div className="modal-pin-body">
                <h3>Enter Users Password:</h3>
                <p>{disclaimer}</p>
                <input
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  className="form-control"
                />
                <div
                  className="justify-content-center"
                  style={{ marginTop: "10px", textAlign: "center" }}
                >
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={makeConsent}
                  >
                    Consent
                  </button>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>,
        document.body
      )
    : null;

export default Pin;
