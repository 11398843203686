import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { ContextConfig } from "../App";
import { toast } from "react-toastify";
import DeleteGroup from "./DeleteGroup";
import { createGroup } from "../api/GroupApi";

const CreateGroup = ({ createCallback }) => {
  const [groupname, setGroupname] = useState("");
  const [toggle, setToggle] = useState(false);
  const context = useContext(ContextConfig);

  const handleToggle = () => {
    setToggle(!toggle);
  };

  const handleChange = e => {
    setGroupname(e.target.value);
  };

  const deleteCallback = () => {
    createCallback();
  };

  const handleSubmit = e => {
    e.preventDefault();
    const config = {
      url: context.url,
      token: context.token,
      groupname: groupname
    };
    createGroup(
      config,
      response => {
        const j = response.data;
        if (j.error === 0) {
          toast.success("Your group has been created", {
            position: toast.POSITION.TOP_LEFT
          });
          createCallback();
          setGroupname("");
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      },
      err => {
        console.log(err);
        toast.error("An error occured creating your group", {
          position: toast.POSITION.TOP_LEFT
        });
      }
    );
  };

  return (
    <div className="container-fluid">
      <DeleteGroup state={toggle} deleteCallback={deleteCallback} />
      <div className="row justify-content-center">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="groupname">Create Group Name:</label>
            <input
              type="text"
              className="form-control"
              id="groupname"
              value={groupname}
              placeholder="Enter New Group Name"
              onChange={handleChange}
            />
          </div>
          <div className="row justify-content-center">
            <button type="submit" className="btn btn-outline-dark">
              Create
            </button>
            <button
              type="button"
              className="btn btn-outline-danger ml-3"
              onClick={handleToggle}
            >
              Delete Group
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

CreateGroup.propTypes = {
  createCallback: PropTypes.func
};

export default CreateGroup;
