import React, { useState, useEffect, useContext } from "react";
import ClockDesktopMenu from "../ClockDesktopMenu";
import FloatingSidebar from "../../Dashboard/FloatingSidebar";
import { ContextConfig } from "../../App";
import { debounce } from "../../fp";
import {
  getBatches,
  closeBatch,
  checkEodForOpenPunches,
  getTc08
} from "../../api/eodApi";
import { toast } from "react-toastify";
import ConfirmDelete from "../../common/ConfirmDelete";
import Header from "../widgets/Header";
import Viewer from "../Reports/Viewer";
import { generatePdf } from "./EodPdf";
import { save } from "../../utils";

import "./Eod.css";

const Eod = () => {
  const [eodDate, setEodDate] = useState(new Date());
  const [batches, setBatches] = useState([]);
  const [isShowing, setIsShowing] = useState(false);
  const [error, setError] = useState("");
  const [confirmText, setConfirmText] = useState("");
  const [reRender, setReRender] = useState(false);
  const [showErrorReport, setShowErrorReport] = useState(false);
  const [contents, setContents] = useState("");
  const [tables, setTables] = useState([]);
  const [punches, setPunches] = useState([]);
  const [breaks, setBreaks] = useState([]);
  const [lunches, setLunches] = useState([]);
  const [longLunches, setLongLunches] = useState([]);
  const [storename, setStorename] = useState("");
  const [forced, setForced] = useState(false);
  const [showForce, setShowForce] = useState(false);
  const [showTc08, setShowTc08] = useState(false);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });
  const context = useContext(ContextConfig);

  useEffect(() => {
    getBatchList();
    setEodDate(context.endDate);

    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);

    findDivHeight();

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, [reRender]);

  useEffect(() => {
    if (showErrorReport) {
      const report = {
        reportName: "Eod Issues that need resolved"
      };
      const params = {
        startDate: context.startDate,
        endDate: context.endDate,
        storename
      };

      generatePdf(
        report,
        params,
        tables,
        punches,
        breaks,
        lunches,
        longLunches
      );
    }
  }, [showErrorReport]);

  const isEodOk = () => {
    checkEodForOpenPunches(
      context.clockUrl,
      context.clockToken,
      context.lastClockStore,
      context.startDate,
      context.endDate
    )
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          //now check to see if we have any open punches
          if (
            j.punches.length > 0 ||
            j.lunches.length > 0 ||
            j.breaks.length > 0 ||
            j.longLunches.length > 0
          ) {
            // we cannot proceed with the eod
            setShowForce(true);
            setTables(j.tables);
            setPunches(j.punches);
            setBreaks(j.breaks);
            setLunches(j.lunches);
            setLongLunches(j.longLunches);
            setContents(j.rawHtml);
            setStorename(j.storename);
            setShowErrorReport(true);
          } else {
            closePeriod();
          }
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An Internal error has occured", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const getBatchList = () => {
    getBatches(context.clockUrl, context.clockToken, context.lastClockStore)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setBatches(j.items);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An Internal error occured", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const findDivHeight = () => {
    const navbar = document
      .getElementById("master-navbar")
      .getBoundingClientRect().height;
    const controls = document.getElementById("controls").getBoundingClientRect()
      .height;

    const usedSpace = navbar + controls + 20;
    const remaining = window.innerHeight - usedSpace;

    const widget = document.getElementById("batch-list");
    if (typeof widget !== "undefined" && widget !== null) {
      widget.style.height = remaining + "px";
      widget.style.overflow = "auto";
    }
  };

  const handleClosePeriod = () => {
    setIsShowing(true);
  };

  const hide = () => {
    setIsShowing(false);
  };

  const handleTextChange = (e) => {
    setConfirmText(e.target.value);
  };

  const compareDeleteText = () => {
    if (confirmText != "close me") {
      setError(`"${confirmText}" does not match "close me"`);
    } else {
      if (forced) {
        setIsShowing(false);
        setConfirmText("");
        closePeriod();
        return;
      }
      setConfirmText("");
      setIsShowing(false);
      isEodOk();
    }
  };

  const closePeriod = () => {
    closeBatch(
      context.clockUrl,
      context.clockToken,
      context.lastClockStore,
      eodDate,
      forced
    )
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          getBatchList();
          setIsShowing(false);
          setShowForce(false);
          setShowTc08(true);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An Internal error occured", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const closeReport = () => {
    setShowErrorReport(false);
  };

  const handleForceClosePeriod = () => {
    setForced(true);
    setIsShowing(true);
  };

  const print = () => {};

  const fetchTc08 = () => {
    getTc08(
      context.clockUrl,
      context.clockToken,
      context.lastClockStore,
      context.startDate,
      context.endDate
    )
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          var uriContent =
            "data:application/octec-stream," + encodeURIComponent(j.csv);
          var filename = j.storenumber + ".tc08";
          save(uriContent, filename);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION_TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An Internal error occured", {
          position: toast.POSITION_TOP_LEFT
        });
      });
  };

  return (
    <div>
      {isShowing && (
        <ConfirmDelete
          isShowing={isShowing}
          hide={hide}
          confirmText={confirmText}
          setConfirmText={handleTextChange}
          error={error}
          compareDeleteText={compareDeleteText}
          title={"Close Period"}
          instructionsText={"close me"}
        />
      )}
      <ClockDesktopMenu />
      <FloatingSidebar />
      {showErrorReport && (
        <Viewer
          isShowing={showErrorReport}
          hide={closeReport}
          contents={contents}
          reportName={"Eod Problems"}
          showPrint={false}
          print={print}
        />
      )}
      <div className="container">
        <div id="controls">
          <div
            style={{
              width: "100%",
              marginTop: "20px",
              textAlign: "center",
              fontSize: "1.3rem",
              fontWeight: "bold"
            }}
          >
            Close out Pay Period
          </div>
          <p className="instructions mb-3">
            Select the date that you want to use to set the Close Flag for the
            Week. Once you execute this functionality, no more edits will be
            allowed for any punches prior to the date selected.
          </p>
          <Header reRender={reRender} setReRender={setReRender} />
          <div className="row mt-3 justify-content-center">
            <div className="col-4">
              <button
                className="btn btn-outline-light"
                onClick={handleClosePeriod}
              >
                Close Payroll Period
              </button>
            </div>
            {context.userLevel >= 7 && showForce ? (
              <div className="col-4">
                <button
                  className="btn btn-outline-light"
                  onClick={handleForceClosePeriod}
                >
                  Force Close Payroll Period
                </button>
              </div>
            ) : null}
          </div>
          {showTc08 ? (
            <div className="row justify-content-center mt-3">
              <div className="col-4">
                <button className="btn btn-outline-light" onClick={fetchTc08}>
                  Download Tc08
                </button>
              </div>
            </div>
          ) : null}
        </div>
        <div id="batch-list">
          {batches.length === 0 ? (
            <div className="empty-list">
              There are no closed periods to show
            </div>
          ) : (
            <div>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Close Date</th>
                    <th>Username</th>
                    <th>Closed on</th>
                    <th>Forced</th>
                  </tr>
                </thead>
                <tbody>
                  {batches.map((record, index) => (
                    <tr key={`tr-${index}`}>
                      <td>{record.closeDate}</td>
                      <td>{record.username}</td>
                      <td>{record.closedOn}</td>
                      <td>
                        {
                          <input
                            type="checkbox"
                            value={record.forced}
                            checked={record.forced == "1" ? true : false}
                            readOnly={true}
                            stylel={{ opacity: "1", visibility: "visible" }}
                          />
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Eod;
