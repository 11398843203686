import React, { useState, useEffect, useContext, useRef } from "react"
import { Picker } from "c5-react-library"
import PropTypes from "prop-types"
import "./MobileStorePicker.css"
import { ContextConfig } from "../App"
import axios from "axios"
import { isEqual } from "lodash"
import { usePrevious } from "../utils"

const MobileStorePicker = ({ sendError, selectedStore, error }) => {
  const [stores, setStores] = useState([])
  const [store, setStore] = useState({})
  const [isShowing, setIsShowing] = useState(false)

  const previousStores = usePrevious(stores)

  const context = useContext(ContextConfig)
  useEffect(() => {
    if (!isEqual(previousStores, stores)) {
      loadStores()
    }
  }, [stores])

  const handleClick = (e) => {
    e.preventDefault()
    setIsShowing(!isShowing)
  }

  const toggleShow = () => {
    setIsShowing(!isShowing)
  }

  const handleSelect = (e) => {
    setStore(e)
    selectedStore(e)
  }

  const loadStores = () => {
    axios({
      method: "GET",
      headers: {
        "Context-Type": "application/json",
        Authorization: "Bearer " + context.token
      },
      url: context.url + "stores/assignedstores"
    })
      .then((response) => {
        const j = response.data
        if (j.error === 0) {
          setStores(
            j.items.map((s) => {
              return { ...s, store_Name: s.store_Name.substring(0, 20) }
            })
          )
        } else {
          sendError(j.msg)
        }
      })
      .catch((err) => {
        console.log(err)
        sendError("An error occured getting storelist")
      })
  }

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="store-picker-container">
          <div className="store-picker-button">
            <button className="btn btn-outline-dark" onClick={handleClick}>
              <i className="far fa-cash-register"></i>
            </button>
          </div>
          <div className="store-picker-name">{store.store_Name}</div>
        </div>
      </div>
      <Picker
        isShowing={isShowing}
        hide={toggleShow}
        header="Stores"
        store={stores}
        displayField="store_Name"
        valueField="storeid"
        handleSelect={handleSelect}
        useEffect={useEffect}
        useState={useState}
        useRef={useRef}
      />
      {error && <div className="alert alert-danger">{error}</div>}
    </React.Fragment>
  )
}

MobileStorePicker.propTypes = {
  sendError: PropTypes.func,
  selectedStore: PropTypes.func,
  error: PropTypes.string
}

export default MobileStorePicker
