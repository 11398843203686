import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import JSONTree from "react-json-tree";

import "./Stats.css";

const Stats = ({
  isShowing,
  hide,
  status,
  punches,
  username,
  userid,
  departments,
  storeid
}) =>
  isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <div className="modal-stats-overlay" />
          <div
            className="p-4 modal-hours-wrapper"
            aria-modal
            aria-hidden
            tabIndex={-1}
            role="dialog"
          >
            <div className="modal-stats">
              <div className="modal-stats-header">
                <span className="modal-stats-confirm-title">Local Storage</span>
                <span className="modal-stats-close-box">
                  <button
                    className="modal-stats-close-button"
                    data-dismiss="modal"
                    aria-label="close"
                    onClick={hide}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </span>
              </div>
              <div className="modal-stats-body">
                <h3>Storeid: {storeid}</h3>
                <div>
                  <span>Userid: {userid}</span>
                  <span style={{ marginLeft: "10px" }}>
                    Username: {username}
                  </span>
                </div>
                <div>
                  {status && status.length > 0 ? (
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Userid</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {status.map((record, i) => (
                          <tr key={`sr-${i}`}>
                            <td>{record.userid}</td>
                            <td>{record.status}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div>There are no Status to show</div>
                  )}
                </div>
                <div>
                  {departments && departments.length > 0 ? (
                    <div>
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>Id</th>
                            <th>Description</th>
                            <th>Group</th>
                            <th>posLink</th>
                          </tr>
                        </thead>
                        <tbody>
                          {departments.map((record, i) => (
                            <tr key={`dr-${i}`}>
                              <td>{record.id}</td>
                              <td>{record.description}</td>
                              <td>{record.group}</td>
                              <th>{record.posLink}</th>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div>There are no departments to show</div>
                  )}
                </div>
                <div>
                  {punches && punches.length > 0 ? (
                    <div>
                      <h3 style={{ width: "100%", textAlign: "center" }}>
                        Punches
                      </h3>
                      <JSONTree data={punches} />
                    </div>
                  ) : (
                    <div>There are no punches to show</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>,
        document.body
      )
    : null;

Stats.propTypes = {
  isShowing: PropTypes.bool,
  hide: PropTypes.func,
  departments: PropTypes.array,
  punches: PropTypes.array,
  username: PropTypes.string,
  password: PropTypes.string,
  status: PropTypes.array,
  storeid: PropTypes.any
};

export default Stats;
