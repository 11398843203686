import * as actions from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  showModal: false,
  storeData1: [],
  storeData2: [],
  startDate: new Date(),
  endDate: new Date(),
  leftSide: {},
  rightSide: {}
};

const kpiReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.KPI_SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading
      };
    case actions.KPI_SHOW_MODAL:
      if (!action.showModal) {
        state.leftSide = {};
        state.rightSide = {};
        state.storeData1 = [];
        state.storeData2 = [];
      }
      return {
        ...state,
        showModal: action.showModal
      };
    case actions.KPI_SET_STORE_DATA_1:
      return {
        ...state,
        storeData1: action.data
      };
    case actions.KPI_SET_STORE_DATA_2:
      return {
        ...state,
        storeData2: action.data
      };
    case actions.KPI_SET_START_DATE:
      return {
        ...state,
        startDate: action.date
      };
    case actions.KPI_SET_END_DATE:
      return {
        ...state,
        endDate: action.date
      };
    case actions.KPI_SET_LEFT:
      return {
        ...state,
        leftSide: action.leftSide
      };
    case actions.KPI_SET_RIGHT:
      return {
        ...state,
        rightSide: action.rightSide
      };
    case actions.KPI_DELETE_LEFT:
      return {
        ...state,
        leftSide: {}
      };
    case actions.KPI_DELETE_RIGHT:
      return {
        ...state,
        rightSide: {}
      };
    default:
      return {
        ...state
      };
  }
};

export default kpiReducer;
