import React, { useState, useContext, useEffect } from "react";
import { ContextConfig } from "../App";
import { toast } from "react-toastify";
import HeaderRB from "../common/HeaderRb";
import Spinner from "../common/Spinner";
import { getAssignedStoresAsync, changeStorename } from "../api/StoreApi";

const Storename = () => {
  const [stores, setStores] = useState([]);
  const [isLoadingStores, setIsLoadingStores] = useState(false);
  const [currentStore, setCurrentStore] = useState("");
  const [storename, setStorename] = useState("");

  const context = useContext(ContextConfig);

  useEffect(() => {
    loadStores();
  }, []);

  const loadStores = () => {
    setIsLoadingStores(true);
    getAssignedStoresAsync(context.url, context.token)
      .then((response) => {
        setIsLoadingStores(false);
        const j = response.data;
        if (j.error === 0) {
          setStores(j.items);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setIsLoadingStores(false);
        console.log("", err);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const handleStoreChange = (e) => {
    setCurrentStore(e.target.value);
  };

  const handleStorenameChange = (e) => {
    setStorename(e.target.value);
  };

  const handleClick = () => {
    changeStorename(context.url, context.token, currentStore, storename)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          toast.success("Store Name has been changed", {
            position: toast.POSITION.TOP_LEFT
          });
          loadStores();
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.ReactTOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An Internal error has occured", {
          position: toast.POSITION_TOP_LEFT
        });
      });
  };

  return (
    <div>
      {isLoadingStores && <Spinner />}

      <HeaderRB />
      <div className="container" id="controls">
        <div
          className="storenam-title"
          style={{
            width: "100%",
            textAlign: "center",
            fontSize: "1.4rem",
            fontWeight: "bold",
            marginBottom: "20px"
          }}
        >
          Change Storename
        </div>
        <div className="row hourly-d-header justify-content-center">
          <div className="col-xs-12 col-sm-8">
            <label>Select a Store</label>
            <select
              className="form-control h-select"
              onChange={handleStoreChange}
              value={currentStore || 0}
            >
              <option value="0">All Stores</option>
              {stores.length === 0
                ? null
                : stores.map((store) => (
                    <option key={store.storeid} value={store.storeid}>
                      {store.store_Name}
                    </option>
                  ))}
            </select>
          </div>
        </div>
      </div>
      <div className="container" id="body" style={{ marginTop: "20px" }}>
        <div className="row justify-content-center">
          <div className="col-xs-12 col-sm-8">
            <input
              type="text"
              className="form-control"
              placeholder="Enter new Store Name"
              value={storename}
              onChange={handleStorenameChange}
            />
          </div>
        </div>
        <div
          className="row justify-content-center"
          style={{ marginTop: "20px" }}
        >
          <div
            className="col"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <button className="btn btn-outline-light" onClick={handleClick}>
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Storename;
