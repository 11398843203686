import axios from "axios";
import qs from "qs";

async function loadDepartments(url, token, storeid) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "schedule/departments",
    params: {
      storeid
    }
  });
  return json;
}

async function addDepartment(
  url,
  token,
  storeid,
  description,
  poslink,
  group,
  userid
) {
  let json = await axios({
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: "Bearer " + token
    },
    url: url + "schedule/adddepartment",
    data: qs.stringify({
      storeid,
      description,
      poslink,
      group,
      userid
    })
  });
  return json;
}

async function copyDepartments(url, token, source, dest) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "schedule/copydept",
    params: {
      source,
      dest
    }
  });
  return json;
}

async function updateDepartment(url, token, storeid, department) {
  let json = await axios({
    method: "PUT",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: "Bearer " + token
    },
    url: url + "schedule/updatedept",
    data: qs.stringify({
      storeid,
      Id: department.id,
      description: department.description,
      posLink: department.posLink,
      group: department.group
    })
  });
  return json;
}

async function deleteDepartment(url, token, id) {
  let json = await axios({
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "schedule/deldept",
    params: {
      id
    }
  });
  return json;
}

export {
  loadDepartments,
  addDepartment,
  copyDepartments,
  updateDepartment,
  deleteDepartment
};
