import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import Spinner from "../../common/Spinner";
import CreateStoreForm from "./CreateStoreForm";
import { readStates } from "../../api/statesApi";
import { ContextConfig } from "../../App";
import { usePrevious, isValid, formatDate, formatTimeAmPm } from "../../utils";
import { isEqual, debounce } from "../../fp";
import { readCompanies } from "../../api/companyApi";
import { createClockStore, storeGroups } from "../../api/StoreApi";
import { toast } from "react-toastify";
import { isBrowser } from "react-device-detect";

const initialState = {
  id: "0",
  storeName: "",
  storeNumber: "",
  address: "",
  city: "",
  state: "",
  zip: "",
  phoneNumber: "",
  openTime: "1/1/1900 5:00 am",
  closeTime: "1/1/1900 5:00 am",
  weekStart: 0,
  companyId: 0,
  storeGroup: 0
};

const CreateStore = ({ activeStore, updateStoreList }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [store, setStore] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [states, setStates] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [groups, setGroups] = useState([]);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const previousStates = usePrevious(states);
  const previousCompanies = usePrevious(companies);

  const context = useContext(ContextConfig);

  const formIsValid = () => {
    const _errors = {};
    if (!store.storeName) _errors.storeName = "This field is required";
    if (!store.storeNumber) _errors.storeNumber = "This field is required";
    if (!store.companyId) _errors.company = "This field is required";
    if (!store.weekStart === -1) _errors.weekStart = "This field is required";
    if (!store.phoneNumber) _errors.phoneNumber = "This field is required";
    if (!store.state) _errors.state = "This field is required";
    if (store.state == "0") _errors.state = "This field is required";
    if (store.store == "-1") _errors.state = "This field is required";
    if (!store.storeGroup) _errors.storeGroup = "This field is required";
    if (store.storeGroup == "0") _errors.storeGroup = "This field is required";

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  };

  useEffect(() => {
    if (!isEqual(previousStates, states)) {
      loadStates();
    }

    if (!isEqual(previousCompanies, companies)) {
      loadCompanies();
    }

    if (isValid(activeStore)) {
      setStore(activeStore);
    }

    getStoreGroups();

    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);

    findDivHeight();

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, []);

  const findDivHeight = () => {
    if (!isBrowser) return;
    const navbar = document
      .getElementById("master-navbar")
      .getBoundingClientRect().height;

    const usedSpace = navbar + 180;
    const remaining = window.innerHeight - usedSpace;

    const widget = document.getElementById("create-store");
    widget.style.height = remaining + "px";
    widget.style.overflow = "auto";
  };

  const loadStates = () => {
    setIsLoading(true);
    readStates(context.clockUrl, context.clockToken)
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          setStates(j.states);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const loadCompanies = () => {
    readCompanies(context.clockUrl, context.clockToken)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setCompanies(j.companies);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleOnChange = ({ target }) => {
    setStore({
      ...store,
      [target.name]: target.value
    });
  };

  const handleOpenChange = (value) => {
    const temp = formatDate(value) + " " + formatTimeAmPm(value);
    setStore({
      ...store,
      openTime: temp
    });
  };

  const handleCloseChange = (value) => {
    const temp = formatDate(value) + " " + formatTimeAmPm(value);
    setStore({
      ...store,
      closeTime: temp
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formIsValid()) {
      return;
    }

    createClockStore(context.clockUrl, context.clockToken, store)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          toast.success("Store Success");
          setStore(initialState);
          updateStoreList();
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const getStoreGroups = () => {
    storeGroups(context.clockUrl, context.clockToken)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setGroups(j.groups);
        } else {
          setGroups([]);
          toast.error(j.msg);
        }
      })
      .catch((err) => {
        console.log(err);
        setGroups([]);
        toast.error("An Internal error occured");
      });
  };

  return (
    <div id="create-store">
      {!isLoading ? null : <Spinner />}
      <div>
        <CreateStoreForm
          store={store}
          states={states}
          companies={companies}
          errors={errors}
          onChange={handleOnChange}
          onSubmit={handleSubmit}
          handleOpenChange={handleOpenChange}
          handleCloseChange={handleCloseChange}
          groups={groups}
        />
      </div>
    </div>
  );
};

CreateStore.propTypes = {
  activeStore: PropTypes.object,
  editMode: PropTypes.bool,
  updateStoreList: PropTypes.func
};

export default CreateStore;
