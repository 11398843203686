import React from "react";
import PropTypes from "prop-types";

const StoreDetail = ({ data, store, other }) => {
  const flippedTotalizers = [3001, 3002, 3004, 3011, 3101, 3112];

  const isAmountHigher = (f1034, amount) => {
    let otherAmount = 0;
    other.map((record) => {
      if (parseInt(record.f1034) === f1034) {
        otherAmount = parseFloat(record.f65);
      }
    });

    if (flippedTotalizers.includes(parseInt(f1034))) {
      return otherAmount > amount;
    }
    return amount > otherAmount;
  };

  const isQtyHigher = (f1034, amount) => {
    let otherAmount = 0;
    other.map((record) => {
      if (parseInt(record.f1034) === f1034) {
        otherAmount = parseFloat(record.f64);
      }
    });

    if (flippedTotalizers.includes(parseInt(f1034))) {
      return otherAmount > amount;
    }
    return amount > otherAmount;
  };

  return (
    <div className="kpi-store-container">
      <h3>{store.storeName}</h3>
      {data.length > 0 ? (
        <div>
          <div className="kpi-legend">
            <div>Totalizer</div>
            <div>Qty</div>
            <div>Sales</div>
          </div>
          {data.map((record, i) => (
            <div key={`si-${store.storeid}-${i}`} className="kpi-item">
              <div className="kpi-description">{record.f1039}</div>
              {isQtyHigher(parseInt(record.f1034), parseInt(record.f64)) ? (
                <div
                  className="kpi-qty kpi-green"
                  style={{ marginRight: "5px" }}
                >
                  {parseInt(record.f64)}
                </div>
              ) : (
                <div className="kpi-qty kpi-red" style={{ marginRight: "5px" }}>
                  {parseInt(record.f64)}
                </div>
              )}
              {isAmountHigher(
                parseInt(record.f1034),
                parseFloat(record.f65)
              ) ? (
                <div className="kpi-amount kpi-green">
                  {parseFloat(record.f65).toFixed(2)}
                </div>
              ) : (
                <div className="kpi-amount kpi-red">
                  {parseFloat(record.f65).toFixed(2)}
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <div className="kpi-empty-records">
          There was no data returned for this store
        </div>
      )}
    </div>
  );
};

StoreDetail.propTypes = {
  data: PropTypes.array,
  store: PropTypes.object,
  other: PropTypes.array
};

export default StoreDetail;
