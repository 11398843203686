import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import CircularProgressBar from "../../common/CircularProgressBar";
import preval from "preval.macro";

import "./UserInfo.css";

const UserInfo = (props) => {
  const [progress, setProgress] = useState(0);
  const [progressColor, setProgressColor] = useState("#47e61c");
  const [progressText, setProgressText] = useState("");
  const InitialProgress = {
    size: 150,
    progress: progress,
    progressText: progressText,
    strokeWidth: 15,
    circleOneStroke: "#d9edfe",
    circleTwoStroke: progressColor,
    showPercent: null
  };
  useEffect(() => {
    // I still need to investigate if this is using the correct number or not.
    // I think it needs to dynamic based on if we are in manager mode or not
    if (!props.generalSettings) return;
    if (
      props.timeRemainingInSeconds >=
        parseFloat(props.generalSettings.punchInTimeout) + 0.5 &&
      !props.managerMode
    ) {
      props.resetClock();
      return;
    }
    if (
      props.timeRemainingInSeconds >=
        parseFloat(props.generalSettings.managerTimeout) + 0.5 &&
      props.managerMode
    ) {
      props.resetClock();
      return;
    }

    // now we need to trick out progress bar to not be a progress bar and instead be a countdown timer
    // fun with countdown timers
    // timeRemainingInSeconds will count up from zero each time that it is reset so we just need to do the reverse
    // calculation and update our progress with that new value
    let progressLeft =
      props.generalSettings.punchInTimeout - props.timeRemainingInSeconds;
    if (props.managerMode) {
      progressLeft =
        props.generalSettings.managerTimeout - props.timeRemainingInSeconds;
    }
    let percentOfProgressBar =
      (progressLeft / props.generalSettings.punchInTimeout) * 100;
    if (props.managerMode) {
      percentOfProgressBar =
        (progressLeft / props.generalSettings.managerTimeout) * 100;
    }

    setProgress(percentOfProgressBar);
    if (percentOfProgressBar > 75) {
      setProgressColor("#47e61c");
    } else if (percentOfProgressBar < 25) {
      setProgressColor("#f00e0e");
    } else if (percentOfProgressBar < 50) {
      setProgressColor("#f59b33");
    } else if (percentOfProgressBar < 75) {
      setProgressColor("#daf542");
    }
    let newProgress =
      props.generalSettings.punchInTimeout - props.timeRemainingInSeconds + 1;
    if (props.managerMode) {
      newProgress =
        props.generalSettings.managerTimeout - props.timeRemainingInSeconds + 1;
    }
    if (
      newProgress > props.generalSettings.punchInTimeout &&
      !props.managerMode
    )
      newProgress = props.generalSettings.punchInTimeout;
    if (props.managerMode) {
      if (newProgress > props.generalSettings.managerTimeout)
        newProgress = props.generalSettings.managerTimeout;
    }
    setProgressText(newProgress);
  }, [props.timeRemainingInSeconds]);

  return (
    <div className="mt-4 w-100 user-info">
      <div>
        {props.context.store ? (
          <div className="storeid" style={{ textAlign: "center" }}>
            {`${props.context.store.storeNumber} ${props.context.store.storeName}`}
          </div>
        ) : null}
        <div style={{ width: "100%", textAlign: "center", fontSize: ".8rem" }}>
          VS: {preval`module.exports = new Date().toLocaleString();`}
        </div>
        <div className="d-username">Username: {props.username}</div>
        <div className="d-clockin-name">
          {props.firstname} {props.lastname}
        </div>
        <div className="d-clockin-email">{props.email}</div>
        <div className="d-employeeid">Employeeid: {props.employeeid}</div>
        {/* {props.links.length > 0 && props.status == "0" ? (
          <div className="m-clockin-select-store-list">
            <form onSubmit={props.handleLinkSubmit}>
              <div className="cc-form-item">
                <label>Select a Store to Clock Into</label>
                <select
                  className="form-control"
                  onChange={props.handleLinkChange}
                  value={props.currentStore || ""}
                >
                  <option value="0">Select a Store</option>
                  {props.links.map((link, index) => (
                    <option key={`l-${index}`} value={link.storeid}>
                      {link.storeName}
                    </option>
                  ))}
                </select>
              </div>
            </form>
          </div>
        ) : null} */}
        {props.departments.length > 0 && props.status == "0" ? (
          <div className="m-clockin-select-department-list">
            <div className="cc-form-item">
              <label>Select a Department</label>
              <select
                className="form-control"
                onChange={props.handleDepartmentChange}
                value={props.currentDepartment || ""}
              >
                <option value="0">Select a Department</option>
                {props.departments.map((record, index) => (
                  <option key={`sd-${index}`} value={record.id}>
                    {record.description}
                  </option>
                ))}
              </select>
            </div>
          </div>
        ) : null}
      </div>
      <CircularProgressBar {...InitialProgress} />
      {props.managerMode ? (
        <div className="clock-manager-mode">Manager Mode</div>
      ) : null}
    </div>
  );
};

UserInfo.propTypes = {
  username: PropTypes.string,
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  email: PropTypes.string,
  status: PropTypes.any,
  links: PropTypes.array,
  handleLinkSubmit: PropTypes.func,
  handleLinkChange: PropTypes.func,
  currentStore: PropTypes.any,
  employeeid: PropTypes.string,
  timeRemainingInSeconds: PropTypes.number,
  generalSettings: PropTypes.object,
  resetClock: PropTypes.func,
  departments: PropTypes.array,
  handleDepartmentChange: PropTypes.func,
  currentDepartment: PropTypes.any,
  context: PropTypes.object,
  managerMode: PropTypes.bool
};

export default UserInfo;
