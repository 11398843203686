import * as actions from "../actions/actionTypes";

const initialState = {
  unassignedSearch: "",
  assignedSearch: ""
};

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SETTINGS_SET_ASSIGNED_SEARCH:
      return {
        ...state,
        assignedSearch: action.search
      };
    case actions.SETTINGS_SET_UNASSIGNED_SEARCH:
      return {
        ...state,
        unassignedSearch: action.search
      };
    default:
      return state;
  }
};

export default settingsReducer;
