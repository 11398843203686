import React from "react";
import PropTypes from "prop-types";

const DepartmentCopy = (props) => {
  return (
    <form className="form-inline justify-content-center">
      <div className="form group">
        <div className="form-check">
          <input
            type="checkbox"
            className="form-check-input"
            name="checkCopy"
            onChange={props.handleCheckChange}
            style={{
              opacity: "1",
              visibility: "visible",
              position: "relative"
            }}
          />
          <label htmlFor="checkCopy">Copy from Another Store?</label>
          {props.copy ? (
            <React.Fragment>
              <select
                className="form-control ml-3"
                name="copyStore"
                onChange={props.setCopyStore}
              >
                <option value="0">Select Store to copy from</option>
                {props.stores.length > 0
                  ? props.stores.map((store, index) => (
                      <option key={`copy-store-${index}`} value={store.id}>
                        {store.storeName}
                      </option>
                    ))
                  : null}
              </select>
              <button
                className="btn btn-outline-dark ml-3"
                onClick={props.handleCopySettings}
              >
                Copy
              </button>
            </React.Fragment>
          ) : null}
        </div>
      </div>
    </form>
  );
};

DepartmentCopy.propTypes = {
  handleCopySettings: PropTypes.func,
  handleCheckChange: PropTypes.func,
  copy: PropTypes.bool,
  setCopyStore: PropTypes.func,
  stores: PropTypes.array
};

export default DepartmentCopy;
