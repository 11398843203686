import React from "react";
import ReactDOM from "react-dom";
import "./PayserverCustomerHistory.css";
import { formatDate, formatTimeShort } from "../../../utils";

const PayserverCustomerHistory = ({
  isShowing,
  hide,
  currentCustomer,
  mousePos,
  customerHistory,
  formatTotalizer
}) =>
  isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <div className="modal-history-overlay" onClick={hide} />
          <div
            className="modal-history-wrapper"
            aria-modal
            aria-hidden
            tabIndex={-1}
            role="dialog"
          >
            <div
              className="modal-history"
              style={{
                position: "absolute",
                top: "100",
                left: mousePos.clientX
              }}
            >
              <div className="modal-history-header">
                <span className="modal-history-title">Customer History</span>
                <span className="modal-history-close-box">
                  <button
                    type="button"
                    className="modal-history-close-button"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={hide}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </span>
              </div>
              <div className="modal-history-body">
                <div className="modal-history-customer-title">
                  Current Customer Number: {currentCustomer}
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Store</th>
                        <th>Term</th>
                        <th>Totalizer</th>
                        <th>Trans</th>
                        <th>Points</th>
                      </tr>
                    </thead>
                    <tbody>
                      {customerHistory.map((record, index) => (
                        <tr key={`tr-ch-${index}`}>
                          <td>{formatDate(record.f254)}</td>
                          <td>{formatTimeShort(record.f253)}</td>
                          <td>{record.f1056}</td>
                          <td>{record.f1057}</td>
                          <td>{formatTotalizer(record.f1034)}</td>
                          <td>{record.f1032}</td>
                          <td>{record.f64}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>,
        document.body
      )
    : null;

export default PayserverCustomerHistory;
