import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardHeader, CardBody, CardFooter } from "reactstrap";
import PropTypes from "prop-types";
import { formatDate, formatTimeAmPm } from "../../utils";
import Tooltip from "../../Tooltip/Tooltip";
import { useDispatch } from "react-redux";
import PriceModModal from "./PriceModModal";
import { isMobile } from "react-device-detect";
import { debounce } from "../../fp";

import * as actions from "../../actions/actionTypes";
import "./PriceMod.css";

const PriceModWidget = ({ freshopState }) => {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  useEffect(() => {
    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
    }, 500);

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, []);

  const handleShowInfo = (item) => {
    dispatch({
      type: actions.FRESHOP_SET_ACTIVE_PRICE_MOD,
      currentPriceMod: item
    });
    setShowModal(!showModal);
  };
  return (
    <React.Fragment>
      {showModal && (
        <PriceModModal
          isShowing={showModal}
          hide={() => setShowModal(false)}
          dimensions={dimensions}
        />
      )}
      <h5 className="error-item-title">Price Modifications</h5>
      <Row className="justify-content-center align-items-center">
        {freshopState.priceMods.length > 0
          ? freshopState.priceMods.map((item, index) => (
              <Col lg="6" md="12" key={`uos-${index}`}>
                <Card className="freshop-price-mod-widget freshop-fade-in">
                  <CardHeader>
                    <div className="freshop-pricemod-cardheader">
                      <div>{formatDate(item.postDate)}</div>
                      <div>Order Id: {item.freshopOrderid}</div>
                      <div>UPC: {item.upc}</div>
                      {!isMobile ? (
                        <div
                          className="freshop-pricemod-header-info"
                          onClick={() => handleShowInfo(item)}
                        >
                          <Tooltip
                            message={
                              "Click me to see more information about this item"
                            }
                            position={"bottom"}
                          >
                            <i className="far fa-info-circle"></i>
                          </Tooltip>
                        </div>
                      ) : null}
                    </div>
                  </CardHeader>
                  <CardBody>
                    <div className="freshop-pricemod-row1">
                      <div>{item.description}</div>
                      <div className="freshop-pricemod-iteminfo">
                        Freshop Price: {item.freshopPrice}
                      </div>
                    </div>
                    <div className="freshop-pricemod-row2">
                      <div className="freshop-pricemod-iteminfo">
                        Retail: {item.retail}/{parseInt(item.retailSplit)}
                      </div>
                      <div className="freshop-pricemod-iteminfo">
                        Active: {item.f1007}/{parseInt(item.f1006)}
                      </div>
                      <div className="freshop-pricemod-iteminfo">
                        Fulfilled: {item.fulfilledPrice}/
                        {parseInt(item.fulfilledQuantity)}
                      </div>
                    </div>
                  </CardBody>
                  <CardFooter
                    style={{ borderTop: "1px solid #fff !important" }}
                  >
                    <div className="freshop-pricemod-footer">
                      <div className="freshop-pricemod-iteminfo">
                        {formatDate(item.postTime)}{" "}
                        {formatTimeAmPm(item.postTime)}
                      </div>
                      <div className="freshop-pricemod-iteminfo">
                        SMS Trans: {item.smsTransaction}
                      </div>
                      <div className="freshop-pricemod-iteminfo">
                        Active Price: {item.smsActivePrice}
                      </div>
                    </div>
                  </CardFooter>
                </Card>
              </Col>
            ))
          : null}
      </Row>
    </React.Fragment>
  );
};

PriceModWidget.propTypes = {
  freshopState: PropTypes.array
};

export default PriceModWidget;
