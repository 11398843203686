import React from "react";
import PropTypes from "prop-types";
import "./EntryButton.css";

const EntryButton = (props) => {
  const handleClick = (e) => {
    if (props.disabled) {
      e.preventDefault();
      return;
    }
    props.handleButtonClick(e.target.innerText);
  };
  return (
    <div
      className={`d-flex flex-column col-md-3 p-3 align-middle ${
        props.disabled ? "d-entry-button-disabled" : "d-entry-button"
      } `}
      onClick={handleClick}
    >
      <div className="d-flex align-items-center my-auto">
        <div className="d-entry-button-value">{props.value}</div>
      </div>
    </div>
  );
};

EntryButton.propTypes = {
  value: PropTypes.string,
  handleButtonClick: PropTypes.func,
  disabled: PropTypes.bool
};

export default EntryButton;
