import React, { useEffect, useContext, useState } from "react";
import ClockDesktopMenu from "../ClockDesktopMenu";
import { ContextConfig } from "../../App";
import { toast } from "react-toastify";
import Header from "../widgets/Header";
import SingleHeader from "../widgets/SingleHeader";
import ManualPunch from "./ManualPunch";
import { getGeneralSettings } from "../../api/settingsApi";
import PunchList from "./PunchList";
import { readUsersFromStore } from "../../api/UsersApi";
import ErrorBoundary from "../../common/ErrorBoundary";
import { useSelector, useDispatch } from "react-redux";
import { isValid } from "../../utils";
import * as actions from "../../actions/actionTypes";
import "./Edits.css";

const Edit = () => {
  const [departments, setDepartments] = useState([]);
  const [filteredDepartment, setFilteredDepartment] = useState(0);
  const state = useSelector((app) => app.editReducer);
  const appState = useSelector((app) => app.appReducer);
  const dispatch = useDispatch();
  const [reRender, setReRender] = useState(false);

  const context = useContext(ContextConfig);

  useEffect(() => {
    generalSettings();
    getStoreUsers();

    if (state.punchUser == context.userid) {
      if (context.settings.dissalowEditingOfYourOwnTime == "1") {
        dispatch({ type: actions.EDIT_SET_IS_LOCKED, isLocked: true });
      } else {
        dispatch({ type: actions.EDIT_SET_IS_LOCKED, isLocked: false });
      }
    } else {
      dispatch({ type: actions.EDIT_SET_IS_LOCKED, isLocked: false });
    }

    let closeDate = context.lastBatchClose;
    if (closeDate.length > 0) {
      const batchDate = new Date(closeDate);
      if (context.endDate <= batchDate) {
        dispatch({ type: actions.EDIT_SET_IS_EDITABLE, isEditable: false });
      } else {
        dispatch({ type: actions.EDIT_SET_IS_EDITABLE, isEditable: true });
      }
    }

    return () => {
      dispatch({ type: actions.EDIT_RESET });
    };
  }, [appState.reRender, state.punchUser, appState.store]);

  useEffect(() => {
    dispatch({ type: actions.EDIT_SET_PUNCH_USER, punchUser: "" });
    const punch = {
      selectedUser: 0
    };
    context.punch = punch;
  }, []);

  const getStoreUsers = () => {
    readUsersFromStore(
      context.clockUrl,
      context.clockToken,
      context.lastClockStore
    )
      .then((response) => {
        var j = response.data;
        if (j.error === 0) {
          if (j.users.length > 0) {
            dispatch({ type: actions.EDIT_SET_USERS, users: j.users });
          } else {
            dispatch({ type: actions.EDIT_SET_USERS, users: [] });
          }
        } else {
          dispatch({ type: actions.EDIT_SET_USERS, users: [] });
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: actions.EDIT_SET_USERS, users: [] });
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const handleUserChange = (e) => {
    const punch = { selectedUser: e.target.value };
    context.punch = punch;
    dispatch({ type: actions.EDIT_SET_PUNCH_USER, punchUser: e.target.value });
  };

  const generalSettings = () => {
    getGeneralSettings(
      context.clockUrl,
      context.clockToken,
      context.lastClockStore
    )
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          if (isValid(j.settings)) {
            dispatch({ type: actions.EDIT_SET_SETTINGS, settings: j.settings });
          } else {
            dispatch({ type: actions.EDIT_SET_SETTINGS, settings: null });
          }
        } else {
          dispatch({ type: actions.EDIT_SET_SETTINGS, settings: null });
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        dispatch({ type: actions.EDIT_SET_SETTINGS, settings: null });
        console.log(err);
        toast.error("An error occured getting the General Settings", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const handleSingleDayChange = (e) => {
    dispatch({
      type: actions.EDIT_SET_SINGLE_DAY,
      singleDay: e.target.checked
    });
  };

  Array.prototype.contains = function(field) {
    let result = false;
    this.forEach((record) => {
      if (record.departmentId == field.departmentId) {
        result = true;
      }
    });
    return result;
  };

  const getDepartments = (punches) => {
    const newArray = [];
    punches.map((punch) => {
      if (!newArray.contains(punch)) {
        newArray.push({
          departmentId: punch.departmentId,
          departmentDescription: punch.departmentDescription
        });
      }
    });
    setDepartments(newArray);
  };

  const handleDeptChange = (e) => {
    setFilteredDepartment(parseInt(e.target.value));
  };

  return (
    <div className="d-edits">
      <ClockDesktopMenu />

      <div
        style={{
          width: "100%",
          marginTop: "20px",
          textAlign: "center",
          fontSize: "1.3rem",
          fontWeight: "bold"
        }}
      >
        Punch Edits
      </div>
      {!state.singleDay ? (
        <Header reRender={reRender} setReRender={setReRender} />
      ) : (
        <SingleHeader />
      )}
      <div className="container">
        <div className="row justify-content-center mt-3 mb-3">
          <div className="col-4">
            {context.userLevel >= 5 ? (
              <select
                id="comboPunchUser"
                className="form-control"
                onChange={handleUserChange}
              >
                {state.users.length > 0 ? (
                  <option value="0">All Employees</option>
                ) : null}
                {state.users.length > 0 ? (
                  state.users.map((record, index) => (
                    <option key={`users-${index}`} value={record.userid}>
                      {record.firstName} {record.lastName} - (
                      {record.employeeId})
                    </option>
                  ))
                ) : (
                  <option value="0">There are no Users to display</option>
                )}
              </select>
            ) : null}
          </div>
          <div className="col-2 ml-4" style={{ paddingTop: "10px" }}>
            <div className="form-check m-auto">
              <input
                type="checkbox"
                className="form-check-input"
                style={{ opacity: "1", visibility: "visible" }}
                value={state.singleDay}
                checked={state.singleDay ? true : false}
                onChange={handleSingleDayChange}
              />
              <label style={{ color: "#fff" }}>View Single Day</label>
            </div>
          </div>
          <div className="col-4 ml-4">
            <select className="form-control" onChange={handleDeptChange}>
              <option value="0">Select Department</option>
              {departments.length > 0
                ? departments.map((dept, i) => (
                    <option key={`d-${i}`} value={dept.departmentId}>
                      {dept.departmentDescription}
                    </option>
                  ))
                : null}
            </select>
          </div>
        </div>
      </div>

      <div className="container-fluid p-3">
        <ErrorBoundary>
          <ManualPunch
            context={context}
            isEditable={state.isEditable}
            isLocked={state.isLocked}
          />
        </ErrorBoundary>

        <ErrorBoundary>
          <PunchList
            context={context}
            settings={state.settings}
            punchUser={state.punchUser}
            isEditable={state.isEditable}
            isLocked={state.isLocked}
            singleDay={state.singleDay}
            getDepartments={getDepartments}
            filteredDepartment={filteredDepartment}
          />
        </ErrorBoundary>
      </div>
    </div>
  );
};

export default Edit;
