import React, { useState, useEffect, useContext } from "react";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import Spinner from "../../common/Spinner";
import ClockSidebar from "../ClockSidebar";
//import colors from "../../common/colors";
import { ContextConfig } from "../../App";
import ClockInMobile from "./ClockInMobile";
import "./ClockIn.css";
import { usePrevious } from "../../utils";
import { isEqual } from "../../fp";
import PunchList from "./PunchList";
import {
  loadGeneralSettings,
  loadPunches,
  createClockPunch,
  UserStatus
} from "./ClockInOut-I";
import { toast } from "react-toastify";
import { loadDepartments } from "../../api/departmentApi";
import { getStorename } from "../../api/UsersApi";
import ClockDesktopMenu from "../ClockDesktopMenu";
import ClockInDesk from "./ClockInDesk";

const ClockInOut = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [links, setLinks] = useState([]);
  const [showLinkOptions, setShowLinkOptions] = useState(false);
  const [currentStore, setCurrentStore] = useState("");
  const [generalSettings, setGeneralSettings] = useState({});
  const [comment, setComment] = useState("");
  const [punches, setPunches] = useState([]);
  const [total, setTotal] = useState("");
  const [departments, setDepartments] = useState([]);
  const [currentDepartment, setCurrentDepartment] = useState("");
  const [storename, setStorename] = useState("");

  const context = useContext(ContextConfig);

  const previousGeneralSettings = usePrevious(generalSettings);
  const previousStatus = usePrevious(status);
  const previousPunches = usePrevious(punches);

  useEffect(() => {
    fetchStorename();
    if (
      typeof context.defaultDepartment !== "undefined" &&
      context.defaultDepartment
    ) {
      setCurrentDepartment(context.defaultDepartment);
    }
    if (!isEqual(previousStatus, status) && previousStatus != "") {
      UserStatus(
        setIsLoading,
        isMobile,
        context,
        setCurrentStore,
        setLinks,
        setStatus
      );
    }
    if (!isEqual(previousPunches, punches)) {
      loadPunches(
        currentStore,
        setIsLoading,
        context,
        setPunches,
        setGeneralSettings,
        generalSettings,
        setTotal
      );
    }

    // now we need to see if this user has more than one link or not
    // if they do, we need to ask them which store they are clocking in at.
    // then we need to get the general settings for that store to see what their clockin options are
    if (links.length > 1) {
      setShowLinkOptions(true);
    }

    if (!isEqual(previousGeneralSettings, generalSettings)) {
      loadGeneralSettings();
    }
  }, [status, generalSettings, showLinkOptions]);

  useEffect(() => {
    fetchDepartments();
  }, [currentStore]);

  const handleLinkSubmit = (e) => {
    e.preventDefault();
    // now that we know what store the user wants to clock into, we need to get the general settings
    loadGeneralSettings(
      currentStore,
      setIsLoading,
      context,
      setGeneralSettings,
      setPunches
    );
  };

  const fetchStorename = () => {
    getStorename(context.clockUrl, context.clockToken).then((response) => {
      const j = response.data;
      if (j.error == "0") {
        setStorename(j.storename);
      }
    });
  };

  const handleLinkChange = (e) => {
    setCurrentStore(e.target.value);
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleClockIn = () => {
    sendPunchToServer(1);
  };

  const clockOut = () => {
    sendPunchToServer(2);
  };

  const startBreak = () => {
    sendPunchToServer(3);
  };

  const startLunch = () => {
    sendPunchToServer(5);
  };

  const endBreak = () => {
    sendPunchToServer(4);
  };

  const endLunch = () => {
    sendPunchToServer(6);
  };

  const sendPunchToServer = (punchType) => {
    createClockPunch(
      setShowLinkOptions,
      setIsLoading,
      context,
      currentStore,
      comment,
      generalSettings,
      UserStatus,
      setPunches,
      setStatus,
      setLinks,
      punchType,
      currentDepartment,
      setCurrentStore
    );
  };

  const fetchDepartments = () => {
    if (typeof currentStore === "undefined" || currentStore === null) return;
    loadDepartments(context.clockUrl, context.clockToken, currentStore)
      .then((response) => {
        const j = response.data;
        if (j.error == "0") {
          setDepartments(j.departments);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An internal Error occured", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const handleDepartmentChange = (e) => {
    setCurrentDepartment(e.target.value);
  };

  return (
    <div className="clock-mobile">
      {isLoading && <Spinner />}
      <BrowserView>
        <div className="clock-header">
          <ClockDesktopMenu />
          <div className="container">
            <div className="m-clockin-title">Clock In/Out</div>
            <ClockInDesk
              email={context.email}
              status={status}
              links={links}
              showLinkOptions={showLinkOptions}
              handleLinkSubmit={handleLinkSubmit}
              handleLinkChange={handleLinkChange}
              currentStore={currentStore}
              generalSettings={generalSettings}
              clockIn={handleClockIn}
              handleCommentChange={handleCommentChange}
              comment={comment}
              startBreak={startBreak}
              clockOut={clockOut}
              startLunch={startLunch}
              endBreak={endBreak}
              endLunch={endLunch}
              employeeid={context.employeeid}
              username={context.username}
              departments={departments}
              handleDepartmentChange={handleDepartmentChange}
              currentDepartment={currentDepartment}
              context={context}
              storename={storename}
            />
            <PunchList
              punches={punches}
              total={total}
              generalSettings={generalSettings}
            />
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div className="mobile-clock-header">
          <ClockSidebar />
          <div className="container cc-main-body">
            <div className="m-clockin-title">Clock In/Out</div>
            <ClockInMobile
              email={context.email}
              status={status}
              links={links}
              showLinkOptions={showLinkOptions}
              handleLinkSubmit={handleLinkSubmit}
              handleLinkChange={handleLinkChange}
              currentStore={currentStore}
              generalSettings={generalSettings}
              clockIn={handleClockIn}
              handleCommentChange={handleCommentChange}
              comment={comment}
              startBreak={startBreak}
              clockOut={clockOut}
              startLunch={startLunch}
              endBreak={endBreak}
              endLunch={endLunch}
              employeeid={context.employeeid}
              username={context.username}
              departments={departments}
              handleDepartmentChange={handleDepartmentChange}
              currentDepartment={currentDepartment}
              context={context}
              storename={storename}
            />
            <PunchList
              punches={punches}
              total={total}
              generalSettings={generalSettings}
            />
          </div>
        </div>
      </MobileView>
    </div>
  );
};

ClockInOut.propTypes = {};

export default ClockInOut;
