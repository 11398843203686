import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./PasswordStrengthMeter.css";

const PasswordStrengthMeter = ({
  userid,
  confirmPasswordChange,
  passwordChangeRef,
  confirmPasswordRef,
  setInputName,
  newPassword,
  setNewPassword,
  confirmPassword,
  setConfirmPassword
}) => {
  const [barOneClass, setBarOneClass] = useState("");
  const [barTwoClass, setBarTwoClass] = useState("");
  const [barThreeClass, setBarThreeClass] = useState("");
  const [barFourClass, setBarFourClass] = useState("");
  const [buttonActive, setButtonActive] = useState(false);
  const [passwordWarning, setPasswordWarning] = useState(
    "Password is required"
  );

  const passwordMinimum = 7;
  let mandatory = 0;

  useEffect(() => {
    calculateScore();
    if (newPassword.length < passwordMinimum && newPassword.length > 0) {
      setPasswordWarning("Password too short");
    } else if (newPassword.length > passwordMinimum) {
      setPasswordWarning("");
    }
  }, [newPassword]);

  const handlePasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleClick = (e) => {
    e.preventDefault();
    // first lets make sure the two match
    if (newPassword !== confirmPassword) {
      setPasswordWarning("Passwords dont match");
      return;
    }

    // then verify if a userid has been passed in or not
    if (!userid || userid.length == 0) {
      setPasswordWarning("Select a User");
      return;
    }

    confirmPasswordChange(newPassword);
  };

  const calculateScore = () => {
    let intScore = 0; // eslint-disable-line
    mandatory = 0;

    intScore = newPassword.length;

    if (newPassword.length > 0 && newPassword.length <= 4) {
      intScore += newPassword.length;
    } else if (newPassword.length >= 5 && newPassword.length <= 7) {
      intScore += 6;
    } else if (newPassword.length >= 8 && newPassword.length <= 15) {
      intScore += 12;
    } else if (newPassword.length >= 16) {
      intScore += 18;
    }
    if (newPassword.match(/[a-z]/)) {
      intScore += 1;
      mandatory++;
    }

    if (newPassword.match(/[A-Z]/)) {
      intScore += 5;
      mandatory++;
    }

    // numbers
    if (newPassword.match(/\d/)) {
      intScore += 5;
      mandatory++;
    }

    if (newPassword.match(/(:.*?\d){d}/)) {
      // at least 3 numbers
      intScore += 5;
    }

    if (newPassword.match(/[!,@,#,$,^,&,*,?,_,~]/)) {
      //verified at least one special character
      intScore += 5;
      mandatory++;
    }

    if (newPassword.match(/(?:.*?[!,@,#,$,%,^,&,*,?,_,~]){2}/)) {
      intScore += 5;
    }

    if (newPassword.match(/(?=.*[a-z])(?=.*[A-Z])/)) {
      //verified both uppercase and lowercase
      intScore += 2;
    }

    //verified letters,number and special charactyers
    if (
      newPassword.match(
        /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!,@,#,$,%,^,&,*,?,_,~])/
      )
    ) {
      intScore += 2;
    }

    switch (mandatory) {
      case 0:
        setBarOneClass("bad-password");
        setBarTwoClass("");
        setBarThreeClass("");
        setBarFourClass("");
        break;
      case 1:
        setBarOneClass("bad-password");
        setBarTwoClass("");
        setBarThreeClass("");
        setBarFourClass("");
        break;
      case 2:
        setBarOneClass("ok-password");
        setBarTwoClass("bad-password");
        setBarThreeClass("");
        setBarFourClass("");
        break;
      case 3:
        setBarOneClass("decent-password");
        setBarTwoClass("ok-password");
        setBarThreeClass("bad-password");
        setBarFourClass("");
        break;
      case 4:
        setBarOneClass("better-password");
        setBarTwoClass("decent-password");
        setBarThreeClass("ok-password");
        setBarFourClass("bad-password");
        break;
    }

    if (newPassword.length > passwordMinimum && mandatory >= 4) {
      setBarOneClass("good-password");
      setBarTwoClass("good-password");
      setBarThreeClass("good-password");
      setBarFourClass("good-password");
      setButtonActive(true);
    }
  };

  return (
    <div style={{ textAlign: "center" }} className="container-fluid">
      <div className="password-title-container">
        <div className="password-title">Password</div>
        <div className="password-warning" id="password-warning">
          {passwordWarning}
        </div>
      </div>
      <div className="password-instructions">
        You need at least one special character, one number, one upperCase
        letter and one lowercase character.
        <br /> Also a minimum of 7 characters.
      </div>
      <div
        className={
          newPassword.length === 0
            ? "password-bar-container-hidden"
            : "password-bar-container"
        }
      >
        <div className={`password-bar ${barOneClass}`} id="password-one"></div>
        <div className={`password-bar ${barTwoClass}`} id="password-one"></div>
        <div
          className={`password-bar ${barThreeClass}`}
          id="password-one"
        ></div>
        <div className={`password-bar ${barFourClass}`} id="password-one"></div>
      </div>
      <input
        className="mt-2 w-100 form-control"
        type="password"
        autoComplete="timeclock-password"
        onChange={handlePasswordChange}
        value={newPassword}
        placeholder="Enter Password"
        ref={passwordChangeRef}
        onFocus={() => setInputName("changepassword")}
      />
      <input
        className="mt-2 w-100 form-control"
        type="password"
        autoComplete="timeclock-confirm-password"
        onChange={handleConfirmChange}
        value={confirmPassword}
        placeholder="Confirm Password"
        ref={confirmPasswordRef}
        onFocus={() => setInputName("confirmpassword")}
      />
      {!buttonActive ? (
        <div></div>
      ) : (
        <button className="btn btn-outline-dark mt-2" onClick={handleClick}>
          Submit
        </button>
      )}
    </div>
  );
};

PasswordStrengthMeter.propTypes = {
  userid: PropTypes.number,
  confirmPasswordChange: PropTypes.func,
  redirect: PropTypes.bool,
  setInputName: PropTypes.func,
  passwordChangeRef: PropTypes.any,
  confirmPasswordRef: PropTypes.any,
  newPassword: PropTypes.string,
  setNewPassword: PropTypes.func,
  confirmPassword: PropTypes.string,
  setConfirmPassword: PropTypes.func
};

export default PasswordStrengthMeter;
