/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import { BrowserView, MobileView, isBrowser } from "react-device-detect";
import { toast } from "react-toastify";
import ClockDesktopMenu from "../ClockDesktopMenu";
import { getUserClockStores, getWeekStart } from "../../api/StoreApi";
import { debounce } from "../../fp";
import { ContextConfig } from "../../App";
import ClockSidebar from "../ClockSidebar";
import { addDays, formatDate, isValidDate } from "../../utils";
import { liveSchedule } from "../../api/scheduleApi";

const LiveSchedule = () => {
  const [stores, setStores] = useState([]);
  const [currentStore, setCurrentStore] = useState(0);
  const [weekStart, setWeekStart] = useState();
  const [dateRange, setDateRange] = useState([]);
  const [selectedWeek, setSelectedWeek] = useState();
  const [schedule, setSchedule] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const context = useContext(ContextConfig);

  const daysOfWeek = ["Sun", "Mon", "Tues", "Wed", "Thur", "Fri", "Sat"];

  useEffect(() => {
    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);

    findDivHeight();

    getClockStores();

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, []);

  useEffect(() => {
    if (isValidDate(selectedWeek)) {
      getLiveSchedule();
    }
  }, [selectedWeek]);

  const findDivHeight = () => {
    if (!isBrowser) return;
    const navbar = document
      .getElementById("master-navbar")
      .getBoundingClientRect().height;
    const controls = document.getElementById("controls").getBoundingClientRect()
      .height;

    const usedSpace = navbar + controls + 50;
    const remaining = window.innerHeight - usedSpace;

    const widget = document.getElementById("body");
    if (widget) {
      widget.style.height = remaining + "px";
      widget.style.overflow = "auto";
    }
  };

  const getClockStores = () => {
    getUserClockStores(context.clockUrl, context.clockToken)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          if (j.stores.length > 0) {
            setStores(j.stores);
            setCurrentStore(j.stores[0].id);
            findWeekStart(j.stores[0].id);
          } else {
            setStores([]);
            toast.error("No Stores were returned", {
              position: toast.POSITION.TOP_LEFT
            });
          }
        } else {
          setStores([]);
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setStores([]);
        console.log(err);
        toast.error("An Internal error occured", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const handleStoreChange = (e) => {
    setCurrentStore(e.target.value);
    findWeekStart(e.target.value);
  };

  const findWeekStart = (storeid) => {
    getWeekStart(context.clockUrl, context.clockToken, storeid)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setWeekStart(j.weekstart);
          buildDates(j.weekstart);
        } else {
          toast.error(j.msg);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const buildDates = (weekStart) => {
    const newArray = [];
    const startDate = new Date(weekStart);
    const week1 = addDays(startDate, 6);
    newArray.push(week1);
    const week2 = addDays(week1, 7);
    newArray.push(week2);
    const week3 = addDays(week2, 7);
    newArray.push(week3);
    const week4 = addDays(week3, 7);
    newArray.push(week4);
    const week5 = addDays(week4, 7);
    newArray.push(week5);
    const week6 = addDays(week5, 7);
    newArray.push(week6);
    setDateRange(newArray);
    setSelectedWeek(newArray[0]);
  };

  const handleWeekChange = (e) => {
    setSelectedWeek(e.target.value);
  };

  const getLiveSchedule = () => {
    const weekEndDate = new Date(selectedWeek);
    const weekStartDate = addDays(weekEndDate, -6);
    liveSchedule(
      context.clockUrl,
      context.clockToken,
      currentStore,
      context.userid,
      formatDate(weekStartDate),
      formatDate(weekEndDate)
    )
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setSchedule(j.schedule);
          setCurrentUser(j.user);
        } else {
          setSchedule([]);
          setCurrentUser({});
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setSchedule([]);
        setCurrentUser({});
        toast.error(err.message, {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const getTotal = () => {
    return schedule.reduce((acc, cur) => {
      return acc + parseFloat(cur.totalMinutes);
    }, 0);
  };

  return (
    <div>
      <MobileView>
        <div className="clock-mobile">
          <div className="mobile-clock-header">
            <ClockSidebar />
            <div className="container cc-main-body" id="clock-controls">
              <div
                style={{
                  width: "100%",
                  marginTop: "20px",
                  textAlign: "center",
                  fontSize: "1.3rem",
                  fontWeight: "bold"
                }}
              >
                Live Schedule
              </div>
              <div className="row justify-content-center align-items-center">
                <div className="col-4">
                  <label>Select Week</label>
                  <select
                    className="form-control"
                    value={selectedWeek}
                    onChange={handleWeekChange}
                  >
                    {dateRange.map((date, i) => (
                      <option key={`drv-${i}`} value={formatDate(date)}>
                        {formatDate(date)}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-4">
                  <label>Select a Store</label>
                  <select
                    className="form-control"
                    value={currentStore}
                    onChange={handleStoreChange}
                  >
                    {stores.map((store, i) => (
                      <option key={`sts-${i}`} value={store.id}>
                        {store.storeName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div id="mobile-body">
                {schedule && schedule.length > 0 ? (
                  <div>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Start</th>
                          <th>End</th>

                          <th style={{ textAlign: "center" }}>Description</th>
                          <th style={{ textAlign: "center" }}>Hours</th>
                        </tr>
                      </thead>
                      <tbody>
                        {schedule.map((shift, i) => (
                          <tr key={`ss-${i}`}>
                            <td>{shift.startTime}</td>
                            <td>{shift.endTime}</td>

                            <td style={{ textAlign: "center" }}>
                              {shift.departmentDescription}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {shift.totalMinutes}
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td colSpan="4" style={{ textAlign: "right" }}>
                            <b>Total</b>
                          </td>
                          <td style={{ textAlign: "center" }}>{getTotal()}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: "1.2rem",
                      color: "#fff",
                      marginTop: "25px"
                    }}
                  >
                    There is currently no schedule to display
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </MobileView>
      <BrowserView>
        <div>
          <ClockDesktopMenu />
          <div className="container mt-4">
            <div
              id="controls"
              style={{
                width: "100%",
                marginTop: "20px",
                textAlign: "center",
                fontSize: "1.3rem",
                fontWeight: "bold"
              }}
            >
              Live Schedule
              <div className="row justify-content-center align-items-center">
                <div className="col-4">
                  <label>Select Week</label>
                  <select
                    className="form-control"
                    value={selectedWeek}
                    onChange={handleWeekChange}
                  >
                    {dateRange.map((date, i) => (
                      <option key={`drv-${i}`} value={formatDate(date)}>
                        {formatDate(date)}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-4">
                  <label>Select a Store</label>
                  <select
                    className="form-control"
                    value={currentStore}
                    onChange={handleStoreChange}
                  >
                    {stores.map((store, i) => (
                      <option key={`sts-${i}`} value={store.id}>
                        {store.storeName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div id="body">
              {schedule && schedule.length > 0 ? (
                <div>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Day</th>
                        <th>Start</th>
                        <th>End</th>
                        <th style={{ textAlign: "center" }}>Department</th>
                        <th style={{ textAlign: "center" }}>Description</th>
                        <th style={{ textAlign: "center" }}>Hours</th>
                      </tr>
                    </thead>
                    <tbody>
                      {schedule.map((shift, i) => (
                        <tr key={`ss-${i}`}>
                          <td>
                            {daysOfWeek[new Date(shift.startTime).getDay()]}
                          </td>
                          <td>{shift.startTime}</td>
                          <td>{shift.endTime}</td>
                          <td style={{ textAlign: "center" }}>
                            {shift.department}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {shift.departmentDescription}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {shift.totalMinutes}
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan="5" style={{ textAlign: "right" }}>
                          <b>Total</b>
                        </td>
                        <td style={{ textAlign: "center" }}>{getTotal()}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : (
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "1.2rem",
                    color: "#fff",
                    marginTop: "25px"
                  }}
                >
                  There is currently no schedule to display
                </div>
              )}
            </div>
          </div>
        </div>
      </BrowserView>
    </div>
  );
};

export default LiveSchedule;
