import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { formatDate, addDays } from "../../utils";

const Employee = (props) => {
  const { user, days, shifts, startDate } = props;

  useEffect(() => {}, []);

  const getEmployeeTotal = () => {
    if (typeof shifts === "undefined" || shifts === null) return;
    if (typeof startDate === "undefined" || startDate === null) return;

    if (shifts.length === 0) {
      return 0;
    }

    return shifts.reduce((acc, cur) => {
      var total = parseFloat(acc) + parseFloat(cur.totalHours);
      return parseFloat(total).toFixed(2);
    }, 0);
  };

  const findShift = (day) => {
    if (typeof shifts === "undefined" || shifts === null) return;
    if (typeof startDate === "undefined" || startDate === null) return;

    const shift = shifts.filter(
      (s) => formatDate(s.punchDate) === formatDate(addDays(startDate, day))
    );
    if (shift.length === 0) return null;
    if (shift.length > 1) {
      return shift.map((s) => <div key={s.id}>{s.id}</div>);
    } else {
      return (
        <div className="employee-shift fade-shift-in">
          <div
            className="shift-info"
            style={{ color: "#fff", fontWeight: "bold" }}
          >
            Total: {shift[0].totalHours}
          </div>
          <div className="shift-info">{shift[0].department}</div>
        </div>
      );
    }
  };

  return (
    <div className="employee-row">
      <div className="employee-name fade-shift-in">
        <div>
          {user.firstName} {user.lastName}
        </div>
        <div>#: {user.employeeId}</div>
        <div>Userid: {user.userid}</div>
      </div>
      {days.map((day, dayI) => (
        <div
          key={`ed-${dayI}`}
          id={`${user.userid}-${day}`}
          className="cell day-cell"
        >
          {findShift(day)}
        </div>
      ))}

      <div className="cell employee-total">{getEmployeeTotal()}</div>
    </div>
  );
};

Employee.propTypes = {
  user: PropTypes.object,
  days: PropTypes.array,
  startDate: PropTypes.instanceOf(Date),
  shifts: PropTypes.array,
  context: PropTypes.object,
  reloadSchedule: PropTypes.bool,
  setReloadSchedule: PropTypes.func,
  handleDateClick: PropTypes.func
};

export default Employee;
