function curry(fn, arity = fn.length) {
  return (function nextCurried(prevArgs) {
    return function curried(nextArg) {
      var args = [...prevArgs, nextArg];

      if (args.length >= arity) {
        return fn(...args);
      } else {
        return nextCurried(args);
      }
    };
  })([]);
}

// function log(level, message){
//      console.log(level + ":" + message)
// }
// const logInfo = partial(log, "info");
// logInfo("a message");
function partial(fn, ...leftArgs) {
  return function(...rightArgs) {
    return fn(...leftArgs, ...rightArgs);
  };
}

// you can use this as a generator
// const generator = sequence()
// generator(); yields 1
// generator(); yields 2, etc...
function sequence() {
  let count = 0;
  return function() {
    count += 1;
    return count;
  };
}

// you can use this to increment and decrement
// counter.increment(); yields 1
// counter.decrement(); yields 0
// this is the revealing module pattern
const counter = (function() {
  let state = 0;

  function increment() {
    state += 1;
    return state;
  }

  function decrement() {
    state -= 1;
    return state;
  }

  return {
    increment,
    decrement
  };
})();

// function process(){
//      console.log("process ran");
//}
//
// const processOnce = once(process);
// processOnce(); // this time it will run
// processOnce(); // this time it will not call the originating function being "process()"
function once(fn) {
  let returnValue;
  let canRun = true;
  return function(...args) {
    if (canRun) {
      returnValue = fn(...args);
      canRun = false;
    }
    return returnValue;
  };
}

// this function only runs after a certain number of calls
//
// function logResults(){
//      console.log("finish");
// }
// const logResultAfter2 = after(logResult, 2);
// setTimeout(function firstCall(){
//      console.log("1rst");
//      logResultAfter2()
// }, 3000)
//
// setTimeout(function secondCall(){
//      console.log("2nds")
//      logResultAfter2();
//}, 4000)
function after(fn, startCount) {
  let count = 0;
  return function(...args) {
    count = count + 1;
    if (count >= startCount) {
      return fn(...args);
    }
  };
}

// this is preffered to debouncing
// use when you only want a function called every x seconds
// would work well for detecting React Resing of window where you dont want to rerender on every pixel that changes
// let throttledProcess = throttle(process, 1000);
// $window.mouseMove(throttledProcess)
function throttle(fn, interval) {
  let lastTime;
  return function throttled(...args) {
    if (!lastTime || Date.now() - lastTime >= interval) {
      fn(...args);
      lastTime = Date.now();
    }
  };
}

// usefull for running a function only after the event has stopped arriving
// let debouncedProcess = debounce(process, 4000);
// $window.resize(debouncedProcess)
function debounce(fn, interval) {
  let timer;
  return function debounced(...args) {
    clearTimeout(timer);
    timer = setTimeout(function() {
      fn(...args);
    }, interval);
  };
}

// saves the previous result of calling a function to speed up slow computations
// const factorial = memoize(function(n){
//      console.log("n=" + n);
//      if ( n < 2 ) return 1;
//      return n * factorial(n-1);
// })
// console.log(factorial(3))
// n = 3
// n = 2
// n = 1
// console.log(factorial(5))
// n = 5
// n = 4
function memoize(fn) {
  const map = Object.create(null);
  return function(x) {
    if (!map[x]) {
      map[x] = fn(x);
    }
    return map[x];
  };
}

// used to fix problems whern the function is called with more arguments than necessarey
// const numbers = [1,2,3,4,5,6]
// numbers.map(unary(parseInt))
//
function unary(fn) {
  return function(first) {
    return fn(first);
  };
}

// much the same
// numbers.reduce(binary(Math.max))
function binary(fn) {
  return function(a, b) {
    return fn(a, b);
  };
}

// capitalize("chapter")
// returns "Chapter"
function capitalize(name) {
  if (name) {
    return name.charAt(0).toUpperCase() + name.slice(1);
  }
  return name;
}

// since we can use Object.freeze to shallow freeze an object
// we need a way to freeze and object with nested sub-objects
// deepFreeze makes plain objects immutable
function deepFreeze(object) {
  Object.keys(object).forEach(function(name) {
    const value = object[name];
    if (typeof value === "object") {
      deepFreeze(value);
    }
  });
  return Object.freeze(object);
}

function pipe(...functions) {
  return function(x) {
    return functions.reduce((value, f) => f(value), x);
  };
}

function isEqual(value, other) {
  // check to see if both items are null, if so, return true. There is no other need to check anything else
  if (value === null && other === null) return true;

  // get the value type
  var type = Object.prototype.toString.call(value);

  // if the two objects are not the same type, return false
  if (type !== Object.prototype.toString.call(other)) return false;

  // if items are not an object or array, return false
  if (["[object Array]", "[object Object]"].indexOf(type) < 0) return false;

  // compare the length of the length of the two items
  var valueLen =
    type === "[object Array]" ? value.length : Object.keys(value).length;
  var otherLen =
    type === "[object Array]" ? other.length : Object.keys(other).length;
  if (valueLen !== otherLen) {
    // we are going to make a minor exception here
    // if the previous value has a length of zero
    // and the other (which should always be the freshly loaded data)
    // has a length greater than zero, we know that data has been loaded so we really dont
    // need to compare anything any furter. This will also prevent us from always loading the data from the server twice
    if (valueLen === 0 && otherLen > 0) return true;
    return false;
  }

  // here we need another exception
  // if both are arrays and have a length of zero,
  // then we need to return false because otherwise we will never load any data
  if (
    type === "[object Array]" &&
    value.length === 0 &&
    type === "[object Array]" &&
    other.length === 0
  )
    return false;

  //compare two items
  var compare = function(item1, item2) {
    // get the object type
    var itemType = Object.prototype.toString.call(item1);

    // if an object or array, compare recursively
    if (["[object Array]", "[object Object]"].indexOf(itemType) >= 0) {
      if (!isEqual(item1, item2)) return false;
    }

    // otherwise, do a simple comparison
    else {
      // if the two items are not the same type, return false
      if (itemType != Object.prototype.toString.call(item2)) return false;

      // if its a function, convert to a string and compare
      // otherwise just compare
      if (itemType === "[object Function]") {
        if (item1.toString() !== item2.toString()) return false;
      } else {
        if (item1 !== item2) return false;
      }
    }
  };

  // compare properties
  if (type === "[object Array]") {
    for (var i = 0; i < valueLen; i++) {
      if (compare(value[i], other[i]) === false) return false;
    }
  } else {
    for (var key in value) {
      if (value.hasOwnProperty(key)) {
        if (compare(value[key], other[key]) === false) return false;
      }
    }
  }

  return true;
}

const compose = (...fns) => (...args) =>
  fns.reduceRight((res, fn) => [fn.call(null, ...res)], args)[0];

const map = curry((fn, f) => f.map(fn));

function debounceFn(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this,
      args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export {
  curry,
  partial,
  sequence,
  counter,
  once,
  after,
  throttle,
  debounce,
  memoize,
  unary,
  binary,
  capitalize,
  deepFreeze,
  pipe,
  isEqual,
  compose,
  map,
  debounceFn
};
