import React from "react";
import PropTypes from "prop-types";
import { formatDate } from "../../utils";

const RequestList = (props) => {
  const { requests } = props;

  return (
    <div className="container" style={{ padding: "5px", overflowY: "auto" }}>
      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th>Date</th>
            <th style={{ textAlign: "center" }}>Hours</th>
            <th style={{ textAlign: "center" }}>Approved</th>
            <th style={{ textAlign: "center" }}>Denied</th>
          </tr>
        </thead>
        <tbody>
          {requests.length > 0
            ? requests.map((request, index) => (
                <React.Fragment key={index}>
                  <tr key={index}>
                    <td>
                      {formatDate(request.requestStart)}-
                      {formatDate(request.requestEnd)}
                    </td>
                    <td style={{ textAlign: "center" }}>{request.hours}</td>
                    <td style={{ textAlign: "center" }}>
                      <input
                        className="form-check-input ml-1"
                        type="checkbox"
                        readOnly={true}
                        value={request.approved}
                        checked={request.approved}
                      />
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <input
                        className="form-check-input ml-1"
                        type="checkbox"
                        readOnly={true}
                        value={request.denied}
                        checked={request.denied}
                      />
                    </td>
                  </tr>
                  {props.showTooltip ? (
                    <tr>
                      <td colSpan="3">
                        {request.denied == "1" ? (
                          <div>Reason for denial: {request.denyReason}</div>
                        ) : (
                          <React.Fragment>
                            {request.approved == "1" ? (
                              <div>This request was approved</div>
                            ) : (
                              <div>This request is pending</div>
                            )}
                          </React.Fragment>
                        )}
                      </td>
                    </tr>
                  ) : null}
                </React.Fragment>
              ))
            : null}
        </tbody>
      </table>
    </div>
  );
};

RequestList.propTypes = {
  requests: PropTypes.array,
  showTooltip: PropTypes.bool
};

export default RequestList;
