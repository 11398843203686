import React, { useState, useEffect, useContext, useRef } from "react";
import { ContextConfig } from "../../App";
import { getVendorReport } from "../../api/ReportsApi";
import { usePrevious, formatDate } from "../../utils";
import { isEqual, debounce } from "../../fp";
import Spinner from "../../common/Spinner";
import HeaderRb from "../../common/HeaderRb";
import { Picker } from "c5-react-library";
import "./VendorReport.css";

const vendors = [{ f27: "h2569", f334: "Hallmark" }];

const VendorReport = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [vendor, setVendor] = useState("h2569");
  const [vendorName, setVendorName] = useState("Hallmark");
  const [isShowing, setIsShowing] = useState(false);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const context = useContext(ContextConfig);

  const previousData = usePrevious(data);
  //const previousVendors = usePrevious(vendors);

  useEffect(() => {
    console.log("useEffect for vendorReport");
    if (!isEqual(previousData, data)) {
      if (vendors.length > 0) {
        loadData();
      }
    }
    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);

    findDivHeight();

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, [data, vendor]);

  const findDivHeight = () => {
    const navbar = document
      .getElementById("master-navbar")
      .getBoundingClientRect().height;
    const controls = document
      .getElementById("r-controls")
      .getBoundingClientRect().height;
    const usedSpace = navbar + controls + 30;
    const remaining = window.innerHeight - usedSpace;

    const body = document.getElementById("r-body");
    body.style.height = remaining + "px";
    body.style.overflow = "auto";
  };

  const loadData = () => {
    setIsLoading(true);
    getVendorReport(
      context.url,
      context.token,
      context.lastStoreid,
      vendor,
      context.endDate,
      context.endDate
    )
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          setData(j.items);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const toggleShow = () => {
    setIsShowing(!isShowing);
  };

  const handleSelect = (e) => {
    setVendor(e.f27);
    setVendorName(e.f334);
  };

  return (
    <div>
      {!isLoading ? null : <Spinner />}
      <HeaderRb />
      <div className="vendorHeader" id="r-controls">
        <div className="vendorTitle">Sales by Vendor</div>
        <div className="vendorDate">Date: {formatDate(context.endDate)}</div>
        <div className="vendorName">
          Vendor: {vendor} {vendorName}
        </div>
        <div>
          {context.store.store_Number} {context.store.store_Name}
        </div>
      </div>
      <div className="vendorBody" id="r-body">
        {data.length === 0 ? (
          <div>No records were returned</div>
        ) : (
          <table style={{ width: "90%", margin: "auto" }}>
            <tr>
              <th>UPC</th>
              <th>Description</th>
              <th>Vendor</th>
              <th>Name</th>
              <th>Qty</th>
              <th>$</th>
              <th>#</th>
            </tr>
            {data.map((record, index) => (
              <tr key={index}>
                <td>{record.f01}</td>
                <td>{record.f02}</td>
                <td>{record.f27}</td>
                <td>{record.f334}</td>
                <td>{record.f64}</td>
                <td>{record.f65}</td>
                <td>{record.f67}</td>
              </tr>
            ))}
          </table>
        )}
      </div>
      <Picker
        isShowing={isShowing}
        hide={toggleShow}
        header="Stores"
        store={vendors}
        displayField="f334"
        valueField="f27"
        handleSelect={handleSelect}
        useEffect={useEffect}
        useState={useState}
        useRef={useRef}
      />
    </div>
  );
};

export default VendorReport;
