import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { isValid } from "../../utils";
import { debounce } from "../../fp";
import Moment from "moment";
import Header from "./Header";
import Employees from "./Employees";
import Footer from "./Footer";

const DetailCalendar = (props) => {
  const [weekStart, setWeekStart] = useState({});
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  useEffect(() => {
    if (isValid(props.weekStart)) {
      const a = Moment(props.weekStart, "MM/DD/YYYY");
      setWeekStart(a);
    }

    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);
    findDivHeight();
    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, [props.weekStart, props.users]);

  const findDivHeight = () => {
    const div = document.getElementById("scheduleBody");
    const controls = document.getElementById("schedule-header-wrapper");
    const tableHeader = document.getElementById("scheduleHead");
    const tableFooter = document.getElementById("scheduleFoot");
    const menu = document.getElementById("master-navbar");

    const headerComponents =
      menu.getBoundingClientRect().height +
      controls.getBoundingClientRect().height;

    const wrapper = document.getElementById("main-calendar-wrapper");

    const remainingRoom = window.innerHeight - headerComponents;

    wrapper.style.height = window.innerHeight - remainingRoom + "px";
    div.style.height =
      remainingRoom -
      20 -
      tableHeader.getBoundingClientRect().height -
      tableFooter.getBoundingClientRect().height +
      "px";

    tableFooter.style.top = remainingRoom - 20 - 60 + "px"; // adding 20 for additional possible margin
  };
  return (
    <div className="container schedule-main-table" id="main-calendar-wrapper">
      <div className="schedule-wrapper">
        <Header weekStart={weekStart} days={props.days} />

        <div id="scheduleBody" className="schedule-body">
          <Employees
            hours={props.hours}
            users={props.users}
            days={props.days}
            startDate={props.startDate}
            endDate={props.endDate}
            context={props.context}
            reloadSchedule={props.reloadSchedule}
            setReloadSchedule={props.setReloadSchedule}
            handleDateClick={props.handleDateClick}
            editShift={props.editShift}
            setStoreSchedules={props.setStoreSchedules}
            store={props.store}
          />
        </div>
        <Footer
          days={props.days}
          weekStart={weekStart}
          hours={props.hours}
          startDate={props.startDate}
          endDate={props.endDate}
          settings={props.settings}
        />
      </div>
    </div>
  );
};

DetailCalendar.propTypes = {
  days: PropTypes.array,
  weekStart: PropTypes.any,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  settings: PropTypes.object,
  users: PropTypes.array,
  context: PropTypes.object,
  reloadSchedule: PropTypes.bool,
  setReloadSchedule: PropTypes.func,
  handleDateClick: PropTypes.func,
  editShift: PropTypes.func,
  setStoreSchedules: PropTypes.func,
  store: PropTypes.any,
  hours: PropTypes.array
};

export default DetailCalendar;
