import React, { useState, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { getUserPunchesByDates, deletePunch } from "../../api/punchApi";
import { isValid, formatDate, formatTimeAmPm } from "../../utils";
import { debounce } from "../../fp";
import { toast } from "react-toastify";
import ConfirmDelete from "../../common/ConfirmDelete";
import Spinner from "../../common/Spinner";
import ReactTooltip from "react-tooltip";
import editReducer from "./editReducer";
import SortColumn from "./SortColumn";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../actions/actionTypes";

const initialTableState = {
  sortUsernameAsc: false,
  sortEmployeeIdAsc: false,
  sortTypeAsc: false,
  sortInAsc: false,
  sortOutAsc: false,
  sortTotalAsc: false,
  sortDeptAsc: false,
  sortField: null,
  sortDataType: "string"
};

const PunchList = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const state = useSelector((app) => app.editReducer);
  const appState = useSelector((app) => app.appReducer);
  const dispatch = useDispatch();

  const [tableState, tableDispatch] = useReducer(
    editReducer,
    initialTableState
  );

  useEffect(() => {
    loadPunches();
    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);

    findDivHeight();

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, [appState.reRender, props.punchUser, props.singleDay]);

  useEffect(() => {
    if (tableState.sortField === null) return;
    sortRecords(tableState.sortField);
  }, [tableState]);

  useEffect(() => {
    if (props.filteredDepartment === 0) {
      dispatch({
        type: actions.EDIT_SET_FILTERED_PUNCHES,
        punches: state.punches
      });
      return;
    }

    const newArray = [];
    state.punches.map((punch) => {
      if (parseInt(punch.departmentId) === props.filteredDepartment) {
        newArray.push(punch);
      }
    });

    dispatch({
      type: actions.EDIT_SET_FILTERED_PUNCHES,
      punches: newArray
    });
  }, [props.filteredDepartment]);

  const findDivHeight = () => {
    const navbar = document
      .getElementById("master-navbar")
      .getBoundingClientRect().height;

    const usedSpace = navbar + 390;
    const remaining = window.innerHeight - usedSpace;

    const widget = document.getElementById("punch-list");
    widget.style.height = remaining + "px";
    widget.style.overflow = "auto";
  };

  const loadPunches = () => {
    setIsLoading(true);
    // check to see if a particular user has been specified or not
    // we may need to show all punches from a particular store
    // or if this is a manager, then maybe just a user
    // and if this is just a user, then just show their punches

    let userid = props.context.userid;

    if (props.context.userLevel >= 5) {
      userid = 0;
      if (isValid(props.context.punch)) {
        if (isValid(props.context.punch.selectedUser)) {
          userid = props.context.punch.selectedUser;
        }
      }
    }
    let endingDate = props.context.endDate;
    if (props.singleDay) {
      endingDate = props.context.singleDay;
    }

    getUserPunchesByDates(
      props.context.clockUrl,
      props.context.clockToken,
      userid,
      props.context.startDate,
      endingDate,
      props.context.lastClockStore,
      props.singleDay
    )
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          if (j.punches) {
            if (j.punches.length > 0) {
              dispatch({ type: actions.EDIT_SET_PUNCHES, punches: j.punches });
              dispatch({
                type: actions.EDIT_SET_FILTERED_PUNCHES,
                punches: j.punches
              });
              props.getDepartments(j.punches);
            } else {
              dispatch({ type: actions.EDIT_SET_PUNCHES, punches: [] });
              dispatch({
                type: actions.EDIT_SET_FILTERED_PUNCHES,
                punches: []
              });
            }
          } else {
            dispatch({ type: actions.EDIT_SET_PUNCHES, punches: [] });
            dispatch({ type: actions.EDIT_SET_FILTERED_PUNCHES, punches: [] });
          }
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
          dispatch({ type: actions.EDIT_SET_PUNCHES, punches: [] });
          dispatch({ type: actions.EDIT_SET_FILTERED_PUNCHES, punches: [] });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        dispatch({ type: actions.EDIT_SET_PUNCHES, punches: [] });
        dispatch({ type: actions.EDIT_SET_FILTERED_PUNCHES, punches: [] });
        console.log(err);
        toast.error("An error occured fetching your punches", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const handleDelete = (punch) => {
    dispatch({ type: actions.EDIT_SET_DEL_PUNCH, punch: punch });
    dispatch({ type: actions.EDIT_SET_SHOW_DELETE, showDelete: true });
  };

  const hide = () => {
    dispatch({ type: actions.EDIT_SET_SHOW_DELETE, showDelete: false });
  };

  const totalPunches = () => {
    let total = 0.0;
    total = state.punches.reduce((accum, curr) => {
      return accum + curr.total;
    }, 0.0);
    if (
      typeof props.context.settings === "undefined" ||
      props.context.settings === null
    ) {
      debugger;
    }
    return total.toFixed(props.context.settings.timePrecision);
  };

  const compareDeleteText = () => {
    if (state.confirmText === "delete me") {
      dispatch({ type: actions.EDIT_SET_SHOW_DELETE, showDelete: false });
      deletePunch(
        props.context.clockUrl,
        props.context.clockToken,
        state.delPunch.id
      )
        .then((response) => {
          const j = response.data;
          if (j.error === 0) {
            dispatch({ type: actions.APP_SET_RERENDER });
          } else {
            toast.error(j.msg, {
              position: toast.POSITION.TOP_LEFT
            });
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error("An error occured deleting your punch", {
            position: toast.POSITION.TOP_LEFT
          });
        });
    } else {
      dispatch({
        type: actions.EDIT_SET_ERROR,
        error: state.confirmText + " does not match delete me"
      });
    }
  };

  const handleConfirmTextChange = (e) => {
    dispatch({
      type: actions.EDIT_SET_CONFIRM_TEXT,
      confirmText: e.target.value
    });
  };

  const getSortField = () => {
    switch (tableState.sortField) {
      case "username":
        return tableState.sortUsernameAsc;
      case "employeeid":
        return tableState.sortEmployeeIdAsc;
      case "punchType":
        return tableState.sortTypeAsc;
      case "punchIn":
        return tableState.sortInAsc;
      case "punchOut":
        return tableState.sortOutAsc;
      case "rawPunchIn":
        return tableState.sortInAsc;
      case "rawPunchOut":
        return tableState.sortOutAsc;
      case "total":
        return tableState.sortTotalAsc;
      case "departmentDescription":
        return tableState.sortDeptAsc;
      default:
        return null;
    }
  };

  const compare = (a, b) => {
    let fieldA = a[tableState.sortField];
    let fieldB = b[tableState.sortField];

    if (tableState.sortDataType !== "number") {
      fieldA = fieldA.toUpperCase();
      fieldB = fieldB.toUpperCase();
    } else {
      fieldA = parseFloat(a[tableState.sortField]);
      fieldB = parseFloat(b[tableState.sortField]);
    }

    let comparison = 0;
    if (fieldA > fieldB) {
      comparison = 1;
    } else if (fieldA < fieldB) {
      comparison = -1;
    }

    if (getSortField()) {
      return comparison * -1;
    } else {
      return comparison;
    }
  };

  const sortRecords = () => {
    setIsLoading(true);
    const filteredPunches = [...state.punches].sort(compare);
    dispatch({
      type: actions.EDIT_SET_FILTERED_PUNCHES,
      punches: filteredPunches
    });
    setIsLoading(false);
  };

  // const getTime = (input) => {
  //   let time = formatTimeAmPm(input);
  //   if ( props.context.settings.useMilitaryTime){
  //     time = formatTimeMilitary(input);
  //   }

  //   return time;
  // }

  return (
    <div className="d-edits-punchlist" id="punch-list">
      <ConfirmDelete
        isShowing={state.showDelete}
        hide={hide}
        confirmText={state.confirmText}
        setConfirmText={handleConfirmTextChange}
        error={state.error}
        compareDeleteText={compareDeleteText}
      />
      {isLoading && <Spinner />}

      <div className="d-edits-punchlist-header">Punches</div>
      {!props.isEditable ? (
        <div className="period-closed">
          This period has already been closed. You cannot make any further
          edits.
        </div>
      ) : null}
      {props.isLocked ? (
        <div className="period-closed">
          You are not allowed to edit your own time
        </div>
      ) : null}
      {state.filteredPunches.length > 0 ? (
        <React.Fragment>
          <ReactTooltip />
          <table className="table table-striped">
            <thead>
              <tr>
                {props.isEditable ? <th>Edit</th> : null}
                <th>&nbsp;</th>
                <th>
                  <SortColumn
                    state={state}
                    dispatch={tableDispatch}
                    tableDispatch={tableDispatch}
                    title="Username"
                    dispatchType={"sortUsername"}
                    sortField={"sortUsernameAsc"}
                    dbField={"username"}
                  />
                </th>
                <th>
                  <SortColumn
                    state={state}
                    dispatch={tableDispatch}
                    tableDispatch={tableDispatch}
                    title="Emp Id"
                    dispatchType={"sortEmployeeid"}
                    sortField={"sortEmployeeIdAsc"}
                    dbField={"employeeid"}
                  />
                </th>
                <th>
                  <SortColumn
                    state={state}
                    dispatch={tableDispatch}
                    tableDispatch={tableDispatch}
                    title="Type"
                    dispatchType={"sortType"}
                    sortField={"sortTypeAsc"}
                    dbField={"punchType"}
                  />
                </th>
                <th>
                  <SortColumn
                    state={state}
                    dispatch={tableDispatch}
                    tableDispatch={tableDispatch}
                    title="In"
                    dispatchType={"sortIn"}
                    sortField={"sortInAsc"}
                    dbField={
                      props.context.settings.showRawPunchesOnEditScreen
                        ? "rawPunchIn"
                        : "punchIn"
                    }
                  />
                </th>
                <th>
                  <SortColumn
                    state={state}
                    dispatch={tableDispatch}
                    tableDispatch={tableDispatch}
                    title="Out"
                    dispatchType={"sortOut"}
                    sortField={"sortOutAsc"}
                    dbField={
                      props.context.settings.showRawPunchesOnEditScreen
                        ? "rawPunchOut"
                        : "punchOut"
                    }
                  />
                </th>
                <th style={{ textAlign: "center" }}>
                  <SortColumn
                    state={state}
                    dispatch={tableDispatch}
                    tableDispatch={tableDispatch}
                    title="Department"
                    dispatchType={"sortDept"}
                    sortField={"sortDeptAsc"}
                    dbField={"departmentDescription"}
                  />
                </th>
                <th>Comment</th>
                <th style={{ textAlign: "center" }}>
                  <SortColumn
                    state={state}
                    dispatch={tableDispatch}
                    tableDispatch={tableDispatch}
                    title="Total"
                    dispatchType={"sortTotal"}
                    sortField={"sortTotalAsc"}
                    dbField={"total"}
                  />
                </th>
                {props.isEditable ? <th>Delete</th> : null}
              </tr>
            </thead>
            <tbody>
              {state.filteredPunches.map((punch, index) => (
                <tr key={`d-edits-${index}`}>
                  {props.isEditable ? (
                    <td
                      className="d-edit-hover"
                      onClick={() =>
                        dispatch({
                          type: actions.EDIT_SET_EXISTING_PUNCH,
                          existingPunch: punch
                        })
                      }
                    >
                      <i className="far fa-edit"></i>
                    </td>
                  ) : null}
                  <td>
                    {punch.edited ? (
                      <span
                        data-tip={`<p>Edit User: ${punch.editUser}</p><p>Edit Date: ${punch.editDate}</p><p>Changes: ${punch.editType}</p>`}
                        data-html={true}
                      >
                        <i className="fas fa-stethoscope"></i>
                      </span>
                    ) : null}
                  </td>
                  <td>{punch.username}</td>
                  <td>{punch.employeeNumber}</td>
                  <td>{punch.punchType}</td>
                  <td>
                    {props.context.settings.showRawPunchesOnEditScreen
                      ? formatDate(punch.rawPunchIn) +
                        " " +
                        formatTimeAmPm(punch.rawPunchIn)
                      : punch.punchIn}
                  </td>
                  <td>
                    {props.context.settings.showRawPunchesOnEditScreen
                      ? formatDate(punch.rawPunchOut) +
                        " " +
                        formatTimeAmPm(punch.rawPunchOut)
                      : punch.punchOut}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {punch.departmentDescription}
                  </td>
                  <td>
                    <div
                      className="d-edits-punch-comment"
                      data-tip={punch.comment}
                    >
                      {punch.comment}
                    </div>
                  </td>
                  <td style={{ textAlign: "center" }}>{punch.total}</td>
                  {props.isEditable ? (
                    <td
                      className="d-edit-hover"
                      onClick={() => handleDelete(punch)}
                    >
                      <i className="far fa-minus-octagon"></i>
                    </td>
                  ) : null}
                </tr>
              ))}
              <tr>
                <td
                  className="d-edits-total"
                  colSpan="9"
                  style={{ textAlign: "right" }}
                >
                  Total
                </td>
                <td className="d-edits-total">{totalPunches()}</td>
                <td>&nbsp;</td>
              </tr>
            </tbody>
          </table>
        </React.Fragment>
      ) : (
        <div className="d-edits-emptypunches">
          There were no punches returned
        </div>
      )}
    </div>
  );
};

PunchList.propTypes = {
  context: PropTypes.object,
  reRender: PropTypes.bool,
  setReRender: PropTypes.func,
  setExistingPunch: PropTypes.func,
  settings: PropTypes.object,
  punchUser: PropTypes.any,
  isEditable: PropTypes.bool,
  isLocked: PropTypes.bool,
  singleDay: PropTypes.bool,
  getDepartments: PropTypes.func,
  filteredDepartment: PropTypes.number
};

export default PunchList;
