import React, { useState, useEffect, useContext } from "react";
import { ContextConfig } from "../App";
import { isEqual } from "../fp";
import { usePrevious } from "../utils";
import { readMenu } from "../Clock/Clock-I";
import { isMobile } from "react-device-detect";
import { NavLink } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import "./FloatingSidebar.css";

const FloatingSidebar = () => {
  const [menu, setMenu] = useState([]);
  const activeStyle = { color: "#F15B2A" };
  const [mini, setMini] = useState(true);
  const [menuClass, setMenuClass] = useState(
    "floating-sidebar floating-sidebar-mini"
  );

  const context = useContext(ContextConfig);

  const previousMenu = usePrevious(menu);

  useEffect(() => {
    if (!isEqual(previousMenu, menu)) {
      loadMenuItems();
    }
  }, [menu]);

  const loadMenuItems = () => {
    readMenu(context.clockUrl, context.clockToken, isMobile).then(
      (response) => {
        const j = response.data;
        if (j.error === 0) {
          // here we can filter out our floating sidebar menu to keep it short
          const newArray = j.menu.filter(
            (m) =>
              ![
                "Clock",
                "Schedule",
                "Eod",
                "Company",
                "Clock In/Out",
                "Reqeust",
                "Edits",
                "Roles"
              ].includes(m.description)
          );
          setMenu(newArray);
        }
      }
    );
  };

  const handleToggleMini = () => {
    if (mini === true) {
      setMenuClass("floating-sidebar floating-sidebar-full");
    } else {
      setMenuClass("floating-sidebar floating-sidebar-mini");
    }
    setMini(!mini);
  };

  return (
    <div className="floating-sidebar-wrapper">
      <div className="floating-sidebar-header" onClick={handleToggleMini}>
        <i className="fas fa-bars"></i>
      </div>

      <div className={menuClass}>
        <div className="floating-sidebar-icon">
          <img
            src="img/homepage-icon.svg"
            style={{ width: "50px" }}
            alt="MikTo"
          />
        </div>

        <div className="floating-sidebar-line">
          <hr className="fs-hl" />
        </div>
        {menu.length > 0
          ? menu.map((item, index) => (
              <React.Fragment key={`fs-mi-${index}`}>
                <ReactTooltip />
                {mini === true ? (
                  <div
                    className="fs-mi"
                    data-toggle="tooltip"
                    data-placement="top"
                    title={item.description}
                    data-tip={item.description}
                  >
                    <NavLink
                      to={item.route}
                      className="menu-item-mini"
                      activeStyle={activeStyle}
                    >
                      <i className={item.iconCls}></i>
                    </NavLink>
                  </div>
                ) : (
                  <div className="fs-full">
                    <NavLink
                      to={item.route}
                      className="menu-item-full"
                      activeStyle={activeStyle}
                    >
                      <span className="fs-mn-icon">
                        <i className={item.iconCls}></i>
                      </span>
                      <span className="fs-mn-text">{item.description}</span>
                    </NavLink>
                  </div>
                )}
              </React.Fragment>
            ))
          : null}
      </div>
    </div>
  );
};

export default FloatingSidebar;
