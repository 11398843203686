import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { ContextConfig } from "../App";
import { getCoupons, getCouponPrediction } from "../api/predictApi";
import {
  usePrevious,
  isValid,
  addDays,
  formatDate,
  getCurrentState
} from "../utils";
import { Row, Col } from "reactstrap";
import DatePicker from "react-datepicker";
import { readGroupAsync } from "../api/GroupApi";
import { getAssignedStoresAsync } from "../api/StoreApi";
import "../Header/react-datepicker.css";
import { savePrefs } from "../api/GlobalApi";
import ReactTooltip from "react-tooltip";
import { debounce } from "../fp";
import TypeAheadDropDown from "../common/TypeAheadDropDown";
import Tooltip from "../Tooltip/Tooltip";
import BouncyLoader from "../common/loaders/BouncyLoader";

const pickerData = [
  { id: 1, name: "Stores" },
  { id: 2, name: "Group" },
  { id: 3, name: "Single Store" }
];

const Coupon = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [coupons, setCoupons] = useState([]);
  // eslint-disable-next-line
  const [searchType, setSearchType] = useState(1);
  const [itemSize, setItemSize] = useState(5);
  const [upc, setUpc] = useState("");
  const [couponPredictions, setCouponPredictaions] = useState([]);
  const [reRender, setReRender] = useState(false);
  const [startDate, setStartDate] = useState(addDays(new Date(), -7));
  const [endDate, setEndDate] = useState(new Date());
  const [showGroups, setShowGroups] = useState(false);
  // eslint-disable-next-line
  const [group, setGroup] = useState([]);
  const [groups, setGroups] = useState([]);
  const [assignedStores, setAssignedStores] = useState([]);
  // eslint-disable-next-line
  const [showSelection, setShowSelection] = useState(false);
  // const [search, setSearch] = useState("");
  // const [filteredSearch, setFilteredSearch] = useState([]);
  const [cpnsearch, setCpnsearch] = useState("");
  const [filteredCoupons, setFilteredCoupons] = useState([]);

  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const context = useContext(ContextConfig);

  // const previousStores = usePrevious(stores);
  //const previousCoupons = usePrevious(coupons);
  const previousUpc = usePrevious(upc);

  // eslint-disable-next-line
  let lastSearchType = context.lastSearchType;

  useEffect(() => {
    getCurrentState(
      context,
      setShowGroups,
      setShowSelection,
      setStartDate,
      setEndDate
    );
    savePrefs(
      context.url,
      context.token,
      context.lastStoreid,
      context.lastGroup,
      context.lastSearchType,
      context.lastGroupName,
      prefsSuccess,
      prefsFailure
    );

    fetchCoupons();

    if (upc != previousUpc && isValid(upc) && upc.length > 0) {
      fetchCouponPrediction();
    }

    if (showGroups) {
      fetchGroups();
    }

    if (context.lastSearchType == 3) {
      fetchStores();
    }

    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);

    findDivHeight();

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, [itemSize, reRender]);

  useEffect(() => {
    fetchCouponPrediction();
  }, [itemSize, upc]);

  useEffect(() => {}, [couponPredictions]);

  const findDivHeight = () => {
    const navbar = document
      .getElementById("master-navbar")
      .getBoundingClientRect().height;
    const controls = document
      .getElementById("coupon-controls")
      .getBoundingClientRect().height;
    const usedSpace = navbar + controls + 100;
    const remaining = window.innerHeight - usedSpace;

    const body = document.getElementById("coupon-body");
    body.style.height = remaining + "px";
    body.style.overflowY = "auto";
  };

  const prefsSuccess = () => {};

  const prefsFailure = (err) => {
    console.log(err);
  };

  const fetchCoupons = () => {
    setIsLoading(true);
    let useGroups = 0;
    let singleStore = 0;
    if (context.lastSearchType == 2) useGroups = 1;
    if (context.lastSearchType == 3) singleStore = 1;
    getCoupons(
      context.url,
      context.token,
      formatDate(startDate),
      formatDate(endDate),
      useGroups,
      context.lastSearchType == 2 ? context.lastGroup : context.lastStoreid,
      singleStore
    )
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          if (j.coupons.length > 0) {
            setCoupons(j.coupons);
            setFilteredCoupons(j.coupons);
          } else {
            setCoupons([]);
            setFilteredCoupons([]);
          }
        } else {
          setCoupons([]);
          setFilteredCoupons([]);
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setCoupons([]);
        toast.error("An error occured fetching a list of coupons", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const fetchCouponPrediction = () => {
    setIsLoading(true);
    let useGroups = 0;
    let singleStore = 0;
    if (context.lastSearchType == 2) useGroups = 1;
    if (context.lastSearchType == 3) singleStore = 1;
    getCouponPrediction(
      context.url,
      context.token,
      upc,
      itemSize,
      formatDate(startDate),
      formatDate(endDate),
      useGroups,
      context.lastSearchType == 2 ? context.lastGroup : context.lastStoreid,
      singleStore
    )
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          setCouponPredictaions(j.items);
          //filterPredictedCoupons(j.items);
        } else {
          if (j.error !== 4) {
            setCouponPredictaions([]);
            toast.error(j.msg, {
              position: toast.POSITION.TOP_LEFT
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setCouponPredictaions([]);
        toast.error("An error occured getting the coupon results", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const fetchGroups = () => {
    setIsLoading(true);
    readGroupAsync(context.url, context.token)
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          setGroups(j.groups);
        } else {
          setGroups([]);
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        toast.error("An error occured fetching your groups", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const fetchStores = () => {
    setIsLoading(true);
    getAssignedStoresAsync(context.url, context.token)
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          setAssignedStores(j.items);
        } else {
          setAssignedStores([]);
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setAssignedStores([]);
        console.log(err);
        toast.error("An error occured getting your assigned stores", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const handleItemSizeChange = (e) => {
    setItemSize(e.target.value);
  };

  const handleUpcChange = (e) => {
    setUpc(e.target.value);
  };

  const handleStartDateChange = (e) => {
    context.startDate = e;
    context.endDate = addDays(e, 7);
    setStartDate(context.startDate);
    setEndDate(context.endDate);
    setReRender(!reRender);
  };

  const handleEndDateChange = (e) => {
    context.endDate = e;
    context.startDate = addDays(e, -7);
    setStartDate(context.startDate);
    setEndDate(context.endDate);
    setReRender(!reRender);
  };

  const handleSelectionChange = (e) => {
    if (parseInt(e.target.value) === 2) {
      context.lastSearchType = 2;
      setSearchType(2);
      setShowGroups(true);
      fetchGroups();
    } else if (parseInt(e.target.value) === 3) {
      context.lastSearchType = 3;
      setSearchType(3);
      setShowGroups(true);
      lastSearchType = 3;
      fetchStores();
    } else {
      context.lastSearchType = 1;
      setShowGroups(false);
    }
    setReRender(!reRender);
  };

  const handleGroupChange = (e) => {
    setGroup(e.target.value);
    context.lastSearchType = 2;
    context.lastGroup = e.target.value;
    setReRender(!reRender);
  };

  const handleStoreChange = (e) => {
    context.lastStoreid = e.storeid;
    context.lastSearchType = 3;
    setReRender(!reRender);
  };

  const handleCpnSearchChange = (e) => {
    setCpnsearch(e.target.value);
    const newArray = [];
    coupons.map((record) => {
      if (
        record.description.toLowerCase().includes(e.target.value.toLowerCase())
      ) {
        newArray.push(record);
      }
    });
    if (newArray.length > 0) {
      setFilteredCoupons(newArray);
    }
  };

  // const getTooltipData = (record) => {
  //   setIsLoading(true);
  //   let useGroups = 0;
  //   let singleStore = 0;
  //   if (context.lastSearchType == 2) useGroups = 1;
  //   if (context.lastSearchType == 3) singleStore = 1;
  //   getCpnQty(
  //     context.url,
  //     context.token,
  //     record,
  //     formatDate(context.startDate),
  //     formatDate(context.endDate),
  //     useGroups,
  //     context.lastSearchType == 2 ? context.lastGroup : context.lastStoreid,
  //     singleStore
  //   )
  //     .then((response) => {
  //       setIsLoading(false);
  //       const j = response.data;
  //       if (j.error === 0) {
  //         return `<Tooltip message={<div>Qty: ${j.f64}</div><div>${j.f65}</div>} position={'right'}></Tooltip>`;
  //       } else {
  //         toast.error(j.msg, {
  //           position: toast.POSITION.TOP_LEFT
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setIsLoading(false);
  //       toast.error("An error occured getting the tooltip data", {
  //         position: toast.POSITION.TOP_LEFT
  //       });
  //     });
  // };

  return (
    <div className="container">
      {isLoading && <BouncyLoader label={"Loading..."} />}
      <div id="coupon-controls">
        <Row
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <Col xs="4">
            <span style={{ marginRight: "10px" }}>Start:</span>
            <DatePicker selected={startDate} onChange={handleStartDateChange} />
          </Col>
          <Col xs="4">
            <span style={{ marginRight: "10px" }}>End:</span>
            <DatePicker selected={endDate} onChange={handleEndDateChange} />
          </Col>
        </Row>
        <Row
          style={{
            marginTop: "15px",
            display: "flex",
            justifyContent: "center"
          }}
        >
          <Col xs="4">
            <select
              className="form-control"
              id="comboSelectionType"
              value={context.lastSearchType}
              onChange={handleSelectionChange}
            >
              {pickerData.map((record) => (
                <option key={record.id} value={record.id}>
                  {record.name}
                </option>
              ))}
            </select>
          </Col>

          {context.lastSearchType == 2 ? (
            <Col xs="6">
              <Row>
                <Col xs="2">
                  <span
                    style={{
                      display: "flex",
                      marginRight: "10px",
                      marginTop: "10px"
                    }}
                  >
                    Group:
                  </span>
                </Col>
                <Col xs="10">
                  <select
                    id="comboGroup"
                    className="form-control"
                    onChange={handleGroupChange}
                    value={context.lastGroup}
                  >
                    {groups.length === 0
                      ? null
                      : groups.map((group) => (
                          <option key={group.id} value={group.id}>
                            {group.group_name}
                          </option>
                        ))}
                  </select>
                </Col>
              </Row>
            </Col>
          ) : null}

          {context.lastSearchType == 3 ? (
            <Col xs="6">
              <Row>
                <Col xs="12">
                  <TypeAheadDropDown
                    data={assignedStores}
                    keyValue={"storeid"}
                    displayValue={"store_Name"}
                    value={parseInt(context.lastStoreid)}
                    setSelection={handleStoreChange}
                    label="Store"
                  />
                </Col>
              </Row>
            </Col>
          ) : null}
          <Col xs="2">
            <Tooltip
              message={
                "Set the number of items you would like to have returned"
              }
              position={"top"}
            >
              <input
                className="form-control"
                type="number"
                value={itemSize}
                onChange={handleItemSizeChange}
                placeholder="set result length"
              />
            </Tooltip>
          </Col>
        </Row>
        <div className="coupon-list">
          <Row
            style={{
              display: "flex",
              justifyContent: "center"
            }}
          >
            {filteredCoupons.length > 0 ? (
              <React.Fragment>
                <Col xs="6">
                  <label>Select a Coupon</label>
                  <select
                    className="form-control"
                    value={upc}
                    onChange={handleUpcChange}
                  >
                    <option value="0">Select a Coupon</option>
                    {filteredCoupons.map((record, index) => (
                      <option key={`c-${index}`} value={record.upc}>
                        {record.upc} {record.description}
                      </option>
                    ))}
                  </select>
                </Col>
                <Col xs="6">
                  <label>Enter text to refine the Coupon List</label>
                  <input
                    type="text"
                    className="form-control"
                    value={cpnsearch}
                    onChange={handleCpnSearchChange}
                  />
                </Col>
              </React.Fragment>
            ) : (
              <div className="empty-coupons" style={{ minHeight: "250px" }}>
                There are no Coupons to Display
              </div>
            )}
          </Row>
        </div>
      </div>
      <div id="coupon-body">
        <div id="coupon-predictions" className="coupon-predictions">
          {couponPredictions.length > 0 ? (
            <React.Fragment>
              <ReactTooltip />
              <p style={{ width: "100%", textAlign: "center" }}>
                These are the results returned for UPC: {upc}.
              </p>
              <table className="table table-striped coupon-table">
                <thead>
                  <tr>
                    <th style={{ width: "20%" }}>UPC</th>
                    <th className="truncate" style={{ width: "50%" }}>
                      Description
                    </th>
                    <th style={{ textAlign: "center", width: "10%" }}>
                      Order Count
                    </th>
                    <th style={{ textAlign: "center", width: "10%" }}>
                      Cpn Count
                    </th>
                    <th style={{ textAlign: "center", width: "10%" }}>
                      Item Count
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {couponPredictions.map((record, index) => {
                    if (record.upc == upc) {
                      return (
                        <tr key={`cr-${index}`}>
                          <td>{record.product_id1}</td>
                          <td
                            className="truncate"
                            data-tip={record.description1}
                          >
                            {record.description1}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {record.orderCount}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {record.itemCount2}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {record.itemCount1}
                          </td>
                        </tr>
                      );
                    } else {
                      return (
                        <tr key={`cr-${index}`}>
                          <td>{record.upc}</td>
                          <td
                            className="truncate"
                            data-tip={record.description}
                          >
                            {record.description}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {record.orderCount}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {record.orderCount}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {record.itemCount1}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {record.itemCount2}
                          </td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </table>
            </React.Fragment>
          ) : (
            <div className="empty-coupon-predictions">
              There are no results to display
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Coupon;
