import React, { useEffect, useState, useContext } from "react";
import { getTicker } from "../../../api/securityApi";
import { ContextConfig } from "../../../App";
import { toast } from "react-toastify";
import Ticker from "react-ticker";
import { formatDate } from "../../../utils";

const TickerWidgetFetcher = () => {
  const [data, setData] = useState([]);
  const [tickerData, setTickerData] = useState(null);
  const context = useContext(ContextConfig);

  useEffect(() => {
    console.log("loading refund ticker");
    fetchTicker();
  }, []);

  useEffect(() => {
    strigifyData();
  }, [data]);

  const fetchTicker = () => {
    getTicker(context.url, context.token)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          setData(j.ticker);
        } else {
          toast.warning(j.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const strigifyData = () => {
    let output = "";
    data.map((record) => {
      output +=
        "Date: " +
        formatDate(record.post_Date) +
        "--" +
        record.store_Name +
        "--" +
        record.cashier +
        "--$" +
        parseFloat(record.f65).toFixed(2) +
        "--, Qty: " +
        parseInt(record.f64) +
        "********************";
    });

    setTickerData(output);
  };

  return tickerData ? (
    <p
      style={{
        whiteSpace: "nowrap",
        fontSize: "1.4rem",
        fontWeight: "bold",
        color: "limegreen"
      }}
    >
      {tickerData}
    </p>
  ) : (
    <p style={{ visibility: "hidden" }}>Loading Ticker Data, Please wait...</p>
  );
};

const RefundTicker = () => {
  return (
    <Ticker offset="run-in" speed={10}>
      {() => <TickerWidgetFetcher />}
    </Ticker>
  );
};

export default RefundTicker;
