import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { addDays, truncateString, isValid } from "../utils";
import { useTrail, animated } from "react-spring";
import DatePicker from "react-datepicker";
import { ContextConfig } from "../App";
import { getAssignedStoresAsync } from "../api/StoreApi";
import { toast } from "react-toastify";
import "./DesktopReports.css";
import { savePrefs } from "../api/GlobalApi";
import { debounce } from "../fp";
import UnitsSold from "./desktop_components/UnitsSold";

const DesktopReports = ({ reports, handleClick }) => {
  const [isLoading, setIsLoading] = useState(false); // eslint-disable-line
  const [startDate, setStartDate] = useState(addDays(new Date(), -7));
  const [endDate, setEndDate] = useState(new Date());
  const [stores, setStores] = useState([]);
  const [storeid, setStoreid] = useState("");
  const [showUnitsSold, setShowUnitsSold] = useState(false);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const context = useContext(ContextConfig);

  useEffect(() => {
    if (!isValid(storeid) || storeid.length === 0) {
      setStoreid(context.lastStoreid);
    }

    if (isValid(context.startDate)) {
      setStartDate(context.startDate);
    }

    if (isValid(context.endDate)) {
      setEndDate(context.endDate);
    }
    fetchStores();
    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);

    findDivHeight();

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, [reports, storeid]);

  const findDivHeight = () => {
    const navbar = document
      .getElementById("master-navbar")
      .getBoundingClientRect().height;
    const controls = document
      .getElementById("r-controls")
      .getBoundingClientRect().height;
    const usedSpace = navbar + controls + 20;
    const remaining = window.innerHeight - usedSpace;

    const body = document.getElementById("r-body");
    body.style.height = remaining + "px";
    body.style.overflow = "auto";
  };

  const trail = useTrail(reports.length, {
    from: {
      marginLeft: -20,
      opacity: 0,
      transform: "translate3d(0, -40px, 0)"
    },
    to: { marginLeft: 20, opacity: 1, transform: "translate3d(0, 0px, 0)" }
  });

  const handleSelectionChange = (e) => {
    context.lastStoreid = e.target.value;
    setStoreid(e.target.value);
    savePrefs(
      context.url,
      context.token,
      context.lastStoreid,
      context.lastGroup,
      context.lastSearchType,
      context.lastGroupName,
      prefsSuccess,
      prefsFailure
    );
  };

  const prefsSuccess = (response) => {
    console.log(response);
  };

  const prefsFailure = (err) => {
    console.log(err);
  };

  const handleStartDateChange = (e) => {
    context.startDate = e;
    setStartDate(context.startDate);
  };

  const handleEndDateChange = (e) => {
    context.endDate = e;
    setEndDate(context.endDate);
  };

  const fetchStores = () => {
    getAssignedStoresAsync(context.url, context.token).then((response) => {
      const j = response.data;
      if (j.error === 0) {
        setStores(j.items);
      } else {
        toast.error(j.msg, {
          position: toast.POSITION.TOP_LEFT
        });
      }
    });
  };

  const selectReport = (e) => {
    if (e.reportName === "UnitsSold") {
      setShowUnitsSold(true);
      return;
    }
    const selectedIndex = document.getElementById("combodSelectionType")
      .selectedIndex;
    const selectedStore = stores[selectedIndex];
    context.reportStore = {
      name: selectedStore.store_Name,
      number: selectedStore.store_Number
    };
    handleClick(e);
  };

  const hide = () => {
    setShowUnitsSold(false);
  };

  return (
    <div className="container">
      {showUnitsSold ? (
        <UnitsSold isShowing={showUnitsSold} hide={hide} />
      ) : null}
      <div id="r-controls">
        <h3 style={{ margin: "auto", width: "90%" }}>Reports</h3>
        <form className="form-inline" style={{ margin: "auto" }}>
          <div className="form-group row w-100">
            <div className="col-xs-12 col-sm-6 col-lg-3 m-auto">
              Selection Type:{" "}
              <select
                className="form-control"
                id="combodSelectionType"
                value={storeid}
                onChange={handleSelectionChange}
              >
                {stores.map((record, index) => (
                  <option key={index} value={record.storeid}>
                    {truncateString(record.store_Name, 40)}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-xs-12 col-sm-6 col-lg-3 mt-1">
              Start:&nbsp;
              <DatePicker
                selected={startDate}
                onChange={handleStartDateChange}
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-lg-3 mt-1">
              End:&nbsp;
              <DatePicker selected={endDate} onChange={handleEndDateChange} />
            </div>
          </div>
        </form>
        <div className="d-report-spacer">&nbsp;</div>
      </div>
      <div id="r-body">
        <React.Fragment>
          {reports.length === 0 ? (
            <div className="d-empty">No records were returned</div>
          ) : (
            <div className="d-reportsList">
              {trail.map((props, index) => {
                return (
                  <animated.div
                    key={index}
                    style={props}
                    className="d-report"
                    onClick={() => selectReport(reports[index])}
                  >
                    {reports[index].report_Name}
                  </animated.div>
                );
              })}
            </div>
          )}
        </React.Fragment>
      </div>
    </div>
  );
};

DesktopReports.propTypes = {
  reports: PropTypes.array,
  handleClick: PropTypes.func,
  reRender: PropTypes.bool,
  setRerender: PropTypes.func
};

export default DesktopReports;
