import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import PropTypes, { string } from "prop-types";
import "./MultiSelect.css";

const MultiSelectPortal = ({
  data,
  keyDescriptor,
  optionValue,
  optionText,
  open,
  handleCheck,
  handleSearchChange,
  search,
  amIChecked,
  inputId
}) =>
  open
    ? ReactDOM.createPortal(
        <div className="multi-select-checkboxes" id="multi-select-checkboxes">
          <input
            className="form-control"
            placeholder="search"
            type="text"
            onChange={handleSearchChange}
            value={search}
            id={inputId}
          />
          {data && data.length > 0 ? (
            <React.Fragment>
              {data.map((d, i) => (
                <label key={`${keyDescriptor}-${i}`} htmlFor={d[optionValue]}>
                  <input
                    type="checkbox"
                    checked={amIChecked(d)}
                    onChange={(e) => handleCheck(e, d)}
                  />
                  {d[optionText]}
                </label>
              ))}
            </React.Fragment>
          ) : null}
        </div>,
        document.getElementById("ms-portal-container")
      )
    : null;

const MultiSelect = ({
  data,
  defaultOption,
  keyDescriptor,
  optionValue,
  optionText,
  checkedItems,
  setCheckedItems,
  id = "multi-select-checkboxes",
  selectBoxId = "select-box-id",
  backgroundColor = "#1f1e2e",
  selectId = "select-id",
  inputId = "multi-select-search"
}) => {
  const [show, setShow] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const handleCheck = (e, item) => {
    if (e.target.checked) {
      if (!checkedItems.includes(item[optionValue])) {
        setCheckedItems([...checkedItems, item[optionValue]]);
      }
    } else {
      // we need to remove this from our state
      const newArray = checkedItems.filter((i) => i != item[optionValue]);
      setCheckedItems(newArray);
    }
  };

  const tapOutside = (e) => {
    // determine if the moust click is outside of our portal
    const portal = document.getElementById(id);
    if (portal) {
      const rect = portal.getBoundingClientRect();

      if (e.clientX < rect.left || e.clientX > rect.right) {
        setShow(false);
        const actualSelect = document.getElementById(selectId);
        const option = new Option(defaultOption, "0");
        actualSelect.prepend(option);
        window.removeEventListener("click", tapOutside);
        return;
      }
      if (e.clientY < rect.top || e.clientY > rect.bottom) {
        setShow(false);
        const actualSelect = document.getElementById(selectId);
        const option = new Option(defaultOption, "0");
        actualSelect.prepend(option);
        window.removeEventListener("click", tapOutside);
        return;
      }
    } else {
      // just in case something happens and the portal cannot be found
      // then we need to cleanup the event listener just in case it orphaned
      window.removeEventListener("click", tapOutside);
    }
  };

  const amIChecked = (i) => {
    if (checkedItems.includes(i[optionValue])) {
      return true;
    } else {
      return false;
    }
  };

  const showCheckboxes = () => {
    if (show) {
      setShow(false);
      return;
    } else {
      setShow(true);
    }
    const actualSelect = document.getElementById(selectId);
    actualSelect.options.remove(0);

    setTimeout(() => {
      const select = document.getElementById(selectBoxId);
      const portal = document.getElementById(id);
      portal.style.top =
        portal.style.top + select.getBoundingClientRect().height + "px";
      portal.style.left = 12 + "px";
      // portal.style.top =
      //   select.getBoundingClientRect().y +
      //   select.getBoundingClientRect().height +
      //   "px";
      // portal.style.left = select.getBoundingClientRect().x + "px";
      // portal.style.width = select.getBoundingClientRect().width + "px";
      portal.style.backgroundColor = backgroundColor;
      const input = document.getElementById(inputId);
      input.focus();
      window.addEventListener("click", tapOutside);
    }, 100);
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    if (e.target.value.length == 0) {
      setFilteredData(data);
    } else {
      const filtered = data.filter((s) =>
        s[optionText].toLowerCase().startsWith(e.target.value.toLowerCase())
      );
      setFilteredData(filtered);
    }
  };

  return (
    <div className="multi-select">
      <div
        id={selectBoxId}
        className="select-box"
        onClick={() => showCheckboxes()}
      >
        <select className="form-control" id={selectId}>
          <option value="0">{defaultOption}</option>
        </select>
      </div>
      <div id="ms-portal-container"></div>

      <MultiSelectPortal
        open={show}
        data={filteredData}
        id={id}
        keyDescriptor={keyDescriptor}
        optionText={optionText}
        handleCheck={handleCheck}
        handleSearchChange={handleSearchChange}
        search={search}
        amIChecked={amIChecked}
        inputId={inputId}
      />
    </div>
  );
};

MultiSelect.propTypes = {
  defaultOption: PropTypes.string,
  data: PropTypes.array,
  keyDescriptor: string,
  optionValue: string,
  optionText: string,
  checkedItems: PropTypes.array,
  setCheckedItems: PropTypes.func,
  id: PropTypes.string,
  selectBoxId: PropTypes.string,
  backgroundColor: PropTypes.string,
  selectId: PropTypes.string,
  inputId: PropTypes.string
};

export default MultiSelect;
