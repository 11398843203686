import axios from "axios";
import qs from "qs";
import { formatDate } from "../utils";

async function queryBasketItems(url, query, token) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "basket/query",
    params: {
      query
    }
  });

  return json;
}

async function testQuery(url, query, apiKey) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
    url: url + "basket/testquery",
    params: {
      query,
      apiKey
    }
  });
  return json;
}

async function saveQuery(
  url,
  apiKey,
  reportName,
  query,
  columns,
  wheres,
  groups,
  orderbys,
  description,
  username,
  updateQuery,
  id
) {
  let json = await axios({
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    url: url + "basket/saveQuery",
    data: qs.stringify({
      reportName,
      query,
      description,
      username,
      apiKey,
      updateQuery,
      id,
      queryBuilder: {
        Columns: columns,
        Wheres: wheres,
        GroupBys: groups,
        OrderBys: orderbys
      }
    })
  });

  return json;
}

async function getReportList(url, apiKey, reportid) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
    url: url + "basket/customreportlist",
    params: {
      apiKey,
      reportid
    }
  });
  return json;
}

async function runQuery(
  url,
  id,
  apiKey,
  startDate,
  endDate,
  top,
  storeid,
  group
) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
    url: url + "basket/launchreport",
    params: {
      id,
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      apiKey,
      top,
      storeid,
      group
    }
  });
  return json;
}

async function getCsvData(
  url,
  id,
  apiKey,
  startDate,
  endDate,
  top,
  storeid,
  group
) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
    url: url + "basket/exportreport",
    params: {
      id,
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      apiKey,
      top,
      storeid,
      group
    }
  });
  return json;
}

async function testReport(
  url,
  id,
  apiKey,
  startDate,
  endDate,
  top,
  storeid,
  group
) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
    url: url + "basket/testreport",
    params: {
      id,
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      apiKey,
      top,
      storeid,
      group
    }
  });
  return json;
}

async function getCategories(url, apiKey) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
    url: url + "basket/categories",
    params: {
      apiKey
    }
  });
  return json;
}

async function getSubs(url, apiKey) {
  let json = await axios({
    method: "GEt",
    headers: {
      "Content-Type": "application/json"
    },
    url: url + "basket/subs",
    params: {
      apiKey
    }
  });
  return json;
}

export {
  queryBasketItems,
  testQuery,
  saveQuery,
  getReportList,
  runQuery,
  getCsvData,
  testReport,
  getCategories,
  getSubs
};
