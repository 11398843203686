import React from "react";
import PropTypes from "prop-types";

const Tab = (props) => {
  let className = "tab-list-item";
  if (props.label == props.activeTab) {
    className += " tab-list-active";
  } else if (props.disabled === true) {
    className += " tab-list-disabled";
  }
  return (
    <li
      onClick={() => props.onClick(props)}
      className={className}
      id={props.id}
    >
      {props.label}
    </li>
  );
};

Tab.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  label: PropTypes.string,
  activeTab: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string
};

export default Tab;
