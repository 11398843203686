import axios from "axios";
import { formatDate } from "../utils";

async function GetCustomerInfo(url, userid, customer, phone, apikey) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
    url: url + "mobile/custinfo",
    params: {
      userid,
      customer,
      phone,
      apikey
    }
  });

  return json;
}

async function ReadCustomerLinks(url, userid, customer, phone, apikey) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
    url: url + "mobile/readlinks",
    params: {
      userid,
      customer,
      phone,
      apikey
    }
  });
  return json;
}

async function GetMobileCustomerBalance(url, userid, customer, apikey) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
    url: url + "mobile/balance",
    params: {
      userid,
      customer,
      apikey
    }
  });
  return json;
}

async function getPointOverview(url, phonenumber, apikey) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
    url: url + "mobile/overview",
    params: {
      phonenumber,
      apikey
    }
  });
  return json;
}

async function getMonthOverview(url, phonenumber, activityDate, apikey) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
    url: url + "mobile/monthactivity",
    params: {
      phonenumber,
      activityDate,
      apikey
    }
  });
  return json;
}

async function getRedemptions(url, apikey, startDate, endDate, storenumber) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
    url: url + "houchens/redeemed",
    params: {
      storenumber,
      apikey,
      startDate: formatDate(startDate),
      endDate: formatDate(endDate)
    }
  });
  return json;
}

async function getTransactions(
  url,
  storenumber,
  startDate,
  endDate,
  totalizer,
  customer,
  apikey
) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
    url: url + "analysis/trspay",
    params: {
      storenumber,
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      totalizer,
      customer,
      apikey
    }
  });
  return json;
}

async function readRptPayN(url, token, customer, apikey) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "houchens/rptpayn",
    params: {
      customer,
      apikey
    }
  });
  return json;
}

async function getCustomerHistory(url, customer, apikey) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
    url: url + "analysis/history",
    params: {
      customer,
      apikey
    }
  });
  return json;
}

async function getExpired(url, apikey, customer, startDate, endDate) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
    url: url + "analysis/expired",
    params: {
      customer,
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      apikey
    }
  });
  return json;
}

async function getSummary(url, storenumber, month, year, apikey) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
    url: url + "analysis/summary",
    params: {
      storenumber,
      month,
      year,
      apikey
    }
  });

  return json;
}

async function getCustomerCounts(url, apikey) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
    url: url + "analysis/ccounts",
    params: {
      apikey
    }
  });

  return json;
}

async function getStoreCustomerCounts(url, storenumber, month, year, apikey) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
    url: url + "analysis/scount",
    params: {
      storenumber,
      month,
      year,
      apikey
    }
  });

  return json;
}

async function getStoreTotals(url, storenumber, apikey) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
    url: url + "analysis/stotals",
    params: {
      storenumber,
      apikey
    }
  });

  return json;
}

async function getTotals(url, apikey) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
    url: url + "analysis/totals",
    params: {
      apikey
    }
  });

  return json;
}

export {
  GetCustomerInfo,
  ReadCustomerLinks,
  GetMobileCustomerBalance,
  getPointOverview,
  getMonthOverview,
  getRedemptions,
  getTransactions,
  readRptPayN,
  getCustomerHistory,
  getExpired,
  getSummary,
  getCustomerCounts,
  getStoreCustomerCounts,
  getStoreTotals,
  getTotals
};
