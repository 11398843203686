import React from "react";
import PropTypes from "prop-types";
import TextInput from "../../common/TextInput";
import "react-datepicker/dist/react-datepicker.css";
import InputMask from "react-input-mask";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker
} from "@material-ui/pickers";

import "./Stores.css";

const daysOfWeek = [
  { id: 0, value: "Sunday" },
  { id: 1, value: "Monday" },
  { id: 2, value: "Tuesday" },
  { id: 3, value: "Wednesday" },
  { id: 4, value: "Thursday" },
  { id: 5, value: "Friday" },
  { id: 6, value: "Saturday" }
];

const CreateStoreForm = (props) => {
  return (
    <form className="p-3">
      <TextInput
        type="text"
        id="txtstorename"
        name="storeName"
        autoComplete="timeclock-storename"
        label={`Store Name (${props.store.id})`}
        onChange={props.onChange}
        value={props.store.storeName}
        error={props.errors.storeName}
        placeholder="Store Name"
      />

      <TextInput
        type="tel"
        id="txtstorenumber"
        autoComplete="timeclock-storenumber"
        name="storeNumber"
        label="Store Number"
        onChange={props.onChange}
        value={props.store.storeNumber}
        error={props.errors.storeNumber}
        placeholder="Store Number"
      />

      <TextInput
        type="text"
        id="txtaddress"
        autoComplete="timeclock-address"
        name="address"
        label="Address"
        onChange={props.onChange}
        value={props.store.address}
        error={props.errors.address}
        placeholder="Address"
      />

      <TextInput
        type="text"
        id="txtcity"
        autoComplete="timeclock-city"
        name="city"
        label="City"
        onChange={props.onChange}
        value={props.store.city}
        error={props.errors.city}
        placeholder="City"
      />

      <div className="cc-form-item">
        <label>State</label>
        <select
          className="form-control"
          autoComplete="timeclock-state"
          name="state"
          placeholder="State"
          onChange={props.onChange}
          value={props.store.state || ""}
        >
          <option value="0">Select a State</option>
          {props.states.length === 0 ? (
            <option value="-1">No States returned</option>
          ) : (
            props.states.map((s, index) => (
              <option key={index} value={s.abbr}>
                {s.name}
              </option>
            ))
          )}
        </select>
        {props.errors.state && (
          <div className="alert alert-danger">{props.errors.state}</div>
        )}
      </div>

      <TextInput
        type="text"
        id="txtzip"
        name="zip"
        autoComplete="timeclock-zip"
        label="Zip"
        onChange={props.onChange}
        value={props.store.zip}
        error={props.errors.zip}
        placeholder="Zip Code"
      />

      <div className="cc-form-item">
        <label htmlFor="phoneNumber">Phone Number</label>
        <InputMask
          mask="(999)-999-9999"
          type="tel"
          name="phoneNumber"
          autoComplete="timeclock-phonenumber"
          placeholde="Phone Number"
          className="form-control"
          onChange={props.onChange}
          value={props.store.phoneNumber}
        />
        {props.errors.phoneNumber && (
          <div className="alert alert-danger">{props.errors.phoneNumber}</div>
        )}
      </div>

      <div className="cc-form-item">
        <label>Company</label>
        <select
          className="form-control"
          name="companyId"
          autoComplete="timeclock-companyid"
          placeholder="CompanyId"
          onChange={props.onChange}
          value={props.store.companyId || ""}
        >
          <option value="-2">Select a Company</option>
          {props.companies.length === 0 ? (
            <option value="-1">No Companies returned</option>
          ) : (
            props.companies.map((s, index) => (
              <option key={index} value={s.company_Id}>
                {s.company_Name}
              </option>
            ))
          )}
        </select>
        {props.errors.company && (
          <div className="alert alert-danger">{props.errors.company}</div>
        )}
      </div>

      <div className="cc-form-item">
        <label>Store Group</label>
        <select
          className="form-control"
          name="storeGroup"
          onChange={props.onChange}
          value={props.store.storeGroup}
        >
          <option value="0">Select a Group</option>
          {props.groups.map((group, i) => (
            <option key={`sg-${i}`} value={group.id}>
              {group.name}
            </option>
          ))}
        </select>
        {props.errors.storeGroup && (
          <div className="alert alert-danger">{props.errors.storeGroup}</div>
        )}
      </div>

      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container justify="space-around">
          <div className="form-group mt-3" style={{ color: "#fff" }}>
            <KeyboardTimePicker
              margin="normal"
              label="Open Time"
              format="h:mm a"
              value={props.store.openTime}
              onChange={props.handleOpenChange}
              KeyboardButtonProps={{
                "aria-label": "change time"
              }}
            />
          </div>
          <div className="form-group mt-3" style={{ color: "#fff" }}>
            <KeyboardTimePicker
              margin="normal"
              label="Close Time"
              format="h:mm a"
              value={props.store.closeTime}
              onChange={props.handleCloseChange}
              KeyboardButtonProps={{
                "aria-label": "change time"
              }}
            />
          </div>
        </Grid>
      </MuiPickersUtilsProvider>

      <div className="cc-form-item">
        <label>Week Start</label>
        <select
          className="form-control"
          name="weekStart"
          placeholder="Select Week Start"
          autoComplete="timeclock-week-start"
          onChange={props.onChange}
          value={props.store.weekStart || ""}
        >
          {daysOfWeek.map((s, index) => (
            <option key={index} value={s.id}>
              {s.value}
            </option>
          ))}
        </select>
        {props.errors.weekStart && (
          <div className="alert alert-danger">{props.errors.weekStart}</div>
        )}
      </div>

      <div
        className="form-group mt-3"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <button
          className="btn btn-outline-dark"
          type="submit"
          onClick={props.onSubmit}
        >
          Submit
        </button>
      </div>
    </form>
  );
};

CreateStoreForm.propTypes = {
  store: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  states: PropTypes.array,
  openTime: PropTypes.any,
  closeTime: PropTypes.any,
  companies: PropTypes.array,
  handleOpenChange: PropTypes.func,
  handleCloseChange: PropTypes.func,
  groups: PropTypes.array
};

export default CreateStoreForm;
