import React from "react";
import ReactDOM from "react-dom";
import SmsItem from "./SmsItem";
import "./PriceModModal.css";

const PriceModModal = ({ isShowing, hide, dimensions }) =>
  isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <div className="modal-login-overlay freshop-fade-in" />
          <div
            className="modal-login-wrapper freshop-fade-in"
            aria-modal
            aria-hidden
            tabIndex={-1}
            role="dialog"
          >
            <div
              className="modal-pricemod"
              style={{
                position: "absolute",
                top: `${dimensions.height / 2 - dimensions.height / 4}`,
                left: `${dimensions.width / 2 - 500}`,
                height: `${dimensions.height - dimensions.height / 4 - 100}`,
                width: `${dimensions.width - dimensions.width / 4 - 100}`
              }}
            >
              <div className="modal-pricemodheader">
                <span className="modal-pricemod-confirm-title">
                  Price Modification
                </span>
                <span className="modal-pricemod-close-box">
                  <button
                    type="button"
                    className="modal-pricemod-close-button"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={hide}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </span>
              </div>
              <div className="modal-pricemod-body">
                <SmsItem />
              </div>
            </div>
          </div>
        </React.Fragment>,
        document.body
      )
    : null;

export default PriceModModal;
