import React, { useState, useEffect, useContext } from "react";
import { ContextConfig } from "../../App";
import { getUserPunchesByDates } from "../../api/punchApi";
import { getWeekStart } from "../../api/StoreApi";
import { toast } from "react-toastify";
import Spinner from "../../common/Spinner";
import DatePicker from "react-datepicker";
import { addDays, isValidDate, isValid } from "../../utils";
import { debounce } from "../../fp";

const UserDash = (tempStartDate) => {
  const [punches, setPunches] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [reRender, setReRender] = useState(false);
  const [weekStart, setWeekStart] = useState();
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const context = useContext(ContextConfig);

  useEffect(() => {
    if (isValid(tempStartDate)) {
      const sd = new Date(tempStartDate);
      setStartDate(sd);
      const tempEnd = addDays(sd, 6);
      const ed = new Date(tempEnd);
      setEndDate(ed);
    } else {
      if (isValidDate(context.startDate)) {
        setStartDate(context.startDate);
      } else {
        let sdate = new Date();
        setStartDate(addDays(sdate, -7));
      }
      if (isValidDate(context.endDate)) {
        setEndDate(context.endDate);
      }
    }
    if (isValid(context.lastClockStore)) {
      if (!isValidDate(weekStart)) {
        fetchWeekStart();
      } else {
        fetchPunches();
      }
    }

    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);

    findDivHeight();

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, [reRender, weekStart]);

  const findDivHeight = () => {
    const navbar = document
      .getElementById("master-navbar")
      .getBoundingClientRect().height;

    const controls = document
      .getElementById("dash-controls")
      .getBoundingClientRect().height;

    const usedSpace = navbar + controls + 20;
    const remaining = window.innerHeight - usedSpace;

    const widget = document.getElementById("punches");
    widget.style.height = remaining + "px";
    widget.style.overflow = "auto";
  };

  const handleStartDateChange = (e) => {
    context.startDate = e;
    context.endDate = addDays(e, 6);
    setStartDate(context.startDate);
    setEndDate(context.endDate);
    setReRender(!reRender);
  };

  const handleEndDateChange = (e) => {
    context.endDate = e;
    context.startDate = addDays(e, -7);
    setStartDate(context.startDate);
    setEndDate(context.endDate);
    setReRender(!reRender);
  };

  const fetchPunches = () => {
    setIsLoading(true);
    getUserPunchesByDates(
      context.clockUrl,
      context.clockToken,
      context.userid,
      context.startDate,
      context.endDate,
      context.lastClockStore
    )
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          setPunches(j.punches);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        toast.error("An error occured fetching yyur punches", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const getTotal = () => {
    return punches.reduce((acc, cur) => {
      return acc + parseFloat(cur.total);
    }, 0);
  };

  const fetchWeekStart = () => {
    getWeekStart(context.clockUrl, context.clockToken, context.lastClockStore)
      .then((response) => {
        const j = response.data;
        if (j.error === 0) {
          context.startDate = new Date(j.weekstart);
          setStartDate(j.weekStart);
          context.endDate = new Date(addDays(context.startDate, 7));
          setEndDate(context.endDate);
          setWeekStart(context.startDate);
          context.lastBatchClose = j.lastBatchClose;
          context.weekStartLoaded = true;
        } else {
          console.log(j.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="container" id="dash">
      {isLoading && <Spinner />}
      <div id="dash-controls">
        <h3>User Dashboard</h3>
        <h3>Hours</h3>
        <div className="container">
          <div className="row">
            <div className="col align-items-center justify-content-center d-flex mt-1">
              Start:&nbsp;
              <DatePicker
                selected={startDate}
                onChange={handleStartDateChange}
              />
            </div>
            <div className="col align-items-center justify-content-center d-flex mt-1">
              End:&nbsp;
              <DatePicker selected={endDate} onChange={handleEndDateChange} />
            </div>
          </div>
        </div>
      </div>

      <div className="user-db-punches" id="punches">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>In</th>
              <th>Out</th>
              <th>Type</th>
              <th style={{ textAlign: "right" }}>Total</th>
            </tr>
          </thead>
          <tbody>
            {punches.map((record, index) => (
              <tr key={index}>
                <td>{record.punchIn}</td>
                <td>{record.punchOut}</td>
                <td>{record.punchType}</td>
                <td style={{ textAlign: "right" }}>{record.total}</td>
              </tr>
            ))}
            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td style={{ textAlign: "right" }}>Total</td>
              <td style={{ textAlign: "right" }}>{getTotal()}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UserDash;
