import React from "react";
import PropTypes from "prop-types";

const Td = (props) => {
  return <div className={`sudo-cell ${props.className}`}>{props.children}</div>;
};

Td.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any
};

export default Td;
