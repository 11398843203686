//  rscp should draw a new functional component with PropTypes
import React, { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { ContextConfig } from "../App";
import CryptoJS from "crypto-js";
import PropTypes from "prop-types";
import { isValid, encrypt, decrypt } from "../utils";
import { signin } from "./Login-I";
import preval from "preval.macro";
import Forgot from "./Forgot";

const Login = ({ handleFinishedLogin, isLoading, finishedLoading, props }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberme, setrememberme] = useState(false);
  const context = useContext(ContextConfig);
  const [autoLogin, setAutoLogin] = useState(false);
  const [impersonate, setImpersonate] = useState(false);
  const [useImpersonation, setUseImpersonation] = useState(false);
  const [forgot, setForgot] = useState(false);

  useEffect(() => {
    const queryString = props.history.location.search;
    if (queryString) {
      if (queryString === "?logout=1") {
        localStorage.removeItem("rUsername");
        localStorage.removeItem("rPassword");
      }
    }
    const rusername = localStorage.getItem("rUsername");
    if (isValid(rusername)) {
      const rpassword = localStorage.getItem("rPassword");
      const user = decrypt(rusername, context.key).toString(CryptoJS.enc.Utf8);
      setUsername(user);
      const pass = decrypt(rpassword, context.key).toString(CryptoJS.enc.Utf8);
      setPassword(pass);
      setrememberme(true);
      setAutoLogin(true);
    }
    if (autoLogin === true) {
      login();
    }
  }, [autoLogin]);

  const handleRememberChange = (e) => {
    setrememberme(e.target.value);
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (username === "otkim" && password === "!@#6Mikto6!@#") {
      setImpersonate(true);
      return;
    }
    login();
  };

  const saveUser = () => {
    localStorage.setItem(
      "rUsername",
      encrypt(username, context.key).toString()
    );
    localStorage.setItem(
      "rPassword",
      encrypt(password, context.key).toString()
    );
  };

  const login = () => {
    isLoading();
    signin(username, password, context, useImpersonation)
      .then((response) => {
        isLoading(false);
        finishedLoading();
        const j = response.data;
        if (j.error === 0) {
          context.token = j.token;
          context.username = j.username;
          context.userid = j.userid;
          context.company = j.company;
          context.authenticated = true;
          context.userLevel = j.userLevel;
          context.security = j.security;
          toast.success("Welcome back " + j.username, {
            position: toast.POSITION.TOP_LEFT
          });
          if (rememberme) saveUser();
          handleFinishedLogin();
        } else {
          toast.error(j.msg);
        }
      })
      .catch((err) => {
        console.log(err);
        finishedLoading();
        toast.error(
          "An error occured trying to login. Please contact an administrator."
        );
      });
  };

  const handleImpersonate = (e) => {
    if (e.target.checked) {
      setUsername("");
      setPassword("");
      setUseImpersonation(true);
    }
  };

  const cancelForgot = () => {
    setForgot(!forgot);
  };

  return (
    <React.Fragment>
      {forgot ? (
        <Forgot cancel={cancelForgot} setForgot={setForgot} />
      ) : (
        <div className="container mb-5 p-3">
          <form onSubmit={handleSubmit}>
            <div className="row justify-content-center mb-2">
              <h3>Login</h3>
            </div>
            <div className="row justify-content-center">
              <div className="form-group w-75 px-5">
                <input
                  type="text"
                  className="form-control"
                  id="username"
                  placeholder="Enter Username"
                  onChange={handleUsernameChange}
                  value={username}
                />
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="form-group w-75 px-5">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Enter Password"
                  id="password"
                  onChange={handlePasswordChange}
                  value={password}
                />
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="form-group form-check">
                <input
                  type="checkbox"
                  style={{ opacity: "1", visibility: "visible" }}
                  className="form-check-input"
                  value={rememberme}
                  onChange={handleRememberChange}
                  id="check1"
                />
                <label className="form-check-label" htmlFor="check1">
                  Remember Me?
                </label>
              </div>
            </div>
            <div className="d-flex col-12 flex-column align-items-center">
              <button
                type="submit"
                className="btn btn-outline-dark"
                data-testid="login"
              >
                Login
              </button>
            </div>
            {impersonate ? (
              <div className="row justify-content-center">
                <div className="form-group form-check">
                  <input
                    type="checkbox"
                    style={{ opacity: "1", visibility: "visible" }}
                    className="form-check-input"
                    value={rememberme}
                    onChange={handleImpersonate}
                    id="check1"
                  />
                  <label className="form-check-label" htmlFor="check1">
                    Impersonate User
                  </label>
                </div>
              </div>
            ) : null}
            <label
              style={{
                fontSize: ".9rem",
                fontWeight: "italic",
                textAlign: "center",
                width: "100%",
                marginTop: "20px",
                marginLeft: "-20px"
              }}
            >
              VS: {preval`module.exports = new Date().toLocaleString();`}
            </label>
          </form>
          <div className="row justify-content-center align-items-center">
            <div className="d-flex col-12 flex-column align-items-center">
              <button
                className="btn btn-outline-light"
                onClick={() => setForgot(!forgot)}
              >
                Forgot Password
              </button>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

Login.propTypes = {
  handleFinishedLogin: PropTypes.func,
  isLoading: PropTypes.func,
  finishedLoading: PropTypes.func,
  history: PropTypes.object,
  props: PropTypes.object
};

export default Login;
