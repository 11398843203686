import React from "react";
import HeaderRB from "../common/HeaderRb";
import Tabs from "../common/Tabs";
import Item from "./Item";
import Coupon from "./Coupon";
import Itemset from "./Itemsets";
import "./Predict.css";

const Predict = () => {
  return (
    <div>
      <HeaderRB />
      <div className="container" style={{ paddingTop: "10px" }}>
        <Tabs>
          <div label="Item">
            <Item />
          </div>
          <div label="Coupons">
            <Coupon />
          </div>
          <div label="Item Sets">
            <Itemset />
          </div>
        </Tabs>
      </div>
    </div>
  );
};

export default Predict;
