import * as actions from "../actions/actionTypes";
import { addDays } from "../utils";

const initialState = {
  isLoading: false,
  startDate: addDays(new Date(), -7),
  endDate: new Date(),
  charges: [],
  filteredCharges: [],
  customer: 0,
  totalizer: 0,
  totalizers: [],
  customers: []
};

const chargesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.CHARGES_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading
      };
    case actions.CHARGES_SET_START_DATE:
      return {
        ...state,
        startDate: action.startDate
      };
    case actions.CHARGES_SET_CHARGES:
      return {
        ...state,
        charges: action.charges
      };
    case actions.CHARGES_SET_FILTERED_CHARGES:
      return {
        ...state,
        filteredCharges: action.filteredCharges
      };
    case actions.CHARGES_SET_CUSTOMER:
      return {
        ...state,
        customer: action.customer
      };
    case actions.CHARGES_SET_TOTALIZER:
      return {
        ...state,
        totalizer: action.totalizer
      };
    case actions.CHARGES_SET_TOTALIZERS:
      return {
        ...state,
        totalizers: action.totalizers
      };
    case actions.CHARGES_SET_CUSTOMERS:
      return {
        ...state,
        customers: action.customers
      };
    default:
      return state;
  }
};

export default chargesReducer;
