import React, { useState, useEffect, useContext } from "react";
import LoadingOverlay from "react-loading-overlay";
import HeaderRB from "../common/HeaderRb";
import { ContextConfig } from "../App";
import StoreMulti from "./mobile_components/StoreMulti";

const ReportViewer = () => {
  const [isLoading, setIsLoading] = useState(false); // eslint-disable-line
  const [report, setReport] = useState({});
  const context = useContext(ContextConfig);
  useEffect(() => {
    setReport(context.report);
  }, []);

  return (
    <LoadingOverlay active={isLoading} spinner text="Loading Data...">
      <HeaderRB />
      <div className="container-fluid">
        <h3>{report.report_Name}</h3>
        {() => {
          switch (report.reportName) {
            case "storeMulti":
              return <StoreMulti storelist={context.storelist} />;
          }
        }}
      </div>
    </LoadingOverlay>
  );
};

ReportViewer.propTypes = {};

export default ReportViewer;
