import React from "react";
import PropTypes from "prop-types";

const ReportConverter = ({ html }) => {
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};

ReportConverter.propTypes = {
  html: PropTypes.string
};

export default ReportConverter;
