import React from "react";
import PropTypes from "prop-types";

const OpenPunchThumbnail = (props) => {
  const style = {
    backgroundColor: props.backgroundColor,
    borderRadius: "10px",
    marginRight: "10px",
    padding: "10px",
    cursor: "pointer",
    marginBottom: "10px",
    minWidth: "110px",
    color: "#fff",
    WebkitBoxShadow:
      "7px 7px 5px 0px rgba(0,0,0,0.29), inset 5px 5px 29px 4px rgba(0, 0, 0, 0.29)",
    MozBoxShadow:
      "7px 7px 5px 0px rgba(0,0,0,0.29), inset 5px 5px 29px 4px rgba(0, 0, 0, 0.29)",
    boxShadow:
      "7px 7px 5px 0px rgba(0,0,0,0.29), inset 5px 5px 29px 4px rgba(0, 0, 0, 0.29)"
  };

  return (
    <div
      className="open-punch-thumbnail"
      style={style}
      onClick={props.handleClick}
    >
      <div className="opt-header" style={{ display: "flex" }}>
        <div className="opt-id" style={{ flex: "0 0 65%" }}>
          {props.punch.id}
        </div>
        <div style={{ flex: "1" }}>
          <span
            className="opt-edit"
            style={{ cursor: "pointer" }}
            data-toggle="tooltip"
            data-placement="top"
            title="Edit"
            onClick={() => props.handleEdit(props.punch)}
          >
            <i className="far fa-save"></i>
          </span>
          <span
            className="opt-del"
            style={{ float: "right", cursor: "pointer" }}
            data-toggle="tooltip"
            data-placement="top"
            title="Delete"
            onClick={() => props.handleDelete(props.punch.id)}
          >
            <i className="far fa-trash-alt"></i>
          </span>
        </div>
      </div>
      <div className="opt-body">
        <div className="opt-user">{props.punch.username}</div>
        <div className="opt-punchtype">{props.punch.punchType}</div>
        <div className="opt-date">{props.punch.punchInDate}</div>
      </div>
    </div>
  );
};

OpenPunchThumbnail.propTypes = {
  backgroundColor: PropTypes.string,
  punch: PropTypes.object,
  handleClick: PropTypes.func,
  handleDelete: PropTypes.func,
  handleEdit: PropTypes.func
};

export default OpenPunchThumbnail;
