import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { createIcon, createColor } from "./CashierFlyout-I";
import { formatDate, setDecimal } from "../../utils";
import CashierNavigation from "../CashierNavigation";
import { debounce } from "../../fp";
import { Pie } from "react-chartjs-2";
import "./DesktopFlyout.css";

const DesktopFlyout = ({ cashiers, context }) => {
  const [reRender, setReRender] = useState(false);
  const [goBack, setGoBack] = useState(false);
  const [step, setStep] = useState(0);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  useEffect(() => {
    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);

    findDivHeight();

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, []);

  const findDivHeight = () => {
    const navbar = document
      .getElementById("master-navbar")
      .getBoundingClientRect().height;
    const controls = document
      .getElementById("flyout-controls")
      .getBoundingClientRect().height;
    const usedSpace = navbar + controls;

    const remaining = window.innerHeight - usedSpace;
    const target = document.getElementById("flyout-holder");
    target.style.height = remaining + "px";
    target.style.overflow = "auto";
  };

  const handleClick = (cashier) => {
    context.cashier = cashier;
    setReRender(true);
  };

  const handleGoback = (currentStep) => {
    setStep(currentStep);
    setGoBack(true);
  };

  const generatePie = (cashier) => {
    const pie = {
      data: {
        labels: ["Void", "EC", "Refund", "Correction", "No Sale"],
        datasets: [
          {
            label: "Exceptions",
            pointRadius: 4,
            pointHoverRadius: 4,
            backgroundColor: [
              "rgba(246, 72, 97, 0.9)",
              "rgba(79, 38, 237, 0.9)",
              "rgba(182, 88, 174, 0.9)",
              "rgba(227, 122, 36, 0.9)",
              "rgba(237, 51, 38, 0.9)"
            ],
            borderWidth: 2,
            data: [
              cashier.voidCount,
              cashier.errorCorrectCount,
              cashier.refundKeyCount,
              cashier.correctionCount,
              cashier.noSaleCount
            ]
          }
        ]
      },
      options: {
        cutoutPercentage: 50,
        legend: {
          display: false
        },
        tooltips: {
          backgroundColor: "#f5f5f5",
          titleFontColor: "#333",
          bodyFontColor: "#666",
          bodySpacing: 2,
          xPadding: 6,
          yPadding: 6,
          mode: "nearest",
          intersect: 0,
          position: "nearest"
        },
        scales: {
          yAxes: [
            {
              display: 0,
              ticks: {
                display: false
              },
              gridLines: {
                drawBorder: false,
                zeroLineColor: "transparent",
                color: "rgba(255, 255, 255, 0.05)"
              }
            }
          ],
          xAxes: [
            {
              display: 0,
              barPercentage: 1.6,
              gridLines: {
                drawBorder: false,
                color: "rgba(255, 255, 255, 255, 0.1)",
                zeroLineColor: "transparent"
              },
              ticks: {
                display: false
              }
            }
          ]
        }
      }
    };

    return pie;
  };

  return (
    <div className="div">
      {reRender && <Redirect to="/totalizers" />}
      {goBack && step == 0 && <Redirect to="/cashiers" />}

      <div className="dfo-main">
        <div className="container flyout-controls" id="flyout-controls">
          <div className="dfo-title">
            <div className="dfo-storename">
              {context.selectedRecord.store_Name}
            </div>
          </div>
          <div className="dfo-date">
            Start Date: {formatDate(context.startDate)} - EndDate:{" "}
            {formatDate(context.endDate)}
          </div>
          <div className="container">
            <CashierNavigation step="0" goBack={handleGoback} />
          </div>
        </div>

        <div
          className="dfo-holder row justify-content-center p-5"
          id="flyout-holder"
        >
          {cashiers.length === 0 ? (
            <div>No records returned</div>
          ) : (
            cashiers.map((cashier, index) => (
              <div
                key={index}
                className="dfo-cashier d-inline col-sm-6 col-md-4 col-lg-3 m-3"
              >
                <div className="dfo-cashier-wrapper">
                  <div className="dfo-cashier-pie">
                    <Pie
                      data={generatePie(cashier).data}
                      options={generatePie(cashier).options}
                    />
                  </div>
                  <div
                    className="dfo-cashier-body"
                    onClick={() => handleClick(cashier)}
                  >
                    <div className="dfo-cashier-name">
                      <span
                        className={createColor(
                          cashier.finalCashierConcern,
                          cashier.purples
                        )}
                      >
                        <i
                          className={`dfo-icon ${createIcon(
                            cashier.finalCashierConcern,
                            cashier.purples
                          )}`}
                        ></i>
                      </span>
                      &nbsp;{`${cashier.cashier}`}
                    </div>
                    <div className="dfo-contents">
                      <div className="dfo-left">
                        <div className="dfo-info">Seq: {cashier.f1185}</div>
                        <div className="dfo-cashier-perc">{`${setDecimal(
                          cashier.totalCashierScore
                        )}%/${cashier.maxThreshold}`}</div>
                      </div>
                      <div className="dfo-right">
                        <div className="dfo-concern">
                          Concern: {cashier.finalCashierConcern}
                        </div>
                        <div className="dfo-cashier-level">
                          User Level:{cashier.cashierLevel}
                        </div>
                      </div>
                    </div>
                    <div className="dfo-cashier-purple">
                      Outliers: {cashier.purples}
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

DesktopFlyout.propTypes = {
  cashiers: PropTypes.array,
  context: PropTypes.object
};

export default DesktopFlyout;
