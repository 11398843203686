import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { handleAjax } from "../../../utils";
import { Row, Col } from "reactstrap";
import DatePicker from "react-datepicker";
import { getAssignedStoresAsync } from "../../../api/StoreApi";
import { getStoreNumberById } from "../../../api/ReportsApi";

const MobilePayserverTransactionsControls = ({
  context,
  state,
  dispatch,
  handleSearchClick
}) => {
  useEffect(() => {
    console.log("useEffect for Controls", context);
    fetchStores();
  }, [state.storeid, state.startDate, state.endDate]);

  useEffect(() => {
    if (state.stores.length > 0) {
      getStoreNumberById(context.url, context.token, context.lastStoreid)
        .then((response) => {
          const j = response.data;
          if (j.error === 0) {
            dispatch({ type: "storenumber", storenumber: j.storenumber });
          } else {
            dispatch({ type: "error", msg: j.msg });
          }
        })
        .catch((err) => {
          dispatch({
            type: "fail",
            msg: "An error occured getting the store number from the storeid",
            err
          });
        });
    }
  }, [state.stores]);

  const fetchStores = () => {
    getAssignedStoresAsync(context.url, context.token).then((response) => {
      handleAjax(response, dispatch, "stores");
    });
  };

  return (
    <div id="r-controls">
      <div className="rpt-name">Transactions</div>

      <div className="d-report-info">
        <div className="d-report-storename">
          <div className="d-report-date">{context.reportStore.name}</div>
        </div>
        <div className="container" style={{ marginTop: "20px" }}>
          <Row className="justify-content-center">
            <Col md="4">
              <select
                className="form-control"
                value={state.storeid}
                onChange={(e) =>
                  dispatch({
                    type: "storeid",
                    storeid: e.target.value,
                    context: context
                  })
                }
              >
                <option value="0">Select a Store</option>
                {state.stores.length > 0
                  ? state.stores.map((record, index) => (
                      <option key={`ss-${index}`} value={record.storeid}>
                        {record.store_Name}
                      </option>
                    ))
                  : null}
              </select>
            </Col>
            <Col md="4" className="mt-2">
              <label>Start Date: </label>
              <DatePicker
                selected={state.startDate}
                onChange={(e) => dispatch({ type: "startdate", date: e })}
              />
            </Col>
            <Col md="4" className="mt-2">
              <label>End Date: </label>
              <DatePicker
                selected={state.endDate}
                onChange={(e) => dispatch({ type: "enddate", date: e })}
              />
            </Col>
          </Row>
          <Row className="justify-content-center mt-3">
            <Col md="4">
              <input
                type="number"
                className="form-control"
                value={state.customer}
                onChange={(e) =>
                  dispatch({ type: "customer", customer: e.target.value })
                }
              />
            </Col>
            <Col
              md="4"
              className="mt-2"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <button
                className="btn btn-outline-light"
                onClick={handleSearchClick}
              >
                Search
              </button>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

MobilePayserverTransactionsControls.propTypes = {
  data: PropTypes.array,
  context: PropTypes.object,
  state: PropTypes.object,
  dispatch: PropTypes.func,
  stores: PropTypes.array,
  storenumber: PropTypes.string,
  handleSearchClick: PropTypes.func
};

export default MobilePayserverTransactionsControls;
