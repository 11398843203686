import React, { useState, useEffect, useContext } from "react";
import HeaderRb from "../../common/HeaderRb";
import { toast } from "react-toastify";
import { ContextConfig } from "../../App";
import { isEqual } from "lodash";
import {
  usePrevious,
  isValidDate,
  addDays,
  formatDate,
  print,
  handleCsv
} from "../../utils";
import { readManagerOverridePunchesDesktop } from "../../api/ReportsApi";
import Spinner from "../../common/Spinner";
import { debounce } from "../../fp";

const dManagerOverridePunches = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const context = useContext(ContextConfig);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const previousData = usePrevious(data);

  useEffect(() => {
    console.log(context);
    if (!isEqual(previousData, data)) {
      loadData();
    }
    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);

    findDivHeight();

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, [data]);

  const findDivHeight = () => {
    const navbar = document
      .getElementById("master-navbar")
      .getBoundingClientRect().height;
    const controls = document
      .getElementById("r-controls")
      .getBoundingClientRect().height;
    const usedSpace = navbar + controls + 30;
    const remaining = window.innerHeight - usedSpace;

    const body = document.getElementById("r-body");
    body.style.height = remaining + "px";
    body.style.overflow = "auto";
  };

  const loadData = () => {
    setIsLoading(true);
    if (!isValidDate(context.startDate)) {
      context.startDate = addDays(new Date(), -7);
    }
    readManagerOverridePunchesDesktop(
      context.url,
      context.token,
      context.startDate,
      context.endDate,
      context.lastStoreid
    )
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          setData(j.items);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const handlePrint = () => {
    print(document.getElementById("overrides").innerHTML);
  };

  return (
    <React.Fragment>
      {isLoading && <Spinner />}
      <HeaderRb />
      <div className="container-fluid" id="overrides">
        <div id="r-controls">
          <div className="rpt-name">Manager Punch Overrides</div>
          <div className="export-report">
            <span className="print-report" onClick={handlePrint}>
              <i className="far fa-print"></i>
            </span>
            <span
              className="csv-report"
              onClick={() => handleCsv(data, "overrides.csv")}
            >
              <i className="far fa-save"></i>
            </span>
          </div>

          <div className="d-report-info">
            <div className="d-report-storename">
              <div className="d-report=date">{context.reportStore.name}</div>
              <span>Start: {formatDate(context.startDate)}</span>
              <span>&nbsp;</span>
              <span>End: {formatDate(context.endDate)}</span>
            </div>
          </div>
        </div>
        <div id="r-body">
          <React.Fragment>
            {data.length === 0 ? (
              <div>No records were returned</div>
            ) : (
              <table style={{ width: "90%", margin: "auto" }}>
                <thead>
                  <tr>
                    <th style={{ width: "20%" }}>Out</th>
                    <th style={{ width: "20%" }}>In</th>
                    <th style={{ width: "10%" }}>Userid</th>
                    <th style={{ width: "20%" }}>Username</th>
                    <th style={{ width: "10%" }}>Manager Id</th>
                    <th style={{ width: "20%" }}>Manager Name</th>
                    <th style={{ width: "20%" }}>Posted</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((record, index) => (
                    <tr key={index}>
                      <td>{record.out}</td>
                      <td>{record.in}</td>
                      <td>{record.userid}</td>
                      <td>{record.username}</td>
                      <td>{record.manager_Id}</td>
                      <td>{record.manager_Name}</td>
                      <td>{formatDate(record.posted)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </React.Fragment>
        </div>
      </div>
    </React.Fragment>
  );
};

export default dManagerOverridePunches;
