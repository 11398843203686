import { emailTransaction } from "../../api/CashierApi";

async function email(f1032, storeid, cashier, f254, f1056, context) {
  let args = f1056.split(":");
  let cashierArgs = cashier.split(":");
  let result = await emailTransaction(
    context.url,
    context.token,
    storeid,
    f1032,
    f254,
    cashierArgs[0],
    args[1]
  );
  return result;
}

export { email };
