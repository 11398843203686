import axios from "axios";
import { formatDate } from "../utils";

async function getGroups(url, token) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "users/globalgroups"
  });
  return json;
}

async function getDistricts(url, token) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "mobile/districts"
  });
  return json;
}

async function getDistrictStore(url, token, group_id, queryDate) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "mobile/districtstore",
    params: {
      group_id,
      querydate: formatDate(queryDate)
    }
  });
  return json;
}

async function getDistrictStoreDesktop(
  url,
  token,
  group_id,
  startDate,
  endDate
) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "reports/districtstore",
    params: {
      group_id,
      startdate: formatDate(startDate),
      endDate: formatDate(endDate)
    }
  });
  return json;
}

async function getDistrictSales(url, token, group_id, queryDate) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "mobile/districtsales",
    params: {
      group_id,
      queryDate: formatDate(queryDate)
    }
  });
  return json;
}

async function getDistrictSalesDesktop(
  url,
  token,
  group_id,
  startDate,
  endDate
) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "reports/districtsales",
    params: {
      group_id,
      startDate: formatDate(startDate),
      endDate: formatDate(endDate)
    }
  });
  return json;
}

export {
  getGroups,
  getDistricts,
  getDistrictStore,
  getDistrictSales,
  getDistrictSalesDesktop,
  getDistrictStoreDesktop
};
