import axios from "axios";
import { formatDate } from "../utils";

async function readReports(url, token) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "mobile/reportlist"
  });
  return json;
}

async function readStoreMulti(url, token, startDate, storelist) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "mobile/storeMulti",
    params: {
      startDate: formatDate(startDate),
      storelist
    }
  });
  return json;
}

async function readDesktopStoreMulti(
  url,
  token,
  startDate,
  endDate,
  storelist
) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "reports/multi",
    params: {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      storelist
    }
  });
  return json;
}

async function readAceRewards(url, token, startDate, storelist) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "mobile/AceRewardsReport",
    params: {
      startDate: formatDate(startDate),
      storelist
    }
  });
  return json;
}

async function readDesktopAceRewards(
  url,
  token,
  startDate,
  endDate,
  storelist
) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "reports/AceRewardsReport",
    params: {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      storelist
    }
  });
  return json;
}

async function readDeptSingle(url, token, startDate, storelist) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "mobile/deptSingle",
    params: {
      startDate: formatDate(startDate),
      storelist
    }
  });
  return json;
}

async function readDeptSingleDesktop(
  url,
  token,
  startDate,
  endDate,
  storelist
) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "reports/deptSingle",
    params: {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      storelist
    }
  });
  return json;
}

async function readGroupSingle(url, token, startDate, storelist) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "mobile/GroupSingle",
    params: {
      startDate: formatDate(startDate),
      storelist
    }
  });
  return json;
}

async function readGroupSingleDesktop(
  url,
  token,
  startDate,
  endDate,
  storelist
) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "reports/GroupSingle",
    params: {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      storelist
    }
  });
  return json;
}

async function readOperatorMulti(url, token, startDate, storelist) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "mobile/OperatorMulti",
    params: {
      startDate: formatDate(startDate),
      storelist
    }
  });
  return json;
}

async function readOperatorMultiDesktop(
  url,
  token,
  startDate,
  endDate,
  storelist
) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "reports/OperatorMulti",
    params: {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      storelist
    }
  });
  return json;
}

async function readManagerOverridePunches(url, token, startDate, storelist) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "mobile/manager_override_punches",
    params: {
      startDate: formatDate(startDate),
      storelist
    }
  });
  return json;
}

async function readManagerOverridePunchesDesktop(
  url,
  token,
  startDate,
  endDate,
  storelist
) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "reports/manager_override_punches",
    params: {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      storelist
    }
  });
  return json;
}

async function readOvertime(url, token, startDate, storelist) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "mobile/read_Overtime",
    params: {
      startDate: formatDate(startDate),
      storelist
    }
  });
  return json;
}

async function readOvertimeDesktop(url, token, startDate, endDate, storelist) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "mobile/read_Overtime",
    params: {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      storelist
    }
  });
  return json;
}

async function readDivision(url, token, startDate, storelist) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "mobile/SalDivision",
    params: {
      startDate: formatDate(startDate),
      storelist
    }
  });
  return json;
}

async function readDivisionDesktop(url, token, startDate, endDate, storelist) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "reports/SalDivision",
    params: {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      storelist
    }
  });
  return json;
}

async function readBalancing(url, token, startDate, storelist) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "mobile/sbSheet",
    params: {
      startDate: formatDate(startDate),
      storelist
    }
  });
  return json;
}

async function readBalancingDesktop(url, token, startDate, endDate, storelist) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "mobile/sbSheet",
    params: {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      storelist
    }
  });
  return json;
}

async function readSubs(url, token, startDate, storelist) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "mobile/SubDepartments",
    params: {
      startDate: formatDate(startDate),
      storelist
    }
  });
  return json;
}

async function readSubsDesktop(url, token, startDate, endDate, storelist) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "mobile/SubDepartments",
    params: {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      storelist
    }
  });
  return json;
}

async function getStoreNumberById(url, token, storeid) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "reports/storenumber",
    params: {
      storeid
    }
  });
  return json;
}

async function getVendorReport(
  url,
  token,
  storeid,
  vendorid,
  startDate,
  endDate
) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "reports/vendor",
    params: {
      vendorid,
      startDate,
      endDate,
      storeid
    }
  });
  return json;
}

async function getVendorList(url, token) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "reports/vendorlist"
  });
  return json;
}

async function singleToDate(url, token, date, group) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    url: url + "reports/singleToDate",
    params: {
      date,
      group
    }
  });
  return json;
}

async function getReceiverList(url, apikey, startdate, enddate, storeid) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
    url: url + "reports/list",
    params: {
      storeid,
      startdate,
      enddate,
      apikey
    }
  });
  return json;
}

async function getReceiverContents(url, storeid, f1032, apikey) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
    url: url + "reports/rec",
    params: {
      storeid,
      f1032,
      apikey
    }
  });
  return json;
}

async function getRecStoreList(url, apikey) {
  let json = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
    url: url + "reports/stores",
    params: {
      apikey
    }
  });
  return json;
}

export {
  readReports,
  readStoreMulti,
  readAceRewards,
  readDeptSingle,
  readGroupSingle,
  readOperatorMulti,
  readManagerOverridePunches,
  readOvertime,
  readDivision,
  readBalancing,
  readSubs,
  readDesktopStoreMulti,
  readDesktopAceRewards,
  readDeptSingleDesktop,
  readGroupSingleDesktop,
  readOperatorMultiDesktop,
  readManagerOverridePunchesDesktop,
  readOvertimeDesktop,
  readDivisionDesktop,
  readBalancingDesktop,
  readSubsDesktop,
  getStoreNumberById,
  getVendorReport,
  getVendorList,
  singleToDate,
  getReceiverList,
  getReceiverContents,
  getRecStoreList
};
