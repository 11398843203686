import React, { useState, useEffect, useContext } from "react";
import { Col, Row } from "reactstrap";
import { debounce } from "../fp";
import { pad } from "../utils";
import { getItemDetails } from "../api/predictApi";
import { toast } from "react-toastify";
import { ContextConfig } from "../App";

//import Spinner from "../common/Spinner";
import HappyLoader from "../common/loaders/HappyLoader";

const Item = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState([]);
  // const [predictedItems, setPredictedItems] = useState([]);
  // const [filteredPredictedItems, setFilterdPredictedItems] = useState([]);
  const [itemDetails, setItemDetails] = useState([]);
  // const [recordSize, setRecordSize] = useState(5);
  // const [support, setSupport] = useState(0);
  // const [probability, setProbability] = useState(0);
  // const [adjProbability, setAdjProbability] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [targetItems, setTargetItems] = useState([]);
  const [itemSize, setItemSize] = useState(5);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const context = useContext(ContextConfig);

  useEffect(() => {
    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);

    findDivHeight();

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, []);

  useEffect(() => {
    fetchItemInfo();
  }, [itemSize]);

  const findDivHeight = () => {
    const navbar = document
      .getElementById("master-navbar")
      .getBoundingClientRect().height;
    const controls = document
      .getElementById("predict-controls")
      .getBoundingClientRect().height;
    const usedSpace = navbar + controls + 20;
    const remaining = window.innerHeight - usedSpace;

    const body = document.getElementById("predict-body");
    body.style.height = remaining + "px";
    body.style.overflow = "auto";
  };

  const fetchItemInfo = () => {
    if (items.length === 0) return;
    const newArray = items.map((item) => {
      return pad(item, 13, "0", "left");
    });
    setIsLoading(true);
    getItemDetails(context.url, context.token, newArray, itemSize)
      .then((response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          setItemDetails(j.items);
          setTotalRecords(j.totalTransactions);
          const targets = filterRecords(j.items, "product_id1");
          setTargetItems(targets);
        } else {
          setItemDetails([]);
          setTargetItems([]);
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setItemDetails([]);
        toast.error("An error occured getting your item details", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const handleItemChange = (e) => {
    const itemEntry = e.target.value;
    const itemArray = itemEntry.split(",");
    setItems(itemArray);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    fetchItemInfo();
  };

  Array.prototype.contains = function(key, value) {
    let status = false;
    this.forEach((item) => {
      if (item[key] === value) {
        status = true;
      }
    });

    return status;
  };

  const handleItemsizeChange = (e) => {
    setItemSize(e.target.value);
  };

  const filterRecords = (items) => {
    const newArray = [];
    items.map((record) => {
      if (!newArray.contains("product_id1", record.product_id1)) {
        newArray.push({
          product_id1: record.product_id1,
          description1: record.description1
        });
      }
    });

    return newArray;
  };

  // const findMin = (arr, field) => {
  //   return arr.reduce((acc, cur) => {
  //     return parseFloat(cur[field]) < acc ? parseFloat(cur[field]) : acc;
  //   }, 10.0);
  // };

  // const findMin = curry((arr, field) => {
  //   return arr.reduce((acc, cur) => {
  //     return parseFloat(cur[field]) < acc ? parseFloat(cur[field]) : acc;
  //   }, 10.0);
  // });

  // const findMins = (arr) => {
  //   const min = findMin(arr);
  //   const lowestSupport = min("support");
  //   const lowestProbability = min("probability");
  //   const lowestAdjProbability = min("adjustedProbability");
  //   // const lowestSupport = findMin(arr, "support");
  //   // const lowestProbability = findMin(arr, "probability");
  //   // const lowestAdjProbability = findMin(arr, "adjustedProbability");

  //   setSupport(lowestSupport);
  //   setProbability(parseFloat(lowestProbability).toFixed(2));
  //   setAdjProbability(parseFloat(lowestAdjProbability).toFixed(3));
  // };
  return (
    <div>
      {isLoading && <HappyLoader />}
      <div id="predict-controls">
        <h3>Predict Associated Items</h3>
        <p style={{ width: "100%", textAlign: "center" }}>
          Enter a list of UPCs (separated by a comma) to get a list of generated
          predicted items. Ex: 4011,4065
        </p>
        <form className="form" onSubmit={handleFormSubmit}>
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              width: "90%",
              margin: "auto"
            }}
          >
            <Col xs="4">
              <label>Enter Upcs</label>
              <input
                type="text"
                className="form-control"
                value={items}
                onChange={handleItemChange}
              ></input>
            </Col>
            <Col xs="4">
              <label>Records to return</label>
              <input
                type="text"
                className="form-control"
                value={itemSize}
                onChange={handleItemsizeChange}
                placeholder="Items Size"
              />
            </Col>
            <Col
              xs="4"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "20px"
              }}
            >
              <button className="btn btn-outline-light">Submit</button>
            </Col>
          </Row>
        </form>
      </div>
      <div id="predict-body" style={{ marginTop: "10px" }}>
        {itemDetails.length > 0 ? (
          <React.Fragment>
            <p style={{ width: "100%", textAlign: "center" }}>
              These are the Upc(s) that suggested the items below
            </p>
            {targetItems.map((record, i) => (
              <div
                key={`ti-${i}`}
                className="targeted-item"
                style={{ width: "100%", textAlign: "center" }}
              >
                <div>Targeted UPC: {record.product_id1}</div>

                <div>Description: {record.description1}</div>
              </div>
            ))}

            <div
              className="record-count"
              style={{
                width: "100%",
                textAlign: "center",
                fontSize: "1.2rem",
                fontWeight: "bold"
              }}
            >
              Total Number of Transactions that have been processed is{" "}
              {totalRecords}
            </div>
            <div>
              <table className="table" style={{ width: "90%", margin: "auto" }}>
                <thead>
                  <tr>
                    <th>Upc</th>
                    <th>Description</th>
                    <th>Order Count</th>
                    <th>Item Count</th>
                  </tr>
                </thead>
                <tbody>
                  {itemDetails.map((record, index) => (
                    <tr key={`id-${index}`}>
                      <td>{record.upc}</td>
                      <td>{record.description}</td>
                      <td>{record.orderCount}</td>
                      <td>{record.itemCount1}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </React.Fragment>
        ) : null}
      </div>
    </div>
  );
};

export default Item;
